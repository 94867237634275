import React from 'react'
import TextInput from '../../../components/form/TextInput'

export default function DirectClaimNotes({
  litigationStatus,
  setTab,
  setFieldValue,
  values,
  handleSubmit,
}) {
  return (
    <div className={'indent-form'}>
      <div className={'form-row'}>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <label>Decline</label>
            <textarea
              name="incident.notes.claimDeclineReason"
              cols="30"
              rows="2"
              className="form-control"
              placeholder="Decline"
              onChange={(e) =>
                setFieldValue(
                  'incident.notes.claimDeclineReason',
                  e.target.value,
                )
              }
              value={values?.incident?.notes?.claimDeclineReason}
            ></textarea>
          </div>
        </div>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label>Litigation</label>
              <div>
                <div className="form-check form-check-inline mr-4 ">
                  <input
                    className="form-check-TextInput"
                    type="radio"
                    name="litigationStatus"
                    value="yes"
                    checked={litigationStatus === 'yes'}
                    onChange={() =>
                      setFieldValue('incident.notes.litigationStatus', 'yes')
                    }
                  />
                  <label
                    className="form-check-label"
                    style={{ paddingLeft: '5px' }}
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-TextInput"
                    type="radio"
                    name="litigationStatus"
                    value="no"
                    checked={litigationStatus === 'no'}
                    onChange={() => {
                      setFieldValue('incident.notes.litigationStatus', 'no')
                      setFieldValue(
                        'incident.notes.litigationAttorney.name',
                        '',
                      )
                      setFieldValue(
                        'incident.notes.litigationAttorney.email',
                        '',
                      )
                      setFieldValue(
                        'incident.notes.litigationAttorney.contactNumber',
                        '',
                      )
                    }}
                  />
                  <label
                    className="form-check-label"
                    style={{ paddingLeft: '5px' }}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className={'form-group'}>
              <textarea
                name="incident.notes.litigationDetails"
                cols="30"
                rows="2"
                className="form-control"
                placeholder="Litigation"
                onChange={(e) =>
                  setFieldValue(
                    'incident.notes.litigationDetails',
                    e.target.value,
                  )
                }
                value={values?.incident?.notes?.litigationDetails}
              ></textarea>
            </div>
          </div>
        </div>
        {values?.incident?.notes?.litigationStatus === 'yes' && (
          <>
            <div className="col-md-6">
              <TextInput
                name="incident.notes.litigationAttorney.name"
                label="Attorney Name"
              />
            </div>
            <div className="col-md-6">
              <TextInput
                isPhone
                changeOnUndefined={true}
                label="Attorney Contact Number"
                name="incident.notes.litigationAttorney.contactNumber"
                showErrorEvenWhenUntouched
                maxLength={14}
                onPhoneChange={(phone) => {
                  setFieldValue(
                    'incident.notes.litigationAttorney.contactNumber',
                    phone || '',
                  )
                }}
                value={
                  values?.incident?.notes?.litigationAttorney?.contactNumber ||
                  ''
                }
              />
              {/* <ErrorMessage
                name="incident.notes.litigationAttorney.contactNumber"
                component="span"
                className="text-danger"
              /> */}
            </div>
            <div className="col-md-12">
              <TextInput
                name="incident.notes.litigationAttorney.email"
                label="Attorney Email"
                type="email"
              />
            </div>
          </>
        )}
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <label>Settlement</label>
            <textarea
              name="incident.notes.settlementOffered"
              cols="30"
              rows="2"
              className="form-control"
              placeholder="Settlement"
              onChange={(e) =>
                setFieldValue(
                  'incident.notes.settlementOffered',
                  e.target.value,
                )
              }
              value={values?.incident?.notes?.settlementOffered}
            ></textarea>
          </div>
        </div>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <label>Remarks</label>
            <textarea
              name="incident.notes.claimAdjustorRemarks"
              cols="30"
              rows="2"
              className="form-control"
              placeholder="Remarks"
              onChange={(e) =>
                setFieldValue(
                  'incident.notes.claimAdjustorRemarks',
                  e.target.value,
                )
              }
              value={values?.incident?.notes?.claimAdjustorRemarks}
            ></textarea>
          </div>
        </div>
        {/* <div
          className="d-flex justify-content-between"
          style={{ width: '100%', marginTop: '43px' }}
        >
          <button
            className="btn text-primary pl-0"
            onClick={setTab}
            type="button"
          >
            <i
              className={'fas fa-chevron-left'}
              aria-hidden={true}
              style={{ paddingRight: '4px' }}
            >
              {' '}
            </i>
            Back
          </button>
          <button id="submit" type="submit" className="btn btn-primary" onClick={handleSubmit}>
            Save &amp; Continue
          </button>
        </div> */}
      </div>
    </div>
  )
}
