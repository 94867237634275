/* eslint-disable react/prop-types */
import React, {useState, useRef} from 'react'
import {Modal, ModalBody} from 'reactstrap'
import {Formik, Form} from 'formik'
import DatePickerField from './form/DatePickerField'
import TextInput from './form/TextInput'
import TextArea from './form/TextArea'
import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../utils/helper'

function AddVendorModalBtn(props) {
    const ref = useRef();
    const {buttonLabel, title, isOpen, bodyHeading} = props
    const [modal, setModal] = useState(isOpen || false)
    const [tab, setTab] = useState('details')
    const toggle = () => {
        setModal(!modal);
        !buttonLabel && props.modalClose(null);
    }
    const handleSubmit = (values) => {
        let formatDateValue = values?.addedOn ? moment(values?.addedOn).format(NO_TIMEZONE_DATE_FORMAT) : null;
        props.modalClose({ ...ref.current?.values, addedOn: formatDateValue } || {})
        toggle()
        ref.current.resetForm()
    }

    return (
        <Formik
            innerRef={ref}
            enableReinitialize
            initialValues={{...props.values}}
            onSubmit={handleSubmit}
        >
            <div>
                {buttonLabel && <button type="button" className="btn btn-outline-secondary side-btn mt-2"
                                        id="exampleModalLabel" data-toggle="modal" data-target="#addaccountant"
                                        onClick={toggle}>
                    {buttonLabel}
                </button>}
                <Modal
                    isOpen={modal}
                    backdrop="static"
                    toggle={toggle}
                    className={"modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable"}
                >
                    <div className={"modal-header justify-content-center"}>
                        <h5 className="modal-title text-primary " id="modal-basic-title">
                            {title}
                        </h5>
                    </div>
                    <ModalBody className="p-0" id="smodaltab">
                        <div>
                            <ul className="nav nav-pills bg-light d-flex justify-content-center" id="pills-tab"
                                role="tablist">
                                <li className="nav-item" role="presentation" onClick={() => setTab('details')}
                                    style={{cursor: "pointer"}}>
                                    <a className={"nav-link mt-0 font-weight-normal " + (tab === 'details' ? "active" : "")}
                                       id="Person-tab" data-toggle="pill" role="tab" aria-controls="pills-Person"
                                       aria-selected="true">{bodyHeading}</a>
                                </li>
                                <li className="nav-item" role="presentation" onClick={() => setTab('notes')}
                                    style={{cursor: "pointer"}}>
                                    <a className={"nav-link mt-0 font-weight-normal " + (tab === 'notes' ? "active" : "")}
                                       id="Person-tab"
                                       data-toggle="pill" role="tab" aria-controls="pills-Person"
                                       aria-selected="true">Notes</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className={"px-5 mt-4 pb-3"}>
                                {tab === 'details' ? <Form>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <TextInput label="Name" name="name"/>
                                            </div>
                                            <div className="col-md-6">
                                                <DatePickerField name="addedOn" label="As of Date"/>
                                            </div>
                                        </div>
                                    </Form> :
                                    <Form>
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <TextArea
                                                    cols={30}
                                                    rows={4}
                                                    label="Notes"
                                                    name="notes"
                                                />
                                            </div>
                                        </div>
                                    </Form>}
                            </div>
                        </div>
                        <div className={"modal-footer"} style={{background: "#F4F7FE"}}>
                            <button
                                onClick={handleSubmit}
                                className="btn btn-primary  add-btn"
                            >
                                Add
                            </button>
                            <button
                                onClick={toggle}
                                type="button"
                                className="btn btn-secondary bg-white text-dark cls-btn"
                                aria-label="Close"
                            >
                                Cancel
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </Formik>
    )
}

export default AddVendorModalBtn
