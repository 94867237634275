import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import store from './store'
import Routes from './routes/Routes'
import LoaderContext from './context/LoaderContext'
import * as Sentry from "@sentry/react";

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { deleteToken, getMessaging } from 'firebase/messaging'
import { getCurrentUser } from './services/userService'
import { handleLogout, saveUserData } from './actions'
import { deleteFirebaseToken } from './services/notificationService'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseDevConfig = {
  apiKey: "AIzaSyDF9IX83x0vtlViZSmTJusN_B91bRz7wtw",
  authDomain: "acuity-software.firebaseapp.com",
  projectId: "acuity-software",
  storageBucket: "acuity-software.appspot.com",
  messagingSenderId: "174202099754",
  appId: "1:174202099754:web:a2e831a589f854c85c4b53",
  measurementId: "G-V4L7E5NJS2"
};

const firebaseProdConfig = {
  apiKey: "AIzaSyDXCRK9vzor9-v6DpcbZ0zhLt7vEASRapk",
  authDomain: "acuity-production-43fcd.firebaseapp.com",
  projectId: "acuity-production-43fcd",
  storageBucket: "acuity-production-43fcd.appspot.com",
  messagingSenderId: "40492190690",
  appId: "1:40492190690:web:fb3cbd2460b4d7de7847bb"
};

// Initialize Firebase
const app = initializeApp(process.env.REACT_APP_FIREBASE_ENV === "production" ? firebaseProdConfig : firebaseDevConfig)
export const messaging = getMessaging(app)

export default function App() {
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // window.Intercom('boot', {
    //   api_base: 'https://api-iam.intercom.io',
    //   app_id: 'gcqq13ep',
    // })
    if (token) {
      getCurrentUser(token)
        .then((c) => {
          const userData = c.data.data.user
          Sentry.setUser({
            email: userData.email,
            id: userData._id
          })
          store.dispatch(saveUserData(c.data.data))
          setLoading(false)
          // history.push('/dashboard')
          // window.location = '/dashboard';
        })
        .catch((err) => {
          const firebaseToken = sessionStorage.getItem('firebaseToken')
          sessionStorage.clear()

          if (firebaseToken) {
            deleteToken(messaging).then(() => {
              deleteFirebaseToken(firebaseToken).then(() => {
                sessionStorage.removeItem('firebaseToken')
                store.dispatch(handleLogout())
              })
            })
          }
        })
    } else {
      setLoading(false)
      sessionStorage.removeItem('token')
    }
  }, [])

  if (loading) return <div>Loading...</div>

  return (
    <Provider store={store}>
      <LoaderContext.Provider value={{ loading: false }}>
        <Routes />
        <ToastContainer autoClose={3000} hideProgressBar={false} closeOnClick />
      </LoaderContext.Provider>
    </Provider>
  )
}
