import ActionTypes from './actionTypes'

export const handleTabIdUpdated = (id) => {
  return {
    type: ActionTypes.TAB_ID_UPDATED,
    id: id,
  }
}

export const handleSideNavDataUpdated = ({ data, module }, context) => {
  return {
    type: ActionTypes.SIDE_NAV_DATA_UPDATED,
    data: data,
    module: module,
    context: context,
  }
}

export const handleSideNavElementClicked = (id) => {
  return {
    type: ActionTypes.SIDE_NAV_ELEMENT_CLICKED,
    id: id,
  }
}

export const handleClientIdUpdated = (clientId) => {
  return {
    type: ActionTypes.HANDLE_CLIENT_ID_UPDATED,
    clientId: clientId,
  }
}

export const handleFetchClients = (clients) => {
  return {
    type: ActionTypes.FETCH_CLIENTS,
    clients: clients,
  }
}

export const handleLogin = (data) => {
  return {
    type: ActionTypes.LOGIN,
    data: data,
  }
}

export const handleLogout = () => {
  return {
    type: ActionTypes.LOGOUT,
  }
}

export const saveIncidentInfo = (data) => {
  return {
    type: ActionTypes.CALENDAR_INFO,
    key: 'incident',
    data:
      data?.map(({ _id, incidentId, endDate, partiesInvolved = [] }) => ({
        key: 'incident',
        start: endDate,
        title: incidentId,
        party: partiesInvolved?.map(({ personDetails = {} }) => ({
          firstName: personDetails?.firstName || '',
          lastName: personDetails?.lastName || '',
        })),
        _id,
      })) || [],
  }
}

export const saveClaimInfo = (data) => {
  return {
    type: ActionTypes.CALENDAR_INFO,
    key: 'claim',
    data:
      data?.map(({ _id, claimId, dateOfClaim }) => ({
        key: 'claim',
        start: dateOfClaim,
        title: claimId,
        _id,
      })) || [],
  }
}
export const savePolicyInfo = (data) => {
  return {
    type: ActionTypes.CALENDAR_INFO,
    key: 'policy',
    data:
      data?.map(({ _id, policyId, premiumRenewalDate }) => ({
        key: 'policy',
        start: premiumRenewalDate,
        title: policyId,
        _id,
      })) || [],
  }
}

export const saveIP = (ip) => {
  return {
    type: ActionTypes.SAVE_IP,
    ip: ip,
  }
}

export const applyViewAction = (type, data) => {
  return {
    type: ActionTypes.VIEW_ACTION,
    action: type,
    data
  }
}

export const saveIncidentFormData = (values) => {
  return {
    type: ActionTypes.SAVE_INCIDENT_FORM_DATA,
    data: values,
  }
}

export const saveUserData = (data) => {
  return {
    type: ActionTypes.SAVE_USER_DATA,
    data: data,
  }
}

export const handleDashboardDateSlotChanged = (data) => {
  return {
    type: ActionTypes.DASHBOARD_DATE_SLOT_CHANGED,
    data: data,
  }
}

