import { ErrorMessage, useFormikContext } from 'formik'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import AttachmentAddView from '../../../components/AttachmentAddView'
import { DatePickerCustomHeader } from '../../../components/form/DatePickerCustomHeader'
import {
  ClaimReportedViaTypes,
  allPriorityStatusTypes
} from '../../incident/utils'
import ClaimFinancialCard from '../claimFinancialCard'
import ClaimFinancialModal from '../claimFinancialModal'
import FormikSelect from './FormikSelect'
import DatePickerField from '../../../components/form/DatePickerField'

// In Process, Unresolved, Resolved, Pending Closure, Closed
const OutcomeOptions = [
  {
    value: 'In Progress',
    key: 'In Progress',
    label: 'In Progress',
  },
  {
    label: 'Unresolved',
    value: 'Unresolved',
    key: 'Unresolved',
  },
  {
    value: 'Resolved',
    label: 'Resolved',
    key: 'Resolved',
  },
  {
    value: 'Pending Closure',
    key: 'Pending Closure',
    label: 'Pending Closure',
  },
  {
    value: 'Closed',
    key: 'Closed',
    label: 'Closed',
  },
]

const ClaimRightSection = () => {
  const { values, setFieldValue, handleChange, handleBlur, setFieldTouched } = useFormikContext()

  const [openModal, setOpenModal] = useState({ isOpen: false, data: null })

  // const getDate = (date) => {
  //   let t = ''
  //   if (date) {
  //     try {
  //       t = new Date(date)
  //     } catch (err) {
  //       t = new Date()
  //     }
  //   }
  //   return t && t.toDateString() === 'Invalid Date' ? new Date() : t
  // }

  return (
    <>
      <div
        className="col-md-5 col-h border-left"
        style={{ background: '#F4F7FE', height: '100vh' }}
      >
        <div className="modal-body p-4 mdl-scroll">
          <div className="row">
          <div className="col-md-6">
              <DatePickerField
                name="incident.startDate"
                label="Start Date"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-md-6 posi-rel">
              <DatePickerField
                name="incident.endDate"
                label="Due Date"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-md-6 posi-rel">
              <DatePickerField
                name="incident.closeDate"
                label="Claim Close Date"
                onBlur={handleBlur}
              />
            </div>
            <div className="w-100 border-top mx-2 py-2"></div>
            <div className="col-md-6">
              <FormikSelect
                label="Priority"
                name="incident.priority"
                defaultLabel="Select Priority"
                options={allPriorityStatusTypes}
                value={values?.incident?.priority}
                setFieldValue={setFieldValue}
              />
            </div>
            {/* <div className="col-md-6">
              <FormikSelect
                label="Claim Status"
                name="incident.status"
                defaultLabel="Select status"
                options={allClaimStatusTypes}
                value={values?.incident?.status}
                setFieldValue={setFieldValue}
                errors={errors}
                handleBlur={handleBlur}
              />
            </div> */}
            <div className="col-md-6">
              <FormikSelect
                label="Reported Via"
                name="incident.reportedVia"
                defaultLabel="Select Reported Via"
                options={ClaimReportedViaTypes}
                value={values?.incident?.reportedVia}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            <div className="col-md-6">
              <DatePickerField
                  withAsterisk
                  name="dateOfClaim"
                  label="Claim File Date"
                  onBlur={handleBlur}
              />
              {/* <div className="form-group">
                <label>Claim File Date <span className='text-danger'>*</span></label>
                <DatePicker
                  autoComplete="off"
                  className="form-control"
                  placeholderText="MM/DD/YYYY"
                  name="dateOfClaim"
                  title='Claim File Date'
                  renderCustomHeader={DatePickerCustomHeader}
                  selected={getDate(values?.dateOfClaim)}
                  onChange={(val) => {
                    setFieldValue('dateOfClaim', val)
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault()
                  }}
                />
                <ErrorMessage className="text-danger" name="dateOfClaim" component="div" />
              </div> */}
            </div>
            <div className="form-group col-md-6">
              <div>
                <label>Time of Claim</label>
              </div>
              <TimePicker
                use12Hours
                className="antDOverride"
                name="timeOfClaim"
                onChange={(value) => {
                  setFieldValue('timeOfClaim', value.format('HH:mm:ss A'))
                }}
                value={moment(values?.timeOfClaim || '00:00:00', 'HH:mm:ss A')}
                defaultValue={moment('00:00:00', 'HH:mm:ss A')}
              />
            </div>

            <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            {/* <div className="form-group col-md-12">
              <label htmlFor="Outcome" className="col-form-label">
                Outcome
              </label>
              <textarea
                className="form-control col-md-12"
                id="Outcome"
                placeholder={'Outcome'}
                value={values?.incident?.description?.outcome}
                onChange={(a) =>
                  setFieldValue('incident.description.outcome', a.target.value)
                }
              ></textarea>
            </div> */}
            {/* <div className="col-md-12">
              <div className="form-group">
                <label>Next Action</label>
                <input
                  data-testid="next-action"
                  className="form-control"
                  placeholder="Next Action"
                  name="incident.nextAction"
                  type="text"
                  value={values?.incident?.nextAction ?? ""}
                  onChange={(a) =>
                    setFieldValue('incident.nextAction', a.target.value)
                  }
                />
              </div>
            </div> */}
            <div className="form-group col-md-12">
              <label htmlFor="next-action" className="col-form-label">
                Next Action <span className='text-danger'> *</span>
              </label>
              <textarea
                data-testid="next-action"
                className="form-control col-md-12"
                id="next-action"
                placeholder={'Next Action'}
                value={values?.incident?.nextAction ?? ""}
                onChange={(a) =>
                  setFieldValue('incident.nextAction', a.target.value)
                }
              ></textarea>
              <ErrorMessage
                data-testid="next-action-error"
                className="text-danger"
                name="incident.nextAction"
                component="div"
              />
            </div>
            <div className="col-md-12">
              <FormikSelect
                label="Outcome"
                name="incident.description.outcome"
                defaultLabel="Select Outcome"
                options={OutcomeOptions}
                value={values?.incident?.description?.outcome}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="w-100 border-top border-primary border-bottom mx-2 p-0 mb-3 "></div>
            <div className="form-group col-md-12">
              <label className="d-block incident-text" htmlFor="package">
                Claim Checklist
              </label>

              <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                <label
                  className="form-check-label text-primary"
                  htmlFor="Check4"
                >
                  Claimant Contacted
                </label>
                <input
                  onChange={(a) =>
                    setFieldValue(
                      'incident.checkList.claimantContacted',
                      a.target.checked,
                    )
                  }
                  name={'incident.checkList.claimantContacted'}
                  className="form-check-TextInput1 pt-0"
                  type="checkbox"
                  value=""
                  id="Check4"
                  data-testid="checklist-claimant-contacted"
                  checked={values?.incident?.checkList?.claimantContacted}
                />
              </div>

              <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                <label
                  className="form-check-label text-primary"
                  htmlFor="Check5"
                >
                  Client Contacted
                </label>
                <input
                  onChange={(a) =>
                    setFieldValue(
                      'incident.checkList.clientContacted',
                      a.target.checked,
                    )
                  }
                  name={'incident.checkList.clientContacted'}
                  className="form-check-TextInput1 pt-0"
                  type="checkbox"
                  value=""
                  id="Check5"
                  data-testid="checklist-client-contacted"
                  checked={values?.incident?.checkList?.clientContacted}
                />
              </div>

              <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                <label
                  className="form-check-label text-primary"
                  htmlFor="Check6"
                >
                  All Documents filed
                </label>
                <input
                  onChange={(a) =>
                    setFieldValue(
                      'incident.checkList.allDocumentsFiled',
                      a.target.checked,
                    )
                  }
                  name={'incident.checkList.allDocumentsFiled'}
                  className="form-check-TextInput1 pt-0"
                  type="checkbox"
                  value=""
                  id="Check6"
                  data-testid="checklist-all-documents-filed"
                  checked={values?.incident?.checkList?.allDocumentsFiled}
                />
              </div>

              <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                <label
                  className="form-check-label text-primary"
                  htmlFor="Check7"
                >
                  Gensuite Filed
                </label>
                <input
                  onChange={(a) =>
                    setFieldValue(
                      'incident.checkList.gensuiteFiled',
                      a.target.checked,
                    )
                  }
                  name={'incident.checkList.gensuiteFiled'}
                  className="form-check-TextInput1 pt-0"
                  type="checkbox"
                  value=""
                  id="Check7"
                  data-testid="checklist-gensuite-filed"
                  checked={values?.incident?.checkList?.gensuiteFiled}
                />
              </div>
              <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                <label
                  className="form-check-label text-primary"
                  htmlFor="Check8"
                >
                  Evidence Filed
                </label>
                <input
                  onChange={(a) =>
                    setFieldValue(
                      'incident.checkList.evidenceFiled',
                      a.target.checked,
                    )
                  }
                  name={'incident.checkList.evidenceFiled'}
                  className="form-check-TextInput1 pt-0"
                  type="checkbox"
                  value=""
                  id="Check8"
                  data-testid="checklist-evidence-filed"
                  checked={values?.incident?.checkList?.evidenceFiled}
                />
              </div>
            </div>
            <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            <div className="form-group col-md-12">
              <label className="d-block incident-text" htmlFor="package">
                Claim KPI
              </label>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Deductible</label>
                    <input
                      className="form-control"
                      placeholder="Deductible"
                      name="incident.deductible"
                      value={values?.incident?.deductible}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <DatePickerField
                    name="incident.openSince"
                    label="Open Since"
                    onBlur={handleBlur}
                  />
                  {/* <div className="form-group">
                    <label>Open Since</label>
                    <DatePicker
                      autoComplete="off"
                      className="form-control"
                      placeholderText="MM/DD/YYYY"
                      name="incident.openSince"
                      renderCustomHeader={DatePickerCustomHeader}
                      selected={getDate(values?.incident?.openSince)}
                      onChange={(val) => {
                        setFieldValue('incident.openSince', val)
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            <div className="form-group col-md-12">
              <label className="d-block incident-text">Claim Financials</label>
              {values?.incident?.financials?.map((f, index) => {
                return (
                  <ClaimFinancialCard
                    claim={f}
                    id={index}
                    key={index}
                    onEdit={() => {
                      const a = values?.incident?.financials[index]
                      setOpenModal({ isOpen: true, data: a, editId: index })
                    }}
                    onDelete={() => {
                      const a = values?.incident?.financials.filter(
                        (party, i) => i != index,
                      )
                      setFieldValue('incident.financials', a)
                    }}
                  />
                )
              })}
              <button
                data-testid="add-claim-financials"
                onClick={() => setOpenModal({ isOpen: true, data: null })}
                type="button"
                className="btn btn-outline-secondary side-btn mt-2"
                id="exampleModalLabel"
                data-toggle="modal"
                data-target="#claimfinancials"
              >
                Add..
              </button>
            </div>

            <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            <div className="form-group col-md-12">
              <AttachmentAddView
                formik={{
                  setFieldValue: setFieldValue,
                  values: values,
                }}
                module={'claim'}
                uid={values?.claimId}
              />
            </div>
          </div>
        </div>
      </div>
      <ClaimFinancialModal
        isOpen={openModal.isOpen}
        data={openModal.data}
        onAdd={(data) => {
          const financials =
            openModal.editId != null
              ? [
                ...(values?.incident?.financials?.filter(
                  (a, i) => i != openModal.editId,
                ) ?? []),
                data,
              ]
              : [...(values?.incident?.financials ?? []), data]
          setFieldValue('incident.financials', financials)
        }}
        onClose={() =>
          setOpenModal({
            isOpen: false,
            data: null,
            editId: null,
          })
        }
      />
    </>
  )
}

export default ClaimRightSection
