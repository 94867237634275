import React, { useEffect, useMemo, useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { toast } from 'react-toastify'
import Select1 from 'react-select'
// import FormPageHeader from '../../../components/FormPageHeader'
import { saveUser, updateUser } from '../../../services/userService'
import TextInput from '../../../components/form/TextInput'
import AddressInput from '../../../components/form/AddressInput'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import { getUserClients } from '../../../services/clientService'
import AreYouSureModal from '../../../components/common/AreYouSureModal'
import { USER_VALIDATION_SCHEMA } from '../../../utils/validation'
import { getUserRoles } from '../../../services/roleService'
import ShowAllClientCheck from './ShowAllClientCheck'
import { generateUID } from '../../../utils/generateUID'
import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'

function UserForm({ data = null, onClose, title }) {
  const [isNew, setIsNew] = useState(false)
  const [values, setValues] = useState({})
  const [clients, setClients] = useState([])
  const [roles, setRoles] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [showAllClientOption, setShowAllClientOption] = useState(true)

  const userId = useMemo(() => generateUID('user'), [])

  const clientOptions = useMemo(() => {
    const allClient = {
      _id: 'allClients',
      clientId: 'allClients',
      companyName: 'All Clients',
    }
    if (showAllClientOption) {
      const clts = [...clients]
      clts.unshift(allClient)
      return clts
    } else {
      return clients
    }
  }, [clients, showAllClientOption])

  const newUser = () => ({
    firstName: '',
    lastName: '',
    address: {
      state: '',
      city: '',
      country: '',
      line1: '',
      line2: '',
      pinCode: '',
    },
    email: '',
    accessPriviliges: [],
    clients: [],
    roleId: '',
    designation: '',
    contactNumber: '',
    dateOfBirth: '',
    userId,
  })

  useEffect(() => {
    setIsNew(!data)
    if (data && !isNew) {
      setValues({
        ...data,
        contactNumber: data.contactNumber ? data.contactNumber.toString() : '',
        roleId: data?.roleId?._id,
        clients: data.clients.length == 0 ? ['allClients'] : data.clients,
      })
    }
    fetchClients()
    fetchRoles()
  }, [isNew, data])

  const fetchClients = async () => {
    let {
      data: { data: clients },
    } = await getUserClients()

    setClients(clients)
  }

  const fetchRoles = async () => {
    const {
      data: { data: roles },
    } = await getUserRoles()
    setRoles(roles)
  }

  const closeModal = () => setOpenModal(false)

  const handleSubmit = async (values) => {
    const modifiedValues = { ...values }

    setValues(modifiedValues)
    setOpenModal(true)
  }

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      let req = values
      req.clients = values.clients
      if (req?.clients.includes('allClients')) {
        req.clients = []
      }

      if (req.dateOfBirth) {
        req.dateOfBirth = moment(req.dateOfBirth).format(NO_TIMEZONE_DATE_FORMAT)
      }

      if (isNew) {
        await saveUser(req)
        onClose()
        toast.success('User Added!')
      } else {
        await updateUser(req._id, req)
        onClose()
        toast.success('User Updated!')
      }
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...newUser(), ...values }}
        validationSchema={USER_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <div className={'row no-gutters col-container'}>
            <ShowAllClientCheck
              roleId={values.roleId}
              setFieldValue={setFieldValue}
              showAllClient={(d) => {
                setShowAllClientOption(!d)
              }}
            />
            <div className="col-md-7 col-h">
              <div className={'modal-body p-4 mb-4 px-3'}>
                <h2 className={'modal-title text-primary'}>{title}</h2>
                <div className={'border-bottom h6 py-2 mb-4 text-primary'}>
                  User Information
                </div>
                <Form className={'indent-form'}>
                  <div className="row">
                    <div className="col-md-6">
                      <TextInput label="First Name" name="firstName" />
                    </div>
                    <div className="col-md-6">
                      <TextInput label="Last Name" name="lastName" />
                    </div>
                    <div className="col-md-6">
                      <TextInput
                        isPhone
                        changeOnUndefined={true}
                        label="Contact Number"
                        name="contactNumber"
                        maxLength={14}
                        onPhoneChange={(phone) =>
                          setFieldValue('contactNumber', phone ?? "")
                        }
                        value={values?.contactNumber || ''}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInput label="Email ID" name="email" />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField label="DOB" name="dateOfBirth" />
                    </div>
                    <div className="col-md-6">
                      <TextInput label="Designation" name="designation" />
                    </div>
                    <div className="col-md-6">
                      <Select
                        label="Role"
                        defaultLabel="Select Role"
                        options={roles.map((el) => ({
                          key: el.role._id,
                          value: el.role.roleName,
                        }))}
                        name="roleId"
                      />
                    </div>
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Clients</label>
                          <Select1
                            isMulti
                            options={clientOptions.map((cl) => ({
                              value: cl._id,
                              label:
                                cl.clientId === 'allClients'
                                  ? cl.companyName
                                  : `${cl.clientId}-${cl.companyName}`,
                            }))}
                            getOptionvalue={(c) => c.value}
                            isSearchable="true"
                            isClearable="true"
                            value={clientOptions
                              .filter((c) => values.clients.includes(c._id))
                              .map((cl) => ({
                                value: cl._id,
                                label:
                                  cl.clientId === 'allClients'
                                    ? cl.companyName
                                    : `${cl.clientId}-${cl.companyName}`,
                              }))}
                            onChange={(value, actionMeta) => {
                              if (value.length === 0) {
                                setFieldValue(`clients`, [])
                                return
                              }
                              if (
                                actionMeta?.option?.value &&
                                actionMeta?.option?.value === 'allClients'
                              ) {
                                setFieldValue(`clients`, ['allClients'])
                              } else {
                                const cls = value
                                  .filter((c) => c.value !== 'allClients')
                                  .map((c) => c.value)
                                setFieldValue(`clients`, cls)
                              }
                            }}
                            name="clients"
                          />
                          <ErrorMessage
                            className="text-danger"
                            name={'clients'}
                            component="div"
                          />
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary text-white"
                        >
                          Save &amp; Close
                        </button>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className={'col-md-5 col-h border-left '}>
              <div className={'modal-body p-4 mdl-scroll'}>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <label className={'incident-text'}>
                      User ID
                      <span className={'text-primery'}>
                        {values.userId}
                        <b className={'text-dark font-weight-normal ml-2'}>
                          (System Generated)
                        </b>
                      </span>
                    </label>
                  </div>
                  <div className="col-md-12">
                    <AddressInput
                      label="Address"
                      name="address"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className={'w-100 border-top mx-2 py-2'}></div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <AreYouSureModal
        openModal={openModal}
        closeModal={closeModal}
        handleSubmit={handleSubmitModal}
      />
    </>
  )
}
export default UserForm
