import React, { useContext } from 'react'
import { operations } from '../../configs/utils'
import RolesContext from './RolesContext'

const AccessLevelRow = ({ index, AccesLevels, permissionObj }) => {
    const formik = useContext(RolesContext)
    const currentValue = formik.values.permissions[index].accessMode
  return (
    <tr key={index}>
    <td></td>
    <td>{permissionObj.moduleName}</td>
    {AccesLevels?.map((level) => (
      <td key={level}>
        <div className="text-center  mt-2">
          <input
            name={`permissions[${index}].accessMode`}
            type="radio"
            value={level}
            checked={currentValue === level}
            onChange={(e)=>{
              formik.handleChange(e);
              if(level === 'NO_ACCESS') {
                formik.setFieldValue(`permissions[${index}].operations`, []);
              } else {
                formik.setFieldValue(`permissions[${index}].operations`, operations);
              }
            }}
          />
        </div>
      </td>
    ))}
  </tr>
  )
}

export default AccessLevelRow