import React, { useEffect, useState } from 'react'
import Select1 from 'react-select'
import DateRangePicker from '../../components/common/DateRangePicker'

function RenderAutoCompelete(props){
  const {name, label, options, index ,setFilterComp , clearAuto , resetClearAuto} = props;  
  const [selectedOption, setSelectedOption] = useState([]);  
  useEffect(()=>{
    
    if(clearAuto === "clearAll") {
      setSelectedOption([]);
      resetClearAuto();
    }
  }, [clearAuto])
  return  <Select1       
  isMulti        
  placeholder = {label}                 
  value={selectedOption}                                                                        
  onChange={(data)=>{          
    setSelectedOption(data);        
    setFilterComp(data)                                
}}
  options={options.map((c)=>({value: c.key, label:c.value }))}
/>
}

function FilterDropdown({
  filters = [],
  setFilters = () => {},
  inputFilters = [],
  setInputFilters = () => {},
  dateRangeFilter = {},
  setDateRangeFilter = () => {},
  selectFilters = [],
  selectedFilterCount = 0,
  setSelectedFilter = () => {},
  showDateRange=true,  
  onClear  
}) {
  const [clearAuto,setClearAuto] = useState('');
  const formatDate = (date) => {
    const iso = date.toISOString()
    return iso.split('T')[0]
  }        

  return (
    <div className="dropdown">
      <div
        className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg"
        id="filter-drop"
        x-placement="bottom-end"
        style={{
          position: 'absolute',
          willChange: 'transform',
          top: 0,
          left: 0,
          transform: 'translate3d(-333px, 38px, 0px)',
        }}
      >
        <form>
          <div className="navi navi-hover my-3">
            <div className="navi-item font-weight-normal">
              <div className="">
              {!showDateRange 
                        ? <> </> 
                        :
                Object.values(dateRangeFilter || {}).map(
                  ({ label, start, end, key }) => {
                    return (
                      <div key={label}>                        
                        <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            timePicker: true,
                            locale: {
                              format: 'DD/MM/YYYY hh:mm A',
                            },
                          }}
                          onEvent={(event, picker) => {
                            if (event.type === 'apply') {
                              const start = picker.startDate._d
                              const end = picker.endDate._d
                              setDateRangeFilter((dateFilter) => ({
                                ...dateFilter,
                                [key]: {
                                  label,
                                  start,
                                  end,
                                  key,
                                },
                              }))
                            } else if (event.type === 'cancel') {
                              setDateRangeFilter((dateFilter) => ({
                                ...dateFilter,
                                [key]: {
                                  label,
                                  start: null,
                                  end: null,
                                  key,
                                },
                              }))
                            }
                          }}
                          onCallback={(...rest) => {
                            console.log({ rest })
                          }}
                        >
                          <div className=" px-4">
                            <div className="input-group mb-3 ">
                              <div className="input-group-prepend">
                                <span className="input-group-text p-2">
                                  <i
                                    className="fa fa-calendar cald"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <input
                                type=""
                                name="daterange"
                                value={
                                  start && end
                                    ? `${formatDate(start)} - ${formatDate(
                                        end,
                                      )}`
                                    : label
                                }
                                readOnly
                                className="form-control bg-none inherit"
                              />
                            </div>
                          </div>
                        </DateRangePicker>                  
                      </div>
                    )
                  },
                )}
                <div className="separator border-gray-200"></div>
                <div className="mt-2">
                  <div className="d-flex justify-content-between align-items-center px-4">
                    <div>
                      <span className="badge badge-success  py-2 fltr">
                        Filter Status{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.3"
                            d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <div className="pt-3 pb-3 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            type="reset"
                            onClick={()=>{                                                 
                              
                                setClearAuto('clearAll')
                              
                              onClear();}}
                            className="btn btn-primary btn-xs bg-none border-0 py-0 px-0"
                            id="clearall"
                            style={{
                              display:
                                selectedFilterCount < 2 ? 'none' : 'flex',
                            }}
                          >
                            <small>Clear All</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {selectFilters.length
                      ? selectFilters.map(({ name, label, options, key , isAutocomplete = false , selectedOption = '', setSelectedOption = () => {}} , index) => (                        
                          <div key={name} className="my-2 dropdown-item ">
                            <div className="select-wrapper">
                              <i
                                className="fas fa-chevron-down arrow-filter"
                                aria-hidden="true"
                              ></i>
                              {
                                isAutocomplete 
                                ?                                   
                                <RenderAutoCompelete                                 
                                name={name} 
                                label={label} 
                                clearAuto={clearAuto}
                                options={options} 
                                 index={index} 
                                resetClearAuto={()=>{setClearAuto('')}}
                                setFilterComp={(data)=>{
                                  setSelectedFilter((sFilter) => ({
                                      ...sFilter,
                                      [key]: {
                                        ...sFilter[key],          
                                        selected:data.map((d)=>d.value)
                                      },
                                    }))    
                                }} />                        
                                :                              
                              <select
                                name={name}
                                autoComplete="off"
                                onChange={(event) => {
                                  setSelectedFilter((sFilter) => ({
                                    ...sFilter,
                                    [key]: {
                                      ...sFilter[key],
                                      selected: event.target.value,
                                    },
                                  }))
                                }}
                                className="form-control form-select inherit"
                              >
                                <option
                                  // selected={selected === ''}
                                  value=""
                                >
                                  {label}
                                </option>
                                {options?.map(({ key, value }) => (
                                  <option
                                    // selected={selected === key}
                                    key={key}
                                    value={key}
                                  >
                                    {value}
                                  </option>
                                ))}
                              </select>

}
                            </div>
                          </div>
                        ))
                      : null}
                    
                    {Object.values(inputFilters || {}).map(
                      ({ key, value, label }) => {
                        return (
                          <div
                            key={key}
                            className="dropdown-item d-flex justify-content-between"
                          >
                            <div
                              className="d-flex align-items-center "
                              style={{ cursor: 'pointer', width:"100%" }}
                            >
                              <div className="form-group" style={{ cursor: 'pointer', width:"100%" }}>
                                <input
                                  className="form-control"
                                  placeholder={label}
                                  value={value}
                                  onChange={(e) => {
                                    setInputFilters((s) => ({
                                      ...s,
                                      [key]: {
                                        ...s[key],
                                        value: e.target.value,
                                      },
                                    }))
                                  }}
                                />
                              </div>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center">
                              <div className="ml-5">{}</div>
                            </div> */}
                          </div>
                        )
                      },
                    )}

                    {Object.values(filters || {}).map(
                      ({ key, value, count, isAvailable }) => {
                        return (
                          <div
                            key={key}
                            className="my-2 dropdown-item d-flex justify-content-between"
                          >
                            <div
                              className="d-flex align-items-center "
                              onClick={() => {
                                setFilters((s) => ({
                                  ...s,
                                  [key]: {
                                    ...s[key],
                                    isAvailable: !isAvailable,
                                  },
                                }))
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <input
                                  className="chkbx"
                                  type="checkbox"
                                  style={{ cursor: 'pointer' }}
                                  checked={!!isAvailable}
                                  onChange={() => {}}
                                />
                              </div>
                              <div className="ml-3">{value}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ml-5">{count}</div>
                            </div>
                          </div>
                        )
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="separator border-gray-200"></div>
            <div className="px-4 pt-3"></div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FilterDropdown
