import React from 'react'
import { useDispatch } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'

export default function DashboardRecordInfo(props) {
  const dispatch = useDispatch()
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  return (
    <div
      className={'slide-sidebar mt-5 sidebar-shift'}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight mr-3">
              <h2
                className="mb-0 pb-0 dark-text"
                style={{ paddingTop: '0.5rem' }}
              >
                {' '}
                No Record To Show
              </h2>
            </div>
            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
