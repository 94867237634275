/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useFormikContext } from 'formik'
import IncidentDetailsView from '../AddNewIncidentViews/IncidentDetailsView'
import SupervisorDetailsView from '../AddNewIncidentViews/SupervisorDetailsView'
import UserDetailsView from '../AddNewIncidentViews/UserDetailsView'
import ClaimNotes from './ClaimNotes'
import { useDirectClaimContext } from './DirectClaimContext'
import DirectClaimNotes from './DirectClaimNotes'
import DirectIncidentNarrativeView from './DirectIncidentNarrativeView'
import FormikSelect from './FormikSelect'

function DirectIncidentDetailsTab() {
  const { values, setFieldValue, errors, handleBlur, handleSubmit } = useFormikContext()
  const {
    activeStep = 1,
    setActiveStep,
    isNew,
    initialValues
  } = useDirectClaimContext()

  const changeActiveStep = (step) => {
    setActiveStep(step)
  }
  const renderUI = () => {
    switch (activeStep) {
      case 1:
        return <UserDetailsView
          showNext={false}
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      case 2:
        return <IncidentDetailsView
          showActionButtons={false}
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      case 3:
        return <SupervisorDetailsView
          showActionButtons={false}
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
          setFieldTouched={setFieldValue}
        />
      case 4:
        return <ClaimNotes />
      case 5:
        return <DirectClaimNotes
          litigationStatus={values?.incident?.notes?.litigationStatus || ''}
          // setTab={handleBackButtonClicked}
          setFieldValue={setFieldValue}
          values={values}
        // handleSubmit={handleSubmit}
        />
      case 6:
        return <DirectIncidentNarrativeView
          showActionButtons={false}
          context={context}
          // setNarrative={setNarrative}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      default:
        return <p>There's nothing in this Tab.</p>
    }
  }

  const handleContinue = () => {
      if (activeStep === 1) {
        if (isClientExist && selectedClientId !== 'allClients') {
          values['clientId'] = selectedClientId
          setFieldValue('clientId', selectedClientId)
        }
        let parties = values?.incident?.partiesInvolved?.length
          ? [...values?.incident?.partiesInvolved]
          : [];
      
        let party = {
          firstName: values?.incident?.userDetails?.firstName || '',
          lastName: values?.incident?.userDetails?.lastName || '',
          email: values?.incident?.userDetails?.email || '',
          organisation: values?.incident?.userDetails?.organisation || '',
          contactNumber: values?.incident?.userDetails?.contactNumber || '',
          address: {
            line1: values?.incident?.userDetails?.address,
            line2: '',
            city: values?.incident?.userDetails?.city,
            state: values?.incident?.userDetails?.state,
            country: values?.incident?.userDetails?.country,
            pinCode: values?.incident?.userDetails?.zip,
          },
          employeeId:
            values?.incident?.userDetails?.employeeId &&
            values?.incident?.userDetails?.employeeId !== 'OTHER'
              ? values?.incident?.userDetails?.employeeId
              : 'OTHERS',
        };
      
        if (parties.length) {
          // Update the existing object in the array
          parties[0].isCompanyEmployee = true;
          parties[0].personDetails = { ...party };
        } else {
          parties.push({ isCompanyEmployee: true, personDetails: { ...party } });
        }
        setFieldValue('incident.partiesInvolved', parties);
      }
      changeActiveStep(activeStep + 1)
  }

  const getDate = (date) => {
    let t = ''
    if (date) {
      try {
        t = new Date(date)
      } catch (err) {
        t = new Date()
      }
    }
    return t && t.toDateString() === 'Invalid Date' ? new Date() : t
  }
  const context = 'claim'
  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const vehicularAccident = values?.incident?.vehicularAccident ?? false

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])

  useEffect(() => {
    if (!isClientExist) {// Client dropdown appear only when  creating a new incident
      // Reset userDetails employee selection dropdown on client selection as userdetails is based on client selection
      setFieldValue('incident.userDetails.employeeId', '')
    }
  }, [isClientExist, values?.clientId])

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <div
      className="col-md-7 col-h"
      style={{ height: '100vh', overflow: 'scroll' }}
    >
      <div className="modal-body p-4 mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="modal-title text-primary">
            {isNew ? 'Add Claim' : 'Edit Claim'}
          </h2>
          {context === 'claim' && (
            <p className="mb-0 text-success ft-20">{activeStep}/6</p>
          )}
        </div>

        <div
          className="mt-4 mb-3 "
          style={activeStep > 1 ? { pointerEvents: 'none' } : null}
        >
          <div
            className="btn-group btn-group-toggle border btn-tgl"
            data-toggle="buttons"
          >
            <label
              className={
                'btn mr-2 px-4' +
                (vehicularAccident ? ' active' : '') +
                (vehicularAccident && activeStep > 1 ? ' disabled' : '')
              }
              onClick={() => {
                setFieldValue('incident.vehicularAccident', true)
              }}
            >
              <input type="radio" name="options" id="option1" /> Vehicular
            </label>
            <label
              className={
                'btn mr-2 px-4' +
                (!vehicularAccident ? ' active' : '') +
                (!vehicularAccident && activeStep > 1 ? ' disabled' : '')
              }
              onClick={() => {
                setFieldValue('incident.vehicularAccident', false)
              }}
            >
              <input type="radio" name="options" id="option2" /> Non-Vehicular
            </label>
          </div>
        </div>

        {/* <div className="border-bottom h6 py-2 mb-3">Claim Details</div> */}
        <div className="">
          <div className="form-row">
            {(isClientExist || activeStep > 1) ? null : (
              <div className="col-md-12 mb-2">
                <FormikSelect
                  withAsterisk
                  label="Select Client"
                  name="clientId"
                  defaultLabel="Select Client"
                  options={getClientDropdownOpt()}
                  value={values?.clientId}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  handleBlur={handleBlur}
                />
              </div>
            )}
          </div>
          {
            renderUI()
          }
          <hr />
          {
            activeStep > 1 && (<button type='button' className="btn text-primary pl-0" onClick={() => setActiveStep(activeStep - 1)}>
              <i
                className={'fas fa-chevron-left'}
                aria-hidden={true}
                style={{ paddingRight: '4px' }}
              />
              Back
            </button>)
          }
          {
            activeStep === 6 ? (<button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary pull-right"
            >
              Save and Close
            </button>) : (<button
              type="button"
              className="btn btn-primary pull-right"
              onClick={handleContinue}
            >
              Continue
            </button>)
          }

        </div>

      </div>
    </div >
  )
}

export default DirectIncidentDetailsTab
