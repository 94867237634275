import React from 'react'
import moment from 'moment'
import { transformPhoneNumber } from '../incident/utils'
import { VehiclePart } from '../../components/Incidents/VehiclePart'
import { SedanCarParts } from '../../components/Incidents/SedanCarParts'
import { HumanBodyPart } from '../../components/Incidents/HumanBodyPart'
import { capitalizeWords } from '../../utils/helper'

const PublicIncidentView = ({ setActiveStep, publicIncidentData }) => {
  let getAddress = (address) => {
    let addressString = ''
    if (address) {
      addressString += address.address ? address.address + ', ' : ''
      addressString += address.city ? address.city + ', ' : ''
      addressString += address.state ? address.state + ', ' : ''
      addressString += address.country ? address.country + '-' : ''
      addressString += address.zip ? address.zip : ''
    }
    return addressString
  }

  const getKeyLabel = (key) => {
    const k = {
      vehicleType: "Vehicle Type",
      companyVehicleType: "Company Vehicle Type",
      yearOfManufacture: "Year of Manufactured",
      model: "Model",
      make: "Make",
      mileage: "Mileage",
      vin: "VIN",
      van: "VAN",
      licensePlate: "License Plate",
      damageState: "State"
    }
    return k[key] ?? key
  }

  const getVehicleDetailView = (vehicle) => {
    let vehicleData = []
    {
      Object.keys(vehicle?.vehicleDetails).forEach((keyName, index) => {
        if (keyName !== 'vehicleDamageParts') {
          vehicleData.push(
            <div className="d-flex border-bottom" key={index}>
              <div className="form-group col-md-6">
                <p className="mt-2 mb-0 font-weight-bold"> {getKeyLabel(keyName)}</p>
              </div>

              <div className="form-group col-md-6">
                <p className="mt-2 mb-0">
                  {keyName === 'yearOfManufacture'
                    ? vehicle?.vehicleDetails[keyName]
                      ? moment(vehicle?.vehicleDetails[keyName]).format('YYYY')
                      : 'N/A'
                    : _.capitalize(vehicle?.vehicleDetails[keyName])}
                </p>
              </div>
            </div>
          )
        }
      })
    }
    return vehicleData
  }

  return (
    <section className="public-form-wrapper">
      <div className="container-fluid modal2">
        <div className="row">
          <div className="col-md-12">
            <div className="my-5 py-5">
              <div
                className="cab-form customized-scrollbar has-sticky-footer"
                style={{
                  overflowY: 'auto',
                  height: '80vh',
                  overflowX: 'hidden',
                }}
              >
                <div className="text-center mb-2">
                  <h1>New Incident Reported</h1>
                </div>
                <div className="border-bottom pb-3"></div>
                <div className="mt-4">
                  <div>
                    <div className="form-group  mb-0">
                      <p className="mb-1">
                        <strong>
                          Reference No.:{' '}
                          <span data-testid="reference-number">{publicIncidentData?.publicIncidentId}</span>
                        </strong>
                      </p>

                      <p>
                        Incident Type:{' '}
                        <span> {_.capitalize(publicIncidentData?.vehicleNature)} </span>
                      </p>
                    </div>
                    <div className="form-group">
                      <p className="mb-1">
                        Date:{' '}
                        <span>
                          {moment(publicIncidentData?.created).format(
                            'MM/DD/YYYY',
                          )}
                        </span>
                      </p>
                      <p>
                        Kind Attn.:{' '}
                        <span>
                          {' '}
                          {publicIncidentData?.supervisorDetails?.name}{' '}
                          (Supervisor)
                        </span>
                      </p>
                      <p>
                        Company Name:{' '}
                        <span> {publicIncidentData?.clientId} </span>
                      </p>
                      <p>
                        Shift Time:{' '}
                        <span data-testid="shift-time"> {publicIncidentData?.shiftStartTime} </span>
                      </p>
                    </div>

                    <div className="form-group border-bottom mt-5">
                      <p className="text-primary">
                        <strong> Employee Details: </strong>
                      </p>
                    </div>

                    <div className="d-flex  border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mb-0 font-weight-bold"> Name </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mb-0">
                          {publicIncidentData?.userDetails?.firstName}{' '}
                          {publicIncidentData?.userDetails?.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Date of Birth
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0">
                          {publicIncidentData?.userDetails?.dateOfBirth
                            ? moment(
                              publicIncidentData?.userDetails?.dateOfBirth,
                            ).format('MM/DD/YYYY')
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Job Position/Title
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.userDetails?.jobTitle}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">Address</p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {getAddress(publicIncidentData?.userDetails)}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Phone Number
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0" data-testid="employee-contact-number">
                          {publicIncidentData?.userDetails?.contactNumber
                            ? transformPhoneNumber(
                              publicIncidentData?.userDetails?.contactNumber,
                            )
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Email Address
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.userDetails?.email ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">FICO Score</p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.userDetails?.ficoScore}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Station Code/ Job Site
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.userDetails?.jobSite}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <div>
                    <div className="form-group border-bottom">
                      <p className="text-primary">
                        <strong> Incident Details: </strong>
                      </p>
                    </div>

                    <div className="d-flex  border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mb-0 font-weight-bold">
                          Date of Incident
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mb-0">
                          {moment(
                            publicIncidentData?.incidentDetails?.dateOfIncident,
                          ).format('MM/DD/YYYY')}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Time of Incident
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0" data-testid="incident-time">
                          {publicIncidentData?.incidentDetails?.time || 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">Location</p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.location}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          What was employee doing when the incident happened
                          (lifting, driving, pushing, delivering, etc.):
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0">
                          {
                            publicIncidentData?.incidentDetails
                              ?.employeeActivity
                          }
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Were there any Injuries?
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.isInjury ===
                            'true'
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    {publicIncidentData?.incidentDetails?.isInjury ===
                      'true' && (
                        <>
                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Nature of Injury
                              </p>
                            </div>

                            <div className="form-group col-md-6">
                              <p className="mt-2   mb-0">
                                {
                                  publicIncidentData?.incidentDetails
                                    ?.natureOfInjury
                                }
                              </p>
                            </div>
                          </div>
                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Was an Ambulance called, if so, which hospital?
                              </p>
                            </div>

                            <div className="form-group col-md-6">
                              <p className="mt-2   mb-0">
                                {publicIncidentData?.incidentDetails?.isAmbulance}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Injuries Details
                              </p>
                            </div>
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-normal">
                                {publicIncidentData?.humanParts?.length > 0
                                  ? publicIncidentData?.humanParts
                                    .join(', ')
                                    .toString()
                                  : 'N/A'}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <HumanBodyPart
                                selectedParts={
                                  publicIncidentData?.humanParts || []
                                }
                                onClick={() => { }}
                              />
                            </div>
                          </div>

                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Pain Level
                              </p>
                            </div>

                            <div className="form-group col-md-6">
                              <p className="mt-2   mb-0">
                                {publicIncidentData?.incidentDetails?.painLevel}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Do You require Medical Treatment?
                              </p>
                            </div>

                            <div className="form-group col-md-6">
                              <p className="mt-2   mb-0">
                                {publicIncidentData?.incidentDetails
                                  ?.isMedicalTreatment === 'true'
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                              <p className="mt-2 mb-0">
                                {
                                  capitalizeWords(publicIncidentData?.incidentDetails
                                    ?.medicalTreatmentType ?? [], true)
                                }
                              </p>
                            </div>
                          </div>
                          {publicIncidentData?.incidentDetails
                            ?.isMedicalTreatment === 'true' && (
                              <>
                                <div className="d-flex border-bottom">
                                  <div className="form-group col-md-6">
                                    <p className="mt-2 mb-0 font-weight-bold">
                                      Was a member of the public injured?
                                    </p>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <p className="mt-2   mb-0">
                                      {
                                        publicIncidentData?.incidentDetails
                                          ?.publicMemberInjured
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex border-bottom">
                                  <div className="form-group col-md-6">
                                    <p className="mt-2 mb-0 font-weight-bold">
                                      Object/Substance which directly injured or
                                      exposed the employee?
                                    </p>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <p className="mt-2   mb-0">
                                      {
                                        publicIncidentData?.incidentDetails
                                          ?.substance
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex border-bottom">
                                  <div className="form-group col-md-6">
                                    <p className="mt-2 mb-0 font-weight-bold">
                                      Describe Injury in detail
                                    </p>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <p className="mt-2   mb-0">
                                      {
                                        publicIncidentData?.incidentDetails
                                          ?.incidentDescription
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex border-bottom">
                                  <div className="form-group col-md-6">
                                    <p className="mt-2 mb-0 font-weight-bold">
                                      Describe the alleged injury
                                    </p>
                                  </div>

                                  <div className="form-group col-md-6">
                                    <p className="mt-2   mb-0">
                                      {
                                        publicIncidentData?.incidentDetails
                                          ?.allegedInjury
                                      }
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}

                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Type of Third-Party Involvement
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {
                            publicIncidentData?.incidentDetails
                              ?.thirdPartyInvolvementType
                          }
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Is Third Party present?
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails
                            ?.isThirdPartyPresent
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    {publicIncidentData?.incidentDetails
                      ?.isThirdPartyPresent && (
                        <>
                          <div className="d-flex border-bottom">
                            <div className="form-group col-md-6">
                              <p className="mt-2 mb-0 font-weight-bold">
                                Third Party Details
                              </p>
                            </div>

                            <div className="form-group col-md-6">
                              <p className="mt-2   mb-0 font-weight-bold">
                                Name :{' '}
                                <span className="font-weight-normal">
                                  {
                                    publicIncidentData?.incidentDetails
                                      ?.thirdPartyDetails?.thirdPartyName
                                  }
                                </span>
                              </p>
                              <p className="mt-2   mb-0 font-weight-bold">
                                Contact Number:{' '}
                                <span className="font-weight-normal">
                                  {publicIncidentData?.incidentDetails
                                    ?.thirdPartyDetails?.thirdPartyContactNumber
                                    ? transformPhoneNumber(
                                      publicIncidentData?.incidentDetails
                                        ?.thirdPartyDetails
                                        ?.thirdPartyContactNumber,
                                    )
                                    : 'N/A'}
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Has Third Party left or refused to give info ?
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.thirdPartyLeaves
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Was there a Police Report taken for this incident?
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.policeCalled
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    {publicIncidentData?.incidentDetails?.policeCalled && (
                      <>
                        <div className="d-flex border-bottom">
                          <div className="form-group col-md-6">
                            <p className="mt-2 mb-0 font-weight-bold">
                              Police Report Details
                            </p>
                          </div>

                          <div className="form-group col-md-6">
                            <p className="mt-2   mb-0 font-weight-bold">
                              Report Number :{' '}
                              <span className="font-weight-normal">
                                {
                                  publicIncidentData?.incidentDetails
                                    ?.policeReportDetails?.policeReportNumber
                                }
                              </span>
                            </p>
                            <p className="mt-2   mb-0 font-weight-bold">
                              Jurisdiction(Country):{' '}
                              <span className="font-weight-normal">
                                {
                                  publicIncidentData?.incidentDetails
                                    ?.policeReportDetails?.policeReportcountry
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Driver statement{' '}
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0 font-weight-bold">
                          {publicIncidentData?.incidentDetails?.driverStatement}
                        </p>
                      </div>
                    </div>

                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Unsafe conditions
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.unsafeCondition}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">Witnesses</p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.incidentDetails?.hasWitnesses
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    {publicIncidentData?.incidentDetails?.hasWitnesses &&
                      publicIncidentData?.incidentDetails?.witnesses.length && (
                        <div className="d-flex border-bottom">
                          <div className="form-group col-md-6">
                            <p className="mt-2 mb-0 font-weight-bold">
                              Witnesses Details
                            </p>
                          </div>

                          <div className="form-group col-md-6 d-flex flex-wrap">
                            {publicIncidentData.incidentDetails?.witnesses.map(
                              (witness, index) => (
                                <div key={index}>
                                  <p className="mt-2   mb-0 font-weight-bold">
                                    Name :{' '}
                                    <span className="font-weight-normal">
                                      {witness?.name}
                                    </span>
                                  </p>
                                  <p className="mt-2   mb-0 font-weight-bold">
                                    Contact No:{' '}
                                    <span className="font-weight-normal">
                                      {witness?.contactNumber
                                        ? transformPhoneNumber(
                                          witness?.contactNumber,
                                        )
                                        : 'N/A'}
                                    </span>
                                  </p>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="d-flex border-bottom">
                    <div className="form-group col-md-6">
                      <p className="mt-2 mb-0 font-weight-bold">Attachments</p>
                    </div>

                    <div className="form-group col-md-6">
                      <div className="previewimg d-flex justify-content-between flex-wrap mt-2 mb-0">
                        {publicIncidentData?.attachmentViews?.length &&
                          publicIncidentData?.attachmentViews?.map(
                            (item, index) => (
                              <div className="attachment" key={index}>
                                <div className="attach-img d-flex justify-content-center align-items-center ">
                                  <a
                                    target="_blank"
                                    href={item}
                                    rel="noreferer noreferrer"
                                    role="button"
                                    className="btn btn-primary btn-down"
                                  >
                                    Download
                                  </a>
                                  <div className="text-center">
                                    {item?.split("?")?.shift()?.match(/\.(jpg|jpeg|png|gif)$/) ? (
                                      <object
                                        style={{
                                          width: '151px',
                                          height: '100%',
                                          objectFit: 'cover',
                                        }}
                                        data={item}
                                      />
                                    ) : (
                                      <>
                                        <svg
                                          id="picture"
                                          xmlns={item}
                                          width="34.705"
                                          height="34.705"
                                          viewBox="0 0 34.705 34.705"
                                        >
                                          <path
                                            id="Path_17011"
                                            data-name="Path 17011"
                                            d="M27.475,0H7.23A7.239,7.239,0,0,0,0,7.23V27.475A7.239,7.239,0,0,0,7.23,34.7H27.475a7.239,7.239,0,0,0,7.23-7.23V7.23A7.239,7.239,0,0,0,27.475,0ZM7.23,2.892H27.475A4.338,4.338,0,0,1,31.813,7.23V27.475a4.267,4.267,0,0,1-.434,1.858l-13.25-13.25a7.23,7.23,0,0,0-10.226,0l-5.01,5.009V7.23A4.338,4.338,0,0,1,7.23,2.892Zm0,28.921a4.338,4.338,0,0,1-4.338-4.338V25.181l7.054-7.054a4.338,4.338,0,0,1,6.137,0l13.25,13.251a4.267,4.267,0,0,1-1.858.434Z"
                                            fill="#868686"
                                          ></path>
                                          <path
                                            id="Path_17012"
                                            data-name="Path 17012"
                                            d="M17.561,13.622A5.061,5.061,0,1,0,12.5,8.561,5.061,5.061,0,0,0,17.561,13.622Zm0-7.23a2.169,2.169,0,1,1-2.169,2.169,2.169,2.169,0,0,1,2.169-2.169Z"
                                            transform="translate(5.575 1.561)"
                                            fill="#868686"
                                          ></path>
                                        </svg>
                                        <div className="">
                                          Preview Not Available
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {publicIncidentData?.vehicleNature === 'vehicular' && (
                  <>
                    <div className="form-group mt-5">
                      <p className="text-primary" style={{ fontSize: '16px' }}>
                        <strong> Vehicle Details </strong>
                      </p>
                    </div>
                    {publicIncidentData?.vehiclesInvolved?.map(
                      (vehicle, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-baseline"
                        >
                          <div id="main1" className="vehicle-accordion">
                            <div>
                              <div className="accordion" id="faq">
                                <div className="card vehicleDetail-accordion">
                                  <div
                                    className="card-header1"
                                    id={`faqhead${index}`}
                                  >
                                    <a
                                      href="#"
                                      className="btn btn-header-link"
                                      data-toggle="collapse"
                                      data-target={`#faq${index}`}
                                      aria-controls={`faq${index}`}
                                    >
                                      Vehicle Information
                                    </a>
                                  </div>

                                  <div
                                    id={`faq${index}`}
                                    className="pt-3 collapse show"
                                    aria-labelledby={`faqhead${index}`}
                                    data-parent="#faq"
                                  >
                                    <div className="card-body">
                                      {getVehicleDetailView(vehicle)}
                                      <div
                                        className="d-flex border-bottom"
                                        key={index}
                                      >
                                        <div className="form-group col-md-6">
                                          <p className="mt-2 mb-0 font-weight-bold">
                                            {' '}
                                            Vehicle Damage Parts
                                          </p>
                                        </div>

                                        <div className="form-group col-md-6">
                                          <p className="mt-2 mb-0">
                                            {vehicle?.filteredVehicleParts
                                              ?.length > 0
                                              ? vehicle.filteredVehicleParts.map(e => _.capitalize(e))
                                                .join(', ')
                                                .toString()
                                              : 'N/A'}
                                          </p>
                                        </div>
                                      </div>
                                      {vehicle?.vehicleDetails.vehicleType ===
                                        'company' ? (
                                        <VehiclePart
                                          selectedParts={
                                            vehicle?.filteredVehicleParts || []
                                          }
                                          onSelect={() => { }}
                                        />
                                      ) : (
                                        <SedanCarParts
                                          selectedParts={
                                            vehicle?.filteredVehicleParts || []
                                          }
                                          onSelect={() => { }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </>
                )}

                <div className="mt-5">
                  <div>
                    <div className="form-group border-bottom">
                      <p className="text-primary">
                        <strong> Supervisor Details: </strong>
                      </p>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Did you notify your supervisor?
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.supervisorDetails?.isNotify ===
                            'true'
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold"> Name </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.supervisorDetails?.name}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Contact Number
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0">
                          {publicIncidentData?.supervisorDetails?.contactNumber
                            ? transformPhoneNumber(
                              publicIncidentData?.supervisorDetails
                                ?.contactNumber,
                            )
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Notified Date
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {publicIncidentData?.supervisorDetails
                            ?.notifiedDate !== ''
                            ? moment(
                              publicIncidentData?.supervisorDetails
                                ?.notifiedDate,
                            ).format('MM/DD/YYYY')
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex border-bottom">
                      <div className="form-group col-md-6">
                        <p className="mt-2 mb-0 font-weight-bold">
                          Method Supervisor Notified
                        </p>
                      </div>

                      <div className="form-group col-md-6">
                        <p className="mt-2   mb-0">
                          {
                            publicIncidentData?.supervisorDetails
                              ?.notificationMethod
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="form-group my-4">
                    <p>
                      <strong> Consent Details :</strong> Accepted{' '}
                      <strong> IP Address :</strong>{' '}
                      {publicIncidentData?.ipAddress !== ''
                        ? publicIncidentData?.ipAddress
                        : `2401:4900:1c17:6d49:
                    41d1:128b:54e3:98e7`}{' '}
                      <strong> Reference Number : </strong>{' '}
                      {publicIncidentData?.publicIncidentId}
                    </p>

                    <p>
                      <strong> Date &amp; Time : </strong>{' '}
                      {moment(publicIncidentData?.created).format(
                        'MM/DD/YYYY - hh:mm A z',
                      )}
                      <strong> Phone Number :</strong>{' '}
                      {publicIncidentData?.userDetails?.contactNumber
                        ? transformPhoneNumber(
                          publicIncidentData?.userDetails?.contactNumber,
                        )
                        : 'N/A'}{' '}
                      <strong> Email : </strong>{' '}
                      {publicIncidentData?.userDetails?.email}
                    </p>
                  </div>
                </div>
                <div className="sticky-button-wrapper">
                  <button
                    data-testid="continue-to-manager-form"
                    type="submit"
                    className="btn btn-primary px-4 border-radius-12 w-100"
                    onClick={() => setActiveStep(2)}
                  >
                    Continue to Manager&apos;s Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PublicIncidentView
