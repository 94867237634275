import React from 'react'
import {Modal} from 'reactstrap'
import PolicyForm from './PolicyForm'

import { ReactComponent as Close } from '../../svgs/close.svg'

export default function PolicyModal({title, isOpen, data, onClose}) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        backdrop={'static'}
        className={'modal-dialog-top modal-lg modal-right posi-rel'}
        style={{ minWidth: '60vw' }}
      >
        <div>
          <a
            onClick={onClose}
            className="close close-left"
            aria-label="Close"
          >
            <Close />
          </a>
          <div className="modal-content" style={{ height: '100vh' }}>
            <div className="col-md-12 p-0 col-h">
              <div className="modal-body p-0">
                <PolicyForm data={data} onClose={onClose} title={title} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
}