import React from 'react'
import ClaimsReported from '../Dashboard/components/charts/ClaimsReported'
import ClaimsIncurredCoverage from '../Dashboard/components/charts/ClaimsIncurredCoverage'
import IncurredClaims from '../Dashboard/components/charts/IncurredClaims'
import OpenClaims from '../Dashboard/components/charts/OpenClaims'

function ClaimsDashboard(props) {
  return (
    <div className="">
      <div className="">
        <div>
          <div className="row">
            <div className="col-6 col-lg-4 col-xxl-4 col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <ClaimsReported />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <ClaimsIncurredCoverage onViewClaimsClick={props.onViewClaimsClick}/>
                  <div className="mb-60" />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-xxl-4 col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <IncurredClaims />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-xxl-4 col-md-4">
              <div className="card card-custom card-stretch card-stretch-half gutter-b">
                <div className="card-header align-items-center border-0 mt-3 pb-0">
                  <h6 className="card-title align-items-start flex-column">
                    <span className="card-label font-weight-bolder text-dark ">
                      Open Claims
                    </span>
                    <span className="text-muted mt-3 font-size-sm pull-right" style={{cursor: "pointer"}}>
                      <a className="pt-2" onClick={props.onViewClaimsClick}>View Claims</a>
                    </span>
                  </h6>
                </div>
                <div className="card-body">
                  <div style={{ height: '250px', overflow: 'auto' }}>
                    <OpenClaims />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" />
        </div>
      </div>
    </div>
  )
}

export default ClaimsDashboard
