import React from 'react'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextArea from '../../../components/form/TextArea'
import TextInput from '../../../components/form/TextInput'
import { notificationMethod } from '../utils'

export default function SupervisorDetailsView({
  values,
  setFieldValue,
  onNavigate,
  setFieldTouched,
}) {
  return (
    <div className="d-flex flex-column">
      <div className="border-bottom h6 py-2 mb-3">Supervisor Details</div>
      <div className="form-row flex-1">
        <div className="col-md-12">
          <TextInput
            withAsterisk
            name={`supervisorDetails.name`}
            label="Supervisor Name"
            showErrorEvenWhenUntouched
          />
        </div>
        <div className="col-md-6">
          <TextInput
            type="email"
            data-testid="supervisor-email"
            name={`supervisorDetails.email`}
            label="Supervisor Email"
            showErrorEvenWhenUntouched
          />
        </div>
        <div className="col-md-6">
          <TextInput
            isPhone
            data-testid="supervisor-contact-number"
            changeOnUndefined={true}
            label="Contact Number"
            name="supervisorDetails.contactNumber"
            showErrorEvenWhenUntouched
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('supervisorDetails.contactNumber', phone || '')
            }}
            value={values?.supervisorDetails?.contactNumber || ''}
          />
        </div>
        <div className="form-group col-md-6">
          <DatePickerField
            name="supervisorDetails.notifiedDate"
            label="Date Supervisor Notified of Incident:"
          />
        </div>
        <div className="form-group col-md-6">
          <Select
            label="Method Supervisor Notified"
            name="supervisorDetails.notificationMethod"
            defaultLabel="Select Notification Method"
            options={notificationMethod}
            value={values?.supervisorDetails?.notificationMethod || ''}
          />
        </div>
        <div className="form-group col-md-12">
          <TextArea
            cols={30}
            rows={4}
            label="Supervisor Narrative"
            name="supervisorDetails.incidentDescription"
          />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <button data-testid="btn-back" className="btn text-primary pl-0" onClick={() => onNavigate(2)}>
          <i
            className={'fas fa-chevron-left'}
            aria-hidden={true}
            style={{ paddingRight: '4px' }}
          />
          Back
        </button>
        <button
          data-testid="btn-next"
          type="button"
          className="btn btn-primary pull-right"
          onClick={() => onNavigate(4)}
        >
          Continue
        </button>
      </div>
    </div>
  )
}
