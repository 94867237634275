import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Table from '../../../../components/common/Table'
import Pagination from '../../../../components/common/Pagination'
import { paginate } from '../../../../utils/paginate'
import { getClaims } from '../../../../services/claimService'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/common/Loader'

let policies = []
let itemsCount = 0

function OpenClaims() {
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(4)
  const [data, setData] = useState([])
  const history = useHistory()
  const mountedRef = useRef(true)
  const [isLoading , setIsLoading] = useState(true);

  const columns = [
    {
      path: 'claimId',
      label: ' Claim Number ',
      content: (claim) => (
        <>
          <a>{claim.claimId}</a>
        </>
      ),
    },
    {
      path: 'incidents[0].claimants[0].claimantInfo.basicInfo.firstName',
      label: ' Claimant ',
    },
    { path: 'dateOfClaim', label: ' Date ', type: 'date' },
  ]

  const getPageData = () => {
    const filtered = data
    const sorted =
      sortColumn && sortColumn.path
        ? _.orderBy(filtered, [sortColumn.path], [sortColumn.order])
        : filtered
    itemsCount = sorted.length
    policies = [...paginate(sorted, currPage, pageSize)]
    return policies.length > 0
  }

  const {selectedClientId} = useSelector(state=>state);
  useEffect(() => {
    mountedRef.current = true
    setIsLoading(true)
    fetchList()
    return () => { 
      mountedRef.current = false
    }
  }, [selectedClientId])

  const fetchList = async () => {
    const { data } = await getClaims()
    if (!mountedRef.current) return null
    setData(data.data)
    setIsLoading(false)
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handlePageChange = (page) => {
    setCurrPage(page)
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <div className="table-responsive-xl scroll-change">
      {getPageData() || ''}
      <Table
        hideCheckbox={true}
        columns={columns}
        data={policies}
        className={"user-mang"}
        sortColumn={sortColumn}
        onSort={handleSort}
      />
      <Pagination
        itemsCount={itemsCount}
        pageSize={pageSize}
        currentPage={currPage}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default OpenClaims
