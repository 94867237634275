import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { addComma } from '../../../incident/utils';
import { getincidentPaidVSIncurredReportData } from "../../../../services/dashboardService";
import Loader from "../../../../components/common/Loader";
import moment from "moment";

const labelColor = '#3F4254';
const borderColor = '#E4E6EF';
const baseprimaryColor = '#009ef7';
const lightprimaryColor = '#009ef7';
const basesuccessColor = '#50cd89';
const lightsuccessColor = '#50cd89';


let initialOptions = {
  series: [{
    name: 'Paid',
    data: []
  },
  {
    name: 'Incurred',
    data: []
  }
  ],
  chart: {
    id: 'area-datetime',
    type: 'area',
    height: 350,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  responsive: [{
    breakpoint: 480,
    options: {
      legend: {
        position: 'bottom',
        offsetX: -10,
        offsetY: 0
      }
    }
  }],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  xaxis: {
    type: 'datetime',
    categories: [],
    overwriteCategories: undefined,
    position: 'bottom',
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      // hideOverlappingLabels: true,
      showDuplicates: true,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      formatter: undefined,
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: labelColor,
        fontSize: '12px'
      }
    }
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0
      }
    },
    hover: {
      filter: {
        type: 'none',
        value: 0
      }
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0
      }
    }
  },
  tooltip: {
    style: {
      fontSize: '12px'
    },
    y: {
      formatter: function (val) {
        return "$ " + addComma(val) + " USD"
      }
    },
    x:{
      show: true,
      formatter: function (val) {
        return  moment(val).format('DD/MM/yyyy HH:mm')
      }
    }
  },
  grid: {
    borderColor: borderColor,
    strokeDashArray: 4,
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    position: 'top',
    offsetY: 10,
    markers: {
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: [lightprimaryColor, lightsuccessColor],
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0
    },
  },
  stroke: {
    curve: 'smooth',
    show: true,
    width: 3,
    colors: [baseprimaryColor, basesuccessColor]
  },
};

function AccidentsPaidVSIncurred() {
  const [options, setOptions] = useState(initialOptions)
  const [total, setTotal] = useState({ paid: 0, incurred: 0 })

  const [isLoading, setIsLoading] = useState(true)
  const { slot, selectedClientId } = useSelector((state) => state)


  useEffect(() => {
    setIsLoading(true)

    getincidentPaidVSIncurredReportData(slot).then(response => {
      let incidentPaidVSIncurredReport = response.data.data
      setOptions({
        ...options,
        series: incidentPaidVSIncurredReport?.series,
        xaxis: {
          ...options.xaxis,
          categories: incidentPaidVSIncurredReport?.timeslots
        }
      })
      let paidTotal = incidentPaidVSIncurredReport?.series[0].data.reduce((a, b) => a + b, 0)
      let incurredTotal = incidentPaidVSIncurredReport?.series[1].data.reduce((a, b) => a + b, 0)
      setTotal({
        paid: paidTotal,
        incurred: incurredTotal
      })
      setIsLoading(false)
    })
  }, [slot, selectedClientId])


  return (
    <div>
      <div className="card card-bordered" style={{ minHeight: '550px' }}>
        {isLoading &&
          <div style={{ marginTop: "200px" }} ><Loader loading={isLoading} /></div>
        }
        {!isLoading &&
          <>
            <div className="d-flex justify-content-between card-header border-0 mt-3 pt-2">
              <div>
                <h6 className="card-title align-items-start flex-column p-0 m-0">
                  <span className="font-weight-bolder text-dark">Accidents Paid vs Incurred</span>
                </h6>
              </div>
              <div className="d-flex">
                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mr-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">Paid</span>
                  <div className="fw-semibold text-gray-400">$ {total.paid ? addComma(total.paid) : 0} USD</div>
                </div>

                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">
                    <span className="ms-n1 counted">Incurred</span></span>
                  <div className="fw-semibold text-gray-400">$ {total.incurred ? addComma(total.incurred) : 0} USD</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
              options={options}
              series={options.series}
              type="area" height={350}
              />
            </div>
            <div className="kt-portlet__foot d-flex justify-content-between p-3 align-items-center">
              <div className="">
                <a href="/incidents" className="text-uppercase font-weight-semibold">View All
                  <span className="svg-icon-arrow">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3"
                        d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                        fill="currentColor"></path>
                      <rect x="21.9497" y="3.46448" width="13" height="2" rx="1"
                        transform="rotate(135 21.9497 3.46448)" fill="currentColor">
                      </rect>
                      <path
                        d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                        fill="currentColor"></path>
                    </svg>
                  </span></a>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default AccidentsPaidVSIncurred
