import React, { useContext } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import Context from '../../context/LoaderContext'

const Loader = ({loading}) => {
  const loader = useContext(Context) 
  return (
    <div className={`${loader.loading || loading ? "loader-container" : '' }`}>
      <LoadingOverlay active={loader.loading || loading} spinner={<BounceLoader />} />
    </div>
  )
}

export default Loader
