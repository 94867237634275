import "bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import React, { useEffect, useRef } from "react";

let $picker = null;
function DateRangePicker({
    initialSettings, children, 
    onEvent, onCallback
}) {
    let ref = useRef(null);

    useEffect(() => {
        $picker = $(ref);
        $picker.daterangepicker({
            ...initialSettings,
            handleCallback,
            setStartDate: (dateOrString) => {
                $picker?.data('daterangepicker')?.setStartDate(dateOrString);
            },
            setEndDate: (dateOrString) => {
                $picker?.data('daterangepicker')?.setEndDate(dateOrString);
            }
        });
         // attach event listeners
        ['Show', 'Hide', 'ShowCalendar', 'HideCalendar', 'Apply', 'Cancel'].forEach(
            (event) => {
                const lcase = event.toLowerCase();
                $picker?.on(lcase + '.daterangepicker',makeEventHandler());
            }
        );
        return () => {
            $picker?.data('daterangepicker')?.remove();
        }
    }, [ref]);

    const handleCallback = (...args) => {
        if (typeof onCallback === 'function') {
            onCallback(...args);
        }
    }

    const makeEventHandler = () => {
        return (event, picker) => {
            const daterangepicker = document.getElementsByClassName("daterangepicker")[0];
            if ( event.type === "show" && daterangepicker ) {
                const {left, right, top} = daterangepicker.style;
                window.myDatePickerStyle = {left, right, top};
            }
            if (typeof onEvent === 'function') {
                onEvent(event, picker);
            }
        };
    }

    return (
        React.cloneElement(React.Children.only(children), { ref: (el) => (ref = el),})
    );
}

export default DateRangePicker;
