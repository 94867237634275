import React from 'react'

const ExtraSettingHeader = ({ moduleName }) => {
  return (
    <div className="modal-header px-5">
      <h5 className="modal-title text-primary " id="">
        {' '}
        <span>
          <a href="#" style={{ textTransform: 'capitalize' }}>
            {moduleName.toLowerCase().replace(/-/g, ' ')}
            <i
              className="fa fa-chevron-right mx-2"
              style={{ fontSize: '14px' }}
            ></i>
          </a>
        </span>{' '}
        <span>
          <a href="#">Settings</a>
        </span>
      </h5>
    </div>
  )
}

export default ExtraSettingHeader
