import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/comments`

export function saveComment(comment,module) {
    return http.post(`${apiEndpoint}/save?module=${module}`, comment)
}

export function updateComment(commentId,comment,module) {
    return http.patch(`${apiEndpoint}/update/${commentId}?module=${module}`, comment)
}

export function deleteComment(commentId,module) {
    return http.delete(`${apiEndpoint}/delete/${commentId}?module=${module}`)
}
  
export function getCommentsByEventId(eventId,module,payload) {
    return http.get(`${apiEndpoint}/list/${eventId}?page=1&module=${module}`,{params: payload})
}

export function getCommentsByGenId(genId,module) {
    return http.get(`${apiEndpoint}/list?module=${module}&genId=${genId}`)
}

export function updateCommentPined(commentId,module,comment) {
    return http.patch(`${apiEndpoint}/update-pined/${commentId}?module=${module}`, comment)
}