import React from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import queryString from 'query-string'

import { forgotPassword } from '../../services/authService'
const Schema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    changepassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
        )
    })
});


export default function ResetPassword({ match,location }) {
    const history = useHistory()
    
    const forgotPasswordHandler = async (password) =>{
        const {id} = match?.params
        const qParams = queryString.parse(location.search)
            try {
                const res = await forgotPassword({password,token:qParams?.token || null});
                toast.success('Password Changed Successfully!')
                history.replace('/login')
            } catch (error) {
                toast.error(error.message)
            }
    }

    return (
        <div className="m-content">
            <div className="container">
                <div className="col-lg-12">
                    <div className="text-center">
                        <div className="mt-3 mb-4">
                            <img src="/assets/images/logo.png" alt="acuity" />
                        </div>
                        <h1 className="wel_back">Welcome Back!</h1>
                        <h6 className="login-acu mt-3 mb-5">Reset your account credentials to view your space</h6>
                    </div>
                    <div className="m-portlet">
                        <div className="m-portlet__head text-center">
                            <h3>Reset Password</h3>
                        </div>
                        <Formik
                            initialValues={{
                                password: "",
                                changepassword: ""
                            }}
                            validationSchema={Schema}
                            onSubmit={({password}) =>forgotPasswordHandler(password)}
                        >
                            {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                                return (
                                    <form onSubmit={handleSubmit} className="m-form m-form--fit p-4">
                                        <div>
                                            <div className="form-group m-form__group row">
                                                <div className="col-lg-12">
                                                    <label className="form-control-label"><strong>Password</strong> </label>
                                                    <input type="password"
                                                        name="password"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.password} className="form-control border-radius-0" placeholder="Password" required />

                                                    <span id="message" style={{ color: 'red' }} >{errors.password}</span>
                                                </div>
                                            </div>
                                            <div className="form-group m-form__group row">
                                                <div className="col-lg-12">
                                                    <label className="form-control-label"><strong>Confirm-Password:</strong> </label>
                                                    <input
                                                        type="password"
                                                        name="changepassword"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.changepassword} className="form-control border-radius-0" placeholder="Confrim Password" required />
                                                    <span id="message" style={{ color: 'red' }} >{errors.changepassword}</span>
                                                </div>
                                            </div>
                                            <div className="form-group m-form__group row">
                                                <div className="col-lg-12">
                                                    <button  disabled={errors.changepassword} type="submit" className="btn btn-primary custom-btn"> <strong>Submit</strong>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}
