import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'

import AddAttachmentMVR from '../../components/AddAttachmentMvr'
import DatePickerField from '../../components/form/DatePickerField'
import TextInput from '../../components/form/TextInput'
import apiUrl from '../../configs/api'
import { generateUID } from '../../utils/generateUID'
import { parseJWT } from '../../utils/jwt'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function MvrAdd({ match }) {
  const [values, setValues] = useState({})
  const [mvrSubmitted, setMvrSubmitted] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [termsModal, setTermsModal] = useState(false)
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [attachments, setAttachments] = useState([])
  const { orgName } = match?.params
  let query = useQuery()

  const clientInfo = useMemo(() => {
    const token = query.get('key')
    try {
      if (token) {
        const info = parseJWT(token)
        // No error
        if (info?.data) {
          return info['data']
        }
      }
      return false
    } catch (error) {
      return false
    }
  }, [query])

  const {
    clientId = '',
    accessToken = '',
    companyName = '',
    userId = '',
  } = clientInfo || {}

  const isInvalidClient = useCallback(() => {
    return (
      orgName.length === 0 ||
      clientId.length === 0 ||
      accessToken.length === 0 ||
      companyName.length === 0 ||
      userId === 0 ||
      companyName !== orgName
    )
  }, [orgName, clientId, accessToken, companyName, userId])

  const newMvr = () => ({
    firstName: '',
    lastName: '',
    organisation: orgName,
    contactNumber: '',
    attachments: [],
    ssnCode: '',
    issueDate: '',
    expiryDate: '',
    mvrId: generateUID('mvr'),
  })

  const handleSubmit = (vlc) => {
    setMvrSubmitted(true)
    setTermsModal(true)
    setValues(vlc)
  }

  const saveMVR = (mvr) => {
    // const date = new Date()
    // const mvrId = `MVR-${date.getFullYear()}-${(date.getMonth() + 1)
    //   .toString()
    //   .padStart(2, 0)}-${date.getMilliseconds()}`
    fetch(`${apiUrl}/mvr/save`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `${accessToken}`,
      },
      body: JSON.stringify({
        ...mvr,
        licenseIssueDate: mvr.issueDate ? moment(mvr.issueDate).format(NO_TIMEZONE_DATE_FORMAT) : mvr.issueDate,
        licenseExpiryDate: mvr.expiryDate ? moment(mvr.expiryDate).format(NO_TIMEZONE_DATE_FORMAT) : mvr.expiryDate,
        // mvrId,
        clientId,
        userId,
        organisation: companyName,
      }),
    })
      .then((res) =>
        !res.ok ? res.json().then((e) => Promise.reject(e)) : res.json(),
      )
      .then(() => {
        toast.success('Mvr Added')
      })
      .catch((err) => {
        console.log({ err })
        toast.error('Error in MVR addition')
      })
      .finally(() => {
        setTermsModal(false)
      })
  }

  const handleFinalSubmitForm = async () => {
    try {
      if (termsAccepted && mvrSubmitted) {
        saveMVR(values)
      } else {
        toast.error('Please accept terms and conditions')
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const handleTermsSubmit = (event) => {
    setTermsAccepted(event.target.checked)
  }

  const handleClose = () => {
    setTermsModal(false)
  }

  const handleAddAttachmentToggle = () => {
    setOpenAttachmentModal(!openAttachmentModal)
  }

  return isInvalidClient() ? (
    <Redirect to="/login" />
  ) : (
    <Formik
      initialValues={{
        ...newMvr(),
        ...values,
      }}
      onSubmit={handleSubmit}
    >
      {({ values: mv, setFieldValue }) => (
        <Fragment>
          <section>
            <header>
              <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom shadow-sm">
                <span className="my-0 mr-md-auto font-weight-normal acuity-logo">
                  <a href="">
                    <img
                      style={{ margin: '-10px 0' }}
                      src="/assets/images/logo.png"
                    />
                  </a>
                </span>
              </div>
            </header>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="mvr-form">
                    <Form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <TextInput label="First Name" name="firstName" />
                        </div>
                        <div className="form-group col-md-6">
                          <TextInput label="Last Name" name="lastName" />
                        </div>
                        <div className="form-group col-md-6">
                          <div>
                            <label>Organization</label>
                            <div className={'form-control'}>{companyName}</div>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <TextInput
                            isPhone
                            label="Contact Number"
                            name="contactNumber"
                            maxLength={14}
                            onPhoneChange={(phone) =>
                              phone.length > 2 &&
                              setFieldValue('contactNumber', phone)
                            }
                            value={mv.contactNumber || ''}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <DatePickerField
                            name="issueDate"
                            label="License Issue Date"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <DatePickerField
                            name="expiryDate"
                            label="License Expiry Date"
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <TextInput
                            label="Social Security Number"
                            name="ssnCode"
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <div className="form-group">
                            {openAttachmentModal && (
                              <AddAttachmentMVR
                                token={accessToken}
                                uid={mv.mvrId}
                                onClose={handleAddAttachmentToggle}
                                onAdd={(atts) => {
                                  const newAttachments = [
                                    ...attachments,
                                    ...atts,
                                  ]
                                  setAttachments(newAttachments)

                                  setFieldValue(
                                    'attachments',
                                    newAttachments.map((c) => c._id),
                                  )
                                  setOpenAttachmentModal(false)
                                }}
                                isOpen={openAttachmentModal}
                                module='mvr'
                              />
                            )}
                            <div
                              className="add-file upload-file-MVR"
                              onClick={() => setOpenAttachmentModal(true)}
                            >
                              <i
                                className="fas fa-paperclip1"
                                style={{ width: '2rem', textAlign: 'center' }}
                              />
                              <span>Upload File</span>
                            </div>
                          </div>
                        </div>
                        {attachments.map((att) => (
                          <div key={att._id} className="d-flex w-100">
                            <p style={{ flexGrow: 1 }}>{att.fileName}</p>
                            <i
                              onClick={() => {
                                const nw = mv.attachments.filter(
                                  (c) => att._id !== c,
                                )

                                setAttachments(
                                  attachments.filter((c) => att._id !== c._id),
                                )
                                setFieldValue('attachments', nw)
                              }}
                              style={{ cursor: 'pointer' }}
                              className="fa fa-trash"
                            />
                          </div>
                        ))}
                      </div>
                      <div className="col-lg-12">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary custom-btn pl-5 pr-5"
                            // disabled={isSubmitting && termsAccepted}
                            data-dismiss="modal"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="mb-5" />
            </div>
            <footer className="p-3 px-4 container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between footer-bottom">
              <div className="text-dark order-2 order-md-1">
                <span>Copyright © {moment().year()} All Rights Reserved</span>
              </div>
            </footer>
          </section>
          <Modal
            className="s-modal"
            isOpen={termsModal}
            backdrop="static"
            size="lg"
            toggle={handleClose}
          >
            <ModalBody>
              <div className="modal-content rounded-20 border-0">
                <div className="modal-header MVRSubmission-head">
                  <h4 className="modal-title MVR-Submission-title">
                    MVR SUBMISSION
                  </h4>
                  <button
                    type="button"
                    className="close mvr-close"
                    onClick={handleClose}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body pl-5 pr-5 MVR-Submission-body">
                  <p
                    style={{ height: '300px', overflow: 'scroll' }}
                    className="mvr-subtxt"
                  >
                    As a driver of <strong>{mv.organisation}</strong>, I understand that
                    it is my responsibility to operate the vehicle in a safe
                    manner and to drive defensively to prevent injuries and
                    property damage. I also understand that{' '}
                    <strong>{mv.organisation}</strong> will periodically review my Motor
                    Vehicle Record to determine continued eligibility to drive a
                    Company vehicle or a personal vehicle on Company business.
                    In accordance with the Fair Credit Reporting Act and the
                    Federal Drivers Privacy Protection Act, as well as any State
                    specific regulations that may be applicable, I have been
                    informed that a Motor Vehicle Record will be (annually)
                    obtained on me for initial and continued employment
                    purposes. I agree that <strong>{mv.organisation}</strong> has the
                    right, at any time, to perform inspections of both the
                    inside and outside of the vehicle provided to me by{' '}
                    <strong>{mv.organisation}</strong> .I acknowledge the receipt of the
                    above disclosure and authorize <strong>{mv.organisation}</strong> or
                    its designated agent to obtain a Motor Vehicle Record
                    report. The authorization is valid as long as I am an
                    Employee or Employee candidate and may only be rescinded in
                    writing, in accordance to referenced public laws.
                  </p>
                </div>
                <div className="text-center">
                  <p className="term-check">
                    <input
                      type="checkbox"
                      value=""
                      className="check-box mt-3 mr-2"
                      id="checkboxTerms"
                      onClick={handleTermsSubmit}
                    />{' '}
                    <span className="agree">
                      I agree to the terms & conditions
                    </span>
                  </p>
                  <div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <div className="text-center m-auto pt-4 pb-4">
                          <button
                            type="button"
                            className="btn btn-primary custom-btn  btn-block pt-3 pb-3 mvr-sub"
                            onClick={handleFinalSubmitForm}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Fragment>
      )}
    </Formik>
  )
}

export default MvrAdd
