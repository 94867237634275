import React, { useContext } from 'react'
import AccessLevelRow from './AccessLevelRow'
import RolesContext from './RolesContext'

function AccessLevel() {
  const AccesLevels = [
    'SELF_ACCESS',
    'ORGANISATION_ACCESS',
    'FULL_ACCESS',
    'NO_ACCESS',
  ]
  const formik = useContext(RolesContext)

  //Not require
  const permissions = formik.values.permissions

  const handleAccessLevelSelectionChange = (level) => {
    let filterData = formik.values.permissions.map((data) => {
      data.accessMode = level
      return data
    })

    formik.setValues((v) => {
      return {
        ...v,
        permissions: [...filterData],
      }
    })
  }

  const isAllModulesSelected = (level) => {
    const selectedModules = formik.values.permissions.map(data => data?.accessMode)
    return selectedModules.every(lvl => lvl === level)
  }

  return (
    <div className="table-responsive-xl">
      <table id="id" className="table table-hover">
        <thead
          className="table-active text-center"
          style={{ position: 'relative' }}
        >
          <tr className="sticky">
            <th
              style={{
                width: '50px',
                verticalAlign: 'middle',
              }}
              className="py-4"
            >
              {/* <div className="hvr-pulse1">
                <button
                  type="button"
                  className="btn btn-outline-secondary side-btn"
                >
                  <i className="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              </div> */}
            </th>
            <th className="text-left" style={{ minWidth: '120px' }}>
              <a className="text-decoration-none"></a>
            </th>
            {AccesLevels?.map((level) => (
              <th key={level} style={{ minWidth: '200px' }}>
                <div className="d-flex justify-content-center">{level === 'ORGANISATION_ACCESS' ? 'ORGANZATION_ACCESS': level}</div>

                <div className="text-center  mt-2">
                  <input
                    name="level"
                    type="radio"
                    checked={isAllModulesSelected(level)}
                    onChange={() => handleAccessLevelSelectionChange(level)}
                  />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permissionObj, index) => (
            <AccessLevelRow
              key={index}
              AccesLevels={AccesLevels}
              permissionObj={permissionObj}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AccessLevel
