import React, { useState, useEffect } from 'react'
import Table from '../../components/common/Table'
import { getPagedData } from '../../services/helperService'

function TrashList({ data, selectedFilterId, typeArr }) {
  const [list, setList] = useState(data)

  useEffect(() => {
    let updatedList = data
    // if (selectedFilterId !== 'All') {
    //   updatedList = data.filter(function(details) {
    //     let index = typeArr.indexOf(selectedFilterId) + 1 + ''
    //     return details.moduleName === index
    //   })
    // }

    setList(updatedList)
  }, [selectedFilterId, data])

  const columns = [
    { path: 'time', label: ' Time ' },
    { path: 'name', label: ' Name ' },
    { path: 'type', label: ' Type ' },
    { path: 'deletdBy', label: ' Deleted By ' },
    { path: 'action', label: 'Action ' },
  ]

  const [sortColumn, setSortColumn] = useState(null)

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      sortColumn,
      columns,
    )
  }

  const handleSort = (sortColumn) => {
    /*setSortColumn(sortColumn);
    getPageData();*/
  }

  return (
    <Table data={list}
           columns={columns}
           onSort={handleSort}
           hideCheckbox={true}
           sortColumn={sortColumn}
    />
  )
}

export default TrashList
