import { ErrorMessage } from 'formik'
import moment from 'moment'
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import DatePickerField from '../../components/form/DatePickerField'
import TextInput from '../../components/form/TextInput'
import { getAddressDetails } from '../../services/helperService'

function UserDetailsForm({ values, setFieldValue, setFieldTouched }) {
  const handlePincodeChange = async (e) => {
    if (e.target.value !== '') {
      try {
        const {
          data: {
            data: { data: data },
          },
        } = await getAddressDetails(e.target.value)
        setFieldValue('userDetails.state', data?.state_fullname)
        setFieldValue('userDetails.city', data?.city)
        setFieldValue('userDetails.country', 'US')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  return (
    <div>
      <div className="border-bottom h6 py-2 mb-4">
        <span className="text-primery font-weight-bold">Employee Details</span>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <TextInput
            withAsterisk
            name={`userDetails.firstName`}
            label="First Name"
          />
        </div>
        <div className="form-group col-md-6">
          <TextInput
            withAsterisk
            name={`userDetails.lastName`}
            label="Last Name"
          />
        </div>
        <div className="form-group col-md-6">
          <TextInput
            withAsterisk
            label="Email"
            name="userDetails.email"
            type="email"
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor='userDetails.contactNumber'>
            Contact Number <span className="text-danger">*</span>
          </label>
          <PhoneInput
            data-testid="input-userDetails.contactNumber"
            id="userDetails.contactNumber"
            // displayInitialValueAsLocalNumber={true}
            limitMaxLength={true}
            value={values?.userDetails?.contactNumber || ''}
            name="userDetails.contactNumber"
            defaultCountry="US"
            international={false}
            className={'form-control'}
            placeholder="Contact Number"
            onChange={(phone) => {
              if (phone) {
                setFieldValue('userDetails.contactNumber', phone || '')
              } else {
                setFieldValue('userDetails.contactNumber', '')
              }
            }}
            onBlur={() => {
              setFieldTouched('userDetails.contactNumber', true)              
            }}
          />
          <ErrorMessage
            data-testid="error-message-userDetails.contactNumber"
            className="text-danger"
            name="userDetails.contactNumber"
            component="div"
          />
          {/* <TextInput
            isPhone
            label="Contact Number"
            name="userDetails.contactNumber"
            onPhoneChange={(phone) => {
              console.log(
                'test phone===<<',
                phone.length,
                phone,
                values?.userDetails?.contactNumber,
              )
              if (phone.length <= 2) {
                console.log("inside");
                setFieldValue('userDetails.contactNumber', '')
              } else setFieldValue('userDetails.contactNumber', phone || '')
            }}
            value={values?.userDetails?.contactNumber || ''}
          /> */}
        </div>
        <div className="form-group col-md-6">
          <DatePickerField
            name="userDetails.dateOfBirth"
            label="Date of Birth"
            minDate={moment().subtract(100, 'years').toDate()}
            maxDate={new Date()}
          />
        </div>
        <div className="form-group col-md-6">
          <TextInput label="FICO Score" name="userDetails.ficoScore" />
        </div>
        <div className="form-group col-md-6">
          <TextInput label="Job Position/Title" name="userDetails.jobTitle" />
        </div>
        <div className="form-group col-md-6">
          <TextInput
            label="Station Code/ Job Site"
            name="userDetails.jobSite"
          />
        </div>
        <div className="form-group col-md-12">
          <TextInput label="Address" name="userDetails.address" />
        </div>
        <div className="form-group col-md-6">
          <TextInput
            label="Zip"
            name="userDetails.zip"
            type="number"
            onBlur={handlePincodeChange}
          />
        </div>
        <div className="form-group col-md-6">
          <TextInput label="City" name="userDetails.city" />
        </div>
        <div className="form-group col-md-6">
          <TextInput label="State" name="userDetails.state" />
        </div>
        <div className="form-group col-md-6">
          <TextInput label="Country" name="userDetails.country" />
        </div>
      </div>
    </div>
  )
}

export default UserDetailsForm
