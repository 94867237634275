export const ReportedViaTypes = [
  {
    value: 'Email',
    key: 'Email',
    label: 'Email',
  },
  {
    label: 'Phone',
    value: 'Phone',
    key: 'Phone',
  },
  {
    value: 'Sms',
    label: 'Sms',
    key: 'Sms',
  },
  {
    value: 'Public Form',
    key: 'Public Form',
    label: 'Public Form',
  },
  {
    value: 'Self',
    key: 'Self',
    label: 'Self',
  },
]

export const allPolicyTypes = [
  {
    value: 'WC',
    key: 'WC',
    label: 'WC',
  },
  {
    value: 'GL',
    key: 'GL',
    label: 'GL',
  },
  {
    value: 'Auto',
    key: 'Auto',
    label: 'Auto',
  },
  {
    value: 'Property',
    key: 'Property',
    label: 'Property',
  },
]
export const allClaimStatusTypes = [
  {
    value: 'Open',
    key: 'Open',
    label: 'Open',
  },
  {
    value: 'Closed',
    key: 'Closed',
    label: 'Closed',
  },
]

export const allPriorityStatusTypes = [
  {
    value: 'Low',
    key: 'Low',
    label: 'Low',
  },
  {
    value: 'Medium',
    key: 'Medium',
    label: 'Medium',
  },
  {
    value: 'High',
    key: 'High',
    label: 'High',
  },
]

export const allCoverageDescription = [
  {
    value: 'Collision',
    key: 'collision',
    label: 'Collision',
  },
  {
    value: 'Bodily Injury',
    key: 'bodily_injury',
    label: 'Bodily Injury',
  },
  {
    value: 'Hit & Run',
    key: 'hit_run',
    label: 'Hit & Run',
  },
  {
    value: 'Property Damage',
    key: 'property_damage',
    label: 'Property Damage',
  },
  {
    value: 'Comprehensive',
    key: 'comprehensive',
  },
  {
    value: 'No Fault',
    key: 'no_fault',
    label: 'Comprehensive',
  },
  {
    value: 'Indemnity',
    key: 'indemnity',
    label: 'Indemnity',
  },
  {
    value: 'OTHERS',
    key: 'OTHERS',
    label: 'OTHERS',
  },
]

export const notificationMethod = [
  {
    value: 'Call',
    key: 'Call',
  },
  {
    value: 'Email',
    key: 'Email',
  },
  {
    value: 'Text',
    key: 'Text',
  },
  {
    value: 'In Person',
    key: 'In Person',
  },
]

// pain level types array
export const painLevelTypes = [
  '0 No Pain',
  '1-3 Mild',
  '4-7 Moderate',
  '7-9 Severe',
  '10 ICU',
]

export const loadoutShift = [
  { label: 'First', key: 'First', value: 'First' },
  { label: 'Second', key: 'Second', value: 'Second' },
  { label: 'Third', key: 'Third', value: 'Third' },
  { label: 'Fourth', key: 'Fourth', value: 'Fourth' },
  { label: 'Fifth', key: 'Fifth', value: 'Fifth' },
]

export function addComma(num) {
  const decimalRegex = /.+\.([0-9])*$/

  if (decimalRegex.test(num.toString())) {
    const [number, decimal] = num.toString().split('.')
    const formattedNumber = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    }).format(number)
    return `${formattedNumber}.${decimal}`
  }
  const number = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  }).format(num)
  return number
}

export function addCommaTableField(num) {
  const number = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  }).format(num)
  return number
}

export function transformPhoneNumber(rawNum = '') {
  if (rawNum) {
    const areaCodeStr = rawNum.slice(2, 5)
    const midSectionStr = rawNum.slice(5, 8)
    const lastSectionStr = rawNum.slice(8)

    return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`
  }
  return ''
}

export function convertBase64ImageToFile(base64Img) {
  // Split the base64 string into header and data parts
  const parts = base64Img.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const data = atob(parts[1])
  // Create an ArrayBuffer from the decoded data
  const arrayBuffer = new ArrayBuffer(data.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < data.length; i++) {
    uint8Array[i] = data.charCodeAt(i)
  }
  // Create a Blob from the ArrayBuffer
  const blob = new Blob(
    [arrayBuffer],
    { type: contentType },
  )
  // Create a file object from the Blob
  return new File([blob], 'image.png')
}


export const vehicleTypeOptions = [
  {
    label: 'Company',
    value: 'company'
  },
  {
    label: '3rd Party',
    value: 'thirdParty'
  }
]

export const vehicleDamageOptions = [
  { label: 'Driver side Panel', value: 'driver-side-panel' },
  { label: 'Passenger side Panel', value: 'passenger-side-panel' },
  { label: 'Passenger side Panel Glass', value: 'passenger-side-panel-glass' },
  { label: 'Tail Gate', value: 'tailgate' },
  { label: 'Driver side Backlight Upper side', value: 'driver-side-backlight-upperside' },
  { label: 'Passenger side Backlight Upper side', value: 'passenger-side-backlight-upperside' },
  { label: 'Driver side Front Door', value: 'driver-side-front-door' },
  { label: 'Hood', value: 'hood' },
  { label: 'Front Glass', value: 'front-glass' },
  { label: 'Roof', value: 'roof' },
  { label: 'Driver side Front Door Mirror', value: 'driver-side-front-door-mirror' },
  { label: 'Passenger side Head Light', value: 'passenger-side-head-light' },
  { label: 'Back Mirror', value: 'back-mirror' },
  { label: 'Passenger side Door Mirror', value: 'passenger-side-door-mirror' },
  { label: 'Driver side panel glass', value: 'driver-side-panel-glass' },
  { label: 'Driver Side Mirror', value: 'driver-side-mirror' },
  { label: 'Passenger Side Mirror', value: 'passenger-side-mirror' },
  { label: 'Driver side Head Light', value: 'driver-side-head-light' },
  { label: 'Driver side Back light', value: 'driver-side-back-light' },
  { label: 'Passenger side Back light', value: 'passenger-side-back-light' },
  { label: 'Front Bumper', value: 'front-bumper' },
  { label: 'Driver side front fender', value: 'driver-side-front-fender' },
  { label: 'Passenger side front fender', value: 'passenger-side-front-fender' },
  { label: 'Front Upper Panel', value: 'front-upper-panel' },
]