import React, { useState } from 'react'
import { getPreSignedUrl, uploadFile } from '../../services/helperService'

function ImageUpload({ afterUpload, src, rowStyle, imageStyle,type }) {
  const [localImage, setLocalImage] = useState(null)
  const handleFile = async (event) => {
    const { files } = event.target
    const reader = new FileReader()
    reader.onload = function (event) {
      setLocalImage(event.target.result)
    }
    reader.readAsDataURL(files[0])
    const fileToUpload = {
      key: files[0].name,
      contentType: files[0].type,
    }
    const { data: urls } = await getPreSignedUrl({ files: [fileToUpload] },type)
    afterUpload(urls.data.map((u) => u.objectUrl))
    await uploadFiles(files, urls)
    event.target.value = null
  }

  const uploadFiles = async (files, urls) => {
    const promiseArray = []
    const filesArray = Array.from(files)
    for (let i = 0; i < filesArray.length; i++) {
      const myHeaders = new Headers()
      myHeaders.append('ContentType', filesArray[i].type)
      const resp = uploadFile(
        urls.data[i].preSignedUrl,
        filesArray[i],
        myHeaders,
      )
      promiseArray.push(resp)
    }
    await Promise.all(promiseArray)
  }

  return (
    <div className="container">
      <div className="row" style={rowStyle}>
        <div className="circle">
          <div className="picture-container">
            <div className="picture">
              <img
                className="profile-pic"
                src={localImage || src || '/assets/images/user-profile.svg'}
                id="wizardPicturePreview"
                style={imageStyle}
              />
              <img src="/assets/images/cam.svg" alt="" className="cam-img" />
              <input
                onChange={handleFile}
                type="file"
                accept="image/*"
                id="wizard-picture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageUpload
