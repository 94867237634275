import { ErrorMessage, Field } from 'formik'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React from 'react'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextArea from '../../../components/form/TextArea'
import TextInput from '../../../components/form/TextInput'
import {
  addComma,
  allClaimStatusTypes,
  allCoverageDescription,
  claimTypes,
  yesNoOptions,
} from '../../incident/utils'

export default function IncidentDetailsView({
  values,
  setFieldValue,
  onNavigate,
}) {
  return (
    <>
      <div className="border-bottom h6 py-2 mb-3">Incidents Details</div>
      <div className="form-row">
        <div className="col-md-6">
          <DatePickerField
            withAsterisk
            name="incident.incidentDetails.dateOfIncident"
            label="Date of Loss"
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div>
              <label>Time of Incident</label>
            </div>
            <TimePicker
              use12Hours
              className="antDOverride"
              name="incident.incidentDetails.time"
              onChange={(value) => {
                setFieldValue(
                  'incident.incidentDetails.time',
                  value.format('HH:mm:ss A'),
                )
              }}
              format="hh:mm:ss A"
              value={moment(
                values?.incident?.incidentDetails?.time || '00:00:00',
                'HH:mm:ss A',
              )}
              defaultValue={moment('00:00:00', 'HH:mm:ss A')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <TextInput label="POC Name" name="incident.incidentDetails.pocName" />
        </div>
        <div className="col-md-6">
          <TextInput
            isPhone
            changeOnUndefined={true}
            label="POC Number"
            name="incident.incidentDetails.pocNumber"
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('incident.incidentDetails.pocNumber', phone || '')
            }}
            value={values?.incident?.incidentDetails?.pocNumber || ''}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="POC Email"
            name="incident.incidentDetails.pocEmail"
            type="email"
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Package Number" name="incident.packageNumber" />
        </div>
        <div className="col-md-6">
          {/* <TextInput label="Type of Claim" name="claimType" /> */}
          <Select
            label="Type of Claim"
            name="incident.claimType"
            defaultLabel="Select claim type"
            options={claimTypes}
            value={values.claimType}
          />
        </div>
        <div className="col-md-6">
          <Select
            withAsterisk
            label="Claim Status"
            name="claimStatus"
            defaultLabel="Select status"
            options={allClaimStatusTypes}
            value={values?.claimStatus}
            // showErrorEvenWhenUntouched={true}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Claim Specialist"
            name="incident.incidentDetails.incidentHandledBy"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Entered By"
            name="incident.incidentDetails.enteredBy"
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            withAsterisk
            showErrorEvenWhenUntouched
            label="Location where the incident happened"
            name="incident.incidentDetails.location"
            placeholder="i.e.,inside hub, vehicle, hub parking, customer’s specific address, etc."
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            showErrorEvenWhenUntouched
            name="incident.incidentDetails.employeeActivity"
            label="What was employee doing when the incident happened"
            placeholder="i.e.,lifting, driving, pushing, delivering, etc."
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>
            Has Third Party left or refused to give info ? (If yes, make a
            police report)
          </label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incident.incidentDetails.thirdPartyLeaves`}
                value={'true'}
                id="thirdPartyLeavesYes"
              />
              <label className="mt-2" htmlFor="thirdPartyLeavesYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incident.incidentDetails.thirdPartyLeaves`}
                value={'false'}
                id="thirdPartyLeavesNo"
              />
              <label className="mt-2" htmlFor="thirdPartyLeavesNo">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Was there a Police Report taken for this incident?</label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incident.incidentDetails.policeCalled`}
                value={'true'}
                id="policeReportYes"
              />
              <label className="mt-2" htmlFor="policeReportYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incident.incidentDetails.policeCalled`}
                value={'false'}
                id="policeReportNo"
              />
              <label className="mt-2" htmlFor="policeReportNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incident.incidentDetails.policeCalled`}
            component="div"
          />
        </div>
        {values?.incident?.incidentDetails?.policeCalled === 'true' && (
          <>
            <div className="form-group col-md-6">
              <TextInput
                name={`incident.incidentDetails.policeReportDetails.policeReportNumber`}
                label="Report Number"
              />
            </div>
            <div className="form-group col-md-6">
              <TextInput
                label="Jurisdiction (County)"
                name="incident.incidentDetails.policeReportDetails.policeReportcountry"
              />
            </div>
          </>
        )}
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            name="incident.incidentDetails.driverStatement"
            label="Driver statement"
            withAsterisk
            showErrorEvenWhenUntouched
            placeholder="Driver statement"
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            label="Unsafe conditions"
            name="incident.incidentDetails.unsafeCondition"
            placeholder="i.e.,Icy, wet, snow roads, uneven pavement, etc."
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Coverage Description"
            name="incident.coverageDescription"
            defaultLabel="Select Description"
            options={allCoverageDescription}
            value={values?.incident?.coverageDescription}
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Subrogation" name="incident.subrogation" />
        </div>
        <div className="col-md-12">
          <div className="form-group m-0">
            <label>Amount Paid (As of Date)</label>
            <input
              className="form-control"
              label="Amount Paid"
              name="incident.amountPaidTillDate.amount"
              placeholder="Amount"
              type="text"
              value={
                values?.incident?.amountPaidTillDate?.amount
                  ? addComma(values?.incident?.amountPaidTillDate?.amount)
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value
                const onlyNums = value.replaceAll(',', '')
                if (!isNaN(Number(onlyNums))) {
                  setFieldValue('incident.amountPaidTillDate.amount', onlyNums)
                }
              }}
            />
          </div>
          <DatePickerField name="incident.amountPaidTillDate.date" />
        </div>
        <div className="col-md-12">
          <div className="form-group m-0">
            <label>Amount Incurred (As of Date)</label>
            <input
              className="form-control"
              label="Amount Incurred"
              name="incident.amountIncurredTillDate.amount"
              type="text"
              placeholder="Amount"
              value={
                values?.incident?.amountIncurredTillDate?.amount
                  ? addComma(values?.incident?.amountIncurredTillDate?.amount)
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value
                const onlyNums = value.replaceAll(',', '')
                if (!isNaN(Number(onlyNums))) {
                  setFieldValue(
                    'incident.amountIncurredTillDate.amount',
                    onlyNums,
                  )
                }
              }}
            />
          </div>
          <DatePickerField name="incident.amountIncurredTillDate.date" />
        </div>
        <div className="form-group col-md-12">
          <label>Whether occurred on company premises?</label>
          <div className="form-group d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                onChange={() => {
                  setFieldValue(
                    'incident.incidentDetails.companyPremises',
                    true,
                  )
                  setFieldValue(`incident.incidentProperty`, null)
                }}
                value={true}
                name="incident.incidentDetails.companyPremises"
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                onChange={() => {
                  setFieldValue(
                    'incident.incidentDetails.companyPremises',
                    false,
                  )
                  setFieldValue(`incident.incidentProperty`, null)
                }}
                value={false}
                name="incident.incidentDetails.companyPremises"
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Select
            label="Triage"
            name="incident.triage"
            defaultLabel="Select triage"
            options={yesNoOptions}
            value={values?.incident?.triage}
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Gensuite ID" name="incident.gensuiteID" />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="incident.dateReportedToDSP"
            label="Date Reported to DSP"
          />
        </div>
        <div className="col-md-6">
          <Select
            label="OSHA Recordable"
            name="incident.oshaRecordable"
            defaultLabel="Select Option"
            options={yesNoOptions}
            value={values?.incident?.oshaRecordable}
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            label="Recordability Rationale?"
            name="incident.recordabilityRational"
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            withAsterisk
            name="incident.returnToWorkDate"
            label="Return to Work Date"
          />
        </div>
      </div>
    </>
  )
}
