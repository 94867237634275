/* eslint-disable react/prop-types */
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { addComma } from '../pages/incident/utils'
import { TRANSACTION_VALIDATION_SCHEMA } from '../utils/validation'
import DatePickerField from './form/DatePickerField'

function AddTransactionModalBtn(props) {
  const { buttonLabel, className } = props
  const [modal, setModal] = useState(false)
  const init = () => {
    return { amount: '', transactionDate: null }
  }
  const toggle = () => setModal(!modal)
  const handleSubmit = (val) => {
    toggle()
    props.modalClose(val)
  }

  return (
    <div>
      <button
        data-testid={`${props.testId || 'add-transaction'}-btn`}
        type="button"
        className="btn btn-outline-secondary side-btn mt-2"
        id="#exampleModal23"
        data-toggle="modal"
        data-target="#exampleModal23"
        onClick={toggle}
      >
        {buttonLabel}
      </button>
      <Modal
        data-testid={`${props.testId || 'add-transaction'}-modal`}
        isOpen={modal}
        backdrop="static"
        toggle={toggle}
        className={className}
      >
        <div className="modal-header border-0">
          <h4
            className="modal-title"
            id="modal-basic-title"
            data-testid={`${props.testId || 'add-transaction'}-title`}
          >
            {props.heading}
          </h4>
        </div>
        <ModalBody className="modal-body pt-0">
          <Formik
            enableReinitialize
            validationSchema={TRANSACTION_VALIDATION_SCHEMA()}
            initialValues={{ ...init(), ...props.values }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <div className="form-group mb-0">
                  <div className="input-group">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group input-dollar">
                          <label>Amount</label>
                          <span className="pr-4">$</span>
                          <input
                            className="form-control dollar-text"
                            label="Amount"
                            name="amount"
                            type="text"
                            value={
                              values?.amount ? addComma(values?.amount) : ''
                            }
                            onChange={(e) => {
                              const value = e.target.value
                              const onlyNums = value.replaceAll(',', '')
                              if (!isNaN(onlyNums)) {
                                setFieldValue('amount', onlyNums)
                              }
                            }}
                          />
                          <ErrorMessage
                            className="text-danger"
                            name="amount"
                            component="div"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <DatePickerField
                          name="transactionDate"
                          label={props.dateLabel || 'Date'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-start p-0 border-0">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary  add-btn"
                  >
                    Add
                  </button>
                  <button
                    data-testid={`${props.testId || 'add-transaction'}-cancel`}
                    onClick={toggle}
                    type="button"
                    className="btn btn-light pl-4 pr-4 cls-btn"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddTransactionModalBtn
