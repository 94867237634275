import {createStore} from "redux";
import rootReducer from "./reducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import httpService from "./services/httpService";

const store = createStore(rootReducer, composeWithDevTools(
    // other store enhancers if any
));

httpService?.setDefaultHeaders({authorization:store.getState().token})
export default store;