import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Select1 from 'react-select'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import AttachmentAddView from '../../../components/AttachmentAddView'

// import AddAttachment from '../../../components/addAttachment'
import AreYouSureModal from '../../../components/common/AreYouSureModal'
import AddressInput from '../../../components/form/AddressInput'
import DatePickerField from '../../../components/form/DatePickerField'
import EmergencyContactInput from '../../../components/form/EmergencyContactInput'
import ImageUpload from '../../../components/form/ImageUpload'
import Select from '../../../components/form/Select'
import TextInput from '../../../components/form/TextInput'
import {
  CompensationTypes,
  employeeStatusOptions,
  genderOptions,
  LicenseStatus,
  martialOptions,
  stateOptions,
} from '../../../configs/utils'
import { saveEmployee, updateEmployee } from '../../../services/employeeService'
import { getPoliciesByType } from '../../../services/policyService'
import { generateUID } from '../../../utils/generateUID'
import FormikSelect from '../../claims/DirectClaim/FormikSelect'
import { addComma } from '../../incident/utils'
import { isValidPhoneNumber } from 'react-phone-number-input'
import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'

const newEmployee = {
  image: '',
  firstName: '',
  lastName: '',
  ssnCode: '',
  dateOfBirth: null,
  employeeId: generateUID('employee'),
  address: {
    state: '',
    city: '',
    country: '',
    line1: '',
    line2: '',
    pinCode: '',
  },
  officePhone: '',
  personalPhone: '',
  email: '',
  maritalStatus: '',
  gender: '',
  emergencyContact: {
    name: '',
    phoneNumber: '',
    relation: '',
  },
  occupation: '',
  hireDate: null,
  isDriver: false,
  liscense: {
    issueDate: null,
    expiryDate: null,
    status: '',
  },
  driverDetails: {
    driverLicense: '',
    state: '',
  },
  terminationDetails: {
    terminatedOn: null,
    reason: '',
  },
  status: '',
  policyDetails: {},
  attachments: [],
}

function EmployeeForm({ data, title, onClose }) {
  const ref = useRef()
  const mountedRef = useRef(false)
  const [isNew, setIsNew] = useState(false)
  const [ePolicies, setEPolicies] = useState([])
  const [filterPolicies, setFilterPolicies] = useState([])
  const [values, setValues] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [clientId, setClientId] = useState('')
  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const closeModal = () => setOpenModal(false)

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])

  useEffect(() => {
    let policyListByClient = ePolicies.filter(
      (a) => a.clientId === values.clientId || a.clientId === selectedClientId || a.clientId === clientId,
    )
    setFilterPolicies([...policyListByClient])
  }, [selectedClientId, values?.clientId, clientId])

  const EmployeeValidationSchema = yup.object({
    clientId: yup.string().when('$exist', {
      is: () => !isClientExist,
      then: yup.string().required('Client is required!'),
      otherwise: yup.string(),
    }),
    personalPhone: yup.string().trim().test({
      test: (value) => {
        if (value && value !== "") {
          return isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          })
        }
        return true
      },
      message: "Please enter a valid US number"
    }),
    officePhone: yup.string().trim().test({
      test: (value) => {
        if (value && value !== "") {
          return isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          })
        }
        return true
      },
      message: "Please enter a valid US number"
    }),
    emergencyContact: yup.object({
      phoneNumber: yup.string().trim().test({
        test: (value) => {
          if (value && value !== "") {
            return isValidPhoneNumber(value ?? "", {
              defaultCountry: "US",
              defaultCallingCode: "+1"
            })
          }
          return true
        },
        message: "Please enter a valid US number"
      })
    }),
    firstName: yup.string('').required('First Name is required!'),
    lastName: yup.string('').required('Last Name is required!'),
    maritalStatus: yup.string('').required('Marital Status is required!'),
    gender: yup.string('').required('Gender is required!'),
    hireDate: yup.date().typeError("Please select a valid Hire Date").required(),
    status: yup.string('').required('Status is required!'),
    ssnCode: yup.string('').required('SSN Code is required!'),
    dateOfBirth :  yup.date().nullable(),
    liscense: yup.object().shape({
      issueDate :  yup.date().nullable(),
      expiryDate :  yup.date().nullable(),
    }),
    terminationDetails: yup.object().shape({
      terminatedOn :  yup.date().nullable(),
    }),
  })
  const initialValues = data
    ? {
      ...data,
      personalPhone: data.personalPhone ? data.personalPhone : '',
      officePhone: data.officePhone ? data.officePhone : '',
    }
    : { ...newEmployee }
  useEffect(() => {
    mountedRef.current = true
    if (data) {
      setIsNew(false)
      setValues({
        ...data,
        personalPhone: data.personalPhone ? data.personalPhone : '',
        officePhone: data.officePhone ? data.officePhone : '',
      })
    } else {
      setIsNew(true)
      const emp = { ...newEmployee }
      if (!isClientExist) {
        emp['clientId'] = ''
      }
      setValues(emp)
    }
    fetchPolicies()
    return () => {
      mountedRef.current = false
    }
  }, [isNew, isClientExist])

  const fetchPolicies = async () => {
    const { data: policies } = await getPoliciesByType('WC')
    if (!mountedRef.current) return null
    setEPolicies(
      policies.data.map((p) => ({ policyId: p._id, policyName: p.policyId, clientId: p.clientId })),
    )
  }

  const handleSubmit = async (values) => {
    if (values && values?.status !== 'TERMINATED') {
      values.terminationDetails = {
        terminatedOn: null,
        reason: '',
      }
    }
    setValues({
      ...values,
      dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format(NO_TIMEZONE_DATE_FORMAT) : null,
      dateOfJoining: values.hireDate ? moment(values.hireDate).format(NO_TIMEZONE_DATE_FORMAT) : null,
      hireDate: values.hireDate ? moment(values.hireDate).format(NO_TIMEZONE_DATE_FORMAT) : null,
      terminationDetails: {
        ...values.terminationDetails,
        terminatedOn: values.terminationDetails?.terminatedOn
          ? moment(values.terminationDetails.terminatedOn).format(NO_TIMEZONE_DATE_FORMAT)
          : null,
      },
      liscense: {
        ...(values.liscense ?? {}),
        issueDate: values.liscense?.issueDate
          ? moment(values.liscense.issueDate).format(NO_TIMEZONE_DATE_FORMAT)
          : null,
        expiryDate: values.liscense?.expiryDate
          ? moment(values.liscense.expiryDate).format(NO_TIMEZONE_DATE_FORMAT)
          : null,
      },
      rehireDate: values.rehireDate ? moment(values.rehireDate).format(NO_TIMEZONE_DATE_FORMAT) : null
    })
    setOpenModal(true)
  }

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      if (isNew) {
        await saveEmployee(values)
        onClose()
        toast.success('Employee Added!')
      } else {
        await updateEmployee(values._id, values)
        onClose()
        toast.success('Employee Updated!')
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <Formik
      validationSchema={EmployeeValidationSchema}
      innerRef={ref}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values: mainValues, setFieldValue, errors, handleBlur }) => (
        <Form className="indent-form">
          <div className="row m-0">
            <div
              className="col-7 p-4"
              style={{ height: '100vh', overflow: 'auto' }}
            >
              <h2
                className="modal-title text-primary mb-4"
                id="exampleModalLabel"
              >
                {title}
              </h2>
              <div className="border-bottom h6 py-2 mb-3">Employee Details</div>
              <div className="col-12 p-0">
                <div className="col-12 px-0 pt-4">
                  <div className="col-12 col-md-12 col-lg-12 p-0">
                    <div className="row">
                      {isClientExist ? null : (
                        <div className="col-md-12 mb-2">
                          <FormikSelect
                            label="Select Client"
                            name="clientId"
                            defaultLabel="Select Client"
                            options={getClientDropdownOpt()}
                            value={mainValues?.clientId || ''}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            handleBlur={handleBlur}
                            setClientId={setClientId}
                          />
                        </div>
                      )}
                      <div className="col-md-6">
                        <TextInput label="First Name" name="firstName" />
                      </div>
                      <div className="col-md-6">
                        <TextInput label="Last Name" name="lastName" />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label="Social Security Number"
                          name="ssnCode"
                        />
                      </div>
                      <div className="col-md-6">
                        <DatePickerField
                          name="dateOfBirth"
                          label="Date of Birth"
                        />
                      </div>
                      <div className="col-md-6 ">
                        <TextInput label="Employee Id" name="employeeId" />
                      </div>
                      <div className="col-md-6">
                        <TextInput label="Email" name="email" />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          isPhone
                          changeOnUndefined={true}
                          label="Phone (Personal)"
                          name="personalPhone"
                          maxLength={14}
                          onPhoneChange={(phone) =>
                            setFieldValue('personalPhone', phone)
                          }
                          value={mainValues?.personalPhone || ''}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          isPhone
                          changeOnUndefined={true}
                          label="Phone (Work)"
                          name="officePhone"
                          maxLength={14}
                          onPhoneChange={(phone) =>
                            setFieldValue('officePhone', phone)
                          }
                          value={mainValues?.officePhone || ''}
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          label="Marital Status"
                          name="maritalStatus"
                          defaultLabel="Select Status"
                          options={martialOptions}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>WC Details</label>
                          <Select1
                            placeholder="Select Insurance"
                            getOptionLabel={(option) => option.policyName}
                            getOptionValue={(option) => option}
                            name="policyDetails"
                            value={mainValues.policyDetails}
                            isSearchable="true"
                            onChange={(val) =>
                              setFieldValue('policyDetails', val)
                            }
                            options={filterPolicies}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-md-12">
                        <p>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary text-white"
                          >
                            Save &amp; Close
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {mainValues && (
              <div className="col-md-5 pt-3 modal-right-scroll">
                <div className="col-12 p-0">
                  <div className={'row'}>
                    <div className="col-md-6">
                      <label className="incident-text">
                        Employee ID
                        <span className="text-primery">
                          {mainValues ? mainValues.employeeId : '-'}
                          <b className="text-dark font-weight-normal ml-2">
                            {' '}
                            (System Generated)
                          </b>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <ImageUpload
                        type="employee"
                        src={mainValues.image}
                        rowStyle={{ float: 'right' }}
                        imageStyle={{ height: '62px', width: '62px' }}
                        afterUpload={(val) => {
                          setFieldValue('image', val[0])
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <DatePickerField name="hireDate" label="Hire Date" />
                    </div>
                    <div className="col-md-6">
                      <Select
                        label="Employee Status"
                        name="status"
                        defaultLabel="Select Status"
                        options={employeeStatusOptions}
                      />
                    </div>
                    {mainValues.status == 'TERMINATED' && (
                      <>
                        <div className="col-md-12">
                          <DatePickerField
                            name="terminationDetails.terminatedOn"
                            label="Date of Termination"
                          />
                        </div>
                        <div className="col-md-12">
                          <TextInput
                            label="Reason For Termination"
                            name="terminationDetails.reason"
                          />
                        </div>
                      </>
                    )}
                    <div className="col-md-6">
                      <Select
                        label="Compensation"
                        name="compensation"
                        defaultLabel="Select Type"
                        options={CompensationTypes}
                      />
                    </div>
                    {mainValues?.compensation && (
                      <div className="col-md-6">
                        <div className="form-group ">
                          <label>Amount</label>
                          <input
                            className="form-control"
                            label="Amount"
                            name="amount"
                            type="text"
                            value={
                              mainValues?.amount
                                ? addComma(mainValues?.amount)
                                : ''
                            }
                            onChange={(e) => {
                              const value = e.target.value
                              const onlyNums = value.replaceAll(',', '')
                              setFieldValue('amount', onlyNums)
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label>Annual Salary</label>
                        <input
                          className="form-control"
                          label="Annual Salary"
                          name="annualSalary"
                          type="text"
                          value={
                            mainValues?.annualSalary
                              ? addComma(mainValues?.annualSalary)
                              : ''
                          }
                          onChange={(e) => {
                            const value = e.target.value
                            const onlyNums = value.replaceAll(',', '')
                            setFieldValue('annualSalary', onlyNums)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <DatePickerField name="rehireDate" label="Rehire Date" />
                    </div>
                    <div className="col-md-6">
                      <Select
                        label="MVR"
                        name="mvr"
                        defaultLabel="Select Option"
                        options={[
                          { value: 'Yes', key: 'yes' },
                          { value: 'Required', key: 'required' },
                          { value: 'Not Applicable', key: 'not_applicable' },
                        ]}
                      />
                    </div>
                    <div className="col-md-12">
                      <Select
                        label="Trained on incident reporting"
                        name="incidentReporting"
                        defaultLabel="Select Option"
                        options={[
                          { value: 'Yes', key: 'yes' },
                          { value: 'No', key: 'no' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        label="Is Driver?"
                        name="isDriver"
                        defaultLabel="Select Option"
                        options={[
                          { value: 'Yes', key: 'true' },
                          { value: 'No', key: 'false' },
                        ]}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        label="Gender"
                        name="gender"
                        defaultLabel="Select Gender"
                        options={genderOptions}
                      />
                    </div>
                  </div>
                  {(mainValues.isDriver == 'true' ||
                    mainValues.isDriver == true) && (
                      <>
                        <div className="row m-0">
                          <div className="col-md-6">
                            <TextInput
                              label="License"
                              name="driverDetails.driverLicense"
                            />
                          </div>
                          <div className="col-md-6">
                            <Select
                              label="State"
                              name="driverDetails.state"
                              defaultLabel="Select State"
                              options={stateOptions}
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-6">
                            <DatePickerField
                              name="liscense.issueDate"
                              label="Issue Date"
                            />
                          </div>
                          <div className="col-md-6">
                            <DatePickerField
                              name="liscense.expiryDate"
                              label="Expiry Date"
                            />
                          </div>
                          <div className="col-md-6">
                            <Select
                              label="Status"
                              name="liscense.status"
                              defaultLabel="Select Option"
                              options={LicenseStatus}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group" style={{ marginBottom: 'unset' }}>
                    <AddressInput
                      label="Address"
                      name="address"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group" style={{ marginBottom: 'unset' }}>
                    <EmergencyContactInput
                      label="Emergency Contact"
                      name="emergencyContact"
                      setFieldValue={setFieldValue}
                      contact={mainValues?.emergencyContact || {}}
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group col-md-12">
                    <AttachmentAddView
                      formik={{
                        setFieldValue: setFieldValue,
                        values: mainValues,
                      }}
                      module={'employee'}
                      uid={mainValues.employeeId}
                    />
                    {/* <label className="d-block incident-text" htmlFor="package">
                      Attachments
                    </label>
                    <div className="row m-0">
                      {values.attachments &&
                        values.attachments.length > 0 &&
                        getAttachmentViews()}
                    </div>
                    {openAttachmentModal && (
                      <AddAttachment
                        module="employee"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                      data-toggle="modal"
                      data-target="#attachuplaods"
                      onClick={handleAddAttachmentToggle}
                    >
                      Add..
                    </button> */}
                  </div>
                </div>
              </div>
            )}
            <AreYouSureModal
              openModal={openModal}
              closeModal={closeModal}
              handleSubmit={handleSubmitModal}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EmployeeForm
