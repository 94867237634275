import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'reactstrap'
import _ from 'lodash'
import Select1 from 'react-select/creatable'
import { getProperties } from '../../services/propertyService'
import { Formik } from 'formik'
import AddressInput from '../../components/form/AddressInput'
let isLoading = true

function PropertyInvolvedModal({
  isOpen,
  onClose,
  values,
  setFieldValue,
  editData,
  editId,
  directClaim = false,
  clientId = '',
}) {
  const addressRef = useRef(true)
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
  })

  const [propertyDetails, setpropertyDetails] = useState({
    propertyType: '',
    propertyId: '',
    propId: '',
    name: '',
  })

  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState({})
  const [data, setdata] = useState({
    isThirdPartyPropertyDamaged: false,
    thirdPartyPropertyDetails: '',
    propertyDamages: [],
  })
  const [propertlist, setpropertlist] = useState([])
  const [filterPropertyList, setFilterPropertyList] = useState([])

  useEffect(() => {
    if (editData) {
      const { propertyType, propertyId, propId, name } =
        editData.propertyDetails
      setpropertyDetails({
        propertyType,
        propertyId,
        propId,
        name,
      })

      const { address } = editData.propertyDetails
      const { line1, line2, city, state, country, pinCode } = address
      setAddress({ line1, line2, city, state, country, pinCode })

      const {
        isThirdPartyPropertyDamaged,
        thirdPartyPropertyDetails,
        propertyDamages,
        otherpropertyDamages = '',
      } = editData

      setdata({
        isThirdPartyPropertyDamaged,
        thirdPartyPropertyDetails,
        propertyDamages: propertyDamages.map((prop) => {
          if (typeof prop === 'string') {
            return {
              label: prop,
              value: prop,
            }
          }
          return prop
        }),
        otherpropertyDamages,
      })
      if (!data.isThirdPartyPropertyDamaged) {
        let selectedPropertyDetails = {
          propertyType: editData.propertyDetails.propertyType,
          propertyId: editData.propertyDetails.propId,
          name: editData.propertyDetails.name,
        }

        setSelectedPropertyDetails(selectedPropertyDetails)
      }
    } else {
      let address = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        pinCode: '',
      }
      let propDetails = {
        propertyType: '',
        propertyId: 0,
        propId: '',
        name: '',
      }
      let data = {
        isThirdPartyPropertyDamaged: false,
        thirdPartyPropertyDetails: '',
        propertyDamages: [],
        otherpropertyDamages: '',
      }
      setdata(data)
      setAddress(address)
      setSelectedPropertyDetails({})
      setpropertyDetails(propDetails)
    }
    isLoading = false
  }, [values, editData])

  const getPropertiesData = async () => {
    const { data: propertlistData } = await getProperties()
    setpropertlist(propertlistData.data)
  }
  useEffect(() => {
    getPropertiesData()
  }, [])

  useEffect(async () => {
    let propertlistDataByClient = propertlist?.filter(
      (a) => a.clientId === values.clientId || a.clientId === clientId,
    )

    setFilterPropertyList([
      ...propertlistDataByClient,
      { _id: 'OTHERS', value: 'Other', key: 'Other' },
    ])
  }, [values?.clientId, clientId])

  const handleSave = () => {
    let propertyDamagedDetails = { ...propertyDetails }
    if (data.isThirdPartyPropertyDamaged) {
      delete propertyDamagedDetails.propertyId
    }
    const a = {
      ...data,
      propertyDetails: {
        ...propertyDamagedDetails,
        address: addressRef.current?.values?.address || {},
      },
    }

    const party =
      editId != null
        ? [...values?.propertiesInvolved.filter((a, i) => i != editId), a]
        : [...values.propertiesInvolved, a]

    if (directClaim) {
      setFieldValue('incident.propertiesInvolved', party)
    } else {
      setFieldValue('propertiesInvolved', party)
    }
    onClose()
  }

  const options = [
    { label: 'Door', value: 'Door' },
    { label: 'Front Wall', value: 'Front Wall' },
    { label: 'Back Wall', value: 'Back Wall' },
    { label: 'Side Wall', value: 'Side Wall' },
    { label: 'Glass', value: 'Glass' },
    { label: 'Others', value: 'Others' },
  ]

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      centered
      style={{
        height: '400px',
        position: 'absolute',
        top: '-23%',
        left: '31%',
        minWidth: '500px',
      }}
    >
      {!isLoading && (
        <div>
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title text-primary " id="">
                Add Property involved in the incident
              </h5>
            </div>
            <div className="modal-body p-0" id="smodaltab">
              <ul
                className="nav nav-pills bg-light d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active mt-0 font-weight-normal"
                    id="Person-tab"
                    data-toggle="pill"
                    href="#Person"
                    role="tab"
                    aria-controls="pills-Person"
                    aria-selected="true"
                  >
                    Property Details
                  </a>
                </li>
              </ul>

              <div style={{ height: '400px', overflowY: 'auto' }}>
                <div className="py-4 d-flex justify-content-center align-items-center">
                  <span className="mr-4">Company property damaged?</span>
                  <div
                    className="btn-group btn-group-toggle border btn-yesno"
                    data-toggle="buttons"
                  >
                    <label
                      className={
                        'btn px-3 ' +
                        (!data.isThirdPartyPropertyDamaged ? 'active' : '')
                      }
                      onClick={() =>
                        setdata((x) => ({
                          ...x,
                          isThirdPartyPropertyDamaged: false,
                        }))
                      }
                    >
                      <input
                        type="radio"
                        name="dd"
                        id="option6"
                        checked={!data.isThirdPartyPropertyDamaged}
                        readOnly
                      />{' '}
                      Yes
                    </label>
                    <label
                      className={
                        'btn px-3 ' +
                        (data.isThirdPartyPropertyDamaged ? 'active' : '')
                      }
                      onClick={() =>
                        setdata((x) => ({
                          ...x,
                          isThirdPartyPropertyDamaged: true,
                        }))
                      }
                    >
                      <input
                        type="radio"
                        name="dd"
                        id="option5"
                        checked={data.isThirdPartyPropertyDamaged}
                        readOnly
                      />{' '}
                      No
                    </label>
                  </div>
                </div>
                <div className="px-5">
                  <form>
                    <div className="form-row">
                      {data.isThirdPartyPropertyDamaged && (
                        <div className="form-group">
                          <div className="mb-2">
                            <label htmlFor="" className="col-form-label">
                              Property Name
                            </label>
                            <input
                              type="text"
                              label={'Property Name'}
                              name={'propertyName'}
                              placeholder={'Property Name'}
                              className="form-control"
                              onChange={(a) =>
                                setpropertyDetails((x) => ({
                                  ...x,
                                  name: a.target.value,
                                }))
                              }
                              value={propertyDetails.name}
                            />
                          </div>
                          <Formik
                            innerRef={addressRef}
                            enableReinitialize
                            initialValues={{ address }}
                          >
                            {({ setFieldValue }) => (
                              <AddressInput
                                label="Address"
                                name="address"
                                setFieldValue={setFieldValue}
                              />
                            )}
                          </Formik>
                          <div className="form-group col-md-12">
                            <label>Property Damage Details</label>
                            <Select1
                              isMulti
                              name={`propertyDamages`}
                              onChange={(val) => {
                                setdata((x) => ({
                                  ...x,
                                  propertyDamages: val,
                                }))
                              }}
                              options={options}
                              value={
                                data.propertyDamages.length > 0
                                  ? data.propertyDamages
                                  : []
                              }
                            />
                          </div>
                          {data.propertyDamages &&
                            data.propertyDamages.find(
                              (a) => a.value === 'Others',
                            ) && (
                              <div className="form-group">
                                <label>Other Details</label>
                                <input
                                  onChange={(a) =>
                                    setdata((x) => ({
                                      ...x,
                                      otherpropertyDamages: a.target.value,
                                    }))
                                  }
                                  label={'Other details'}
                                  name={'other'}
                                  type="text"
                                  className="form-control"
                                  value={data.otherpropertyDamages}
                                />
                              </div>
                            )}
                        </div>
                      )}
                      {!data.isThirdPartyPropertyDamaged && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div
                                className="col-md-12"
                                style={{ width: '420px' }}
                              >
                                <div className="form-group">
                                  <div className="form-group">
                                    <label>Select Property</label>
                                    <Select1
                                      placeholder="Select Property"
                                      getOptionLabel={(option) =>
                                        option.propertyId
                                          ? `${option.propertyId} - ${
                                              option.name
                                            } ${_.capitalize(
                                              option?.propertyType || '',
                                            )}`
                                          : option.value
                                      }
                                      getOptionValue={(option) => option}
                                      isSearchable="true"
                                      value={
                                        _.isEmpty(selectedPropertyDetails)
                                          ? null
                                          : selectedPropertyDetails
                                      }
                                      onChange={(val) => {
                                        setpropertyDetails((x) => ({
                                          ...x,
                                          propertyType: val.propertyType || '',
                                          propId: val.propertyId || val._id,
                                          name: val.name || '',
                                          propertyId: val._id,
                                        }))
                                        setSelectedPropertyDetails(val)
                                      }}
                                      options={filterPropertyList}
                                    />
                                  </div>
                                  {!data.isThirdPartyPropertyDamaged &&
                                    propertyDetails.propertyId === 'OTHERS' && (
                                      <div className="form-group">
                                        <div className="mb-2">
                                          <label
                                            htmlFor=""
                                            className="col-form-label"
                                          >
                                            Property Name
                                          </label>
                                          <input
                                            type="text"
                                            label={'Property Name'}
                                            name={'propertyName'}
                                            placeholder={'Property Name'}
                                            className="form-control"
                                            onChange={(a) =>
                                              setpropertyDetails((x) => ({
                                                ...x,
                                                name: a.target.value,
                                              }))
                                            }
                                            value={propertyDetails.name}
                                          />
                                        </div>
                                        <Formik
                                          innerRef={addressRef}
                                          enableReinitialize
                                          initialValues={{ address }}
                                        >
                                          {({ setFieldValue }) => (
                                            <AddressInput
                                              label="Address"
                                              name="address"
                                              setFieldValue={setFieldValue}
                                            />
                                          )}
                                        </Formik>
                                      </div>
                                    )}
                                  {typeof propertyDetails.propertyId ===
                                    'string' && (
                                    <div className="form-group">
                                      <label>Property Damage Details</label>
                                      <Select1
                                        isMulti
                                        name={`propertyDamages`}
                                        onChange={(val) => {
                                          setdata((x) => ({
                                            ...x,
                                            propertyDamages: val,
                                          }))
                                        }}
                                        options={options}
                                        value={
                                          data.propertyDamages.length > 0
                                            ? data.propertyDamages
                                            : []
                                        }
                                      />
                                    </div>
                                  )}
                                  {data.propertyDamages &&
                                    data.propertyDamages.find(
                                      (a) => a.value === 'Others',
                                    ) && (
                                      <div className="form-group">
                                        <label>Other Details</label>
                                        <input
                                          onChange={(a) =>
                                            setdata((x) => ({
                                              ...x,
                                              otherpropertyDamages:
                                                a.target.value,
                                            }))
                                          }
                                          label={'Other details'}
                                          name={'other'}
                                          type="text"
                                          className="form-control"
                                          value={data.otherpropertyDamages}
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div></div>
              </div>
              <div className="modal-footer" style={{ background: '#F4F7FE' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="btn btn-secondary bg-white text-dark"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default PropertyInvolvedModal