import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavDataUpdated, handleSideNavElementClicked, handleTabIdUpdated } from '../../actions'
import ActionWidget from '../../components/common/ActionWidget'
import Loader from '../../components/common/Loader'
import Table from '../../components/common/Table'
import { getPublicIncidentList } from '../../services/publicIncidentService'
import PublicIncidentModal from './PublicIncidentModal'
import { transformPhoneNumber } from './utils'

function PublicIncidentList() {
  const [isLoading, setIsLoading] = useState(true);
  const [publicIncidentData, setPublicIncidentData] = useState([])
  const [incidentsVehicularVisible, setIncidentsVehicularVisible] =
    useState(false)
  const [sortColumn, setSortColumn] = useState(null)
  const [checkedRecords, setCheckedRecords] = useState([])
  const dispatch = useDispatch()
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)
  const [claimModal, setClaimModal] = useState({ open: false, incidentId: '', data: undefined })

  const handleConvertToIncident = (incident) => {
    setClaimModal({ open: true, incidentId: incident._id, data: incident })
    // setConvertToClaimModal((x) => ({
    //   ...x,
    //   data: { incidentId: incident._id, claimId: null },
    // }))
  }

  const handleOnView = (incident, openView = true) => {
    dispatch(handleTabIdUpdated('public-incident'))
    dispatch(
      handleSideNavDataUpdated({ data: incident, module: 'public-incident' }, 'view'),
    )
    if (openView) dispatch(handleSideNavElementClicked('info'))
  }

  const columns = [
    {
      path: 'incidentDetails.dateOfIncident',
      label: 'Date Of Loss',
      type: 'date',
      content: (incident) => {
        const dateOfIncident = incident?.dateOfIncident ?? moment(incident.incidentDetails?.dateOfIncident).format("MM/DD/YYYY")
        if (dateOfIncident) {
          if (incident.timezone) {
            return <div>
              <p className='m-0'>{dateOfIncident}</p>
              <small className='text-muted'>{`${incident?.incidentDetails?.time ? incident?.incidentDetails?.time : 'N/A'}  ${moment.tz(incident?.timezone).zoneAbbr()}`}</small>
            </div>
          }
          return moment(dateOfIncident).format("MM/DD/YYYY")
        }
        return "N/A"
      },
    },
    {
      path: 'status',
      label: '',
      width: '150px',
      content: (incident) => (
        <ActionWidget
          module="PUBLIC-INCIDENT"
          isPublicIncident={true}
          convertToClaim={() => handleConvertToIncident(incident)}
          isUpdateHide
          isDeleteHide
          onView={() => handleOnView(incident)}
        // onEdit={() =>
        //   setIncidentModal({
        //     isOpen: true,
        //     data: incident,
        //     title: 'Edit Incident',
        //   })
        // }
        // onDelete={() => {
        //   setIncident(incident)
        //   setDeleteOne(true)
        // }}
        />
      ),
    },
    {
      path: 'userDetails.firstName',
      label: 'Employee Name',
      content: (incident) => {
        const userData = incident.userDetails
        const name = `${userData.lastName ? userData.lastName + ',' : ''} ${userData.firstName}`
        return <span>{name.length > 2 ? name : 'N/A'}</span>
      },
    },
    {
      label: 'FICO Score',
      path: "userDetails.ficoScore"
    },
    {
      path: 'userDetails.contactNumber',
      label: 'Contact Number',
      type: 'string',
      content: (incident) => {
        return incident.userDetails.contactNumber ? transformPhoneNumber(incident.userDetails.contactNumber) : "N/A"
      }
    },
    {
      label: 'Company Name',
      path: "clientId",
      type: 'string'
    },
    {
      label: 'Job Position/Title',
      path: "userDetails.jobTitle",
      type: 'string'
    },
    {
      path: "supervisorDetails.name",
      label: 'Supervisor',
      content: (incident) => {
        const userData = incident.supervisorDetails
        return <span>{userData.name ?? 'N/A'}</span>
      },
    },
    {
      path: "supervisorDetails.email",
      label: 'Supervisor Email',
      content: (incident) => {
        const supervisorEmail = incident?.managerFormDetails?.supervisorEmail ?? incident?.supervisorDetails?.email ?? "N/A"
        return <span>{supervisorEmail}</span>
      },
    },
    // {
    //   label: 'Supervisor Notified Via',
    //   path: "supervisorDetails.notificationMethod",
    //   type: 'string'
    // },
    // {
    //   label: 'Has Witness',
    //   content: (incident) => {
    //     const hasWitnesses = incident.incidentDetails.hasWitnesses
    //     console.log({ hasWitnesses })
    //     return <span>{hasWitnesses ? "Yes" : "No"}</span>
    //   },
    // },
    // {
    //   label: 'Police Called',
    //   content: (incident) => {
    //     const policeCalled = incident.policeCalled
    //     return <span>{policeCalled ? "Yes" : "No"}</span>
    //   },
    // },
    // {
    //   label: 'Police Called',
    //   content: (incident) => {
    //     const policeCalled = incident.policeCalled
    //     return <span>{policeCalled ? "Yes" : "No"}</span>
    //   },
    // },
  ]

  const fetchPublicIncidentList = async () => {
    const {
      data: { data },
    } = await getPublicIncidentList().finally(() => setIsLoading(false))
    setPublicIncidentData(data)
  }

  const vehicularIncidents = useMemo(() => {
    let pubData = publicIncidentData?.filter((incidentData) => {
      return incidentData.vehicleNature === 'vehicular'
    })

    if (sortColumn && sortColumn.path) {
      pubData = _.orderBy(pubData, [sortColumn.path], [sortColumn.order])
    }

    return pubData
  }, [publicIncidentData, sortColumn])

  const nonVehicularIncidents = useMemo(() => {
    let pubData = publicIncidentData?.filter((incidentData) => {
      return incidentData.vehicleNature === 'non-vehicular'
    })

    if (sortColumn && sortColumn.path) {
      pubData = _.orderBy(pubData, [sortColumn.path], [sortColumn.order])
    }

    return pubData
  }, [publicIncidentData, sortColumn])

  useEffect(() => {
    if (incidentsVehicularVisible) {
      handleOnView(vehicularIncidents?.[0], false)
    } else {
      handleOnView(nonVehicularIncidents?.[0], false)
    }
  }, [vehicularIncidents, nonVehicularIncidents, incidentsVehicularVisible])

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let records = incidentsVehicularVisible
        ? vehicularIncidents
        : nonVehicularIncidents
      let selectedIds = []
      if (records.length !== checkedRecords.length) {
        records.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  useEffect(() => {
    fetchPublicIncidentList()
    if (
      !sideNavDataContext &&
      sideNavDataContext !== 'view' &&
      sideNavDataContext !== 'searchView'
    )
      dispatch(handleTabIdUpdated('public-incident'))
  }, [])

  if (isLoading) return (<div style={{ marginTop: "120px" }} ><Loader loading={isLoading} /></div>)

  return (
    <>
      <div className="px-4">
        <div className="row">
          <div className="col-md-12">
            <div>
              <>
                <div
                  className="tab-pane fade show active"
                  id="Incidents"
                  role="tabpanel"
                  style={{ marginLeft: '90px' }}
                  aria-labelledby="nav-employees"
                >
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className={
                          'nav-link bdr-0 ' +
                          (incidentsVehicularVisible ? '' : 'active')
                        }
                        id="home-tab"
                        data-toggle="tab"
                        href="#Non-Vehiuclar"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => setIncidentsVehicularVisible(false)}
                      >
                        Non Vehicular Incidents
                      </a>
                    </li>

                    <li className="nav-item" role="presentation">
                      <a
                        className={
                          'nav-link bdr-0 ' +
                          (incidentsVehicularVisible ? 'active' : '')
                        }
                        id="profile-tab"
                        data-toggle="tab"
                        href="#V-Incidents"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setIncidentsVehicularVisible(true)}
                      >
                        Vehicular Incidents
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="container-fluid"
                  style={{ paddingLeft: '0px', paddingRight: '0px' }}
                >
                  <Table
                    columns={columns}
                    data={
                      incidentsVehicularVisible
                        ? vehicularIncidents
                        : nonVehicularIncidents
                    }
                    sortColumn={sortColumn}
                    onSort={handleSort}
                    // checkedItems={checkedRecords}
                    // checkboxClick={handleElementCheckboxClicked}
                    hideCheckbox
                  />

                  {((nonVehicularIncidents.length === 0 &&
                    !incidentsVehicularVisible) ||
                    (vehicularIncidents.length === 0 &&
                      incidentsVehicularVisible)) && (
                      <>
                        <div className="py-5">
                          <div className="text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="200"
                              height="200"
                              viewBox="0 0 238 238"
                            >
                              <g
                                id="Group_11"
                                data-name="Group 11"
                                transform="translate(-562 -325)"
                              >
                                <rect
                                  id="Rectangle_3349"
                                  data-name="Rectangle 3349"
                                  width="238"
                                  height="238"
                                  rx="119"
                                  transform="translate(562 325)"
                                  fill="#f4f7fe"
                                ></rect>
                                <path
                                  id="ambulance"
                                  d="M118.335,59.821,109,38.82A30.8,30.8,0,0,0,80.851,20.53h-6.93L70.533,6.968a9.209,9.209,0,0,0-17.864,0L49.281,20.53H25.667A25.7,25.7,0,0,0,0,46.2V87.264a15.369,15.369,0,0,0,10.631,14.569,17.453,17.453,0,0,0-.364,3.4,17.967,17.967,0,1,0,35.934,0,17.787,17.787,0,0,0-.21-2.567H77.211a17.788,17.788,0,0,0-.21,2.567,17.967,17.967,0,0,0,35.934,0,17.453,17.453,0,0,0-.364-3.4A15.369,15.369,0,0,0,123.2,87.264V82.757A56.108,56.108,0,0,0,118.335,59.821ZM99.619,42.994l5.991,13.47H87.268a5.133,5.133,0,0,1-5.133-5.133V30.889a20.534,20.534,0,0,1,17.484,12.1ZM35.934,105.231a7.7,7.7,0,1,1-15.4,0,7.279,7.279,0,0,1,.477-2.567H35.456a7.279,7.279,0,0,1,.477,2.567Zm59.034,7.7a7.7,7.7,0,0,1-7.7-7.7,7.279,7.279,0,0,1,.477-2.567H102.19a7.279,7.279,0,0,1,.477,2.567A7.7,7.7,0,0,1,94.968,112.931Zm17.967-25.667A5.133,5.133,0,0,1,107.8,92.4H15.4a5.133,5.133,0,0,1-5.133-5.133V46.2a15.4,15.4,0,0,1,15.4-15.4h46.2V51.33a15.4,15.4,0,0,0,15.4,15.4H110a45.769,45.769,0,0,1,2.931,16.026ZM56.467,61.6a5.133,5.133,0,0,1-5.133,5.133H46.2v5.133a5.133,5.133,0,0,1-10.267,0V66.731H30.8a5.133,5.133,0,0,1,0-10.267h5.133V51.33a5.133,5.133,0,0,1,10.267,0v5.133h5.133A5.133,5.133,0,0,1,56.467,61.6Z"
                                  transform="translate(619.132 378.668)"
                                  fill="#207be3"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="text-center">
                            <h2 className="no-comment mt-5">
                              No Data Found Yet Here
                            </h2>
                            <p className="all-comment my-3">
                              All data related to this module will show up here
                            </p>
                            {/* {createPermission && (
                              <a
                                className="all-comment my-3"
                                style={{
                                  cursor: 'pointer',
                                  color: '#07448a',
                                }}
                                onClick={() =>
                                  setIncidentModal({
                                    isOpen: true,
                                    data: null,
                                    title: 'Add New Incident',
                                  })
                                }
                              >
                                {' '}
                                Get Started{' '}
                              </a>
                            )} */}
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <PublicIncidentModal
        isOpen={claimModal.open}
        data={claimModal.data}
        title="Convert to Incident"
        onClose={() => setClaimModal({ data: undefined, incidentId: "", open: false })}
      />
    </>
  )
}

export default PublicIncidentList
