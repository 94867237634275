import React from 'react'
import RolesList from './RolesList'

function Roles({ handleAddNewRole, fetchRoleDetails }) {
  

  return (
    <div className="position-rel" style={{ paddingLeft: '13rem' }}>
      <div className="sticki-top">
        <div className="pl-6 pt-4 user-mang">
          <div className="container-fluid">
            <h1 className="pb-0 d-none">Policy Hub</h1>
            <div className="row mt-3 mb-2 d-none">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-right">
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control "
                    name="search"
                    placeholder=""
                    id="searchtable"
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-md-6 col-lg-8">
                <div className="download-btn text-right">
                  <a
                    className="btn btn-warning custom-btn"
                    data-toggle="modal"
                    data-target="#addnewpolicy"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        id="Path_16690"
                        data-name="Path 16690"
                        d="M-16.32-7.495h-6.5V-14.07h-3v6.575h-6.5v2.886h6.5V1.93h3V-4.609h6.5Z"
                        transform="translate(32.32 14.07)"
                        fill="#fff"
                      ></path>
                    </svg>
                    <span className="pl-2"> Add New</span>
                  </a>
                  <a
                    href="../assets/images/excel.csv"
                    download=""
                    className="btn btn-warning export-btn pl-3 pr-3 ml-3 mr-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Export CSV"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25.874"
                        height="25.874"
                        viewBox="0 0 25.874 25.874"
                      >
                        <path
                          id="clip"
                          d="M17.837,7.451a.776.776,0,0,0-1.1,0l-8.454,8.49A3.881,3.881,0,1,1,2.8,10.453l8.188-8.221a2.328,2.328,0,1,1,3.293,3.293L6.09,13.745a.793.793,0,0,1-1.1,0,.776.776,0,0,1,0-1.1l7.29-7.323a.776.776,0,1,0-1.1-1.1L3.895,11.55a2.328,2.328,0,0,0,0,3.293,2.385,2.385,0,0,0,3.293,0l8.187-8.222A3.881,3.881,0,1,0,9.887,1.134L1.7,9.355a5.433,5.433,0,0,0,7.684,7.684l8.454-8.488a.776.776,0,0,0,0-1.1Z"
                          transform="translate(-0.074 12.777) rotate(-45)"
                          fill="#207be3"
                        ></path>
                      </svg>
                    </span>
                    <span className="export">Export</span>{' '}
                  </a>
                  <a
                    href="#"
                    className="btn btn-success  import-btn mr-3"
                    data-toggle="modal"
                    data-target="#importfile"
                  >
                    <span>
                      <svg
                        id="surface1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.948"
                        height="21.927"
                        viewBox="0 0 19.948 21.927"
                      >
                        <path
                          id="Path_17005"
                          data-name="Path 17005"
                          d="M14.944,9.492V5.342a.48.48,0,0,0-.132-.315L10.159.142A.464.464,0,0,0,9.826,0H2.449A2.458,2.458,0,0,0,0,2.472V16.42a2.442,2.442,0,0,0,2.449,2.454H8.272a6.285,6.285,0,1,0,6.672-9.382ZM10.283,1.6,13.4,4.89H11.38a1.1,1.1,0,0,1-1.1-1.1ZM2.449,17.961A1.531,1.531,0,0,1,.914,16.42V2.472A1.544,1.544,0,0,1,2.449.914H9.369V3.789A2.015,2.015,0,0,0,11.38,5.8H14.03V9.373c-.137,0-.247-.018-.366-.018A6.377,6.377,0,0,0,9.5,10.927h-5.8a.457.457,0,1,0,0,.914H8.647a7.271,7.271,0,0,0-.8,1.417H3.693a.457.457,0,0,0,0,.914H7.545a6.272,6.272,0,0,0,.274,3.793H2.449ZM13.66,21.018a5.37,5.37,0,1,1,5.37-5.37A5.376,5.376,0,0,1,13.66,21.018Zm0,0"
                          fill="#207be3"
                        ></path>
                        <path
                          id="Path_17006"
                          data-name="Path 17006"
                          d="M76.008,202.6h4.629a.457.457,0,1,0,0-.914H76.008a.457.457,0,1,0,0,.914Zm0,0"
                          transform="translate(-72.315 -193.046)"
                          fill="#207be3"
                        ></path>
                        <path
                          id="Path_17007"
                          data-name="Path 17007"
                          d="M249.028,283.357a.454.454,0,0,0-.667,0l-2.678,2.875a.455.455,0,0,0,.023.644.467.467,0,0,0,.653-.023l1.892-2.025v4.991a.457.457,0,1,0,.914,0v-4.991l1.878,2.025a.456.456,0,0,0,.667-.622Zm0,0"
                          transform="translate(-235.043 -271.082)"
                          fill="#207be3"
                        ></path>
                      </svg>
                    </span>{' '}
                    <span
                      className="pl-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Import CSV"
                    >
                      Import
                    </span>
                  </a>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-filter mr-2" aria-hidden="true"></i>{' '}
                      Filter
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        All
                      </a>
                      <a className="dropdown-item" href="#">
                        WC
                      </a>
                      <a className="dropdown-item" href="#">
                        GL
                      </a>
                      <a className="dropdown-item" href="#">
                        Auto
                      </a>
                      <a className="dropdown-item" href="#">
                        Property
                      </a>
                    </div>
                  </div>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-sort" aria-hidden="true"></i> Sort
                    </button>
                  </div>

                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">
                        Separated link
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <nav className="mt-4">
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <div
                  className=" nav-link text-left hvr-pulse1 mb-2 inherit"
                  style={{ padding: '0px !important' }}
                >
                  <h2 className="text-dark">Roles</h2>
                  <p className="mb-0">List of roles for app users</p>
                </div>
                <a
                  className="btn btn-warning custom-btn"
                  style={{ height: '54px' }}
                  onClick={handleAddNewRole}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="Path_16690"
                      data-name="Path 16690"
                      d="M-16.32-7.495h-6.5V-14.07h-3v6.575h-6.5v2.886h6.5V1.93h3V-4.609h6.5Z"
                      transform="translate(32.32 14.07)"
                      fill="#fff"
                    ></path>
                  </svg>
                  <span className="pl-2"> Add New Role</span>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="pl-6 user-mang assetmanag ">
        <div className="container-fluid">
          <div>
            <div>
              <div style={{ position: 'relative' }}>
                <div className="table-responsive-xl mr-5">
                  <RolesList fetchRoleDetails={fetchRoleDetails} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roles
