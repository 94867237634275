/* eslint-disable react/prop-types */
import React from 'react'
import classNames from 'classnames'

export function HumanBodyPart({ selectedParts = [], onClick }) {
  const partClicked = (key, value) => {
    onClick(key, value)
  }

  return (
    <div className="human-body">
      <svg xmlns="http://www.w3.org/2000/svg" height="350">
        <svg
          onClick={() => partClicked('head', 'Head')}
          className={classNames({ active: selectedParts.includes('head') })}
        >
          <path
            opacity="5"
            d="M44.0025 23.9365L43.8203 14.1444L59.7188 4.81625L76.4534 15.9795L76.3003 23.3272L77.2149 10.6859L69.5376 1.95133L58.7845 0L50.4536 1.99994L43.1353 11.943L44.0025 23.9365Z"
          />
          <path d="M181.902 23.9365L183.573 23.371L184.182 23.9918L189.781 39.2895L198.565 44.7743L207.635 39.0148L211.323 25.0695L213.317 22.9564L214.2 23.3272L215.114 10.6859L207.437 1.95133L196.684 0L188.353 1.99994L181.035 11.943L181.902 23.9365Z" />
          <path d="M74.5306 25.2403L74.6073 16.8971L59.6143 6.45166L45.4751 14.9011L45.4525 24.8612L43.1567 25.0136L44.1926 31.9092L47.8828 33.1917L49.555 41.4321L56.4421 49.239L64.1341 49.0096L70.9079 41.6619L72.2669 33.1932L75.9563 31.9107L76.9072 25.1675L74.5306 25.2403Z" />
        </svg>
        <svg
          onClick={() => partClicked('neck', 'Neck')}
          className={classNames({ active: selectedParts.includes('neck') })}
        >
          <path d="M50.1748 44.9204L56.41 53.8099L59.223 63.7183L52.666 58.4892L49.3911 47.2601L50.1748 44.9204ZM69.3765 44.9204L63.1413 53.8099L60.3241 63.7211L66.881 58.492L70.1559 47.2629L69.3765 44.9204Z" />
          <path d="M197.822 45.6558L188.911 39.7959L183.351 24.8613L181.055 25.0136L182.091 31.9093L185.781 33.1917L188.659 41.5661L189.906 45.6045L190.245 53.7265L194.646 53.7567L195.045 51.0796L197.113 50.8505L197.822 45.6558ZM201.669 51.2342L202.568 53.6902L207.788 53.6751L207.825 44.6502L208.807 41.6621L210.969 33.4613L213.855 31.911L214.806 25.1677L212.43 25.2406L208.33 39.9801L199.27 45.524L200.316 51.1369L201.669 51.2342Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_upper_arm', 'Left Upper Arm')}
          className={classNames({
            active: selectedParts.includes('left_upper_arm'),
          })}
        >
          <path d="M104.286 116.37L103.007 122.814L96.131 113.198L93.6255 108.402L104.286 116.37ZM93.5041 106.507C93.4063 106.452 93.3016 106.411 93.1927 106.384L87.5864 87.6564L91.3832 79.7788L97.6387 86.3753L106.068 111.61L106.225 115.153C102.199 111.97 97.9495 109.08 93.5096 106.505L93.5041 106.507ZM99.9086 84.7495L106.141 109.068L104.762 90.4625L99.9086 84.7495Z" />
          <path d="M106.945 103.351L106.926 115.045L109.117 122.271L111.178 113.155L106.945 103.351Z" />
          <path d="M163.37 103.5L164.61 99.5198L169.43 91.7598L164.18 80.5498L155.54 84.0098L148.59 90.5998L146.55 97.3498L147.54 113.63L163.37 103.5Z" />
        </svg>
        <svg
          onClick={() => partClicked('left_shoulder', 'Left Shoulder')}
          className={classNames({
            active: selectedParts.includes('left_shoulder'),
          })}
        >
          <path d="M71.8811 49.9771L85.3267 57.4573L88.0726 56.1317L71.8811 49.9771ZM71.4767 51.6131L70.0737 58.1545L78.3305 60.1788L83.6262 58.3104L71.4767 51.6131ZM86.5249 59.0813L89.3903 57.5317L98.4916 63.8359L102.927 69.5319L105.296 75.4378L105.12 89.4294L100.1 82.9228L100.253 73.1196L98.4075 71.228C98.4075 71.228 86.6985 59.1698 86.5249 59.0813ZM85.6144 59.4922C85.6106 59.6907 98.1997 72.8995 98.1997 72.8995L98.5802 84.631L94.2177 77.1557L85.8064 73.5726L79.8886 62.6694L85.6144 59.4922Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_lower_arm', 'Left Lower Arm')}
          className={classNames({
            active: selectedParts.includes('left_lower_arm'),
          })}
        >
          <path d="M101.768 124.512L106.685 163.401L102.498 163.439L95.3398 133.217L96.0645 116.069L101.768 124.512ZM106.378 117.143L104.159 126.919L108.387 161.524L110.498 160.706L111.369 135.118L106.378 117.143Z" />
          <path d="M149.97 160.56L144.55 157.73L143.45 131.23C143.45 131.23 145.91 120.73 145.88 119.66L148.19 115.06L163.25 105.32L159.39 112.63L160.86 130.86L153.97 159.94L149.97 160.56Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_hand', 'Left Hand')}
          className={classNames({
            active: selectedParts.includes('left_hand'),
          })}
        >
          <path d="M102.467 164.553L108.211 164.744L111.107 162.208L115.69 170.362L118.95 182.943L117.083 183.779L114.822 175.455L112.932 176.414L114.279 185.841L111.921 186.761L110.357 177.342L108.246 178.017L109.101 188.471L106.193 189.691L105.218 178.853L103.488 178.784L102.86 188.809L100.366 189.804L100.097 172.545L98.8059 171.811L93.6916 178.174L92.4507 176.876L95.1963 169.109L102.467 164.553Z" />
          <path d="M153.67 161.24L147.93 161.55L144.98 159.08L140.57 167.33L137.57 179.97L139.46 180.77L141.54 172.4L143.45 173.32L142.31 182.77L144.68 183.64L146.05 174.19L148.17 174.82L147.54 185.29L150.47 186.45L151.22 175.59L152.94 175.49L153.78 185.5L156.3 186.44L156.2 169.18L157.48 168.42L162.73 174.67L163.94 173.35L161.03 165.64L153.67 161.24Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_upper_arm', 'Right Upper Arm')}
          className={classNames({
            active: selectedParts.includes('right_upper_arm'),
          })}
        >
          <path d="M15.2897 116.37L16.5686 122.814L23.4448 113.198L25.9502 108.402L15.2897 116.37ZM26.0716 106.507C26.1695 106.452 26.2742 106.411 26.383 106.384L31.9892 87.6564L28.1925 79.7788L21.9371 86.3753L13.5074 111.61L13.3506 115.153C17.3768 111.97 21.6263 109.08 26.0662 106.505L26.0716 106.507ZM19.6672 84.7495L13.4351 109.068L14.8134 90.4625L19.6672 84.7495Z" />
          <path d="M12.0045 103.351L12.0234 115.045L9.8328 122.271L7.77148 113.155L12.0045 103.351Z" />
          <path d="M233.63 103.5L232.39 99.5198L227.57 91.7598L232.82 80.5498L241.46 84.0098L248.41 90.5998L250.45 97.3498L249.46 113.63L233.63 103.5Z" />
        </svg>
        <svg
          onClick={() => partClicked('right_shoulder', 'Right Shoulder')}
          className={classNames({
            active: selectedParts.includes('right_shoulder'),
          })}
        >
          <path d="M47.605 49.9771L34.1595 57.4573L31.4136 56.1317L47.605 49.9771ZM48.0094 51.6131L49.4124 58.1545L41.1557 60.1788L35.86 58.3104L48.0094 51.6131ZM32.9613 59.0813L30.0959 57.5317L20.9946 63.8359L16.5588 69.5319L14.1904 75.4378L14.3658 89.4294L19.3865 82.9228L19.2327 73.1196L21.0787 71.228C21.0787 71.228 32.7877 59.1698 32.9613 59.0813ZM33.8718 59.4922C33.8793 59.6907 21.2865 72.8995 21.2865 72.8995L20.906 84.631L25.2685 77.1557L33.6797 73.5726L39.5975 62.6694L33.8718 59.4922Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_lower_arm', 'Right Lower Arm')}
          className={classNames({
            active: selectedParts.includes('right_lower_arm'),
          })}
        >
          <path d="M17.1816 124.512L12.2651 163.401L16.4519 163.439L23.6099 133.217L22.8852 116.069L17.1816 124.512ZM12.5714 117.143L14.791 126.919L10.5627 161.524L8.45186 160.706L7.58105 135.118L12.5714 117.143Z" />
          <path d="M247.03 160.56L252.45 157.73L253.55 131.23C253.55 131.23 251.09 120.73 251.12 119.66L248.81 115.06L233.75 105.32L237.61 112.63L236.14 130.86L243.03 159.94L247.03 160.56Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_hand', 'Right Hand')}
          className={classNames({
            active: selectedParts.includes('right_hand'),
          })}
        >
          <path d="M16.4832 164.553L10.7391 164.744L7.84278 162.208L3.25965 170.363L0 182.944L1.86656 183.779L4.12728 175.455L6.01765 176.414L4.67066 185.841L7.02841 186.761L8.59321 177.342L10.7037 178.017L9.84873 188.471L12.7566 189.691L13.7313 178.853L15.4616 178.784L16.0894 188.809L18.5837 189.804L18.8529 172.545L20.1439 171.811L25.2582 178.174L26.4991 176.876L23.7535 169.109L16.4832 164.553Z" />
          <path d="M243.33 161.24L249.07 161.55L252.02 159.08L256.43 167.33L259.43 179.97L257.54 180.77L255.46 172.4L253.55 173.32L254.69 182.77L252.32 183.64L250.95 174.19L248.83 174.82L249.46 185.29L246.53 186.45L245.78 175.59L244.06 175.49L243.22 185.5L240.7 186.44L240.8 169.18L239.52 168.42L234.27 174.67L233.06 173.35L235.97 165.64L243.33 161.24Z" />
        </svg>

        <svg
          onClick={() => partClicked('torso_front', 'Torso Front')}
          className={classNames({
            active: selectedParts.includes('torso_front'),
          })}
        >
          <path d="M74.1262 131.083L80.9801 125.924L81.5753 132.854L86.4373 141.824L78.9522 152.167L75.0552 152.793L73.6558 138.097L74.1262 131.083Z" />
          <path d="M72.7922 98.7468L61.0303 93.4326L61.3844 102.049L71.9716 107.476L72.7922 98.7468ZM80.1045 105.053L75.2155 107.779L75.7823 101.32L80.1045 105.053ZM76.1246 98.8651L85.4966 94.9631L81.968 104.491L76.1246 98.8651ZM81.9135 105.431L75.5259 109.338L74.4347 117.154L80.6699 113.089L81.9135 105.431ZM70.9096 109.605L70.6745 115.742L61.97 113.854L61.4995 105.474L70.9096 109.605ZM70.3215 118.693L70.4391 126.246L62.0881 128.49L61.9705 116.688L70.3215 118.693ZM80.3546 114.968L74.7464 118.875L73.967 126.847L80.198 121.844L80.3546 114.968Z" />
          <path d="M45.4172 131.083L38.5633 125.924L37.9681 132.854L33.1089 141.828L40.5941 152.168L44.4911 152.794L45.894 138.102L45.4172 131.083Z" />
          <path d="M58.986 169.68L56.6938 147.335L56.6371 132.788L48.3796 128.724L47.4443 155.295L58.986 169.68ZM60.5575 169.678L62.849 147.333L62.9072 132.785L71.1618 128.721L72.0971 155.292L60.5575 169.678Z" />
          <path d="M46.7527 98.7477L58.5147 93.4336L58.1605 102.05L47.5726 107.479L46.7527 98.7477ZM39.439 105.054L44.328 107.78L43.7612 101.321L39.439 105.054ZM43.419 98.8668L34.0483 94.9641L37.5769 104.494L43.419 98.8668ZM37.6301 105.433L44.0177 109.34L45.1089 117.154L38.8771 113.09L37.6301 105.433ZM48.6339 109.606L48.8693 115.744L57.5746 113.855L58.0451 105.476L48.6339 109.606ZM49.222 118.694L49.1052 126.247L57.457 128.49L57.5745 116.688L49.222 118.694ZM39.1889 114.97L44.7971 118.878L45.5765 126.849L39.3412 121.847L39.1889 114.97Z" />
        </svg>
        <svg
          onClick={() => partClicked('chest', 'Chest')}
          className={classNames({
            active: selectedParts.includes('chest'),
          })}
        >
          <path d="M76.7554 64.4795L83.2918 76.1621L90.4481 79.7445L86.0905 83.1711L82.6661 93.1405L74.7303 96.4112L62.0869 90.1561L65.24 65.5692L76.7554 64.4795Z" />
          <path d="M42.7914 64.4795L36.255 76.1621L29.0986 79.7445L33.4562 83.1711L36.88 93.1405L44.8157 96.4112L57.4634 90.1561L54.3089 65.5692L42.7914 64.4795Z" />
        </svg>

        <svg
          onClick={() => partClicked('torso_back', 'Torso Back')}
          className={classNames({
            active: selectedParts.includes('torso_back'),
          })}
        >
          <path d="M159.389 62.5934L153.437 68.509L150.36 76.329L148.901 85.8859L155.844 81.202L166.283 76.8352L173.264 89.8149L177.56 110.592L177.85 132.737L193.236 136.93L192.844 106.917L185.508 88.3962L166.444 57.4292L159.389 62.5934Z" />
          <path d="M187.449 55.1962L187.687 45.2764L184.987 49.6297L168.433 55.2912L187.249 86.9163L194.167 106.123L193.338 54.8971L187.449 55.1962Z" />
          <path d="M237.483 62.9684L243.434 68.884L246.511 76.704L246.656 86.2609L241.028 81.577L230.588 77.2102L223.607 90.1899L219.311 110.966L219.021 133.112L203.636 137.305L204.027 107.292L211.363 88.7712L230.427 57.8042L237.483 62.9684Z" />
          <path d="M195.418 55.7981L197.454 151.536L199.305 151.424L201.787 55.6554L200.723 52.4735L195.996 52.4717L195.418 55.7981Z" />
          <path d="M209.423 55.5712L209.185 45.6514L211.885 50.0047L228.439 55.6662L209.622 87.2913L202.704 106.498L203.534 55.2722L209.423 55.5712Z" />
        </svg>

        <svg
          onClick={() => partClicked('pelvis', 'Pelvis')}
          className={classNames({ active: selectedParts.includes('pelvis') })}
        >
          <path d="M54.3314 170.135L54.4149 170.03L53.853 168.596L42.13 155.728L41.25 155.417L49.0327 175.524L54.3314 170.135ZM49.8994 177.763L54.726 190.233L56.3373 174.931L55.385 172.501L49.8994 177.763ZM65.2147 170.136L65.1312 170.03L65.6931 168.596L77.4161 155.729L78.2947 155.417L70.5148 175.524L65.2147 170.136ZM69.6467 177.763L64.82 190.233L63.2088 174.932L64.1611 172.502L69.6467 177.763Z" />
        </svg>
        <svg
          onClick={() => partClicked('waist', 'Waist')}
          className={classNames({ active: selectedParts.includes('waist') })}
        >
          <path d="M195.738 140.917L196.283 152.153L200.667 152.062L200.848 140.856L211.464 137.758L220.309 134.908L225.378 149.017L207.316 154.175L198.499 158.77L189.521 154.109L168.897 148.903L174.641 133.946L195.738 140.917Z" />
          <path d="M168.996 149.91L189.722 156.957L198.316 162.014L208.679 155.422L225.755 150.581L229.157 159.263L231.709 172.244L228.637 191.249L217.955 191.885L202.411 185.551L198.601 187.03L194.978 185.522L178.195 192.87L167.725 190.924L164.127 167.668L165.517 157.137L168.996 149.91Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_upper_leg', 'Right Upper Leg')}
          className={classNames({
            active: selectedParts.includes('right_upper_leg'),
          })}
        >
          <path d="M31.1437 190.389L31.7296 208.344L40.8105 233.325L38.0273 240.507L29.0924 208.97L31.1437 190.389ZM33.3402 146.518L33.9262 161.662L28.9465 191.639L26.604 167.438L33.3402 146.518ZM34.8055 165.877L45.2049 188.828L47.5481 207.251L43.1551 233.169L33.6343 206.782L33.0483 179.615L34.8055 165.877Z" />
          <path d="M36.27 148.702V164.628L47.4014 186.645L54.4324 208.503L53.7004 193.358L36.27 148.702ZM48.5492 199.445L48.7937 200.03L53.3781 210.998L50.4185 238.166L45.7463 235.667L49.7957 210.372L48.5492 199.445Z" />
          <path d="M216.807 193.352L201.766 186.918L199.634 187.943L199.435 194.737L199.151 212.299L206.629 235.138L204.829 240.483L210.227 252.934L216.902 251.796L218.795 243.798L219.435 237.159L228.586 206.327L228.62 192.406L216.807 193.352Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_knee', 'Right Knee')}
          className={classNames({
            active: selectedParts.includes('right_knee'),
          })}
        >
          <path d="M38.7593 244.759L38.2889 249.003L41.5815 253.091L42.6798 259.539L44.8763 260.481L46.7575 250.735L48.0116 247.277L46.9134 245.076L38.7593 244.759ZM53.3428 245.073L50.9904 251.675L45.9724 261.421L47.2265 270.382L50.8331 260.321L53.8125 254.665L53.3428 245.073ZM34.5257 236.113L38.1323 255.45L35.7799 254.191L33.8979 260.479L34.5257 236.113Z" />
          <path d="M208.615 253.467L204.056 242.074L204.658 252.603L202.549 267.766L205.941 254.471L208.615 253.467Z" />
          <path d="M220.881 237.201L223.275 230.168L222.43 260.024L221.351 251.861L217.928 252.183L220.245 244.884L220.881 237.201Z" />
        </svg>
        <svg
          onClick={() => partClicked('right_lower_leg', 'Right Lower Leg')}
          className={classNames({
            active: selectedParts.includes('right_lower_leg'),
          })}
        >
          <path d="M50.6773 266.138L49.579 269.596L47.2266 284.216L46.9133 303.708L50.208 322.731L50.0501 298.364L53.5007 288.618L53.9705 277.928L50.6773 266.138ZM43.1503 274.941L43.934 304.181L50.2068 327.761L43.3849 324.538L32.0161 283.583L35.9365 257.803L43.1503 274.941Z" />
          <path d="M206.714 254.509L201.889 273.395L202.985 284.82L205.55 322.863L211.836 325.263L225.08 280.023L220.356 252.904L206.714 254.509Z" />
        </svg>

        <svg
          onClick={() => partClicked('right_foot', 'Right Foot')}
          className={classNames({
            active: selectedParts.includes('right_foot'),
          })}
        >
          <path d="M54.4403 332.005L53.9698 345.053L52.8715 349.612H49.5789L48.6387 346.468L47.5404 346.311L47.7758 349.455L43.6208 348.195L42.5226 347.567L37.8178 346.545L37.6621 342.851L42.2096 335.149L45.3457 331.218L53.0292 328.074L54.4403 332.005Z" />
          <path d="M201.755 338.924L204.975 333.791L205.696 324.79L211.5 326.862L211.427 333.723L212.549 336.903L211.696 340.153L214.749 345.082L213.833 348.706L209.563 349.809L203.848 349.335L200.939 346.522L200.35 341.919L201.755 338.924Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_upper_leg', 'Left Upper Leg')}
          className={classNames({
            active: selectedParts.includes('left_upper_leg'),
          })}
        >
          <path d="M88.4022 190.389L87.8162 208.346L78.7354 233.326L81.5179 240.508L90.4527 208.97L88.4022 190.389ZM86.2042 146.516L85.619 161.661L90.6001 191.638L92.9426 167.438L86.2042 146.516ZM84.7404 165.877L74.3409 188.828L71.9971 207.251L76.3901 233.17L85.9102 206.783L86.4962 179.617L84.7404 165.877Z" />
          <path d="M83.2777 148.703V164.629L72.1442 186.645L65.1133 208.504L65.8452 193.358L83.2777 148.703ZM70.9964 199.447L70.7511 200.031L66.1668 211L69.1271 238.167L73.7993 235.668L69.7499 210.373L70.9964 199.447Z" />
          <path d="M179.702 193.352L194.743 186.918L196.874 187.943L197.074 194.737L197.357 212.299L189.88 235.138L191.68 240.483L186.282 252.934L179.607 251.796L177.714 243.798L177.074 237.159L167.923 206.327L167.889 192.406L179.702 193.352Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_knee', 'Left Knee')}
          className={classNames({
            active: selectedParts.includes('left_knee'),
          })}
        >
          <path d="M80.7889 244.759L81.2586 249.003L77.966 253.091L76.8677 259.539L74.6712 260.481L72.79 250.735L71.5359 247.278L72.6341 245.076L80.7889 244.759ZM66.2054 245.073L68.5578 251.675L73.5744 261.422L72.3203 270.383L68.7137 260.321L65.7378 254.662L66.2054 245.073ZM85.0203 236.114L81.4138 255.451L83.7662 254.192L85.6474 260.481L85.0203 236.114Z" />
          <path d="M175.628 237.201L173.234 230.168L174.079 260.024L175.157 251.861L178.581 252.183L176.264 244.884L175.628 237.201Z" />
          <path d="M187.894 253.467L192.452 242.074L191.851 252.603L193.96 267.766L190.568 254.471L187.894 253.467Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_lower_leg', 'Left Lower Leg')}
          className={classNames({
            active: selectedParts.includes('left_lower_leg'),
          })}
        >
          <path d="M68.8707 266.138L69.9689 269.596L72.3213 284.216L72.6346 303.708L69.3413 322.73L69.497 298.363L66.0485 288.618L65.5737 277.928L68.8707 266.138ZM76.3976 274.941L75.6132 304.181L69.3411 327.762L76.1623 324.538L87.5318 283.584L83.6114 257.804L76.3976 274.941Z" />
          <path d="M189.795 254.509L194.62 273.395L193.524 284.82L190.959 322.863L184.673 325.263L171.429 280.023L176.153 252.904L189.795 254.509Z" />
        </svg>

        <svg
          onClick={() => partClicked('left_foot', 'Left Foot')}
          className={classNames({
            active: selectedParts.includes('left_foot'),
          })}
        >
          <path d="M65.1084 332.005L65.5782 345.053L66.6736 349.613H69.9669L70.9079 346.469L72.0062 346.312L71.7703 349.456L75.9246 348.196L77.0228 347.568L81.7262 346.546L81.8835 342.851L77.336 335.151L74.1999 331.221L66.5164 328.077L65.1084 332.005Z" />
          <path d="M194.754 338.924L191.534 333.791L190.813 324.79L185.008 326.862L185.082 333.723L183.96 336.903L184.813 340.153L181.76 345.082L182.676 348.706L186.946 349.809L192.66 349.335L195.57 346.522L196.159 341.919L194.754 338.924Z" />
        </svg>
      </svg>
    </div>
  )
}
