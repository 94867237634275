import apiUrl from '../configs/api'
import { uploadFile } from './helperService'
import httpService from './httpService'

// const apiUrl = 'http://localhost:3005/api/v1'
const apiEndpoint = `${apiUrl}/attachment`

export async function uploadFilesWithPresignedUrl(files, module, uid) {
  if (!uid) throw new Error('UID is required')
  const filesToUpload = []
  for (const file of files) {
    const obj = {
      key: file.name,
      contentType: file.type,
    }
    filesToUpload.push(obj)
  }

  const response = await httpService.post(
    `${apiEndpoint}/get-presigned-url?module=${module}`,
    {
      files: filesToUpload,
      uid,
    },
  )

  const presignedUrls = response.data.data.map((c) => c.preSignedUrl)
  const promiseArray = []
  const filesArray = Array.from(files)
  for (let i = 0; i < filesArray.length; i++) {
    const myHeaders = new Headers()
    myHeaders.append('ContentType', filesArray[i].type)
    const resp = uploadFile(presignedUrls[i], filesArray[i], myHeaders)
    promiseArray.push(resp)
  }
  await Promise.all(promiseArray)

  return response.data.data.map((c) => c.attachment)
}

export async function uploadMVRFilesWithPresignedUrl(
  files,
  module = 'mvr',
  uid,
  token,
) {
  if (!uid) throw new Error('UID is required')
  const filesToUpload = []
  for (const file of files) {
    const obj = {
      key: file.name,
      contentType: file.type,
    }
    filesToUpload.push(obj)
  }

  const response = await httpService.post(
    `${apiEndpoint}/save-mvr-attachments?module=${module}`,
    {
      files: filesToUpload,
      uid,
    },
    {
      headers: {
        authorization: token,
      },
    },
  )

  const presignedUrls = response.data.data.map((c) => c.preSignedUrl)
  const promiseArray = []
  const filesArray = Array.from(files)
  for (let i = 0; i < filesArray.length; i++) {
    const myHeaders = new Headers()
    myHeaders.append('ContentType', filesArray[i].type)
    const resp = uploadFile(presignedUrls[i], filesArray[i], myHeaders)
    promiseArray.push(resp)
  }
  await Promise.all(promiseArray)

  return response.data.data.map((c) => c.attachment)
}

export async function getAttachmentList(module, ids) {
  return httpService.post(`${apiEndpoint}/get-attachments?module=${module}`, {
    ids: ids,
  })
}


export async function getPublicIncidentAttachmentList(module, ids) {
  return httpService.post(`${apiEndpoint}/get-public-incident-attachments?module=${module}`, {
    ids: ids,
  })
}


// delete attachment
export async function deleteAttachment(id, module) {
  return httpService.delete(`${apiEndpoint}/delete-attachment/${id}?module=${module}`)
}


export async function uploadPublicIncidentFilesWithPresignedUrl(
  files,
  module,
  uid,
  isAttachment
) {
  if (!uid) throw new Error('UID is required')
  const filesToUpload = []
  for (const file of files) {
    const obj = {
      key: file.name,
      contentType: file.type,
    }
    filesToUpload.push(obj)
  }

  const response = await httpService.post(
    `${apiEndpoint}/save-public-incident-attachments?module=${module}`,
    {
      files: filesToUpload,
      uid,
    }
  )

  const presignedUrls = response.data.data.map((c) => c.preSignedUrl)
  const promiseArray = []
  const filesArray = Array.from(files)
  for (let i = 0; i < filesArray.length; i++) {
    const myHeaders = new Headers()
    myHeaders.append('ContentType', filesArray[i].type)
    if(isAttachment) {
      myHeaders.append('Content-Disposition', `attachment`)
    }
    const resp = uploadFile(presignedUrls[i], filesArray[i], myHeaders)
    promiseArray.push(resp)
  }
  await Promise.all(promiseArray)

  return response.data.data.map((c) => c.attachment)
}