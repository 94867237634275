/* eslint-disable react/prop-types */
import React, { useState } from "react";

function MaskedSSN({ssn}) {

    const [isSSN, setSSN] = useState(false);

    return (
        <div onClick={() => setSSN(true)} style={{cursor: "pointer"}}>
            {isSSN ? ssn : "********"}
        </div>
    )
}

export default MaskedSSN;
