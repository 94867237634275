import React from 'react'
import {Modal} from 'reactstrap'
import PropertyForm from './PropertyForm'

import { ReactComponent as Close } from '../../../svgs/close.svg'

export default function PropertyModal({isOpen, title, data, onClose, match}) {

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{minWidth: '65vw'}}
        >
            <div>
                <a
                    className="close close-left"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <Close />
                </a>
                <div className="modal-content" style={{height: '100vh'}}>
                    <div className="col-md-12 p-0 col-h">
                        <div className="modal-body p-0">
                            <PropertyForm data={data} match={match} onClose={onClose} title={title}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
