import React from 'react'
import { Field } from 'formik'

const PainLevel = ({ painLevelTypes, values }) => {
  const multiLevelPainView = (i) => {
    let painLevelValue = i === 1 ? 1 : i === 2 ? 4 : i === 3 ? 7 : 10

    let innerPainLevel = []
    for (let count = 0, j = painLevelValue; count < 3; count++, j++) {
      if (j === 10) {
        innerPainLevel.push(
          <div className="form-check form-check-inline" key={j}>
            <Field
              className="form-check-input"
              type="radio"
              name={`incidentDetails.painLevel`}
              checked={values?.incidentDetails?.painLevel === j.toString()}
              value={j}
              id={j}
            />
            <label className="mt-2" htmlFor={j}>
              {j}
            </label>
          </div>,
        )
        break
      }
      innerPainLevel.push(
        <div className="form-check form-check-inline" key={j}>
          <Field
            className="form-check-input"
            type="radio"
            name={`incidentDetails.painLevel`}
            checked={values?.incidentDetails?.painLevel === j.toString()}
            value={j}
            id={j}
          />
          <label className="mt-2" htmlFor={j}>
            {j}
          </label>
        </div>,
      )
    }
    return innerPainLevel
  }

  return (
    <div className="d-flex justify-content-between flex-wrap pain-level">
      {painLevelTypes?.map((painLevelType, i) => (
        <div className="text-center" key={i}>
          <label className="col-form-label font-weight-bold">
            {painLevelType}
          </label>
          {i === 0 ? (
            <div className="">
              <div className="form-check form-check-inline">
                <Field
                  className="form-check-input"
                  type="radio"
                  name={`incidentDetails.painLevel`}
                  checked={values?.incidentDetails?.painLevel === i.toString()}
                  value={i}
                  id={i}
                />
                <label className="mt-2" htmlFor={i}>
                  {i}
                </label>
              </div>
            </div>
          ) : (
            <div className="d-flex">{multiLevelPainView(i)}</div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PainLevel
