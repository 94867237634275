import React, { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import classNames from 'classnames'
import { DatePickerCustomHeader, months } from './DatePickerCustomHeader'

/**
 * Generate ID for input field
 * @param {string | undefined} name Name of the input field
 * @returns ID of the input field
 */
function generateInputId(name) {
  return name ? `input-${name}` : undefined
}

function DatePickerField({ withAsterisk, ...props }) {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field] = useField(props)
  const inputId = useMemo(
    () => props.id ?? generateInputId(field.name),
    [props.id, field.name],
  )

  const [dateMonth, setDateMonth] = useState({ day: '', month: '' })

  useEffect(() => {
    if (field.value && dateMonth.day.length === 0) {
      const date = new Date(field.value)
      setDateMonth({ day: date.getDate(), month: date.getMonth() })
    }
  }, [field])

  return (
    <div
      className={classNames('form-group', props?.className || '', {
        'short-date-picker': props?.label === 'Due on',
      })}
    >
      <div>
        <label htmlFor={inputId}>
          {props.label} {withAsterisk && <span className="text-danger">*</span>}
        </label>
        <span
          className="date_icon icon_date_icon due_date_icon"
          style={
            props?.label !== 'Due on' || dateMonth?.day?.length === 0
              ? { display: 'none' }
              : {}
          }
        >
          <span className="date_icon_month">
            {months[dateMonth.month]?.substr(0, 3)}
          </span>
          <span className="date_icon_day">{dateMonth.day}</span>
        </span>
      </div>
      {props.customFormat ? (
        <DatePicker
          id={inputId}
          data-testid={inputId}
          title={props?.label}
          autoComplete="off"
          className="form-control"
          dateFormat={props.customFormat}
          placeholderText="MM/DD"
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          renderCustomHeader={DatePickerCustomHeader}
          onChange={(val) => {
            setDateMonth({
              day: val?.getDate() ?? '',
              month: val?.getMonth() ?? '',
            })
            setFieldValue(field.name, val)
          }}
          onKeyDown={(e) => {
            e.preventDefault()
          }}
          onBlur={() => setFieldTouched(field.name, true, true)}
          onChangeRaw={() => {
            setFieldTouched(field.name, true, true)
          }}
        />
      ) : (
        <DatePicker
          id={inputId}
          data-testid={inputId}
          title={props?.label}
          autoComplete="off"
          className="form-control"
          dateFormat="MM/dd/yyyy"
          placeholderText="MM/DD/YYYY"
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          renderCustomHeader={DatePickerCustomHeader}
          onChange={(val) => {
            setDateMonth({
              day: val?.getDate() ?? '',
              month: val?.getMonth() ?? '',
            })
            setFieldValue(field.name, val)
          }}
          onChangeRaw={() => {
            setFieldTouched(field.name, true, true)
          }}
          onBlur={() => setFieldTouched(field.name, true, true)}
          // onKeyDown={(e) => {
          //   e.preventDefault()
          // }}
        />
      )}
      <ErrorMessage
        data-testid={`error-message-${field.name}`}
        className="text-danger"
        name={field.name}
        component="div"
      />
    </div>
  )
}

export default DatePickerField
