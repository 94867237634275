import React from 'react'
import ReactDOM from 'react-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_NODE_ENV !== "local") {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_NODE_ENV ?? process.env.NODE_ENV
    });
}

ReactDOM.render(<App />, document.getElementById('root'))

//Overwrite console
if (process.env.NODE_ENV === "production") {
    console.log = function () {
        //Do nothing
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
