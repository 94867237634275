import moment from 'moment'
import React from 'react'

const FooterPublicIncident = () => {
  return (
    <footer className="p-3 px-4 container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between footer-bottom m-0">
      <div className="text-dark order-2 order-md-1">
        <span>Copyright © {moment().year()} All Rights Reserved</span>
      </div>
    </footer>
  )
}

export default FooterPublicIncident
