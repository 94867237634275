import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { handleSideNavElementClicked } from '../../actions'
import { getClient } from '../../services/clientService'
import {
  useCommentAccess,
  useGetPermission,
} from '../../hooks/useGetPermission'
import { transformPhoneNumber } from '../../pages/incident/utils'

export default function EmployeesRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const [clientInfo, setclientInfo] = useState({})

  const deletePermission = useGetPermission('employee', 'DELETE')
  const editPermission = useGetPermission('employee', 'UPDATE')
  const addCommentPermission = useCommentAccess('employee', 'CREATE')

  useEffect(async () => {
    const { data: client } = await getClient(data?.clientId)
    setclientInfo(client.data)
  }, [data])

  const getAddress = (address) => {
    let addressString = ''
    if (address) {
      addressString += address.line1 ? address.line1 + ', ' : ''
      addressString += '\n'
      addressString += address.line2 ? address.line2 + ', ' : ''
      addressString += address.city ? address.city : ''
      addressString += '\n'
      addressString += address.state ? address.state + ', ' : ''
      addressString += address.country ? address.country + '-' : ''
      addressString += address.pinCode ? address.pinCode : ''
    }

    return addressString
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3 d-flex flex-column bd-highlight text-center mt-1"
                data-toggle="tooltip"
                data-placement="top"
                title={`${data?.firstName} ${data?.lastName}`}
                data-original-title={`${data?.firstName} ${data?.lastName}`}
              >
                <div>
                  <img
                    className="profile-pic"
                    src={data?.image || '/assets/images/user-profile.svg'}
                    id="wizardPicturePreview"
                    style={{ height: '80px', width: '80px' }}
                  />
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div
                  className="text-black-50 py-1"
                  style={{ textTransform: 'uppercase' }}
                >
                  {data?.employeeId || 'N/A'}
                </div>
                {clientInfo.companyName || 'N/A'}
              </span>
              {data?.emergencyContact && (
                <>
                  <p className=" mt-3 mb-0">
                    Emergency Contact{' '}
                    {data?.emergencyContact?.relation || 'N/A'}
                  </p>
                  <p
                    className="text-dark"
                    style={{ color: '#000', fontWeight: 500 }}
                  >
                    {data?.emergencyContact.name &&
                      _.map(
                        (data?.emergencyContact.name || ' ')?.split(' '),
                        _.capitalize,
                      ).join(' ')}
                    &nbsp;
                    {transformPhoneNumber(
                      data?.emergencyContact?.phoneNumber,
                    ) || 'N/A'}
                  </p>
                </>
              )}
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          <div className="text-center px-5 pb-2">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}
            {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
            )}
            {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="false"
                      aria-controls="faq1"
                    >
                      Employee Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Name</div>
                          <span>
                            {' '}
                            {_.capitalize(data?.firstName) +
                              ' ' +
                              _.capitalize(data?.lastName)}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Employee Status</div>
                          <span className="text-warning">
                            {_.capitalize(data?.status) || 'N/A'}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Birthday</div>
                          <span>
                            {data?.dateOfBirth
                              ? moment(data?.dateOfBirth).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Email ID</div>
                          <span>{data?.email || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Phone (Personal)</div>
                          <span>
                            {transformPhoneNumber(data?.personalPhone) || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Phone (Work)</div>
                          <span>
                            {transformPhoneNumber(data?.officePhone) || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Is Driver?</div>
                          <span>{data?.isDriver ? 'Yes' : 'No'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Marital Status</div>
                          <span>
                            {_.capitalize(data?.maritalStatus) || 'N/A'}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Date of Joining</div>
                          <span>
                            {data?.dateOfJoining
                              ? moment(data?.dateOfJoining).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Date of Termination</div>
                          <span>
                            {data?.terminationDetails?.terminatedOn
                              ? moment(
                                  data?.terminationDetails?.terminatedOn,
                                ).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          <div className="d-flex"> Address</div>
                          <span>
                            {getAddress(data?.address)}
                            {/* {data?.address &&
                              `${data?.address?.line1 + ',' || ''}`}
                            <br />
                            {data?.address &&
                              `${data?.address?.line2 + ',' || ''} ${
                                data?.address?.city + ',' || ''
                              }`}
                            <br />
                            {data?.address &&
                              `${data?.address?.state + ',' || ''} ${
                                data?.address?.country + '' || ''
                              } - ${data?.address?.pinCode || ''}`} */}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-12">
                        <label className="incident-text mb-0">
                          <div className="d-flex"> Reason For Termination</div>
                        </label>
                        <p
                          className=""
                          style={{
                            color: '#000',
                            fontWeight: 600,
                            lineHeight: 1.6,
                          }}
                        >
                          {data?.terminationDetails?.reason || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="">
                <div className="pt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.167"
                              height="18.25"
                              viewBox="0 0 12.167 18.25"
                            >
                              <g id="smartphone" transform="translate(-4)">
                                <path
                                  id="Path_17004"
                                  data-name="Path 17004"
                                  d="M12.365,0H7.8A3.807,3.807,0,0,0,4,3.8V14.448a3.807,3.807,0,0,0,3.8,3.8h4.562a3.807,3.807,0,0,0,3.8-3.8V3.8A3.807,3.807,0,0,0,12.365,0ZM7.8,1.521h4.562A2.281,2.281,0,0,1,14.646,3.8v8.365H5.521V3.8A2.281,2.281,0,0,1,7.8,1.521Zm4.562,15.208H7.8a2.281,2.281,0,0,1-2.281-2.281v-.76h9.125v.76A2.281,2.281,0,0,1,12.365,16.729Z"
                                />
                                <circle
                                  id="Ellipse_739"
                                  data-name="Ellipse 739"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(9.083 13.25)"
                                />
                              </g>
                            </svg>
                          </span>{' '}
                          Phone (Personal)
                        </div>
                        <span className="ml-4">{data?.personalPhone}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                id="phone-call"
                                d="M8.153.625A.626.626,0,0,1,8.779,0a6.267,6.267,0,0,1,6.26,6.26.626.626,0,0,1-1.252,0A5.014,5.014,0,0,0,8.779,1.251.626.626,0,0,1,8.153.625Zm.626,3.13a2.5,2.5,0,0,1,2.5,2.5.626.626,0,0,0,1.252,0A3.76,3.76,0,0,0,8.779,2.5a.626.626,0,1,0,0,1.252Zm5.692,6.723a1.941,1.941,0,0,1,0,2.741l-.57.657C8.775,18.785-3.7,6.311,1.131,1.168l.72-.626A1.929,1.929,0,0,1,4.56.567c.019.019,1.179,1.526,1.179,1.526a1.941,1.941,0,0,1,0,2.681l-.725.911a8,8,0,0,0,4.339,4.348l.917-.729a1.941,1.941,0,0,1,2.68,0S14.452,10.459,14.471,10.478Zm-.861.91s-1.5-1.153-1.517-1.172a.689.689,0,0,0-.97,0c-.017.018-1.28,1.024-1.28,1.024a.626.626,0,0,1-.613.1A9.4,9.4,0,0,1,3.708,5.821.626.626,0,0,1,3.8,5.2S4.8,3.932,4.822,3.916a.689.689,0,0,0,0-.97C4.8,2.927,3.65,1.428,3.65,1.428a.689.689,0,0,0-.945.024l-.72.626c-3.532,4.247,7.28,14.459,11,10.942l.57-.657a.7.7,0,0,0,.053-.975Z"
                                transform="translate(-0.039 0.002)"
                              />
                            </svg>
                          </span>{' '}
                          Phone (Work)
                        </div>
                        <span className="ml-4">{data?.officePhone}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14.999"
                              viewBox="0 0 15 14.999"
                            >
                              <path
                                id="cake-birthday"
                                d="M14.375,13.751H13.75v-5a3.129,3.129,0,0,0-3.125-3.125h-2.5V3.775a1.562,1.562,0,0,0,.937-1.431A4.558,4.558,0,0,0,7.961.205a.625.625,0,0,0-.922,0,4.558,4.558,0,0,0-1.1,2.139,1.562,1.562,0,0,0,.937,1.431V5.626h-2.5A3.129,3.129,0,0,0,1.25,8.751v5H.625a.625.625,0,0,0,0,1.25h13.75a.625.625,0,0,0,0-1.25Zm-10-6.875h6.25A1.875,1.875,0,0,1,12.5,8.751V9.988c-.585-.062-.937-.437-.937-.612a.625.625,0,0,0-1.25,0c0,.214-.426.625-1.094.625s-1.094-.434-1.094-.625a.625.625,0,0,0-1.25,0c0,.214-.426.625-1.094.625s-1.094-.434-1.094-.625a.625.625,0,0,0-1.25,0c0,.2-.362.555-.937.613V8.751A1.875,1.875,0,0,1,4.375,6.876ZM2.5,11.238a2.6,2.6,0,0,0,1.563-.612,2.693,2.693,0,0,0,3.438.009,2.693,2.693,0,0,0,3.438-.009,2.6,2.6,0,0,0,1.562.611v2.514H2.5Z"
                                transform="translate(0 -0.002)"
                              />
                            </svg>
                          </span>{' '}
                          Birthday
                        </div>
                        <span className="ml-4">
                          {new Date(data?.dateOfBirth).toLocaleDateString(
                            'pt-PT',
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="13.75"
                              viewBox="0 0 15 13.75"
                            >
                              <path
                                id="envelope"
                                d="M11.875,1H3.125A3.129,3.129,0,0,0,0,4.125v7.5A3.129,3.129,0,0,0,3.125,14.75h8.75A3.129,3.129,0,0,0,15,11.625v-7.5A3.129,3.129,0,0,0,11.875,1ZM3.125,2.25h8.75a1.875,1.875,0,0,1,1.738,1.179L8.826,8.216a1.879,1.879,0,0,1-2.652,0L1.388,3.429A1.875,1.875,0,0,1,3.125,2.25Zm8.75,11.25H3.125A1.875,1.875,0,0,1,1.25,11.625V5.063L5.29,9.1a3.129,3.129,0,0,0,4.42,0l4.04-4.038v6.563A1.875,1.875,0,0,1,11.875,13.5Z"
                                transform="translate(0 -1)"
                              />
                            </svg>
                          </span>{' '}
                          Email ID
                        </div>
                        <span className="ml-4">{data?.email}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14.999"
                              viewBox="0 0 15 14.999"
                            >
                              <path
                                id="taxi"
                                d="M14.374,6.875a.625.625,0,1,0,0-1.25H13.5l-.481-1.551a3.1,3.1,0,0,0-2.581-2.167A3.114,3.114,0,0,0,7.572,0H7.426A3.114,3.114,0,0,0,4.562,1.907a3.1,3.1,0,0,0-2.58,2.166L1.5,5.625H.624a.625.625,0,1,0,0,1.25h.488L.666,8.312a2.474,2.474,0,0,0,.584,3.84v.972a1.875,1.875,0,0,0,3.75,0V12.5h5v.625a1.875,1.875,0,0,0,3.75,0v-.972a2.474,2.474,0,0,0,.584-3.839l-.447-1.439ZM7.426,1.25h.146a1.869,1.869,0,0,1,1.391.625H6.035A1.867,1.867,0,0,1,7.426,1.25ZM3.176,4.444a1.866,1.866,0,0,1,1.79-1.319h5.066a1.866,1.866,0,0,1,1.791,1.319l.957,3.084A2.463,2.463,0,0,0,12.5,7.5H2.5a2.463,2.463,0,0,0-.281.028Zm.573,8.681a.625.625,0,1,1-1.25,0V12.5h1.25Zm8.75,0a.625.625,0,1,1-1.25,0V12.5H12.5Zm0-1.875H2.5a1.25,1.25,0,0,1,0-2.5v.625a.625.625,0,0,0,1.25,0V8.75h7.5v.625a.625.625,0,0,0,1.25,0V8.75a1.25,1.25,0,1,1,0,2.5Z"
                                transform="translate(0.001)"
                              />
                            </svg>
                          </span>{' '}
                          Occupation
                        </div>
                        <span className="ml-4">
                          {data?.isDriver ? 'Driver' : ''}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.156"
                              height="17.161"
                              viewBox="0 0 17.156 17.161"
                            >
                              <path
                                id="venus-mars"
                                d="M15.733,0h-2.86a.715.715,0,0,0,0,1.43h1.849L13.006,3.146a4.963,4.963,0,0,0-5.5-.321A5,5,0,1,0,4.292,12.1v1.487H2.862a.715.715,0,1,0,0,1.43h1.43v1.43a.715.715,0,0,0,1.43,0v-1.43h1.43a.715.715,0,1,0,0-1.43H5.722V12.1A4.958,4.958,0,0,0,7.5,11.474a5,5,0,0,0,6.513-7.316l1.716-1.716V4.29a.715.715,0,1,0,1.43,0V1.43A1.43,1.43,0,0,0,15.733,0ZM1.432,7.15A3.554,3.554,0,0,1,6.286,3.821a4.974,4.974,0,0,0,0,6.658A3.554,3.554,0,0,1,1.432,7.15Zm8.58,3.575A3.575,3.575,0,1,1,13.588,7.15,3.575,3.575,0,0,1,10.013,10.726Z"
                                transform="translate(-0.007)"
                              />
                            </svg>
                          </span>{' '}
                          Marital Status
                        </div>
                        <span className="ml-4">{data?.maritalStatus}</span>
                      </label>
                    </div>
                    {data?.isDriver && (
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">
                            <span className="mr-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.537"
                                height="15.537"
                                viewBox="0 0 15.537 15.537"
                              >
                                <path
                                  id="id-badge"
                                  d="M12.3,2.589H9.711V1.942a1.942,1.942,0,1,0-3.884,0v.647H3.237A3.241,3.241,0,0,0,0,5.826V12.3a3.241,3.241,0,0,0,3.237,3.237H12.3A3.241,3.241,0,0,0,15.537,12.3V5.826A3.241,3.241,0,0,0,12.3,2.589ZM7.121,1.942a.647.647,0,0,1,1.295,0V3.237a.647.647,0,0,1-1.295,0ZM14.242,12.3A1.942,1.942,0,0,1,12.3,14.242H3.237A1.942,1.942,0,0,1,1.295,12.3V5.826A1.942,1.942,0,0,1,3.237,3.884H5.945a1.93,1.93,0,0,0,3.646,0H12.3a1.942,1.942,0,0,1,1.942,1.942ZM6.474,6.474H3.237a.647.647,0,0,0-.647.647V12.3a.647.647,0,0,0,.647.647H6.474a.647.647,0,0,0,.647-.647V7.121A.647.647,0,0,0,6.474,6.474Zm-.647,5.179H3.884V7.768H5.826Zm7.121-1.942a.647.647,0,0,1-.647.647H9.063a.647.647,0,0,1,0-1.295H12.3A.647.647,0,0,1,12.947,9.711Zm0-2.589a.647.647,0,0,1-.647.647H9.063a.647.647,0,0,1,0-1.295H12.3A.647.647,0,0,1,12.947,7.121ZM11.653,12.3a.647.647,0,0,1-.647.647H9.063a.647.647,0,0,1,0-1.295h1.942A.647.647,0,0,1,11.653,12.3Z"
                                />
                              </svg>
                            </span>{' '}
                            Licence number
                          </div>
                          <span className="ml-4">{data?.licenceNumber}</span>
                        </label>
                      </div>
                    )}
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.003"
                              height="15"
                              viewBox="0 0 15.003 15"
                            >
                              <path
                                id="map"
                                d="M12.927.731l-.013,0-1.7-.563A3.136,3.136,0,0,0,9.4.115L5.919,1.127a1.891,1.891,0,0,1-1.28-.113L4.326.869A3.126,3.126,0,0,0,0,3.752V11.3a3.134,3.134,0,0,0,2.25,3l1.793.563A3.12,3.12,0,0,0,4.978,15a2.87,2.87,0,0,0,.835-.113l3.626-1a1.875,1.875,0,0,1,1.011.008l1.465.423A2.5,2.5,0,0,0,15,11.892V3.675A3.132,3.132,0,0,0,12.927.731ZM2.61,13.1a1.883,1.883,0,0,1-1.36-1.8V3.752A1.846,1.846,0,0,1,2.085,2.19a1.875,1.875,0,0,1,1.04-.313,1.813,1.813,0,0,1,.7.139s.466.2.554.231V13.655Zm3.016.543V2.426a3.191,3.191,0,0,0,.629-.1l3.122-.907V12.629Zm8.126-1.754a1.25,1.25,0,0,1-1.521,1.221l-1.6-.452V1.3l1.887.612a1.879,1.879,0,0,1,1.239,1.764Z"
                                transform="translate(0 -0.004)"
                              />
                            </svg>
                          </span>{' '}
                          State
                        </div>
                        <span className="ml-4">
                          {data?.address && data?.address.state}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.001"
                              height="16.001"
                              viewBox="0 0 16.001 16.001"
                            >
                              <path
                                id="rocket"
                                d="M12,4.666A.667.667,0,1,1,11.334,4,.667.667,0,0,1,12,4.666ZM9.334,6A.667.667,0,1,0,10,6.666.667.667,0,0,0,9.334,6Zm-2,2A.667.667,0,1,0,8,8.667.667.667,0,0,0,7.334,8Zm8.578-6.028-.05.141A25.371,25.371,0,0,1,12,9.88V10a7.347,7.347,0,0,1-2.4,5.423A2.176,2.176,0,0,1,8.134,16,2.14,2.14,0,0,1,6,13.863V13.1A3.3,3.3,0,0,1,4.693,12.3l-.026-.025L3.138,13.8a.667.667,0,1,1-.943-.943l1.529-1.529L3.7,11.308A3.32,3.32,0,0,1,2.9,10h-.66A2.244,2.244,0,0,1,0,7.758,2.267,2.267,0,0,1,.625,6.2,7.282,7.282,0,0,1,6,4h.12A25.365,25.365,0,0,1,13.884.14l.144-.051a1.467,1.467,0,0,1,1.883,1.883ZM2.242,8.667h.491a3.333,3.333,0,0,1,.594-1.633A16.909,16.909,0,0,1,4.533,5.5,5.671,5.671,0,0,0,1.6,7.113a.938.938,0,0,0-.267.645A.909.909,0,0,0,2.242,8.667ZM10.467,11.5a17.035,17.035,0,0,1-1.5,1.173,3.322,3.322,0,0,1-1.632.584v.6a.805.805,0,0,0,.8.8.847.847,0,0,0,.568-.23A6,6,0,0,0,10.467,11.5ZM14.625,1.375a.127.127,0,0,0-.145-.032l-.143.052c-3.229,1.163-6.018,2.169-8.42,4.569A13.912,13.912,0,0,0,4.419,7.8a2,2,0,0,0,.222,2.567l.995.993a2,2,0,0,0,2.566.223,13.878,13.878,0,0,0,1.834-1.5c2.4-2.4,3.407-5.191,4.571-8.422l.051-.141a.128.128,0,0,0-.032-.147Z"
                                transform="translate(0 0.001)"
                              />
                            </svg>
                          </span>{' '}
                          Date of Joining
                        </div>
                        <span className="ml-4">
                          {new Date(data?.dateOfJoining).toLocaleDateString(
                            'pt-PT',
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <path
                                id="ban"
                                d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,1.333a6.633,6.633,0,0,1,4.216,1.508L2.841,12.216A6.661,6.661,0,0,1,8,1.333ZM8,14.667a6.633,6.633,0,0,1-4.216-1.508l9.375-9.375A6.661,6.661,0,0,1,8,14.667Z"
                              />
                            </svg>
                          </span>{' '}
                          Date of Termination
                        </div>
                        <span className="ml-4">8/20/2021</span>
                      </label>
                    </div>
                    <div className="col-md-12">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.386"
                              height="17.876"
                              viewBox="0 0 16.386 17.876"
                            >
                              <path
                                id="map-marker-home"
                                d="M9.193,0A8.193,8.193,0,0,0,3.421,14.007l2.937,2.712a4.053,4.053,0,0,0,5.655.015l2.973-2.748A8.193,8.193,0,0,0,9.193,0Zm4.761,12.913-2.967,2.742a2.617,2.617,0,0,1-3.6-.013L4.453,12.933a6.7,6.7,0,1,1,9.5-.02ZM12.7,5.708l-2.234-1.55a2.24,2.24,0,0,0-2.547,0L5.685,5.708a2.234,2.234,0,0,0-.961,1.836v2.512a1.862,1.862,0,0,0,1.862,1.862H11.8a1.862,1.862,0,0,0,1.862-1.862V7.544A2.234,2.234,0,0,0,12.7,5.708Zm-.529,4.348a.372.372,0,0,1-.372.372H10.683V8.938a.745.745,0,0,0-.745-.745H8.448a.745.745,0,0,0-.745.745v1.49H6.586a.372.372,0,0,1-.372-.372V7.544a.745.745,0,0,1,.32-.612l2.234-1.55a.749.749,0,0,1,.849,0l2.234,1.55a.745.745,0,0,1,.32.612Z"
                                transform="translate(-1)"
                              />
                            </svg>
                          </span>{' '}
                          Address
                        </div>
                        <span className="ml-4">
                          {data?.address && data?.address.line1},
                          {data?.address && data?.address.line2},
                          {data?.address && data?.address.city},
                          {data?.address && data?.address.state},
                          {data?.address && data?.address.country} -
                          {data?.address && data?.address.pinCode}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
