import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { handleSideNavElementClicked } from '../../actions'
import moment from 'moment'
import { addCommaTableField } from '../../pages/incident/utils'
import {
  useCommentAccess,
  useGetPermission,
} from '../../hooks/useGetPermission'

export default function ClientRecordInfo({ onEdit, onDelete }) {
  const [activeCardId, setActiveCardId] = useState('parent')
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)

  const deletePermission = useGetPermission('client', 'DELETE')
  const editPermission = useGetPermission('client', 'UPDATE')
  const addCommentPermission = useCommentAccess('client', 'CREATE')

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  let cardHeaderClicked = (sId) => {
    setActiveCardId(sId)
  }

  let getAccountantsCardView = () => {
    let accountantViews = []

    data?.accountants?.forEach(function (accountant, index) {
      accountantViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white p-3 mb-3">
            <div className="d-flex justify-content-between">
              <h6 className="text-primery weekly">
                {_.map(
                  (accountant?.name || ' ')?.split(' '),
                  _.capitalize,
                ).join(' ')}
              </h6>
            </div>
            <div className="row mt-3">
              <div className="weekly col-md-4">
                <small>As of Date:</small>
                { accountant.addedOn ? moment(accountant.addedOn).format('MM/DD/YYYY') : 'N/A'}
              </div>
              <div className="weekly col-md-4">
                <small>Notes:</small>
                ***********
              </div>
            </div>
          </div>
        </div>,
      )
    })

    return accountantViews
  }

  let getLawyersCardView = () => {
    let lawyerViews = []

    data?.lawyers?.forEach(function (lawyer, index) {
      lawyerViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white p-3 mb-3 ">
            <div className="d-flex justify-content-between">
              <h6 className="text-primery weekly">
                {' '}
                {_.map((lawyer?.name || ' ')?.split(' '), _.capitalize).join(
                  ' ',
                )}
              </h6>
            </div>
            <div className="row mt-3">
              <div className="weekly col-md-4">
                <small>As of Date:</small>
                {lawyer.addedOn
                  ? moment(lawyer.addedOn).format('MM/DD/YYYY')
                  : 'N/A'}
              </div>
              <div className="weekly col-md-4">
                <small>Notes:</small>
                ***********
              </div>
            </div>
          </div>
        </div>,
      )
    })

    return lawyerViews
  }

  let getAnnualRevenueCardView = () => {
    let annualRevenueViews = []

    data?.annualRevenue?.forEach(function (ar, index) {
      annualRevenueViews.push(
        <div className="col-md-4" key={index}>
          <div className="parties price bg-white p-3 mb-3 mx-2 mt-3 claim-taken-view">
            <h6 className="text-primery">{ar.year || 'N/A'}</h6>
            <small className="mb-2">
              ${ar.revenue ? addCommaTableField(ar.revenue) : 'N/A'}
            </small>
          </div>
        </div>,
      )
    })

    return annualRevenueViews
  }

  let getOwnershipStructureCardView = () => {
    let ownershipStructureViews = []

    data?.ownershipStructure?.forEach(function (os, index) {
      ownershipStructureViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white p-3 mb-3">
            <h6 className="text-primery">{os.name || 'N/A'}</h6>

            <div className="d-flex ">
              <div>
                <i className="fas fa-mobile-alt mr-2" aria-hidden="true"></i>
              </div>
              <div> {os.phoneNumber ? os.phoneNumber : 'N/A'}</div>
            </div>
            <div className="d-flex justify-content-start">
              <div>
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
              </div>
              <div>
                {os.address.state},{os.address.country} -{os.address.pinCode} 
              </div>
            </div>

            <div className="mt-2">
              <span className="badge badge-pill btn btn-outline-primary pt-1">
                {os.ownerShare ? os.ownerShare : 0}% Owned
              </span>
            </div>
          </div>
        </div>,
      )
    })

    return ownershipStructureViews
  }

  let getPayrollProcessorCardView = () => {
    let payrollProcessorViews = []

    data?.payrollProcessor?.forEach(function (pp, index) {
      payrollProcessorViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white p-3 mb-3">
            <div className="d-flex justify-content-between">
              <h6 className="text-primery weekly">
                {_.map((pp.name || ' ')?.split(' '), _.capitalize).join(' ')}
              </h6>
            </div>
            <div className="row mt-3">
              <div className="weekly col-md-4">
                <small>As of Date:</small>
                {pp.addedOn ? moment(pp.addedOn).format('MM/DD/YYYY') : 'N/A'}
              </div>
              <div className="weekly col-md-4">
                <small>Notes:</small>
                ***********
              </div>
              <div className="weekly col-md-4">
                <small>Payroll Cost:</small>
                {pp.payrollCost ? addCommaTableField(pp.payrollCost) : 'N/A'}
              </div>
            </div>
            <div className="mt-3">
              <span className="badge badge-pill btn btn-outline-primary pt-1">
                {pp.frequency ? pp.frequency : 'N/A'} frequency
              </span>
            </div>
          </div>
        </div>,
      )
    })

    return payrollProcessorViews
  }

  let getThirdPartySoftwareCardView = () => {
    let thirdPartySoftwareViews = []

    data.thirdPartySoftware.forEach(function (tps, index) {
      thirdPartySoftwareViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white p-3 mb-3">
            <div className="d-flex justify-content-between">
              <h6 className="text-primery weekly">
                {' '}
                {_.map((tps?.name || ' ')?.split(' '), _.capitalize).join(' ')}
              </h6>
            </div>
            <div className="row mt-3">
              <div className="weekly col-md-4">
                <small>As of Date:</small>
                {tps.addedOn ? moment(tps.addedOn).format('MM/DD/YYYY') : 'N/A' }
              </div>
              <div className="weekly col-md-4">
                <small>Notes:</small>
                ***********
              </div>
            </div>
          </div>
        </div>,
      )
    })

    return thirdPartySoftwareViews
  }

  let getOrganisationStructureView = () => {
    let organisationStructureViews = []

    data?.organisationStructure?.forEach(function (os, index) {
      organisationStructureViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white border mt-4 p-3 mb-3">
            <h6 className="text-primery">{os.name}</h6>
            <div className="col-md-6">
              <label className="incident-text">
                Position
                <span>{os.position || 'N/A'}</span>
              </label>
            </div>
            <div className="col-md-6">
              <label className="incident-text">
                Email
                <span>{os.email || 'N/A'}</span>
              </label>
            </div>
            <div className="col-md-6">
              <label className="incident-text">
                Phone Number
                <span>{os.phoneNumber || 'N/A'}</span>
              </label>
            </div>
          </div>
        </div>,
      )
    })

    return organisationStructureViews
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-4 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="right"
                title="Client Creation Date"
                data-original-title={moment(data.created).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {moment(data.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {moment(data.created).format('YYYY')}
                </div>
              </div>
            </div>
            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data.clientId}</div>
                {data.companyName}
              </span>
              <p className="mt-3 mb-1 text-black-50">Corporation Type</p>
              <span>{data.corporationType}</span>
            </div>
            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          <div className="text-center px-5 pb-2">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}
            {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
            )}
            {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            )}
          </div>
        </div>
        <div className="pr-2 pb-4 overflow-auto">
          <div id="main1">
            <div className="container">
              <div
                className="accordion"
                id="faq"
                style={{ paddingBottom: '20px' }}
              >
                <div className="card">
                  <div
                    className="card-header1"
                    id="faqhead1"
                    onClick={cardHeaderClicked.bind(this, 'parent')}
                  >
                    <a
                      href="#"
                      className={
                        'btn btn-header-link ' +
                        (activeCardId !== 'parent' ? 'collapsed' : '')
                      }
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Company Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="collapse show pt-3"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Company Name<span>{data.companyName || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          FEIN/ TAX ID<span>{data.taxId || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Corporation Type
                          <span>{data.corporationType || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Fiscal Year End
                          <span>
                            {data.fiscalYear
                              ? moment(data.fiscalYear).format('MM/DD')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Incorporation State
                          <span>{_.capitalize(data.incorporationState) || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Issue Date
                          <span>
                          { data.issueDate ? moment(data.issueDate).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          Tax ID
                          <span>{data.taxId || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Unemployment Id
                          <span>
                            {data.unemployementId ? data.unemployementId : 'N/A'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {data?.accountants?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'account')}
                  >
                    <div className="card-header1" id="faqhead2">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'account' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Accountants
                      </a>
                    </div>
                    <div
                      id="faq2"
                      className="collapse"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      {getAccountantsCardView()}
                    </div>
                  </div>
                )}
                {data?.lawyers?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'lawyer')}
                  >
                    <div className="card-header1" id="faqhead3">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'lawyer' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Lawyers
                      </a>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      {getLawyersCardView()}
                    </div>
                  </div>
                )}
                {data?.annualRevenue?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'revenue')}
                  >
                    <div className="card-header1" id="faqhead4">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'revenue' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="true"
                        aria-controls="faq4"
                      >
                        Annual Revenue
                      </a>
                    </div>
                    <div
                      id="faq4"
                      className="collapse"
                      aria-labelledby="faqhead4"
                      data-parent="#faq"
                    >
                      <div className="d-flex flex-wrap">
                        {getAnnualRevenueCardView()}
                      </div>
                    </div>
                  </div>
                )}
                {data?.ownershipStructure?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'ownership')}
                  >
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'ownership' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq5"
                        aria-expanded="true"
                        aria-controls="faq5"
                      >
                        Ownership Structure
                      </a>
                    </div>
                    <div
                      id="faq5"
                      className="collapse"
                      aria-labelledby="faqhead5"
                      data-parent="#faq"
                    >
                      {getOwnershipStructureCardView()}
                    </div>
                  </div>
                )}
                {data?.payrollProcessor?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'payroll')}
                  >
                    <div className="card-header1" id="faqhead6">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'payroll' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq6"
                        aria-expanded="true"
                        aria-controls="faq6"
                      >
                        Payroll Processor
                      </a>
                    </div>
                    <div
                      id="faq6"
                      className="collapse"
                      aria-labelledby="faqhead6"
                      data-parent="#faq"
                    >
                      {getPayrollProcessorCardView()}
                    </div>
                  </div>
                )}
                {data?.thirdPartySoftware?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'tps')}
                  >
                    <div className="card-header1" id="faqhead7">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'tps' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq7"
                        aria-expanded="true"
                        aria-controls="faq7"
                      >
                        Third Party Software
                      </a>
                    </div>
                    <div
                      id="faq7"
                      className="collapse"
                      aria-labelledby="faqhead7"
                      data-parent="#faq"
                    >
                      {getThirdPartySoftwareCardView()}
                    </div>
                  </div>
                )}
                {data?.organisationStructure?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'orgStr')}
                  >
                    <div className="card-header1" id="faqhead8">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'orgStr' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq8"
                        aria-expanded="true"
                        aria-controls="faq8"
                      >
                        Organization Structure
                      </a>
                    </div>
                    <div
                      id="faq8"
                      className="collapse"
                      aria-labelledby="faqhead8"
                      data-parent="#faq"
                    >
                      {getOrganisationStructureView()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
