import React from 'react'
import { Modal } from 'reactstrap'
import EmployeeForm from './EmployeeForm'

import { ReactComponent as Close } from '../../../svgs/close.svg'

export default function EmployeeModal({ isOpen, title, data, onClose, match }) {

    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{minWidth: '65vw'}}
        >
            <div>
                <a
                    className="close close-left"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <Close />
                </a>
                <div className="modal-content" style={{ height: '100vh' }}>
                    <div className="col-md-12 p-0 col-h">
                        <div className="modal-body p-0">
                            <EmployeeForm data={data} match={match} title={title} onClose={onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
