import React, { useState, useEffect, useRef } from 'react'
import ReactECharts from 'echarts-for-react'
// import { Link } from 'react-router-dom'
// import { getPolicyByPolicyType } from '../../../../services/policyService'
import { getCoverageType } from '../../../../services/claimService'

function ClaimsIncurredCoverage(props) {
  const [options, setOptions] = useState(null)
  const mountedRef = useRef(true)

  const getClaimsIncurredTypeCoverage = async () => {
    try {
      const { data } = await getCoverageType()
      if (!mountedRef.current) return null
      setOptions(createOptions(data.data))
    } catch (error) {
      console.log(error)
    }
  }

  const createOptions = (data) => {
    return {
      color: ['#0f0f8a', '#53c23a', '#EA2027', '#f39c12', '#16a085'],
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        right: 0,
        itemHeight: 14,
        itemWidth: 14,
        top: '18%',
        textStyle: {
          fontSize: 8,
        },
      },
      series: [
        {
          zoom: 0.5,
          name: 'Claim Incurred',
          type: 'pie',
          radius: ['50%', '100%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          center: ['40%', '50%'],
          labelLine: {
            show: false,
          },
          top: 5,
          bottom: 5,
          data: data.map((p) => {
            let sum = 0
            p.claims.forEach((claim) =>
              claim.incidents.claimants.financials.forEach(
                (financial) => (sum += financial.amount),
              ),
            )
            return {
              value: sum,
              name: p._id,
            }
          }),
        },
      ],
    }
  }

  useEffect(() => {
    mountedRef.current = true
    getClaimsIncurredTypeCoverage()
    return () => { 
      mountedRef.current = false
    }
  }, [])

  return (
    <div className="card card-custom bg-gray-100 card-stretch gutter-b">
      <div className="card-header align-items-center border-0 mt-2  text-center">
        <h6 className="card-title align-items-start flex-column">
          <span className="font-weight-bolder text-dark cus-header">
            Claims Incurred by Type of Coverage
          </span>
        </h6>
      </div>
      <div className="card-body">
        <div
          className="card-rounded-bottom"
          style={{ height: '174px', minHeight: '174px' }}
        >
          <div className="text-center mt-5">
            {options ? (
              <ReactECharts
                option={options}
                style={{ height: '150px', width: '100%', paddingTop: '3px' }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="text-center mb-4 mt-3" style={{cursor: "pointer"}} onClick={props.onViewClaimsClick}>
        <a className="pt-2" onClick={props.onViewClaimsClick}>View Claims</a>
      </div>
    </div>
  )
}

export default ClaimsIncurredCoverage
