import JWT from 'jsrsasign'

function parseJWT(token) {
  const isValid = JWT.jws.JWS.verifyJWT(token, '965912', { alg: ['HS256'] })
  if (!isValid) return new Error('Error:- Invalid JWT')

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

function generateToken(data) {
  const tokenHeader = { alg: 'HS256', type: 'JWT' }
  const tokenPayload = {}
  const tokenNow = JWT.jws.IntDate.get('now')
  const tokenEnd = JWT.jws.IntDate.get('now + 1day')

  tokenPayload.data = data
  // Sign JWT, password = 965912

  const sHeader = JSON.stringify(tokenHeader)
  const sPayload = JSON.stringify(tokenPayload)
  const sJWT = JWT.jws.JWS.sign('HS256', sHeader, sPayload, '965912')
  return sJWT
}

export { parseJWT, generateToken }
