import { ErrorMessage, Field } from 'formik'
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import TextInput from '../../components/form/TextInput'
import { notificationMethod } from '../incident/utils'

function SupervisorDetailForm({ values, setFieldValue, setFieldTouched }) {
  return (
    <div>
      <div className="border-bottom h6 py-2 mb-4">
        <span className="text-primery font-weight-bold">
          Supervisor Details
        </span>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label> Did you notify your supervisor? <span className='text-danger'>*</span></label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`supervisorDetails.isNotify`}
                value={'true'}
                id="isNotifyYes"
              />
              <label className="mt-2" htmlFor="isNotifyYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                value={'false'}
                name={`supervisorDetails.isNotify`}
                id="isNotifyNo"
              />
              <label className="mt-2" htmlFor="isNotifyNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            data-testid="isNotifyError"
            className="text-danger"
            name={`supervisorDetails.isNotify`}
            component="div"
          />
          {values?.supervisorDetails?.isNotify === 'false' && (
            <div data-testid="isNotifyError-custom" className="text-danger">
              Please notify your supervisor and then submit again
            </div>
          )}
        </div>
        {values?.supervisorDetails?.isNotify === 'true' && (
          <div className="form-row">
            <div className="form-group col-md-6">
              <TextInput
                withAsterisk
                name={`supervisorDetails.name`}
                label="Supervisor Name"
              />
            </div>
            <div className="form-group col-md-6">
              <TextInput
                withAsterisk
                type="email"
                name={`supervisorDetails.email`}
                label="Supervisor Email"
              />
            </div>
            <div className="form-group col-md-6">
              <label>Contact Number <span className='text-danger'>*</span></label>
              <PhoneInput
                // displayInitialValueAsLocalNumber={true}
                limitMaxLength={true}
                value={values?.supervisorDetails?.contactNumber || ''}
                name="supervisorDetails.contactNumber"
                defaultCountry="US"
                international={false}
                className={'form-control'}
                placeholder="Contact Number"
                onChange={(phone) => {
                  if (phone) {
                    setFieldValue('supervisorDetails.contactNumber', phone || '')
                  } else {
                    setFieldValue('supervisorDetails.contactNumber', '')
                  }
                }}
                onBlur={() => {
                  if (values?.supervisorDetails?.contactNumber === '') {
                    setFieldTouched('supervisorDetails.contactNumber', true)
                  }
                }}
                data-testid="input-supervisorDetails.contactNumber"
              />
              <ErrorMessage
                data-testid="error-message-supervisorDetails.contactNumber"
                className="text-danger"
                name="supervisorDetails.contactNumber"
                component="div"
              />
            </div>
            {/* <div className="form-group col-md-6">
              <TextInput
                isPhone
                label="Contact Number"
                name="supervisorDetails.contactNumber"
                onPhoneChange={(phone) => {
                  setFieldValue('supervisorDetails.contactNumber', phone || '')
                }}
                value={values?.supervisorDetails?.contactNumber || ''}
              />
            </div> */}
            <div className="form-group col-md-6">
              <DatePickerField
                maxDate={new Date()}
                name="supervisorDetails.notifiedDate"
                label="Date Supervisor Notified of Incident:"
              />
            </div>
            <div className="form-group col-md-6">
              <Select
                label="Method Supervisor Notified"
                name="supervisorDetails.notificationMethod"
                defaultLabel="Select Notification Method"
                options={notificationMethod}
                value={values?.supervisorDetails?.notificationMethod || ''}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SupervisorDetailForm
