import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
  saveClaimInfo,
  saveIncidentInfo,
  savePolicyInfo
} from '../../actions'
import FilterDropdown from '../../components/common/FilterDropdown'
import ImportCSV from '../../components/common/importCSV'
import ListPageHeader from '../../components/ListPageHeader'
import { stateOptions } from '../../configs/utils'
import { useShowModule } from '../../hooks/useShowModule'
import { getClaims } from '../../services/claimService'
import { exportClients, getClient } from '../../services/clientService'
import {
  downloadFile,
  getPromiseArrayForImport,
  parseFile
} from '../../services/helperService'
import { getIncidents } from '../../services/incidentService'
import { getPolicies } from '../../services/policyService'
import { exportUsers, getUser } from '../../services/userService'
import ClientList from './client/ClientList'
import ClientModal from './client/ClientModal'
import UserList from './user/UserList'
import UserModal from './user/UserModal'

function UserManagement(props) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [editId, setEditId] = useState('')
  const [context, setContext] = useState('')
  const [tab, setTab] = useState('user')
  const [searchQuery, setSearchQuery] = useState('')
  const [checkedRecords, setCheckedRecords] = useState([])
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [openForm, setOpenForm] = useState({ isOpen: false, data: null })
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false) 
  const selectedTabId = useSelector((state) => state.selectedTabId) 

  const initialDateFilterState = {
    fiscalYear: {
      label: ' Select date for Fiscal Year',
      start: null,
      end: null,
      key: 'fiscalYear',
    },
  }
  
  const [dateRangeFilter, setDateRangeFilter] = useState(initialDateFilterState)
  const [selectedRoleOption, setSelectedRoleOption] = useState([]);  
  const [selectedDesignationOption, setSelectedDesignationOption] = useState([]);  
  const [userFilters, setUserFilters] = useState({
    role: {
      name: 'role',
      label: 'Select Role',
      key: 'role',
      isAutocomplete: true,
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selectedOption: 'selectedRoleOption',
      setSelectedOption:'setSelectedRoleOption',
      selected: [],
    },
    designation: {
      name: 'designation',
      label: 'Select Designation',
      key: 'designation',
      isAutocomplete: true,
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selectedOption: 'selectedDesignationOption',
      setSelectedOption:'setSelectedDesignationOption',
      selected: [],
    },
  })

  const [clientFilters, setClientFilters] = useState({

    corporationType: {
      name: 'corporationType',
      label: 'Select Corporation Type',
      key: 'corporationType',
      options: [
        {
          value: 'Corp-C',
          key: 'Corp-C',
        },
        {
          value: 'Corp-S',
          key: 'Corp-S',
        },
        {
          value: 'LLC',
          key: 'LLC',
        },
        {
          value: 'LLP',
          key: 'LLP',
        },
        {
          value: 'Sole-Prop',
          key: 'Sole-Prop',
        },
      ],
      selected: '',
    },
    incorporationState: {
      name: 'incorporationState',
      label: 'Select Incorporation State',
      key: 'incorporationState',
      isAutocomplete: true,
      options: stateOptions,      
      selected: [],
    },
  })

  const [openBulkDel, setOpenBulkDel] = useState(false)
  const [openBulkClientDel, setOpenBulkClientDel] = useState(false)
  const { calendarInfo } = useSelector((state) => state)
  const { showModule } = useShowModule()

  // useEffect(() => {
  //   setShowDeleteButton(checkedRecords.length > 0)
  // }, [checkedRecords])

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const exportToCSV = async () => {
    const fileName = tab === 'user' ? 'Users.csv' : 'Clients.csv'
    const { data: policyData } =
      tab === 'user' ? await exportUsers() : await exportClients()
    downloadFile(policyData, fileName)
  }

  const handleEdit = async (item) => {
    if (tab === 'user') {
      setTitle('Edit User')
      await fetchUser(item._id)
    } else if(tab === 'client') {
      setTitle('Edit Client')
      await fetchClient(item._id)
    }
  }

  const handleView = async (item) => {
    if (tab === 'user') {
      dispatch(handleTabIdUpdated('user'))
      setTitle('View User')
      await fetchUser(item._id, 'view')
    } else if(tab === 'client'){
      dispatch(handleTabIdUpdated('client'))
      setTitle('View Client')
      await fetchClient(item._id, 'view')
    }
  }

  const fetchClient = async (id, context) => {
    const { data: client } = await getClient(id)
    setEditId(id)
    dispatch(
      handleSideNavDataUpdated({ data: client.data, module: 'client' }),
    )
    context !== 'view' && setOpenForm({ isOpen: true, data: client.data })
    context === 'view' && 
      dispatch(handleSideNavElementClicked('info'))
  }

  const fetchUser = async (id, context) => {
    const { data: user } = await getUser(id)
    dispatch(handleSideNavDataUpdated({ data: user.data, module: 'user' }))
    context !== 'view' && setOpenForm({ isOpen: true, data: user.data })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const importFromCSV = async (file) => {
    try {
      const type = tab === 'user' ? 'user' : 'client'
      const res = await parseFile(file)
      const createPolicyPromiseArray = getPromiseArrayForImport(res, type)
      await Promise.all(createPolicyPromiseArray)
      toast.success(`${res.length} items are imported`)
      setContext('fetchList')
      setImportCSVDialogOpen(false)
    } catch (err) {}
  }

  const handleAddNew = () => {
    setOpenForm({ isOpen: true, data: null })
    if (tab === 'user') {
      setTitle('Add New User')
    } else {
      setTitle('Add New Client')
    }
  }

  useEffect(() => {
    if (
      !(
        calendarInfo.claim.length &&
        calendarInfo.incident.length &&
        calendarInfo.policy.length
      )
    ) {
      let modules = ['claim', 'incident', 'policy']
      let fetchCalenderInfo = modules.filter((m) => showModule([m]))

      Promise.all([
        fetchCalenderInfo.includes('claim') && getClaims(),
        fetchCalenderInfo.includes('incident') && getIncidents(),
        fetchCalenderInfo.includes('policy') && getPolicies(),
      ])
        .then(([{ data: claims }, { data: incidents }, { data: policies }]) => {
          if (claims.data && Array.isArray(claims.data)) {
            dispatch(saveClaimInfo(claims.data))
          }

          if (incidents.data && Array.isArray(incidents.data)) {
            dispatch(saveIncidentInfo(incidents.data))
          }

          if (policies.data && Array.isArray(policies.data)) {
            dispatch(savePolicyInfo(policies.data))
          }
        })
        .catch((error) => {})
    }
  }, [])
  
  useEffect(()=> {
    if(selectedTabId){
      setTab(selectedTabId);
    }
  },[selectedTabId])

  let getTableView = () => {
    if (tab === 'user') {
      return (
        <UserList
          context={context}
          handleView={handleView}
          searchQuery={searchQuery}
          checkedRecords={checkedRecords}
          setShowDeleteButton={setShowDeleteButton}
          handleElementCheckboxClicked={handleElementCheckboxClicked}
          handleEdit={handleEdit}
          handleDechecked = {handleDechecked}
          openBulkDel={openBulkDel}
          closeBulkDel={() => setOpenBulkDel(false)}
          selectFilters={Object.values(userFilters)
            .filter(({ selected }) => selected.length)
            .reduce((prev, { key, selected }) => {
              prev[key] = selected
              return prev
            }, {})}
          setSelectFilter={(designationList, roleList) => {
            const pFilter = { ...userFilters }
            pFilter.designation.options = designationList
            pFilter.role.options = roleList
            setUserFilters(pFilter)
          }}
        />
      )
    } else if (tab === 'client') {
      return (
        <ClientList
          context={context}
          handleView={handleView}
          searchQuery={searchQuery}
          checkedRecords={checkedRecords}
          setShowDeleteButton={setShowDeleteButton}
          handleElementCheckboxClicked={handleElementCheckboxClicked}
          handleEdit={handleEdit}
          handleDechecked = {handleDechecked}
          dateRangeFilter={dateRangeFilter}
          openBulkDel={openBulkClientDel}
          closeBulkDel={() => setOpenBulkClientDel(false)}
          selectFilters={Object.values(clientFilters)
            .filter(({ selected }) => selected.length)
            .reduce((prev, { key, selected }) => {
              prev[key] = selected
              return prev
            }, {})}
          setSelectFilter={(incorporationStateList) => {
            const pFilter = { ...clientFilters }
            pFilter.incorporationState.options = incorporationStateList
            // pFilter.corporationType.options = corporationTypeList
            setClientFilters(pFilter)
          }}
        />
      )
    }
  }

  const handleModalClose = () => {
    setEditId('')
    context === 'fetchList' ? setContext('') : setContext('fetchList')
    setOpenForm({ data: null, isOpen: false })
  }

  let getModal = () => {
    if (tab === 'user') {
      return (
        <UserModal
          id={editId}
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )
    } else {
      return (
        <ClientModal
          id={editId}
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )
    }
  }

  let getTabsData = () => {
    return [
      {
        module: 'USER',
        id: 'user',
        label: 'App Users',
      },
      {
        module: 'CLIENT',
        id: 'client',
        label: 'Clients',
        style: { marginRight: '48px' },
      },
    ]
  }

  const onClear = () => {
    setUserFilters((user) => ({
      role: { ...user['role'], selected: '' },
      designation: { ...user['designation'], selected: '' },
    }))
  
  }

  const onClearClientFilter = () => {
    setClientFilters((client) => ({
      incorporationState: { ...client['incorporationState'], selected: '' },
      corporationType: { ...client['corporationType'], selected: '' },
    }))
    setDateRangeFilter(initialDateFilterState)
  }


  const selectedFilterCount = useMemo(() => {
    let count = 0
    if (tab === 'user') {    
      Object.values(userFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })
    }
    if (tab === 'client') {
      Object.values(clientFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })
      Object.values(dateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })  
    }
    return count
  }, [userFilters,clientFilters,dateRangeFilter])

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView:
          tab === 'user' ? (           
            <FilterDropdown
              selectFilters={Object.values(userFilters)}
              setSelectedFilter={setUserFilters}              
              selectedFilterCount={selectedFilterCount}
              onClear={onClear}              
            />
          ) : (            
            <FilterDropdown
              selectFilters={Object.values(clientFilters)}
              setSelectedFilter={setClientFilters}
              selectedFilterCount={selectedFilterCount}
              dateRangeFilter={dateRangeFilter}
              setDateRangeFilter={setDateRangeFilter}
              onClear={onClearClientFilter}
            />
          ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  const handleElementCheckboxClicked = (data, context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (data.length !== checkedRecords.length) {
        data.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  const handleTabChange = (tab) => {
    setCheckedRecords([])
    setTab(tab)
    dispatch(handleTabIdUpdated(tab))
  }

  const handleDechecked = () => {
    setCheckedRecords([])
  }

  return (
    <div
      className={'position-rel'}
      style={{ marginBottom: '15px', width: 'calc(100% - 70px)' }}
    >
      <ListPageHeader
        modules={['USER', 'CLIENT']}
        showTabs={true}
        showFilters={true}
        selectedTabId={tab}
        onAdd={handleAddNew}
        setTab={handleTabChange}
        tabsData={getTabsData()}
        searchQuery={searchQuery}
        addCategory={addCategory}
        heading={'User Management'}
        onSearchChange={handleSearch}
        filtersData={getFiltersData()}
        handleExportToCsv={exportToCSV}
        removeCategory={removeCategory}
        hideDeleteButton={!showDeleteButton}
        selectedCategories={selectedCategories}
        handleBulkCheckClick={() => {
          tab == 'user'
            ? setOpenBulkDel(true)
            : setOpenBulkClientDel(true)
        }}
        handleImportfromCsv={importFromCSVButtonClicked}
        filterCount={selectedFilterCount}
      />
      {getTableView()}
      {openForm.isOpen && getModal()}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSV}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}

export default UserManagement
