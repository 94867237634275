import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Select1 from 'react-select'
import { Modal } from 'reactstrap'
import ErrorMessage from '../../components/ErrorMessage'
import AddressInput from '../../components/form/AddressInput'
import { getEmployees } from '../../services/employeeService'
import { getVehicles } from '../../services/vehicleService'
import { PARTY_INVOLVED_VALIDATION_SCHEMA } from '../../utils/validation'
import { vehicleDamageOptions } from './utils'
import { useSelector } from 'react-redux'

const initialPersonalDetails = {
  firstName: '',
  lastName: '',
  email: '',
  organisation: '',
  contactNumber: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
  },
  employeeId: '',
}

const initialInjuryDetails = {
  natureOfInjury: '',
  fatality: false,
  initialTreatmentGiven: '',
  injuryType: '',
  injuryTypes: [],
  painLevel: 1,
  medicalTreatmentRequired: false,
  covidRelated: false,
  status: '',
  ambulanceStatus: '',
}

const initialVehicleDetails = {
  make: '',
  model: '',
  year: '',
  vinNumber: '',
  vehId: '',
  mileage: '',
  vanNumber: '',
  vehicleDamages: [],
  licensePlate: '',
  state: '',
  otherVehicleDamages: '',
}

const painLevel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

// const options = [
//   { label: 'Bumper-Front', value: 'Bumper-Front' },
//   { label: 'Bumper-Back', value: 'Bumper-Back' },
//   { label: 'Mirror–Driver Side', value: 'Mirror–Driver Side' },
//   { label: 'Mirror-Passenger Side', value: 'Mirror-Passenger Side' },
//   { label: 'Driver Side', value: 'Driver Side' },
//   { label: 'Passenger Side', value: 'Passenger Side' },
//   { label: 'Others', value: 'Others' },
// ]

const TabType = {
  PersonDetails: 'personDetails',
  InjuryDetails: 'injuryDetails',
  VehicleDetails: 'vehicleDetails',
}

function PartiesInvolvedModal({
  isOpen,
  onClose,
  values,
  setFieldValue,
  editId,
  editData,
  setPartiesInvolvedValidation,
  isVehicularIncident,
  directClaim = false,
  clientId = '',
}) {
  const ref = useRef()
  const [data, setData] = useState(null)
  const [injuryDetails, setInjuryDetails] = useState({
    natureOfInjury: '',
    fatality: false,
    initialTreatmentGiven: '',
    injuryType: '',
    painLevel: 1,
    medicalTreatmentRequired: false,
    covidRelated: false,
    status: '',
    ambulanceStatus: '',
    injuryTypes: [],
  })
  const clients = useSelector((state) => state.clients)
  // const [personDetailVisible, setPersonDetailVisible] = useState(true)
  const [activeTab, setActiveTab] = useState(TabType.PersonDetails)
  const [personDetails, setPersonDetails] = useState(initialPersonalDetails)
  const [filteredVehList, setFilteredVehList] = useState([])
  const [vehicleList, setVehicleList] = useState(null)
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(-1)
  const [vehicleDetails, setvehicleDetails] = useState(initialVehicleDetails)
  const selectedClient = useMemo(() => {
    return clients?.find((client) => client._id === values?.clientId)
  }, [values?.clientId])

  useEffect(() => {
    if (selectedClient) {
      setFieldValue(
        'partiesInvolved.0.personDetails.organisation',
        selectedClient?.companyName,
      )
    }
  }, [selectedClient?._id])

  const getVehiclesData = async () => {
    const { data: vehData } = await getVehicles()
    setVehicleList(vehData.data)
  }

  const initialValues = {
    clientId: values.clientId,
    personDetails: {
      firstName: '',
      lastName: '',
      email: '',
      organisation: '',
      contactNumber: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        pinCode: '',
      },
      employeeId: '',
    },
    injuryDetails: {
      natureOfInjury: '',
      fatality: false,
      initialTreatmentGiven: '',
      injuryType: '',
      painLevel: 1,
      medicalTreatmentRequired: false,
      covidRelated: false,
      status: '',
      ambulanceStatus: '',
    },
    isCompanyEmployee: false,
    hasInjury: false,
    vehicleDetails: {
      make: '',
      model: '',
      year: '',
      vinNumber: '',
      vehId: '',
      mileage: '',
      vanNumber: '',
      vehicleDamages: [],
      licensePlate: '',
      state: '',
      otherVehicleDamages: '',
    },
  }

  useEffect(async () => {
    let vehDataByClient = vehicleList?.filter(
      (a) =>
        a?.clientId === initialValues?.clientId || a?.clientId === clientId,
    )

    setFilteredVehList([
      ...(vehDataByClient || []),
      { _id: 'OTHERS', value: 'Other', key: 'Other' },
    ])
  }, [vehicleList, clientId])

  useEffect(() => {
    if (editData) {
      const vehicleDetailsData = editData.vehicleDetails
      if (vehicleDetailsData) {
        setSelectedVehicleIndex(filteredVehList.length - 1)
        setvehicleDetails(vehicleDetailsData)
      }

      const isThirdPartyVehicle = vehicleDetailsData?.vehicleType !== 'company'
      setData({
        isThirdPartyVehicle,
        isCompanyEmployee: editData.isCompanyEmployee,
        hasInjury: editData.hasInjury,
        hospitalName: editData.hospitalName,
      })

      setInjuryDetails({
        ...editData.injuryDetails,
        injuryTypes:
          editData?.injuryDetails?.injuryTypes?.map((e) => {
            const valueExists = injuryDetailsOptions
              .filter((f) => f.value.toLowerCase() === e.toLowerCase())
              ?.shift()
            if (valueExists) {
              return valueExists.value
            } else {
              return e
            }
          }) ?? [],
      })
      const {
        firstName,
        lastName,
        email,
        organisation,
        contactNumber,
        address,
        employeeId,
      } = editData.personDetails
      setPersonDetails({
        firstName,
        lastName,
        email,
        organisation,
        contactNumber: contactNumber,
        employeeId,
        address: { ...address },
      })
      // const vehicleDamages = vehicleDetails?.vehicleDamageParts
    } else {
      setData({
        isThirdPartyVehicle: false,
        isCompanyEmployee: false,
        hasInjury: false,
        hospitalName: '',
      })
    }
    getVehiclesData()
    getEmployeesData()
  }, [editData])

  const handleSave = () => {
    const a = {
      ...data,
      personDetails: {
        ...personDetails,
        employeeId: data.isCompanyEmployee ? personDetails.employeeId : '',
        isCompanyEmployee: data.isCompanyEmployee ?? false,
      },
      injuryDetails,
      vehicleDetails,
    }
    // update address field based on person is company employee or not
    if (editId === null) {
      a['personDetails']['address'] = data.isCompanyEmployee
        ? personDetails.employeeId !== 'OTHERS'
          ? { ...personDetails.address }
          : ref.current?.values?.address
        : ref.current?.values?.address || {}
      delete values['address']
    } else if (editId !== null) {
      a['personDetails']['address'] = data.isCompanyEmployee
        ? personDetails.employeeId !== 'OTHERS'
          ? { ...personDetails.address }
          : ref.current?.values?.personDetails.address
        : ref.current?.values?.personDetails.address || {}
    }
    const party =
      editId === null
        ? [...values.partiesInvolved, a]
        : values?.partiesInvolved.map((item, index) => {
            if (index === editId) {
              return a
            } else {
              return item
            }
          })
    if (editId === 0 && data.isCompanyEmployee) {
      if (directClaim) {
        setFieldValue(
          'incident.userDetails.employeeId',
          a?.personDetails?.employeeId === 'OTHERS'
            ? 'OTHER'
            : a?.personDetails?.employeeId || '',
        )
        setFieldValue(
          'incident.userDetails.firstName',
          a?.personDetails?.firstName,
        )
        setFieldValue(
          'incident.userDetails.lastName',
          a?.personDetails?.lastName,
        )
        setFieldValue('incident.userDetails.email', a?.personDetails?.email)
        setFieldValue(
          'incident.userDetails.contactNumber',
          a?.personDetails?.contactNumber,
        )
        setFieldValue(
          'incident.userDetails.address',
          a?.personDetails?.address?.line1,
        )
        setFieldValue(
          'incident.userDetails.zip',
          a?.personDetails?.address?.pinCode,
        )
        setFieldValue(
          'incident.userDetails.city',
          a?.personDetails?.address?.city,
        )
        setFieldValue(
          'incident.userDetails.state',
          a?.personDetails?.address?.state,
        )
        setFieldValue(
          'incident.userDetails.country',
          a?.personDetails?.address?.country,
        )
      } else {
        setFieldValue(
          'userDetails.employeeId',
          a?.personDetails?.employeeId === 'OTHERS'
            ? 'OTHER'
            : a?.personDetails?.employeeId || '',
        )
        setFieldValue('userDetails.firstName', a?.personDetails?.firstName)
        setFieldValue('userDetails.lastName', a?.personDetails?.lastName)
        setFieldValue('userDetails.email', a?.personDetails?.email)
        setFieldValue(
          'userDetails.contactNumber',
          a?.personDetails?.contactNumber,
        )
        setFieldValue('userDetails.address', a?.personDetails?.address?.line1)
        setFieldValue('userDetails.zip', a?.personDetails?.address?.pinCode)
        setFieldValue('userDetails.city', a?.personDetails?.address?.city)
        setFieldValue('userDetails.state', a?.personDetails?.address?.state)
        setFieldValue('userDetails.country', a?.personDetails?.address?.country)
      }
    }

    if (directClaim) {
      setFieldValue('incident.partiesInvolved', party)
    } else {
      setFieldValue('partiesInvolved', party)
    }
    setPartiesInvolvedValidation(false)
    onClose()
    setPersonDetails(initialPersonalDetails)
    setInjuryDetails(initialInjuryDetails)
    setvehicleDetails(initialVehicleDetails)
    ref.current.resetForm()
  }

  const [employeList, setEmployeList] = useState([])
  const [filterEmpList, setFilterEmpList] = useState([])

  const getEmployeesData = async () => {
    const { data: employeListData } = await getEmployees()
    setEmployeList(employeListData.data)
  }

  useEffect(() => {
    if (data?.isCompanyEmployee) {
      let employeListByClient = employeList.filter(
        (a) => a.clientId === values.clientId || a.clientId === clientId,
      )
      setFilterEmpList([
        ...employeListByClient,
        { _id: 'OTHERS', value: 'Other', key: 'Other' },
      ])
    } else {
      setFilterEmpList([])
    }
  }, [data?.isCompanyEmployee, values?.clientId, clientId])

  const onEmployeeSelected = (emp) => {
    if (emp._id) {
      setPersonDetails((x) => ({
        ...x,
        firstName: emp.firstName,
        lastName: emp.lastName,
        email: emp.email,
        organisation: emp?.organisation || '',
        contactNumber: emp?.personalPhone + '' || '',
        employeeId: emp._id,
        address: emp.address,
      }))
    } else {
      setPersonDetails({ ...initialPersonalDetails, employeeId: 'OTHERS' })
    }
  }
  return (
    <Modal
      data-testid="parties-involved-modal"
      isOpen={isOpen}
      backdrop={'static'}
      centered
      style={{
        height: '400px',
        position: 'absolute',
        top: '44px',
        top: '-23%',
        left: '31%',
        minWidth: '500px',
      }}
    >
      <div>
        <div className="modal-header justify-content-center">
          <h5 className="modal-title text-primary " id="">
            {editData ? 'Update' : 'Add'} party involved in the incident
          </h5>
        </div>

        <Formik
          innerRef={ref}
          validationSchema={PARTY_INVOLVED_VALIDATION_SCHEMA}
          enableReinitialize
          initialValues={{ ...initialValues, ...editData }}
          onSubmit={handleSave}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <div className="modal-body p-0" id="smodaltab">
              <ul
                className="nav nav-pills bg-light d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link  mt-0 font-weight-normal ${
                      activeTab === TabType.PersonDetails ? 'active' : ''
                    }`}
                    id="Person-tab"
                    data-toggle="pill"
                    href="#Person"
                    role="tab"
                    aria-controls="pills-Person"
                    aria-selected="true"
                    onClick={() => setActiveTab(TabType.PersonDetails)}
                  >
                    Person Details
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className={`nav-link mt-0 font-weight-normal ${
                      activeTab === TabType.InjuryDetails ? 'active' : ''
                    }`}
                    id="pills-injury-tab"
                    data-toggle="pill"
                    href="#pills-injury"
                    role="tab"
                    aria-controls="pills-injury"
                    aria-selected="false"
                    onClick={() => setActiveTab(TabType.InjuryDetails)}
                  >
                    Injury Details
                  </a>
                </li>
                {isVehicularIncident && (
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link mt-0 font-weight-normal ${
                        activeTab === TabType.VehicleDetails ? 'active' : ''
                      }`}
                      id="pills-injury-tab"
                      data-toggle="pill"
                      href="#pills-injury"
                      role="tab"
                      aria-controls="pills-injury"
                      aria-selected="false"
                      onClick={() => setActiveTab(TabType.VehicleDetails)}
                    >
                      Vehicle Details
                    </a>
                  </li>
                )}
              </ul>
              <div
                className="tab-content"
                id="pill-tabContent1"
                style={{ height: '400px', overflowY: 'auto' }}
              >
                <div
                  className="tab-pane fade show active"
                  id="Person"
                  role="tabpanel"
                  aria-labelledby="Person-tab"
                >
                  {activeTab === TabType.PersonDetails && data && (
                    <>
                      <div className="py-4 d-flex justify-content-center align-items-center">
                        <span className="mr-4">
                          {' '}
                          Is this a company employee?
                        </span>
                        <div
                          className="btn-group btn-group-toggle border btn-yesno"
                          data-toggle="buttons"
                        >
                          <label
                            className={`btn mr-2 px-3 ${
                              data.isCompanyEmployee ? 'active' : ''
                            }`}
                            onClick={() =>
                              setData((x) => ({
                                ...x,
                                isCompanyEmployee: true,
                              }))
                            }
                          >
                            <input
                              type="radio"
                              name="dd"
                              id="option6"
                              checked={data.isCompanyEmployee}
                              readOnly
                            />
                            Yes
                          </label>
                          <label
                            className={`btn px-3 ${
                              !data.isCompanyEmployee ? 'active' : ''
                            }`}
                            onClick={() => {
                              setData((x) => ({
                                ...x,
                                isCompanyEmployee: false,
                              }))
                            }}
                          >
                            <input
                              type="radio"
                              name="dd"
                              id="option5"
                              checked={!data.isCompanyEmployee}
                              readOnly
                            />
                            No
                          </label>
                        </div>
                      </div>
                      {data.isCompanyEmployee && (
                        <div
                          style={{
                            margin: 'auto',
                            width: '381px',
                            paddingBottom: '15px',
                          }}
                        >
                          <Select1
                            placeholder="Select Employee"
                            getOptionLabel={(option) => {
                              if (option.employeeId) {
                                return `${option.employeeId} - ${option.firstName} ${option.lastName}`
                              } else {
                                return option.value
                              }
                            }}
                            getOptionValue={(option) => option}
                            name="incidentProperty"
                            value={filterEmpList.find(
                              (e) => e._id == personDetails.employeeId,
                            )}
                            isSearchable="true"
                            onChange={(val) => {
                              onEmployeeSelected(val)

                              setFieldValue(
                                'personDetails.firstName',
                                val.firstName,
                              )
                              setFieldValue(
                                'personDetails.lastName',
                                val.lastName,
                              )
                              setFieldValue('personDetails.email', val.email)
                              setFieldValue(
                                'personDetails.organisation',
                                val.organisation || '',
                              )
                              setFieldValue(
                                'personDetails.contactNumber',
                                val.personalPhone || '',
                              )
                              setFieldValue('personDetails.employeeId', val._id)
                            }}
                            options={filterEmpList}
                          />
                        </div>
                      )}
                      {(!data.isCompanyEmployee ||
                        (data.isCompanyEmployee &&
                          personDetails.employeeId === 'OTHERS')) && (
                        <div className="px-5">
                          <form>
                            <div className="form-row">
                              <div className="form-group required col-md-6">
                                <label
                                  htmlFor="f-name"
                                  className="col-form-label"
                                >
                                  {!data.isCompanyEmployee && `3rd Party `}
                                  First Name
                                  <span className="text-danger"> *</span>
                                </label>
                                <input
                                  type="text"
                                  data-testid="personDetail-firstName"
                                  className="form-control"
                                  id="f-name"
                                  name={`personDetails.firstName`}
                                  value={personDetails.firstName}
                                  onChange={(a) => {
                                    handleChange(a)
                                    setPersonDetails((x) => ({
                                      ...x,
                                      firstName: a.target.value,
                                    }))
                                  }}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`personDetails.firstName`}
                                />
                              </div>

                              <div className="form-group col-md-6">
                                <label
                                  htmlFor="lname"
                                  className="col-form-label"
                                >
                                  {!data.isCompanyEmployee && `3rd Party `}
                                  Last Name{' '}
                                  <span className="text-danger"> *</span>
                                </label>
                                <input
                                  data-testid="personDetail-lastName"
                                  value={personDetails.lastName}
                                  onChange={(a) => {
                                    handleChange(a)
                                    setPersonDetails((x) => ({
                                      ...x,
                                      lastName: a.target.value,
                                    }))
                                  }}
                                  type="text"
                                  className="form-control"
                                  id="lname"
                                  name={`personDetails.lastName`}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`personDetails.lastName`}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  htmlFor="email"
                                  className="col-form-label"
                                >
                                  {!data.isCompanyEmployee && `3rd Party `}
                                  Email
                                </label>
                                <input
                                  value={personDetails.email}
                                  onChange={(a) =>
                                    setPersonDetails((x) => ({
                                      ...x,
                                      email: a.target.value,
                                    }))
                                  }
                                  type="email"
                                  className="form-control"
                                  id="email"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  htmlFor="Company"
                                  className="col-form-label"
                                >
                                  {!data.isCompanyEmployee && `3rd Party `}
                                  Company
                                </label>
                                <input
                                  value={personDetails.organisation}
                                  onChange={(a) =>
                                    setPersonDetails((x) => ({
                                      ...x,
                                      organisation: a.target.value,
                                    }))
                                  }
                                  type="text"
                                  className="form-control"
                                  id="Company"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  htmlFor="Contact"
                                  className="col-form-label"
                                >
                                  {!data.isCompanyEmployee && `3rd Party `}
                                  Contact Number
                                </label>
                                <PhoneInput
                                  name={`personDetails.contactNumber`}
                                  defaultCountry="US"
                                  // displayInitialValueAsLocalNumber={true}
                                  international={false}
                                  className={'form-control'}
                                  placeholder="Contact Number"
                                  onChange={(phone) => {
                                    setFieldValue(
                                      'personDetails.contactNumber',
                                      phone || '',
                                    )
                                    setPersonDetails((x) => ({
                                      ...x,
                                      contactNumber: phone || '',
                                    }))
                                  }}
                                  id="Contact"
                                  maxLength={14}
                                  value={personDetails.contactNumber || ''}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name={`personDetails.contactNumber`}
                                />
                              </div>

                              <div className="form-group col-md-12">
                                <AddressInput
                                  label={`${
                                    !data.isCompanyEmployee ? '3rd Party ' : ''
                                  }Address`}
                                  name={
                                    editId !== null
                                      ? `personDetails.address`
                                      : `address`
                                  }
                                  setFieldValue={setFieldValue}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </>
                  )}

                  {activeTab === TabType.InjuryDetails && data && (
                    <div
                      id="pills-injury"
                      role="tabpanel"
                      aria-labelledby="pills-injury-tab"
                    >
                      <div
                        className="tab-pane fade show"
                        id="Person"
                        role="tabpanel"
                        aria-labelledby="Person-tab"
                      >
                        <div className="py-4 d-flex justify-content-center align-items-center">
                          <span className="mr-4"> Has Injury?</span>
                          <div
                            className="btn-group btn-group-toggle border btn-yesno"
                            data-toggle="buttons"
                          >
                            <label
                              className={
                                'btn mr-2 px-3 ' +
                                (data.hasInjury ? 'active' : '')
                              }
                            >
                              <input
                                type="radio"
                                name="yes"
                                checked={data.hasInjury}
                                onChange={() =>
                                  setData((x) => ({ ...x, hasInjury: true }))
                                }
                              />
                              Yes
                            </label>
                            <label
                              className={
                                'btn px-3 ' + (!data.hasInjury ? 'active' : '')
                              }
                            >
                              <input
                                type="radio"
                                name="yes"
                                checked={!data.hasInjury}
                                onChange={() =>
                                  setData((x) => ({ ...x, hasInjury: false }))
                                }
                              />
                              No
                            </label>
                          </div>
                        </div>
                        {data.hasInjury && (
                          <div className="px-5">
                            <form>
                              <div className="form-group">
                                <label
                                  htmlFor="Injury"
                                  className="col-form-label"
                                >
                                  Nature of Injury
                                </label>
                                <textarea
                                  value={injuryDetails.natureOfInjury}
                                  onChange={(a) =>
                                    setInjuryDetails((x) => ({
                                      ...x,
                                      natureOfInjury: a.target.value,
                                    }))
                                  }
                                  className="form-control"
                                  id="Injury"
                                ></textarea>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  Fatality
                                </label>
                                <div className="d-flex">
                                  <div className="form-check form-check-inline mr-5">
                                    <input
                                      checked={injuryDetails.fatality}
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          fatality: true,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio9"
                                      value="option9"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio9"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      checked={!injuryDetails.fatality}
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          fatality: false,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio10"
                                      value="option10"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio10"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="form-group">
                                <label
                                  htmlFor="ambulance-status"
                                  className="col-form-label"
                                >
                                  Was Ambulance Called?
                                </label>
                                <div className="d-flex">
                                  <div className="form-check form-check-inline mr-5">
                                    <input
                                      checked={injuryDetails.ambulanceStatus}
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          ambulanceStatus: true,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="ambulanceOptions"
                                      id="ambulanceOption1"
                                      value="yes"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ambulanceOption1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      checked={!injuryDetails.ambulanceStatus}
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          ambulanceStatus: false,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="ambulanceOptions"
                                      id="ambulanceOption2"
                                      value="no"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="ambulanceOption2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div> */}
                              <div className="form-group">
                                <label
                                  htmlFor="ambulanceStatus"
                                  className="col-form-label"
                                >
                                  Was an Ambulance called, if so, which
                                  hospital?
                                </label>
                                <textarea
                                  value={injuryDetails.ambulanceStatus}
                                  onChange={(a) =>
                                    setInjuryDetails((x) => ({
                                      ...x,
                                      ambulanceStatus: a.target.value,
                                    }))
                                  }
                                  className="form-control"
                                  id="ambulanceStatus"
                                ></textarea>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="Treatment"
                                  className="col-form-label"
                                >
                                  Initial Treatment Provided
                                </label>
                                <textarea
                                  value={injuryDetails.initialTreatmentGiven}
                                  onChange={(a) =>
                                    setInjuryDetails((x) => ({
                                      ...x,
                                      initialTreatmentGiven: a.target.value,
                                    }))
                                  }
                                  className="form-control"
                                  id="Treatment"
                                ></textarea>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  Select type of Injury
                                </label>
                                <Select1
                                  isMulti
                                  onChange={(a) =>
                                    setInjuryDetails((x) => ({
                                      ...x,
                                      injuryTypes: a.map((el) => el.value),
                                    }))
                                  }
                                  options={injuryDetailsOptions}
                                  value={injuryDetails.injuryTypes?.map(
                                    (d) => ({
                                      label: d,
                                      value: d,
                                    }),
                                  )}
                                />
                                {/* <select
                            isMulti
                            className="form-control"
                            value={injuryDetails.injuryType}
                            onChange={(a) =>
                              setInjuryDetails((x) => ({
                                ...x,
                                injuryTypes: a.map((el) => el.value),
                              }))
                            }
                          >
                            <option>Select type</option>
                            <option>HEAD</option>
                            <option>TORSO FRONT</option>
                            <option>TORSO BACK</option>
                            <option>LEFT ARM</option>
                            <option>RIGHT ARM</option>
                            <option>LEFT LEG</option>
                            <option>RIGHT LEG</option>
                            <option>OTHER</option>
                          </select> */}
                              </div>

                              <div className="form-group">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  Pain level
                                </label>
                                <div
                                  className="d-flex"
                                  style={{ flexWrap: 'wrap' }}
                                >
                                  {painLevel.map((el, i) => (
                                    <div
                                      className="form-check form-check-inline mr-4"
                                      key={i}
                                    >
                                      <input
                                        checked={injuryDetails.painLevel == el}
                                        onChange={(a) =>
                                          setInjuryDetails((x) => ({
                                            ...x,
                                            painLevel: el,
                                          }))
                                        }
                                        className="form-check-input"
                                        type="radio"
                                        name="Pain level"
                                        id="r3"
                                        value="option11"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="r3"
                                      >
                                        {el}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="form-group">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  Do You Need Medical Treatment
                                </label>
                                <div className="d-flex">
                                  <div className="form-check form-check-inline mr-5">
                                    <input
                                      checked={
                                        injuryDetails.medicalTreatmentRequired
                                      }
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          medicalTreatmentRequired: true,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="Fatality"
                                      id="r1"
                                      value="option11"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="r1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      checked={
                                        !injuryDetails.medicalTreatmentRequired
                                      }
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          medicalTreatmentRequired: false,
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="Fatality"
                                      id="r2"
                                      value="option12"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="r2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="recipient-name"
                                  className="col-form-label"
                                >
                                  Were you sent home, to a hospital or did you
                                  continue working?
                                </label>
                                <div className="d-flex">
                                  <div className="form-check form-check-inline mr-4">
                                    <input
                                      checked={injuryDetails.status == 'Home'}
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          status: 'Home',
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="hospital"
                                      id="home1"
                                      value="option1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="home1"
                                    >
                                      Home
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mr-4">
                                    <input
                                      checked={
                                        injuryDetails.status == 'Hospital'
                                      }
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          status: 'Hospital',
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="hospital"
                                      id="home2"
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="home2"
                                    >
                                      Hospital
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mr-4">
                                    <input
                                      checked={
                                        injuryDetails.status == 'Urgent Care'
                                      }
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          status: 'Urgent Care',
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="hospital"
                                      id="home3"
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="home3"
                                    >
                                      Urgent Care
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline mr-4">
                                    <input
                                      checked={
                                        injuryDetails.status == 'Continued Work'
                                      }
                                      onChange={(a) =>
                                        setInjuryDetails((x) => ({
                                          ...x,
                                          status: 'Continued Work',
                                        }))
                                      }
                                      className="form-check-input"
                                      type="radio"
                                      name="hospital"
                                      id="home4"
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="home4"
                                    >
                                      Continued Work
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {/* {injuryDetails.status === 'Hospital' && data && (
                                <div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="recipient-name"
                                      className="col-form-label"
                                    >
                                      Hospital Name
                                    </label>
                                    <input
                                      type="text"
                                      value={data.hospitalName}
                                      className="form-control"
                                      onChange={(a) =>
                                        setData((x) => ({
                                          ...x,
                                          hospitalName: a.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </div>
                              )} */}
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {isVehicularIncident &&
                    activeTab === TabType.VehicleDetails &&
                    data && (
                      <div
                        className="tab-pane"
                        id="vehicle"
                        role="tabpanel"
                        aria-labelledby="pills-injury-tab"
                      >
                        {data?.isCompanyEmployee ? (
                          <div className="py-4 d-flex justify-content-center align-items-center">
                            <span className="mr-4">Is Company Vehicle?</span>
                            <div
                              style={{ height: '44px' }}
                              className="btn-group btn-group-toggle border btn-yesno"
                              data-toggle="buttons"
                            >
                              <label
                                className={
                                  'btn mr-2 px-3 ' +
                                  (!data.isThirdPartyVehicle ? 'active' : '')
                                }
                                onClick={() =>
                                  setData((x) => ({
                                    ...x,
                                    isThirdPartyVehicle: false,
                                  }))
                                }
                              >
                                <input
                                  onChange={() =>
                                    setData((x) => ({
                                      ...x,
                                      isThirdPartyVehicle: false,
                                    }))
                                  }
                                  label={'Yes'}
                                  type="radio"
                                  name="dd"
                                  id="option6"
                                  checked={!data.isThirdPartyVehicle}
                                />
                                Yes
                              </label>
                              <label
                                className={
                                  'btn px-3 ' +
                                  (data.isThirdPartyVehicle ? 'active' : '')
                                }
                                onClick={() =>
                                  setData((x) => ({
                                    ...x,
                                    isThirdPartyVehicle: true,
                                  }))
                                }
                              >
                                <input
                                  onChange={() =>
                                    setData((x) => ({
                                      ...x,
                                      isThirdPartyVehicle: true,
                                    }))
                                  }
                                  type="radio"
                                  name="dd"
                                  id="option5"
                                  label="No"
                                  checked={data.isThirdPartyVehicle}
                                />{' '}
                                No
                              </label>
                            </div>
                          </div>
                        ) : null}

                        {data.isThirdPartyVehicle && (
                          <div
                            className="tab-pane fade show"
                            id="Person"
                            role="tabpanel"
                            aria-labelledby="Person-tab"
                          >
                            <div className="px-5">
                              <form>
                                <div className="form-group">
                                  <label htmlFor="" className="col-form-label">
                                    Model
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        model: a.target.value,
                                      }))
                                    }
                                    label={'Model'}
                                    name={'model'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.model || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="" className="col-form-label">
                                    Make
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        make: a.target.value,
                                      }))
                                    }
                                    label={'Make'}
                                    name={'make'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.make || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Year of Manufactured</label>
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="yyyy"
                                    showYearPicker
                                    name={`year`}
                                    selected={
                                      vehicleDetails?.year &&
                                      vehicleDetails?.year !== '' &&
                                      new Date(vehicleDetails?.year)
                                    }
                                    onChange={(val) => {
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        year: val,
                                      }))
                                    }}
                                    minDate={moment()
                                      .subtract(50, 'years')
                                      .toDate()}
                                    maxDate={moment().toDate()}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="mileage"
                                    className="col-form-label"
                                  >
                                    Mileage
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        mileage: a.target.value,
                                      }))
                                    }
                                    label={'Mileage'}
                                    name={'mileage'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.mileage || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="vanNumber"
                                    className="col-form-label"
                                  >
                                    VAN Number
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        vanNumber: a.target.value,
                                      }))
                                    }
                                    label={'VAN Number'}
                                    name={'vanNumber'}
                                    type="text"
                                    id="vanNumber"
                                    className="form-control"
                                    value={vehicleDetails?.vanNumber || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label className="col-form-label">
                                    VIN Number
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        vinNumber: a.target.value,
                                      }))
                                    }
                                    label={'VIN Number'}
                                    name={'vinNumber'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.vinNumber || ''}
                                  />
                                </div>

                                <div className="form-group">
                                  <label
                                    htmlFor="recipient-name"
                                    className="col-form-label"
                                  >
                                    License Plate
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        licensePlate: a.target.value,
                                      }))
                                    }
                                    label={'License Plate'}
                                    name={'licensePlate'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.licensePlate || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="state"
                                    className="col-form-label"
                                  >
                                    State
                                  </label>
                                  <input
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        state: a.target.value,
                                      }))
                                    }
                                    label={'State'}
                                    name={'state'}
                                    type="text"
                                    id="state"
                                    className="form-control"
                                    value={vehicleDetails?.state || ''}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    htmlFor="recipient-name"
                                    className="col-form-label"
                                  >
                                    Notes
                                  </label>
                                  <textarea
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="5"
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        notes: a.target.value,
                                      }))
                                    }
                                    className="form-control"
                                    value={vehicleDetails?.notes || ''}
                                  ></textarea>
                                </div>
                                <div className="form-group">
                                  <label>Vehicle Damage Details</label>
                                  <Select1
                                    isMulti
                                    name={`vehicleDamages`}
                                    // onChange={(val) =>
                                    //   setData((x) => ({
                                    //     ...x,
                                    //     vehicleDamages: val,
                                    //   }))
                                    // }
                                    onChange={(val) => {
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        vehicleDamages: val.map((e) => e.value),
                                      }))
                                    }}
                                    options={vehicleDamageOptions}
                                    value={
                                      (vehicleDetails?.vehicleDamages?.length ??
                                        0) > 0
                                        ? vehicleDamageOptions.filter((f) =>
                                            vehicleDetails?.vehicleDamages.includes(
                                              f.value,
                                            ),
                                          )
                                        : []
                                    }
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        )}

                        {filteredVehList && !data.isThirdPartyVehicle && (
                          <div className={'form-group '}>
                            <div className="form-group col-md-12">
                              <label>Select Vehicle</label>
                              <Select1
                                placeholder="Select Vehicle"
                                getOptionLabel={(option) =>
                                  option.vehicleId
                                    ? `${option.vehicleId} - ${option.model} ${option.manufactureYear}`
                                    : option.value
                                }
                                getOptionValue={(option) => option}
                                isSearchable="true"
                                onChange={(val) => {
                                  setSelectedVehicleIndex(
                                    filteredVehList.indexOf(val),
                                  )
                                  setvehicleDetails((x) => ({
                                    ...x,
                                    make: '',
                                    model: val.model || '',
                                    year: val.manufactureYear || '',
                                    vinNumber: val.vinNumber || '',
                                    vehId: val._id || '',
                                  }))
                                }}
                                value={
                                  selectedVehicleIndex >= 0
                                    ? filteredVehList[selectedVehicleIndex]
                                    : ''
                                }
                                options={filteredVehList}
                              />
                            </div>
                            {!data.isThirdPartyVehicle &&
                              vehicleDetails?.vehId === 'OTHERS' && (
                                <div
                                  className="tab-pane fade show"
                                  id="Person"
                                  role="tabpanel"
                                  aria-labelledby="Person-tab"
                                >
                                  <div className="px-4">
                                    <form>
                                      <div className="form-group">
                                        <label
                                          htmlFor=""
                                          className="col-form-label"
                                        >
                                          Model
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              model: a.target.value,
                                            }))
                                          }
                                          label={'Model'}
                                          name={'model'}
                                          type="text"
                                          className="form-control"
                                          value={vehicleDetails?.model || ''}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor=""
                                          className="col-form-label"
                                        >
                                          Make
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              make: a.target.value,
                                            }))
                                          }
                                          label={'Make'}
                                          name={'make'}
                                          type="text"
                                          className="form-control"
                                          value={vehicleDetails?.make || ''}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label>Year of Manufactured</label>
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="yyyy"
                                          showYearPicker
                                          name={`year`}
                                          selected={
                                            vehicleDetails?.year &&
                                            vehicleDetails?.year !== '' &&
                                            new Date(vehicleDetails?.year)
                                          }
                                          onChange={(val) => {
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              year: val,
                                            }))
                                          }}
                                          minDate={moment()
                                            .subtract(50, 'years')
                                            .toDate()}
                                          maxDate={moment().toDate()}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor="mileage"
                                          className="col-form-label"
                                        >
                                          Mileage
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              mileage: a.target.value,
                                            }))
                                          }
                                          label={'Mileage'}
                                          name={'mileage'}
                                          type="text"
                                          className="form-control"
                                          value={vehicleDetails?.mileage || ''}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor="vanNumber"
                                          className="col-form-label"
                                        >
                                          VAN Number
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              vanNumber: a.target.value,
                                            }))
                                          }
                                          label={'VAN Number'}
                                          name={'vanNumber'}
                                          type="text"
                                          id="vanNumber"
                                          className="form-control"
                                          value={
                                            vehicleDetails?.vanNumber || ''
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor="Treatment"
                                          className="col-form-label"
                                        >
                                          VIN Number
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              vinNumber: a.target.value,
                                            }))
                                          }
                                          label={'VIN Number'}
                                          name={'vinNumber'}
                                          type="text"
                                          className="form-control"
                                          value={
                                            vehicleDetails?.vinNumber || ''
                                          }
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label
                                          htmlFor="recipient-name"
                                          className="col-form-label"
                                        >
                                          License Plate
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              licensePlate: a.target.value,
                                            }))
                                          }
                                          label={'License Plate'}
                                          name={'licensePlate'}
                                          type="text"
                                          className="form-control"
                                          value={
                                            vehicleDetails?.licensePlate || ''
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label
                                          htmlFor="state"
                                          className="col-form-label"
                                        >
                                          State
                                        </label>
                                        <input
                                          onChange={(a) =>
                                            setvehicleDetails((x) => ({
                                              ...x,
                                              state: a.target.value,
                                            }))
                                          }
                                          label={'State'}
                                          name={'state'}
                                          type="text"
                                          id="state"
                                          className="form-control"
                                          value={vehicleDetails?.state || ''}
                                        />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              )}
                            {/* {vehicleDetails.vehId &&
                            typeof vehicleDetails.vehId === 'string' && ( */}
                            <div className="form-group col-md-12">
                              <label>Vehicle Damage Details</label>
                              <Select1
                                isMulti
                                name={`vehicleDamages`}
                                onChange={(val) =>
                                  // setData((x) => ({
                                  //   ...x,
                                  //   vehicleDamages: val,
                                  // }))
                                  setvehicleDetails((x) => ({
                                    ...x,
                                    vehicleDamages: val.map((e) => e.value),
                                  }))
                                }
                                options={vehicleDamageOptions}
                                value={
                                  (vehicleDetails?.vehicleDamages?.length ??
                                    0) > 0
                                    ? vehicleDamageOptions.filter((f) =>
                                        vehicleDetails?.vehicleDamages.includes(
                                          f.value,
                                        ),
                                      )
                                    : []
                                }
                              />
                            </div>
                            {vehicleDetails?.vehicleDamages &&
                              vehicleDetails?.vehicleDamages.find(
                                (a) => a.value === 'Others',
                              ) && (
                                <div className="form-group col-md-12">
                                  <label>Other Details</label>
                                  <input
                                    // onChange={(a) =>
                                    //   setData((x) => ({
                                    //     ...x,
                                    //     otherVehicleDamages: a.target.value,
                                    //   }))
                                    // }
                                    onChange={(a) =>
                                      setvehicleDetails((x) => ({
                                        ...x,
                                        otherVehicleDamages: a.target.value,
                                      }))
                                    }
                                    label={'Other details'}
                                    name={'other'}
                                    type="text"
                                    className="form-control"
                                    value={vehicleDetails?.otherVehicleDamages}
                                  />
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="modal-footer" style={{ background: '#F4F7FE' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setPersonDetails({
                      firstName: '',
                      lastName: '',
                      email: '',
                      organisation: '', //todo
                      contactNumber: '',
                    })
                    setvehicleDetails(initialVehicleDetails)
                    onClose()
                  }}
                  type="button"
                  className="btn btn-secondary bg-white text-dark"
                  data-dismiss="modal"
                  data-testid="parties-involved-modal-close"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

const injuryDetailsOptions = [
  { label: 'HEAD', value: 'HEAD' },
  { label: 'TORSO FRONT', value: 'TORSO FRONT' },
  { label: 'TORSO BACK', value: 'TORSO BACK' },
  { label: 'LEFT ARM', value: 'LEFT ARM' },
  { label: 'RIGHT ARM', value: 'RIGHT ARM' },
  { label: 'LEFT LEG', value: 'LEFT LEG' },
  { label: 'RIGHT ARM', value: 'RIGHT ARM' },
  { label: 'Others', value: 'Others' },
]

export default PartiesInvolvedModal
