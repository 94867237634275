import classNames from 'classnames'
import React from 'react'
import { useGetPermission } from '../../hooks/useGetPermission'

function ActionWidget({
  module = '',
  isIncident = false,
  isViewHide = false,
  isUpdateHide = false,
  isDeleteHide = false,
  isPublicIncident = false,
  onDelete,
  onEdit,
  onView,
  convertToClaim,
  approveMVR,
}) {
  // const { getModule } = useGetModule()
  // const isInactive = getModule(module)?.operations.length === 0 || false

  const isInactive = () => {
    // let moduleData = permissions.find(
    //   (permission) => permission.module.moduleName == module,
    // )
  }
  const approvePermission = useGetPermission(module, 'APPROVE')
  const deletePermission = useGetPermission(module, 'DELETE')
  const editPermission = useGetPermission(module, 'UPDATE')
  const convertToClaimPermission = useGetPermission(module, 'CONVERT-CLAIM')
  const readPermission = useGetPermission(module, 'READ')


  return (
    <div
      className={classNames('dropdown', 'action-widget', {
        active: !isInactive() || true,
      })}
    >
      <a className="remove-decotraion" />
      <a
        className="btn btn-clean btn-hover-light-primary btn-sm btn-icon p-0"
        data-toggle="dropdown"
        title="Option"
        data-placement="top"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fas fa-ellipsis-h" aria-hidden="true" />
      </a>
      <div className="dropdown-menu dropdown-menu-left user-dd animated">
        <ul className="navi navi-hover">
          {(!isViewHide && readPermission) && (
            <li className="navi-item" onClick={onView}>
              <a className="navi-link text-decoration-none">
                <span className="navi-text">
                  <i className="fas fa-info-circle"></i>
                  <span className="label label-xl label-inline  label-light-danger pl-2">
                    View
                  </span>
                </span>
              </a>
            </li>
          )}
          {(!isUpdateHide && editPermission) && (
            <li className="navi-item" onClick={onEdit}>
              <a className="navi-link text-decoration-none">
                <span className="navi-text">
                  <span className="label label-xl label-inline  label-light-danger">
                    <i className="far fa-edit"></i>
                    <span className="pl-2">Edit</span>
                  </span>
                </span>
              </a>
            </li>
          )}

          {!isDeleteHide && deletePermission && (
            <li className="navi-item" onClick={onDelete}>
              <a className="navi-link text-decoration-none">
                <span className="navi-text">
                  <i className="far fa-trash-alt"></i>
                  <span className="label label-xl label-inline label-light-warning pl-2">
                    Delete
                  </span>
                </span>
              </a>
            </li>
          )}

          {((isIncident || isPublicIncident) && convertToClaimPermission && convertToClaim) && (
            <li className="navi-item" onClick={convertToClaim}>
              <a className="navi-link">
                <span className="navi-text">
                  <span className="label label-xl label-inline label-light-warning">
                    <i
                      className="fas fa-file-download"
                      style={{ paddingRight: '10px' }}
                    ></i>
                    Convert to {isIncident ? "Claim" : "Incident"}
                  </span>
                </span>
              </a>
            </li>
          )}

          {module === 'MVR' && approvePermission && (
            <li className="navi-item" onClick={() => approveMVR(true)}>
              <a href="#" className="navi-link text-decoration-none">
                <span className="navi-text">
                  <span className="label label-xl label-inline label-light-danger">
                    <i className="fa fa-check view" aria-hidden="true"></i>
                    <span className="pl-2"> Approved</span>
                  </span>
                </span>
              </a>
            </li>
          )}

          {module === 'MVR' && approvePermission && (
            <li className="navi-item" onClick={() => approveMVR(false)}>
              <a href="#" className="navi-link text-decoration-none">
                <span className="navi-text">
                  <span className="label label-xl label-inline label-light-danger">
                    <i className="fa fa-close" aria-hidden="true"></i>
                    <span className="pl-2"> Not Approved</span>
                  </span>
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default ActionWidget
