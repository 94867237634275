import React from 'react'
import { Modal } from 'reactstrap'

function ModuleSelectionAlertModal({ isOpen, onClose }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdrop={'static'}
      className={
        'modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable'
      }
      style={{ width: '600px' }}
    >
      <div className="modal-content">
        <div className="modal-header px-5">
          <h5 className="modal-title text-primary " id="">
            {' '}
            <span>
              <a href="#">
                {' '}
                Client Hub{' '}
                <i
                  className="fa fa-chevron-right mx-2"
                  style={{fontSize: '14px'}}
                ></i>
              </a>
            </span>{' '}
            <span>
              <a href="#">Settings</a>
            </span>
          </h5>
        </div>
        <div className="modal-body p-0 px-5" id="smodaltab">
          <ul className="nav nav-pills bg-lightdrop d-flex px-4">
            <li className="nav-item px-3" role="presentation">
              <a className="nav-link  mt-0 font-weight-normal" href="#">
                DEFINE CONDITIONS
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pill-tabContent1">
            <div className="tab-pane fade show active">
              <div className="px-5 my-5">
                <p className="ft-18 mb-0">
                  You first need to select actions (create, read, update, ...)
                  before defining conditions on them.
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="modal-footer term-footer">
          <button
            type="button"
            className="btn btn-primary add-btn"
            onClick={onClose}
          >
            Apply
          </button>
          <button
            type="button"
            className="btn btn-secondary bg-white text-dark px-4 border-radius-12 setcan"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModuleSelectionAlertModal
