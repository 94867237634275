import React from 'react'
import { transformPhoneNumber } from './utils'

function WitnessCard({ witness, onEdit, onDelete, uid }) {
  const address = witness.address ?? ''
  const line1 = address?.line1 ?? ''
  const line2 = address?.line2 ?? ''
  const city = address?.city ?? ''
  const state = address?.state ?? ''
  const country = address?.country ?? ''
  const pincode = address?.pinCode ?? ''
  const addr =
    [line1, line2, city, state, country].filter((e) => e !== '').join(', ') +
    ' - ' +
    pincode

  return (
    <div className="parties bg-white p-3 mb-3" data-testid={`witness-card-${uid}`}>
      <div className="pull-right pb-2">
        <button
          data-testid={`edit-witness-${uid}`}
          type="button"
          className="btn btn-outline-secondary side-btn mr-1"
          onClick={() => onEdit(uid)}
        >
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </button>
        <button
          data-testid={`delete-witness-${uid}`}
          type="button"
          className="btn btn-outline-secondary side-btn"
          onClick={() => onDelete(uid)}
        >
          <i className="far fa-trash-alt" aria-hidden="true"></i>
        </button>
      </div>
      <h6 className="text-primery">
        {witness?.firstName + ' ' + witness?.lastName}
      </h6>
      <p className="mb-2">
        <i className="fas fa-mobile-alt mr-2" aria-hidden="true"></i>
        {transformPhoneNumber(witness?.contactNumber)}
      </p>
      <p className="mb-2">
        <i className="fas fa-map-marker-alt mr-2" aria-hidden="true"></i> {addr}
      </p>
      <span className="badge badge-pill btn btn-outline-primary pt-1">
        {witness.companyName}
      </span>
      <span className="badge badge-pill btn btn-outline-primary pt-1">
        {witness.designation}
      </span>
    </div>
  )
}
export default WitnessCard
