import apiUrl from '../configs/api'
import http from './httpService'

const apiEndpoint = `${apiUrl}/vehicle`

export function getVehicles() {
  return http.get(`${apiEndpoint}/get-vehicle-list`)
}

export function exportVehicles() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getVehicle(id) {
  return http.get(`${apiEndpoint}/get-vehicle/${id}`)
}

export function updateVehicle(id, vehicle) {
  return http.put(`${apiEndpoint}/update-vehicle/${id}`, vehicle)
}
export function deleteVehicle(id, vehicle) {
  return http.put(`${apiEndpoint}/delete-vehicle/${id}`, vehicle)
}

export function deleteBlukVehicle(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}


export function saveVehicle(vehicle) {
  // const date = new Date()
  // const vehicleId = `VH-${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...vehicle })
}

export function getTotalInsuranceValueOfVehicles() {
  return http.get(`${apiEndpoint}/get-total-insurance-value`)
}
