export const getModuleByTabId = (tabId) => {
  switch (tabId) {
    case 'incident':
      return 'incident'
    case 'claim':
      return 'claim'
    case 'employee':
      return 'employee'
    case 'thirdParty':
      return 'third-party'
    case 'mvr_submission':
    case 'approved':
    case 'not_approved':
    case 'all_approvals':
      return 'mvr'
    default:
      return tabId
  }
}
