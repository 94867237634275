import React from 'react'
import { Modal } from 'reactstrap'
import IncidentForm from './IncidentForm'
import ConvertToclaim from '../claims/convertToClaim'

import { ReactComponent as Close } from '../../svgs/close.svg'
import DirectClaim from '../claims/DirectClaim/DirectClaim'

function IncidentModal({
  isOpen,
  data,
  onClose,
  title,
  context,
  activeStep,
  setActiveStep,
  setClaimData,
  addClaimData,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdrop={'static'}
      className={'modal-dialog-top modal-lg modal-right posi-rel'}
      style={{ minWidth: '65vw' }}
    >
      <div>
        <a onClick={onClose} className="close close-left" aria-label="Close">
          <Close />
        </a>
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="col-md-12 p-0 col-h">
            <div className="modal-body p-0">
              {context === 'claim' && activeStep > 2 ? (
                <ConvertToclaim
                  match={{ params: { id: data?.incidentId } }}
                  data={context !== 'incident' ? data : null}
                  location={{ search: { claimId: data?.claimId } }}
                  context={context}
                  title={title}
                  addClaimData={addClaimData}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  onClose={onClose}
                  directClaim={true}
                />
              ) : (
                <IncidentForm
                  data={data}
                  match={null}
                  title={title}
                  onClose={onClose}
                  context={context}
                  activeStep={activeStep}
                  setClaimData={setClaimData}
                  setActiveStep={setActiveStep}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IncidentModal
