import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/user`
const dashboardApiEndpoint = `${apiUrl}/dashboard`

export function getUsers() {
  return http.get(`${apiEndpoint}/get-list`)
}

export function getUsersDeletedList() {
  return http.get(`${apiEndpoint}/deleted-list`)
}

export function deleteUserForever(ids) {
  return http.delete(`${apiEndpoint}/delete-forever`, {
    data: { idsList: ids },
  })
}

export function restoreUser(id) {
  return http.put(`${apiEndpoint}/restore`, { moduleName: 10, docId: id })
}

export function exportUsers() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getUser(id) {
  return http.get(`${apiEndpoint}/details/${id}`)
}

export function getCurrentUser(token) {
  return http.get(`${apiEndpoint}/currentUser`, {
    headers: {
      authorization: token,
    },
  })
}


export function updateUser(id, user) {
  delete user.password
  return http.put(`${apiEndpoint}/update/${id}`, user)
}

export function deleteUser(id, user) {
  return http.put(`${apiEndpoint}/delete/${id}`, user)
}

export function deleteBlukUser(ids) {    
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}

export function saveUser(user) {
  const date = new Date()
  const userId = user?.userId ?? `USR-${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...user, userId })
}

export function acceptTerms() {
  return http.put(`${apiEndpoint}/accept-terms`)
}

// "/notification/save-token"