import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
  saveClaimInfo,
  saveIncidentInfo,
} from '../../actions'
import ActionWidget from '../../components/common/ActionWidget'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import Loader from '../../components/common/Loader'
import Table from '../../components/common/Table'
import { useGetPermission } from '../../hooks/useGetPermission'
import { getConvertedClaims } from '../../services/claimService'
import { getPagedData } from '../../services/helperService'
import {
  deleteBlukIncident,
  deleteIncident,
  getIncidents,
} from '../../services/incidentService'
import IncinentClaimData from './IncidentClaimData'
import IncidentConvertToClaimModal from './IncidentConvertToClaimModal'
import IncidentModal from './IncidentModal'

let setSelectedClaim = ''
let nonVehicularIncidents = []
let vehicularIncidents = []
let itemsCount = 0
let nonVehicularItemsCount = 0

function IncidentList({
  isOpen,
  context,
  searchQuery,
  setShowDeleteButton,
  dateRangeFilter,
  filters,
  selectFilters,
  setFilterCount,
  openBulkDel,
  closeBulkDel,
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [isLoading, setIsLoading] = useState(true)

  const history = useHistory()

  const createPermission = useGetPermission('incident', 'CREATE')

  const getIncidentFilterData = (incidentData) => {
    let { reportedVia, priority, status, coverageDescription } = incidentData

    let isDateRangeValid = false
    let isDateRangeSelected = Object.values(dateRangeFilter).every((x) => {
      return x.start === null
    })

    if (dateRangeFilter && !isDateRangeSelected) {
      Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
        // console.log("start=====>>>>",start,end,key,incidentData[key])
        if (start !== null && incidentData[key] !== '') {
          if (
            // incidentData[key] > start.toISOString() &&
            // incidentData[key] < end.toISOString()
            incidentData[key] > moment(start).format('YYYY-MM-DD') &&
            incidentData[key] < moment(end).format('YYYY-MM-DD')
          ) {
            // console.log("moment(start).format('YYYY-MM-DD')====>>",moment(start).format('YYYY-MM-DD'),moment(end).format('YYYY-MM-DD'),incidentData[key])
            isDateRangeValid = true
          } else {
            isDateRangeValid = false
          }
        }
      })
    }

    const {
      reportedVia: selectReportedVia = '',
      priority: selectPriority = '',
      coverageDescription: selectCoverageDescription = '',
    } = selectFilters

    let isReportedVia = false,
      isPriority = false,
      isStatus = false,
      isCoveragedescription = false

    if (
      (filters['overdue'].isAvailable && status === 'OVER DUE') ||
      (filters['dueToday'].isAvailable && status === 'DUE NOW')
    ) {
      isStatus = true
    }

    if (
      selectReportedVia.length &&
      selectReportedVia.toLowerCase() === reportedVia.toLowerCase()
    ) {
      isReportedVia = true
    }
    if (
      selectPriority.length &&
      selectPriority.toLowerCase() === priority.toLowerCase()
    ) {
      isPriority = true
    }

    if (
      selectCoverageDescription.length &&
      selectCoverageDescription.toLowerCase() ===
        coverageDescription.toLowerCase()
    ) {
      isCoveragedescription = true
    }

    return (
      !(
        (filters['overdue'].isAvailable || filters['dueToday'].isAvailable) ^
        isStatus
      ) &&
      !(!!selectReportedVia.length ^ isReportedVia) &&
      !(!!selectPriority.length ^ isPriority) &&
      !(!!selectCoverageDescription.length ^ isCoveragedescription) &&
      !(!isDateRangeSelected ^ isDateRangeValid)
    )
  }

  const columns = [
    {
      path: 'dateOfIncident',
      label: 'Date Of Loss',
      type: 'date',
      content: (incident) => {
        // return moment(incident.dateOfIncident ?? incident.incidentDetails?.dateOfIncident).format("MM/DD/YYYY")
        const dateOfIncident =
          incident?.dateOfIncident ??
          moment(incident.incidentDetails?.dateOfIncident).format('MM/DD/YYYY')
        if (dateOfIncident) {
          if (incident.timezone) {
            return (
              <div>
                <p className="m-0">{dateOfIncident}</p>
                <small className="text-muted">{`${
                  incident?.incidentDetails?.time
                    ? incident?.incidentDetails?.time
                    : 'N/A'
                }  ${moment.tz(incident?.timezone).zoneAbbr()}`}</small>
              </div>
            )
          }
          return moment(dateOfIncident).format('MM/DD/YYYY')
        }
        return 'N/A'
      },
    },
    {
      path: 'status',
      label: '',
      width: '150px',
      content: (incident) => (
        <ActionWidget
          module="INCIDENT"
          isIncident
          convertToClaim={() => handleConvertToClaim(incident)}
          onView={() => handleOnView(incident)}
          onEdit={() => {
            setIncidentModal({
              isOpen: true,
              data: { ...incident },
              title: 'Edit Incident',
            })
          }}
          onDelete={() => {
            setIncident(incident)
            setDeleteOne(true)
          }}
        />
      ),
    },
    { path: 'claimStatus', label: 'Incident Status', type: 'string' },
    { path: 'startDate', label: 'Start Date', type: 'date' },
    { path: 'endDate', label: 'Due Date', type: 'date' },
    { path: 'clientId.companyName', label: 'Account Name' },
    {
      label: 'Employee Name',
      content: (incident) => {
        const name = incident?.userDetails
          ? `${incident.userDetails.lastName || ''} ${
              incident.userDetails.firstName || ''
            }`
          : (() => {
              const companyEmp = incident?.partiesInvolved.find(
                ({ isCompanyEmployee }) => isCompanyEmployee,
              )
              return `${companyEmp?.personDetails.lastName || ''}, ${
                companyEmp?.personDetails.firstName || ''
              }`
            })()

        return <span>{name.length > 2 ? name : 'N/A'}</span>
      },
    },
    { path: 'claimType', label: 'Type of Claim', type: 'string' },
    {
      label: 'Claimant Full Name',
      content: (incident) => {
        let names = []
        let parties = []
        if (!incident?.vehicularAccident) {
          parties = incident?.partiesInvolved
          for (const party of parties) {
            if (party.personDetails?.firstName || party.personDetails?.lastName)
              names.push(
                `${party?.personDetails?.lastName} ${party?.personDetails?.firstName}`,
              )
          }
        } else {
          parties = incident.partiesInvolved.filter(
            ({ isCompanyEmployee }) => !isCompanyEmployee,
          )
          for (const party of parties) {
            if (party.personDetails?.firstName || party.personDetails?.lastName)
              names.push(
                `${party?.personDetails?.lastName} ${party?.personDetails?.firstName}`,
              )
          }
        }
        return (
          <span>
            {parties.length
              ? names.length
                ? names.join(',')
                : ''
              : `${incident?.userDetails?.lastName} ${incident?.userDetails?.firstName}`}
          </span>
        )
      },
    },
    { path: 'priority', label: 'Priority' },
    {
      path: 'claim.modified',
      label: 'Last changed date',
      content: (claim) => (
        <>
          <span>{moment(claim.modified).format('MM/DD/YYYY')}</span>
        </>
      ),
    },
  ]

  const [incidentModal, setIncidentModal] = useState({
    isOpen: false,
    data: null,
    title: '',
  })
  const [convertToClaimModal, setConvertToClaimModal] = useState({
    isOpen: false,
    data: null,
  })
  const [claimModal, setClaimModal] = useState({ open: false, incidentId: '' })
  const [incidentsVisible, setIncidentsVisible] = useState(true)
  const [incidentsNonVehicularVisible, setIncidentsNonVehicularVisible] =
    useState(true)
  const [claimActiveTab, setClaimActiveTab] = useState('2')
  const [claimIdModal, setClaimIdModal] = useState(false)
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [fetchData, setFetchData] = useState(false)
  const [nonVehicularSortColumn, setNonVehicularSortColumn] = useState(null)
  const [checkedRecords, setCheckedRecords] = useState([])
  const [data, setData] = useState([])
  const [claims, setClaims] = useState([])
  const [deleteOne, setDeleteOne] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)
  const dispatch = useDispatch()
  const [incident, setIncident] = useState({})

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const [activeStep, setActiveStep] = useState(1)

  useEffect(() => {
    if (
      isOpen ||
      incidentModal.isOpen ||
      convertToClaimModal.isOpen ||
      claimModal.open
    )
      return
    setIsLoading(true)
    fetchList()
    fetchClaims()
    if (
      !sideNavDataContext &&
      sideNavDataContext !== 'view' &&
      sideNavDataContext !== 'searchView'
    )
      dispatch(handleTabIdUpdated('incident'))
  }, [
    selectedClientId,
    context,
    fetchData,
    isOpen,
    incidentModal.isOpen,
    convertToClaimModal.isOpen,
    claimModal.open,
  ])

  useEffect(() => {
    if (openBulkDel) {
      setIncident({
        incident: { firstName: 'incidents', lastName: 'incidents' },
      })
    }
  }, [openBulkDel])
  useEffect(() => {
    if (
      selectedTabId === 'incident' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      setIncidentModal({
        isOpen: true,
        data: viewData,
        title: 'Edit Incident',
      })
    }
    if (selectedTabId === 'incident' && viewAction === 'refresh') {
      fetchList()
      fetchClaims()
      dispatch(handleTabIdUpdated('incident'))
    }
  }, [viewAction, viewData, selectedTabId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {
    if (data && data.length) {
      const mapFilterCount = {
        overdue: 0,
        duenow: 0,
      }
      data
        .filter((it) =>
          incidentsNonVehicularVisible
            ? !it.vehicularAccident
            : it.vehicularAccident,
        )
        .forEach(({ status = '' }) => {
          if (status && status.length) {
            mapFilterCount[status.toLowerCase().split(' ').join('')] += 1
          }
        })
      setFilterCount(mapFilterCount['overdue'], mapFilterCount['duenow'])
    }
  }, [data, incidentsNonVehicularVisible])

  const fetchClaims = async () => {
    const { data } = await getConvertedClaims()
    dispatch(saveClaimInfo(data.data))
    setClaims(data.data)
  }

  const handleOnView = (incident) => {
    dispatch(handleTabIdUpdated('incident'))
    dispatch(
      handleSideNavDataUpdated({ data: incident, module: 'incident' }, 'view'),
    )
    dispatch(handleSideNavElementClicked('info'))
  }

  const fetchList = async () => {
    const { data } = await getIncidents()
    if (data.data && Array.isArray(data.data)) {
      let processedRecords = []
      let currentDate = moment().format('MM/DD/YYYY')
      data?.data?.forEach(function (record) {
        // if (record.endDate) {
        //   let endDate = moment(record.endDate).format('MM/DD/YYYY')
        //   let overDue = endDate < currentDate
        //   let dueNow = endDate === currentDate

        //   if (overDue) {
        //     record.status = 'OVER DUE'
        //   } else if (dueNow) {
        //     record.status = 'DUE NOW'
        //   } else {
        //     record.status = ''
        //   }
        // }
        const companyEmp = record.partiesInvolved.find(
          ({ isCompanyEmployee }) => isCompanyEmployee,
        )
        const { personDetails: { lastName = '', firstName = '' } = {} } =
          companyEmp || {}
        record.employeeFullName = `${lastName ? lastName : ''} ${firstName}`

        const claimantName = record.partiesInvolved.find(
          ({ isCompanyEmployee }) => !isCompanyEmployee,
        )
        record.claimantName = `${
          claimantName?.personDetails?.lastName
            ? claimantName?.personDetails?.lastName
            : ''
        } ${claimantName?.personDetails?.firstName}`

        processedRecords.push(record)
      })

      dispatch(saveIncidentInfo(data.data))

      setData(processedRecords)
    }
    fetchData && setFetchData(false)
    setIsLoading(false)
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  // const toggleIncident = () => setIncidentModal(!incidentModal)
  const getPageData = async (isVehicular = true) => {
    if (isVehicular) {
      const { count, result } = getPagedData(
        data.filter((it) => it.vehicularAccident),
        searchQuery,
        sortColumn,
        selectedCategories,
        columns,
        currPage,
        pageSize,
      )
      itemsCount = count
      vehicularIncidents = result
      if (
        vehicularIncidents.length !== 0 &&
        sideNavDataContext !== 'view' &&
        sideNavDataContext !== 'searchView'
      ) {
        dispatch(
          handleSideNavDataUpdated(
            { data: vehicularIncidents[0], module: 'incident' },
            'view',
          ),
        )
        dispatch(handleTabIdUpdated('incident'))
      }

      return result.length > 0
    } else {
      const { count, result } = getPagedData(
        data.filter((it) => !it.vehicularAccident),
        searchQuery,
        nonVehicularSortColumn,
        selectedCategories,
        columns,
        currPage,
        pageSize,
      )
      nonVehicularItemsCount = count
      nonVehicularIncidents = result

      if (
        nonVehicularIncidents.length !== 0 &&
        sideNavDataContext !== 'view' &&
        sideNavDataContext !== 'searchView'
      ) {
        dispatch(
          handleSideNavDataUpdated(
            { data: nonVehicularIncidents[0], module: 'incident' },
            'view',
          ),
        )
        dispatch(handleTabIdUpdated('incident'))
      }

      return result.length > 0
    }
  }

  const handleConvertToClaim = (incident) => {
    setClaimModal({ open: true, incidentId: incident._id })
    setConvertToClaimModal((x) => ({
      ...x,
      data: { incidentId: incident._id, claimId: null },
    }))
  }

  const handleDelete = async (item) => {
    await deleteIncident(item._id, item)
    await fetchList()
  }

  const hendleBulkDelete = async (ids) => {
    await deleteBlukIncident(ids)
    await fetchList()
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handlePageChange = (page) => {
    setCurrPage(page)
  }

  const handleNonVehicularSort = (sortColumn) => {
    setNonVehicularSortColumn(sortColumn)
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let records = !incidentsNonVehicularVisible
        ? vehicularIncidents
        : nonVehicularIncidents
      let selectedIds = []
      if (records.length !== checkedRecords.length) {
        records.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  function renderPage() {
    getPageData()
    getPageData(false)
    return true
  }

  const openNewModal = () => setIncidentModal({ isOpen: true, data: null })

  const handleIncidentsVehicleVisibleChange = (val) => {
    setIncidentsNonVehicularVisible(val)
    setCheckedRecords([])
  }

  const handleModalClose = () => {
    setConvertToClaimModal({ isOpen: false, data: null })
    setFetchData(true)
    history.replace(`/incidents`)
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukIncident(params)
        setShowDeleteButton(false)
        closeBulkDel()
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      } else {
        const { data } = await deleteIncident(incident._id, incident)
        setDeleteOne(false)
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    setIncident({})
    await fetchList()
  }

  if (isLoading)
    return (
      <div style={{ marginTop: '120px' }}>
        <Loader loading={isLoading} />
      </div>
    )

  return (
    renderPage() && (
      <>
        <div className="px-4">
          <div className="row">
            <div className="col-md-12">
              <div>
                {incidentsVisible && (
                  <>
                    <div
                      className="tab-pane fade show active"
                      id="Incidents"
                      role="tabpanel"
                      style={{ marginLeft: '90px' }}
                      aria-labelledby="nav-employees"
                    >
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a
                            className={
                              'nav-link bdr-0 ' +
                              (incidentsNonVehicularVisible ? 'active' : '')
                            }
                            id="home-tab"
                            data-toggle="tab"
                            href="#Non-Vehiuclar"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            onClick={handleIncidentsVehicleVisibleChange.bind(
                              this,
                              true,
                            )}
                          >
                            Non Vehicular Incidents
                          </a>
                        </li>

                        <li className="nav-item" role="presentation">
                          <a
                            className={
                              'nav-link bdr-0 ' +
                              (!incidentsNonVehicularVisible ? 'active' : '')
                            }
                            id="profile-tab"
                            data-toggle="tab"
                            href="#V-Incidents"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            onClick={handleIncidentsVehicleVisibleChange.bind(
                              this,
                              false,
                            )}
                          >
                            Vehicular Incidents
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="container-fluid"
                      style={{ paddingLeft: '0px', paddingRight: '0px' }}
                    >
                      {vehicularIncidents.length > 0 &&
                        !incidentsNonVehicularVisible && (
                          <>
                            <Table
                              columns={columns}
                              data={vehicularIncidents.filter(
                                (incidentData) => {
                                  return getIncidentFilterData(incidentData)
                                },
                              )}
                              sortColumn={sortColumn}
                              onSort={handleSort}
                              checkedItems={checkedRecords}
                              checkboxClick={handleElementCheckboxClicked}
                            />
                          </>
                        )}
                      {nonVehicularIncidents.length > 0 &&
                        incidentsNonVehicularVisible && (
                          <>
                            <Table
                              columns={columns}
                              data={nonVehicularIncidents.filter(
                                (incidentData) => {
                                  return getIncidentFilterData(incidentData)
                                },
                              )}
                              sortColumn={nonVehicularSortColumn}
                              onSort={handleNonVehicularSort}
                              checkedItems={checkedRecords}
                              checkboxClick={handleElementCheckboxClicked}
                            />
                          </>
                        )}
                      {((nonVehicularIncidents.length === 0 &&
                        incidentsNonVehicularVisible) ||
                        (vehicularIncidents.length === 0 &&
                          !incidentsNonVehicularVisible)) && (
                        <>
                          <div className="py-5">
                            <div className="text-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="200"
                                height="200"
                                viewBox="0 0 238 238"
                              >
                                <g
                                  id="Group_11"
                                  data-name="Group 11"
                                  transform="translate(-562 -325)"
                                >
                                  <rect
                                    id="Rectangle_3349"
                                    data-name="Rectangle 3349"
                                    width="238"
                                    height="238"
                                    rx="119"
                                    transform="translate(562 325)"
                                    fill="#f4f7fe"
                                  ></rect>
                                  <path
                                    id="ambulance"
                                    d="M118.335,59.821,109,38.82A30.8,30.8,0,0,0,80.851,20.53h-6.93L70.533,6.968a9.209,9.209,0,0,0-17.864,0L49.281,20.53H25.667A25.7,25.7,0,0,0,0,46.2V87.264a15.369,15.369,0,0,0,10.631,14.569,17.453,17.453,0,0,0-.364,3.4,17.967,17.967,0,1,0,35.934,0,17.787,17.787,0,0,0-.21-2.567H77.211a17.788,17.788,0,0,0-.21,2.567,17.967,17.967,0,0,0,35.934,0,17.453,17.453,0,0,0-.364-3.4A15.369,15.369,0,0,0,123.2,87.264V82.757A56.108,56.108,0,0,0,118.335,59.821ZM99.619,42.994l5.991,13.47H87.268a5.133,5.133,0,0,1-5.133-5.133V30.889a20.534,20.534,0,0,1,17.484,12.1ZM35.934,105.231a7.7,7.7,0,1,1-15.4,0,7.279,7.279,0,0,1,.477-2.567H35.456a7.279,7.279,0,0,1,.477,2.567Zm59.034,7.7a7.7,7.7,0,0,1-7.7-7.7,7.279,7.279,0,0,1,.477-2.567H102.19a7.279,7.279,0,0,1,.477,2.567A7.7,7.7,0,0,1,94.968,112.931Zm17.967-25.667A5.133,5.133,0,0,1,107.8,92.4H15.4a5.133,5.133,0,0,1-5.133-5.133V46.2a15.4,15.4,0,0,1,15.4-15.4h46.2V51.33a15.4,15.4,0,0,0,15.4,15.4H110a45.769,45.769,0,0,1,2.931,16.026ZM56.467,61.6a5.133,5.133,0,0,1-5.133,5.133H46.2v5.133a5.133,5.133,0,0,1-10.267,0V66.731H30.8a5.133,5.133,0,0,1,0-10.267h5.133V51.33a5.133,5.133,0,0,1,10.267,0v5.133h5.133A5.133,5.133,0,0,1,56.467,61.6Z"
                                    transform="translate(619.132 378.668)"
                                    fill="#207be3"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <div className="text-center">
                              <h2 className="no-comment mt-5">
                                No Data Found Yet Here
                              </h2>
                              <p className="all-comment my-3">
                                All data related to this module will show up
                                here
                              </p>
                              {createPermission && (
                                <a
                                  className="all-comment my-3"
                                  style={{
                                    cursor: 'pointer',
                                    color: '#07448a',
                                  }}
                                  onClick={() =>
                                    setIncidentModal({
                                      isOpen: true,
                                      data: null,
                                      title: 'Add New Incident',
                                    })
                                  }
                                >
                                  {' '}
                                  Get Started{' '}
                                </a>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
                {!incidentsVisible && (
                  <>
                    <IncinentClaimData activeTab={claimActiveTab} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={claimModal.open}
          toggle={() =>
            setClaimModal({ open: !claimModal.open, incidentId: '' })
          }
          backdrop="static"
        >
          <ModalBody>
            <div className="modal-content rounded-20 border-0">
              <div className="modal-body pb-0">
                <h4 className="modal-title text-center">
                  Do you want to add this incident into existing claim?
                </h4>
              </div>
              <div className="modal-footer claim-modal">
                <button
                  onClick={() => {
                    setConvertToClaimModal((x) => ({
                      ...x,
                      data: {
                        incidentId: claimModal.incidentId,
                        claimId: null,
                      },
                    }))
                    setClaimModal({ open: !claimModal.open, incidentId: '' })
                    setClaimIdModal(true)
                  }}
                  type="button"
                  className="btn btn-primary pl-10 pr-10"
                  data-dismiss="modal"
                >
                  Yes
                </button>{' '}
                <button
                  onClick={() => {
                    history.replace(`/incidents`)
                    setConvertToClaimModal({
                      isOpen: true,
                      data: {
                        incidentId: claimModal.incidentId,
                        claimId: null,
                      },
                    })
                    setClaimModal({ open: !claimModal.open, incidentId: '' })
                  }}
                  type="button text-dark"
                  className="btn no-btn pl-10 pr-10"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={claimIdModal}
          toggle={() => setClaimIdModal(!claimIdModal)}
          backdrop="static"
        >
          <ModalBody>
            <div className="modal-content rounded-20 border-0">
              <h4 className="modal-title mb-3">Select Claim Id</h4>
              <select
                onChange={({ currentTarget }) =>
                  (setSelectedClaim = currentTarget.value)
                }
                className="form-control"
              >
                <option value=""> Search By Claim Id</option>
                {claims.map((c) => (
                  <option key={c._id} value={c._id}>
                    {c?.claimId}
                  </option>
                ))}
              </select>
              <div className="modal-footer claim-modal">
                <button
                  onClick={() => {
                    if (!setSelectedClaim || setSelectedClaim === '') {
                      history.replace(`/incidents`)
                    } else {
                      history.replace(`/incidents?claimId=${setSelectedClaim}`)
                    }
                    setClaimIdModal(false)
                    setConvertToClaimModal((x) => ({
                      ...x,
                      isOpen: true,
                      data: {
                        incidentId: claimModal.incidentId || x.data.incidentId,
                        claimId:
                          setSelectedClaim && setSelectedClaim !== ''
                            ? setSelectedClaim
                            : null,
                      },
                    }))
                  }}
                  type="button"
                  className="btn btn-primary pl-10 pr-10"
                  data-dismiss="modal"
                >
                  Yes
                </button>{' '}
                <button
                  onClick={() => {
                    history.replace(`/incidents`)
                    setClaimIdModal(false)
                    setConvertToClaimModal((x) => ({
                      ...x,
                      isOpen: true,
                      data: {
                        incidentId: claimModal.incidentId || x.data.incidentId,
                        claimId: null,
                      },
                    }))
                  }}
                  type="button text-dark"
                  className="btn no-btn pl-10 pr-10"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <IncidentConvertToClaimModal
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isOpen={convertToClaimModal.isOpen}
          context={'incident'}
          data={convertToClaimModal.data}
          onClose={handleModalClose}
        />
        <IncidentModal
          isOpen={incidentModal.isOpen}
          title={incidentModal.title}
          data={incidentModal.data}
          onClose={() => setIncidentModal({ isOpen: false, data: null })}
        />
        <ConfirmationModal
          name={
            checkedRecords.length > 0
              ? `Incidents`
              : (incident?.firstName || '') + ' ' + (incident?.lastName || '')
          }
          isOpen={
            Object.keys(incident || {}).length > 0 &&
            (checkedRecords.length > 0 || deleteOne)
          }
          // isOpen={ checkedRecords.length > 0 || deleteOne}
          onClose={() => {
            setIncident({})
            setShowDeleteButton(false)
            closeBulkDel()
            setDeleteOne(false)
            setCheckedRecords([])
          }}
          onConfirm={handleDeleteModal}
        />
      </>
    )
  )
}

export default IncidentList
