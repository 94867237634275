import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import {
  handleSideNavDataUpdated,
  handleTabIdUpdated,
  savePolicyInfo,
} from '../../actions'
import FilterDropdown from '../../components/common/FilterDropdown'
import ImportCSV from '../../components/common/importCSV'
import ListPageHeader from '../../components/ListPageHeader'
import { amountRangeOptions } from '../../configs/utils'
import { exportClaims } from '../../services/claimService'
import {
  downloadFile,
  getPromiseArrayForImport,
  parseFile,
} from '../../services/helperService'
import { exportIncidents } from '../../services/incidentService'
import { getPolicies } from '../../services/policyService'
import ClaimsList from '../claims/ClaimsList'
import DirectClaim from '../claims/DirectClaim/DirectClaim'
import DirectClaimModal from '../claims/DirectClaim/DirectClaimModal'
import IncidentList from '../incident/IncidentList'
import IncidentModal from '../incident/IncidentModal'
import { allCoverageDescription } from '../incident/utils'
import PublicIncidentList from '../publicIncident/PublicIncidentList'

const initialIncidentFilterState = {
  overdue: {
    key: 'overdue',
    value: 'OVER DUE',
    count: 0,
    isAvailable: false,
  },
  dueToday: {
    key: 'dueToday',
    value: 'DUE NOW',
    count: 0,
    isAvailable: false,
  },
}

const initialClaimFilterState = {
  overdue: {
    key: 'overdue',
    value: 'OVER DUE',
    count: 0,
    isAvailable: false,
  },
  dueToday: {
    key: 'dueToday',
    value: 'DUE NOW',
    count: 0,
    isAvailable: false,
  },
}

const initialIncidentDateRangeFilter = {
  startDate: {
    label: ' Select date range of Loss',
    start: null,
    end: null,
    key: 'startDate',
  },
  endDate: {
    label: ' Select date range of Due Date',
    start: null,
    end: null,
    key: 'endDate',
  },
}

const initialClaimDateRangeFilter = {
  startDate: {
    label: ' Select date range of Claim',
    start: null,
    end: null,
    key: 'startDate',
  },
  endDate: {
    label: ' Select date range of Claim Due Date',
    start: null,
    end: null,
    key: 'endDate',
  },
}

export default function ClaimAndIncidents(props) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [editId, setEditId] = useState('')
  const [tab, setTab] = useState('public-incident')
  const [context, setContext] = useState('')
  const [activeStep, setActiveStep] = useState(1)
  const [claimData, setClaimData] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [activeClaimTabId, setActiveClaimTabId] = useState('1')
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false)
  const [openForm, setOpenForm] = useState({ isOpen: false, data: null })

  const [claimFilters, setClaimFilters] = useState({
    directClaim: {
      name: 'claimType',
      label: 'Select Claim Type',
      key: 'directClaim',
      options: [
        {
          value: 'Direct Claim',
          key: true,
        },
        {
          value: 'Converted Claim',
          key: false,
        },
      ],
      selected: '',
    },
    severity: {
      name: 'claimSeverity',
      label: 'Select Severity',
      key: 'severity',
      options: [
        {
          value: 'Low',
          key: 'Low',
        },
        {
          value: 'Medium',
          key: 'Medium',
        },
        {
          value: 'High',
          key: 'High',
        },
      ],
      selected: '',
    },
    priority: {
      name: 'claimPriority',
      label: 'Select Priority',
      key: 'priority',
      options: [
        {
          value: 'Low',
          key: 'Low',
        },
        {
          value: 'Medium',
          key: 'Medium',
        },
        {
          value: 'High',
          key: 'High',
        },
      ],
      selected: '',
    },
    status: {
      name: 'claimStatus',
      label: 'Select Claim Status',
      key: 'status',
      options: [
        {
          value: 'Open',
          key: 'Open',
        },
        {
          value: 'Closed',
          key: 'Closed',
        },
      ],
      selected: '',
    },
    checkList: {
      name: 'claimCheckList',
      label: 'Select Claim Check List',
      key: 'checkList',
      options: [
        {
          value: 'Claimant Contacted',
          key: 'claimantContacted',
        },
        {
          value: 'Policy Assigned',
          key: 'policyAssigned',
        },
        {
          value: 'Claim Closed',
          key: 'claimClosed',
        },
      ],
      selected: '',
    },
    vehicularAccident: {
      name: 'claimVehicularAccident',
      label: 'Select Vehicular / Not Vehicular',
      key: 'vehicularAccident',
      options: [
        {
          value: 'Vehicular',
          key: 'true',
        },
        {
          value: 'Not Vehicular',
          key: 'false',
        },
      ],
      selected: '',
    },
    coverageType: {
      name: 'coverageType',
      label: 'Select Coverage Type',
      key: 'coverageType',
      //   // }WC", "GL", "Auto", "Property
      options: [
        {
          value: 'WC',
          key: 'WC',
        },
        {
          value: 'GL',
          key: 'GL',
        },
        {
          value: 'Auto',
          key: 'Auto',
        },
        {
          value: 'Property',
          key: 'Property',
        },
      ],
      selected: '',
    },
    amountPaid: {
      name: 'amountPaid',
      label: 'Select Claim Paid',
      key: 'amountPaid',
      options: amountRangeOptions,
      selected: '',
    },
    amountIncurred: {
      name: 'amountIncurred',
      label: 'Select Claim Incurred',
      key: 'amountIncurred',
      options: amountRangeOptions,
      selected: '',
    },
  })
  const [selectIncidentFilters, setSelectIncidentFilters] = useState({
    reportedVia: {
      name: 'reportedVia',
      label: 'Reported Via',
      key: 'reportedVia',
      options: [
        {
          value: 'Email',
          key: 'Email',
        },
        {
          value: 'Phone',
          key: 'Phone',
        },
        {
          value: 'Sms',
          key: 'Sms',
        },
        {
          value: 'Guest Form',
          key: 'Guest Form',
        },
        {
          value: 'Self',
          key: 'Self',
        },
      ],
      selected: '',
    },
    priority: {
      name: 'priorityType',
      label: 'Priority',
      key: 'priority',
      options: [
        {
          value: 'Low',
          key: 'Low',
        },
        {
          value: 'Medium',
          key: 'Medium',
        },
        {
          value: 'High',
          key: 'High',
        },
      ],
      selected: '',
    },
    coverageDescription: {
      name: 'coverageDescription',
      label: 'Select Coverage Description',
      key: 'coverageDescription',
      options: [...allCoverageDescription],
      selected: '',
    },
  })

  const [incidentFilter, setIncidentFilter] = useState(
    initialIncidentFilterState,
  )

  const [claimDueFilter, setClaimDueFilter] = useState(initialClaimFilterState)

  const [inputFilters, setInputFilters] = useState({
    amountPaid: {
      value: '',
      key: 'amountPaid',
      label: 'Claim Paid',
    },
    amountIncurred: {
      value: '',
      key: 'amountIncurred',
      label: 'Claim Incurred',
    },
  })

  const [openBulkDel, setOpenBulkDel] = useState(false)
  const [openBulkIncidentDel, setOpenBulkIncidentDel] = useState(false)

  const [incidentDateRangeFilter, setIncidentDateRangeFilter] = useState(
    initialIncidentDateRangeFilter,
  )

  const [claimDateRangeFilter, setClaimDateRangeFilter] = useState(
    initialClaimDateRangeFilter,
  )

  useEffect(() => {
    getPolicies()
      .then(({ data: policies }) => {
        if (policies?.data && Array.isArray(policies?.data)) {
          savePolicyInfo(policies.data)
        }
      })
      .catch((error) => {})
  }, [])

  // TODO:- Fulfill selected Filter count code.
  const selectedFilterCount = useMemo(() => {
    let count = 0

    let filters = []
    if (tab === 'incident') {
      filters = incidentFilter
      Object.values(selectIncidentFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })
      Object.values(incidentDateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })
    } else if (tab === 'claim') {
      filters = claimDueFilter
      Object.values(claimFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })

      Object.values(inputFilters).forEach(({ value }) => {
        if (value.length) {
          count += 1
        }
      })

      Object.values(claimDueFilter).forEach(({ isAvailable }) => {
        if (isAvailable) {
          count += 1
        }
      })

      Object.values(claimDateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })
    }
    Object.values(filters).forEach(({ isAvailable }) => {
      if (isAvailable) {
        count += 1
      }
    })
    return count
  }, [
    tab,
    incidentFilter,
    claimDueFilter,
    claimFilters,
    claimDateRangeFilter,
    incidentDateRangeFilter,
    selectIncidentFilters,
  ])

  const selectedClaimFilterCount = useMemo(() => {
    let count = 0
    let filters = []
    filters = claimDueFilter
    Object.values(claimFilters).forEach(({ selected }) => {
      if (selected.length) {
        count += 1
      }
    })

    Object.values(inputFilters).forEach(({ value }) => {
      if (value.length) {
        count += 1
      }
    })

    Object.values(claimDueFilter).forEach(({ isAvailable }) => {
      if (isAvailable) {
        count += 1
      }
    })

    Object.values(claimDateRangeFilter).forEach(({ start, end }) => {
      if (start || end) {
        count += 1
      }
    })
    return count
  }, [tab, claimDueFilter, inputFilters, claimFilters, claimDateRangeFilter])

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const exportToCSV = async () => {
    if (tab === 'claim') {
      const { data: claimsData } = await exportClaims()
      downloadFile(claimsData, 'claims.csv')
    } else {
      const { data: incidentsData } = await exportIncidents()
      downloadFile(incidentsData, 'Incidents.csv')
    }
  }

  const importFromCSV = async (file) => {
    try {
      const res = await parseFile(file)
      const createPolicyPromiseArray = getPromiseArrayForImport(res, tab)
      await Promise.all(createPolicyPromiseArray)
      toast.success(`${res.length} items are imported`)
      setContext('fetchList')
      setImportCSVDialogOpen(false)
    } catch (err) {}
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  const handleAddNew = () => {
    setOpenForm({ isOpen: true, data: null })
    if (tab === 'claim') {
      setTitle('Add New Claim')
    } else if (tab === 'incident') {
      setTitle('Add New Incident')
    } else {
      setTitle('Add New Public incident')
    }
  }

  const handleModalClose = () => {
    setEditId('')
    setActiveStep(1)
    setClaimData(null)
    context !== 'fetchList' ? setContext('fetchList') : setContext('')
    setOpenForm({ data: null, isOpen: false })
  }

  let getTableView = () => {
    if (tab === 'claim') {
      return (
        <ClaimsList
          context={context}
          setShowDeleteButton={setShowDeleteButton}
          activeClaimsTabId={handleActiveClaimTabId}
          searchQuery={searchQuery}
          filters={claimDueFilter}
          // inputFilters={inputFilters}
          // setInputFilters={setInputFilters}
          dateRangeFilter={claimDateRangeFilter}
          openBulkDel={openBulkDel}
          closeBulkDel={() => setOpenBulkDel(false)}
          selectFilters={Object.values(claimFilters)
            .filter(({ selected }) => {
              return selected.length
            })
            .reduce((prev, { key, selected }) => {
              if (key === 'directClaim') selected = selected === 'true'
              prev[key] = selected
              return prev
            }, {})}
          setSelectFilter={(coverageTypeList) => {
            const pFilter = { ...claimFilters }
            pFilter.coverageType.options = coverageTypeList
            setClaimFilters(pFilter)
          }}
          setFilterCount={(overDue, dueNow) => {
            setClaimDueFilter({
              overdue: { ...claimDueFilter['overdue'], count: overDue },
              dueToday: { ...claimDueFilter['dueToday'], count: dueNow },
            })
          }}
          isOpen={openForm.isOpen}
        />
      )
    } else if (tab === 'incident') {
      return (
        <IncidentList
          context={context}
          setShowDeleteButton={setShowDeleteButton}
          searchQuery={searchQuery}
          filters={incidentFilter}
          openBulkDel={openBulkIncidentDel}
          closeBulkDel={() => setOpenBulkIncidentDel(false)}
          dateRangeFilter={incidentDateRangeFilter}
          selectFilters={Object.values(selectIncidentFilters)
            .filter(({ selected }) => selected.length)
            .reduce((prev, { key, selected }) => {
              prev[key] = selected
              return prev
            }, {})}
          setFilterCount={(overDue, dueNow) => {
            setIncidentFilter({
              overdue: { ...incidentFilter['overdue'], count: overDue },
              dueToday: { ...incidentFilter['dueToday'], count: dueNow },
            })
          }}
          isOpen={openForm.isOpen}
        />
      )
    } else {
      return <PublicIncidentList />
    }
  }

  const handleActiveClaimTabId = (tab) => {
    setActiveClaimTabId(tab)
  }

  let getModal = () => {
    return (
      <DirectClaimModal
        id={editId}
        title={title}
        data={openForm.data}
        isOpen={openForm.isOpen}
        onClose={handleModalClose}
        claimData={claimData}
        context={tab}
      />
    )
  }

  let getTabsData = () => {
    return [
      {
        module: 'PUBLIC-INCIDENT',
        id: 'public-incident',
        label: 'Public Form',
      },
      {
        module: 'INCIDENT',
        id: 'incident',
        label: 'Incidents',
      },
      {
        module: 'CLAIM',
        id: 'claim',
        label: 'Claims',
        style: { marginRight: '48px' },
      },
    ]
  }

  const onClear = (tab) => {
    if (tab === 'incident') {
      setIncidentFilter(initialIncidentFilterState)
      setSelectIncidentFilters((incident) => ({
        reportedVia: { ...incident['reportedVia'], selected: '' },
        priority: { ...incident['priority'], selected: '' },
        coverageDescription: {
          ...incident['coverageDescription'],
          selected: '',
        },
      }))
      setIncidentDateRangeFilter(initialIncidentDateRangeFilter)
    } else {
      setClaimDueFilter(initialClaimFilterState)
      setClaimFilters((claim) => ({
        directClaim: { ...claim['directClaim'], selected: '' },
        severity: { ...claim['severity'], selected: '' },
        priority: { ...claim['priority'], selected: '' },
        status: { ...claim['status'], selected: '' },
        checkList: { ...claim['checkList'], selected: '' },
        vehicularAccident: { ...claim['vehicularAccident'], selected: '' },
        coverageType: { ...claim['coverageType'], selected: '' },
      }))
      setClaimDateRangeFilter(initialClaimDateRangeFilter)
    }
  }

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView:
          tab === 'incident' ? (
            <FilterDropdown
              filters={incidentFilter}
              setFilters={setIncidentFilter}
              selectFilters={Object.values(selectIncidentFilters)}
              setSelectedFilter={setSelectIncidentFilters}
              dateRangeFilter={incidentDateRangeFilter}
              setDateRangeFilter={setIncidentDateRangeFilter}
              selectedFilterCount={selectedFilterCount}
              dateRangeLabel="Select DateRange for Incident"
              onClear={() => onClear('incident')}
            />
          ) : (
            <FilterDropdown
              filters={claimDueFilter}
              // inputFilters={inputFilters}
              // setInputFilters={setInputFilters}
              setFilters={setClaimDueFilter}
              selectFilters={Object.values(claimFilters)}
              setSelectedFilter={setClaimFilters}
              dateRangeFilter={claimDateRangeFilter}
              setDateRangeFilter={setClaimDateRangeFilter}
              selectedFilterCount={selectedClaimFilterCount}
              dateRangeLabel="Select DateRange for Claim"
              onClear={() => onClear('claim')}
            />
          ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const handleTabChange = (id) => {
    setTab(id)
    dispatch(handleTabIdUpdated(id))
    dispatch(handleSideNavDataUpdated({}, ''))
  }

  const selectedTabId = useSelector((state) => state.selectedTabId)

  useEffect(() => {
    if (selectedTabId) {
      setTab(selectedTabId)
    }
  }, [selectedTabId])

  return (
    <div
      className="position-rel"
      style={{ marginBottom: '15px', width: 'calc(100% - 70px)' }}
    >
      <ListPageHeader
        showTabs={true}
        modules={['INCIDENT', 'CLAIM', 'PUBLIC-INCIDENT']}
        // hideSearch={tab === 'claim' && activeClaimTabId === '1'}
        setTab={handleTabChange}
        showFilters={true}
        selectedTabId={tab}
        onAdd={handleAddNew}
        style={{ maxWidth: '100%' }}
        hideImport={true}
        hideHeader={tab === 'public-incident'}
        tabsData={getTabsData()}
        searchQuery={searchQuery}
        addCategory={addCategory}
        heading={'Claims & Incidents'}
        onSearchChange={handleSearch}
        filtersData={getFiltersData()}
        handleExportToCsv={exportToCSV}
        removeCategory={removeCategory}
        hideDeleteButton={!showDeleteButton}
        exportFormatted={['incident', 'claim'].includes(tab)}
        handleBulkCheckClick={() => {
          tab == 'incident'
            ? setOpenBulkIncidentDel(true)
            : setOpenBulkDel(true)
        }}
        handleImportfromCsv={importFromCSVButtonClicked}
        selectedCategories={selectedCategories}
        filterCount={selectedFilterCount}
      />
      {getTableView()}
      {openForm.isOpen && getModal()}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSV}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}
