import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

export default function ViewNotes({ heading, info = "", isNotesModal, onClose }) {
  return (
    <Modal isOpen={isNotesModal} backdrop="static" toggle={onClose}>
      <div className="modal-header d-flex justify-content-center">
        <h5 className="modal-title text-primary">{heading}</h5>
      </div>
      <ModalBody className="p-0" id="smodaltab">
        <div className="px-4 py-3">
          <div className="row">
            <div className="col-md-12 mt-3">
              <p>
                {info}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque dictum tincidunt ultrices. */}
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      <div className="modal-footer term-footer">
        <button
          type="button"
          className="btn btn-primary add-btn px-4 border-radius-12"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}
