import { ErrorMessage, Field } from 'formik'
import React from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function EmergencyContactInput({
  label,
  name,
  contact,
  setFieldValue,
}) {
  return (
    <div className="form-group">
      <label htmlFor="EmergencyContact">{label}</label>
      <div className="form-row">
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomsting"
            name={`${name}.name`}
            type="text"
            className="form-control"
            placeholder="Name"
            value={contact?.name || ''}
          />
        </div>
        <div className="form-group col-md-6">
          <PhoneInput
            name={`${name}.phoneNumber`}
            defaultCountry="US"
            // displayInitialValueAsLocalNumber={true}
            international={false}
            className={'form-control'}
            placeholder="Phone Number"
            maxLength={14}
            onChange={(phone) =>
              // phone &&
              // phone.length > 2 &&
              // setFieldValue(`${name}.phoneNumber`, phone)
              setFieldValue(`${name}.phoneNumber`, phone)
            }
            value={contact?.phoneNumber?.toString() || ''}
          />
          <ErrorMessage name={`${name}.phoneNumber`} className='text-danger' component="span" />
        </div>
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomsting"
            name={`${name}.relation`}
            type="text"
            className="form-control"
            placeholder="Relation"
            value={contact?.relation}
          />
        </div>
      </div>
    </div>
  )
}
