import React from 'react'
import moment from 'moment'
import { addCommaTableField } from '../pages/incident/utils'

function TransactionTable({ data, hideTransactionId = false }) {
  return (
    data && (
      <div>
        <div>
          <div>
            <div className="table-responsive">
              <table
                className="table table-vertical-center mb-0"
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    {!hideTransactionId && (
                      <th className="text-dark-75 font-weight-bolder text-primary">
                        Transaction ID
                      </th>
                    )}
                    <th className="text-dark-75 font-weight-bolder text-primary">
                      Amount
                    </th>
                    <th style={{ minWidth: '150px' }} className="text-right">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((el,i) => (
                    <tr key={''+el.transactionId+i}>
                      {!hideTransactionId && <td>{el.transactionId}</td>}
                      <td> ${el.amount ? addCommaTableField(el.amount) : 'N/A'}</td>
                      <td className="text-right">
                        {moment(el.transactionDate).format('MM/DD/YYYY')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default TransactionTable
