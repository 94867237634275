import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import { transformPhoneNumber } from '../../pages/incident/utils'

export default function ThirdPartyRecordInfo(props) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3  d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Fiscal Year End"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="84"
                  height="81"
                  viewBox="0 0 84 81"
                >
                  <g
                    id="Group_10455"
                    data-name="Group 10455"
                    transform="translate(-1442 -118)"
                  >
                    <ellipse
                      id="Ellipse_255"
                      data-name="Ellipse 255"
                      cx="42"
                      cy="40.5"
                      rx="42"
                      ry="40.5"
                      transform="translate(1442 118)"
                      fill="#e8eded"
                    />
                    <path
                      id="Path_43"
                      data-name="Path 43"
                      d="M44.127,12.989a7.988,7.988,0,1,0-15.975,0c0,4.4,3.583,9.079,7.988,9.079s7.988-4.676,7.988-9.079Z"
                      transform="translate(1446.991 136.663)"
                      fill="#fff"
                    />
                    <path
                      id="Path_44"
                      data-name="Path 44"
                      d="M9.191,62.639c0,2.906,6.679,5.263,14.92,5.263s14.92-2.356,14.92-5.263a10.633,10.633,0,0,0-8.122-10.33c-1.287,2.085-8.638,6.443-13.494-.024A10.634,10.634,0,0,0,9.192,62.639Z"
                      transform="translate(1459.02 106.665)"
                      fill="#fff"
                    />
                    <path
                      id="Path_45"
                      data-name="Path 45"
                      d="M72.46,66.925a1.1,1.1,0,0,1-2.194,0,1.1,1.1,0,0,1,2.194,0"
                      transform="translate(1420.274 98.073)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data.thirdPartyId}</div>
                {data.firstName + ' ' + data.lastName}
              </span>
              <p className="mt-3 mb-1 text-black-50">Contact Number</p>
              <span>{transformPhoneNumber(data?.contactNumber)}</span>
            </div>
            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          {/* <div className="text-center px-5 pb-2">
                        <button type="button" className="btn btn-primary mr-3">
                            Add Comment
                        </button>
                        <button type="button" className="btn btn-outline-secondary side-btn mr-3">
                            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                        </button>
                        <button type="button" className="btn btn-outline-secondary side-btn">
                            <i className="far fa-trash-alt" aria-hidden="true"></i>
                        </button>
                    </div> */}
        </div>
        <div className="pr-2 pb-4" style={{ overflow: 'hidden' }}>
          <div id="main1">
            <div className="container">
              <div className="">
                <div className="pt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12.167"
                              height="18.25"
                              viewBox="0 0 12.167 18.25"
                            >
                              <g id="smartphone" transform="translate(-4)">
                                <path
                                  id="Path_17004"
                                  data-name="Path 17004"
                                  d="M12.365,0H7.8A3.807,3.807,0,0,0,4,3.8V14.448a3.807,3.807,0,0,0,3.8,3.8h4.562a3.807,3.807,0,0,0,3.8-3.8V3.8A3.807,3.807,0,0,0,12.365,0ZM7.8,1.521h4.562A2.281,2.281,0,0,1,14.646,3.8v8.365H5.521V3.8A2.281,2.281,0,0,1,7.8,1.521Zm4.562,15.208H7.8a2.281,2.281,0,0,1-2.281-2.281v-.76h9.125v.76A2.281,2.281,0,0,1,12.365,16.729Z"
                                />
                                <circle
                                  id="Ellipse_739"
                                  data-name="Ellipse 739"
                                  cx="1"
                                  cy="1"
                                  r="1"
                                  transform="translate(9.083 13.25)"
                                />
                              </g>
                            </svg>
                          </span>{' '}
                          Designation
                        </div>
                        <span className="ml-4">{data.designation}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                            >
                              <path
                                id="phone-call"
                                d="M8.153.625A.626.626,0,0,1,8.779,0a6.267,6.267,0,0,1,6.26,6.26.626.626,0,0,1-1.252,0A5.014,5.014,0,0,0,8.779,1.251.626.626,0,0,1,8.153.625Zm.626,3.13a2.5,2.5,0,0,1,2.5,2.5.626.626,0,0,0,1.252,0A3.76,3.76,0,0,0,8.779,2.5a.626.626,0,1,0,0,1.252Zm5.692,6.723a1.941,1.941,0,0,1,0,2.741l-.57.657C8.775,18.785-3.7,6.311,1.131,1.168l.72-.626A1.929,1.929,0,0,1,4.56.567c.019.019,1.179,1.526,1.179,1.526a1.941,1.941,0,0,1,0,2.681l-.725.911a8,8,0,0,0,4.339,4.348l.917-.729a1.941,1.941,0,0,1,2.68,0S14.452,10.459,14.471,10.478Zm-.861.91s-1.5-1.153-1.517-1.172a.689.689,0,0,0-.97,0c-.017.018-1.28,1.024-1.28,1.024a.626.626,0,0,1-.613.1A9.4,9.4,0,0,1,3.708,5.821.626.626,0,0,1,3.8,5.2S4.8,3.932,4.822,3.916a.689.689,0,0,0,0-.97C4.8,2.927,3.65,1.428,3.65,1.428a.689.689,0,0,0-.945.024l-.72.626c-3.532,4.247,7.28,14.459,11,10.942l.57-.657a.7.7,0,0,0,.053-.975Z"
                                transform="translate(-0.039 0.002)"
                              />
                            </svg>
                          </span>{' '}
                          Email
                        </div>
                        <span className="ml-4">{data.email}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="14.999"
                              viewBox="0 0 15 14.999"
                            >
                              <path
                                id="cake-birthday"
                                d="M14.375,13.751H13.75v-5a3.129,3.129,0,0,0-3.125-3.125h-2.5V3.775a1.562,1.562,0,0,0,.937-1.431A4.558,4.558,0,0,0,7.961.205a.625.625,0,0,0-.922,0,4.558,4.558,0,0,0-1.1,2.139,1.562,1.562,0,0,0,.937,1.431V5.626h-2.5A3.129,3.129,0,0,0,1.25,8.751v5H.625a.625.625,0,0,0,0,1.25h13.75a.625.625,0,0,0,0-1.25Zm-10-6.875h6.25A1.875,1.875,0,0,1,12.5,8.751V9.988c-.585-.062-.937-.437-.937-.612a.625.625,0,0,0-1.25,0c0,.214-.426.625-1.094.625s-1.094-.434-1.094-.625a.625.625,0,0,0-1.25,0c0,.214-.426.625-1.094.625s-1.094-.434-1.094-.625a.625.625,0,0,0-1.25,0c0,.2-.362.555-.937.613V8.751A1.875,1.875,0,0,1,4.375,6.876ZM2.5,11.238a2.6,2.6,0,0,0,1.563-.612,2.693,2.693,0,0,0,3.438.009,2.693,2.693,0,0,0,3.438-.009,2.6,2.6,0,0,0,1.562.611v2.514H2.5Z"
                                transform="translate(0 -0.002)"
                              />
                            </svg>
                          </span>{' '}
                          Organization
                        </div>
                        <span className="ml-4">{data.organisation}</span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="incident-text">
                        <div className="d-flex">
                          <span className="mr-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="13.75"
                              viewBox="0 0 15 13.75"
                            >
                              <path
                                id="envelope"
                                d="M11.875,1H3.125A3.129,3.129,0,0,0,0,4.125v7.5A3.129,3.129,0,0,0,3.125,14.75h8.75A3.129,3.129,0,0,0,15,11.625v-7.5A3.129,3.129,0,0,0,11.875,1ZM3.125,2.25h8.75a1.875,1.875,0,0,1,1.738,1.179L8.826,8.216a1.879,1.879,0,0,1-2.652,0L1.388,3.429A1.875,1.875,0,0,1,3.125,2.25Zm8.75,11.25H3.125A1.875,1.875,0,0,1,1.25,11.625V5.063L5.29,9.1a3.129,3.129,0,0,0,4.42,0l4.04-4.038v6.563A1.875,1.875,0,0,1,11.875,13.5Z"
                                transform="translate(0 -1)"
                              />
                            </svg>
                          </span>{' '}
                          Type Of Enquiry
                        </div>
                        <span className="ml-4">{data.typeOfEnquiry}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
