import moment from "moment";
import { NO_TIMEZONE_DATE_FORMAT } from "../../utils/helper";

export const convertDateToStringClaimForm = (values) => {
    values.claimants[0]?.dateOfClaim &&
        (values.claimants[0].dateOfClaim = moment(values.claimants[0]?.dateOfClaim).format(NO_TIMEZONE_DATE_FORMAT));

    values.claimants[0]?.startDate &&
        (values.claimants[0].startDate = moment(values.claimants[0]?.startDate).format(NO_TIMEZONE_DATE_FORMAT));
    
    values.claimants[0]?.endDate &&
        (values.claimants[0].endDate = moment(values.claimants[0]?.endDate).format(NO_TIMEZONE_DATE_FORMAT));
    
    values.claimants[0]?.closeDate &&
        (values.claimants[0].closeDate = moment(values.claimants[0]?.closeDate).format(NO_TIMEZONE_DATE_FORMAT));
    
    values.claimants[0]?.openSince &&
        (values.claimants[0].openSince = moment(values.claimants[0]?.openSince).format(NO_TIMEZONE_DATE_FORMAT));
    
    if (values.claimants[0]?.financials && Array.isArray(values.claimants[0]?.financials)) {
        values.claimants[0].financials = values?.claimants[0]?.financials?.map((e) => ({ ...e, transactionDate: e.transactionDate !== '' ? moment(e.transactionDate).format(NO_TIMEZONE_DATE_FORMAT) : null }))
    }
    return values;
}