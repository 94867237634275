import React from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik'
import { getObjectValueByStringKey } from '../../pages/incident/utils'

function TextArea({
  label,
  name,
  cols,
  rows,
  showErrorEvenWhenUntouched = false,
  withAsterisk,
  ...rest
}) {
  const { errors } = useFormikContext()
  return (
    <div className="form-group">
      {label?.length ? (
        <label
          htmlFor={rest.id ?? `text-area-${name}`}
          data-testid={`text-area-label-${name}`}
        >
          {label} {withAsterisk && <span className="text-danger">*</span>}
        </label>
      ) : null}
      <Field
        id={`text-area-${name}`}
        data-testid={`text-area-${name}`}
        cols={cols}
        rows={rows}
        autoComplete="off"
        placeholder={label}
        className="form-control"
        name={name}
        component="textarea"
        {...rest}
      />
      {showErrorEvenWhenUntouched ? (
        <div className="text-danger" data-testid={`text-area-error-${name}`}>
          {getObjectValueByStringKey(errors, name)}
        </div>
      ) : (
        <ErrorMessage
          className="text-danger"
          name={name}
          component="div"
          data-testid={`text-area-error-${name}`}
        />
      )}
    </div>
  )
}

export default TextArea
