import React, { useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { timeNowFormat } from '../configs/utils'

const AttachmentView = ({
  attachment,
  index,
  deleteAttachments,
  downloadAttachments,
  copyLink,
  onDeleteAttachment,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false)

  const url = attachment.url
  let fileName = attachment.fileName
  let isFileImage = fileName?.match(/\.(jpg|jpeg|png|gif)$/)

  // download attachment
  const downloadAttachment = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="col-md-6" key={index}>
      <div className="attachment">
        <div className="attach-img d-flex justify-content-center align-items-center ">
          <a
            target="_blank"
            href={url}
            rel="noreferer noreferrer"
            role="button"
            className="btn btn-primary btn-down"
          >
            Download
          </a>
          <div className="text-center">
            {isFileImage ? (
              <object
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                data={url}
              />
            ) : (
              <>
                <svg
                  id="picture"
                  xmlns={url}
                  width="34.705"
                  height="34.705"
                  viewBox="0 0 34.705 34.705"
                >
                  <path
                    id="Path_17011"
                    data-name="Path 17011"
                    d="M27.475,0H7.23A7.239,7.239,0,0,0,0,7.23V27.475A7.239,7.239,0,0,0,7.23,34.7H27.475a7.239,7.239,0,0,0,7.23-7.23V7.23A7.239,7.239,0,0,0,27.475,0ZM7.23,2.892H27.475A4.338,4.338,0,0,1,31.813,7.23V27.475a4.267,4.267,0,0,1-.434,1.858l-13.25-13.25a7.23,7.23,0,0,0-10.226,0l-5.01,5.009V7.23A4.338,4.338,0,0,1,7.23,2.892Zm0,28.921a4.338,4.338,0,0,1-4.338-4.338V25.181l7.054-7.054a4.338,4.338,0,0,1,6.137,0l13.25,13.251a4.267,4.267,0,0,1-1.858.434Z"
                    fill="#868686"
                  ></path>
                  <path
                    id="Path_17012"
                    data-name="Path 17012"
                    d="M17.561,13.622A5.061,5.061,0,1,0,12.5,8.561,5.061,5.061,0,0,0,17.561,13.622Zm0-7.23a2.169,2.169,0,1,1-2.169,2.169,2.169,2.169,0,0,1,2.169-2.169Z"
                    transform="translate(5.575 1.561)"
                    fill="#868686"
                  ></path>
                </svg>
                <div className="">Preview Not Available</div>
              </>
            )}
          </div>
        </div>
        <div className="px-2">
          <div className="d-flex justify-content-between pb-2">
            <div>FileName: {fileName}</div>
            <div>
              <div>
                <Dropdown isOpen={dropdownOpen} className="dropdown-inline">
                  <DropdownToggle
                    onClick={() => {
                      setdropdownOpen(!dropdownOpen)
                    }}
                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                  >
                    <i className="fas fa-ellipsis-h" aria-hidden="false"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {copyLink && (
                      <DropdownItem
                        onClick={() => {
                          navigator.clipboard.writeText(attachment.url)
                          setdropdownOpen(!dropdownOpen)
                        }}
                      >
                        Copy Link
                      </DropdownItem>
                    )}

                    {deleteAttachments && (
                      <DropdownItem
                      onClick={ () => {
                        onDeleteAttachment(index)
                        setdropdownOpen(!dropdownOpen)
                      }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                    {downloadAttachments && (
                      <DropdownItem
                        onClick={() => {
                          downloadAttachment(attachment.url)
                          setdropdownOpen(!dropdownOpen)
                        }}
                      >
                        Download
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <p className="my-0 py-0">{timeNowFormat(attachment.created)}</p>
        </div>
      </div>
    </div>
  )
}

export default AttachmentView
