import React from 'react'

import TextArea from '../components/form/TextArea'
import { generateUID } from './generateUID'

export const initialVehicleDetail = {
  vehicleDetails: {
    vehicleType: 'company',
    companyVehicleType: 'Branded',
    yearOfManufacture: '',
    model: '',
    make: '',
    mileage: '',
    vin: '',
    van: '',
    licensePlate: '',
    damageState: '',
    vehicleDamageParts: [],
    vanParts: {},
    sedanParts: {},
  },
}

export const initialPublicIncidentValue = {
  publicIncidentId: generateUID('public-incident'),
  clientId: '',
  incidentDetails: {
    dateOfIncident: '',
    employeeActivity: '',
    time: '00:00:00 AM',
    isInjury: "",
    policeCalled: "",
    driverStatement: '',
    location: '',
    policeReportDetails: {
      policeReportNumber: '',
      policeReportcountry: '',
    },
    natureOfInjury: '',
    isAmbulance: '',
    painLevel: '',
    substance: '',
    publicMemberInjured: "false",
    publicMemberInjuredDetails: '',
    incidentDescription: '',
    allegedInjury: '',
    unsafeCondition: '',
    thirdPartyInvolvementType: 'None',
    thirdPartyLeaves: "false",
    isThirdPartyPresent: "",
    thirdPartyDetails: {
      thirdPartyName: '',
      thirdPartyContactNumber: '',
    },
    hasWitnesses: "",
    witnesses: [
      {
        name: '',
        contactNumber: '',
      },
    ],
    isMedicalTreatment: "",
    medicalDeclinationDetails: {
      empName: '',
      dateOfIncident: '',
      empSignature: '',
    },
    medicalTreatmentType: [],
    medicalTreatmentProviderInfo: '',
    injuredThirdPartyDetails: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
    },
  },
  shiftStartTime: '00:00:00 AM',
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    ficoScore: '',
    jobTitle: '',
    jobSite: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    contactNumber: '',
    userSignature: '',
    dateOfBirth: ''
  },
  vehicleNature: 'vehicular',
  vehiclesInvolved: [{ ...initialVehicleDetail }],
  supervisorDetails: {
    isNotify: '',
    name: '',
    contactNumber: '',
    notifiedDate: '',
    notificationMethod: '',
    email: '',
  },
  isAgree: '',
  ipAddress: '',
  attachments: [],
  humanParts: [],
}

export const getThirdPartyInvolvementTypeDetails = (involvementType) => {
  let placeholder = ''
  let name = ''
  switch (involvementType) {
    case 'Animal':
      placeholder = 'Please describe injury details'
      name = 'incidentDetails.thirdPartyAnimalDetails'
      break
    case 'Vehicle':
      placeholder = 'Please enter vehicle & damage details'
      name = 'incidentDetails.thirdPartyPropertyDetails'
      break
    case 'Equipment':
      placeholder = 'Please enter equipment & damage details'
      name = 'incidentDetails.thirdPartyEquipmentDetails'
      break
    case 'Other':
      placeholder = 'Please mention details'
      name = 'incidentDetails.thirdPartyOtherDetails'
      break
    case 'Person/Colleague':
      placeholder = 'Please describe injury details'
      name = 'incidentDetails.thirdPartyPersonDetails'
  }

  return (
    <div className="form-group">
      <TextArea cols={30} rows={5} placeholder={placeholder} name={name} />
    </div>
  )
}
