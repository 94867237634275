import React from 'react'
import { Modal } from 'reactstrap'
import ThirdPartyForm from './ThirdPartyForm'

import { ReactComponent as Close } from '../../svgs/close.svg'

export default function ThirdPartyModal({isOpen, title, data,onClose}) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{minWidth: '60vw'}}
        >
            <div>
                <a onClick={onClose} className="close close-left" aria-label="Close">
                    <Close />
                </a>
                <div className="modal-content mdl-scrol" style={{height: '100vh'}}>
                    <div className="row no-gutters col-container">
                        <ThirdPartyForm data={data} onClose={onClose} title={title}/>
                    </div>
                </div>
            </div>
      </Modal>
    )
}
