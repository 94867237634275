/* eslint-disable react/prop-types */
import { Formik, Form } from 'formik'
import React, { useState, useRef } from 'react'
import TextInput from './form/TextInput'
import { Modal, ModalBody } from 'reactstrap'
import { ORGANIZATION_VALIDATION_SCHEMA } from '../utils/validation'

export default function AddOrganizationalStructureBtn(props) {
  const ref = useRef()
  const { buttonLabel, title, isOpen } = props
  const [modal, setModal] = useState(isOpen || false)

  const init = () => {
    return { name: '', position: '', email: '', phoneNumber: '' }
  }
  const toggle = () => {
    setModal(!modal)
    !buttonLabel && props.modalClose(null)
  }
  const handleSubmit = () => {

    props.modalClose({ ...ref.current?.values,  } || {})
    toggle()
    ref.current.resetForm()
  }

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      initialValues={{
        ...init(),
        ...props.values,
      }}
      validationSchema={ORGANIZATION_VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <div>
            {buttonLabel && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mt-2"
                id="exampleModalLabel"
                data-toggle="modal"
                data-target="#vendorlistpayroll"
                onClick={toggle}
              >
                {buttonLabel}
              </button>
            )}
            <Modal
              isOpen={modal}
              backdrop="static"
              toggle={toggle}
              className={
                'modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable'
              }
            >
              <div className={'modal-header justify-content-center'}>
                <h5
                  className="modal-title text-primary "
                  id="modal-basic-title"
                >
                  {title}
                </h5>
              </div>
              <ModalBody className="p-0" id="smodaltab">
                <div>
                  <ul
                    className="nav nav-pills bg-light d-flex justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active mt-0 font-weight-normal"
                        id="Person-tab"
                        data-toggle="pill"
                        href="#Person"
                        role="tab"
                        aria-controls="pills-Person"
                        aria-selected="true"
                      >
                        Organization Details
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className={'px-5 mt-4 pb-3'}>
                    <Form>
                      <div className="form-row">
                        <div className="col-md-12">
                          <TextInput label="Name" name="name" />
                        </div>
                        <div className="col-md-12">
                          <TextInput label="Position" name="position" />
                        </div>
                        <div className="col-md-12">
                          <TextInput label="Email" name="email" />
                        </div>
                        <div className="col-md-12">
                          <TextInput
                            isPhone
                            label="Phone"
                            name="phoneNumber"
                            maxLength={14}
                            onPhoneChange={(phone) => {
                              setFieldValue('phoneNumber', phone || '')
                            }}
                            value={values?.phoneNumber || ''}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div
                  className={'modal-footer'}
                  style={{ background: '#F4F7FE' }}
                >
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary  add-btn"
                  >
                    Add
                  </button>
                  <button
                    onClick={toggle}
                    type="button"
                    className="btn btn-secondary bg-white text-dark cls-btn"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </>
      )}
    </Formik>
  )
}
