import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'reactstrap'
import { getEmployees } from '../../services/employeeService'
import { getVehicles } from '../../services/vehicleService'
import Select1 from 'react-select'
import _ from 'lodash'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Formik } from 'formik'
import AddressInput from '../../components/form/AddressInput'

function VehicleInvolvedModal({
  isOpen,
  onClose,
  values: initialValues,
  setFieldValue,
  editId,
  editData,
  directClaim = false,
  clientId = '',
}) {
  const [filteredDriverList, setFilteredDriverList] = useState([])
  const [filteredVehList, setFilteredVehList] = useState([])
  const addressRef = useRef(true)
  const [driverDetailVisible, setDriverDetailVisible] = useState(true)
  const [selectedDriverIndex, setSelectedDriverIndex] = useState(-1)
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(-1)
  const [driverList, setDriverList] = useState(null)
  const [vehicleList, setVehicleList] = useState(null)
  const [driverDetails, setdriverDetails] = useState({
    firstName: '',
    lastName: '',
    empId: '',
    email: '',
    company: '',
    contactNumber: '',
  })
  const [vehicleDetails, setvehicleDetails] = useState(() => {
    if (editData) {
      const { make, model, yearOfManufacture: year, vinNumber, vehId } = editData.vehicleDetails
      return { make, model, year, vinNumber, vehId }
    }
    return {
      make: '',
      model: '',
      year: '',
      vinNumber: '',
      vehId: '',
    }
  })
  const [data, setdata] = useState(() => {
    if (editData) {
      const {
        isThirdPartyDriver,
        thirdPartyDriverDetails,
        isThirdPartyVehicle,
        thirdPartyVehicleDetails,
        vehicleDamages,
        otherVehicleDamages = '',
      } = editData
      return {
        isThirdPartyDriver,
        thirdPartyDriverDetails,
        isThirdPartyVehicle,
        thirdPartyVehicleDetails,
        vehicleDamages: vehicleDamages ?? [],
        otherVehicleDamages,
      }
    }
    return {
      isThirdPartyDriver: false,
      thirdPartyDriverDetails: '',
      isThirdPartyVehicle: false,
      thirdPartyVehicleDetails: '',
      vehicleDamages: [],
      otherVehicleDamages: '',
    }
  })
  const [address, setAddress] = useState(() => {
    if (editData) {
      const { address } = editData.driverDetails
      const { line1, line2, city, state, country, pinCode } = address
      return { line1, line2, city, state, country, pinCode }
    }
    return {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    }
  })

  useEffect(() => {
    if (
      filteredDriverList &&
      editData &&
      editData.driverDetails &&
      selectedDriverIndex === -1
    ) {
      let index = _.findIndex(filteredDriverList, { _id: editData.driverDetails.empId })
      setSelectedDriverIndex(index)
    }

    if (
      filteredVehList &&
      editData &&
      editData.vehicleDetails &&
      selectedVehicleIndex === -1
    ) {
      let index = _.findIndex(filteredVehList, {
        _id: editData.vehicleDetails.vehId,
      })
      setSelectedVehicleIndex(index)
    }
  }, [editData, filteredDriverList, filteredVehList])

  const getEmployeesData = async () => {
    const { data: employeListData } = await getEmployees()
    setDriverList(employeListData.data)
  }

  const getVehiclesData = async () => {
    const { data: vehData } = await getVehicles()
    setVehicleList(vehData.data)
  }

  useEffect(() => {
    getEmployeesData()
    getVehiclesData()
  }, [])

  useEffect(async () => {
    let employeListByClient = driverList?.filter(
      (a) =>
        a.isDriver &&
        (a?.clientId === initialValues?.clientId || a?.clientId === clientId),
    )
    setFilteredDriverList([
      ...employeListByClient || [],
      { _id: 'OTHERS', value: 'Other', key: 'Other' },
    ])

    let vehDataByClient = vehicleList?.filter(
      (a) => a?.clientId === initialValues?.clientId || a?.clientId === clientId,
    )
    setFilteredVehList([
      ...vehDataByClient || [],
      { _id: 'OTHERS', value: 'Other', key: 'Other' },
    ])

  }, [
    initialValues?.clientId,
    clientId,
    data?.isThirdPartyDriver,
    data?.isThirdPartyVehicle,
  ])

  useEffect(() => {
    if (editData) {
      const {
        firstName,
        lastName,
        empId,
        address,
        email,
        company,
        contactNumber,
      } = editData.driverDetails
      setdriverDetails({
        firstName,
        lastName,
        empId,
        email,
        company,
        contactNumber,
      })

      const { line1, line2, city, state, country, pinCode } = address
      setAddress({ line1, line2, city, state, country, pinCode })

      const { make, model, yearOfManufacture: year, vinNumber, vehId, licensePlate, notes } = editData.vehicleDetails
      setvehicleDetails({ make, model, year, vinNumber, vehId, licensePlate, notes })

      const {
        isThirdPartyDriver,
        thirdPartyDriverDetails,
        isThirdPartyVehicle,
        thirdPartyVehicleDetails,
        vehicleDamages,
        otherVehicleDamages = '',
      } = editData

      setdata({
        isThirdPartyDriver,
        thirdPartyDriverDetails,
        isThirdPartyVehicle,
        thirdPartyVehicleDetails,
        vehicleDamages: vehicleDamages?.map((prop) => {
          if (typeof prop === 'string') {
            return {
              label: prop,
              value: prop,
            }
          }
          return prop
        }),
        otherVehicleDamages,
      })
    } else {
      resetState()
    }
  }, [initialValues, editData])

  const resetState = () => {
    let address = {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    }

    let data = {
      isThirdPartyDriver: false,
      thirdPartyDriverDetails: '',
      isThirdPartyVehicle: false,
      thirdPartyVehicleDetails: '',
      vehicleDamages: [],
      otherVehicleDamages: '',
    }

    let vehicleDetails = {
      make: '',
      model: '',
      year: '',
      vinNumber: '',
      vehId: '',
      licensePlate: '',
      notes: ''
    }

    let driverDetails = {
      firstName: '',
      lastName: '',
      empId: '',
      email: '',
      company: '',
      contactNumber: '',
    }

    setAddress(address)
    setdata(data)
    setvehicleDetails(vehicleDetails)
    setdriverDetails(driverDetails)
    setSelectedDriverIndex(-1)
    setSelectedVehicleIndex(-1)
  }

  const handleSave = (values) => {
    const a = {
      ...data,
      driverDetails: {
        ...driverDetails,
        address: values.address || addressRef.current?.initialValues?.address || address || {},
      },
      vehicleDetails,
    }
    const party =
      editId != null
        ? [...initialValues?.vehiclesInvolved.filter((a, i) => i != editId), a]
        : [...initialValues.vehiclesInvolved, a]

    if (directClaim) {
      setFieldValue('incident.vehiclesInvolved', party)
    } else {
      setFieldValue('vehiclesInvolved', party)
    }

    onClose()
  }

  const handleCancel = () => {
    onClose()
    resetState()
  }

  const options = [
    { label: 'Bumper-Front', value: 'Bumper-Front' },
    { label: 'Bumper-Back', value: 'Bumper-Back' },
    { label: 'Mirror–Driver Side', value: 'Mirror–Driver Side' },
    { label: 'Mirror-Passenger Side', value: 'Mirror-Passenger Side' },
    { label: 'Driver Side', value: 'Driver Side' },
    { label: 'Passenger Side', value: 'Passenger Side' },
    { label: 'Others', value: 'Others' },
  ]

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      centered
      style={{
        height: '400px',
        position: 'absolute',
        top: '-23%',
        left: '31%',
        minWidth: '500px',
      }}
    >
      <div id="vechinvform">
        <Formik
          innerRef={addressRef}
          // enableReinitialize
          initialValues={{ address }}
          onSubmit={handleSave}
        >
          {({ setFieldValue, handleSubmit }) => (
            <div>
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title text-primary " id="">
                    Add vehicle involved in the incident
                  </h5>
                </div>
                <div className="modal-body p-0" id="smodaltab">
                  <ul
                    className="nav nav-pills bg-light d-flex justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => setDriverDetailVisible(true)}
                    >
                      <a
                        className={`nav-link  mt-0 font-weight-normal ${driverDetailVisible ? 'active' : ''
                          }`}
                        id="Person-tab"
                        data-toggle="pill"
                        href="#driver"
                        role="tab"
                        aria-controls="pills-Person"
                        aria-selected="true"
                      >
                        Driver Details
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={() => setDriverDetailVisible(false)}
                    >
                      <a
                        className={`nav-link  mt-0 font-weight-normal ${driverDetailVisible ? '' : 'active'
                          }`}
                        id="Person-tab"
                        data-toggle="pill"
                        href="#vehicle"
                        role="tab"
                        aria-controls="pills-Person"
                        aria-selected="true"
                      >
                        Vehicle Details
                      </a>
                    </li>
                  </ul>
                  <div
                    className="tab-content"
                    id="pill-tabContent1"
                    style={{ height: '400px', overflowY: 'auto' }}
                  >
                    <div
                      className="tab-pane fade show active"
                      id="driver"
                      role="tabpanel"
                      aria-labelledby="Person-tab"
                    >
                      {driverDetailVisible && (
                        <>
                          <div className="py-4 d-flex justify-content-center align-items-center">
                            <span className="mr-4"> Is Company Driver?</span>
                            <div
                              style={{ height: '44px' }}
                              className="btn-group btn-group-toggle border btn-yesno"
                              data-toggle="buttons"
                            >
                              <label
                                className={
                                  'btn mr-2 px-3 ' +
                                  (!data.isThirdPartyDriver ? 'active' : '')
                                }
                                onClick={() =>
                                  setdata((x) => ({
                                    ...x,
                                    isThirdPartyDriver: false,
                                  }))
                                }
                              >
                                <input
                                  checked={!data.isThirdPartyDriver}
                                  type="radio"
                                  name="dd"
                                  id="option6"
                                  label={'yes'}
                                  readOnly
                                />{' '}
                                Yes
                              </label>
                              <label
                                className={
                                  'btn px-3 ' +
                                  (data.isThirdPartyDriver ? 'active' : '')
                                }
                                onClick={() =>
                                  setdata((x) => ({
                                    ...x,
                                    isThirdPartyDriver: true,
                                  }))
                                }
                              >
                                <input
                                  checked={data.isThirdPartyDriver}
                                  type="radio"
                                  name="dd"
                                  id="option5"
                                  label={'No'}
                                  readOnly
                                />{' '}
                                No
                              </label>
                            </div>
                          </div>
                          {data.isThirdPartyDriver && (
                            <div className="px-4">
                              <form>
                                <div className="form-row">
                                  <div className="form-group col-md-6">
                                    <label
                                      htmlFor="f-name"
                                      className="col-form-label"
                                    >
                                      First Name
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setdriverDetails((x) => ({
                                          ...x,
                                          firstName: a.target.value,
                                        }))
                                      }
                                      label={'First Name'}
                                      name={'firstName'}
                                      type="text"
                                      className="form-control"
                                      id="f-name"
                                      value={driverDetails.firstName}
                                    />
                                  </div>

                                  <div className="form-group col-md-6">
                                    <label
                                      htmlFor="lname"
                                      className="col-form-label"
                                    >
                                      Last Name
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setdriverDetails((x) => ({
                                          ...x,
                                          lastName: a.target.value,
                                        }))
                                      }
                                      label={'Last Name'}
                                      name={'lastName'}
                                      type="text"
                                      className="form-control"
                                      id="lname"
                                      value={driverDetails.lastName}
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      htmlFor="email"
                                      className="col-form-label"
                                    >
                                      Email
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setdriverDetails((x) => ({
                                          ...x,
                                          email: a.target.value,
                                        }))
                                      }
                                      label={'Email'}
                                      name={'email'}
                                      type="email"
                                      className="form-control"
                                      id=""
                                      value={driverDetails.email}
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      htmlFor="Company"
                                      className="col-form-label"
                                    >
                                      Company
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setdriverDetails((x) => ({
                                          ...x,
                                          company: a.target.value,
                                        }))
                                      }
                                      label={'Company'}
                                      name={'company'}
                                      type="text"
                                      className="form-control"
                                      id=""
                                      value={driverDetails.company}
                                    />
                                  </div>
                                  <div className="form-group col-md-12">
                                    <label
                                      htmlFor="Contact"
                                      className="col-form-label"
                                    >
                                      Contact Number
                                    </label>
                                    <PhoneInput
                                      name={`contactNumber`}
                                      defaultCountry="US"
                                      // displayInitialValueAsLocalNumber={true}
                                      international={false}
                                      className={'form-control'}
                                      placeholder="Contact Number"
                                      onChange={(phone) => {
                                        setdriverDetails((x) => ({
                                          ...x,
                                          contactNumber: phone || '',
                                        }))
                                      }}
                                      maxLength={14}
                                      id="Contact"
                                      value={driverDetails.contactNumber || ''}
                                    />
                                    {/* <label htmlFor="Contact" className="col-form-label">
                                Contact Number
                              </label>
                              <input
                                onChange={(a) =>
                                  setdriverDetails((x) => ({
                                    ...x,
                                    contactNumber: a.target.value,
                                  }))
                                }
                                label={'Contact Number'}
                                name={'contactNumber'}
                                type="text"
                                className="form-control"
                                id="Contact"
                                value={driverDetails.contactNumber}
                              /> */}
                                  </div>
                                  {driverDetailVisible && (
                                    <AddressInput
                                      label="Address"
                                      name="address"
                                      setFieldValue={setFieldValue}
                                    />
                                  )}
                                </div>
                              </form>
                            </div>
                          )}
                          {filteredDriverList && !data.isThirdPartyDriver && (
                            <div className="form-group col-md-12">
                              <label>Select Driver</label>
                              <Select1
                                placeholder="Select Driver"
                                getOptionLabel={(option) =>
                                  option.employeeId
                                    ? `${option.employeeId} - ${option.firstName} ${option.lastName}`
                                    : option.value
                                }
                                getOptionValue={(option) => option}
                                isSearchable="true"
                                onChange={(val) => {
                                  setSelectedDriverIndex(
                                    filteredDriverList.indexOf(val),
                                  )
                                  setdriverDetails((x) => ({
                                    ...x,
                                    employeeId: val._id || '',
                                    firstName: val.firstName || '',
                                    lastName: val.lastName || '',
                                    empId: val._id || '',
                                    email: val.email || '',
                                    contactNumber:
                                      val.officePhone + '' ||
                                      val.personalPhone + '' ||
                                      '',
                                  }))
                                }}
                                value={
                                  selectedDriverIndex >= 0
                                    ? filteredDriverList[selectedDriverIndex]
                                    : ''
                                }
                                options={filteredDriverList}
                              />
                            </div>
                          )}
                          {!data.isThirdPartyDriver &&
                            driverDetails.empId === 'OTHERS' && (
                              <div className="px-4">
                                <form>
                                  <div className="form-row">
                                    <div className="form-group col-md-6">
                                      <label
                                        htmlFor="f-name"
                                        className="col-form-label"
                                      >
                                        First Name
                                      </label>
                                      <input
                                        onChange={(a) =>
                                          setdriverDetails((x) => ({
                                            ...x,
                                            firstName: a.target.value,
                                          }))
                                        }
                                        label={'First Name'}
                                        name={'firstName'}
                                        type="text"
                                        className="form-control"
                                        id="f-name"
                                        value={driverDetails.firstName}
                                      />
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label
                                        htmlFor="lname"
                                        className="col-form-label"
                                      >
                                        Last Name
                                      </label>
                                      <input
                                        onChange={(a) =>
                                          setdriverDetails((x) => ({
                                            ...x,
                                            lastName: a.target.value,
                                          }))
                                        }
                                        label={'Last Name'}
                                        name={'lastName'}
                                        type="text"
                                        className="form-control"
                                        id="lname"
                                        value={driverDetails.lastName}
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        htmlFor="email"
                                        className="col-form-label"
                                      >
                                        Email
                                      </label>
                                      <input
                                        onChange={(a) =>
                                          setdriverDetails((x) => ({
                                            ...x,
                                            email: a.target.value,
                                          }))
                                        }
                                        label={'Email'}
                                        name={'email'}
                                        type="email"
                                        className="form-control"
                                        id=""
                                        value={driverDetails.email}
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        htmlFor="Company"
                                        className="col-form-label"
                                      >
                                        Company
                                      </label>
                                      <input
                                        onChange={(a) =>
                                          setdriverDetails((x) => ({
                                            ...x,
                                            company: a.target.value,
                                          }))
                                        }
                                        label={'Company'}
                                        name={'company'}
                                        type="text"
                                        className="form-control"
                                        id=""
                                        value={driverDetails.company}
                                      />
                                    </div>
                                    <div className="form-group col-md-12">
                                      <label
                                        htmlFor="Contact"
                                        className="col-form-label"
                                      >
                                        Contact Number
                                      </label>
                                      <PhoneInput
                                        name={`contactNumber`}
                                        defaultCountry="US"
                                        // displayInitialValueAsLocalNumber={true}
                                        international={false}
                                        className={'form-control'}
                                        placeholder="Contact Number"
                                        onChange={(phone) => {
                                          setdriverDetails((x) => ({
                                            ...x,
                                            contactNumber: phone || '',
                                          }))
                                        }}
                                        maxLength={14}
                                        id="Contact"
                                        value={
                                          driverDetails.contactNumber || ''
                                        }
                                      />
                                      {/* <label htmlFor="Contact" className="col-form-label">
                                Contact Number
                              </label>
                              <input
                                onChange={(a) =>
                                  setdriverDetails((x) => ({
                                    ...x,
                                    contactNumber: a.target.value,
                                  }))
                                }
                                label={'Contact Number'}
                                name={'contactNumber'}
                                type="text"
                                className="form-control"
                                id="Contact"
                                value={driverDetails.contactNumber}
                              /> */}
                                    </div>

                                    <AddressInput
                                      label="Address"
                                      name="address"
                                      setFieldValue={setFieldValue}
                                    />
                                  </div>
                                </form>
                              </div>
                            )}
                        </>
                      )}
                      {!driverDetailVisible && (
                        <div
                          className="tab-pane"
                          id="vehicle"
                          role="tabpanel"
                          aria-labelledby="pills-injury-tab"
                        >
                          <div className="py-4 d-flex justify-content-center align-items-center">
                            <span className="mr-4"> Is Company Vehicle?</span>
                            <div
                              style={{ height: '44px' }}
                              className="btn-group btn-group-toggle border btn-yesno"
                              data-toggle="buttons"
                            >
                              <label
                                className={
                                  'btn mr-2 px-3 ' +
                                  (!data.isThirdPartyVehicle ? 'active' : '')
                                }
                                onClick={() =>
                                  setdata((x) => ({
                                    ...x,
                                    isThirdPartyVehicle: false,
                                  }))
                                }
                              >
                                <input
                                  onChange={() =>
                                    setdata((x) => ({
                                      ...x,
                                      isThirdPartyVehicle: false,
                                    }))
                                  }
                                  label={'Yes'}
                                  type="radio"
                                  name="dd"
                                  id="option6"
                                  checked={!data.isThirdPartyVehicle}
                                />
                                Yes
                              </label>
                              <label
                                className={
                                  'btn px-3 ' +
                                  (data.isThirdPartyVehicle ? 'active' : '')
                                }
                                onClick={() =>
                                  setdata((x) => ({
                                    ...x,
                                    isThirdPartyVehicle: true,
                                  }))
                                }
                              >
                                <input
                                  onChange={() =>
                                    setdata((x) => ({
                                      ...x,
                                      isThirdPartyVehicle: true,
                                    }))
                                  }
                                  type="radio"
                                  name="dd"
                                  id="option5"
                                  label="No"
                                  checked={data.isThirdPartyVehicle}
                                />{' '}
                                No
                              </label>
                            </div>
                          </div>
                          {data.isThirdPartyVehicle && (
                            <div
                              className="tab-pane fade show"
                              id="Person"
                              role="tabpanel"
                              aria-labelledby="Person-tab"
                            >
                              <div className="px-5">
                                <form>
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="col-form-label"
                                    >
                                      Model
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setvehicleDetails((x) => ({
                                          ...x,
                                          model: a.target.value,
                                        }))
                                      }
                                      label={'Model'}
                                      name={'model'}
                                      type="text"
                                      className="form-control"
                                      value={vehicleDetails.model}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="col-form-label"
                                    >
                                      Make
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setvehicleDetails((x) => ({
                                          ...x,
                                          make: a.target.value,
                                        }))
                                      }
                                      label={'Make'}
                                      name={'make'}
                                      type="text"
                                      className="form-control"
                                      value={vehicleDetails.make}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="Treatment"
                                      className="col-form-label"
                                    >
                                      VIN Number
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setvehicleDetails((x) => ({
                                          ...x,
                                          vinNumber: a.target.value,
                                        }))
                                      }
                                      label={'VIN Number'}
                                      name={'vinNumber'}
                                      type="text"
                                      className="form-control"
                                      value={vehicleDetails.vinNumber}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label
                                      htmlFor="recipient-name"
                                      className="col-form-label"
                                    >
                                      License Plate
                                    </label>
                                    <input
                                      onChange={(a) =>
                                        setvehicleDetails((x) => ({
                                          ...x,
                                          licensePlate: a.target.value,
                                        }))
                                      }
                                      label={'License Plate'}
                                      name={'licensePlate'}
                                      type="text"
                                      className="form-control"
                                      value={vehicleDetails.licensePlate}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label
                                      htmlFor="recipient-name"
                                      className="col-form-label"
                                    >
                                      Notes
                                    </label>
                                    <textarea
                                      name=""
                                      id=""
                                      cols="30"
                                      rows="5"
                                      onChange={(a) =>
                                        setvehicleDetails((x) => ({
                                          ...x,
                                          notes: a.target.value,
                                        }))
                                      }
                                      className="form-control"
                                      value={vehicleDetails.notes}
                                    ></textarea>
                                  </div>
                                  <div className="form-group">
                                    <label>Vehicle Damage Details</label>
                                    <Select1
                                      isMulti
                                      name={`vehicleDamages`}
                                      onChange={(val) =>
                                        setdata((x) => ({
                                          ...x,
                                          vehicleDamages: val,
                                        }))
                                      }
                                      options={options}
                                      value={
                                        data.vehicleDamages.length > 0
                                          ? data.vehicleDamages
                                          : []
                                      }
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}

                          {filteredVehList && !data.isThirdPartyVehicle && (
                            <div className={'form-group '}>
                              <div className="form-group col-md-12">
                                <label>Select Vehicle</label>
                                <Select1
                                  placeholder="Select Vehicle"
                                  getOptionLabel={(option) =>
                                    option.vehicleId
                                      ? `${option.vehicleId} - ${option.model} ${option.manufactureYear}`
                                      : option.value
                                  }
                                  getOptionValue={(option) => option}
                                  isSearchable="true"
                                  onChange={(val) => {
                                    setSelectedVehicleIndex(
                                      filteredVehList.indexOf(val),
                                    )
                                    setvehicleDetails((x) => ({
                                      ...x,
                                      make: '',
                                      model: val.model || '',
                                      year: val.manufactureYear || '',
                                      vinNumber: val.vinNumber || '',
                                      vehId: val._id || '',
                                    }))
                                  }}
                                  value={
                                    selectedVehicleIndex >= 0
                                      ? filteredVehList[selectedVehicleIndex]
                                      : ''
                                  }
                                  options={filteredVehList}
                                />
                              </div>
                              {!data.isThirdPartyVehicle &&
                                vehicleDetails.vehId === 'OTHERS' && (
                                  <div
                                    className="tab-pane fade show"
                                    id="Person"
                                    role="tabpanel"
                                    aria-labelledby="Person-tab"
                                  >
                                    <div className="px-4">
                                      <form>
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="col-form-label"
                                          >
                                            Model
                                          </label>
                                          <input
                                            onChange={(a) =>
                                              setvehicleDetails((x) => ({
                                                ...x,
                                                model: a.target.value,
                                              }))
                                            }
                                            label={'Model'}
                                            name={'model'}
                                            type="text"
                                            className="form-control"
                                            value={vehicleDetails.model}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="col-form-label"
                                          >
                                            Make
                                          </label>
                                          <input
                                            onChange={(a) =>
                                              setvehicleDetails((x) => ({
                                                ...x,
                                                make: a.target.value,
                                              }))
                                            }
                                            label={'Make'}
                                            name={'make'}
                                            type="text"
                                            className="form-control"
                                            value={vehicleDetails.make}
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label
                                            htmlFor="Treatment"
                                            className="col-form-label"
                                          >
                                            VIN Number
                                          </label>
                                          <input
                                            onChange={(a) =>
                                              setvehicleDetails((x) => ({
                                                ...x,
                                                vinNumber: a.target.value,
                                              }))
                                            }
                                            label={'VIN Number'}
                                            name={'vinNumber'}
                                            type="text"
                                            className="form-control"
                                            value={vehicleDetails.vinNumber}
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label
                                            htmlFor="recipient-name"
                                            className="col-form-label"
                                          >
                                            License Plate
                                          </label>
                                          <input
                                            onChange={(a) =>
                                              setvehicleDetails((x) => ({
                                                ...x,
                                                licensePlate: a.target.value,
                                              }))
                                            }
                                            label={'License Plate'}
                                            name={'licensePlate'}
                                            type="text"
                                            className="form-control"
                                            value={vehicleDetails.licensePlate}
                                          />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                )}
                              {/* {vehicleDetails.vehId &&
                                typeof vehicleDetails.vehId === 'string' && ( */}
                              <div className="form-group col-md-12">
                                <label>Vehicle Damage Details</label>
                                <Select1
                                  isMulti
                                  name={`vehicleDamages`}
                                  onChange={(val) =>
                                    setdata((x) => ({
                                      ...x,
                                      vehicleDamages: val,
                                    }))
                                  }
                                  options={options}
                                  value={
                                    (data?.vehicleDamages?.length ?? 0) > 0
                                      ? data.vehicleDamages
                                      : []
                                  }
                                />
                              </div>
                              {/* )} */}
                              {data.vehicleDamages &&
                                data.vehicleDamages.find(
                                  (a) => a.value === 'Others',
                                ) && (
                                  <div className="form-group col-md-12">
                                    <label>Other Details</label>
                                    <input
                                      onChange={(a) =>
                                        setdata((x) => ({
                                          ...x,
                                          otherVehicleDamages: a.target.value,
                                        }))
                                      }
                                      label={'Other details'}
                                      name={'other'}
                                      type="text"
                                      className="form-control"
                                      value={data.otherVehicleDamages}
                                    />
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      )}
                      <div></div>
                    </div>
                  </div>
                  <div
                    className="modal-footer"
                    style={{ background: '#F4F7FE' }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      onClick={handleCancel}
                      type="button"
                      className="btn btn-secondary bg-white text-dark"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default VehicleInvolvedModal
