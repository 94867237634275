import React from 'react'

const ExtraSettingFooter = ({ onApply, onClose }) => {
  return (
    <div className="modal-footer term-footer">
      <button
        type="button"
        className="btn btn-primary add-btn"
        onClick={onApply}
      >
        Apply
      </button>
      <button
        type="button"
        className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
        onClick={onClose}
      >
        Cancel
      </button>
    </div>
  )
}

export default ExtraSettingFooter
