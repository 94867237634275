import { ErrorMessage, Field } from 'formik'
import React from 'react'
import { getAddressDetails } from '../../services/helperService'

function AddressInput({ label, name, setFieldValue = () => {} }) {

  const handleOnBlur = async (val) => {
    if (val !== '') {
      const {
        data: {
          data: { data: data },
        },
      } = await getAddressDetails(val)
      setFieldValue(`${name}.state`, data?.state_fullname)
      setFieldValue(`${name}.city`, data?.city)
      setFieldValue(`${name}.country`, 'US')
    }
  }

  return (
    <>
      <div className="form-group">
        <label htmlFor="Address1">{label}</label>
        <Field
          autoComplete="randomstring"
          name={`${name}.line1`}
          type="text"
          className="form-control"
          placeholder="Address Line 1"
        />
      </div>
      <div className="form-group">
        <Field
          autoComplete="randomstring"
          name={`${name}.line2`}
          type="text"
          className="form-control"
          placeholder="Address Line 2"
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomstring"
            name={`${name}.pinCode`}
            type="number"
            className="form-control"
            placeholder="ZIP Code"
            onBlur={({ target }) => {
              handleOnBlur(target.value)
            }}
          />
          <ErrorMessage
            className="text-danger"
            name={`${name}.pinCode`}
            component="div"
          />
        </div>
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomstring"
            name={`${name}.city`}
            type="text"
            className="form-control"
            placeholder="City"
          />
        </div>
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomstring"
            name={`${name}.state`}
            type="text"
            className="form-control"
            placeholder="State"
          />
        </div>
        <div className="form-group col-md-6">
          <Field
            autoComplete="randomstring"
            name={`${name}.country`}
            type="text"
            className="form-control"
            placeholder="Country"
          />
        </div>
      </div>
    </>
  )
}

export default AddressInput
