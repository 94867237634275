/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import 'rc-time-picker/assets/index.css'

import IncidentDetailsView from '../../pages/incident/AddNewIncidentViews/IncidentDetailsView'
import SupervisorDetailsView from '../../pages/incident/AddNewIncidentViews/SupervisorDetailsView'
import UserDetailsView from '../../pages/incident/AddNewIncidentViews/UserDetailsView'
import IncidentNarrativeView from '../../pages/incident/incidentNarrativeView'
import { getEmployees } from '../../services/employeeService'
import Select from '../form/Select'

function IncidentDetailsTab({
  isNew,
  title,
  values,
  setFieldValue,
  nextTab,
  context,
  handleSubmit,
  narrative,
  setNarrative,
  setFieldTouched,
  setIsVehicular = () => { },
}) {
  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const vehicularAccident = values.vehicularAccident
  const [activeStep, setActiveStep] = useState(1)
  const [employeeOptions, setEmployeeOptions] = useState([])

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])



  useEffect(() => {
    (async () => {
      setEmployeeOptions([{
        key: "OTHER",
        value: "Other"
      }])
      try {
        const result = await getEmployees({
          params: {
            clientId: values.clientId ?? selectedClientId
          }
        })
        setEmployeeOptions([...result.data.data.map(e => ({ key: e._id, value: `${e.firstName} ${e.lastName}` })), {
          key: "OTHER",
          value: "Other"
        }])
        if (isNew && title !== 'Convert to Incident') { // Client dropdown appear only when  creating a new incident
          // Reset userDetails employee selection dropdown on client selection as userdetails is based on client selection
          setFieldValue('userDetails.employeeId', '')
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [selectedClientId, values.clientId])

  const changeActiveStep = (step) => {
    setActiveStep(step)
  }

  const renderUI = () => {
    switch (activeStep) {
      case 1:
        return <UserDetailsView
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      case 2:
        return <IncidentDetailsView
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      case 3:
        return <SupervisorDetailsView
          isClientExist={isClientExist}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
          setFieldTouched={setFieldValue}
        />
      case 4:
        return <IncidentNarrativeView
          context={context}
          handleSubmit={handleSubmit}
          setNarrative={setNarrative}
          values={values}
          setFieldValue={setFieldValue}
          onNavigate={changeActiveStep}
        />
      default:
        return <>Nothing to show here.</>
    }
  }

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <div
      className="col-md-7 col-h"
      style={{ height: '100vh', overflow: 'scroll' }}
    >
      <div className="modal-body p-4 mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="modal-title text-primary">{title}</h2>
          <p className="mb-0 text-success ft-20">{activeStep}/4</p>
        </div>
        <div
          className="mt-4 mb-3 "
          style={activeStep !== 1 ? { pointerEvents: 'none' } : null}
        >
          <div
            className="btn-group btn-group-toggle border btn-tgl"
            data-toggle="buttons"
          >
            <label
              className={
                'btn mr-2 px-4' +
                (vehicularAccident ? ' active' : '') + (activeStep !== 1 && vehicularAccident ? "  btn-primary-disabled" : "")
              }
              onClick={() => {
                if (activeStep !== 1) return
                setFieldValue('vehicularAccident', true)
                setIsVehicular(true)
              }}
            >
              <input type="radio" name="options" id="option1" /> Vehicular
            </label>
            <label
              className={
                'btn mr-2 px-4' +
                (!vehicularAccident ? ' active' : '') + (activeStep !== 1 && !vehicularAccident ? " btn-primary-disabled" : "")
              }
              onClick={() => {
                if (activeStep !== 1) return
                setFieldValue('vehicularAccident', false)
                setIsVehicular(false)
              }}
            >
              <input type="radio" name="options" id="option2" /> Non-Vehicular
            </label>
          </div>
        </div>
        {/* {narrative || activeStep === 2 ? (
          <div className={'border-bottom h6 py-3 mb-4 text-primary'}>
            <b>Please add detailed narrative about the whole incident</b>
          </div>
        ) : (
          <div className="border-bottom h6 py-2 mb-3">Incidents Details</div>
        )} */}
        <div className='form-row'>
          {!isClientExist && activeStep === 1 && (
            <div className="col-md-12">
              <Select
                disabled={activeStep !== 1}
                label="Select Client"
                withAsterisk
                name="clientId"
                defaultLabel="Select Client"
                options={getClientDropdownOpt()}
                value={values?.clientId || ''}
              />
            </div>
          )}
        </div>
        {
          renderUI()
        }
      </div>
    </div>
  )
}

export default IncidentDetailsTab
