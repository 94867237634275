import React from 'react'
// import ModuleAdditionalSettingsModal from './ModuleAdditionalSettingsModal'
import RoleDetails from './RoleDetails'
import RolePermissions from './RolePermissions'
import { RolesProvider } from './RolesContext'
import { userRolesForm } from './useRolesForm'

function EditOrCreateRoleNew({ editRoleData, handleBackButtonClick }) {
  // const [modalData, setModalData] = useState({ open: false, data: null })
  const formik = userRolesForm(editRoleData)

  // const handleSubmit = async (values) => {
  //   console.log('submit===>>', values)
  // }

  return (
    <RolesProvider value={formik}>
      <div className="position-rel" style={{ paddingLeft: '13rem' }}>
        <div className="sticki-top">
          <div className="pl-6 pt-4 user-mang">
            <div className="container-fluid">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn text-primary pl-0"
                  onClick={handleBackButtonClick}
                >
                  <i
                    className="fas fa-chevron-left ft-13"
                    aria-hidden="true"
                  ></i>{' '}
                  Back
                </button>
              </div>
              <nav className="mt-4">
                <div
                  className="nav nav-tabs nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  <div
                    className=" nav-link text-left hvr-pulse1 mb-2 inherit p-0 m-0"
                    style={{ padding: '0px !important' }}
                  >
                    <h2 className="text-dark">
                      {editRoleData ? 'Edit a Role' : 'Add a Role'}
                    </h2>
                    <p className="mb-0">
                      Define the permissions given to the role
                    </p>
                  </div>
                  <a
                    href="#"
                    className="btn btn-success  import-btn pl-3 pr-3 ml-3 mr-3"
                    style={{ height: '54px' }}
                    // onClick={() => setData({ ...initialRoleData })}
                  >
                    <span className="pl-1">Reset</span>
                  </a>
                  <a
                    className="btn btn-warning role-primary"
                    onClick={async () => {
                      await formik.submitForm()
                      handleBackButtonClick()
                    }}
                    // type="submit"
                    style={{ height: '54px' }}
                  >
                    <span className="pl-2"> Save</span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <RoleDetails />
        <RolePermissions />

        {/* {modalData.open && (
          <ModuleAdditionalSettingsModal
            isOpen={modalData.open}
            // handleImportExportUpdated={handleImportExportUpdated}
            // handleSaveCancel={handleCommentOrAttachmentModalFooterButtonClicked}
            // commentAttachmentFullValues={commentAttachmentFullValues}
            data={modalData.data}
            // roleData={data}
            // handleCommentOrAttachmentUpdated={handleCommentOrAttachmentUpdated}
            onClose={() => setModalData({ open: false, data: null })}
          />
        )} */}
      </div>
    </RolesProvider>
  )
}

export default EditOrCreateRoleNew
