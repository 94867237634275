import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../components/common/Table'
import { toast } from 'react-toastify'
import { getPagedData } from '../../services/helperService'
import {
  deleteBlukThirdParty,
  deleteThirdParty,
  getThirdParties,
} from '../../services/thirdPartyService'
import ActionWidget from '../../components/common/ActionWidget'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../actions'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import ViewNotes from '../../components/common/ViewNotes'
import { getAttachmentList } from '../../services/attachmentService'
import Loader from '../../components/common/Loader'

let thirdParties = []
let itemsCount = 0

function ThirdPartyList(props) {
  const [isLoading , setIsLoading] = useState(true);
  const columns = [
    {
      path: 'thirdPartyId',
      label: ' Third Party Id ',
      minWidth: '240px',
      content: (tp) => (
        <>
          <a>{tp.thirdPartyId}</a>
          <ActionWidget
            module="THIRD-PARTY"
            isViewHide={true}
            onEdit={() => props.handleEdit(tp)}
            onView={() => props.handleView(tp)}
            onDelete={() => {
              setDelThirdParty(tp)
              setDeleteOne(true)
            }}
          />
        </>
      ),
    },
    { path: 'firstName', label: ' First Name ', type: 'string' },
    { path: 'lastName', label: ' Last Name ', type: 'string' },
    { path: 'organisation', label: ' Organization ' },
    { path: 'contactNumber', label: ' Contact Number ', type: 'phone' },
    { path: 'email', label: ' Email ', type: 'email' },
    { path: 'designation', label: ' Designation ' },
    { path: 'typeOfEnquiry', label: ' Type of Enquiry ', type: 'string' },
    {
      path: 'thirdPartyNotes',
      label: ' Notes ',
      content: (thirdParty) => {
        const { thirdPartyNotes = '' } = thirdParty
        return thirdPartyNotes.length ? (
          <a
            className="text-decoration-none attachmentview attachfiles"
            onClick={() => {
              setNotesModal({ state: true, info: thirdPartyNotes })
            }}
          >
            {`View Notes`}
          </a>
        ) : (
          'N/A'
        )
      },
    },
    {
      path: 'attachments',
      label: ' Attachments ',
      content: (thirdParty) => {
        const { attachments } = thirdParty
        let attachmentsData = []
        if (attachments.length) {
          getAttachmentList('third-party', attachments).then((response) => {
            attachmentsData = response.data.data
          })
        }
        return attachments.length ? (
          <a
            className="text-decoration-none attachmentview attachfiles"
            onClick={() =>
              attachmentsData.forEach((attachment) => {
                window.open(attachment.url, '_blank')
              })
            }
          >
            {`View ${attachments.length} Files`}
          </a>
        ) : (
          'N/A'
        )
      },
    },
  ]
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [checkedRecords, setCheckedRecords] = useState([])
  const dispatch = useDispatch()
  const mountedRef = useRef(true)
  const [delThirdParty, setDelThirdParty] = useState({})
  const [deleteOne, setDeleteOne] = useState(false)
  const [notesModal, setNotesModal] = useState({ state: false, info: '' })

  const { selectedClientId } = useSelector((state) => state)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  useEffect(() => {
    mountedRef.current = true
    setIsLoading(true)
    fetchList()

    return () => {
      mountedRef.current = false
    }
  }, [props.context, selectedClientId])

  useEffect(() => {
    props.setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {
    if (props.openBulkDel) {
      setDelThirdParty({
        delThirdParty: { firstName: 'mvrf', lastName: 'mvrL' },
      })
    }
  }, [props.openBulkDel])

  const fetchList = async () => {
    const { data } = await getThirdParties()
    if (!mountedRef.current) return null
    if (sideNavDataContext !== 'searchView') {
      dispatch(
        handleSideNavDataUpdated({ data: data.data[0], module: 'third-party' }),
      )
      dispatch(handleTabIdUpdated('thirdParty'))
    }
    let thirdPartyData = data.data.map((thirdParty)=> {
      thirdParty.fullName = `${thirdParty.firstName} ${thirdParty.lastName}`
      return thirdParty
    })
    setData(thirdPartyData)
    
    const organisationList = {}

    data.data.forEach(({ organisation }) => {
      // if (typeOfEnquiry !== '') {
      //   typeOfEnquiryList[typeOfEnquiry] = {
      //     key: typeOfEnquiry,
      //     value: typeOfEnquiry,
      //   }
      // }
      if (organisation !== '') {
        organisationList[organisation] = {
          key: organisation,
          value: organisation,
        }
      }
    })

    props.setSelectFilter(Object.values(organisationList || {}))
    setIsLoading(false)
  }

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      props.searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    thirdParties = result
    return result.length > 0
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handlePageChange = (page) => {
    setCurrPage(page)
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (thirdParties.length !== checkedRecords.length) {
        thirdParties.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukThirdParty(params)
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        props.setShowDeleteButton(false)
        props.closeBulkDel()
      } else {
        const { data } = await deleteThirdParty(
          delThirdParty._id,
          delThirdParty,
        )
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setDeleteOne(false)
      }
    } catch (error) {
      console.log('error' , error)
    }
    setDelThirdParty({})
    await fetchList()
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData() || ''}
      <Table
        columns={columns}
        data={thirdParties.filter((tData) => {
          const { typeOfEnquiry, organisation } = tData
          const {
            typeOfEnquiry: selectedTypeOfEnquiry = '',
            organisation: selectedOrganisation = '',
          } = props.selectFilters

          let isTypeOfEnquiry = false,
            isOrganisation = false
          if (
            selectedTypeOfEnquiry.length &&
            selectedTypeOfEnquiry === typeOfEnquiry
          ) {
            isTypeOfEnquiry = true
          }

          if (
            selectedOrganisation.length &&
            selectedOrganisation.includes(organisation)
          ) {
            isOrganisation = true
          }
          return (
            !(!!selectedTypeOfEnquiry.length ^ isTypeOfEnquiry) &&
            !(!!selectedOrganisation.length ^ isOrganisation)
          )
        })}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked}
      />
      <ViewNotes
        heading="Third Party"
        info={notesModal?.info || ''}
        isNotesModal={notesModal.state}
        onClose={() => setNotesModal({ state: false, info: '' })}
      />
      <ConfirmationModal
        name={
          checkedRecords.length > 0
            ? `Third parties`
            : (delThirdParty?.firstName || '') +
              ' ' +
              (delThirdParty?.lastName || '')
        }
        isOpen={
          Object.keys(delThirdParty || {}).length > 0 &&
          (checkedRecords.length > 0 || deleteOne)
        }
        // isOpen={checkedRecords.length > 0 || deleteOne}
        onClose={() => {          
          props.setShowDeleteButton(false)
          props.closeBulkDel()
          setDelThirdParty({})
          setDeleteOne(false)
          setCheckedRecords([])
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default ThirdPartyList
