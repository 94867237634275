import React, { useEffect, useState, useRef } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import classNames from 'classnames'

// import Select from '../../components/form/Select'
import TextInput from '../../components/form/TextInput'
import {
  saveThirdParty,
  updateThirdParty,
} from '../../services/thirdPartyService'
// import AddAttachment from '../../components/addAttachment'
import AreYouSureModal from '../../components/common/AreYouSureModal'
import TextArea from '../../components/form/TextArea'
import AttachmentAddView from '../../components/AttachmentAddView'
import { generateUID } from '../../utils/generateUID'
import Select from '../../components/form/Select'
import { THIRD_PARTY_VALIDATION_SCHEMA } from '../../utils/validation'
// import ValueProvider from '../../components/form/ValueProvider'

const enquiryOptions = [
  { value: 'Active', key: 'Active' },
  { value: 'Inactive', key: 'Inactive' },
]

function ThirdPartyForm({ data, title, onClose }) {
  const ref = useRef()
  const isNew = !data
  const [values, setValues] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  // const [loaded, setLoaded] = useState(false)
  // const [openAttachmentModal, setOpenAttachmentModal] = useState(false)

  const newEmployee = () => ({
    firstName: '',
    lastName: '',
    organisation: '',
    contactNumber: '',
    email: '',
    designation: '',
    // typeOfEnquiry: '',
    attachments: [],
    thirdPartyNotes: '',
    thirdPartyId: generateUID('third-party'),
  })

  const closeModal = () => setOpenModal(false)

  // useEffect(() => {
  //   if (data) {
  //     setIsNew(false)
  //     setValues({
  //       ...data,
  //       contactNumber: data.contactNumber ? data.contactNumber : '',
  //     })
  //     console.log('data', data)
  //     setLoaded(true)
  //   } else {
  //     setIsNew(true)
  //     setValues(newEmployee())
  //     // setLoaded(true)
  //   }
  // }, [isNew])

  const handleSubmit = async (values) => {
    setValues({
      ...values,
      contactNumber: values['contactNumber'].startsWith('+')
        ? values['contactNumber']
        : values['contactNumber'],
    })
    setOpenModal(true)
  }

  // const handleAddAttachmentToggle = () => {
  //   setOpenAttachmentModal(!openAttachmentModal)
  // }

  // const onAttachmentUpload = (file) => {
  //   handleAddAttachmentToggle()
  //   const updatedValues = ref.current?.values || {}
  //   updatedValues['attachments'].push(file[0])
  //   setValues(updatedValues)
  // }

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      if (isNew) {
        await saveThirdParty(values)
        toast.success('Third Party Added!')
      } else {
        await updateThirdParty(values._id, values)
        toast.success('Third Party Updated!')
      }
      history.replace('/thirdParty')
    } catch (err) {
      toast.error(err)
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    setValues(ref.current.values)
  }, [])

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      validationSchema={THIRD_PARTY_VALIDATION_SCHEMA}
      initialValues={data ? data : newEmployee()}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row m-0" style={{ height: '100%' }}>
            {values && (
              <div className="col-7 p-4" style={{ overflow: 'hidden' }}>
                <h2
                  className="modal-title text-primary mb-4"
                  id="exampleModalLabel"
                >
                  {title}
                </h2>
                <div className="border-bottom h6 py-2 mb-4">
                  Third Party Details
                </div>
                <div className="px-4" style={{ marginLeft: '-24px' }}>
                  <div className="row m-0">
                    <div className="col-12 p-0">
                      <div className="tab-content pb-0" id="v-pills-tabContent">
                        <div
                          className={classNames('tab-pane fade', {
                            'show active': true,
                          })}
                          id="v-pills-1"
                          role="tabpanel"
                          aria-labelledby="v-1"
                        >
                          <div className="row m-0 pt-3">
                            <div className="col-12 p-0">
                              <div className="form-row">
                                <div className="col-md-6">
                                  <TextInput
                                    label="First Name"
                                    name="firstName"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Last Name"
                                    name="lastName"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput
                                    label="Organization"
                                    name="organisation"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <TextInput label="Email" name="email" />
                                </div>
                                <div className="form-group col-md-6">
                                  <TextInput
                                    changeOnUndefined={true}
                                    isPhone
                                    label="Contact Number"
                                    name="contactNumber"
                                    maxLength={14}
                                    onPhoneChange={(phone) =>
                                      setFieldValue('contactNumber', phone ?? "")
                                    }
                                    value={values?.contactNumber || ''}
                                  />
                                </div>
                                <div className="form-group col-md-6">
                                  <TextInput
                                    label="Designation"
                                    name="designation"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    label="Type of Enquiry"
                                    name="typeOfEnquiry"
                                    defaultLabel="Select Enquiry"
                                    options={enquiryOptions}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <TextArea
                                    label="Notes"
                                    name="thirdPartyNotes"
                                  />
                                </div>
                              </div>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary text-white"
                              >
                                Save &amp; Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {values && (
              <div
                className="col-md-5 col-h border-left"
                style={{ background: '#F4F7FE' }}
              >
                <div className="modal-body p-4 mdl-scroll">
                  <div className="form-group col-md-12">
                    {
                      <AttachmentAddView
                        formik={{
                          setFieldValue: setFieldValue,
                          values,
                        }}
                        module={'third-party'}
                        uid={values.thirdPartyId}
                      />
                    }
                  </div>
                </div>
              </div>
            )}
            <AreYouSureModal
              openModal={openModal}
              closeModal={closeModal}
              handleSubmit={handleSubmitModal}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ThirdPartyForm
