import http from './httpService';
import apiUrl from '../configs/api';

const apiEndpoint = `${apiUrl}/utilities`;
const trashEndpoint = `${apiUrl}/trash`;

export function getTrashList(payload) {
  return http.get(`${trashEndpoint}/list`,{params : payload});
}

export function deleteForever(id) {
  return http.post(`${trashEndpoint}/delete/${id}`);
}

export function restoreOthers(id) {
  return http.post(`${trashEndpoint}/restore/${id}`)
}

export function exportThirdParties() {
  return http.get(`${apiEndpoint}/export-to-csv`, { responseType: 'blob' })
}

export function getThirdParty(id) {
  return http.get(`${apiEndpoint}/details/${id}`)
}

export function updateThirdParty(id, mvr) {
  return http.put(`${apiEndpoint}/update/${id}`, mvr)
}

export function deleteThirdParty(id, mvr) {
  return http.put(`${apiEndpoint}/delete/${id}`, mvr)
}

export function saveThirdParty(thirdParty) {
  const date = new Date()
  const thirdPartyId = `TP-${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...thirdParty, thirdPartyId })
}
