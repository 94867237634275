import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import Select1 from 'react-select'
import { toast } from 'react-toastify'
import TextInput from '../../components/form/TextInput'
import ClaimNotes from './claimNotes'

import moment from 'moment'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import { useDispatch } from 'react-redux'

import { saveIncidentFormData, savePolicyInfo } from '../../actions'
import AttachmentAddView from '../../components/AttachmentAddView'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import { getClaim, updateClaim } from '../../services/claimService'
import {
  addtoExistingClaim,
  convertToClaim,
  getIncident,
} from '../../services/incidentService'
import { getPolicies } from '../../services/policyService'
import { generateUID } from '../../utils/generateUID'
import { CLAIM_VALIDATION_SCHEMA } from '../../utils/validation'
import {
  allClaimStatusTypes,
  allCoverageDescription,
  allPolicyTypes,
  allPriorityStatusTypes,
  outcomeOptions,
} from '../incident/utils'
import ClaimFinancialCard from './claimFinancialCard'
import ClaimFinancialModal from './claimFinancialModal'
import { convertDateToStringClaimForm } from './utils'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'
import { useMemo } from 'react'
import CustomSelect from '../../components/form/CustomSelect'

function ConvertToClaim({
  match,
  location,
  onClose,
  data,
  title,
  context,
  activeStep,
  setActiveStep,
  addClaimData,
  directClaim = false,
}) {
  const ref = useRef()
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [policies, setPolicies] = useState([])
  const [clientId, setClientId] = useState('')
  const [claimId, setClaimId] = useState('')
  const [tab, setTab] = useState('')
  const [openModal, setOpenModal] = useState({ isOpen: false, data: null })
  const systemGeneratedClaimId = generateUID('claim')
  const [incidentData, setIncidentData] = useState(null)

  const newClaim = () => ({
    attachments: [],
    incidentDetails: {
      incidentId: '',
      id: '',
    },
    claimants: [
      {
        claimantInfo: '',
        dateOfClaim: '',
        timeOfClaim: '00:00:00 AM',
        coverageType: '',
        status: '',
        coverageDescription: '',
        endDate: addClaimData?.values?.endDate || '',
        closeDate: addClaimData?.values?.closeDate || '',
        startDate: addClaimData?.values?.startDate || '',
        policyDetails: {},
        deductible: '',
        openSince: '',
        priority: addClaimData?.values?.priority || '',
        checkList: {
          claimantContacted: false,
          clientContacted: false,
          allDocumentsFiled: false,
          gensuiteFiled: false,
          evidenceFiled: false,
        },
        financials: [],
        notes: {
          claimantContactNumber: '',
          claimDeclineReason: '',
          litigationDetails: '',
          litigationAttorney: {
            name: '',
            contactNumber: '',
            email: '',
          },
          settlementOffered: '',
          claimAdjustorRemarks: '',
        },
        description: {
          coverageType: '',
          discrepancy: '',
          severity: '',
          outcome: '',
          claimType: '',
        },
        nextAction: '',
      },
    ],
  })

  const handleSubmit = async (values) => {
    try {
      const dateOfClaim =
        moment(values.claimants[0]?.dateOfClaim).format(
          NO_TIMEZONE_DATE_FORMAT,
        ) || ''
      const { timeOfClaim } = values.claimants[0]

      let filteredValues = convertDateToStringClaimForm(values)
      let incidents = [filteredValues]

      if (data && Object.keys(data).length > 0) {
        let claim = {
          ...data,
          dateOfClaim,
          timeOfClaim,
          attachments: [...filteredValues.attachments],
        }
        claim.incidents[0].claimants = filteredValues.claimants
        claim.incidents[0].claimNumber = filteredValues.claimNumber
        await updateClaim(data._id, claim)
        toast.success('Claim Updated!')

        onClose()
      } else if (claimId) {
        await addtoExistingClaim(claimId, {
          ...filteredValues,
          dateOfClaim,
          timeOfClaim,
        })
        toast.success('Incident Converted to existing Claim!')
        onClose()
      } else {
        await convertToClaim({
          incidents,
          claimId: systemGeneratedClaimId,
          dateOfClaim,
          timeOfClaim,
          directClaim,
          claimNumber: filteredValues.claimNumber,
          clientId: addClaimData?.values?.clientId || clientId,
        })
        toast.success('Incident Converted to Claim!')
        onClose()
      }
      ref?.current?.resetForm()
      setActiveStep(1)
      await dispatch(saveIncidentFormData(null))
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.msg)
      } else {
        toast.error(err)
      }
    }
  }

  useEffect(() => {
    const qParams = location.search
    if (qParams && qParams.claimId) {
      setClaimId(qParams.claimId)
    }
    match && location
    const incidentId = match.params.id
    incidentId && fetchIncident(incidentId)
    if (qParams && qParams.claimId && incidentId) {
      fetchClaim(qParams.claimId)
    }
  }, [])

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setClaimId(data.claimId)
      setValues({ ...data?.incidents[0], attachments: data.attachments })
    }
  }, [data])

  const claimantData = useMemo(() => {
    const isVehicularAccident = incidentData
      ? incidentData?.vehicularAccident
      : values?.incidentDetails?.vehicularAccident
    const partiesInvolvedData = incidentData
      ? incidentData?.partiesInvolved
      : values && Object.keys(values).length > 0
      ? values?.incidentDetails?.partiesInvolved
      : []
    const filteredClaimants = partiesInvolvedData
      ?.filter(
        (party) =>
          party?.personDetails?.firstName !== '' ||
          party?.personDetails?.lastName !== '',
      )
      .map((party) => ({
        claimantId: party._id,
        basicInfo: party.personDetails,
        isCompanyEmployee: party.isCompanyEmployee,
      }))
      .filter((claimant) =>
        isVehicularAccident ? !claimant.isCompanyEmployee : true,
      )
    return filteredClaimants
  }, [values, incidentData])

  const fetchClaim = async (claimId) => {
    const {
      data: { data: existingclaimData },
    } = await getClaim(claimId)
    setValues({ ...existingclaimData.incidents[0] })
  }

  const fetchIncident = async (id) => {
    const { data: incident } = await getIncident(id)
    let claimantsData = newClaim().claimants.map((claimant) => {
      claimant.coverageDescription = incident?.data?.coverageDescription
      claimant.description.claimType = incident?.data?.claimType
      return claimant
    })
    setValues({
      ...values,
      incidentDetails: incident.data._id,
      claimants: claimantsData,
    })
    setClientId(incident?.data?.clientId)
    setIncidentData(incident.data)
  }

  useEffect(() => {
    clientId ? fetchPolicies() : null
  }, [clientId])

  const fetchPolicies = async () => {
    const { data: policies } = await getPolicies()
    if (policies.data && Array.isArray(policies.data)) {
      dispatch(savePolicyInfo(policies.data))
      setPolicies(
        policies.data
          .filter((el) => el.clientId === clientId)
          .map((p) => ({
            policyId: p._id,
            policyName: p.policyId,
            policyType: p.policyType,
          })),
      )
    }
  }

  const handleNextButtonClicked = () => {
    setActiveStep && setActiveStep(activeStep + 1)
    setTab('notes')
  }

  const handleBackButtonClicked = () => {
    if (activeStep == 1) {
      onClose()
      return
    }
    setActiveStep && setActiveStep(activeStep - 1)
    setTab('')
  }

  const getClaimants = () => {
    if (
      (context === 'incident' || context === 'claim') &&
      Array.isArray(values?.claimants)
    ) {
      return values.claimants.map((e) => ({
        ...(e ?? {}),
        nextAction: e?.nextAction || '',
      }))
    }
    return { ...newClaim(), ...(values ?? {}) }?.claimants
  }

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      initialValues={{
        ...newClaim(),
        ...values,
        claimants: getClaimants(),
      }}
      validationSchema={CLAIM_VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <>
          <div className="col-md-7 col-h">
            <div
              className="modal-body p-4 mb-4 px-3"
              style={{ height: '100vh', overflow: 'scroll' }}
            >
              {title ? (
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="modal-title text-primary">{title}</h2>
                  {context === 'claim' && (
                    <p className="mb-0 text-success ft-20">{activeStep}/4</p>
                  )}
                </div>
              ) : (
                <h2 className="modal-title mb-3">
                  {context === 'incident' ? 'Convert To Claim' : 'Edit Claim'}
                </h2>
              )}
              <div className="border-bottom h6 py-3 mb-4 text-primary d-flex justify-content-between">
                <span className="text-primery font-weight-bold">
                  {activeStep % 2 === 1 ? 'Claim Information' : 'Claim Notes'}
                </span>
                {context !== 'claim' && (
                  <span className="text-black-50">
                    {values.incidentDetails?.incidentId}
                  </span>
                )}
              </div>
              {tab !== 'notes' ? (
                <Form className="indent-form">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="Claimant">Claimant</label>
                      <CustomSelect
                        onChangeHandler={(val) =>
                          setFieldValue('claimants.0.claimantInfo', val)
                        }
                        options={claimantData}
                        values={values}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="claimants.0.claimantInfo"
                        component="div"
                      />
                    </div>
                    {context === 'incident' || context === 'claim' ? (
                      <div className="col-md-6">
                        <TextInput label="Claim Number" name="claimNumber" />
                      </div>
                    ) : null}
                    <div className="col-md-6">
                      <TextInput
                        label="Type of Claim"
                        name="claimants.0.description.claimType"
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        withAsterisk
                        label="Coverage Type"
                        name="claimants.0.coverageType"
                        defaultLabel="Select Type"
                        options={allPolicyTypes}
                      />
                    </div>

                    <div className="col-md-6">
                      <TextInput
                        isPhone
                        withAsterisk
                        changeOnUndefined={true}
                        label="Claimant Contact"
                        name="claimants.0.notes.claimantContactNumber"
                        maxLength={14}
                        onPhoneChange={(phone) =>
                          phone?.length > 2 &&
                          setFieldValue(
                            'claimants.0.notes.claimantContactNumber',
                            phone ?? '',
                          )
                        }
                        value={
                          values?.claimants[0]?.notes?.claimantContactNumber ||
                          ''
                        }
                      />
                    </div>
                    <div
                      className="col-md-6"
                      style={
                        context === 'claim' ? { marginBottom: '20px' } : null
                      }
                    >
                      <label htmlFor="Policy">Policy Details </label>
                      <Select1
                        placeholder="Select Policy"
                        getOptionLabel={(option) => option?.policyName || ''}
                        getOptionValue={(option) => option}
                        name="claimants.0.policyDetails"
                        value={
                          policies.filter(
                            (p) =>
                              p.policyType ===
                              values.claimants[0].coverageType.toUpperCase(),
                          ).length
                            ? values?.claimants?.[0].policyDetails
                            : null
                        }
                        isClearable="true"
                        isSearchable="true"
                        onChange={(val) =>
                          setFieldValue('claimants.0.policyDetails', val)
                        }
                        options={policies.filter(
                          (p) =>
                            p.policyType ===
                            values.claimants[0].coverageType.toUpperCase(),
                        )}
                        // options={policies}
                      />
                    </div>

                    <div className="col-md-6">
                      <TextInput
                        name={'claimants.0.adjusterName'}
                        type="text"
                        label={'Adjuster Name'}
                        className="form-control"
                        placeholder="Adjuster Name"
                      />
                    </div>
                    <div className="col-md-6">
                      {/* <TextInput
                        isPhone
                        changeOnUndefined={true}
                        label={'Adjuster Contact Details'}
                        name={'claimants.0.adjusterContactDetails'}
                        maxLength={14}
                        placeholder={'Adjuster Contact Details'}
                        onPhoneChange={(phone) =>
                          phone?.length > 2 &&
                          setFieldValue(
                            'claimants.0.adjusterContactDetails',
                            phone ?? '',
                          )
                        }
                        value={
                          values?.claimants[0]?.adjusterContactDetails || ''
                        }
                      /> */}
                      <TextInput
                        name={'claimants.0.adjusterContactDetails'}
                        label={'Adjuster Contact Details'}
                        className="form-control"
                        placeholder={'Adjuster Contact Details'}
                      />
                    </div>

                    <div className="col-md-6">
                      <TextInput
                        label="Claim Handled By"
                        className="form-control"
                        name="claimants.0.claimHandledBy"
                        placeholder="Claim Handled By"
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        // showOthers={true}
                        className="form-control"
                        label="Coverage Description"
                        name="claimants.0.coverageDescription"
                        defaultLabel="Select Description"
                        options={allCoverageDescription}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Severity</label>
                    <div>
                      <div className="form-check form-check-inline mr-4 ">
                        <input
                          className="form-check-TextInput"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio-1"
                          value="Low"
                          checked={
                            values?.claimants?.[0]?.description?.severity ===
                            'Low'
                          }
                          onChange={() =>
                            setFieldValue(
                              'claimants.0.description.severity',
                              'Low',
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio-1"
                          style={{ paddingLeft: '5px' }}
                        >
                          Low
                        </label>
                      </div>
                      <div className="form-check form-check-inline mr-4">
                        <input
                          className="form-check-TextInput"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio-2"
                          value="Medium"
                          checked={
                            values?.claimants?.[0]?.description?.severity ===
                            'Medium'
                          }
                          onChange={() =>
                            setFieldValue(
                              'claimants.0.description.severity',
                              'Medium',
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio-2"
                          style={{ paddingLeft: '5px' }}
                        >
                          Medium
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-TextInput"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio-3"
                          value="High"
                          checked={
                            values?.claimants?.[0]?.description?.severity ===
                            'High'
                          }
                          onChange={() =>
                            setFieldValue(
                              'claimants.0.description.severity',
                              'High',
                            )
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio-3"
                          style={{ paddingLeft: '5px' }}
                        >
                          High
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="Discrepancy" className="col-form-label">
                      Discrepancy
                    </label>
                    <textarea
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.description.discrepancy',
                          a.target.value,
                        )
                      }
                      className="form-control"
                      id="Discrepancy"
                      placeholder={'Discrepancy'}
                      value={
                        values?.claimants?.[0]?.description?.discrepancy || ''
                      }
                    />
                  </div>

                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: '65px' }}
                  >
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={handleNextButtonClicked}
                    >
                      Next
                    </button>
                  </div>
                </Form>
              ) : (
                <ClaimNotes
                  litigationStatus={
                    values?.claimants?.[0]?.notes?.litigationStatus || ''
                  }
                  setTab={handleBackButtonClicked}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
            </div>
          </div>
          <div
            className="col-md-5 col-h border-left"
            style={{ background: '#F4F7FE', height: '100vh' }}
          >
            <div className="modal-body p-4 mdl-scroll">
              <div className="row">
                <div className="col-md-6">
                  <DatePickerField
                    label="Start Date"
                    name="claimants.0.startDate"
                  />
                </div>
                <div className="col-md-6 posi-rel">
                  <DatePickerField
                    label="Due Date"
                    name="claimants.0.endDate"
                  />
                </div>
                <div className="col-md-6">
                  <DatePickerField
                    label="Claim Close Date"
                    name="claimants.0.closeDate"
                  />
                </div>
                <div className="w-100 border-top mx-2 py-2"></div>
                <div className="col-md-6">
                  <Select
                    label="Priority"
                    name="claimants.0.priority"
                    defaultLabel="Select Priority"
                    options={allPriorityStatusTypes}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    withAsterisk
                    label="Claim Status"
                    name="claimants.0.status"
                    defaultLabel="Select Status"
                    options={allClaimStatusTypes}
                  />
                </div>
                <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                <div className="col-md-6">
                  <DatePickerField
                    withAsterisk
                    label="Claim File Date"
                    name="claimants.0.dateOfClaim"
                  />
                </div>
                <div className="form-group col-md-6">
                  <div>
                    <label>Time of Claim</label>
                  </div>
                  <TimePicker
                    use12Hours
                    className="antDOverride"
                    name="claimants.0.timeOfClaim"
                    onChange={(value) => {
                      setFieldValue(
                        'claimants.0.timeOfClaim',
                        value.format('HH:mm:ss A'),
                      )
                    }}
                    value={moment(
                      values.claimants[0].timeOfClaim || '00:00:00',
                      'HH:mm:ss A',
                    )}
                    defaultValue={moment('00:00:00', 'HH:mm:ss A')}
                  />
                </div>

                <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label>Next Action</label>
                    <input
                      data-testid="next-action"
                      className="form-control"
                      placeholder="Next Action"
                      name="claimants.0.nextAction"
                      type="text"
                      value={values.claimants[0].nextAction || ''}
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.nextAction',
                          a.target.value,
                        )
                      }
                    />
                  </div>
                </div> */}
                <div className="form-group col-md-12">
                  <label htmlFor="next-action" className="col-form-label">
                    Next Action <span className="text-danger"> *</span>
                  </label>
                  <textarea
                    data-testid="next-action"
                    className="form-control col-md-12"
                    id="next-action"
                    placeholder={'Next Action'}
                    name="claimants.0.nextAction"
                    value={values.claimants[0].nextAction || ''}
                    onChange={(a) =>
                      setFieldValue('claimants.0.nextAction', a.target.value)
                    }
                  ></textarea>
                  <ErrorMessage
                    data-testid="text-area-error-claimants.0.nextAction"
                    className="text-danger"
                    name="claimants.0.nextAction"
                    component="div"
                  />
                </div>
                <div className="form-group col-md-12">
                  <Select
                    label="Outcome"
                    name={`claimants.0.description.outcome`}
                    defaultLabel="Select triage"
                    options={outcomeOptions}
                    value={values.claimants[0].description.outcome || ''}
                  />
                  {/* <label htmlFor="Outcome" className="col-form-label">
                    Outcome
                  </label>
                  <textarea
                    className="form-control col-md-12"
                    value={values.claimants[0].description.outcome || ''}
                    id="Outcome"
                    placeholder={'Outcome'}
                    onChange={(a) =>
                      setFieldValue(
                        'claimants.0.description.outcome',
                        a.target.value,
                      )
                    }
                  ></textarea> */}
                </div>
                <div className="w-100 border-top border-primary border-bottom mx-2 p-0 mb-3 "></div>
                <div className="form-group col-md-12">
                  <label className="d-block incident-text" htmlFor="package">
                    Claim Checklist
                  </label>

                  <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                    <label
                      className="form-check-label text-primary"
                      htmlFor="claimantContacted"
                    >
                      Claimant Contacted
                    </label>
                    <input
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.checkList.claimantContacted',
                          a.target.checked,
                        )
                      }
                      name={'claimants.0.checkList.claimantContacted'}
                      className="form-check-TextInput1 pt-0"
                      type="checkbox"
                      value=""
                      id="claimantContacted"
                      checked={values.claimants[0].checkList.claimantContacted}
                    />
                  </div>

                  <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                    <label
                      className="form-check-label text-primary"
                      htmlFor="clientContacted"
                    >
                      Client Contacted
                    </label>
                    <input
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.checkList.clientContacted',
                          a.target.checked,
                        )
                      }
                      name={'claimants.0.checkList.clientContacted'}
                      className="form-check-TextInput1 pt-0"
                      type="checkbox"
                      value=""
                      id="clientContacted"
                      checked={values.claimants[0].checkList.clientContacted}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                    <label
                      className="form-check-label text-primary"
                      htmlFor="allDocumentsFiled"
                    >
                      All Documents filed
                    </label>
                    <input
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.checkList.allDocumentsFiled',
                          a.target.checked,
                        )
                      }
                      name={'claimants.0.checkList.allDocumentsFiled'}
                      className="form-check-TextInput1 pt-0"
                      type="checkbox"
                      value=""
                      id="allDocumentsFiled"
                      checked={values.claimants[0].checkList.allDocumentsFiled}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                    <label
                      className="form-check-label text-primary"
                      htmlFor="gensuiteFiled"
                    >
                      Gensuite Filed
                    </label>
                    <input
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.checkList.gensuiteFiled',
                          a.target.checked,
                        )
                      }
                      name={'claimants.0.checkList.gensuiteFiled'}
                      className="form-check-TextInput1 pt-0"
                      type="checkbox"
                      value=""
                      id="gensuiteFiled"
                      checked={values.claimants[0].checkList.gensuiteFiled}
                    />
                  </div>
                  <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                    <label
                      className="form-check-label text-primary"
                      htmlFor="evidenceFiled"
                    >
                      Evidence Filed
                    </label>
                    <input
                      onChange={(a) =>
                        setFieldValue(
                          'claimants.0.checkList.evidenceFiled',
                          a.target.checked,
                        )
                      }
                      name={'claimants.0.checkList.evidenceFiled'}
                      className="form-check-TextInput1 pt-0"
                      type="checkbox"
                      value=""
                      id="evidenceFiled"
                      checked={values.claimants[0].checkList.evidenceFiled}
                    />
                  </div>
                </div>
                <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                <div className="form-group col-md-12">
                  <label className="d-block incident-text" htmlFor="package">
                    Claim KPI
                  </label>
                  <div className="form-row">
                    <div className="col-md-6">
                      <TextInput
                        name={'claimants.0.deductible'}
                        type="text"
                        label={'Deductible'}
                        className="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-6 posi-rel">
                      <DatePickerField
                        label="Open Since"
                        name="claimants.0.openSince"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                <div className="form-group col-md-12">
                  <label className="d-block incident-text">
                    Claim Financials
                  </label>
                  {values?.claimants?.[0].financials.map((f, index) => {
                    return (
                      <ClaimFinancialCard
                        claim={f}
                        key={index}
                        onEdit={() => {
                          const a = values?.claimants?.[0].financials[index]
                          setOpenModal({ isOpen: true, data: a, editId: index })
                        }}
                        onDelete={() => {
                          const a = values?.claimants?.[0].financials.filter(
                            (party, i) => i != index,
                          )
                          setFieldValue('claimants.0.financials', a)
                        }}
                      />
                    )
                  })}
                  <button
                    onClick={() => setOpenModal({ isOpen: true, data: null })}
                    type="button"
                    className="btn btn-outline-secondary side-btn mt-2"
                    id="exampleModalLabel"
                    data-toggle="modal"
                    data-target="#claimfinancials"
                  >
                    Add..
                  </button>
                </div>
                <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                <div className="form-group col-md-12">
                  <AttachmentAddView
                    formik={{
                      setFieldValue: setFieldValue,
                      values: values,
                    }}
                    module={'claim'}
                    uid={values?.claimId || systemGeneratedClaimId}
                  />
                </div>
              </div>
            </div>
          </div>
          <ClaimFinancialModal
            isOpen={openModal.isOpen}
            data={openModal.data}
            onAdd={(data) => {
              const financials =
                openModal.editId != null
                  ? [
                      ...values?.claimants[0].financials?.filter(
                        (a, i) => i != openModal.editId,
                      ),
                      data,
                    ]
                  : [...values?.claimants[0].financials, data]
              setFieldValue('claimants[0].financials', financials)
            }}
            onClose={() =>
              setOpenModal({
                isOpen: false,
                data: null,
                editId: null,
              })
            }
          />
        </>
      )}
    </Formik>
  )
}

export default ConvertToClaim
