import { result } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
} from '../../actions'
import { getClaim } from '../../services/claimService'
import { getClient } from '../../services/clientService'
import { getEmployee } from '../../services/employeeService'
import { getIncident } from '../../services/incidentService'
import { getMvr } from '../../services/mvrService'
import { getPolicy } from '../../services/policyService'
import { getProperty } from '../../services/propertyService'
import { gobalSearchQuery } from '../../services/searchService'
import { getThirdParty } from '../../services/thirdPartyService'
import { getUser } from '../../services/userService'
import { getVehicle } from '../../services/vehicleService'

const tagMapper = [
  { key: 'all', value: 'All' },
  { key: 'claim', value: 'Claims' },
  { key: 'employee', value: 'Employees' },
  { key: 'vehicle', value: 'Vehicles' },
  { key: 'incident', value: 'Incidents' },
  { key: 'policy', value: 'Policy' },
  { key: 'property', value: 'Property' },
  { key: 'user', value: 'users' },
]

export function SearchBar({ isOpen = false, onClose }) {
  const history = useHistory()
  const inputRef = useRef(null)
  const dispatch = useDispatch()
  const [selectedTags, setSelectedTags] = useState([])
  const [results, setResults] = useState([])
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [value, setValue] = useState('')
  const selectedTabId = useSelector((state) => state.selectedTabId)

  const handleTagSelection = (keyTag) => {
    if (selectedTags.includes(keyTag)) {
      setSelectedTags((s) => s.filter((val) => val !== keyTag))
    } else {
      setSelectedTags((s) => [...s, keyTag])
    }
  }
  const handleView = async (item) => {
    dispatch(handleSideNavElementClicked('close'))
    await fetchViewData(item.eventId, item.type)
    setValue('')
    setResults([])
  }

  useEffect(() => {
    if (selectedTags.length > 0) {
      filterData(selectedTags)
    }
  }, [selectedTags])

  const filterData = async (selectedTags) => {
    const { data: data } = await gobalSearchQuery(value)

    let queryData = data.data
    setResults(queryData)
    if (!selectedTags.includes('all') && selectedTags.length > 0) {
      let filterData = queryData.filter((item) =>
        selectedTags.includes(item.type),
      )
      setResults(filterData)
    } else if (
      selectedTags.length > 1 &&
      selectedTags[selectedTags.length - 1] === 'all'
    ) {
      setSelectedTags(['all'])
    } else if (selectedTags.includes('all') && selectedTags.length > 1) {
      let selectedTagsWithoutAll = selectedTags.filter((item) => item !== 'all')
      let filterData = queryData.filter((item) =>
        selectedTagsWithoutAll.includes(item.type),
      )
      setResults(filterData)
      setSelectedTags(selectedTagsWithoutAll)
    }
  }

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus()
    }
  }, [isOpen])

  const fetchViewData = async (id, type) => {
    switch (type) {
      case 'employee': {
        const { data: employee } = await getEmployee(id)
        dispatch(handleTabIdUpdated('employee'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: employee.data,
              module: 'employee',
            },
            'searchView',
          ),
        )
        if (
          selectedTabId !== 'employee' &&
          selectedTabId !== 'vehicle' &&
          selectedTabId !== 'property'
        ) {
          history.push('/assetsManagement')
        }
        break
      }
      case 'property': {
        const { data: property } = await getProperty(id)
        dispatch(handleTabIdUpdated('property'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: property.data,
              module: 'property',
            },
            'searchView',
          ),
        )
        if (
          selectedTabId !== 'employee' &&
          selectedTabId !== 'vehicle' &&
          selectedTabId !== 'property'
        ) {
          history.push('/assetsManagement')
        }
        break
      }
      case 'vehicle': {
        const { data: vehicle } = await getVehicle(id)
        dispatch(handleTabIdUpdated('vehicle'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: vehicle.data,
              module: 'vehicle',
            },
            'searchView',
          ),
        )
        if (
          selectedTabId !== 'employee' &&
          selectedTabId !== 'vehicle' &&
          selectedTabId !== 'property'
        ) {
          history.push('/assetsManagement')
        }
        break
      }
      case 'client': {
        const { data: client } = await getClient(id)
        dispatch(handleTabIdUpdated('client'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: client.data,
              module: 'client',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'client' && selectedTabId !== 'user') {
          history.push('/userManagement')
        }
        break
      }
      case 'user': {
        const { data: user } = await getUser(id)
        dispatch(handleTabIdUpdated('user'))
        dispatch(
          handleSideNavDataUpdated(
            { data: user.data, module: 'user' },
            'searchView',
          ),
        )
        if (selectedTabId !== 'client' && selectedTabId !== 'user') {
          history.push('/userManagement')
        }
        break
      }
      case 'policy': {
        const { data: policy } = await getPolicy(id)
        dispatch(handleTabIdUpdated('policy'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: policy.data,
              module: 'policy',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'policy') {
          history.push('/policies')
        }
        break
      }
      case 'third-party': {
        const { data: thirdParty } = await getThirdParty(id)
        dispatch(handleTabIdUpdated('thirdParty'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: thirdParty.data,
              module: 'third-party',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'thirdParty') {
          history.push('/thirdParty')
        }
        break
      }
      case 'claim': {
        const { data: claims } = await getClaim(id)
        dispatch(handleTabIdUpdated('claim'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: claims.data,
              module: 'claim',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'claim' && selectedTabId !== 'incident') {
          history.push('/incidents')
        }
        break
      }
      case 'incident': {
        const { data: incidents } = await getIncident(id)
        dispatch(handleTabIdUpdated('incident'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: incidents.data,
              module: 'incident',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'claim' && selectedTabId !== 'incident') {
          history.push('/incidents')
        }
        break
      }
      case 'mvr': {
        const { data: mvr } = await getMvr(id)
        dispatch(handleTabIdUpdated('mvr'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: mvr.data,
              module: 'mvr',
            },
            'searchView',
          ),
        )
        if (selectedTabId !== 'mvr') {
          history.push('/mvr')
        }
        break
      }
      default:
    }
    dispatch(handleSideNavElementClicked('info'))
    onClose()
  }

  return (
    <div
      id="quick_search"
      className={isOpen ? 'search_visible open' : 'hide_search_bar'}
    >
      <div
        className="quick_search_overlay"
        onClick={() => {
          setDisplayDropdown(false)
          onClose()
        }}
      ></div>

      <div className="quick_search_content">
        <div className="quick_search_field_container">
          <input
            ref={inputRef}
            type="text"
            className="quick_search_field"
            placeholder="Search Everything"
            onChange={(event) => {
              if (!event.target.value) {
                setResults([])
                return
              }
              if (event.target.value.length > 3) {
                gobalSearchQuery(event.target.value).then((res) => {
                  setResults(res.data.data)
                })
                setValue(event.target.value)
              }
            }}
            data-qa-id="quick-search-field"
            onKeyDown={() => setDisplayDropdown(true)}
          />

          <svg-image img="svg_icons_search_ic">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.717 15.708a6 6 0 1 0-1.07 1.056l.602-.46.468-.596zm1.572 1.238l2.883 2.882a1 1 0 0 1-1.415 1.415l-2.891-2.892a8 8 0 1 1 1.423-1.405z"></path>
            </svg>
          </svg-image>
        </div>

        <ul
          className={'quick_search_results'}
          style={!displayDropdown ? { display: 'none' } : {}}
        >
          <li className="loader_container hidden">
            <span className="loader print_hide">
              <span className="dot_1"></span>
              <span className="dot_2"></span>
              <span className="dot_3"></span>
            </span>
          </li>
          <div className="d-flex justify-content-between">
            <ul className="tags" style={{ alignItems: 'center' }}>
              {tagMapper.map(({ key, value }, index) => (
                <li
                  key={index}
                  style={{ fontSize: '15px' }}
                  className={selectedTags.includes(key) ? 'activeTag' : ''}
                  onClick={() => handleTagSelection(key)}
                >
                  {value}
                </li>
              ))}
            </ul>
            <div>
              <button
                onClick={() => {
                  setValue('')
                  inputRef.current.value = ''
                  setResults([])
                  onClose()
                }}
                type="button"
                className="btn btn-secondary bg-white text-dark cls-btn"
                aria-label="Close"
              >
                Cancel
              </button>
            </div>
          </div>
          <li className="top_hits" ng-if="results.length">
            Suggested results
          </li>

          {results.map((result) => (
            <li className="search_hit" key={result.eventId}>
              <a ng-href="" onClick={() => {
                inputRef.current.value = ''
                handleView(result) 
              }}>
                <span
                  className="type uppercase"
                  style={{ textTransform: 'uppercase' }}
                >
                  {result.type}
                </span>
                <span className="name">{result.label}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
