import React from 'react'
import {Modal} from 'reactstrap'
import ClientForm from './ClientForm'

import { ReactComponent as Close } from '../../../svgs/close.svg'

export default function ClientModal({isOpen, data, onClose, id, title}) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{minWidth: '65vw'}}
        >
            <div>
                <a onClick={onClose} className="close close-left" aria-label="Close">
                    <Close />
                </a>
                <div className="modal-content mdl-scrol" style={{height: '100vh'}}>
                    <ClientForm data={data} id={id} title={title} onClose={onClose}/>
                </div>
            </div>
        </Modal>
    )
}
