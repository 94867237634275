import React, { useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import ImportCSV from '../../components/common/importCSV'
import ListPageHeader from '../../components/ListPageHeader'
import { exportPolicies, getPolicy } from '../../services/policyService'
import PolicyList from './PolicyList'
import PolicyModal from './PolicyModal'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
} from '../../actions'
import {
  downloadFile,
  getPromiseArrayForImport,
  parseFile,
} from '../../services/helperService'
import FilterDropdown from '../../components/common/FilterDropdown'
import { allPolicyTypes } from '../incident/utils'
import { amountRangeOptions } from '../../configs/utils'

const initialDateFilterState = {
  premiumRenewalDate: {
    label: ' Select date range of Premium Renewal Date',
    start: null,
    end: null,
    key: 'premiumRenewalDate',
  },
}

function Policy({ match }) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [context, setContext] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [openForm, setOpenForm] = useState({ isOpen: false, data: null })
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false)
  const [dateRangeFilter, setDateRangeFilter] = useState(initialDateFilterState)
  const [openBulkDel, setOpenBulkDel] = useState(false)


  const [policyFilters, setPolicyFilters] = useState({
    insurance: {
      name: 'insuranceCarrierType',
      label: 'Select Insurance Carrier',
      key: 'insurance',
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selected: '',
    },
    policyType: {
      name: 'policyType',
      label: 'Select Policy Type',
      key: 'policyType',
      options: [
        ...allPolicyTypes,
        {
          value: 'Others',
          key: 'Others',
        },
      ],
      selected: '',
    },
    sumInsured: {
      name: 'sumInsured',
      label: 'Select Sum Insured',
      key: 'sumInsured',
      options: amountRangeOptions,
      selected: '',
    }
  })

  const fetchPolicy = async (id, context) => {
    const { data: policy } = await getPolicy(id)
    dispatch(handleSideNavDataUpdated({data:policy.data,module:'policy'}))
    context !== 'view' && setOpenForm({ isOpen: true, data: policy.data })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const exportToCSV = async () => {
    const { data: policyData } = await exportPolicies()
    downloadFile(policyData, 'Policies.csv')
  }

  const handleEdit = async (item) => {
    setTitle('Edit Policy')
    await fetchPolicy(item._id)
  }

  const handleView = async (item) => {
    dispatch(handleTabIdUpdated('policy'))
    await fetchPolicy(item._id, 'view')
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const importFromCSV = async (file) => {
    try {
      const res = await parseFile(file)
      const createPolicyPromiseArray = getPromiseArrayForImport(res, 'policy')
      await Promise.all(createPolicyPromiseArray)
      toast.success(`${res.length} items are imported`)
      setContext('fetchList')
      setImportCSVDialogOpen(false)
    } catch (err) {
    } finally {
      setImportCSVDialogOpen(!importCSVDialogOpen)
    }
  }

  const onClear = () => {
    setPolicyFilters((policy) => ({
      insurance: { ...policy['insurance'], selected: '' },
      policyType: { ...policy['policyType'], selected: '' },
      sumInsured:{ ...policy['sumInsured'], selected: ''},
    }))
    setDateRangeFilter(initialDateFilterState)
  }

  const selectedFilterCount = useMemo(() => {
    let count = 0

    Object.values(policyFilters).forEach(({ selected }) => {
      if (selected.length) {
        count += 1
      }
    })

    Object.values(dateRangeFilter).forEach(({ start, end }) => {
      if (start || end) {
        count += 1
      }
    })
    return count
  }, [dateRangeFilter, policyFilters])

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView: (
          <FilterDropdown
            selectFilters={Object.values(policyFilters)}
            setSelectedFilter={setPolicyFilters}
            dateRangeFilter={dateRangeFilter}
            setDateRangeFilter={setDateRangeFilter}
            selectedFilterCount={selectedFilterCount}
            dateRangeLabel="Select DateRange for Policy Renewal Date"
            onClear={onClear}
          />
        ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  const handleAddNew = () => {
    setOpenForm({ isOpen: true, data: null })
    setTitle('Add New Policy')
  }

  const handleModalClose = () => {
    setOpenForm({ data: null, isOpen: false })
    setContext('')
    setContext('fetchList')
  }

  const handleDechecked = () => {
    setCheckedRecords([])
  }

  return (
    <div
      className={'position-rel'}
      style={{ marginBottom: '15px', width: 'calc(100% - 70px)' }}
    >
      <ListPageHeader
        modules = {['POLICY']}
        showTabs={false}
        showFilters={true}
        onAdd={handleAddNew}
        searchQuery={searchQuery}
        addCategory={addCategory}
        heading={'Policy Hub'}
        onSearchChange={handleSearch}
        filtersData={getFiltersData()}
        handleExportToCsv={exportToCSV}
        removeCategory={removeCategory}
        hideDeleteButton={!showDeleteButton}
        selectedCategories={selectedCategories}
        filterCount={selectedFilterCount}
        handleBulkCheckClick={() => { setOpenBulkDel(true)}}
        handleImportfromCsv={importFromCSVButtonClicked}
      />
      <PolicyList
        context={context}
        handleView={handleView}
        searchQuery={searchQuery}
        setShowDeleteButton={setShowDeleteButton}
        handleEdit={handleEdit}
        dateRangeFilter={dateRangeFilter}
        openBulkDel={openBulkDel}
        closeBulkDel={() => setOpenBulkDel(false)}
        selectFilters={Object.values(policyFilters)
          .filter(({ selected }) => selected.length)
          .reduce((prev, { key, selected }) => {
            prev[key] = selected
            return prev
          }, {})}
        setSelectFilter={(insuranceList) => {
          const pFilter = { ...policyFilters }
          pFilter.insurance.options = insuranceList
          pFilter.policyType.options = policyFilters.policyType.options
          pFilter.sumInsured.options = policyFilters.sumInsured.options
          setPolicyFilters(pFilter)
        }}
      />
      {openForm.isOpen && (
        <PolicyModal
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSV}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}

export default Policy
