import React, { useContext } from 'react'
import AccessLevel from './AccessLevel'
import Extras from './Extras'
import Modules from './Modules'
import RolesContext from './RolesContext'

function RolePermissions() {
  const formik = useContext(RolesContext)

  const isAllModulesSelected = (level) => {
    const selectedModules = formik.values.permissions.map(
      (data) => data?.accessMode,
    )
    return selectedModules.every((lvl) => lvl === level)
  }

  return (
    <div className="pl-6 user-mang assetmanag ">
      <div className="container-fluid" style={{ paddingBottom: '20px' }}>
        <div className="role-shadow  mr-5">
          <nav className="mt-4 " id="roles">
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active role-tab mt-0"
                id="access-tab"
                data-toggle="tab"
                href="#accesslevel"
                role="tab"
                aria-controls="nav-accesslevel"
                aria-selected="true"
              >
                Access Level
              </a>
              <a
                className="nav-item nav-link  role-tab mt-0"
                id="modules-tab"
                data-toggle="tab"
                href="#modules"
                role="tab"
                aria-controls="nav-modules"
                aria-selected="false"
                onClick={() => {
                  // selectOperationForSelectedModules()
                }}
                style={
                  isAllModulesSelected('NO_ACCESS')
                    ? {
                        pointerEvents: 'none',
                        opacity: '0.5',
                      }
                    : null
                }
              >
                Modules
              </a>
              {/* <a
                className="nav-item nav-link  role-tab mt-0"
                id="extras-tab"
                data-toggle="tab"
                href="#extras"
                role="tab"
                aria-controls="nav-extras"
                aria-selected="false"
                style={{ pointerEvents: 'none', opacity: '0.5' }}
              >
                Extras
              </a>
              <a
                className="nav-item nav-link role-tab mt-0 mb-0"
                id="settings-tab"
                data-toggle="tab"
                href="#settings"
                role="tab"
                aria-controls="nav-Settings"
                aria-selected="false"
                style={{ pointerEvents: 'none', opacity: '0.5' }}
              >
                Settings
              </a> */}
            </div>
          </nav>
          <div id="tabs" className="project-tab">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade active show"
                id="accesslevel"
                role="tabpanel"
                aria-labelledby="nav-accesslevel"
              >
                <AccessLevel />
              </div>
              <div
                className="tab-pane fade"
                id="modules"
                role="tabpanel"
                aria-labelledby="nav-modules"
                style={{ position: 'relative' }}
              >
                <Modules />
              </div>
              <div
                className="tab-pane fade mb-5 px-4 py-4"
                id="extras"
                role="tabpanel"
                aria-labelledby="nav-extras"
              >
                <Extras />
              </div>
              <div
                className="tab-pane fade"
                id="settings"
                role="tabpanel"
                aria-labelledby="nav-settings"
              >
                <div className="text-center">
                  <h1 className="my-5 py-5">Settings</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RolePermissions
