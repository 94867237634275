import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal } from 'reactstrap'
import apiUrl from '../../configs/api'
import { csvToJSON, importDataApi } from '../../services/helperService'
import { generateUID, getUniqueIdKey } from '../../utils/generateUID'
import { getModuleByTabId } from '../../utils/getModuleByTabId'
// import Loader from './Loader'
// import Loading from './Loading'

const ReadyState = () => (
  <p className="text-center font-bold">
    CSV is ready for import. Click on Import button to start import.
  </p>
)

const ProcessingState = ({ percentage }) => (
  <div className="modal-body pb-0">
    <div className="form-group">
      <div className="text-center mt-4 mb-5">
        <img src="/assets/images/loading.gif" alt="" />
      </div>
      <div className="text-center mt-4">
        <h2 className="upl"> Uploading ({percentage}%)</h2>
      </div>
      <div>
        <div className="mt-5 pb-2 text-center">
          <p className="fw-600"> Processing your import. One moment... </p>
        </div>
      </div>
    </div>
  </div>
)

//Pause state Body
const PauseState = ({ alert, message, percentage }) => (
  <div className="modal-body pb-0">
    <div className="form-group">
      <div className="text-center mt-4 mb-5">
        <img
          className="image-responsive"
          src="/assets/images/loading.gif"
          alt="loading"
          style={{ width: 80 }}
        />
      </div>
      <div className="text-center mt-4">
        <h2 className="upl"> Uploading ({percentage}%)</h2>
      </div>
      <div className="text-center mt-4">
        <img src="/assets/images/icon/warn.png" alt="" />
      </div>
      <div>
        <div className="mt-1 text-center">
          <p className="mb-3 ft-20"> {alert}</p>
          <p className="mb-1"> {message}</p>
          {/* <p className="mb-0">First Name :John</p> */}
        </div>
      </div>
    </div>
  </div>
)

//Pause state Footer
const PauseStateFooter = ({ onSkip, onOverwrite, onAbort }) => (
  <div className="fileupload modal-footer border-0 cusmdl-ft d-flex justify-content-center">
    <button
      onClick={onSkip}
      type="button"
      className="btn btn-primary skip "
      data-dismiss="modal"
    >
      Skip
    </button>
    <button
      onClick={onOverwrite}
      type="button text-dark"
      className="btn no-btn cancel mr-2 ml-2 overwrite"
      data-dismiss="modal"
    >
      Overwrite
    </button>
    <button
      onClick={onAbort}
      type="button text-dark"
      className="btn no-btn cancel abort"
      data-dismiss="modal"
    >
      Abort
    </button>
  </div>
)

//Initial View
const InitialView = ({ file, onFileSelect, errors, module }) => (
  <div className="modal-body pb-0">
    <div className="form-group" style={{ position: 'relative' }}>
      <div className="dropzone dropzone-default dropzone-primary dz-clickable">
        {file === null ? (
          <div className="dropzone-msg dz-message needsclick">
            <img className="mb-2" src="assets/images/icon/upload.svg" />
            <h3 className="dropzone-msg-title">
              Drag and Drop Here <br /> or
            </h3>
            <span className="dropzone-msg-desc">Browse files</span>
          </div>
        ) : (
          <div className="dropzone-msg dz-message needsclick">
            {' '}
            File Added {file.name}
          </div>
        )}
        <input
          type="file"
          id="upload-picture"
          name="csv"
          className=""
          accept=".csv"
          onChange={onFileSelect}
        />
      </div>
    </div>
    <div>
      <div className="mt-4 d-flex justify-content-between">
        <p className="supported fw-600">
          {' '}
          (Supported filestype: csv, xlsx)
          <br></br>
          {errors.length > 0 && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const json = errors
                  .map(
                    (c) => `
                  ${c.text}
                  ${c.errors.map((e) => `\t${e}`)}
                `,
                  )
                  .join('\n')

                const blob = new Blob([json])
                const url = URL.createObjectURL(blob)
                window.open(url, '_blank')
              }}
            >
              Click here to see all errors
            </span>
          )}
        </p>
        <p>
          <a
            onClick={(e) => {
              e.preventDefault()
              fetch(`${apiUrl}/${module}/import`, {
                headers: {
                  authorization: sessionStorage.getItem('token').toString(),
                },
              })
                .then((res) => res.blob())
                .then((r) => {
                  const blobUrl = URL.createObjectURL(r)
                  const a = document.createElement('a')
                  a.href = blobUrl
                  a.download = `${module}.csv`
                  a.click()
                  a.remove()
                })
            }}
            href={`#`}
          >
            Download Sample CSV
          </a>
        </p>
      </div>
    </div>
  </div>
)

//Validating View
const ValidatingView = () => (
  <div className="modal-body pb-0">
    <div className="form-group">
      <div className="text-center mt-4 mb-5">
        <img src="/assets/images/loading.gif" alt="" />
      </div>
      <div className="text-center mt-4">
        <h2 className="upl"> Validating...</h2>
      </div>
      <div>
        <div className="mt-5 pb-2 text-center">
          <p className="fw-600"> Validating your import. One moment... </p>
        </div>
      </div>
    </div>
  </div>
)

//Completed State Body
const CompletedState = ({ count }) => (
  <div className="modal-body pb-0">
    <div className="form-group">
      <div className="text-center mt-4">
        <svg
          id="img_success"
          xmlns="http://www.w3.org/2000/svg"
          width="73.935"
          height="73.935"
          viewBox="0 0 73.935 73.935"
        >
          <g id="Group_2812" data-name="Group 2812">
            <path
              id="Path_12684"
              data-name="Path 12684"
              d="M73.935,36.971A36.968,36.968,0,1,0,36.968,73.938,36.967,36.967,0,0,0,73.935,36.971Z"
              transform="translate(0 -0.003)"
              fill="#207be3"
            ></path>
            <path
              id="Path_12685"
              data-name="Path 12685"
              d="M151.356,154.7c-.885-2.2-2.688-1.85-4.642-1.477-1.167.234-6.357,1.7-14.568,10.032a67.425,67.425,0,0,0-7.135,8.321,44.334,44.334,0,0,0-3.028-3.259,43.656,43.656,0,0,0-7.232-5.523,3.4,3.4,0,0,0-4.569,1.059,3.135,3.135,0,0,0,1.1,4.4,37.321,37.321,0,0,1,6,4.591,50.033,50.033,0,0,1,5.4,6.427,3.361,3.361,0,0,0,2.768,1.427,3.46,3.46,0,0,0,.569-.047,3.278,3.278,0,0,0,2.658-2.382c.008-.03,1.327-3.538,8.292-10.608,5.61-5.7,9.352-7.507,10.648-8.009l.038-.006c-.006,0,.042-.02.12-.054.223-.08.342-.111.349-.113a.909.909,0,0,1-.1.019l0-.011c.6-.252,1.723-.717,1.747-.727A2.964,2.964,0,0,0,151.356,154.7Z"
              transform="translate(-93.692 -129.86)"
              fill="#fff"
            ></path>
          </g>
        </svg>
      </div>
      <div className="text-center mt-4">
        <h2 className="upl"> Success</h2>
      </div>

      <div>
        <div className="mt-5 mb-5 text-center">
          <p className="ft-20"> {count} new entries added. </p>
        </div>
      </div>
    </div>
  </div>
)

//Completed State Footer
const CompleteStateFooter = ({ onOkay }) => (
  <div className="fileupload modal-footer border-0  d-flex justify-content-center">
    <button
      onClick={onOkay}
      type="button"
      className="btn btn-primary skip pl-5 pr-5 mb-4"
      data-dismiss="modal"
    >
      Ok
    </button>
  </div>
)

const ErrorStateBody = ({ entries, total }) => {
  return (
    <div className="modal-body pb-0">
      <div className="form-group">
        <div className="text-center mt-4">
          <h2 className="upl">Error Occured</h2>
        </div>
        <div>
          <div className="mt-5 pb-2 text-center">
            <p className="fw-600">
              {' '}
              Some Error Occured while importing your data
            </p>
            <p>
              Total processeced entries: {entries} / {total}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

//Main Component
export default function ImportCSV(props) {
  const [file] = useState(null)
  const { selectedTabId, selectedClientId } = useSelector((state) => state)

  const [list, setList] = useState([])

  const [status, setStatus] = useState('')

  const [errors, setErrors] = useState([])

  const module = getModuleByTabId(selectedTabId)

  const [conflict, setConflict] = useState({
    index: null,
    alert: '',
    message: '',
  })

  const onFileSelect = async (event) => {
    setStatus('validating')
    setErrors([])
    const response = await csvToJSON(module, event.target.files[0]).catch(() => setStatus(''))
    const allErrors = []
    const allList = []
    response.data?.data?.forEach((record, index) => {
      if (record.errors.length !== 0) {
        const mainIndex = index + 1
        allErrors.push({
          text: `RECORD ${mainIndex}`,
          errors: record.errors,
        })
      }
      if (record.data) {
        const obj = {
          ...record.data,
          processed: false,
          overwrite: false,
          skip: false,
        }
        allList.push(obj)
      }
    })
    if (allErrors.length > 0) {
      toast.error('Missing or invalid data in the csv uploaded')
      setStatus('')
    } else {
      setStatus('ready')
    }
    setList(allList)
    event.target.value = null
    setErrors(allErrors)
  }

  const importData = useCallback(async () => {
    if (status === 'processing') {
      //   const pendingList = list.filter((c) => !c.processed && !c.skip)
      const key = getUniqueIdKey(module)
      const autoGenId = generateUID(module)
      const entryIndex = list.findIndex((c) => !c.processed && !c.skip)
      if (entryIndex !== -1) {
        try {
          const data = { ...list[entryIndex], [key]: autoGenId }
          const response = await importDataApi(module, data, selectedClientId)
          const { processed, alert, message } = response.data.data
          if (!processed) {
            setConflict({
              alert: alert,
              message: message,
              index: entryIndex,
            })
            setStatus('pause')
          } else {
            const newList = [...list]
            newList[entryIndex].processed = true
            setList(newList)
          }
        } catch (er) {
          console.log("er",er)
          setStatus('error')
        }
      } else {
        setStatus('completed')
      }
    }
  }, [status, list])

  useEffect(() => {
    importData()
  }, [importData])

  const processedList = list.filter((c) => c.processed)
  const percentage = ((processedList.length / list.length) * 100).toFixed(0)

  return (
    <Modal
      isOpen={props.isOpen}
      backdrop={'static'}
      toggle={props.onClose}
      className={'modal-dialog excelfile'}
    >
      <div className="modal-content border-radius-file">
        <div className="modal-header border-0 pb-0">
          <h4 className="modal-title" style={{ color: '#207BE3' }}>
            Import CSV
          </h4>
        </div>
        {status == '' && (
          <InitialView
            errors={errors}
            file={file}
            module={module}
            onFileSelect={onFileSelect}
          />
        )}

        {status === 'validating' && <ValidatingView />}
        {status === 'ready' && <ReadyState />}
        {status === 'pause' && (
          <PauseState {...conflict} percentage={percentage} />
        )}
        {status === 'completed' && (
          <CompletedState count={processedList.length} />
        )}

        {status === 'error' && (
          <ErrorStateBody entries={processedList.length} total={list.length} />
        )}

        {(status === 'ready' || status == '') && (
          <div className="fileupload modal-footer border-0 cusmdl-ft d-flex justify-content-center">
            <button
              onClick={() => {
                setStatus('processing')
              }}
              type="button"
              className="btn btn-primary importexl  mr-2"
              data-dismiss="modal"
            >
              Import
            </button>
            <button
              onClick={props.onClose}
              type="button text-dark"
              className="btn no-btn impcancel"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        )}

        {status === 'processing' && <ProcessingState percentage={percentage} />}
        {status === 'pause' && (
          <PauseStateFooter
            onAbort={() => {
              setStatus('completed')
            }}
            onSkip={() => {
              const changeSkip = [...list]

              changeSkip[conflict.index].skip = true
              setList(changeSkip)
              setStatus('processing')
            }}
            onOverwrite={() => {
              const changeSkip = [...list]
              changeSkip[conflict.index].overwrite = true
              setList(changeSkip)
              setStatus('processing')
            }}
          />
        )}
        {(status === 'completed' || status == 'error') && (
          <CompleteStateFooter onOkay={props.onClose} />
        )}
      </div>
    </Modal>
  )
}
