import React from 'react'

function PropertiesInvolvedCard({ property, onEdit, onDelete, uid }) {
  const add = property?.propertyDetails?.address
  const line1 = add?.line1 ?? ''
  const line2 = add?.line2 ?? ''
  const city = add?.city ?? ''
  const state = add.state ?? ''
  const country = add.country ?? ''
  const pincode = add.pinCode ?? ''
  const propertyLabel = property.isThirdPartyPropertyDamaged ? "Policy Details: " : "Property Details: "
  const addr =
    [line1, line2, city, state, country].filter((e) => e !== '').join(', ') +
    ' - ' +
    pincode

  return (
    <div className="parties bg-white p-3 mb-3" data-testid={`property-card-${uid}`}>
      <div className="pull-right pb-2">
        <button data-testid={`edit-property-${uid}`} key="1" type="button" className="btn btn-outline-secondary side-btn mr-1" onClick={() => onEdit(uid)}>
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </button>
        <button data-testid={`delete-property-${uid}`} key="2" type="button" className="btn btn-outline-secondary side-btn" onClick={() => onDelete(uid)}>
          <i className="far fa-trash-alt" aria-hidden="true"></i>
        </button>
      </div>
      {property.isThirdPartyPropertyDamaged && <h6 className="text-primery">{property.propertyDetails.name}</h6>}
      <p>{propertyLabel}{property.propertyDetails.propId}</p>
      {property.isThirdPartyPropertyDamaged && <p className="mb-2">
        <i className="fas fa-map-marker-alt mr-2" aria-hidden="true"></i> {addr}
      </p>}

      {!property.isThirdPartyPropertyDamaged && <span className="badge badge-pill btn btn-outline-primary">
        Company Property
      </span>}
    </div>
  )
}
export default PropertiesInvolvedCard;
