import React, { useState } from 'react'

const SearchBox = ({
  value,
  onChange,
}) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <form className={`searchboxtbl${show ? '-open' : ''}`}>
        <input
          type="search"
          placeholder="Search......"
          name="search"
          className="searchboxtbl-input"
          onChange={(e) => onChange(e.currentTarget.value)}
          value={value}
          required=""
        />

        <span className="searchboxtbl-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.16"
            height="20.164"
            viewBox="0 0 20.16 20.164"
            onClick={() => {
              setShow(!show)
            }}
          >
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              cx="25"
              cy="25"
              r="25"
              fill="none"
            ></circle>
            <path
              className="search"
              d="M20.036,19.136l-4.9-4.9a8.6,8.6,0,1,0-.849.849l4.9,4.9a.608.608,0,0,0,.425.179.589.589,0,0,0,.425-.179A.6.6,0,0,0,20.036,19.136ZM1.253,8.6a7.391,7.391,0,1,1,7.391,7.4A7.4,7.4,0,0,1,1.253,8.6Z"
              transform="translate(-0.05 0)"
            ></path>
          </svg>
        </span>
      </form>
      {/* <input
        type="text"
        className="form-control search-input"
        placeholder="Search"
        onChange={(e) => onChange(e.currentTarget.value.trim())}
        value={value}
      /> */}
      {/*{!show ? (*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className="form-control search-input"*/}
      {/*    placeholder="Search"*/}
      {/*    onClick={() => setShow(true)}*/}
      {/*    readOnly*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <div className="card searchBox p-2">*/}
      {/*    <div className="card-body">*/}
      {/*      <h5 role="button" onClick={() => setShow(false)}>*/}
      {/*        X*/}
      {/*      </h5>*/}
      {/*      <div className="row no-gutters">*/}
      {/*        <div className="col-sm-12 d-flex justify-content-between">*/}
      {/*          <div className="text-left">*/}
      {/*            {selectedCategories.map((category) => (*/}
      {/*              <button*/}
      {/*                className="btn btn-primary btn-sm m-1"*/}
      {/*                key={category}*/}
      {/*                onClick={() => removeCategory(category)}*/}
      {/*              >*/}
      {/*                {category}*/}
      {/*                {'  '}*/}
      {/*                {'  '}*/}
      {/*                <i className="fa fa-close" />*/}
      {/*              </button>*/}
      {/*            ))}*/}
      {/*          </div>*/}
      {/*          <input*/}
      {/*            type="Text"*/}
      {/*            className="form-control search-input"*/}
      {/*            placeholder="Search"*/}
      {/*            value={value}*/}
      {/*            style={*/}
      {/*              categories.length > 0 && selectedCategories.length > 0*/}
      {/*                ? { width: '50%' }*/}
      {/*                : { width: '100%' }*/}
      {/*            }*/}
      {/*            onChange={(e) => onChange(e.currentTarget.value.trim())}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      {categories.length > 0 && (*/}
      {/*        <>*/}
      {/*          <hr />*/}
      {/*          <div className="row no-gutters">*/}
      {/*            <div className="col-sm-12 d-flex justify-content-between">*/}
      {/*              <strong>Type</strong>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="row no-gutters mt-1">*/}
      {/*            <div className="col-sm-12 text-left">*/}
      {/*              {categories.map((category) => (*/}
      {/*                <button*/}
      {/*                  className="btn btn-light btn-sm m-1"*/}
      {/*                  onClick={() => addCategory(category)}*/}
      {/*                  key={category}*/}
      {/*                >*/}
      {/*                  {category}*/}
      {/*                </button>*/}
      {/*              ))}*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  )
}

SearchBox.defaultProps = {
  categories: [],
  selectedCategories: [],
  addCategory: () => {},
  removeCategory: () => {},
}

export default SearchBox
