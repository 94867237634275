import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import { transformPhoneNumber } from '../../pages/incident/utils'

export default function MVRRecordInfo() {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  return (
    <div
     className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">MVR-WC-1053</div>
                {data?.organisation}
              </span>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li className="nav-item p-0 close11" onClick={handleSideNavClose}>
                  <span className="search" id="closeIcon">
                    <a className="text-dark">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.829"
                          height="17.828"
                          viewBox="0 0 17.829 17.828"
                        >
                          <g
                            id="Group_9756"
                            data-name="Group 9756"
                            transform="translate(-4013.659 13.151)"
                          >
                            <path
                              id="path1419"
                              d="M-1479.2-17.087l15-15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              id="path1421"
                              d="M-1479.2-32.087l15,15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li>
              </nav>
            </div>
          </div>
        </div>

        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="false"
                      aria-controls="faq1"
                    >
                      MVR Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Name</div>
                          <span>{`${data?.firstName || ''} ${
                            data?.lastName || ''
                          }`}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">SSN Number</div>
                          <span>{'*****'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Contact Number</div>
                          <span>{transformPhoneNumber(data?.contactNumber) || ''}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Organization</div>
                          <span>{data?.organisation || ''}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          <div className="d-flex"> Analysis</div>
                          <span>{data?.analysis || ''}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          <div className="d-flex"> Notes</div>
                          <span>{data?.notes || ''}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
