import React, { useRef, useState } from 'react'
import { useDirectClaimContext } from './DirectClaimContext'
import DirectIncidentDetailsTab from './DirectIncidentDetailsTab2'
import IncidentRightSection from './IncidentRightSection'
import { Formik } from 'formik'
import ClaimRightSection from './ClaimRightSection'

const DirectIncidentForm = () => {
  const {
    isNew,
    initialValues,
    submitForm,
    validationSchema,
    activeStep = 1
  } = useDirectClaimContext()
  return (
    <>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues ?? {}}
        onSubmit={submitForm}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {
          ({ values, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="row m-0">
                <DirectIncidentDetailsTab />
                {
                  activeStep > 3 && activeStep < 6 ? <ClaimRightSection /> : <IncidentRightSection />
                }
              </div>

            </form>)
        }
      </Formik>
    </>
  )
}

export default DirectIncidentForm
