import React, { useEffect } from 'react'
import { checkOrgRole } from '../../../services/roleService'

const ShowAllClientCheck = ({ roleId, showAllClient, setFieldValue }) => {
  useEffect(() => {
    if (roleId) {
      checkOrgRole(roleId).then((r) => {
        showAllClient(r.data.data)
      })
    }
  }, [roleId])

  return <></>
}

export default ShowAllClientCheck
