export default function AttachmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.874"
      height="25.874"
      viewBox="0 0 25.874 25.874"
    >
      <path
        id="clip"
        d="M17.837,7.451a.776.776,0,0,0-1.1,0l-8.454,8.49A3.881,3.881,0,1,1,2.8,10.453l8.188-8.221a2.328,2.328,0,1,1,3.293,3.293L6.09,13.745a.793.793,0,0,1-1.1,0,.776.776,0,0,1,0-1.1l7.29-7.323a.776.776,0,1,0-1.1-1.1L3.895,11.55a2.328,2.328,0,0,0,0,3.293,2.385,2.385,0,0,0,3.293,0l8.187-8.222A3.881,3.881,0,1,0,9.887,1.134L1.7,9.355a5.433,5.433,0,0,0,7.684,7.684l8.454-8.488a.776.776,0,0,0,0-1.1Z"
        transform="translate(-0.074 12.777) rotate(-45)"
        fill="#207be3"
      />
    </svg>
  )
}
