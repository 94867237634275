/* eslint-disable react/prop-types */
import { Form, Formik } from 'formik'
import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import TextArea from './form/TextArea'

export default function MVRNotes({
  info = {},
  isNotesModal,
  onSubmit,
  onClose,
}) {
  const handleSubmit = async (values) => {
    onSubmit(values?.notes || '')
  }

  return (
    <Modal
      isOpen={isNotesModal}
      backdrop="static"
      toggle={onClose}
      className="mvr-modal modal-dialog-centered"
    >
      <Formik
        enableReinitialize
        initialValues={{ notes: info?.notes || '' }}
        onSubmit={handleSubmit}
      >
        <Form className="indent-form">
          <div className="modal-header d-flex justify-content-center">
            <h5 className="modal-title text-primary"> Recommendation </h5>
          </div>

          <ModalBody className="p-0" id="smodaltab">
            <div className="px-4 mt-3">
              <div className="form-group pb-3 mt-4">
                <div className="row">
                  <div className="col-md-12">
                    <TextArea
                      name="notes"
                      style={{ height: 108 }}
                      label="Add Notes"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <div className="modal-footer term-footer">
            <button type="submit" className="btn btn-primary add-btn">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}
