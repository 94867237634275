import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import {
  useCommentAccess,
  useGetPermission,
} from '../../hooks/useGetPermission'
import { transformPhoneNumber } from '../../pages/incident/utils'

export default function EmployeesRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const deletePermission = useGetPermission('user', 'DELETE')
  const editPermission = useGetPermission('user', 'UPDATE')
  const addCommentPermission = useCommentAccess('user', 'CREATE')

  const getAddress = (address) => {
    let addressString = ''
    if (address) {
      addressString += address.line1 ? address.line1 + ', ' : ''
      addressString += '\n'
      addressString += address.line2 ? address.line2 + ', ' : ''
      addressString += address.city ? address.city : ''
      addressString += '\n'
      addressString += address.state ? address.state + ', ' : ''
      addressString += address.country ? address.country + '-' : ''
      addressString += address.pinCode ? address.pinCode : ''
    }

    return addressString
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div className="card-header-2 mb-4 mt-3">
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-4 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="right"
                title="User creation date"
                data-original-title={moment(data.created).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {moment(data.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {moment(data.created).format('YYYY')}
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.userId || ''}</div>
                {data?.roleId?.roleName || ''}
              </span>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>

          <div className="pull-right px-3 pb-5 d-flex justify-content-between cus-width">
            <div className="">
              {addCommentPermission && (
                <button
                  type="button"
                  className="btn btn-primary mr-3 px-3"
                  onClick={() =>
                    dispatch(handleSideNavElementClicked('comment'))
                  }
                >
                  Add Comment
                </button>
              )}
            </div>
            <div>
              {editPermission && (
                <button
                  type="button"
                  className="btn btn-outline-secondary side-btn mr-3"
                  data-toggle="modal"
                  onClick={onEdit}
                >
                  <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
              )}
              {deletePermission && (
                <button
                  type="button"
                  className="btn btn-outline-secondary side-btn"
                  onClick={onDelete}
                >
                  <i className="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="false"
                      aria-controls="faq1"
                    >
                      User Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Name</div>
                          <span>{data.firstName + ' ' + data.lastName}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Email ID</div>
                          <span>{data?.email || 'N/A'}</span>
                        </label>
                      </div>
                     
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Date of Birth</div>
                          <span>
                            {data.dateOfBirth ? moment(data.dateOfBirth).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Designation</div>
                          <span>{data?.designation || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Role</div>
                          <span>{data?.roleId?.roleName || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Contact Number</div>
                          <span>{transformPhoneNumber(data?.contactNumber) || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          <div className="d-flex"> Address</div>
                          <span>
                            {getAddress(data.address)}
                            {/* {data?.address &&
                              `${data?.address?.line1 + ',' || ''}`}
                            <br />
                            {data?.address &&
                              `${data?.address?.line2 + ',' || ''} ${
                                data?.address?.city + ',' || ''
                              }`}
                            <br />
                            {data?.address &&
                              `${data?.address?.state + ',' || ''} ${
                                data?.address?.country + '' || ''
                              } - ${data?.address?.pinCode || ''}`} */}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
