import React, { useEffect, useState, useContext } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import { Link, useHistory } from 'react-router-dom'
import ActionWidget from '../../components/common/ActionWidget'
import Table from '../../components/common/Table'
import {
  deleteBlukClaim,
  deleteClaim,
  getClaims,
} from '../../services/claimService'
import ClaimsDashboard from './ClaimsDashboard'
import ClaimFinancialModal from '../claims/claimFinancialModal'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
  saveClaimInfo,
} from '../../actions'
import { useDispatch, useSelector } from 'react-redux'
import IncidentConvertToClaimModal from '../incident/IncidentConvertToClaimModal'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import moment from 'moment'
import { addComma, addCommaTableField } from '../incident/utils'
import { flatten } from 'flat'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'

let claims = []
let itemsCount = 0

function ClaimsList({
  context,
  isOpen,
  activeClaimsTabId,
  searchQuery,
  setShowDeleteButton,
  selectFilters,
  dateRangeFilter,
  inputFilters,
  filters,
  setInputFilters,
  openBulkDel,
  closeBulkDel,
  setFilterCount,
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [activeStep, setActiveStep] = useState(1)
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      path: 'dateOfClaim',
      label: ' Date of Loss ',
      type: 'date',
      // width: '180px',
      content: (data) => {
        if (data?.incidents?.[0]?.incidentDetails?.incidentDetails?.dateOfIncident) {
          return moment(data?.incidents?.[0]?.incidentDetails?.incidentDetails?.dateOfIncident, "YYYY-MM-DD").format("MM/DD/YYYY")
        }

        if (data?.incident?.incidentDetails?.dateOfIncident) {
          return moment(data?.incident?.incidentDetails?.dateOfIncident).format("MM/DD/YYYY")
        }

        return "N/A"
      },
    },
    {
      path: 'status',
      label: '',
      width: '150px',
      content: (claim) => (
        <ActionWidget
        module="CLAIM"
        onEdit={handleEditButtonClicked.bind(this, claim)}
        onView={handleOnView.bind(this, claim)}
        onDelete={() => {
          setClaim(claim)
          setDeleteOne(true)
        }}
      />
      ),
    },
    {
      path: 'clientId',
      label: ' Claim Status ',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.claimStatus
                ? claim.claimStatus
                : 'N/A'
              : claim.incidents[0]?.claimants[0]?.status || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'claim.startDate',
      label: ' Start Date ',
      type: 'date',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.startDate
                ? moment(claim.incident.startDate).format('MM/DD/YYYY')
                : 'N/A'
              : claim.incidents[0]?.claimants[0]?.startDate
                ? moment(claim.incidents[0]?.claimants[0]?.startDate).format(
                  'MM/DD/YYYY',
                )
                : 'N/A' || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'claim.endDate',
      label: ' Due Date ',
      type: 'date',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.endDate
                ? moment(claim.incident.endDate).format('MM/DD/YYYY')
                : 'N/A'
              : claim.incidents[0]?.claimants[0]?.endDate
                ? moment(claim.incidents[0]?.claimants[0]?.endDate).format(
                  'MM/DD/YYYY',
                )
                : 'N/A' || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'claimNumber',
      label: 'Claim Number',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.claimNumber
                ? claim.incident.claimNumber
                : 'N/A'
              : claim.incidents[0]?.claimNumber || 'N/A'}
          </span>
        </>
      ),
    },
    {
      label: 'Employee Name',
      content: (claim) => {
        let companyEmp = null
        if (claim.directClaim) {
          companyEmp = claim?.incident.partiesInvolved.find(
            ({ isCompanyEmployee }) => isCompanyEmployee,
          )
        } else {
          companyEmp =
            claim?.incidents[0]?.incidentDetails?.partiesInvolved.find(
              ({ isCompanyEmployee }) => isCompanyEmployee,
            )
        }
        const { personDetails: { lastName = '', firstName = '' } = {} } =
          companyEmp || {}
        const name = `${lastName ? lastName + ',' : ''} ${firstName}`
        return <span>{name.length > 2 ? name : 'N/A'}</span>
      },
    },
    {
      path: 'directClaim',
      label: 'Claim Type',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.claimType
                ? claim.incident.claimType
                : 'N/A'
              : claim?.incidents[0]?.claimants[0]?.description.claimType ||
              'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'coverageDescription',
      label: 'Coverage Description',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.coverageDescription
                ? claim.incident.coverageDescription
                : 'N/A'
              : claim.incidents[0]?.claimants[0]?.coverageDescription || 'N/A'}
          </span>
        </>
      ),
    },
    {
      label: 'Claimant Full Name',
      content: (claim) => {
        let companyEmp = null
        if (claim.directClaim) {
          companyEmp = claim.incident?.claimantInfo
        } else {
          companyEmp = claim.incidents[0]?.claimants[0]?.claimantInfo
        }
        const { basicInfo: { lastName = '', firstName = '' } = {} } =
          companyEmp || {}
        const name = `${lastName ? lastName + ',' : ''} ${firstName}`
        return <span>{name.length > 2 ? name : 'N/A'}</span>
      },
    },
    {
      path: 'amountPaid',
      label: 'Claim Paid',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? (claim.incident.amountPaid || claim.incident?.amountPaidTillDate?.amount)
                ? addCommaTableField(claim.incident.amountPaid || claim.incident.amountPaidTillDate.amount)
                : 'N/A'
              : addCommaTableField(
                claim.incidents[0]?.incidentDetails?.amountPaid,
              ) || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'amountIncurred',
      label: 'Claim Incurred',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.amountIncurred || claim.incident?.amountIncurredTillDate?.amount
                ? addCommaTableField(claim.incident.amountIncurred || claim.incident.amountIncurredTillDate.amount)
                : 'N/A'
              : addCommaTableField(
                claim?.incidents[0]?.incidentDetails?.amountIncurred,
              ) || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'adjusterName',
      label: 'Adjuster Name',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.adjusterName
                ? claim.incident.adjusterName
                : 'N/A'
              : claim.incidents[0]?.claimants[0]?.adjusterName || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'adjusterContactDetails',
      label: 'Adjuster Contact',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.adjusterContactDetails
                ? claim.incident.adjusterContactDetails
                : 'N/A'
              : claim?.incidents[0]?.claimants[0]?.adjusterContactDetails ||
              'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'priority',
      label: 'Priority',
      content: (claim) => (
        <>
          <span>
            {claim.directClaim
              ? claim.incident.priority
                ? claim.incident.priority
                : 'N/A'
              : claim?.incidents[0]?.claimants[0]?.priority || 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'claim.modified',
      label: 'Last changed date',
      content: (claim) => (
        <>
          <span>{moment(claim.modified).format('MM/DD/YYYY')}</span>
        </>
      ),
    },
  ]

  const getClaimFilterData = (claim) => {
    const claimant = claim?.incidents[0]?.claimants[0] || claim?.incident
    const {
      checkList = '',
      priority = '',
      severity = '',
      status = '',
      directClaim = '',
      vehicularAccident = '',
      coverageType = '',
      amountPaid = '',
      amountIncurred = ''
    } = selectFilters
    const {
      checkList: claimCheckList,
      description,
      status: claimStatus,
      priority: priorityStatus,
      coverageType: coverageTypeData,
    } = claimant
    const { severity: claimSeverity } = (description ?? {})
    const { directClaim: directClaimStatus } = (claim ?? {})
    let isDateRangeValid = false
    let isDateRangeSelected = Object.values(dateRangeFilter).every((x) => {
      return x.start === null
    })

    if (dateRangeFilter && !isDateRangeSelected) {
      Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
        if (start !== null && claimant[key] !== '') {
          if (
            claimant[key] > start.toISOString() &&
            claimant[key] < end.toISOString()
          ) {
            isDateRangeValid = true
          } else {
            isDateRangeValid = false
          }
        }
      })
    }

    let isCheckList = false,
      isPriority = false,
      isSeverity = false,
      isStatus = false,
      isDirectClaim = false,
      isVehicularAccident = false,
      isDueStatus = false,
      isAmmountPaid = false,
      isAmountIncurred = false,
      isCoverageType = false

    if (checkList.length && claimCheckList[checkList]) {
      isCheckList = true
    }
    if (severity.length && severity === claimSeverity) {
      isSeverity = true
    }

    if (status.length && status === claimStatus) {
      isStatus = true
    }

    if (coverageType.length && coverageType === coverageTypeData) {
      isCoverageType = true
    }

    const vehicularAccidentData = claim?.directClaim
      ? claim?.incident?.vehicularAccident
      : claim?.incidents[0]?.incidentDetails?.vehicularAccident

    if (
      vehicularAccident.length &&
      vehicularAccident.toString() === vehicularAccidentData.toString()
    ) {
      isVehicularAccident = true
    }

    if (priority.length && priority === priorityStatus) {
      isPriority = true
    }
    if (directClaim.toString().length && directClaim === directClaimStatus) {
      isDirectClaim = true
    }

    let date
    if (claim.directClaim) {
      date = claim?.incident?.endDate
    } else {
      date = claim?.incidents[0]?.claimants[0]?.endDate
    }
    let Tstatus
    const currentDate = moment().format('MM/DD/YYYY')
    const fDate = moment(date).format('MM/DD/YYYY')
    const overDue = fDate < currentDate
    const dueNow = fDate === currentDate
    if (overDue) {
      Tstatus = 'OVER DUE'
    } else if (dueNow) {
      Tstatus = 'DUE NOW'
    }

    if (
      (filters.overdue.isAvailable && fDate < currentDate) ||
      (filters.dueToday.isAvailable && fDate === currentDate)
    ) {
      isDueStatus = true
    }

    if (amountPaid.length) {
      let startRange = amountPaid.split('-')[0]
      let endRange = amountPaid.split('-')[1]
      if (endRange) {
        if (claimant?.amountPaid >= startRange && claimant?.amountPaid <= endRange) {
          isAmmountPaid = true
        }
      } else {
        if (claimant?.amountPaid >= startRange) {
          isAmmountPaid = true
        }
      }
    }
    if (amountIncurred.length) {
      let startRange = amountIncurred.split('-')[0]
      let endRange = amountIncurred.split('-')[1]
      if (endRange) {
        if (claimant?.amountIncurred >= startRange && claimant?.amountIncurred <= endRange) {
          isAmountIncurred = true
        }
      } else {
        if (claimant?.amountIncurred >= startRange) {
          isAmountIncurred = true
        }
      }
    }
    return (
      !(!!checkList.length ^ isCheckList) &&
      !(!!severity.length ^ isSeverity) &&
      !(!!status.length ^ isStatus) &&
      !(!!vehicularAccident.length ^ isVehicularAccident) &&
      !(!!coverageType.length ^ isCoverageType) &&
      !(!!priority.length ^ isPriority) &&
      !(!!directClaim.toString().length ^ isDirectClaim) &&
      !(!!amountIncurred.length ^ isAmountIncurred) &&
      !(!!amountPaid.length ^ isAmmountPaid) &&
      !(!isDateRangeSelected ^ isDateRangeValid) &&
      !(
        !!(filters.overdue.isAvailable || filters.dueToday.isAvailable) ^
        isDueStatus
      )
    )
  }

  const categories = columns
    .filter((c) => c.type !== 'date')
    .map((col) => col.label)
  const [activeTab, setActiveTab] = useState('2')
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [deleteOne, setDeleteOne] = useState(false)
  const [claimModal, setClaimModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })
  const [editModalData, setEditModalData] = useState({
    isOpen: false,
    data: null,
  })
  const history = useHistory()
  const [checkedRecords, setCheckedRecords] = useState([])
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)

  const dispatch = useDispatch()
  const [claim, setClaim] = useState({})

  useEffect(() => {
    if (openBulkDel) {
      setClaim({ claim: { claimId: 'all claims' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    if (isOpen || claimModal.isOpen || editModalData.isOpen) return
    setIsLoading(true)
    fetchList()
    dispatch(handleTabIdUpdated('claim'))
  }, [
    context,
    selectedClientId,
    isOpen,
    claimModal.isOpen,
    editModalData.isOpen,
  ])

  useEffect(() => {
    if (
      selectedTabId === 'claim' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEditButtonClicked(viewData)
    }
    if (selectedTabId === 'claim' && viewAction === 'refresh') {
      fetchList()
      dispatch(handleTabIdUpdated('claim'))
    }
  }, [viewAction, viewData, selectedTabId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {
    if (data && data.length) {
      const mapFilterCount = {
        overdue: 0,
        duenow: 0,
      }
      let date = ''

      data.forEach((d) => {
        if (d.directClaim) {
          date = d?.incident?.endDate
        } else {
          date = d?.incidents[0]?.claimants[0]?.endDate
        }
        const currentDate = moment().format('MM/DD/YYYY')
        const fDate = moment(date).format('MM/DD/YYYY')
        const overDue = fDate < currentDate
        const dueNow = fDate === currentDate
        if (overDue) {
          mapFilterCount[`overdue`] += 1
        } else if (dueNow) {
          mapFilterCount[`duenow`] += 1
        }
      })
      setFilterCount(mapFilterCount['overdue'], mapFilterCount['duenow'])
    }
  }, [data])

  const handleEditButtonClicked = (data) => {
    setEditModalData({ isOpen: true, data: data })
  }

  const handleOnView = (claim) => {
    dispatch(handleTabIdUpdated('claim'))
    dispatch(handleSideNavDataUpdated({ data: claim, module: 'claim' }, 'view'))
    dispatch(handleSideNavElementClicked('info'))
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }
  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }
  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
    )
    itemsCount = count
    claims = result
    claims.length !== 0 &&
      sideNavDataContext !== 'view' &&
      sideNavDataContext !== 'searchView' &&
      dispatch(
        handleSideNavDataUpdated({ data: claims[0], module: 'claim' }, 'view'),
      )
    return result.length > 0
  }

  const getPagedData = (
    data,
    searchQuery,
    sortColumn,
    selectedCategories,
    columns,
  ) => {
    let manipulatedArr = data
    if (searchQuery) {
      manipulatedArr = Search(data, searchQuery)
    }
    if (sortColumn && sortColumn.path) {
      manipulatedArr = _.orderBy(
        manipulatedArr,
        [sortColumn.path],
        [sortColumn.order],
      )
    }
    const count = manipulatedArr.length
    const result = [...manipulatedArr]
    return { result, count }
  }

  const Search = (data, searchQuery) => {
    let updatedArray = []
    for (let originalData of data) {
      let currentData = null
      let flag = 0
      originalData.directClaim
        ? (currentData = originalData.incident)
        : (currentData = originalData?.incidents[0]?.claimants[0])

      let values = Object.values(currentData)
      for (let val of values) {
        if (typeof val === 'string') {
          if (val.toLocaleLowerCase().includes(searchQuery.toLowerCase())) {
            updatedArray.push(originalData)
            flag = 1
            break;
          }
        } else if (typeof val === 'object' && val) {
          let innerObjectValues = Object.values(val)

          for (let innerVal of innerObjectValues) {
            if (typeof innerVal === 'string') {
              if (
                innerVal.toLocaleLowerCase().includes(searchQuery.toLowerCase())
              ) {
                updatedArray.push(originalData)
                flag = 1
                break;
              }
            } else if (typeof innerVal === 'object' && innerVal) {
              let innerObjectValues1 = Object.values(innerVal)

              for (let innerVal1 of innerObjectValues1) {
                if (typeof innerVal1 === 'string') {
                  if (
                    innerVal1
                      .toLocaleLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    updatedArray.push(originalData)
                    flag = 1
                    break;
                  }
                }
              }
            }
          }
        }
        if (flag === 1) break;
      }
    }
    return updatedArray
  }

  const fetchList = async () => {
    const { data } = await getClaims()
    let claimData = data.data.map((claim) => {
      let companyEmp = null
      let claimantName = null
      if (claim.directClaim) {
        companyEmp = claim?.incident.partiesInvolved.find(
          ({ isCompanyEmployee }) => isCompanyEmployee,
        )
        claimantName = claim?.incident.partiesInvolved.find(
          ({ isCompanyEmployee }) => !isCompanyEmployee,
        )
        const { personDetails: { lastName = '', firstName = '' } = {} } =
          companyEmp || {}

        claim.incident.employeeFullName = `${lastName ? lastName : ''} ${firstName}`
        claim.incident.claimantName = `${claimantName?.personDetails?.lastName ? claimantName?.personDetails?.lastName : ''} ${claimantName?.personDetails?.firstName}`
      } else {
        companyEmp =
          claim?.incidents[0]?.incidentDetails?.partiesInvolved.find(
            ({ isCompanyEmployee }) => isCompanyEmployee,
          )
        claimantName = claim?.incidents[0]?.incidentDetails?.partiesInvolved.find(
          ({ isCompanyEmployee }) => !isCompanyEmployee,
        )
        const { personDetails: { lastName = '', firstName = '' } = {} } =
          companyEmp || {}

        claim.incidents[0].claimants[0].employeeFullName = `${lastName ? lastName : ''} ${firstName}`
        claim.incidents[0].claimants[0].claimantName = `${claimantName?.personDetails?.lastName ? claimantName?.personDetails?.lastName : ''} ${claimantName?.personDetails?.firstName}`
      }

      return claim
    })
    dispatch(saveClaimInfo(data.data))
    setData(claimData)
    setIsLoading(false)
  }
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }
  const handlePageChange = (page) => {
    setCurrPage(page)
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
    activeClaimsTabId(tab)
    tab === '1' && setCheckedRecords([])
  }

  const handleDelete = async (item) => {
    await deleteClaim(item._id, item)
    await fetchList()
  }

  const hendleBulkDelete = async (ids) => {
    await deleteBlukClaim(ids)
    await fetchList()
  }

  const handleEditModalClose = () => {
    setEditModalData({ isOpen: false, data: null })
    history.replace(`/incidents`)
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (data.length !== checkedRecords.length) {
        data.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }
      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukClaim(params)
        setShowDeleteButton(false)
        closeBulkDel()
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      } else {
        const { data } = await deleteClaim(claim._id, claim)
        setDeleteOne(false)
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    setClaim({})
    await fetchList()
  }

  if (isLoading) return (<div style={{ marginTop: "120px" }} ><Loader loading={isLoading} /></div>)

  return (
    <>
      <div className="px-4">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div
                className="tab-pane fade show active"
                id="Incidents"
                role="tabpanel"
                style={{ marginLeft: '90px' }}
                aria-labelledby="nav-employees"
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {/* <li
                    className="nav-item"
                    role="presentation"
                    style={{ cursor: 'pointer' }}
                  >
                    <a
                      className={
                        'nav-link bdr-0 ' + (activeTab === '1' ? 'active' : '')
                      }
                      id="home-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => {
                        toggle('1')
                      }}
                    >
                      Claim Dashboard
                    </a>
                  </li> */}
                  <li
                    className="nav-item"
                    role="presentation"
                    style={{ cursor: 'pointer' }}
                  >
                    <a
                      className={
                        'nav-link bdr-0 ' + (activeTab === '2' ? 'active' : '')
                      }
                      id="profile-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => {
                        toggle('2')
                      }}
                    >
                      Claims
                    </a>
                  </li>
                </ul>
              </div>
              <div className="container-fluid">
                <TabContent activeTab={activeTab}>
                  <hr className="claimhub_border" />
                  {/* <TabPane tabId="1">
                    <h1>Dashboard</h1>
                    <ClaimsDashboard
                      onViewClaimsClick={() => setActiveTab('2')}
                    />
                  </TabPane> */}
                  <TabPane tabId="2">
                    <div>
                      {getPageData() || ''}
                      <Table
                        columns={columns}
                        data={claims.filter((claim) => {
                          if (claim?.incidents[0]?.claimants[0]) {
                            return getClaimFilterData(claim)
                          } else {
                            return getClaimFilterData(claim)
                          }
                        })}
                        className={'user-mang assetmanag'}
                        sortColumn={sortColumn}
                        onSort={handleSort}
                        checkedItems={checkedRecords}
                        checkboxClick={handleElementCheckboxClicked}
                      />
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
        <IncidentConvertToClaimModal
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isOpen={editModalData.isOpen}
          context={'claim'}
          data={editModalData.data}
          onClose={handleEditModalClose}
        />
        <ClaimFinancialModal
          isOpen={claimModal.isOpen}
          data={claimModal.data}
          onClose={() => setClaimModal({ isOpen: false, data: null })}
        />
      </div>
      <ConfirmationModal
        name={
          checkedRecords.length > 0
            ? `Claims`
            : `ClaimId ${claim?.claimId}` || ''
        }
        isOpen={
          Object.keys(claim || {}).length > 0 &&
          (checkedRecords.length > 0 || deleteOne)
        }
        // isOpen={checkedRecords.length > 0 || deleteOne}
        onClose={() => {
          setClaim({})
          setShowDeleteButton(false)
          closeBulkDel()
          setDeleteOne(false)
          setCheckedRecords([])
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default ClaimsList
