import React from 'react'
import { Modal } from 'reactstrap'
import ConvertToclaim from '../claims/convertToClaim'

import { ReactComponent as Close } from '../../svgs/close.svg'
import DirectClaim from '../claims/DirectClaim/DirectClaim'

function IncidentConvertToClaimModal({
  isOpen,
  data,
  onClose,
  context,
  title,
  activeStep,
  setActiveStep,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdrop={'static'}
      style={{ minWidth: '65vw' }}
      className={'modal-dialog-top modal-lg modal-right posi-rel'}
    >
      <div>
        <a onClick={onClose} className="close close-left" aria-label="Close">
          <Close />
        </a>
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="col-md-12 p-0 col-h">
            <div className="modal-body p-0">
              {(!data?.directClaim || activeStep > 2) ? (
                <ConvertToclaim
                  match={{ params: { id: data?.incidentId } }}
                  data={context !== 'incident' ? data : null}
                  location={{ search: { claimId: data?.claimId } }}
                  context={context}
                  title={title}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  onClose={onClose}
                  directClaim={false}
                />
              ) : (
                
                <DirectClaim data={data} onClose={onClose}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default IncidentConvertToClaimModal
{/* <IncidentForm
                  data={data}
                  match={null}
                  title={'Edit Claim'}
                  onClose={onClose}
                  context="claim"
                  activeStep={activeStep}
                  setClaimData={(values) => setClaimData(values)}
                  setActiveStep={setActiveStep}
                /> */}