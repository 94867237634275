import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { timeNowFormat } from '../../configs/utils'
import { useCommentAccess } from '../../hooks/useGetPermission'

const NarrativeView = ({
  c,
  dropdownOpen,
  setdropdownOpen,
  onEdit,
  onDelete,
}) => (
  <div className="comment-section border-bottom">
    <div>
      <div className="img-box active mb-1">
        <div className="pro-box d-flex mr-3">
          {c.narrativeBy ? c.narrativeBy.split(' ')[0]?.split('')[0] : null}
          {c.narrativeBy ? c.narrativeBy.split(' ')[1]?.split('')[0] : null}
        </div>
        <div className="r-box">
          <p>{c.narrative}</p>
          <p
            title={moment
              .utc(c.narrativeDate)
              .local()
              .format('YYYY-MM-DD HH:mm:ss')}
          >
            {timeNowFormat(c.narrativeDate)}
          </p>
        </div>
        <div className="comment-escl">
          <Dropdown isOpen={dropdownOpen} className="custom-dropdown">
            <DropdownToggle
              caret
              onClick={() => {
                setdropdownOpen(!dropdownOpen)
              }}
            >
              <i className="fas fa-ellipsis-v" aria-hidden="true"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => onEdit(c.narrative)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={onDelete}>Delete</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  </div>
)

export default function IncidentNarrativeView({
  handleSubmit,
  setNarrative,
  context,
  values,
  setFieldValue,
  showActionButtons = true,
  onNavigate
}) {
  const [narratives, setNarratives] = useState([])
  const [narrativeInput, setNarrativeInput] = useState([])
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const [editNarrativeIndex, setEditNarrativeIndex] = useState('')

  const module = useSelector((state) => state.sideNavModule)
  const mountedRef = useRef(true)
  const currentUser = useSelector((state) => state.currentUser)

  const init = async () => {
    if ((values?.incidentNarrative?.length ?? 0) > 0) {
      if (values.incidentNarrative !== '') {
        setNarratives(values.incidentNarrative)
      }
    }
  }

  useEffect(() => {
    mountedRef.current = true
    init()
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleAddNarrative = () => {
    const currentDate = new Date()
    if (narrativeInput.length > 0) {
      if (editNarrativeIndex !== '') {
        const narrative = {
          narrative: narrativeInput,
          narrativeDate: currentDate,
          narrativeBy: currentUser.firstName + ' ' + currentUser.lastName,
        }
        narratives[editNarrativeIndex] = narrative
        setNarratives([...narratives])
        setNarrativeInput('')
        setEditNarrativeIndex('')
      } else {
        const narrative = {
          narrative: narrativeInput,
          narrativeDate: currentDate,
          narrativeBy: currentUser.firstName + ' ' + currentUser.lastName,
        }
        setNarratives([...narratives, narrative])
        setNarrativeInput('')
      }
    }
  }

  const handleNarrativeSubmit = () => {
    setFieldValue('incidentNarrative', narratives)
    handleSubmit()
  }

  return (
    <div>
      <div className="img-box" style={{ marginBottom: '10px' }}>
        <div className="r-box1 plus1 posi-rel d-flex">
          <input
            value={narrativeInput}
            onChange={(e) => setNarrativeInput(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Write a Narrative..."
          />
          <button
            className="btn btn-warning custom-btn ml-2"
            type="submit"
            onClick={handleAddNarrative}
          >
            Add
          </button>
        </div>
      </div>
      <div
        className="pb-5 overflow-auto"
        id="scroller"
        style={{ height: '320px', marginBottom: '10px' }}
      >
        {narratives.length > 0 &&
          narratives?.map((c, index) => (
            <NarrativeView
              c={c}
              key={index}
              dropdownOpen={dropdownOpen}
              setdropdownOpen={() => setdropdownOpen(!dropdownOpen)}
              onEdit={(narrative) => {
                setNarrativeInput(narrative)
                setEditNarrativeIndex(index)
                setdropdownOpen(false)
              }}
              onDelete={() => {
                const a = narratives.filter((narrative, i) => i != index)
                setNarratives(a)
                setdropdownOpen(false)
              }}
            />
          ))}
      </div>
      {
        showActionButtons && (<div className="d-flex justify-content-between" style={{ width: '100%' }}>
          <button
            className="btn text-primary pl-0"
            // onClick={() => setNarrative()}
            onClick={() => {
              onNavigate(3)
              setNarrative()
            }}
          >
            <i
              className={'fas fa-chevron-left'}
              aria-hidden={true}
              style={{ paddingRight: '4px' }}
            >
              {' '}
            </i>
            Back
          </button>
          <button
            type="submit"
            className="btn btn-primary pull-right"
            onClick={handleNarrativeSubmit}
          >
            {context === 'claim' ? 'Save & Continue' : 'Save'}
          </button>
        </div>)
      }
    </div>
  )
}
