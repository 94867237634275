import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/property`

export function getProperties() {
  return http.get(`${apiEndpoint}/get-property-list`)
}
export function exportProperties() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getPropertyByIdAndType(id, type) {
  return http.get(`${apiEndpoint}/property-value-change/${id}`, {
    params: { id: type },
  })
}

export function getPropertiesByType(type) {
  return http.get(`${apiEndpoint}/get-property-by-filter`, {
    params: { propertyType: type },
  })
}

export function getProperty(id) {
  return http.get(`${apiEndpoint}/get-property/${id}`)
}

export function updateProperty(id, property) {
  return http.put(`${apiEndpoint}/update-property/${id}`, property)
}

export function deleteProperty(id, property) {
  return http.put(`${apiEndpoint}/delete-property/${id}`, property)
}

export function deleteBlukProperty(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}


export function saveProperty(property) {
  // const date = new Date()
  // const propertyId = `PR-${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...property })
}

export function getTotalInsuranceValueOfProperty() {
  return http.get(`${apiEndpoint}/get-total-insurance-value`)
}
