import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import {
  useCommentAccess
} from '../../hooks/useGetPermission'
import { transformPhoneNumber } from '../../pages/incident/utils'

export default function PublicIncidentRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const [activeCardId, setActiveCardId] = useState('parent')
  const data = useSelector((state) => state.sideNavData)
  const isMedicalTreatment = data?.incidentDetails?.isMedicalTreatment?.toString() === "true"
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const vehicularIncidentLabel = data?.vehicleNature !== "non-vehicular"
    ? '(Vehicular Incident)'
    : '(Non-Vehicular Incident)'
  const dateOfIncident = useMemo(() => {
    if (data?.incidentDetails?.dateOfIncident) {
      return moment(data.incidentDetails.dateOfIncident).format('MM/DD/YYYY')
    }
    return 'N/A'
  }, [data])

  const timeOfIncident = useMemo(() => {
    if (data?.incidentDetails?.time) {
      return `${data?.incidentDetails?.time} ${moment.tz(data?.timezone).zoneAbbr()}`
    }
    return "N/A"
  }, [data])

  // const deletePermission = useGetPermission('public-incident', 'DELETE')
  // const editPermission = useGetPermission('public-incident', 'UPDATE')
  const addCommentPermission = useCommentAccess('public-incident', 'CREATE')

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  let getAddress = (address) => {
    let addressString = ''
    if (address) {
      addressString += address.name ? address.name + ', ' : ''
      addressString += address.line1 ? address.line1 + ', ' : ''
      addressString += '\n'
      addressString += address.line2 ? address.line2 + ', ' : ''
      addressString += address.city ? address.city : ''
      addressString += '\n'
      addressString += address.state ? address.state + ', ' : ''
      addressString += address.country ? address.country + '-' : ''
      addressString += address.pinCode ? address.pinCode : ''
    }

    return addressString
  }

  const getUserAddressString = () => {
    const userDetails = data.userDetails
    if (userDetails.address) {
      return `${userDetails.address}, ${userDetails.city}, ${userDetails.state}, ${userDetails.country} - ${userDetails.zip}`
    }
    return ""
  }

  let cardHeaderClicked = (sId) => {
    setActiveCardId(sId)
  }

  let getVehicleInvolvedView = () => {
    let vehicleInvolvedViews = []

    data?.vehiclesInvolved.forEach(function (vehicleInvolved, index) {
      // const driverDetails = data?.userDetails
      const vehicleDetails = vehicleInvolved?.vehicleDetails

      vehicleInvolvedViews.push(
        <div
          // id="faq9"
          // className="collapse"
          // aria-labelledby="faqhead9"
          // data-parent="#faq"
          key={index}
        >
          <div className=" ">
            <div className="parties bg-white border mt-4 p-3 mb-3">
              {/* <h6 className="text-primery">
                {driverDetails?.firstName + ' ' + driverDetails?.lastName}
              </h6>
              <p className="mb-2">{driverDetails?.contactNumber || 'N/A'}</p>
              <p className="mb-2">
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
                {getUserAddressString()}
              </p> */}
              <h6 className="text-primery">
                {
                  vehicleDetails?.licensePlate
                }
              </h6>
              <p className="mb-2">{vehicleDetails?.make ?? "-"} {vehicleDetails?.model ?? "-"} - {vehicleDetails?.yearOfManufacture ? moment(vehicleDetails?.yearOfManufacture).format("yyyy") : "N/A"}</p>
              <p className="text-primery m-0 fw-bold">Damages</p>
              <p className="mb-2">{(Array.isArray(vehicleDetails?.vehicleDamageParts) && vehicleDetails.vehicleDamageParts.join(", ").trim().length !== 0) ? vehicleDetails.vehicleDamageParts.join(", ") : "N/A"}</p>

              <p className="text-primery m-0 fw-bold">Mileage</p>
              <p className="mb-2">{vehicleDetails?.mileage ?? "N/A"}</p>

              <p className="text-primery m-0 fw-bold">Damage State</p>
              <p className="mb-2">{vehicleDetails?.damageState ?? "N/A"}</p>

              <span className="mr-2 badge badge-pill btn btn-outline-primary pt-1">
                {vehicleDetails?.vehicleType === "company" ? 'Company' : 'Third Party'} Vehicle
              </span>
              {
                (vehicleDetails?.vehicleType === "company" && vehicleDetails?.companyVehicleType) && (<span className="mr-2 badge badge-pill btn btn-outline-primary pt-1">
                  {vehicleDetails?.companyVehicleType} Vehicle
                </span>)
              }
              <span className="badge badge-pill btn btn-outline-primary pt-1 mr-2">
                VIN: {vehicleDetails?.vin || 'N/A'}
              </span>
              <span className="badge badge-pill btn btn-outline-primary pt-1 mr-2">
                VAN: {vehicleDetails?.van || 'N/A'}
              </span>
              {/* <span className="badge badge-pill btn btn-outline-primary pt-1">
                License Number
              </span> */}
            </div>
          </div>
        </div>,
      )
    })

    return vehicleInvolvedViews
  }

  let getPartiesInvolvedView = () => {
    let partiesInvolvedViews = []

    data?.partiesInvolved.forEach(function (partyInvolved, index) {
      const personal = partyInvolved.personDetails

      partiesInvolvedViews.push(
        <div
          id="faq2"
          className="collapse"
          aria-labelledby="faqhead2"
          data-parent="#faq"
          key={index}
        >
          <div className=" ">
            <div className="parties bg-white border mt-4 p-3 mb-3">
              <h6 className="text-primery">
                {personal.firstName + ' ' + personal.lastName}
              </h6>
              <div className="d-flex ">
                <div>
                  <i className="fas fa-mobile-alt mr-2" aria-hidden="true"></i>
                </div>
                <div>
                  {' '}
                  {personal.contactNumber ? transformPhoneNumber(personal.contactNumber) : 'N/A'}
                </div>
              </div>

              <p className="mb-2">
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
                {getAddress(personal.address)}
              </p>
              <span className="badge badge-pill btn btn-outline-primary">
                {partyInvolved.isCompanyEmployee
                  ? 'Company Employee'
                  : 'Not A Company Employee'}
              </span>
              <span className="badge badge-pill btn btn-outline-warning">
                {partyInvolved.injuryDetails !== null
                  ? 'Has Injury'
                  : 'No Injury'}
              </span>
              <span className="badge badge-pill btn btn-outline-danger">
                {partyInvolved.injuryDetails.painLevel || 'N/A'}
              </span>
            </div>
          </div>
        </div>,
      )
    })

    return partiesInvolvedViews
  }

  let getThirdPartyCardView = () => {
    const thirdParty = data?.incidentDetails?.thirdPartyDetails

    if (!thirdParty) {
      return <p>Nothing to show here!</p>
    }

    return (
      <div>
        <div className="parties bg-white border mt-4 p-3 mb-3">
          <h6 className="text-primery">{thirdParty.thirdPartyName}</h6>
          {thirdParty.thirdPartyContactNumber && (
            <div>
              <label className="incident-text">
                Contact Number
                <span>{thirdParty.thirdPartyContactNumber ? transformPhoneNumber(thirdParty.thirdPartyContactNumber) : 'N/A'}</span>
              </label>
            </div>
          )}
          {
            data?.incidentDetails?.thirdPartyLeaves && (<p>Third Party left/refused to give info</p>)
          }
        </div>
      </div>
    )
  }

  let getInjuryDetailsCardView = () => {
    const details = data?.incidentDetails

  }

  let getWitnessCardView = () => {
    let witnessViews = []

    data?.incidentDetails?.witnesses.forEach(function (os, index) {
      const name = os?.name?.split(" ") ?? ["N/A"]
      witnessViews.push(
        <div key={index}>
          <div className="parties bg-white border mt-4 p-3 mb-3">
            <h6 className="text-primery">{name.shift() + ' ' + name.join(" ")}</h6>
            {os.contactNumber && (
              <div>
                <label className="incident-text">
                  Contact Number
                  <span>{os?.contactNumber ? transformPhoneNumber(os?.contactNumber) : 'N/A'}</span>
                </label>
              </div>
            )}
          </div>
        </div>,
      )
    })

    return witnessViews
  }

  // let getPropertiesInvolvedView = () => {
  //   let propertiesInvolvedViews = []

  //   data?.propertiesInvolved.forEach(function (propertyInvolved, index) {
  //     const propertyDetails = propertyInvolved.propertyDetails
  //     const propertyDamages = propertyInvolved.propertyDamages
  //     propertiesInvolvedViews.push(
  //       <div
  //         id="faq4"
  //         className="collapse"
  //         aria-labelledby="faqhead4"
  //         data-parent="#faq"
  //         key={index}
  //       >
  //         <div className="">
  //           <div className="parties bg-white p-3 mb-3 mx-2 mt-4 claim-taken-view">
  //             <h6 className="text-primery">{propertyDetails.name || 'N/A'}</h6>
  //             <div className="mb-2 d-flex">
  //               <div>
  //                 <i
  //                   className="fas fa-map-marker-alt mr-2"
  //                   aria-hidden="true"
  //                 ></i>
  //               </div>
  //               <div>{getAddress(propertyDetails.address)}</div>
  //             </div>
  //             {propertyDamages?.length > 0 && (
  //               <div className="my-2">
  //                 <p className="text-primary mt-2 mb-2">Damage Details</p>
  //                 <div className="m-2">
  //                   {propertyDamages.map((propertyDamage, index) => (
  //                     <span
  //                       key={`${index}-propertyDamage`}
  //                       className="badge badge-light p-2 text-primary mr-2"
  //                     >
  //                       {propertyDamage}
  //                     </span>
  //                   ))}
  //                 </div>
  //               </div>
  //             )}
  //             <span className="badge badge-pill btn btn-outline-primary">
  //               Company Employee
  //             </span>
  //             <span className="badge badge-pill btn btn-outline-warning ml-2">
  //               {propertyDetails.propertyType || 'N/A'}
  //             </span>
  //           </div>
  //         </div>
  //       </div>,
  //     )
  //   })

  //   return propertiesInvolvedViews
  // }

  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Date of Loss"
                data-original-title={moment(data?.incidentDetails?.dateOfIncident).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {data?.incidentDetails?.dateOfIncident ? moment(data?.incidentDetails?.dateOfIncident).format('MMM') : moment(data?.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {data?.incidentDetails?.dateOfIncident ? moment(data?.incidentDetails?.dateOfIncident).format('YYYY') : moment(data?.created).format('YYYY')}
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.publicIncidentId || 'N/A'}</div>
                {data?.clientId || 'N/A'}
              </span>
              <p className="mt-3">{vehicularIncidentLabel || 'N/A'}</p>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <a
                      className="text-dark"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.829"
                          height="17.828"
                          viewBox="0 0 17.829 17.828"
                        >
                          <g
                            id="Group_9756"
                            data-name="Group 9756"
                            transform="translate(-4013.659 13.151)"
                          >
                            <path
                              id="path1419"
                              d="M-1479.2-17.087l15-15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              id="path1421"
                              d="M-1479.2-32.087l15,15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li>
              </nav>
            </div>
          </div>

          <div className="pull-right pb-2">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}
            {/* {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
            )} */}
            {/* {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            )} */}
          </div>
        </div>

        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div
                className="accordion"
                id="faq"
                style={{ paddingBottom: '20px' }}
              >
                <div className="card">
                  <div
                    className="card-header1"
                    id="faqhead1"
                    onClick={cardHeaderClicked.bind(this, 'parent')}
                  >
                    <a
                      href="#"
                      className={
                        'btn btn-header-link ' +
                        (activeCardId !== 'parent' ? 'collapsed' : '')
                      }
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Employee Details
                    </a>
                  </div>
                  <div
                    id="faq1"
                    className="collapse show pt-3"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Employee First Name
                          <span>
                            {_.map(
                              (data?.userDetails?.firstName || ' ')?.split(' '),
                              _.capitalize,
                            ).join(' ')}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Employee  Last Name
                          <span>
                            {_.map(
                              (data?.userDetails?.lastName || ' ')?.split(' '),
                              _.capitalize,
                            ).join(' ')}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          Employee Email
                          <span>{data?.userDetails?.email || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Employee Contact Number
                          <span>{data?.userDetails?.contactNumber ? transformPhoneNumber(data.userDetails.contactNumber) : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Company Name
                          <span>{data?.clientId || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          FICO Score
                          <span>{data?.userDetails?.ficoScore || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Job Position/Title
                          <span>{data?.userDetails?.jobTitle || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Date of Birth
                          <span>
                            {data?.userDetails?.dateOfBirth ? moment(data.userDetails.dateOfBirth).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Driver Address
                          <span>{getAddress({
                            line1: data?.userDetails?.address,
                            city: data?.userDetails?.city,
                            state: data?.userDetails?.state,
                            country: data?.userDetails?.country,
                            pinCode: data?.userDetails?.zip
                          })}</span>
                          {/* <span>{data?.incidentDetails?.location ?? "N/A"}</span> */}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Station Code/ Job Site
                          <span>{data?.userDetails?.jobSite || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Shift Start Time
                          <span>{data?.shiftStartTime && data?.shiftStartTime !== "" ? data?.shiftStartTime : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          IP Address
                          <span>{data?.ipAddress ?? 'N/A'}</span>
                        </label>
                      </div>
                      {/* <div className="col-md-6">
                        <label className="incident-text">
                          Reported
                          <span>{data?.supervisorDetails?.notificationMethod || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Last changed date
                          <span>
                            {data?.modified ? moment(data?.modified).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* Incident Details */}
                <div className="card">
                  <div className="card-header1" id="incidentDetails">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq10"
                      aria-expanded="true"
                      aria-controls="faq10"
                    >
                      Incident Details
                    </a>
                  </div>
                  <div
                    id="faq10"
                    className="collapse"
                    aria-labelledby="incidentDetails"
                    data-parent="#faq"
                  >
                    <div className='row py-2'>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Date of Incident
                          <span>{dateOfIncident}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Time of Incident
                          <span>{timeOfIncident}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Location where the incident happened
                          <span>{data?.incidentDetails?.location || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          What was employee doing when the incident happened
                          <span>{data?.incidentDetails?.employeeActivity || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Police Report taken?
                          <span>{data?.incidentDetails?.policeCalled?.toString() === "true" ? "Yes" : "No"}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Police Report Number
                          <span>{data?.incidentDetails?.policeReportDetails?.policeReportNumber || "N/A"}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Jurisdiction (County)
                          <span>{data?.incidentDetails?.policeReportDetails?.policeReportcountry || "N/A"}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Incident Description
                          <span>{data?.incidentDetails?.incidentDescription || "N/A"}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Object/Substance which directly injured or exposed the employee?
                          <span>{data?.incidentDetails?.substance || "N/A"}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Describe the alleged injury
                          <span>{data?.incidentDetails?.allegedInjury || "N/A"}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Driver statement
                          <span>{data?.incidentDetails?.driverStatement || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Unsafe conditions
                          <span>{data?.incidentDetails?.unsafeCondition || 'N/A'}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Supervisor Details */}
                {data?.managerFormDetails && (
                  <div className="card">
                    <div className="card-header1" id="supervisorData">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq19"
                        aria-expanded="true"
                        aria-controls="faq19"
                      >
                        Supervisor Details
                      </a>
                    </div>
                    <div
                      id="faq19"
                      className="collapse"
                      aria-labelledby="supervisorData"
                      data-parent="#faq"
                    >
                      <div className='row py-2'>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Supervisor Name
                            <span>{data?.managerFormDetails?.supervisorName || data?.supervisorDetails?.name || 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Supervisor Email
                            <span>{data?.managerFormDetails?.supervisorEmail || data?.supervisorDetails?.email || 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Contact Number
                            <span>{(data?.managerFormDetails?.supervisorContactNumber || data?.supervisorDetails?.contactNumber) ? transformPhoneNumber(data?.managerFormDetails?.supervisorContactNumber || data?.supervisorDetails?.contactNumber) : 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Date Supervisor Notified
                            <span>{(data?.managerFormDetails?.dateOfReported || data?.supervisorDetails?.notifiedDate) ? moment(data?.managerFormDetails?.dateOfReported || data?.supervisorDetails?.notifiedDate).tz(data?.managerFormDetails?.tz ?? moment.tz.guess()).format('MM/DD/YYYY') : 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Method Supervisor Notified
                            <span>{data?.supervisorDetails?.notificationMethod || 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-12">
                          <label className="incident-text">
                            Supervisor Narrative
                            <span>{data?.managerFormDetails?.incidentDescription || 'N/A'}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Parties Involved */}
                {data?.partiesInvolved?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'accident')}
                  >
                    <div className="card-header1" id="faqhead2">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'accident' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Parties Involved
                      </a>
                    </div>
                    <div
                      id="faq2"
                      className="collapse"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      {getPartiesInvolvedView()}
                    </div>
                  </div>
                )}

                {/* Vehicles Involved */}
                {data?.vehicleNature !== "non-vehicular" && data?.vehiclesInvolved?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="vehiclesInvolved">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq9"
                        aria-expanded="true"
                        aria-controls="faq9"
                      >
                        Vehicle Involved
                      </a>
                    </div>
                    <div
                      id="faq9"
                      className="collapse"
                      aria-labelledby="vehiclesInvolved"
                      data-parent="#faq"
                    >
                      {getVehicleInvolvedView()}
                    </div>
                  </div>
                )}

                {/* Properties InvolvedView */}
                {/* {data?.propertiesInvolved?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead3">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="true"
                        aria-controls="faq4"
                      >
                        Property Damage Details
                      </a>
                    </div>
                    <div
                      id="faq4"
                      className="collapse"
                      aria-labelledby="faqhead4"
                      data-parent="#faq"
                    >
                      {getPropertiesInvolvedView()}
                    </div>
                  </div>
                )} */}

                {Boolean(data?.incidentDetails?.hasWitnesses ?? false) && Array.isArray(data?.incidentDetails?.witnesses) && data?.incidentDetails?.witnesses?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'witness')}
                  >
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'witness' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq5"
                        aria-expanded="true"
                        aria-controls="faq5"
                      >
                        Witnesses
                      </a>
                    </div>
                    <div
                      id="faq5"
                      className="collapse"
                      aria-labelledby="faqhead5"
                      data-parent="#faq"
                    >
                      {getWitnessCardView()}
                    </div>
                  </div>
                )}
                {Boolean(data?.incidentDetails?.isThirdPartyPresent ?? false) && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'thirdParty')}
                  >
                    <div className="card-header1" id="faqhead7">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'thirdParty' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq7"
                        aria-expanded="true"
                        aria-controls="faq7"
                      >
                        Parties Involved
                      </a>
                    </div>
                    <div
                      id="faq7"
                      className="collapse"
                      aria-labelledby="faqhead7"
                      data-parent="#faq"
                    >
                      {getThirdPartyCardView()}
                    </div>
                  </div>
                )}
                {data?.incidentDetails?.isInjury?.toString() === "true" && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'isInjury')}
                  >
                    <div className="card-header1" id="faqhead8">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'isInjury' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq8"
                        aria-expanded="true"
                        aria-controls="faq8"
                      >
                        Injury Details
                      </a>
                    </div>
                    <div
                      id="faq8"
                      className="collapse"
                      aria-labelledby="faqhead8"
                      data-parent="#faq"
                    >
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <label className="incident-text">
                            Nature Of Injury
                            <span>{data?.incidentDetails?.natureOfInjury || 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Ambulance Called
                            <span>{data?.incidentDetails?.isAmbulance || 'N/A'}</span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Feels pain in
                            <span>
                              {data?.humanParts?.map(e => _.capitalize(e)).join(", ")}
                            </span>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="incident-text">
                            Pain Level
                            <span>
                              {
                                data?.incidentDetails?.painLevel ?? "N/A"
                              }
                            </span>
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label className="incident-text">
                            Medical Treatment Required
                            <span>{isMedicalTreatment ? "Yes" : "No"}</span>
                          </label>
                        </div>
                        {
                          isMedicalTreatment && (<div className="col-md-6">
                            <label className="incident-text">
                              Medical Treatment
                              <span>{data?.incidentDetails?.medicalTreatmentType ? data.incidentDetails.medicalTreatmentType?.map(e => _.capitalize(e)).join(", ") : 'N/A'}</span>
                            </label>
                          </div>)
                        }
                        {
                          isMedicalTreatment && (<div className="col-md-6">
                            <label className="incident-text">
                              Medical Provider
                              <span>{data?.incidentDetails?.medicalTreatmentProviderInfo || 'N/A'}</span>
                            </label>
                          </div>)
                        }
                        {
                          !isMedicalTreatment && (<>
                            <div className="col-md-6">
                              <label className="incident-text">
                                Medical Treatment declined by
                                <span>{data?.incidentDetails?.medicalDeclinationDetails?.empName || 'N/A'}</span>
                              </label>
                            </div>
                          </>)
                        }
                        <div className="col-md-6">
                          <label className="incident-text">
                            Public Member Injured?
                            <span>{data?.incidentDetails?.publicMemberInjured?.toString() === "true" ? "Yes" : "No"}</span>
                          </label>
                        </div>
                        {
                          data?.incidentDetails?.publicMemberInjured?.toString() === "true" && (<div className="col-md-12">
                            <label className="incident-text">
                              Public Member Injury Details
                              <span>{data?.incidentDetails?.publicMemberInjuredDetails && data?.incidentDetails?.publicMemberInjuredDetails !== "" ? data?.incidentDetails?.publicMemberInjuredDetails : "N/A"}</span>
                            </label>
                          </div>)
                        }
                        {/* <div className="col-md-6">
                        <label className="incident-text">
                          Reported
                          <span>{data?.supervisorDetails?.notificationMethod || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Last changed date
                          <span>
                            {data?.modified ? moment(data?.modified).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div> */}
                      </div>
                    </div>
                  </div>
                )}
                {/* 
                {data?.incidentDetails?.driverStatement && (
                  <div className="card">
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Incident Narrative
                      </a>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      <div
                        id="faq6"
                        // className="collapse show"
                        aria-labelledby="faqhead6"
                        data-parent="#faq"
                      >
                        <div className="parties bg-white p-2 mb-2 mx-1 mt-3 claim-taken-view" >
                          <div className="img-box mb-1">
                            <div className="pro-box d-flex mr-3">
                              {data?.userDetails?.firstName
                                ? data?.userDetails?.firstName?.split(' ')?.[0]?.split('')?.[0]
                                : null}
                              {data?.userDetails?.lastName
                                ? data?.userDetails?.lastName?.split(' ')?.[0]?.split('')?.[0]
                                : null}
                            </div>
                            <div className="">
                              <p className="mb-0">{data?.incidentDetails?.driverStatement || 'N/A'}</p>
                              {
                                data?.incidentDetails?.dateOfIncident && (<p
                                  className="mb-0"
                                  title={moment
                                    .utc(data?.incidentDetails?.dateOfIncident)
                                    .local()
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                >
                                  {data?.incidentDetails?.dateOfIncident ? timeNowFormat(data?.incidentDetails?.dateOfIncident) : 'N/A'}
                                </p>)
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
