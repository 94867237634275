// export const allInsuranceCarriers = [
//     {
//         value: 'Accident Insurance Fund',
//         key: 'Accident Insurance Fund',
//     },
//     {
//         value: 'Aflac',
//         key: 'Aflac',
//     },
//     {
//         value: 'Allianz',
//         key: 'Allianz',
//     },
//     {
//         value: 'AllState Insurance Company',
//         key: 'AllState Insurance Company',
//     },
//     {
//         value: 'Allstate',
//         key: 'Allstate',
//     },
//     {
//         value: 'Amtrust Insurance',
//         key: 'Amtrust Insurance',
//     },
//     {
//         value: 'ARCH Insurance Company',
//         key: 'ARCH Insurance Company',
//     },
//     {
//         value: 'Atlas General Insurance',
//         key: 'Atlas General Insurance',
//     },
//     {
//         value: 'Berkley',
//         key: 'Berkley',
//     },
//     {
//         value: 'Berkley Insurance Company',
//         key: 'Berkley Insurance Company',
//     },
//     {
//         value: 'Berkshire Hathaway',
//         key: 'Berkshire Hathaway',
//     },
//     {
//         value: 'Bridgefield Casulaty',
//         key: 'Bridgefield Casulaty',
//     },
//     {
//         value: 'Bridgefield Casualty Insurance Company',
//         key: 'Bridgefield Casualty Insurance Company',
//     },
//     {
//         value: 'Brown & Brown Insurance',
//         key: 'Brown&Brown Insurance',
//     },
//     {
//         value: 'Chesapeake Employers Insurance',
//         key: 'Chesapeake Employers Insurance',
//     },
//     {
//         value: 'Chubb Insurance',
//         key: 'Chubb Insurance',
//     },
//     {
//         value: 'Cimarron Insurance Company',
//         key: 'Cimarron Insurance Company',
//     },
//     {
//         value: 'Cincinnati Insurance',
//         key: 'Cincinnati Insurance',
//     },
//     {
//         value: 'CNA Insurance',
//         key: 'CNA Insurance',
//     },
//     {
//         value: 'Comp 360',
//         key: 'Comp 360',
//     },
//     {
//         value: 'Coverys Preferred Professional Insurance',
//         key: 'Coverys Preferred Professional Insurance',
//     },
//     {
//         value: 'Equitable Holdings',
//         key: 'Equitable Holdings',
//     },
//     {
//         value: 'Erie Insurance',
//         key: 'Erie Insurance',
//     },
//     {
//         value: 'Everest Premier Insurance Company',
//         key: 'Everest Premier Insurance Company',
//     },
//     {
//         value: 'Fairmatic/ HDI Specialty Insurance',
//         key: 'Fairmatic/ HDI Specialty Insurance',
//     },
//     {
//         value: 'Falls Lake Insurance',
//         key: 'Falls Lake Insurance',
//     },
//     {
//         value: 'Fidelity National Financial',
//         key: 'Fidelity National Financial',
//     },
//     {
//         value: 'Gallagher',
//         key: 'Gallagher',
//     },
//     {
//         value: 'Globe Life',
//         key: 'Globe Life',
//     },
//     {
//         value: 'Guard Insurance Company',
//         key: 'Guard Insurance Company',
//     },
//     {
//         value: 'Hartford Insurance',
//         key: 'Hartford Insurance',
//     },
//     {
//         value: 'Key Risk',
//         key: 'Key Risk',
//     },
//     {
//         value: 'Loews',
//         key: 'Loews',
//     },
//     {
//         value: 'Markel',
//         key: 'Markel',
//     },
//     {
//         value: 'Marsh & McLennan',
//         key: 'Marsh&McLennan',
//     },
//     {
//         value: 'MEMIC Indemnity Company',
//         key: 'MEMIC Indemnity Company',
//     },
//     {
//         value: 'MetLife',
//         key: 'MetLife',
//     },
//     {
//         value: 'Midwestern Insurance Alliance',
//         key: 'Midwestern Insurance Alliance',
//     },
//     {
//         value: 'National Casualty Company',
//         key: 'National Casualty Company',
//     },
//     {
//         value: 'NYSIF',
//         key: 'NYSIF',
//     },
//     {
//         value: 'Old Republic Insurance',
//         key: 'Old Republic Insurance',
//     },
//     {
//         value: 'Omaha National Insurance',
//         key: 'Omaha National Insurance',
//     },
//     {
//         value: 'Others',
//         key: 'OTHERS',
//     },
//     {
//         value: 'Oxford Auto Insurance',
//         key: 'Oxford Auto Insurance',
//     },
//     {
//         value: 'Principal',
//         key: 'Principal',
//     },
//     {
//         value: 'Prudential Financial',
//         key: 'Prudential Financial',
//     },
//     {
//         value: 'Progressive',
//         key: 'Progressive',
//     },
//     {
//         value: 'Service American Peoplease',
//         key: 'Service American Peoplease',
//     },
//     {
//         value: 'Starstone National Insurance',
//         key: 'Starstone National Insurance',
//     },
//     {
//         value: 'State Compensation Insurance Fund',
//         key: 'State Compensation Insurance Fund',
//     },
//     {
//         value: 'Texas Mutual Insurance',
//         key: 'Texas Mutual Insurance',
//     },
//     {
//         value: 'Travelers',
//         key: 'Travelers',
//     },
//     {
//         value: 'United Wisconsin',
//         key: 'United Wisconsin',
//     },
//     {
//         value: 'Workecentric',
//         key: 'Workecentric',
//     },
// ]


export const allPolicyTypes = [
  {
    value: 'WC',
    key: 'WC',
  },
  {
    value: 'GL',
    key: 'GL',
  },
  {
    value: 'Auto',
    key: 'AUTO',
  },
  {
    value: 'Property',
    key: 'PROPERTY',
  },
  {
    value: 'Others',
    key: 'OTHERS',
  },
]

export const allInsuranceCarriers = [
    {
        "value": "Accident Insurance Fund",
        "key": "Accident Insurance Fund"
    },
    {
        "value": "Allianz",
        "key": "Allianz"
    },
    {
        "value": "AllState Insurance Company",
        "key": "AllState Insurance Company"
    },
    {
        "value": "Amtrust Insurance",
        "key": "Amtrust Insurance"
    },
    {
        "value": "Amwins",
        "key": "Amwins"
    },
    {
        "value": "ARCH Insurance Company",
        "key": "ARCH Insurance Company"
    },
    {
        "value": "Atlas General Insurance",
        "key": "Atlas General Insurance"
    },
    {
        "value": "Berkley Insurance Company",
        "key": "Berkley Insurance Company"
    },
    {
        "value": "Berkshire Hathaway",
        "key": "Berkshire Hathaway"
    },
    {
        "value": "Bridgefield Casualty Insurance Company",
        "key": "Bridgefield Casualty Insurance Company"
    },
    {
        "value": "Bridgefield Casulaty",
        "key": "Bridgefield Casulaty"
    },
    {
        "value": "Chesapeake Employers Insurance",
        "key": "Chesapeake Employers Insurance"
    },
    {
        "value": "Chubb Insurance",
        "key": "Chubb Insurance"
    },
    {
        "value": "Cimarron Insurance Company",
        "key": "Cimarron Insurance Company"
    },
    {
        "value": "Comp 360",
        "key": "Comp 360"
    },
    {
        "value": "Coverys Preferred Professional Insurance",
        "key": "Coverys Preferred Professional Insurance"
    },
    {
        "value": "Erie Insurance",
        "key": "Erie Insurance"
    },
    {
        "value": "Everest Premier Insurance Company",
        "key": "Everest Premier Insurance Company"
    },
    {
        "value": "Fairmatic/ HDI Specialty Insurance",
        "key": "Fairmatic/ HDI Specialty Insurance"
    },
    {
        "value": "Falls Lake Insurance",
        "key": "Falls Lake Insurance"
    },
    {
        "value": "Firemen's Insurance Company",
        "key": "Firemen's Insurance Company"
    },
    {
        "value": "Guard Insurance Company",
        "key": "Guard Insurance Company"
    },
    {
        "value": "Key Risk",
        "key": "Key Risk"
    },
    {
        "value": "MEMIC Indemnity Company",
        "key": "MEMIC Indemnity Company"
    },
    {
        "value": "Midwestern Insurance Alliance",
        "key": "Midwestern Insurance Alliance"
    },
    {
        "value": "Merchants Mutual Insurance Company",
        "key": "Merchants Mutual Insurance Company"
    },
    {
        "value": "Michigan Commercial Insurance Mutual",
        "key": "Michigan Commercial Insurance Mutual"
    },
    {
        "value": "National Casualty Company",
        "key": "National Casualty Company"
    },
    {
        "value": "NYSIF",
        "key": "NYSIF"
    },
    {
        "value": "Old Republic Insurance",
        "key": "Old Republic Insurance"
    },
    {
        "value": "Omaha National Insurance",
        "key": "Omaha National Insurance"
    },
    {
        "value": "Oxford Auto Insurance",
        "key": "Oxford Auto Insurance"
    },
    {
        "value": "Service American Peoplease",
        "key": "Service American Peoplease"
    },
    {
        "value": "Starstone National Insurance",
        "key": "Starstone National Insurance"
    },
    {
        "value": "State Compensation Insurance Fund",
        "key": "State Compensation Insurance Fund"
    },
    {
        "value": "Texas Mutual Insurance",
        "key": "Texas Mutual Insurance"
    },
    {
        "value": "United Wisconsin",
        "key": "United Wisconsin"
    },
    {
        "value": "Workecentric",
        "key": "Workecentric"
    },
    {
        "value": "Zurich Insurance",
        "key": "Zurich Insurance"
    },
    {
        value: 'Others',
        key: 'OTHERS',
    },
]