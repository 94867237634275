/* eslint-disable no-unused-vars */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { handleSideNavElementClicked } from '../../actions'
import { timeNowFormat } from '../../configs/utils'
import {
  useAttachmentAccess,
  useGetPermission,
} from '../../hooks/useGetPermission'
import { deleteAttachment, getAttachmentList } from '../../services/attachmentService'

export default function AttachmentRecordInfo(props) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const module = useSelector((state) => state.sideNavModule)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const [fullAttachments, setFullAttachments] = useState([])

  const readAttachments = useAttachmentAccess(module, 'READ')
  const deleteAttachments = useAttachmentAccess(module, 'DELETE')
  const downloadAttachments = useAttachmentAccess(module, 'DOWNLOAD')
  const copyLink = useAttachmentAccess(module, 'COPY_LINK')
  const attachments = data?.attachments || []

  const init = async () => {
    if (attachments?.length !== 0) {
      getAttachmentList(module, attachments).then((response) => {
        setFullAttachments(response.data.data)
      })
    }
  }
  
  useEffect(() => {
    init()
  }, [data])

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const getAttachmentViews = () => {
    let attachmentViews = []

    fullAttachments.forEach(function (attachment, index) {
      let xmlnsString = attachment.url
      // let xmlnsArray = xmlnsString.toString().split('/')
      let fileName = attachment.fileName

      let isFileImage = fileName.match(/\.(jpg|jpeg|png|gif)$/)

      attachmentViews.push(
        <div className="col-md-6" key={index}>
          <div className="attachment">
            <div className="attach-img d-flex justify-content-center align-items-center ">
              {downloadAttachments && (
                <a
                  target="_blank"
                  href={xmlnsString}
                  rel="noreferer noreferrer"
                  role="button"
                  className="btn btn-primary btn-down"
                >
                  Download
                </a>
              )}
              <div className="text-center">
                {isFileImage ? (
                  <object
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    data={xmlnsString}
                  />
                ) : (
                  <>
                    <svg
                      id="picture"
                      xmlns={xmlnsString}
                      width="34.705"
                      height="34.705"
                      viewBox="0 0 34.705 34.705"
                    >
                      <path
                        id="Path_17011"
                        data-name="Path 17011"
                        d="M27.475,0H7.23A7.239,7.239,0,0,0,0,7.23V27.475A7.239,7.239,0,0,0,7.23,34.7H27.475a7.239,7.239,0,0,0,7.23-7.23V7.23A7.239,7.239,0,0,0,27.475,0ZM7.23,2.892H27.475A4.338,4.338,0,0,1,31.813,7.23V27.475a4.267,4.267,0,0,1-.434,1.858l-13.25-13.25a7.23,7.23,0,0,0-10.226,0l-5.01,5.009V7.23A4.338,4.338,0,0,1,7.23,2.892Zm0,28.921a4.338,4.338,0,0,1-4.338-4.338V25.181l7.054-7.054a4.338,4.338,0,0,1,6.137,0l13.25,13.251a4.267,4.267,0,0,1-1.858.434Z"
                        fill="#868686"
                      ></path>
                      <path
                        id="Path_17012"
                        data-name="Path 17012"
                        d="M17.561,13.622A5.061,5.061,0,1,0,12.5,8.561,5.061,5.061,0,0,0,17.561,13.622Zm0-7.23a2.169,2.169,0,1,1-2.169,2.169,2.169,2.169,0,0,1,2.169-2.169Z"
                        transform="translate(5.575 1.561)"
                        fill="#868686"
                      ></path>
                    </svg>
                    <div className="">Preview Not Available</div>
                  </>
                )}
              </div>
            </div>
            <div className="px-2">
              <div className="d-flex justify-content-between pb-2">
                <div>FileName: {fileName}</div>
                <div>
                  <div
                    className="dropdown dropdown-inline"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Option"
                  >
                    <a
                      href="#"
                      className="btn btn-clean btn-hover-light-primary btn-sm btn-icon p-0"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-h" aria-hidden="true"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-left user-dd animated">
                      <ul className="navi navi-hover">
                        {copyLink && (
                          <li className="navi-item">
                            <a
                              href="#"
                              className="navi-link text-decoration-none"
                              onClick={() => {
                                navigator.clipboard.writeText(attachment.url)
                              }}
                            >
                              <span className="navi-text">
                                <i
                                  className="fas fa-link mr-1"
                                  aria-hidden="true"
                                ></i>
                                <span className="label label-xl label-inline  label-light-danger">
                                  Copy Link
                                </span>
                              </span>
                            </a>
                          </li>
                        )}

                        {deleteAttachments && (
                          <li className="navi-item">
                            <a
                              href="#"
                              className="navi-link text-decoration-none"
                              onClick={async () => {
                                  await deleteAttachment(attachment._id, attachment.module)
                                  toast.success('Attachment Deleted!')
                                  await init()
                                }}
                            >
                              <span className="navi-text">
                                <i
                                  className="far fa-trash-alt mr-1"
                                  aria-hidden="true"
                                ></i>
                                <span className="label label-xl label-inline label-light-warning">
                                  Delete
                                </span>
                              </span>
                            </a>
                          </li>
                        )}

                        {downloadAttachments && (
                          <li className="navi-item">
                            <a
                              target="_blank"
                              href={attachment.url}
                              className="navi-link text-decoration-none"
                              rel="noreferrer"
                            >
                              <span className="navi-text">
                                <i
                                  className="fas fa-download mr-1"
                                  aria-hidden="true"
                                ></i>
                                <span className="label label-xl label-inline label-light-danger ">
                                  Download
                                </span>
                              </span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
                <p className='my-0 py-0'>{timeNowFormat(attachment.created)}</p>
            </div>
          </div>
        </div>,
      )
    })

    return attachmentViews
  }

  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2 d-flex mb-4 mt-3"
          style={{ marginTop: '190px' }}
        >
          <div className="card-title mb-0 pb-0">
            <h2 className="mb-0 pb-0 dark-text">Attachments</h2>
          </div>
          <nav className="">
            <li className="nav-item p-0 close11">
              <span className="search " onClick={handleSideNavClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.829"
                  height="17.828"
                  viewBox="0 0 17.829 17.828"
                >
                  <g
                    id="Group_9756"
                    data-name="Group 9756"
                    transform="translate(-4013.659 13.151)"
                  >
                    <path
                      id="path1419"
                      d="M-1479.2-17.087l15-15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      id="path1421"
                      d="M-1479.2-32.087l15,15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>
              </span>
            </li>
          </nav>
        </div>
        {fullAttachments && fullAttachments.length > 0 && readAttachments ? (
          <div className="pr-2 pl-2 pb-4 overflow-auto" id="scroller">
            <div className="row">{getAttachmentViews()}</div>
          </div>
        ) : (
          <div className={'pr-2 pl-2 pb-4'}>No Attachments</div>
        )}
      </div>
    </div>
  )
}
