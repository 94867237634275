import moment from 'moment-timezone'
import ActionTypes from './actionTypes'
import authService from './services/authService'

export const initialState = {
  sideNavData: {},
  selectedTabId: '',
  isSidNavOpen: false,
  selectedSideNavId: '',
  sideNavDataContext: '',
  ip: '',
  selectedClientId: 'allClients',
  clients: [],
  token: sessionStorage.getItem('token') || null,
  calendarInfo: {
    claim: [],
    incident: [],
    policy: [],
  },
  viewAction: {
    action: '',
    data: {},
  },
  incidentFormData: null,
  currentUser: null,
  permissions: [],
  firebase: false,
  notifications: [],
  slot: {
    startdate: moment().startOf('day').format('x'),
    enddate: moment().endOf('day').format('x'),
    label: 'Today',
    timeZone: moment.tz.guess(true)
  },
}

const reducer = (state = initialState, oAction) => {
  switch (oAction.type) {
    case ActionTypes.TAB_ID_UPDATED:
      return {
        ...state,
        selectedTabId: oAction.id,
        viewAction: {
          action: '',
          data: {},
        },
      }
    case ActionTypes.SIDE_NAV_DATA_UPDATED:
      return {
        ...state,
        sideNavData: oAction.data,
        sideNavModule: oAction.module,
        sideNavDataContext: oAction.context,
        selectedSideNavId: state.selectedSideNavId.trim() || 'info',
      }
    case ActionTypes.HANDLE_CLIENT_ID_UPDATED:
      return {
        ...state,
        selectedClientId: oAction.clientId,
      }
    case ActionTypes.SIDE_NAV_ELEMENT_CLICKED:
      if (oAction.id === 'arrow') {
        return {
          ...state,
          isSidNavOpen: state.selectedSideNavId ? !state.isSidNavOpen : false,
        }
      } else if (oAction.id === 'close') {
        return {
          ...state,
          selectedSideNavId: '',
          isSidNavOpen: false,
          incidentFormData: null,
        }
      } else {
        return {
          ...state,
          isSidNavOpen: true,
          selectedSideNavId: oAction.id,
        }
      }
    case ActionTypes.FETCH_CLIENTS:
      return {
        ...state,
        clients: oAction.clients,
      }
    case ActionTypes.LOGIN:
      return {
        ...state,
        token: oAction.data,
      }
    case ActionTypes.LOGOUT:
      authService.logout()
      return initialState
    case ActionTypes.SAVE_IP:
      return {
        ...state,
        ip: oAction.ip,
      }

    case ActionTypes.VIEW_ACTION:
      return {
        ...state,
        viewAction: {
          // could be refresh, edit, empty.
          action: oAction.action,
          data: oAction.data,
        },
      }
    case ActionTypes.CALENDAR_INFO:
      return {
        ...state,
        calendarInfo: {
          ...state.calendarInfo,
          [oAction.key]: oAction.data,
        },
        viewAction: {
          action: '',
          data: {},
        },
      }
    case ActionTypes.SAVE_INCIDENT_FORM_DATA:
      return {
        ...state,
        incidentFormData: oAction.data,
      }

    case ActionTypes.SAVE_USER_DATA:
      return {
        ...state,
        currentUser: oAction.data.user,
        permissions: oAction.data.permissions,
        selectedClientId: oAction.data.user?.role?.clients[0] || 'allClients',
      }
    case ActionTypes.SAVE_MODULES:
      return {
        ...state,
        modules: oAction.payload,
      }
    case ActionTypes.ENABLE_FIREBASE:
      return {
        ...state,
        firebase: true,
      }
    case ActionTypes.DISABLE_FIREBASE:
      return {
        ...state,
        firebase: false,
      }
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: oAction.payload,
      }
    case ActionTypes.PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [oAction.payload, ...state.notifications],
      }
    case ActionTypes.MARK_NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map((c) => {
          if (c.notification._id === oAction.id) {
            c.isRead = true
          }
          return {
            ...c,
          }
        }),
      }
    case ActionTypes.MARK_ALL_NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map((c) => {
          c.isRead = true
          return {
            ...c,
          }
        }),
      }
    case ActionTypes.DASHBOARD_DATE_SLOT_CHANGED:
      return {
        ...state,
        slot: oAction.data
      }
      
    default:
      return state
  }
}

export default reducer
