import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/common/Table'
import { deleteBlukVehicle, deleteVehicle, getVehicles } from '../../../services/vehicleService'
import ActionWidget from '../../../components/common/ActionWidget'
import { getPagedData } from '../../../services/helperService'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../../actions'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import { toast } from 'react-toastify'
import Loader from '../../../components/common/Loader'

let vehicles = []
let itemsCount = 0
function VehicleList({
  searchQuery,
  handleEdit,
  handleView,
  context,
  checkedRecords,
  filters,
  openBulkDel,
  setShowDeleteButton,
  closeBulkDel,
  selectFilters,
  setFilterCount,
  setSelectFilter,
  handleElementCheckboxClicked,
  dateRangeFilter = {},
  handleDechecked
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [vehicle, setVehicle] = useState({})
  const [isLoading , setIsLoading] = useState(true);

  const columns = [
    {
      path: 'vehicleId',
      label: ' Vehicle Id ',
      minWidth: '200px',
      width: '240px',
      content: (vehicle) => (
        <>
          <a>{vehicle.vehicleId}</a>
          <ActionWidget
            module="VEHICLE"
            onEdit={() => handleEdit(vehicle)}
            onView={() => handleView(vehicle)}
            onDelete={() => {
              setVehicle(vehicle);
              setDeleteOne(true);
            }}
          />
        </>
      ),
    },
    { path: 'vehicleType', label: ' Vehicle Type ', type: 'string' },
    {
      path: 'dateOfPurchase',
      label: ' Date of Purchased/Leased ',
      type: 'date',
    },
    { path: 'dateOfSold', label: ' Date of Sold/Returned ', type: 'date' },
    { path: 'manufactureYear', label: ' Year of Manufactured' },
    { path: 'make', label: ' Make ' },
    { path: 'model', label: ' Model ' },
    { path: 'liscence', label: ' License Plate ' },
    { path: 'vinNumber', label: ' VIN Number ' },
    { path: 'leaseAgreementNumber', label: ' Rental Agreement Number ' },
    { path: 'vehicleStatus', label: ' Status ' },
  ]

  const dispatch = useDispatch()
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [deleteOne , setDeleteOne] = useState(false);
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedClientId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])
  useEffect(() => {
    if (openBulkDel) {
      setVehicle({ vehicle: { vehicleId: 'all ' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    if (
      selectedTabId === 'vehicle' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEdit(viewData)
    }
    if (selectedTabId === 'vehicle' && viewAction === 'refresh') {
      fetchList()
      dispatch(handleTabIdUpdated('vehicle'))
    }
  }, [viewAction, viewData, selectedTabId])

  const fetchList = async () => {
    const { data } = await getVehicles()
    setData(data.data)
    const modelList = {},
      makeList = {}

    if (data.data && data.data.length) {
      const mapFilterCount = {
        NEW: 0,
        USED: 0,
      }
      data.data.forEach(({ model, make, conditionAtPurchase, vehicleType }) => {
        if (model && model.length) {
          modelList[model] = {
            key: model,
            value: model,
          }
        }
        if (make && make.length) {
          makeList[make] = {
            key: make,
            value: make,
          }
        }

        mapFilterCount[conditionAtPurchase] += 1
      })
      setFilterCount(mapFilterCount)
      setSelectFilter(Object.values(modelList), Object.values(makeList))
    }
    if (sideNavDataContext !== 'searchView') {
      dispatch(
        handleSideNavDataUpdated({ data: data.data[0], module: 'vehicle' }),
      )
      dispatch(handleTabIdUpdated('vehicle'))
    }
    setIsLoading(false)
  }

  const handleDelete = async (item) => {
    await deleteVehicle(item._id, item)
    await fetchList()
  }


  const hendleBulkDelete = async (ids) => {    
    await deleteBlukVehicle(ids)
    await fetchList()
  }


  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }
  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    vehicles = result
    return result.length > 0
  }
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }
  const handlePageChange = (page) => {
    setCurrPage(page)
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
          const { data } = await deleteBlukVehicle(params)
          setShowDeleteButton(false)
          closeBulkDel()
          if (data && data.msg === 'Success') {
            toast.success('Deletion Successful!')
          } else {
            toast.error('Error: Delete Unsuccessful!')
          }
        } else {
      const { data } = await deleteVehicle(vehicle._id , vehicle)
    

      if (data && data.msg === 'Success') {
        toast.success('Deletion Successful!')
      } else {
        toast.error('Error: Delete Unsuccessful!')
      }
      setDeleteOne(false);
    }
    } catch (error) {
      console.log(error)
    }
    setVehicle({})
    await fetchList()

  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData()}
      <Table
        columns={columns}
        data={vehicles.filter(
          (
            vehicle,
          ) => {
            const { model, make, conditionAtPurchase, vehicleStatus, vehicleType } = vehicle
            const {
              make: selectedMake = '',
              model: selectedModel = '',
              vehicleStatus: selectedVehicleStatus = '',
              vehicleType: selectedVehicleType = '',
            } = selectFilters
            let isMake = false,
              isModel = false,
              isCondition = false,
              isvehicleStatus = false,
              isvehicleType = false

            let isDateRangeValid = false
            let isDateRangeSelected = Object.values(dateRangeFilter).every(
              (x) => {
                return x.start === null
              },
            )

            if (dateRangeFilter && !isDateRangeSelected) {
              Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
                if (start !== null && vehicle[key] !== '') {
                  if (
                    vehicle[key] > start.toISOString() &&
                    vehicle[key] < end.toISOString()
                  ) {
                    isDateRangeValid = true
                  } else {
                    isDateRangeValid = false
                  }
                }
              })
            }

            if (selectedModel.length && selectedModel === model) {
              isModel = true
            }
            if (selectedMake.length && selectedMake === make) {
              isMake = true
            }
            if (
              selectedVehicleStatus.length &&
              selectedVehicleStatus === vehicleStatus
            ) {
              isvehicleStatus = true
            }

            if (
              selectedVehicleType.length &&
              selectedVehicleType === vehicleType
            ) {
              isvehicleType = true
            }

            if (
              (filters['new'].isAvailable && conditionAtPurchase === 'NEW') ||
              (filters['used'].isAvailable && conditionAtPurchase === 'USED')
            ) {
              isCondition = true
            }

            return (
              !(
                (filters['new'].isAvailable || filters['used'].isAvailable) ^
                isCondition
              ) &&
              !(!!selectedModel.length ^ isModel) &&
              !(!!selectedMake.length ^ isMake) &&
              !(!!selectedVehicleStatus.length ^ isvehicleStatus) &&
              !(!!selectedVehicleType.length ^ isvehicleType) &&
              !(!isDateRangeSelected ^ isDateRangeValid)
            )
          },
        )}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked.bind(this, vehicles)}
      />
      <ConfirmationModal
        name={
          checkedRecords.length > 0 ? `Vehicles` :
          vehicle?.vehicleId?.length > 0
            ? 'Vehicle ID ' + vehicle?.vehicleId
            : ''
        }
        // isOpen={checkedRecords.length > 0 || deleteOne}
        isOpen={Object.keys(vehicle || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) } 

        onClose={() => {setVehicle({})
        setShowDeleteButton(false)
        closeBulkDel()
        setDeleteOne(false)
        handleDechecked()
      }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default VehicleList
