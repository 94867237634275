import React from 'react'
export const sideBarItems = [
  {
    modules: [],
    title: 'Dashboard',
    to: '/dashboard',
    access: ['Super Admin', 'Account Manager', 'Admin', 'User'],
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10255"
          data-name="Group 10255"
          transform="translate(-112 -126)"
        >
          <rect
            id="Rectangle_3768"
            data-name="Rectangle 3768"
            width="35"
            height="35"
            transform="translate(112 126)"
            fill="none"
          />
          <g id="chat-arrow-grow" transform="translate(119 133)">
            <path
              id="Path_16982"
              data-name="Path 16982"
              d="M20.327,3.976h-2.7A.876.876,0,0,0,17,5.471l.951.951h0l-3.141,3.14a.9.9,0,0,1-1.239,0l-.149-.15a2.69,2.69,0,0,0-3.717,0L5.243,13.88a.879.879,0,0,0,1.245,1.241l4.467-4.467a.9.9,0,0,1,1.239,0l.149.15a2.692,2.692,0,0,0,3.717,0L19.2,7.663h0l.951.951a.876.876,0,0,0,1.489-.621V5.29A1.314,1.314,0,0,0,20.327,3.976Z"
              transform="translate(-0.619 -0.493)"
              fill="#ffffff"
            />
            <path
              id="Path_16983"
              data-name="Path 16983"
              d="M20.147,19.25H4.38a2.628,2.628,0,0,1-2.628-2.628V.876A.876.876,0,0,0,0,.876V16.622A4.385,4.385,0,0,0,4.38,21H20.147a.876.876,0,1,0,0-1.752Z"
              transform="translate(0 0)"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    modules: ['USER', 'CLIENT'],
    title: 'User Management',
    to: '/userManagement',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10256"
          data-name="Group 10256"
          transform="translate(-326 -126)"
        >
          <rect
            id="Rectangle_3774"
            data-name="Rectangle 3774"
            width="35"
            height="35"
            transform="translate(326 126)"
            fill="none"
          />
          <g
            id="user_1_"
            data-name="user (1)"
            transform="translate(336.57 134.223)"
          >
            <path
              id="Path_16984"
              data-name="Path 16984"
              d="M10.571,9.141A4.571,4.571,0,1,0,6,4.571,4.571,4.571,0,0,0,10.571,9.141Zm0-7.618A3.047,3.047,0,1,1,7.524,4.571,3.047,3.047,0,0,1,10.571,1.524Z"
              transform="translate(-3.715 0)"
              fill="#fff"
            />
            <path
              id="Path_16985"
              data-name="Path 16985"
              d="M9.856,14A6.864,6.864,0,0,0,3,20.856a.762.762,0,1,0,1.524,0,5.332,5.332,0,0,1,10.665,0,.762.762,0,1,0,1.524,0A6.864,6.864,0,0,0,9.856,14Z"
              transform="translate(-3 -3.335)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    modules: ['MVR'],
    title: 'MVR Hub',
    to: '/mvr',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10257"
          data-name="Group 10257"
          transform="translate(-529 -126)"
        >
          <rect
            id="Rectangle_3775"
            data-name="Rectangle 3775"
            width="35"
            height="35"
            transform="translate(529 126)"
            fill="none"
          />
          <path
            id="id-badge"
            d="M13.3,2.8H10.5V2.1a2.1,2.1,0,0,0-4.2,0v.7H3.5A3.5,3.5,0,0,0,0,6.3v7a3.5,3.5,0,0,0,3.5,3.5h9.8a3.5,3.5,0,0,0,3.5-3.5v-7a3.5,3.5,0,0,0-3.5-3.5ZM7.7,2.1a.7.7,0,0,1,1.4,0V3.5a.7.7,0,1,1-1.4,0Zm7.7,11.2a2.1,2.1,0,0,1-2.1,2.1H3.5a2.1,2.1,0,0,1-2.1-2.1v-7A2.1,2.1,0,0,1,3.5,4.2H6.429a2.087,2.087,0,0,0,3.942,0H13.3a2.1,2.1,0,0,1,2.1,2.1ZM7,7H3.5a.7.7,0,0,0-.7.7v5.6a.7.7,0,0,0,.7.7H7a.7.7,0,0,0,.7-.7V7.7A.7.7,0,0,0,7,7Zm-.7,5.6H4.2V8.4H6.3ZM14,10.5a.7.7,0,0,1-.7.7H9.8a.7.7,0,0,1,0-1.4h3.5A.7.7,0,0,1,14,10.5Zm0-2.8a.7.7,0,0,1-.7.7H9.8A.7.7,0,0,1,9.8,7h3.5A.7.7,0,0,1,14,7.7Zm-1.4,5.6a.7.7,0,0,1-.7.7H9.8a.7.7,0,0,1,0-1.4h2.1A.7.7,0,0,1,12.6,13.3Z"
            transform="translate(538.1 135.1)"
            fill="#fff"
          />
        </g>
      </svg>
    ),
  },
  {
    modules: ['THIRD-PARTY'],
    access: ['Super Admin'],
    title: 'Third Party',
    to: '/thirdParty',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10266"
          data-name="Group 10266"
          transform="translate(-187 -86)"
        >
          <rect
            id="Rectangle_3778"
            data-name="Rectangle 3778"
            width="35"
            height="35"
            transform="translate(187 86)"
            fill="none"
          />
          <path
            id="diploma"
            d="M8.6,8.4a.7.7,0,1,1,0,1.4H6.5a.7.7,0,1,1,0-1.4Zm4.2-2.1a.7.7,0,0,0-.7-.7H6.5A.7.7,0,1,0,6.5,7h5.6A.7.7,0,0,0,12.8,6.3ZM6.5,4.2h5.6a.7.7,0,0,0,0-1.4H6.5a.7.7,0,1,0,0,1.4Zm8.4,9.411v2.683a.5.5,0,0,1-.862.357l-.538-.538-.538.538a.5.5,0,0,1-.862-.357V13.611A2.791,2.791,0,0,1,13.5,8.4a2.757,2.757,0,0,1,.7.1v-5a2.1,2.1,0,0,0-2.1-2.1H6.5A2.1,2.1,0,0,0,4.4,3.5v8.4A2.1,2.1,0,0,0,6.5,14H10a.7.7,0,1,1,0,1.4H6.5A3.5,3.5,0,0,1,3,11.9V3.5A3.5,3.5,0,0,1,6.5,0h5.6a3.5,3.5,0,0,1,3.5,3.5V9.367a2.765,2.765,0,0,1-.7,4.243Zm0-2.411a1.4,1.4,0,1,0-1.4,1.4A1.4,1.4,0,0,0,14.9,11.2Z"
            transform="translate(194.5 95.1)"
            fill="#fff"
          />
        </g>
      </svg>
    ),
  },
  {
    modules: ['CLAIM', 'INCIDENT'],
    title: 'Claims & Incidents',
    to: '/incidents',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10258"
          data-name="Group 10258"
          transform="translate(-735 -126)"
        >
          <rect
            id="Rectangle_3776"
            data-name="Rectangle 3776"
            width="35"
            height="35"
            transform="translate(735 126)"
            fill="none"
          />
          <path
            id="ambulance"
            d="M16.351,8.267l-1.29-2.9a4.256,4.256,0,0,0-3.889-2.527h-.958L9.746.963a1.272,1.272,0,0,0-2.468,0L6.809,2.837H3.547A3.551,3.551,0,0,0,0,6.384v5.674a2.124,2.124,0,0,0,1.469,2.013,2.412,2.412,0,0,0-.05.47,2.483,2.483,0,1,0,4.965,0,2.458,2.458,0,0,0-.029-.355h4.314a2.458,2.458,0,0,0-.029.355,2.483,2.483,0,0,0,4.965,0,2.412,2.412,0,0,0-.05-.47,2.124,2.124,0,0,0,1.469-2.013v-.623A7.753,7.753,0,0,0,16.351,8.267ZM13.765,5.941,14.593,7.8H12.058a.709.709,0,0,1-.709-.709V4.269a2.837,2.837,0,0,1,2.416,1.673Zm-8.8,8.6a1.064,1.064,0,1,1-2.128,0,1.006,1.006,0,0,1,.066-.355h2a1.006,1.006,0,0,1,.066.355Zm8.157,1.064a1.064,1.064,0,0,1-1.064-1.064,1.006,1.006,0,0,1,.066-.355h2a1.006,1.006,0,0,1,.066.355A1.064,1.064,0,0,1,13.122,15.605ZM15.6,12.058a.709.709,0,0,1-.709.709H2.128a.709.709,0,0,1-.709-.709V6.384A2.128,2.128,0,0,1,3.547,4.256H9.93V7.093a2.128,2.128,0,0,0,2.128,2.128H15.2a6.324,6.324,0,0,1,.405,2.214ZM7.8,8.512a.709.709,0,0,1-.709.709H6.384v.709a.709.709,0,0,1-1.419,0V9.221H4.256a.709.709,0,0,1,0-1.419h.709V7.093a.709.709,0,0,1,1.419,0V7.8h.709A.709.709,0,0,1,7.8,8.512Z"
            transform="translate(744.221 134.755)"
            fill="#fff"
          />
        </g>
      </svg>
    ),
  },

  {
    modules: ['EMPLOYEE', 'VEHICLE', 'PROPERTY'],
    title: 'Assets Management',
    to: '/assetsManagement',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10259"
          data-name="Group 10259"
          transform="translate(-949 -121)"
        >
          <rect
            id="Rectangle_3777"
            data-name="Rectangle 3777"
            width="35"
            height="35"
            transform="translate(949 121)"
            fill="none"
          />
          <path
            id="diamond"
            d="M16.074,3.976,13.99,1.16A2.773,2.773,0,0,0,11.737,0H4.877A2.769,2.769,0,0,0,2.63,1.146L.509,3.983A2.764,2.764,0,0,0,.594,7.294L6.68,15.829A2.075,2.075,0,0,0,9.953,15.8l6.038-8.439a2.769,2.769,0,0,0,.083-3.386Zm-3.2-2L14.96,4.8c.011.015.014.033.025.049H11.6l-.989-3.461h1.128a1.393,1.393,0,0,1,1.133.589ZM8.307,13.235l-1.869-7h3.738ZM6.456,4.846l.989-3.461H9.17l.989,3.461ZM3.75,1.964a1.389,1.389,0,0,1,1.127-.579H6L5.016,4.846H1.6c.01-.015.012-.034.024-.048ZM1.694,6.455a1.315,1.315,0,0,1-.128-.225h3.44L7.079,14Zm7.839,7.556L11.61,6.23h3.452a1.454,1.454,0,0,1-.168.291Z"
            transform="translate(958 130.386)"
            fill="#fff"
          />
        </g>
      </svg>
    ),
  },
  {
    modules: ['POLICY'],
    title: 'Policy Hub',
    to: '/policies',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <g
          id="Group_10266"
          data-name="Group 10266"
          transform="translate(-187 -86)"
        >
          <rect
            id="Rectangle_3778"
            data-name="Rectangle 3778"
            width="35"
            height="35"
            transform="translate(187 86)"
            fill="none"
          />
          <path
            id="diploma"
            d="M8.6,8.4a.7.7,0,1,1,0,1.4H6.5a.7.7,0,1,1,0-1.4Zm4.2-2.1a.7.7,0,0,0-.7-.7H6.5A.7.7,0,1,0,6.5,7h5.6A.7.7,0,0,0,12.8,6.3ZM6.5,4.2h5.6a.7.7,0,0,0,0-1.4H6.5a.7.7,0,1,0,0,1.4Zm8.4,9.411v2.683a.5.5,0,0,1-.862.357l-.538-.538-.538.538a.5.5,0,0,1-.862-.357V13.611A2.791,2.791,0,0,1,13.5,8.4a2.757,2.757,0,0,1,.7.1v-5a2.1,2.1,0,0,0-2.1-2.1H6.5A2.1,2.1,0,0,0,4.4,3.5v8.4A2.1,2.1,0,0,0,6.5,14H10a.7.7,0,1,1,0,1.4H6.5A3.5,3.5,0,0,1,3,11.9V3.5A3.5,3.5,0,0,1,6.5,0h5.6a3.5,3.5,0,0,1,3.5,3.5V9.367a2.765,2.765,0,0,1-.7,4.243Zm0-2.411a1.4,1.4,0,1,0-1.4,1.4A1.4,1.4,0,0,0,14.9,11.2Z"
            transform="translate(194.5 95.1)"
            fill="#fff"
          />
        </g>
      </svg>
    ),
  },
  {
    modules: ['TRASH'],
    title: 'Trash',
    to: '/trash',
    icon: (
      <svg
        id="Group_10259"
        data-name="Group 10259"
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
      >
        <rect
          id="Rectangle_3777"
          data-name="Rectangle 3777"
          width="35"
          height="35"
          fill="none"
        />
        <g id="delete" transform="translate(10.563 9.667)">
          <path
            id="Path_49"
            data-name="Path 49"
            d="M222.794,154.7a.394.394,0,0,0-.394.394v7.443a.394.394,0,0,0,.788,0v-7.443A.394.394,0,0,0,222.794,154.7Zm0,0"
            transform="translate(-213.719 -148.758)"
            fill="#fefefe"
          />
          <path
            id="Path_50"
            data-name="Path 50"
            d="M104.794,154.7a.394.394,0,0,0-.394.394v7.443a.394.394,0,0,0,.788,0v-7.443a.394.394,0,0,0-.394-.394Zm0,0"
            transform="translate(-100.323 -148.758)"
            fill="#fefefe"
          />
          <path
            id="Path_51"
            data-name="Path 51"
            d="M1.106,4.961v9.614a2.154,2.154,0,0,0,.568,1.489,1.922,1.922,0,0,0,1.394.6h7.386a1.922,1.922,0,0,0,1.394-.6,2.154,2.154,0,0,0,.572-1.485V4.961a1.491,1.491,0,0,0-.377-2.933h-2V1.536A1.533,1.533,0,0,0,8.5,0H5.032A1.533,1.533,0,0,0,3.486,1.542v.492h-2A1.491,1.491,0,0,0,1.1,4.967Zm9.355,10.925H3.072A1.236,1.236,0,0,1,1.89,14.578V5h9.76v9.579a1.236,1.236,0,0,1-1.192,1.312ZM4.267,1.54A.752.752,0,0,1,5.032.78H8.5a.752.752,0,0,1,.765.761v.492h-5ZM1.488,2.81H12.044a.7.7,0,0,1,0,1.405H1.488a.7.7,0,1,1,0-1.4Zm0,0"
            transform="translate(0.005)"
            fill="#fefefe"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M163.794,154.7a.394.394,0,0,0-.394.394v7.443a.394.394,0,0,0,.788,0v-7.443a.394.394,0,0,0-.394-.394Zm0,0"
            transform="translate(-157.021 -148.758)"
            fill="#fefefe"
          />
        </g>
      </svg>
    ),
  },
]
