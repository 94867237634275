import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/public-incident`


export function updateManagerForm(id, managerFormDetails) {
  return http.put(`${apiEndpoint}/update-manager-form/${id}`, managerFormDetails)
}

export function savePublicIncident(formData) {
  return http.post(`${apiEndpoint}/add-public-incident`, formData)
}

export function verifyRecaptchToken(data) {
  return http.post(`${apiEndpoint}/verify-token`, data)
}

export function getClientList() {
  return http.get(`${apiEndpoint}/client-list`)
}

export function verifyManagerForm(token) {
  return http.post(`${apiEndpoint}/verify-manager-token`, { token })
}

export function getPublicIncidentAttachments(token) {
  return http.post(`${apiEndpoint}/attachments`, {
    "vtoken": token
  })
}


// TODO BELOW API INTEGRATION IS REMAINING

export function getPublicIncidentList() {
  return http.get(`${apiEndpoint}/get-public-incident-list`)
}

export function getPublicIncident(id) {
  return http.get(`${apiEndpoint}/get-public-incident/${id}`)
}

export function updatePunlicIncident(id, publicIncident) {
  return http.put(`${apiEndpoint}/update-public-incident/${id}`, publicIncident)
}

export function deletePublicIncident(id, publicIncident) {
  return http.put(`${apiEndpoint}/delete-public-incident/${id}`, publicIncident)
}

export function deleteBlukPublicIncident(ids) {
  return http.put(`${apiEndpoint}/bulk-delete`, ids);
}