import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import TextInput from '../../components/form/TextInput'
import { ReactComponent as Close } from '../../svgs/close.svg'
import AddressInput from '../../components/form/AddressInput'
import { updateUser } from '../../services/userService'
import { resetPassword } from '../../services/authService'
export default function AccountSettings() {
  const currentUser = useSelector((state) => state?.currentUser || {})

  const [isOpen, setOpen] = useState('close')
  const [state, setState] = useState({})

  useEffect(() => {
    const {
      firstName = '',
      lastName = '',
      email = '',
      phoneNumber = '',
      address = {},
      userId = '',
      emailNotification = '',
      website = '',
      organization = '',
      _id,
    } = currentUser
    setState({
      firstName,
      lastName,
      email,
      phoneNumber,
      address: { ...address },
      userId,
      emailNotification,
      website,
      organization,
      id: _id,
    })
  }, [currentUser])

  const handleSubmit = async (values) => {
    if (['personal', 'address'].includes(isOpen)) {
      try {
        const { data } = await updateUser(state.id, values)
        if (data.data) {

          toast.success(`${isOpen} is updated successfully`)
        }
        setOpen('close')
        setState((s) => ({ ...s, ...values }))
      } catch {
        toast.success(`Error encounterd: Please check again!.`)
      }
    } else {
      if (isOpen == 'password') {
        try {
          if(!values.password || !values.oldPassword) {
            toast.error('Please fill up password fields')
            return
          }
          if (values.confirmPassword !== values.password) {
            toast.error('Password and confirm password are not same')
            return
          }
           await resetPassword({
            newPassword: values.password,
            oldPassword: values.oldPassword,
          }, state.id)
          toast.success("Password changed successfully")
          setOpen('close')
        } catch (err) {
          if(err.response.status == 400) {
            toast.error(err.response.data.msg.err)
          }
        }
      }
    }
  }

  return (
    <>
      <div className="position-rel" style={{ paddingLeft: '13rem' }}>
        <div className="sticki-top">
          <div className="pl-6 pt-4 user-mang">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-md-12">
                  <nav className="mt-4">
                    <div className="nav nav-tabs nav-fill m-0" role="tablist">
                      <div className=" nav-link text-left hvr-pulse1 mb-2 inherit p-0">
                        <h2 className="text-color-2">Account Details</h2>
                        <p className="mb-0 text-color">
                          Manage Your personal account details
                        </p>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pl-6 user-mang assetmanagset my-4 ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div>
                  <h2 className="text-color-2">Your Account</h2>
                </div>
              </div>
              <div className="col-md-10">
                <div className="px-4 border border-bottom-0 r">
                  <div className="row  align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-3">
                        <p className="mb-0  font-weight-600 text-color">
                          Personal Info
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="py-3">
                        <p className="mb-0 text-color">
                          <span>
                            {state?.firstName + ' ' + state?.lastName}
                          </span>
                      
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="pull-right py-3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen('personal')}
                      >
                        <p className="mb-0">
                          <i
                            className="fas fa-pencil-alt"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border border-bottom-0 ">
                  <div className="row align-items-center">
                    <div className=" col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Username
                        </p>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <p className="mb-0 py-3 text-color">
                      {state?.firstName + ' ' + state?.lastName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="px-4 border border-bottom-0 ">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Password
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 text-color">
                          **********************
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="pull-right"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen('password')}
                      >
                        <p>
                          <i
                            className="fas fa-pencil-alt"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border border-bottom-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Email Notifications
                        </p>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="d-flex justify-content-between  py-4">
                        <p className="mb-0 text-color">
                          {state.emailNotification || ''}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div
                        className="pull-right py-4"
                        onClick={() => setOpen('emailNotification')}
                      >
                        <p className="mb-0"></p>
                        <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border border-bottom-0">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Timezone
                        </p>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="d-flex justify-content-between  py-4">
                        <p className="mb-0 text-color">
                          GMT-06:00 - Central Time, US & Canada America/Chicago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border">
                  <div className="row  align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          User ID
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 text-color">{state?.email}</p>
                      </div>
                    </div>
                    <div className="col-md-7"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div className="row">
                <div className="col-md-12">
                  <nav className="mt-4">
                    <div
                      className="nav nav-tabs nav-fill m-0"
                      id="nav-tab"
                      role="tablist"
                      style={{ marginRight: 0 }}
                    ></div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-2">
                <div>
                  <h2 className="text-color-2">Your Company</h2>
                </div>
              </div>
              <div className="col-md-10">
                <div className="px-4 border border-bottom-0 ">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Organization
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="py-4">
                        <p className="mb-0 text-color">
                          {state.organization || ''}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="pull-right py-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen('organization')}
                      >
                        <p className="mb-0">
                          <i
                            className="fas fa-pencil-alt"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border border-bottom-0 ">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 font-weight-600 text-color">
                          Address
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <p className="mb-0 py-2">
                        <span className="text-color">
                          {`${state?.address?.line1 || ''}
                           ${state?.address?.line2 || ''}
                           ${state.address?.city || ''}, ${
                            state.address?.state || ''
                          } ${state.address?.pinCode || ''}
                           ${state.address?.country || ''}
                          `}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="pull-right py-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen('address')}
                      >
                        <p className="mb-0 ">
                          <i
                            className="fas fa-pencil-alt"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 border ">
                  <div className="row align-items-center">
                    <div className="col-md-3">
                      <div className="d-flex justify-content-between align-items-center py-4">
                        <p className="mb-0 font-weight-600 font-weight-600 text-color">
                          Website
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <p className="mb-0 py-4 text-color">
                        {state?.website || ''}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="pull-right py-4"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen('website')}
                      >
                        <p className="mb-0 ">
                          <i
                            className="fas fa-pencil-alt"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen !== 'close'}
        toggle={() => setOpen('close')}
        backdrop={'static'}
        className={'modal-dialog-top modal-lg modal-right posi-rel'}
        style={{ width: '35vw' }}
      >
        <div>
          <a
            className="close close-left"
            onClick={() => setOpen('close')}
            aria-label="Close"
          >
            <Close />
          </a>
          <div className="row no-gutters col-container">
            <div className="col-md-12 col-h">
              <div className="modal-body p-4 mb-4 px-3 add_scroll">
                {isOpen === 'personal' && (
                  <PersonalUI
                    firstName={state?.firstName || ''}
                    lastName={state?.lastName || ''}
                    phoneNumber={state?.phoneNumber || ''}
                    onSubmit={handleSubmit}
                  />
                )}
                {isOpen === 'password' && (
                  <PasswordUI onSubmit={handleSubmit} />
                )}
                {isOpen === 'organization' && (
                  <OrganizationUI
                    organization={state?.organization || ''}
                    onSubmit={handleSubmit}
                  />
                )}
                {isOpen === 'address' && (
                  <AddressUI
                    address={state?.address || ''}
                    onSubmit={handleSubmit}
                  />
                )}
                {isOpen === 'website' && (
                  <WebsiteUI
                    website={state?.website || ''}
                    onSubmit={handleSubmit}
                  />
                )}
                {isOpen === 'emailNotification' && (
                  <EmailNotificationUI
                    emailNotification={state?.emailNotification || ''}
                    onSubmit={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const PersonalUI = ({ firstName, lastName, phoneNumber, onSubmit }) => (
  <>
    <h2 className="modal-title mb-5">Personal Information</h2>
    <Formik
      enableReinitialize
      initialValues={{ firstName, lastName, phoneNumber }}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      validator={() => ({})}
    >
      {({ status, isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-lg-6">
              <TextInput
                label="First Name"
                name="firstName"
                className="form-control"
                placeholder=""
              />
            </div>
            <div className="form-group col-lg-6">
              <TextInput
                label="Last Name"
                name="lastName"
                className="form-control"
                placeholder=""
              />
            </div>
            <div className="form-group col-md-12">
              <TextInput
                isPhone
                changeOnUndefined={true}
                label="Phone"
                name="phoneNumber"
                className="form-control"
                maxLength={14}
                onPhoneChange={(phone) => {
                  if (phone) {
                    setFieldValue('phoneNumber', phone || '')
                  }
                }}
                value={values.phoneNumber || ''}
              />
            </div>
          </div>
          <div className="clear"></div>

          <div className="d-flex pull-right">
            <button
              type="submit"
              className="btn btn-primary px-4 border-radius-12 mr-3"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => setOpen('close')}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
)

const PasswordUI = ({ onSubmit }) => {
  const passwordInfo = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  }
  return (
    <>
      <h2 className="modal-title mb-5">Password</h2>
      <Formik
        enableReinitialize
        initialValues={{ ...passwordInfo, oldPassword: '' }}
        onSubmit={(values) => {
          if (values['password'] !== values['confirmPassword']) {
            toast.error(
              `Error:- Password and Confirm Password are different. Please make them similar.`,
            )
          } else {
            onSubmit(values)
          }
        }}
        validator={() => ({})}
      >
        {({ status, isSubmitting, values, setFieldValue }) => (
          <Form autoComplete="off">
            <div className="row">
              <div className="form-group col-lg-12">
                <TextInput
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  placeholder=""
                />
              </div>
              <div className="form-group col-lg-12">
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  placeholder=""
                />
              </div>
              <div className="form-group col-md-12">
                <TextInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  placeholder=""
                />
              </div>
            </div>
            <div className="clear"></div>

            <div className="d-flex pull-right">
              <button
                type="submit"
                className="btn btn-primary px-4 border-radius-12 mr-3"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
                onClick={() => setOpen('close')}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

const EmailNotificationUI = ({ emailNotification, onSubmit }) => (
  <>
    <h2 className="modal-title mb-5">Email Notifications</h2>
    <Formik
      enableReinitialize
      initialValues={{ emailNotification }}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      validator={() => ({})}
    >
      {({ status, isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-lg-12">
              <label htmlFor="">Select Time Interval</label>
              <div className="select-wrapper">
                <i
                  className="fas fa-chevron-down arrow-filter"
                  aria-hidden="true"
                ></i>
                <select
                  name="emailNotification"
                  id="emailNotification"
                  className="form-control form-select"
                  onChange={(event) => {
                    const p = event.target?.value || ''
                    setFieldValue('emailNotification', p)
                  }}
                >
                  {[
                    {
                      key: 'select',
                      value: '',
                      text: 'Select Option',
                    },
                    {
                      key: 'daily',
                      value: 'daily',
                      text: 'Daily',
                    },
                    {
                      key: 'weekly',
                      value: 'weekly',
                      text: 'Weekly',
                    },
                    {
                      key: 'monthly',
                      value: 'monthly',
                      text: 'Monthly',
                    },
                  ].map(({ key, value, text }) => (
                    <option key={key} value={value}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group col-lg-12">
              <label className="mb-3" htmlFor="turnOffEmails">
                Turn Off Your Emails
                <div className="border-bottom"></div>
              </label>

              <p className="d-flex align-items-center">
                <input
                  onChange={(a) =>
                    setFieldValue('noNotification', a.target.checked)
                  }
                  name="noNotification"
                  className="email-check"
                  type="checkbox"
                  value=""
                  id="turnOffEmails"
                  checked={!!values?.noNotification}
                  style={{ cursor: 'pointer' }}
                />
                <label
                  htmlFor="noNotification"
                  className="agree"
                  style={{
                    paddingLeft: 6,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setFieldValue('noNotification', !values?.noNotification)
                  }
                >
                  I don't want to receive emails from Acuity Risk Consultants.
                </label>
              </p>
            </div>
          </div>

          <div className="d-flex pull-right pt-5">
            <button
              type="submit"
              className="btn btn-primary px-4 border-radius-12 mr-3"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
)

const OrganizationUI = ({ organization, onSubmit }) => (
  <>
    <h2 className="modal-title mb-5">Organization</h2>
    <Formik
      enableReinitialize
      initialValues={{ organization }}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      validator={() => ({})}
    >
      {({ status, isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-lg-12">
              <TextInput
                label="Organization"
                name="organization"
                className={'form-control border-radius-0'}
                placeholder=""
              />
            </div>
          </div>
          <div className="clear"></div>

          <div className="d-flex pull-right">
            <button
              type="submit"
              className="btn btn-primary px-4 border-radius-12 mr-3"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => setOpen('close')}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
)

const AddressUI = ({ address, onSubmit }) => (
  <>
    <h2 className="modal-title mb-3">Address</h2>
    <Formik
      enableReinitialize
      initialValues={{ address }}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      validator={() => ({})}
    >
      {({ status, isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-lg-12">
              <AddressInput
                label=""
                name="address"
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
          <div className="clear"></div>

          <div className="d-flex pull-right">
            <button
              type="submit"
              className="btn btn-primary px-4 border-radius-12 mr-3"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => setOpen('close')}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
)

const WebsiteUI = ({ website, onSubmit }) => (
  <>
    <h2 className="modal-title mb-5">Website</h2>
    <Formik
      enableReinitialize
      initialValues={{ website }}
      onSubmit={(values) => {
        onSubmit(values)
      }}
      validator={() => ({})}
    >
      {({ status, isSubmitting, values, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-md-12">
              <TextInput
                label="Website"
                name="website"
                className={'form-control border-radius-0'}
                placeholder=""
              />
            </div>
          </div>
          <div className="clear"></div>

          <div className="d-flex pull-right">
            <button
              type="submit"
              className="btn btn-primary px-4 border-radius-12 mr-3"
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => setOpen('close')}
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </>
)
