import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../components/common/Loader'
import FooterPublicIncident from '../../components/Incidents/FooterPublicIncident'
import PublicIncidentHeader from '../../components/Incidents/PublicIncidentHeader'
import { getPublicIncidentAttachmentList } from '../../services/attachmentService'
import { verifyManagerForm } from '../../services/publicIncidentService'
import ManagerForm from './ManagerForm'
import PublicIncidentView from './PublicIncidentView'

export default function PublicIncidentReport() {
  const [activeStep, setActiveStep] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [publicIncidentData, setPublicIncidentData] = useState()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [tokenExpired, setTokenExpired] = useState(false)
  const { id: token } = useParams()

  useEffect(() => {
    fetchAndVerifyPublicIncident()
  }, [])

  const fetchAndVerifyPublicIncident = async () => {
    // get token from the url
    const { data } = await verifyManagerForm(token)
    if (data.data.isTokenExpired) {
      setTokenExpired(true)
      setIsLoading(false)
      return
    }
    if (data?.data?.managerFormDetails?.isAgree) {
      setIsSubmitted(true)
      setIsLoading(false)
      return
    }
    // setPublicIncidentData(data.data)
    let attachmentViews = await getAttachmentViews(data.data.attachments)

    let vehicleData = data?.data?.vehiclesInvolved.map((vehicle) => {
      let filteredVehicleParts = vehicle?.vehicleDetails?.vehicleDamageParts
      if (filteredVehicleParts) {
        filteredVehicleParts = filteredVehicleParts.map((part) => {
          let partName = part
            .split(' ')
            .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
            .join(' ')
            .replace(/ /g, '-')
          return partName
        })
      }
      vehicle.filteredVehicleParts = filteredVehicleParts
      return vehicle
    })

    let humanParts = data.data.humanParts
    if (humanParts) {
      humanParts = humanParts.map((part) => {
        let partName = part
          .split(' ')
          .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
          .join(' ')
          .replace(/ /g, '_')
        return partName
      })
    }
    setPublicIncidentData({
      ...data.data,
      vehiclesInvolved: vehicleData,
      attachmentViews,
      humanParts,
    })
    setIsLoading(false)
  }

  const getAttachmentViews = async (attachments) => {
    let attachmentViews = []

    if (attachments?.length > 0) {
      let { data } = await getPublicIncidentAttachmentList(
        'public-incident',
        attachments,
      )
      attachmentViews = data.data.map((att) => att.url)
    }
    return attachmentViews
  }

  if (isLoading)
    return (
      <div style={{ marginTop: '120px' }}>
        <Loader loading={isLoading} />
      </div>
    )

  if (isSubmitted || tokenExpired)
    return (
      <div style={{ marginTop: '150px' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                {isSubmitted && (
                  <>
                    <h2 className="no-comment mt-5">
                      Thank you for submitting the form
                    </h2>
                    <p className="all-comment my-3">
                      Your report has been submitted to the appropriate
                      authorities.
                    </p>
                  </>
                )}
                {tokenExpired && (
                  <>
                    <h2 className="no-comment mt-5">
                      Your incident report has been expired.
                    </h2>
                    <p className="all-comment my-3">
                      Please contact your driver to re-submit
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <>
      <section>
        <PublicIncidentHeader />
        {activeStep === 1 ? (
          <PublicIncidentView
            setActiveStep={setActiveStep}
            publicIncidentData={publicIncidentData}
          />
        ) : (
          <ManagerForm
            setActiveStep={setActiveStep}
            publicIncidentData={publicIncidentData}
          />
        )}
      </section>
      <FooterPublicIncident />
    </>
  )
}
