import React from 'react'

function PublicIncidentHeader() {
  return (
    <header style={{ position: 'fixed' }}>
      <div className="d-flex flex-column flex-md-row align-items-center p-2 px-md-4 bg-white border-bottom shadow-sm">
        <span className="my-0 mr-md-auto font-weight-normal acuity-logo">
          <a href="#" className="rem-decoration">
            <img
              data-testid="logo"
              style={{ margin: '-8px 0' }}
              src="../assets/images/Acuity-logo-svg.png"
              alt='Acuity Risk Consultants'
            />
          </a>
        </span>
      </div>
    </header>
  )
}

export default PublicIncidentHeader
