import React from 'react'
import ErrorMessage from '../../../components/ErrorMessage';

function FormikSelect({
  name,
  label,
  options,
  defaultLabel,
  value,
  setFieldValue,
  withAsterisk = false,
  errors,
  handleBlur = () => { },
  setClientId = () => { },
}) {
  return (
    <div className="form-group">
      <label>{label} {withAsterisk ? (<span className='text-danger'>*</span>): (<></>)}</label>
      <select
        value={value || ''}
        autoComplete="off"
        className="form-control"
        name={name}
        onChange={(e) => {
          setFieldValue(name, e.target.value)
          setClientId(e.target.value)
        }}
        onBlur={handleBlur}
      >
        {defaultLabel.length && (
          <option disabled value="">
            {defaultLabel}
          </option>
        )}
        {options.length &&
          options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.value}
            </option>
          ))}
      </select>
      {<ErrorMessage errors={errors} name={name} />}
    </div>
  )
}

export default FormikSelect
