import { ErrorMessage, Field } from 'formik'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React from 'react'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextArea from '../../../components/form/TextArea'
import TextInput from '../../../components/form/TextInput'
import {
  addComma,
  allClaimStatusTypes,
  allCoverageDescription,
  claimTypes,
  getAvailableTimezones,
  yesNoOptions,
} from '../utils'

export default function IncidentDetailsView({
  values,
  setFieldValue,
  onNavigate,
}) {
  return (
    <>
      <div className="border-bottom h6 py-2 mb-3">Incidents Details</div>
      <div className="form-row">
        <div className="col-md-6">
          <DatePickerField
            data-testid="date-of-incident"
            withAsterisk
            name="incidentDetails.dateOfIncident"
            label="Date of Loss"
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div>
              <label>
                Time of Incident (
                  {moment(values?.incidentDetails?.dateOfIncident ?? new Date())
                  ?.tz(values?.timezone ?? moment.tz.guess())
                  ?.format('z')}
                )
              </label>
            </div>
            <TimePicker
              use12Hours
              className="antDOverride"
              name="incidentDetails.time"
              onChange={(value) => {
                setFieldValue(
                  'incidentDetails.time',
                  value.format('HH:mm:ss A'),
                )
              }}
              format="hh:mm:ss A"
              value={moment(
                values?.incidentDetails?.time || '00:00:00',
                'HH:mm:ss A',
              )}
              defaultValue={moment('00:00:00', 'HH:mm:ss A')}
            />
          </div>
        </div>
        <div className="col-md-12">
          <Select
            withAsterisk
            label="Incident Timezone"
            name="timezone"
            defaultLabel="Select incident timezone"
            options={getAvailableTimezones(values.timezone)}
            showErrorEvenWhenUntouched={true}
          />
        </div>
        <div className="col-md-6">
          <TextInput label="POC Name" name="incidentDetails.pocName" />
        </div>
        <div className="col-md-6">
          <TextInput
            isPhone
            changeOnUndefined={true}
            showErrorEvenWhenUntouched
            label="POC Number"
            name="incidentDetails.pocNumber"
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('incidentDetails.pocNumber', phone || '')
            }}
            value={values?.incidentDetails?.pocNumber || ''}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="POC Email"
            name="incidentDetails.pocEmail"
            type="email"
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Package Number" name="packageNumber" />
        </div>
        <div className="col-md-6">
          {/* <TextInput label="Type of Claim" name="claimType" /> */}
          <Select
            label="Type of Claim"
            name="claimType"
            defaultLabel="Select claim type"
            options={claimTypes}
            value={values.claimType}
          />
        </div>
        <div className="col-md-6">
          <Select
            data-testid="claim-status"
            label="Incident Status"
            name="claimStatus"
            defaultLabel="Select status"
            options={allClaimStatusTypes}
            value={values.status}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Claim Specialist"
            name="incidentDetails.incidentHandledBy"
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Entered By" name="incidentDetails.enteredBy" />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            withAsterisk
            showErrorEvenWhenUntouched
            label="Location where the incident happened"
            name="incidentDetails.location"
            placeholder="i.e.,inside hub, vehicle, hub parking, customer’s specific address, etc."
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            showErrorEvenWhenUntouched
            name="incidentDetails.employeeActivity"
            label="What was employee doing when the incident happened"
            placeholder="i.e.,lifting, driving, pushing, delivering, etc."
          />
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>
            Has Third Party left or refused to give info ? (If yes, make a
            police report)
          </label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.thirdPartyLeaves`}
                value={'true'}
                id="thirdPartyLeavesYes"
              />
              <label className="mt-2" htmlFor="thirdPartyLeavesYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.thirdPartyLeaves`}
                value={'false'}
                id="thirdPartyLeavesNo"
              />
              <label className="mt-2" htmlFor="thirdPartyLeavesNo">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-12 mb-2">
          <label>Was there a Police Report taken for this incident?</label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.policeCalled`}
                value={'true'}
                id="policeReportYes"
              />
              <label className="mt-2" htmlFor="policeReportYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.policeCalled`}
                value={'false'}
                id="policeReportNo"
              />
              <label className="mt-2" htmlFor="policeReportNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incidentDetails.policeCalled`}
            component="div"
          />
        </div>
        {values?.incidentDetails?.policeCalled === 'true' && (
          <>
            <div className="form-group col-md-6">
              <TextInput
                name={`incidentDetails.policeReportDetails.policeReportNumber`}
                label="Report Number"
              />
            </div>
            <div className="form-group col-md-6">
              <TextInput
                label="Jurisdiction (County)"
                name="incidentDetails.policeReportDetails.policeReportcountry"
              />
            </div>
          </>
        )}
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            withAsterisk
            name="incidentDetails.driverStatement"
            label="Driver statement"
            showErrorEvenWhenUntouched
            placeholder="Driver statement"
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            label="Unsafe conditions"
            name="incidentDetails.unsafeCondition"
            placeholder="i.e.,Icy, wet, snow roads, uneven pavement, etc."
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Coverage Description"
            name="coverageDescription"
            defaultLabel="Select Description"
            options={allCoverageDescription}
            value={values?.coverageDescription}
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Subrogation" name="subrogation" />
        </div>
        <div className="col-md-12">
          <div className="form-group m-0">
            <label>Amount Paid (As of Date)</label>
            <input
              data-testid="amount-paid-till-date"
              className="form-control"
              label="Amount Paid"
              name="amountPaidTillDate.amount"
              placeholder="Amount"
              type="text"
              value={
                values?.amountPaidTillDate?.amount
                  ? addComma(values?.amountPaidTillDate?.amount)
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value
                const onlyNums = value.replaceAll(',', '')
                if (!isNaN(Number(onlyNums))) {
                  setFieldValue('amountPaidTillDate.amount', onlyNums)
                }
              }}
            />
          </div>
          <DatePickerField name="amountPaidTillDate.date" />
        </div>
        <div className="col-md-12">
          <div className="form-group m-0">
            <label>Amount Incurred (As of Date)</label>
            <input
              data-testid="amount-incurred-till-date"
              className="form-control"
              label="Amount Incurred"
              name="amountIncurredTillDate.amount"
              type="text"
              placeholder="Amount"
              value={
                values?.amountIncurredTillDate?.amount
                  ? addComma(values?.amountIncurredTillDate?.amount)
                  : ''
              }
              onChange={(e) => {
                const value = e.target.value
                const onlyNums = value.replaceAll(',', '')
                if (!isNaN(Number(onlyNums))) {
                  setFieldValue('amountIncurredTillDate.amount', onlyNums)
                }
              }}
            />
          </div>
          <DatePickerField name="amountIncurredTillDate.date" />
        </div>
        <div className="form-group col-md-12">
          <label>Whether occurred on company premises?</label>
          <div className="form-group d-block">
            <div className="form-check form-check-inline">
              <Field
                data-testid="company-premises-yes"
                className="form-check-input"
                type="radio"
                onChange={() => {
                  setFieldValue('incidentDetails.companyPremises', true)
                  setFieldValue(`incidentProperty`, null)
                }}
                value={true}
                name="incidentDetails.companyPremises"
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check form-check-inline">
              <Field
                data-testid="company-premises-no"
                className="form-check-input"
                type="radio"
                onChange={() => {
                  setFieldValue('incidentDetails.companyPremises', false)
                  setFieldValue(`incidentProperty`, null)
                }}
                value={false}
                name="incidentDetails.companyPremises"
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Select
            label="Triage"
            name="triage"
            defaultLabel="Select triage"
            options={yesNoOptions}
            value={values?.triage}
          />
        </div>
        <div className="col-md-6">
          <TextInput label="Gensuite ID" name="gensuiteID" />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="dateReportedToDSP"
            label="Date Reported to DSP"
          />
        </div>
        <div className="col-md-6">
          <Select
            label="OSHA Recordable"
            name="oshaRecordable"
            defaultLabel="Select Option"
            options={yesNoOptions}
            value={values?.oshaRecordable}
          />
        </div>
        <div className="col-md-12">
          <TextArea
            cols={30}
            rows={5}
            label="Recordability Rationale?"
            name="recordabilityRational"
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            data-testid="return-to-work-date"
            name="returnToWorkDate"
            defaultValue={values?.managerFormDetails?.returnDate}
            withAsterisk={values?.claimStatus === 'Closed'}
            label="Return to Work Date"
          />
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between">
        <button
          data-testid="btn-back"
          className="btn text-primary pl-0"
          onClick={() => onNavigate(1)}
        >
          <i
            className={'fas fa-chevron-left'}
            aria-hidden={true}
            style={{ paddingRight: '4px' }}
          />
          Back
        </button>
        <button
          data-testid="btn-next"
          type="button"
          className="btn btn-primary pull-right"
          onClick={() => onNavigate(3)}
        >
          Continue
        </button>
      </div>
    </>
  )
}
