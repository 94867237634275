import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/dashboard`

//new dashboard api call
export function getDashboardTotal(object){
  return http.post(`${apiEndpoint}/get-dashboardTotals`,object)
}

export function getAccidentReportData(object){
  return http.post(`${apiEndpoint}/get-accidentReport`,object)
}

export function getincidentPaidVSIncurredReportData(object){
  return http.post(`${apiEndpoint}/get-incidentPaidVSIncurredReport`,object)
}

export function getEmployeesReportData(object){
  return http.post(`${apiEndpoint}/get-employeesReport`,object)
}

export function getVehiclesReportData(object){
  return http.post(`${apiEndpoint}/get-vehiclesReport`,object)
}

export function getClaimsIncurredReportData(object){
  return http.post(`${apiEndpoint}/get-claimsIncurredReport`,object)
}

export function getopenAndclosedClaimsReportQueryData(object){
  return http.post(`${apiEndpoint}/get-openAndclosedClaimsReportQuery`,object)
}

