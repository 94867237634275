import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PublicIncidentHeader from '../../components/Incidents/PublicIncidentHeader';
import { getPublicIncidentAttachments } from '../../services/publicIncidentService';


function UploadAttachment() {
  const { token } = useParams()
  const [dataState, setDataState] = useState({
    isLoading: true,
    isError: false,
    msg: undefined,
    data: undefined
  })

  const getData = async (viewToken) => {
    try {
      const result = await getPublicIncidentAttachments(viewToken)
      setDataState(prev => ({
        ...prev,
        data: result.data?.data ?? {},
        isLoading: false,
        isError: false,
        msg: undefined
      }))
    } catch (error) {
      let msg = "Unknown error occurred!"
      if (axios.isAxiosError(error) && error.response?.data?.msg) {
        msg = error.response?.data?.msg
      }

      setDataState(prev => ({
        ...prev,
        isLoading: false,
        isError: true,
        msg
      }))
    }
  }

  useEffect(() => {
    getData(token)
  }, [token])

  return (
    <>
      <PublicIncidentHeader />
      {
        dataState.isLoading && (<div style={{ paddingTop: 120 }}>
          <p className='text-center'>Please wait...</p>
        </div>)
      }
      {
        dataState.isError && (<div style={{ paddingTop: 120 }}>
          <p className='text-center'>{dataState.msg}</p>
        </div>)
      }
      <div className='upload-doc-outer'>
        {
          dataState.data?.attachments && Array.isArray(dataState.data.attachments) && (
            <div className="uploaded-documents">
              <div className="mb-2 title-wrapper border-bottom">
                <h1>Documents</h1>
              </div>
              <div className="doc-outer">
                {
                  dataState.data.attachments.length === 0 && <p>No attachments...</p>
                }
                {
                  dataState.data.attachments.map((content, index) => <ContentLoader key={`${index}__${content}`} url={content} />)
                }
              </div>
            </div>)
        }
        {
          dataState.data?.managersAttachments && Array.isArray(dataState.data.managersAttachments) && (

            <div className="uploaded-documents">
              <div className="mb-2 title-wrapper pt-4 border-bottom">
                <h1>Manager Files</h1>
              </div>
              <div className="doc-outer">
                {
                  dataState.data.managersAttachments.length === 0 && <p>No attachments...</p>
                }
                {
                  dataState.data.managersAttachments.map((content, index) => <ContentLoader key={`${index}__${content}`} url={content} />)
                }
              </div>
            </div>)
        }
      </div>
    </>
  )
}

export default UploadAttachment



function ContentLoader({ url }) {
  function extractFileNameAndExtension(urlString) {
    const url = new URL(urlString)
    const filename = url.pathname.split('/').pop();
    const parts = filename.split('.');
    if (parts.length > 1) {
      return [filename, `.${parts.pop()}`];
    }
    return [filename, ''];
  }

  const [filename, extension] = extractFileNameAndExtension(url)

  if (/\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(extension)) {
    return (<div className="doc-wrapper">
      <div className="img-wrapper">
        <img src={url}></img>
      </div>
      <span>{filename}</span>
      <a href={url} target="_blank" className='btn btn-primary'>Download</a>
    </div>)
  }

  return (<div className="doc-wrapper">
    <div className="img-wrapper">
      <img src="../assets/images/preview.svg"></img>
      <p>
        No Preview <br /> Available
      </p>
    </div>
    <span>{filename}</span>
    <a href={url} target="_blank" className='btn btn-primary'>Download</a>
  </div>)

}