import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActionTypes from '../actionTypes'
import { timeNowFormat } from '../configs/utils'
import { markNotificationRead } from '../services/notificationService'

const NotificationLogs = ({ showUnread }) => {
  const notifications = useSelector((state) => state.notifications)
  const dispatch = useDispatch()

  if (!notifications || !notifications?.length) {
    return (
      <div className="d-flex justify-content-center p-3">
        No Notification Found
      </div>
    )
  }
  return notifications
    ?.filter((x) => {
      if (showUnread) {
        return !x.isRead
      }
      return true
    })
    ?.map(({ notification, isRead }) => (
      <div key={notification._id} className="navi-item font-weight-normal nofy">
        <div className="img-box active border-bottom px-3 d-flex justify-content-between">
          <div className="pro-box d-flex mr-2 bg-none">
            <div className="log log-txt d-flex justify-content-center">
              <span>
                {`${notification?.user?.firstName.charAt(0)}${notification?.user?.lastName?.charAt(0)}`}{' '}
              </span>
            </div>
          </div>
          <div className="r-box ">
            <a href="#">
              <div className="text-dark navi-text font-weight-normal">
                {notification.title}
              </div>
              <p className="navi-text">
                {timeNowFormat(notification.created)}
              </p>
            </a>
          </div>

          <input
            type="radio"
            name={notification._id}
            className="notification-radio"
            checked={isRead}
            onChange={async () => {
              await markNotificationRead(notification._id)
              dispatch({
                type: ActionTypes.MARK_NOTIFICATION_READ,
                id: notification._id,
              })
            }}
          />
        </div>
      </div>
    ))

  // (
  //   <>

  //     <div className="navi-item font-weight-normal nofy">
  //       <div className="img-box active border-bottom px-3 d-flex justify-content-between">
  //         <div className="pro-box d-flex mr-2 bg-none">
  //           <a href="#">
  //             {' '}
  //             <img src="../assets/images/history.png" alt="" />
  //           </a>
  //         </div>
  //         <div className="r-box ">
  //           <a href="#">
  //             <div className="text-dark navi-text font-weight-normal">
  //               <strong>Guest User</strong> Created{' '}
  //               <strong>Incident INC-2021-07-781</strong> and added{' '}
  //               <strong>#USR10001</strong> in <strong>Parties Involved</strong>
  //             </div>
  //             <p className="navi-text">15 minutes ago</p>
  //           </a>
  //         </div>

  //         <div className="">
  //           <span
  //             className="d-flex align-items-center border-notify bookmark-notify bookmarknotify test"
  //             data-toggle="tooltip"
  //             data-placement="top"
  //             title=""
  //             data-original-title="Mark as unread"
  //           >
  //             <div className="dot vision-notify cool"></div>
  //           </span>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="navi-item font-weight-normal nofy">
  //       <div className="img-box active border-bottom px-3 d-flex justify-content-between">
  //         <div className="pro-box d-flex mr-2 bg-none">
  //           <a href="#">
  //             <img src="../assets/images/history.png" alt="" />
  //           </a>
  //         </div>
  //         <div className="r-box">
  //           <a href="#">
  //             <div className="text-dark navi-text font-weight-normal">
  //               <strong>John Doe</strong> Changed{' '}
  //               <strong>Employee Status</strong> to
  //               <strong>Terminated add</strong> added{' '}
  //               <strong>p of Termination</strong> for #USR10001
  //             </div>
  //             <p className="navi-text">23:59 hrs ago</p>
  //           </a>
  //         </div>

  //         <div className="">
  //           <span
  //             className="d-flex align-items-center border-notify bookmark-notify bookmarknotify test"
  //             data-toggle="tooltip"
  //             data-placement="top"
  //             title=""
  //             data-original-title="Mark as unread"
  //           >
  //             <div className="dot vision-notify cool"></div>
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // )
}

export default NotificationLogs
