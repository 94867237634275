import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Table from '../../components/common/Table'
import { cloneRole, deleteRoleData, getRolesList } from '../../services/roleService'

function RolesList({ fetchRoleDetails }) {
  const columns = [
    { path: 'name', label: ' Name ', type: 'string' },
    { path: 'description', label: ' Description ', type: 'string' },
    { path: 'user', label: ' User ' },
    { path: 'createdBy', label: ' Created By ' },
    { path: 'action', label: '' },
  ]
  const [sortColumn] = useState(null)
  const [data, setData] = useState([])
  const [checkedRecords] = useState([])

  const { selectedClientId } = useSelector((state) => state)
  useEffect(() => {
    fetchList()
  }, [selectedClientId])

  const deleteRole = async (roleId) => {
    try {
      await deleteRoleData(roleId)
      const list = [...data].filter(c=>c.id !== roleId);
      setData(list);
      toast.success('Role delete successfully')
    } catch (error) {
      if (error?.response?.status !== 500 && error?.response?.data?.msg?.err) {
        toast.error(error?.response?.data?.msg?.err)
      } else {
        toast.error('Some error occured')
      }
    }
  }

  const fetchList = async () => {
    const { data } = await getRolesList()
    let processedData = []
    data.data.forEach((value, index) => {
      let f = 'A'
      let l = 'M'
      if (value?.role?.createdBy?.firstName) {
        f = value?.role?.createdBy?.firstName?.at(0) || 'A'
        l = value?.role?.createdBy?.lastName?.at(0) || 'M'
      }
      processedData.push({
        id: value.role._id,
        name: value.role.roleName,
        description: value.role.description,
        user:
          value.userCount === 0
            ? '-'
            : value.userCount === 1
            ? value.userCount + ' User'
            : value.userCount + ' Users',
        createdBy: (
          <div className="align-middle">
            <a href="#" className="codeal">
              {f}
              {l}
            </a>
          </div>
        ),
        action: (
          <div className="align-middle">
            <button
              onClick={async () => {
                await cloneRole(value.role._id)
                fetchList()
              }}
              type="button"
              className="btn btn-outline-secondary side-btn"
            >
              <i className="far fa-copy" aria-hidden="true"></i>
            </button>

            <button
              type="button"
              onClick={fetchRoleDetails.bind(this, value.role._id)}
              className="btn btn-outline-secondary side-btn  mx-3"
            >
              <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                deleteRole(value.role._id, index)
              }}
              className="btn btn-outline-secondary side-btn"
            >
              <i className="far fa-trash-alt" aria-hidden="true"></i>
            </button>
          </div>
        ),
      })
    })
    setData(processedData)
  }

  return (
    <Table
      data={data}
      columns={columns}
      hideCheckbox={true}
      sortColumn={sortColumn}
      className={'pl-2 user-mang assetmanag '}
      checkedItems={checkedRecords}
    />
  )
}

export default RolesList
