import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useOutsideClick } from 'rooks'

import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
} from '../../actions'
import { getClaim } from '../../services/claimService'
import { getIncident } from '../../services/incidentService'
import { useHistory } from 'react-router-dom'

export default function CalendarSection() {
  const dispatch = useDispatch()
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)

  const [position, setPosition] = useState({
    isOpen: 'close',
    top: 0,
    left: 0,
    date: '',
  })

  const calendarInfo = useSelector((state) => state.calendarInfo)

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  return (
    <>
      <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
        <div className="mt-5"></div>
        <div className="sidebar-sec">
          <div className=" my-3" style={{ position: 'relative' }}>
            <div className="card-header-2 mb-2 mt-3">
              <div className="d-flex bd-highlight mb-4">
                <div className="bd-highlight mr-3">
                  <h2 className="mb-0 pb-0 dark-text">Calendar</h2>
                </div>

                <div className="ml-auto bd-highlight">
                  <a className="text-dark search" onClick={handleSideNavClose}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.829"
                        height="17.828"
                        viewBox="0 0 17.829 17.828"
                      >
                        <g
                          id="Group_9756"
                          data-name="Group 9756"
                          transform="translate(-4013.659 13.151)"
                        >
                          <path
                            id="path1419"
                            d="M-1479.2-17.087l15-15"
                            transform="translate(5494.273 20.35)"
                            fill="none"
                            stroke="#828282"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          ></path>
                          <path
                            id="path1421"
                            d="M-1479.2-32.087l15,15"
                            transform="translate(5494.273 20.35)"
                            fill="none"
                            stroke="#828282"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ position: 'relative' }}>
              <FullCalendar
                selectable={true}
                plugins={[bootstrapPlugin, dayGridPlugin, interactionPlugin]}
                themeSystem="bootstrap"
                initialView="dayGridMonth"
                initialDate={moment().format('yyyy-MM-DD')}
                buttonText={{ today: 'Today', prev: '<', next: '>' }}
                headerToolbar={{
                  left: 'title',
                  right: 'today prev,next',
                }}
                events={[
                  ...(calendarInfo?.incident || []),
                  ...(calendarInfo?.claim || []),
                  ...(calendarInfo?.policy || []),
                ]}
                dateClick={(info) => {
                  const element = info.dayEl.querySelector(
                    'div.fc-daygrid-day-events > div > a',
                  )
                  if (element) {
                    const date = info.date.toISOString()
                    const { bottom: top, left } =
                      element.getBoundingClientRect()
                    setPosition({ isOpen: 'open', date, top, left: left })
                  } else {
                    setPosition({ isOpen: 'close', date: '', top: 0, left: 0 })
                  }
                }}
                allDay={true}
                dayMaxEventRows={3}
                bootstrapFontAwesome={false}
              />
              {position.isOpen === 'open' && (
                <Popup
                  left={position.left}
                  top={position.top}
                  events={Object.values(calendarInfo).flatMap((info) => {
                    return info.filter(({ start }) => start === position.date)
                  })}
                  onClose={() =>
                    setPosition({ isOpen: 'close', date: '', top: 0, left: 0 })
                  }
                  onResize={({ top, left }) => {
                    setPosition((s) => ({ ...s, top, left }))
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Popup = ({ onResize, top, left, events, onClose }) => {
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const [isResize, setResize] = useState(true)
  const ref = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()

  useOutsideClick(ref, () => {
    setResize(true)
    onClose()
  })

  useEffect(() => {
    if (ref.current && isResize) {
      let fTop = top,
        fLeft = left
      const {
        top: mTop,
        left: mLeft,
        bottom,
        right,
        height,
        width,
      } = ref.current.getBoundingClientRect()
      if (bottom + height >= window.innerHeight) {
        // total height of block is 200.
        fTop = top - 150
      }

      if (right + 30 >= window.innerWidth) {
        fLeft = mLeft - width * (2 / 3)
      }

      if (
        Math.floor(fTop) !== Math.floor(top) ||
        Math.floor(fLeft) !== Math.floor(left)
      ) {
        setResize(false)
        onResize({ top: fTop, left: fLeft })
      }
    }
  }, [ref.current, isResize])

  const getDueDateMessage = (date) => {
    const currentDate = moment().format('MM/DD/YYYY')
    const fDate = moment(date).format('MM/DD/YYYY')
    const overDue = fDate < currentDate
    const dueNow = fDate === currentDate
    let message = ''
    if (overDue) {
      message = 'OVER DUE'
    } else if (dueNow) {
      message = 'DUE NOW'
    } else {
      message = `Due in ${moment(date).diff(moment(), 'days') + 1} days`
    }
    return message
  }
  return (
    <div
      ref={ref}
      style={{ left, top }}
      id="main1"
      className="calendarPopover calendrop border-radius-16"
    >
      <div className="accordion" id="faq">
        {events.map(
          ({ start = '', title = '', party = [], _id = '' }, index) => (
            <div className="card bg-none" key={index}>
              <div className="card-header1 px-2" id={`faqhead${title}${index}`}>
                <a
                  href="#"
                  className="btn btn-header-link text-white ft-12 collapsed"
                  data-toggle="collapse"
                  data-target={`#faq${title}${index}`}
                  aria-expanded="false"
                  aria-controls={`faq${title}${index}`}
                >
                  {title}
                </a>
              </div>

              <div
                id={`faq${title}${index}`}
                className="collapse"
                aria-labelledby={`faqhead${title}${index}`}
                data-parent="#faq"
              >
                <div className="px-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      {party && !!party.length && (
                        <div>
                          <small className="ft-12 text-white">
                            Parties Invloved
                          </small>
                          <div className="d-flex">
                            {party?.map(
                              ({ firstName, lastName }, partyIndex) => (
                                <span
                                  className="rounded-circle text-white calender-badge"
                                  key={partyIndex}
                                  style={{
                                    padding: '0 5px',
                                    backgroundColor:
                                      '#' +
                                      Math.floor(
                                        Math.random() * 16777215,
                                      ).toString(16),
                                  }}
                                >
                                  {' '}
                                  <small>{`${firstName
                                    .substring(0, 1)
                                    .toUpperCase()}${lastName
                                    .substring(0, 1)
                                    .toUpperCase()}`}</small>
                                </span>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <small
                      style={{ cursor: 'pointer' }}
                      onClick={async () => {
                        if (title.split('-')[0] == 'CLM') {
                          const { data: claims } = await getClaim(_id)
                          dispatch(handleTabIdUpdated('claim'))
                          dispatch(
                            handleSideNavDataUpdated(
                              {
                                data: claims.data,
                                module: 'claim',
                              },
                              'searchView',
                            ),
                          )
                          if (selectedTabId !== 'claim' && selectedTabId !== 'incident') {
                            history.push('/incidents')
                          }
                          dispatch(handleSideNavElementClicked('info'))
                        } else if (title.split('-')[0] == 'INC') {
                          const { data: incident } = await getIncident(_id)
                          dispatch(handleTabIdUpdated('incident'))
                          dispatch(
                            handleSideNavDataUpdated(
                              { data: incident.data, module: 'incident' },
                              'searchView',
                            ),
                          )
                          if (selectedTabId !== 'claim' && selectedTabId !== 'incident') {
                            history.push('/incidents')
                          }
                          dispatch(handleSideNavElementClicked('info'))
                        }
                      }}
                    >
                      <span
                        className="badge badge-primary px-3"
                        style={{ borderRadius: '30px' }}
                      >
                        More
                      </span>
                    </small>
                  </div>
                  <div className="pull-right">
                    <small className="text-white">
                      {getDueDateMessage(start)}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  )
}
