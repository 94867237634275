import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { handleSideNavElementClicked } from '../../actions'
import { useCommentAccess, useGetPermission } from '../../hooks/useGetPermission'
import { getClient } from '../../services/clientService'

// TODO:- N/A fields need to be checked and verified.
export default function VehicleRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const deletePermission = useGetPermission('vehicle', 'DELETE')
  const editPermission = useGetPermission('vehicle', 'UPDATE')
  const addCommentPermission = useCommentAccess('vehicle', 'CREATE')
  
  const [clientInfo, setclientInfo] = useState({});

  useEffect(async() => {
    const { data: client } = await getClient(data.clientId)
    setclientInfo(client.data);
  }, [data])

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
            <div
                className="mr-3 d-flex flex-column bd-highlight text-center mt-1"
                data-toggle="tooltip"
                data-placement="top"
                title={`${data?.firstName} ${data?.lastName}`}
                data-original-title={`${data?.firstName} ${data?.lastName}`}
              >
                <div>
                  <img
                    className="profile-pic"
                    src={data?.image || '/assets/images/user-profile.svg'}
                    id="wizardPicturePreview"
                    style={{height:'80px', width: '80px'}}
                  />
                </div>
              </div>
            </div>
            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.vehicleId}</div>
                {_.capitalize(clientInfo.companyName || 'N/A')}
              </span>
              <p className="mt-3"></p>
            </div>
            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          <div className="text-center px-5 pb-2">
          {addCommentPermission && (
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={() => dispatch(handleSideNavElementClicked('comment'))}
            >
              Add Comment
            </button>
          )}
          {editPermission && (
            <button
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
              onClick={onEdit}
            >
              <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
          )}
          {deletePermission && (
            <button
              type="button"
              className="btn btn-outline-secondary side-btn"
              onClick={onDelete}
            >
              <i className="far fa-trash-alt" aria-hidden="true"></i>
            </button>
          )}
          </div>
        </div>
        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="false"
                      aria-controls="faq1"
                    >
                      Vehicle Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Make</div>
                          <span> {_.capitalize(data?.make || 'N/A')}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Model</div>
                          <span>{data?.model || 'N/A'}</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Vehicle Status</div>
                          <span className="text-warning">{data?.vehicleStatus || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Vehicle Owned or Leased</div>
                          <span>{data?.vehicleType || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Cost at the time of purchase</div>
                          <span>${data?.purchaseCost || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Date of Purchase/Leased </div>
                          <span>
                            {data?.dateOfPurchase ?
                              moment(data?.dateOfPurchase).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Year of Manufacture</div>
                          <span>{data?.manufactureYear || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Date Sold (if applicable)</div>
                          <span>{data?.dateOfSold ?
                              moment(data?.dateOfSold).format('MM/DD/YYYY') : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Vehicle Condition</div>
                          <span>{_.capitalize(data?.conditionAtPurchase) || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Miles Traveled</div>
                          <span>{data?.milesTravelled || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> VIN Number</div>
                          <span>{data?.vinNumber || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> VAN Number</div>
                          <span>{data?.vanNumber || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> License Plate</div>
                          <span>{data?.liscence || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Select Driver</div>
                          <span>
                            {_.capitalize(data?.driverInfo?.driverName.split(',')[0] + ', ' || '')} {_.capitalize(data?.driverInfo?.driverName.split(',')[1] || '')}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Vehicle Insurance Details</div>
                          <span>{data?.policyDetails?.policyName || 'N/A'}</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Rental Agreement Number</div>
                          <span>{data?.leaseAgreementNumber || 'N/A'}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header1" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="false"
                      aria-controls="faq2"
                    >
                      Vehicle Address
                    </a>
                  </div>

                  <div
                    id="faq2"
                    className="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    <div className=" ">
                      <div className="parties bg-white p-3 mb-3 claim-taken-view mt-4 ml-2 mr-2">
                        <div className="mb-2 d-flex justify-content-between">
                          <div>
                            <i
                              className="fas fa-map-marker-alt mr-2"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div>{data?.vehicleAddress || 'N/A'}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
