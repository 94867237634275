import React, { useContext, useState } from 'react'
import ExtraSettingsModal from './ExtraSettingsModal'
import ModuleSelectionAlertModal from './ModuleSelectionAlertModal'
import RolesContext from './RolesContext'

const ModuleRow = ({ permissionObj, Operations, index }) => {
  const [modalData, setModalData] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const formik = useContext(RolesContext)

  const rowChecked = (module) => {
    let shouldBeChecked = true
    formik.values.permissions.forEach((data) => {
      if (data.moduleName === module) {
        if (data.operations.length !== 4) shouldBeChecked = false
      }
    })
    return shouldBeChecked
  }

  const handleModuleSelectionChange = (module, add) => {
    const operations = ['CREATE', 'DELETE', 'READ', 'UPDATE']
    let filterData
    if (add) {
      filterData = formik.values.permissions.map((data) => {
        if (data.moduleName === module) {
          data.operations = [...operations]
        }
        return data
      })
    } else {
      filterData = formik.values.permissions.map((data) => {
        if (data.moduleName === module) {
          data.operations = []
        }
        return data
      })
    }

    formik.setValues((v) => {
      return {
        ...v,
        permissions: [...filterData],
      }
    })
  }

  const operationSelected = (module) => {
    let shouldBeChecked = true
    formik.values.permissions.forEach((data) => {
      if (data.moduleName === module) {
        if (data.operations.length === 0) {
          shouldBeChecked = false
        }
      }
    })
    setAlertModal(!shouldBeChecked)
    setModalData(shouldBeChecked)
  }


  const disable = permissionObj.accessMode === 'NO_ACCESS'
  return (
    <tr
      key={index}
      style={{
        pointerEvents:
          permissionObj.accessMode === 'NO_ACCESS' ? 'none' : 'auto',
      }}
    >
      <td>
        <input
          disabled={disable}
          type="checkbox"
          value={permissionObj.moduleName}
          checked={rowChecked(permissionObj.moduleName)}
          onChange={(e) => {
            handleModuleSelectionChange(
              permissionObj.moduleName,
              e.target.checked,
            )
          }}
        />
      </td>
      <td>{permissionObj.moduleName}</td>
      {Operations?.map((operation) => (
        <td key={operation}>
          {operation === 'DELETE' ? (
            <div className="text-center ">
              <div className="d-flex justify-content-between">
                <div className="text-center mt-2">
                  <input
                    disabled={disable}
                    className="ml-3 "
                    type="checkbox"
                    checked={permissionObj?.operations.includes(operation)}
                    name={`permissions[${index}.operations`}
                    value={operation}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <button
                    onClick={() => {
                      operationSelected(permissionObj.moduleName)
                    }}
                    className="brn bg-white btn btn-outline-light text-dark px-3 sets"
                  >
                    <i className="fa fa-gear"></i>
                    Settings
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center mt-2">
              <input
                disabled={disable}
                className=""
                type="checkbox"
                checked={permissionObj?.operations.includes(operation)}
                value={operation}
                name={`permissions[${index}].operations`}
                onChange={formik.handleChange}
              />
            </div>
          )}
        </td>
      ))}

      {modalData && (
        <ExtraSettingsModal
          isOpen={modalData}
          permissionObj={permissionObj}
          // index={index}
          onClose={() => {
            setModalData(false)
          }}
        />
      )}
      {alertModal && (
        <ModuleSelectionAlertModal
          isOpen={alertModal}
          onClose={() => setAlertModal(false)}
        />
      )}
    </tr>
  )
}

export default ModuleRow
