import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavDataUpdated, saveIncidentFormData } from '../../actions'
import AttachmentAddView from '../../components/AttachmentAddView'
import AreYouSureModal from '../../components/common/AreYouSureModal'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import IncidentDetailsTab from '../../components/Incidents/IncidentDetailsTab2'
import { getIncident } from '../../services/incidentService'
import { generateUID } from '../../utils/generateUID'
import { INCIDENT_VALIDATION_SCHEMA } from '../../utils/validation'
import { handleIncidentSave } from '../ClaimsAndIncidents/ClaimAndIncidentsCommonFunc'
import PartiesInvolvedCard from './PartiesInvolvedCard'
import PartiesInvolvedModal from './PartiesInvolvedModal'
import PropertiesInvolvedCard from './PropertiesInvolvedCard'
import PropertyInvolvedModal from './PropertyInvolvedModal'
import {
  allPriorityStatusTypes,
  ReportedViaTypes,
  vehicleDamageOptions,
} from './utils'
import VehicleInvolvedCard from './VehicleInvolvedCard'
import VehicleInvolvedModal from './VehicleInvolvedModal'
import WitnessCard from './WitnessCard'
import WitnessModal from './WitnessModal'
import { timezones } from '../incident/utils'
import TextArea from '../../components/form/TextArea'

export default function IncidentForm({
  match,
  data,
  title,
  onClose,
  context,
  activeStep,
  setActiveStep,
  setClaimData,
}) {
  const ref = useRef()
  const dispatch = useDispatch()
  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const [isNew, setIsNew] = useState(false)
  const [isVehicular, setIsVehicular] = useState(
    data ? data?.vehicleNature !== 'non-vehicular' : true,
  )

  const [values, setValues] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openNarrative, setOpenNarrative] = useState(false)
  const [showAddPartiesInvolvedModal, setShowAddPartiesInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showAddVehicleInvolvedModal, setShowAddVehicleInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showPropertyInvolvedModal, setShowPropertyInvolvedModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })
  const [showWitnessModal, setShowWitnessModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [partiesInvolvedValidation, setPartiesInvolvedValidation] =
    useState(false)

  const closeModal = () => setOpenModal(false)

  const newIncident = useMemo(
    () => ({
      clientId: '',
      timezone:
        timezones.filter((f) => f.key === moment.tz.guess()).length > 0
          ? moment.tz.guess()
          : '',
      vehicularAccident: isVehicular,
      // dateOfIncident: null,
      // time: '',
      // firstName: '',
      // lastName: '',
      // email: '',
      // companyName: '',
      returnToWorkDate: '',
      shiftStartTime: '00:00:00 AM',
      incidentNarrative: [],
      packageNumber: '',
      // companyPremises: true,
      priority: '',
      incidentProperty: null,
      attachments: [],
      witnesses: [],
      propertiesInvolved: [],
      partiesInvolved: [],
      vehiclesInvolved: [],
      coverageDescription: '',
      claimType: '',
      // amountPaid: '',
      // amountIncurred: '',
      claimFinancials: [],
      reportedVia: '',
      incidentDetails: {
        dateOfIncident: null,
        time: '00:00:00 AM',
      },
      supervisorDetails: {
        isNotify: 'true',
      },
      userDetails: {
        employeeId: 'OTHER',
        employeeStatus: '',
      },
      incidentHandledBy: '',
      incidentId: generateUID('incident'),
      nextAction: '',
    }),
    [isVehicular],
  )

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      reportedVia: 'Public Form',
    }))
    if (data) {
      setIsNew(false)
      data.supervisorDetails = {
        ...(data.supervisorDetails ?? {}),
        ...(data.managerFormDetails ?? {}),
        ...{
          name:
            data.managerFormDetails?.supervisorName ??
            data.supervisorDetails?.name,
          contactNumber:
            data.managerFormDetails?.supervisorContactNumber ??
            data.supervisorDetails?.contactNumber,
          notifiedDate:
            data.managerFormDetails?.dateOfReported ??
            data.supervisorDetails?.notifiedDate,
          email:
            data.managerFormDetails?.supervisorEmail ??
            data.supervisorDetails?.email,
        },
      }
      const userDetails = data?.userDetails
      const incidentDetails = data?.incidentDetails

      const supervisorDetails = data?.supervisorDetails

      let fetchedClientId = ''
      if (data?.clientId && Array.isArray(clients)) {
        const clientData = clients.find(
          ({ companyName }) =>
            companyName?.toLowerCase() === data.clientId.toLowerCase(),
        )
        if (clientData) {
          fetchedClientId = clientData._id
        }
      }
      data.clientId =
        fetchedClientId !== ''
          ? fetchedClientId
          : selectedClientId && selectedClientId !== 'allClients'
          ? selectedClientId
          : ''
      // ref.current.setFieldValue("userDetails.employeeId", "OTHER")

      // Remove objectId from data is present
      delete data._id
      // Get comapny and third party vehicle data and set to parties involved modal if company employee with company vehicle and third party employee with third party vehicle
      const companyVehicleDetails = Array.isArray(data?.vehiclesInvolved)
        ? data.vehiclesInvolved.filter(
            (vehicle) => vehicle?.vehicleDetails?.vehicleType === 'company',
          )[0]
        : null
      const thirdPartyVehicleDetails = Array.isArray(data?.vehiclesInvolved)
        ? data.vehiclesInvolved.filter(
            (vehicle) => vehicle?.vehicleDetails?.vehicleType !== 'company',
          )[0]
        : null
      setValues((prev) => ({
        ...prev,
        ...data,
        userDetails: {
          ...data.userDetails,
          employeeId: 'OTHER',
          employeeStatus: '',
        },
        claimStatus: 'open',
        incidentDetails: {
          ...data.incidentDetails,
          thirdPartyLeaves: data?.incidentDetails?.thirdPartyLeaves?.toString(),
          policeCalled: data?.incidentDetails?.policeCalled?.toString(),
        },
        triage:
          Array.isArray(data?.incidentDetails?.medicalTreatmentType) &&
          data?.incidentDetails?.medicalTreatmentType.includes('triage')
            ? 'Yes'
            : 'No',
        returnToWorkDate: data?.managerFormDetails?.returnDate,
        // firstName: userDetails?.firstName ?? "",
        // lastName: userDetails?.lastName ?? "",
        // email: userDetails?.email ?? "",
        // dateOfIncident: incidentDetails?.dateOfIncident ?? null,
        witnesses:
          incidentDetails?.hasWitnesses &&
          Array.isArray(incidentDetails?.witnesses)
            ? incidentDetails?.witnesses?.map((witness) => {
                const name = witness?.name?.split(' ')
                return {
                  firstName: name?.shift(),
                  lastName: name?.join(' '),
                  contactNumber: witness?.contactNumber,
                }
              })
            : [],
        // time: incidentDetails?.time ?? "",
        // incidentNarrative: incidentDetails?.driverStatement ? [
        //   {
        //     narrativeBy: `${userDetails?.firstName} ${userDetails?.lastName}`,
        //     narrative: incidentDetails?.driverStatement ?? "",
        //     narrativeDate: moment(incidentDetails?.dateOfIncident).toDate()
        //   }
        // ] : [],
        partiesInvolved: [
          {
            isCompanyEmployee: true,
            hasInjury: incidentDetails?.isInjury === 'true' ?? false,
            injuryDetails: {
              medicalTreatmentRequired:
                incidentDetails?.isMedicalTreatment === 'true' ?? false,
              natureOfInjury: incidentDetails?.natureOfInjury,
              ambulanceStatus: incidentDetails?.isAmbulance,
              injuryTypes: data?.humanParts,
              painLevel: incidentDetails?.painLevel,
            },
            personDetails: {
              firstName: userDetails?.firstName || '',
              lastName: userDetails?.lastName || '',
              email: userDetails?.email || '',
              organisation: '',
              contactNumber: userDetails?.contactNumber || '',
              address: {
                line1: userDetails?.address || '',
                line2: '',
                city: userDetails.city || '',
                state: userDetails.state || '',
                country: userDetails.country || '',
                pinCode: userDetails.zip || '',
              },
              employeeId: 'OTHERS',
            },
            vehicleDetails: {
              vehId: companyVehicleDetails ? 'OTHERS' : 'OTHERS',
              make: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.make
                : '',
              model: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.model
                : '',
              year: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.yearOfManufacture
                : '',
              vinNumber: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.vin
                : '',
              mileage: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.mileage
                : '',
              vanNumber: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.van
                : '',
              state: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.damageState
                : '',
              licensePlate: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.licensePlate
                : '',
              vehicleDamages:
                companyVehicleDetails &&
                Array.isArray(
                  companyVehicleDetails?.vehicleDetails?.vehicleDamageParts,
                )
                  ? vehicleDamageOptions
                      .filter((f) =>
                        companyVehicleDetails?.vehicleDetails?.vehicleDamageParts.includes(
                          f.label,
                        ),
                      )
                      .map((e) => e.value)
                  : [],
              vehicleType: companyVehicleDetails
                ? companyVehicleDetails?.vehicleDetails?.vehicleType
                : '',
            },
            // vehiclesInvolved: data?.vehiclesInvolved
          },

          Boolean(incidentDetails?.isThirdPartyPresent) === true
            ? {
                isCompanyEmployee: false,
                personDetails: {
                  ...(() => {
                    const name =
                      incidentDetails?.thirdPartyDetails?.thirdPartyName?.split(
                        ' ',
                      )
                    if (Array.isArray(name)) {
                      return {
                        firstName: name.shift(),
                        lastName: name.join(' '),
                      }
                    }
                    return {}
                  })(),
                  contactNumber:
                    incidentDetails?.thirdPartyDetails?.thirdPartyContactNumber,
                },
                hasInjury: false,
                injuryDetails: {
                  medicalTreatmentRequired: false,
                  natureOfInjury: '',
                  ambulanceStatus: '',
                  injuryTypes: [],
                  painLevel: 0,
                },
                vehicleDetails: {
                  make: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.make
                    : '',
                  model: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.model
                    : '',
                  year: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails
                        ?.yearOfManufacture
                    : '',
                  vinNumber: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.vin
                    : '',
                  vehId: '',
                  mileage: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.mileage
                    : '',
                  vanNumber: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.van
                    : '',
                  vehicleDamages:
                    thirdPartyVehicleDetails &&
                    Array.isArray(
                      thirdPartyVehicleDetails?.vehicleDetails
                        ?.vehicleDamageParts,
                    )
                      ? vehicleDamageOptions
                          .filter((f) =>
                            thirdPartyVehicleDetails?.vehicleDetails?.vehicleDamageParts.includes(
                              f.label,
                            ),
                          )
                          .map((e) => e.value)
                      : [],
                  licensePlate: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.licensePlate
                    : '',
                  state: thirdPartyVehicleDetails
                    ? thirdPartyVehicleDetails?.vehicleDetails?.damageState
                    : '',
                  otherVehicleDamages: '',
                },
              }
            : null,
        ].filter(Boolean),
        companyName: data?.clientId ?? '',
        startDate: data?.created,
        incidentHandledBy: supervisorDetails?.name ?? '',
        vehiclesInvolved: [],
        // vehiclesInvolved: Array.isArray(data?.vehiclesInvolved) ? data?.vehiclesInvolved?.map(e => ({
        //   ...e,
        //   isThirdPartyVehicle: e.vehicleType !== "company",
        //   vehicleDetails: {
        //     ...(e?.vehicleDetails ?? {}),
        //     year: e?.vehicleDetails?.yearOfManufacture,
        //     vinNumber: e?.vehicleDetails?.vin,
        //     vehId: "OTHERS"
        //   },
        //   vehicleDamages: e?.vehicleDetails?.vehicleDamageParts,
        //   driverDetails: {
        //     ...(userDetails ?? {}),
        //     "empId": "OTHERS",
        //     "employeeId": "OTHERS",
        //     address: {
        //       line1: userDetails?.address,
        //       line2: "",
        //       country: userDetails?.country,
        //       pinCode: userDetails?.zip,
        //       city: userDetails?.city,
        //       state: userDetails?.state
        //     }
        //   }
        // })) : []
      }))
      dispatch(
        handleSideNavDataUpdated({ data: data, module: 'public-incident' }),
      )
    } else {
      setIsNew(true)
      setValues(newIncident)
    }
  }, [data])

  const fetchIncident = async (id) => {
    const { data: incident } = await getIncident(id)
    setValues(incident.data)
  }

  const handleSubmit = async (values) => {
    if (values?.incidentDetails?.dateOfIncident) {
      if (values?.incidentDetails?.time) {
        values.incidentDetails.dateOfIncident = moment(
          `${moment(values.incidentDetails.dateOfIncident).format(
            'YYYY-MM-DD',
          )} ${values.incidentDetails.time} `,
          'YYYY-MM-DD HH:mm:ss A',
        ).toDate()
      } else {
        values.incidentDetails.dateOfIncident = moment(
          `${moment(values.incidentDetails.dateOfIncident).format(
            'YYYY-MM-DD',
          )}`,
          'YYYY-MM-DD',
        ).toDate()
      }
    }

    setValues(values)
    if (values.partiesInvolved.length === 0) {
      setPartiesInvolvedValidation(true)
    } else {
      if (context === 'claim') {
        dispatch(saveIncidentFormData(values))
        setActiveStep(activeStep + 1)
      } else {
        setOpenModal(true)
      }
    }
  }

  const handleSubmitModal = () => {
    setOpenModal(false)
    dispatch(saveIncidentFormData(null))
    handleIncidentSave(
      isVehicular,
      { ...values, publicIncidentToIncident: true },
      isNew,
      onClose,
      'publicIncidentToIncident',
    )
    ref?.current?.resetForm()
  }

  const handleNarrativeOpen = (values) => {
    setActiveStep && setActiveStep(activeStep + 1)
    setClaimData && setClaimData({ values: values, isVehicular: isVehicular })
    setValues(values)
    setOpenNarrative(true)
  }

  const handleBackButtonClicked = () => {
    setActiveStep && setActiveStep(activeStep - 1)
    setOpenNarrative(false)
  }

  const vehicularAccident = isVehicular

  return (
    <>
      <Formik
        innerRef={ref}
        enableReinitialize
        initialValues={{
          ...newIncident,
          ...values,
          // timezone: '' // Reset timezone to '' so anything other than acceptable timezone strip out during conversion.
        }}
        validationSchema={INCIDENT_VALIDATION_SCHEMA}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => (
          <div className="row m-0">
            <IncidentDetailsTab
              isNew={true}
              title={title}
              values={values}
              context={context}
              activeStep={activeStep}
              handleSubmit={handleSubmit}
              narrative={openNarrative}
              setFieldValue={setFieldValue}
              nextTab={handleNarrativeOpen}
              setNarrative={handleBackButtonClicked}
              setIsVehicular={setIsVehicular}
            />
            {values && (
              <div className="col-md-5 pt-3 modal-right-scroll">
                <div className="col-12 p-0">
                  {context !== 'claim' && (
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Incident ID
                          <span className="text-primery">
                            {values ? values.incidentId : '-'}
                            <b className="text-dark font-weight-normal ml-2">
                              (System Generated)
                            </b>
                          </span>
                        </label>
                      </div>
                    </div>
                  )}
                  {context !== 'claim' && (
                    <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <DatePickerField name="startDate" label="Start Date" />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField name="endDate" label="Due Date" />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField
                        name="closeDate"
                        label="Incident Close Date"
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className={'row'}>
                    <div className="col-md-6">
                      <Select
                        label="Priority"
                        name="priority"
                        defaultLabel="Select Status"
                        options={allPriorityStatusTypes}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      {/* <Select
                        label="Reported Via"
                        name="reportedVia"
                        defaultLabel="Select"
                        disabled
                        options={ReportedViaTypes}
                      /> */}
                      <label>Reported Via</label>
                      <p className="text-primary fw-bold">
                        {values.reportedVia}
                      </p>
                    </div>
                  </div>
                  <TextArea
                    // cols={30}
                    // rows={4}
                    withAsterisk
                    placeholder="Next Action"
                    label="Next Action"
                    name="nextAction"
                  />

                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text">
                      Parties {vehicularAccident && '& Vehicles'} Involved
                    </label>
                    {values?.partiesInvolved?.map((party, index) => (
                      <PartiesInvolvedCard
                        key={index}
                        info={party}
                        uid={index}
                        onEdit={(uid) => {
                          const a = values?.partiesInvolved[uid]
                          setShowAddPartiesInvolvedModal({
                            isOpen: true,
                            data: a,
                            editId: uid,
                          })
                        }}
                        onDelete={(uid) => {
                          const a = values?.partiesInvolved.filter(
                            (party, i) => i != uid,
                          )
                          setFieldValue('partiesInvolved', a)
                        }}
                      />
                    ))}
                    <button
                      onClick={() =>
                        setShowAddPartiesInvolvedModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  {partiesInvolvedValidation && (
                    <span className="text-danger">
                      Party involved is required.
                    </span>
                  )}

                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  {/* {vehicularAccident && (
                    <>
                      <div className="form-group col-md-12">
                        <label className="d-block incident-text">
                          Vehicles Involved
                        </label>
                        {values?.vehiclesInvolved?.map((vehicle, index) => {
                          return (
                            <VehicleInvolvedCard
                              vehicle={vehicle}
                              key={index}
                              uid={index}
                              onEdit={(uid) => {
                                const a = values?.vehiclesInvolved[uid]

                                setShowAddVehicleInvolvedModal({
                                  isOpen: true,
                                  data: a,
                                  editId: uid,
                                })
                              }}
                              onDelete={(uid) => {
                                const a = values?.vehiclesInvolved.filter(
                                  (party, i) => i != uid,
                                )
                                setFieldValue('vehiclesInvolved', a)
                              }}
                            />
                          )
                        })}
                        <button
                          onClick={() =>
                            setShowAddVehicleInvolvedModal({
                              isOpen: true,
                              data: null,
                              editId: null,
                            })
                          }
                          type="button"
                          className="btn btn-outline-secondary side-btn mr-3"
                        >
                          Add..
                        </button>
                      </div>
                      <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                    </>
                  )} */}
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text">
                      Properties Involved
                    </label>
                    {values?.propertiesInvolved.map((property, index) => {
                      return (
                        <PropertiesInvolvedCard
                          property={property}
                          key={index}
                          uid={index}
                          onEdit={(uid) => {
                            const a = values?.propertiesInvolved[uid]

                            setShowPropertyInvolvedModal({
                              isOpen: true,
                              data: a,
                              editId: uid,
                            })
                          }}
                          onDelete={(uid) => {
                            const a = values?.propertiesInvolved.filter(
                              (party, i) => i != uid,
                            )
                            setFieldValue('propertiesInvolved', a)
                          }}
                        />
                      )
                    })}
                    <button
                      onClick={() =>
                        setShowPropertyInvolvedModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Witnesses
                    </label>
                    {values?.witnesses.map((witness, index) => {
                      return (
                        <WitnessCard
                          witness={witness}
                          key={index}
                          uid={index}
                          onEdit={(uid) => {
                            const a = values?.witnesses[uid]

                            setShowWitnessModal({
                              isOpen: true,
                              data: a,
                              editId: uid,
                            })
                          }}
                          onDelete={(uid) => {
                            const a = values?.witnesses.filter(
                              (party, i) => i != uid,
                            )
                            setFieldValue('witnesses', a)
                          }}
                        />
                      )
                    })}

                    <button
                      onClick={() =>
                        setShowWitnessModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    {/* <label className="d-block incident-text">Attachments</label>
                    {values?.attachments.length !== 0 && getAttachmentViews()}
                    {openAttachmentModal && (
                      <AddAttachment
                        module="incident"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )}
                    <button
                      onClick={handleAddAttachmentToggle}
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button> */}
                    {
                      <AttachmentAddView
                        formik={{
                          setFieldValue: setFieldValue,
                          values: values,
                        }}
                        module={'incident'}
                        uid={values.incidentId}
                      />
                    }
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Gensuite Check List
                    </label>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check4"
                      >
                        Filed on Gensuite
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.filedOnGensuite',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.filedOnGensuite'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check4"
                        checked={values.gensuiteChecklist?.filedOnGensuite}
                      />
                    </div>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check5"
                      >
                        Quality Check Needed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.qualityCheckNeeded',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.qualityCheckNeeded'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check5"
                        checked={values.gensuiteChecklist?.qualityCheckNeeded}
                      />
                    </div>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check6"
                      >
                        Quality Check completed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.qualityCheckCompleted',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.qualityCheckCompleted'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check6"
                        checked={
                          values.gensuiteChecklist?.qualityCheckCompleted
                        }
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check6"
                      >
                        Gensuite Closed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.gensuiteClosed',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.gensuiteClosed'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check6"
                        checked={values.gensuiteChecklist?.gensuiteClosed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PartiesInvolvedModal
              isOpen={showAddPartiesInvolvedModal.isOpen}
              onClose={() =>
                setShowAddPartiesInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              isVehicularIncident={vehicularAccident}
              values={values}
              setFieldValue={setFieldValue}
              editId={showAddPartiesInvolvedModal.editId}
              editData={showAddPartiesInvolvedModal.data}
              setPartiesInvolvedValidation={setPartiesInvolvedValidation}
              clientId={
                selectedClientId &&
                selectedClientId.length &&
                selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />

            <VehicleInvolvedModal
              isOpen={showAddVehicleInvolvedModal.isOpen}
              onClose={() =>
                setShowAddVehicleInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              values={values}
              editId={showAddVehicleInvolvedModal.editId}
              editData={showAddVehicleInvolvedModal.data}
              setFieldValue={setFieldValue}
              clientId={
                selectedClientId &&
                selectedClientId.length &&
                selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />
            <PropertyInvolvedModal
              isOpen={showPropertyInvolvedModal.isOpen}
              onClose={() =>
                setShowPropertyInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              values={values}
              editId={showPropertyInvolvedModal.editId}
              editData={showPropertyInvolvedModal.data}
              setFieldValue={setFieldValue}
              clientId={
                selectedClientId &&
                selectedClientId.length &&
                selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />
            <WitnessModal
              isOpen={showWitnessModal.isOpen}
              onClose={() =>
                setShowWitnessModal({ isOpen: false, data: null, editId: null })
              }
              values={values}
              editId={showWitnessModal.editId}
              editData={showWitnessModal.data}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
      </Formik>
      {context !== 'claim' && (
        <AreYouSureModal
          openModal={openModal}
          closeModal={closeModal}
          handleSubmit={handleSubmitModal}
        />
      )}
    </>
  )
}
