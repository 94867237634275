import React, { useState, useEffect, useRef } from 'react'
import ReactECharts from 'echarts-for-react'
import { getClaimsByTime } from '../../../../services/claimService'

function IncurredClaim(props) {
  const [options, setOptions] = useState(null)
  const [duration, setDuration] = useState('year')
  const mountedRef = useRef(true)

  const getIncurredClaims = async (time = duration) => {
    try {
      const { data } = await getClaimsByTime(time)
      const abc = createOptions(data.data, time)
      if (!mountedRef.current) return null
      setOptions(abc)
    } catch (error) {}
  }

  const handleDurationChange = (event) => {
    setDuration(event.target.name)
    getIncurredClaims(event.target.name)
  }

  const createOptions = (data, duration) => {
    return {
      grid: { top: 8, right: 8, bottom: 20, left: 60 },
      xAxis: {
        type: 'category',
        data: data.map((p) =>
          duration === 'month' ? p._id.month : p._id.year,
        ),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: data.map((d) => getTotalSum(d)),
          type: 'bar',
          smooth: true,
        },
      ],
      tooltip: {
        trigger: 'axis',
      },
    }
  }

  const getTotalSum = (item) => {
    let sum = 0
    item.claims.forEach((claim) =>
      claim.incidents.forEach((incident) =>
        incident.claimants.forEach((cl) =>
          cl.financials.forEach((finance) => (sum += finance.amount)),
        ),
      ),
    )
    return sum
  }

  useEffect(() => {
    mountedRef.current = true
    getIncurredClaims()
    return () => { 
      mountedRef.current = false
    }
  }, [])

  return (
    options && (
      <div className="row mb-4 here">
        <div className="col-md-12">
          <div className="card card-custom bg-gray-100 card-stretch gutter-b">
            <div className="card-header align-items-center border-0 mt-2">
              <h6 className="card-title align-items-start flex-column">
                <span className="font-weight-bolder text-dark cus-header">
                  Claims Incurred
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  <div className="btn-group pull-right">
                    <button
                      type="button"
                      name="month"
                      className={`btn btn-primary-outline btn-sm ${
                        duration === 'month' ? 'text-primary' : ''
                      }`}
                      onClick={handleDurationChange}
                    >
                      By Month
                    </button>
                    <button
                      type="button"
                      className={`btn btn-primary-outline btn-sm ${
                        duration === 'year' ? 'text-primary' : ''
                      }`}
                      name="year"
                      onClick={handleDurationChange}
                    >
                      By Year
                    </button>
                  </div>
                </span>
              </h6>
            </div>

            <div className="card-body" style={{width: "430px"}}>
              <div className="card-rounded-bottom">
                <div className="text-center mt-3">
                  {options ? (
                    <ReactECharts
                      option={options}
                      style={{
                        height: '150px',
                        width: '100%',
                        paddingTop: '3px',
                      }}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default IncurredClaim
