import React from 'react'

const Radio = ({ name, value, onChange, label, checked, id }) => (
  <div className=" d-flex align-items-center">
    <input
      name={name}
      type="radio"
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id} className="m-0 ml-3">
      {' '}
      {label}
    </label>
  </div>
)

const MVR = ({
  show,
  handleDropDownOpenCloseClicked,
  extras,
  onCheckedChange,
}) => {
  const options = [
    {
      value: 'ANALYZE',
      label: 'Recommend',
      checked: extras.includes('ANALYZE'),
      onChange: function (e) {
        onCheckedChange(e.target.value, 'extras')
      },
    },
    {
      value: 'APPROVE',
      label: 'Approve',
      checked: extras.includes('APPROVE'),
      onChange: function (e) {
        onCheckedChange(e.target.value, 'extras')
      },
    },
  ]
  return (
    <div className="form-group ">
      <label htmlFor="" className="col-form-label">
        MVR
      </label>
      <div>
        <div
          className={
            'selectBox form-control form-select pb-5 mb-4 ' +
            (show ? 'show' : '')
          }
        >
          <div
            className="selectBox__value"
            onClick={handleDropDownOpenCloseClicked}
          >
            Select Permission
          </div>
          <div className="dropdown-menu pb-5 m-0 setdrop">
            {/* <a
              href="#"
              className="dropdown-item d-flex justify-content-space-between"
              id="selectboxdrop"
            >
              <div></div>
            </a> */}
            <a href="#" className="dropdown-item " id="selectboxdrop">
              <div className="d-flex justify-content-between px-4 py-3">
                {options.map((option) => (
                  <Radio
                    id={'mvr' + option.value}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={option.checked}
                    onChange={option.onChange}
                  />
                ))}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MVR
