/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'

import { getToken } from 'firebase/messaging'
import { map } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { handleFetchClients, saveIP } from '../actions'
import ActionTypes from '../actionTypes'
import { messaging } from '../App'
import AssetManagement from '../pages/assetManagement/AssetManagement'
import ForgotPassword from '../pages/auth/ForgotPassword'
import Login from '../pages/auth/Login'
import ResetPassword from '../pages/auth/ResetPassword'
import ClaimAndIncidents from '../pages/ClaimsAndIncidents/ClaimAndIncidents'
import Dashboard from '../pages/Dashboard/Dashboard'
import PublicIncidentReport from '../pages/publicIncident/PublicIncidentReport'
import MvrAdd from '../pages/mvr/MvrAdd'
import MvrList from '../pages/mvr/MvrList'
import Policy from '../pages/policy/Policy'
import PublicIncidentLogin from '../pages/publicIncident/PublicIncidentLogin'
import Settings from '../pages/settings/UserSettings'
import ThirdParty from '../pages/thirdParty/ThirdParty'
import Trash from '../pages/trash/Trash'
import UserManagement from '../pages/userManagement/UserManagement'
import { getUserClients } from '../services/clientService'
import { saveUserFirebaseToken } from '../services/notificationService'
import ProtectedRoute from './ProtectedRoutes'
import AddNewIncident from '../pages/publicIncident/AddNewIncident'
import UploadAttachment from '../pages/publicIncident/uploadAttchment'

const ROUTES = [
  {
    path: '/login',
    Component: Login,
    layout: false,
    protect: false,
  },
  {
    path: '/forgot-password',
    Component: ForgotPassword,
    layout: false,
    protect: false,
  },
  {
    path: '/auth/reset-password/:id',
    Component: ResetPassword,
    layout: false,
    protect: false,
  },
  {
    path: '/add-mvr/:orgName?',
    Component: MvrAdd,
    layout: false,
    protect: false,
  },
  {
    path: '/add-new-incident',
    Component: AddNewIncident,
    layout: false,
    protect: false,
  },
  {
    path: '/incident-attachments/:token',
    Component: UploadAttachment,
    layout: false,
    protect: false,
  },
  {
    path: '/login-public-incident',
    Component: PublicIncidentLogin,
    layout: false,
    protect: false,
  },
  {
    path: '/public-incident-report/:id',
    Component: PublicIncidentReport,
    layout: false,
    protect: false,
  },
  {
    path: '/dashboard',
    access: [],
    Component: Dashboard,
  },
  {
    path: '/userManagement',
    access: ['USER', 'CLIENT'],
    Component: UserManagement,
  },
  {
    path: '/mvr',
    access: ['MVR'],
    Component: MvrList,
  },
  {
    path: '/thirdParty',
    access: ['THIRD-PARTY'],
    Component: ThirdParty,
  },
  {
    path: '/incidents',
    access: ['CLAIM', 'INCIDENT'],
    Component: ClaimAndIncidents,
  },
  {
    path: '/assetsManagement',
    access: ['VEHICLE', 'PROPERTY', 'EMPLOYEE'],
    Component: AssetManagement,
  },
  {
    path: '/policies',
    access: ['POLICY'],
    Component: Policy,
  },
  {
    path: '/settings',
    access: [],
    Component: Settings,
  },

  {
    path: '/trash',
    access: ['TRASH'],
    Component: Trash,
  },
]

const Routes = () => {
  const { token, permissions } = useSelector((state) => state)
  const dispatch = useDispatch()

  const checkAccess = (modules = []) => {
    if (modules.length == 0) return true

    const modulesToCheck = permissions.filter((c) =>
      modules.map((z) => z.toLowerCase()).includes(c.module.identifier),
    )
    let access = false
    modulesToCheck.forEach((z) => {
      if (z.accessMode !== 'NO_ACCESS') {
        access = z.operations.includes('READ')
      }
    })

    return access
  }

  useEffect(() => {
    if (token) {
      // httpService.setDefaultHeaders('authorization', token);
      fetchClients()
      getFirebaseToken()
    }
  }, [token])

  useEffect(async () => {
    fetch('https://jsonip.com')
      .then((r) => r.json())
      .then(function (data) {
        dispatch(saveIP(data.ip))
      })
  }, [])
  const fetchClients = async () => {
    const {
      data: { data: clients },
    } = await getUserClients()
    dispatch(handleFetchClients(clients))
  }

  const getFirebaseToken = () => {
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          saveUserFirebaseToken(currentToken)
          sessionStorage.setItem('firebaseToken', currentToken)
          dispatch({
            type: ActionTypes.ENABLE_FIREBASE,
          })
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.',
          )
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        // ...
      })
  }

  const getRoutes = map((props) => {
    let access = checkAccess(props.access)
    if (access) {
      return props.isRedirect ? (
        <Redirect key={props.path} from={props.path} exact to={props.to} />
      ) : (
        <ProtectedRoute exact key={props.path} {...props} />
      )
    } else {
      return (
        <Redirect key={props.path} from={props.path} exact to={'/dashboard'} />
      )
    }
  })
  return (
    <Router>
      <Switch>
        <Redirect from="/" exact to="/dashboard" />
        {getRoutes(ROUTES)}
        <Redirect to="/not-found" />
      </Switch>
    </Router>
  )
}

export default Routes
