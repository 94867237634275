import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { handleSideNavElementClicked } from '../../actions'
import { property } from 'lodash'
import { useCommentAccess, useGetPermission } from '../../hooks/useGetPermission'
import { addCommaTableField } from '../../pages/incident/utils'
import { getClient } from '../../services/clientService'

export default function PropertyRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const deletePermission = useGetPermission('property', 'DELETE')
  const editPermission = useGetPermission('property', 'UPDATE')
  const addCommentPermission = useCommentAccess('property', 'CREATE')

  const [clientInfo, setclientInfo] = useState({});

  useEffect(async() => {
    const { data: client } = await getClient(data.clientId)
    setclientInfo(client.data);
  }, [data])

  const getAddress = (address) => {
    let addressString = ''
    if (address) {
      addressString += address.line1 ? address.line1 + ', ' : ''
      addressString += '\n'
      addressString += address.line2 ? address.line2 + ', ' : ''
      addressString += address.city ? address.city : ''
      addressString += '\n'
      addressString += address.state ? address.state + ', ' : ''
      addressString += address.country ? address.country + '-' : ''
      addressString += address.pinCode ? address.pinCode : ''
    }

    return addressString
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title={moment(data.created).format('ll')}
                data-original-title={moment(data.created).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {moment(data.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {moment(data.created).format('YYYY  ')}
                </div>
              </div>
            </div>
            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.propertyId || 'N/A'}</div>
                {clientInfo.companyName || 'N/A'}
              </span>
              <p className="mt-3"></p>
            </div>
            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.829"
                      height="17.828"
                      viewBox="0 0 17.829 17.828"
                    >
                      <g
                        id="Group_9756"
                        data-name="Group 9756"
                        transform="translate(-4013.659 13.151)"
                      >
                        <path
                          id="path1419"
                          d="M-1479.2-17.087l15-15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                        <path
                          id="path1421"
                          d="M-1479.2-32.087l15,15"
                          transform="translate(5494.273 20.35)"
                          fill="none"
                          stroke="#828282"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          <div className="text-center px-5 pb-2">
          {addCommentPermission && (
            <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={() => dispatch(handleSideNavElementClicked('comment'))}
            >
              Add Comment
            </button>
          )}
          {editPermission && (
            <button
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
              onClick={onEdit}
            >
              <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
          )}
          {deletePermission && (
            <button
              type="button"
              className="btn btn-outline-secondary side-btn"
              onClick={onDelete}
            >
              <i className="far fa-trash-alt" aria-hidden="true"></i>
            </button>
          )}
          </div>
        </div>
        <div className="pr-2 pb-4" style={{ overflow: 'hidden' }}>
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Property Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Property Name</div>
                          <span>{data?.name || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Property Status</div>
                          <span className="text-warning">
                            {!!data?.activeStatus ? 'Active' : 'Inactive'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Property Owned or Leased</div>
                          <span>{_.capitalize(data?.propertyType) || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Date of Purchase/Leased</div>
                          <span>
                            {data?.dateOfPurchaseOrLeased ?
                              moment(data?.dateOfPurchaseOrLeased).format(
                                'MM/DD/YYYY',
                              ) : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Date Sold (if applicable)</div>
                          <span>
                            {data?.soldOn ?
                              moment(data?.soldOn).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          <div className="d-flex"> Address</div>
                          <span>{getAddress(data?.address || '')}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header1" id="faqhead3">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="false"
                      aria-controls="faq3"
                    >
                      COPE + Property Value
                    </a>
                  </div>

                  <div
                    id="faq3"
                    className="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Construction Type</div>
                          <span>{_.capitalize(data?.constructionType) ||'N/A'}</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Construction Year</div>
                          <span>{data?.constructionYear || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Number of Units</div>
                          <span>
                            {data?.propertyMeasurement?.numberOfUnits || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Modernized</div>
                          <span>{data?.modernised ? 'Yes' : 'No'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Disaster Zone</div>
                          <span>{data?.disasterProtectionFrom || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Occupancy Type</div>
                          <span>{_.capitalize(data?.occupancyType) || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Protection</div>
                          <span>
                            {data?.protection &&
                              Array.isArray(data?.protection) &&
                              _.capitalize(data?.protection[0])}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Area</div>
                          <span>{data?.propertyMeasurement?.area || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Height</div>
                          <span>{data?.propertyMeasurement?.height || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Security</div>
                          <span>
                            {data?.security &&
                              Array.isArray(data?.security) &&
                              _.capitalize(data?.security[0])}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header1" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="false"
                      aria-controls="faq2"
                    >
                      Property Valuation
                    </a>
                  </div>

                  <div
                    id="faq2"
                    className="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    {data?.propertyValueDetails?.map(
                      ({ amount, transactionDate }, i) => {
                        return (
                          <div className="parties price bg-white p-3 mb-3 col-md-4 mt-4 claim-taken-view  ml-2" key={i}>
                            <h6 className="text-primery">
                              {transactionDate &&
                                moment(transactionDate).format('MM/DD/YYYY')}
                            </h6>
                            <small className="mb-2">${amount ? addCommaTableField(amount)
                : 'N/A'}</small>
                          </div>
                        )
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
