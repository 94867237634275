import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { DatePickerCustomHeader } from '../../components/form/DatePickerCustomHeader'
import { addComma } from '../incident/utils'

function ClaimFinancialModal({ isOpen, onClose, data, onAdd }) {
  const [financial, setFinancial] = useState({
    amount: '',
    transactionDate: '',
  })

  useEffect(() => {
    setFinancial({ amount: data?.amount || '', transactionDate: data?.transactionDate || '' })    
  }, [data])

  const handleSave = () => {
    onAdd(financial)
    setFinancial({ amount: '', transactionDate: '' })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} backdrop={'static'}>
      <div className="modal-content">
        <div className="modal-header justify-content-center">
          <h5 className="modal-title text-primary " id="">
            Add Transaction
          </h5>
        </div>
        <div className="modal-body p-0" id="smodaltab">
          <ul
            className="nav nav-pills bg-light d-flex justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a className="nav-link active mt-0 font-weight-normal">
                Claim Financials Details
              </a>
            </li>
          </ul>
          <div>
            <div className="px-5 mt-5 pb-4">
              <form action="#">
                <div className="form-group mb-0">
                  <div className="input-group">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group input-dollar">
                          <label>Amount</label>
                          <input
                            data-testid="claim-financial-amount"
                            value={
                              financial?.amount
                                ? addComma(financial?.amount)
                                : ''
                            }
                            onChange={(e) => {
                              const value = e.target.value
                              const onlyNums = value.replaceAll(',', '')
                              if (!isNaN(Number(onlyNums))) {
                                setFinancial((s) => ({
                                  ...s,
                                  amount: onlyNums,
                                }))
                              }
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          className='d-none'
                          htmlFor="claim-financial-date"
                        >
                          Transaction Date
                        </label>
                        <DatePicker
                          id="claim-financial-date"
                          autoComplete="off"
                          placeholder=""
                          className="form-control"
                          dateFormat="MM/dd/yyyy"
                          placeholderText="MM/DD/YYYY"
                          selected={
                            (financial.transactionDate &&
                              new Date(financial.transactionDate)) ||
                            null
                          }
                          renderCustomHeader={DatePickerCustomHeader}
                          onChange={(val) => {
                            setFinancial((s) => ({
                              ...s,
                              transactionDate: val,
                            }))
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault()
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer" style={{ background: '#F4F7FE' }}>
            <button
              data-testid="save-btn"
              type="button"
              className="btn btn-primary add-btn"
              onClick={handleSave}
            >
              Add
            </button>
            <button
              data-testid="close-btn"
              onClick={() => onClose()}
              type="button"
              className="btn btn-secondary bg-white text-dark cls-btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ClaimFinancialModal
