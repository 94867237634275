import React from 'react'
import { DirectClaimProvider } from './DirectClaimContext'
import DirectIncidentForm from './DirectIncidentForm'
import { useDirectClaimForm } from './useDirectClaimForm'

const DirectClaim = ({ data, onClose }) => {
  const { activeStep, setActiveStep, isNew, submitForm, initialValues, validationSchema } = useDirectClaimForm(data, onClose)
  const contextData = {
    activeStep,
    setActiveStep,
    isNew,
    initialValues,
    validationSchema,
    submitForm
  }

  return (
    <DirectClaimProvider value={{ ...contextData }}>
      <DirectIncidentForm />
    </DirectClaimProvider>
  )
}

export default DirectClaim
