import React from 'react'
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../actions';
import { useShowModule } from '../../hooks/useShowModule';

function Sidebar({ items }) {
  const {showModule} = useShowModule();
  const dispatch = useDispatch();

  const getDefaultTabId = (key) => {
    switch (key) {
      case '/userManagement':
        return "user"
      case "/mvr":
        return 'mvr_submission'
      case "/thirdParty":
        return 'thirdParty';
      case '/incidents':
        return "incident";
      case '/assetsManagement':
        return 'employee';
      case '/policies':
        return 'policy';
      case '/trash':
        return 'trash';
      default:
        return "";
    }
  }


  return (
    <aside id="sidebar">
    <div className="container-fluid">
      <div className=' pl-4'>
        <nav className="d-flex custom-slider">
        {items.map(
          (item) =>  showModule(item?.modules) && (
          <NavLink onClick={()=>{
            dispatch(handleTabIdUpdated(getDefaultTabId(item.to)));
            dispatch(
              handleSideNavDataUpdated(
                { data: {}, module: item.to },
              ),
            )
          }} className="nav-link custom-slide  d-flex" key={item.title} to={item.to} >
            <div className="d-flex align-items-center">
              {item?.icon}
              <span className="ml-1 aside-content"> {item.title} </span>
            </div>
          </NavLink>
        ))}
        </nav>
      </div>
    </div>
  </aside>
  )
}

export default Sidebar
