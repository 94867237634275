import { ErrorMessage, Field, useFormikContext } from 'formik'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React from 'react'
import DatePickerField from '../../components/form/DatePickerField'
import TextArea from '../../components/form/TextArea'
import TextInput from '../../components/form/TextInput'
import PublicIncidentWitnessesAdd from '../../components/Incidents/PublicIncidentWitnessesAdd'
import InjuryDetails from './InjuryDetails'
import PhoneInput from 'react-phone-number-input'

function IncidentDetailsForm({ witnesses, setWitnesses }) {
  const { values, setFieldValue } = useFormikContext()

  return (
    <div>     
      <div className="border-bottom h6 py-2 mb-4">
        <span className="text-primery font-weight-bold">Incidents Details</span>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <DatePickerField
            withAsterisk
            maxDate={new Date()}
            name="incidentDetails.dateOfIncident"
            label="Date of Incident"
          />
        </div>
        <div className="form-group col-md-6">
          <div>
            <label>Time of Incident ({moment.tz(moment.tz.guess()).zoneAbbr()})</label>

            <TimePicker
              use12Hours
              className="antDOverride publicincident"
              name="incidentDetails.time"
              onChange={(value) => {
                setFieldValue(
                  'incidentDetails.time',
                  value.format('HH:mm:ss A'),
                )
              }}
              format="hh:mm:ss A"
              value={moment(
                values?.incidentDetails?.time || '00:00:00',
                'HH:mm:ss A',
              )}
              defaultValue={moment('00:00:00', 'HH:mm:ss A')}
            />
          </div>
        </div>
        <div className="form-group mt-3 col-md-12">
          <TextArea
            withAsterisk
            cols={30}
            rows={5}
            label="Location where the incident happened"
            name="incidentDetails.location"
            placeholder="i.e.,inside hub, vehicle, hub parking, customer’s specific address, etc."
          />
        </div>
        <div className="form-group mt-3 col-md-12">
          <TextArea
            withAsterisk
            cols={30}
            rows={5}
            name="incidentDetails.employeeActivity"
            label="What was employee doing when the incident happened"
            placeholder="i.e.,lifting, driving, pushing, delivering, etc."
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label> Is Third Party present? <span className='text-danger'>*</span></label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                data-testid="isThirdPartyPresentYes"
                className="form-check-input"
                type="radio"
                name={`incidentDetails.isThirdPartyPresent`}
                value={'true'}
                id="isThirdPartyPresentYes"
              />
              <label className="mt-2" htmlFor="isThirdPartyPresentYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                data-testid="isThirdPartyPresentNo"
                className="form-check-input"
                type="radio"
                name={`incidentDetails.isThirdPartyPresent`}
                value={'false'}
                id="isThirdPartyPresentNo"
              />
              <label className="mt-2" htmlFor="isThirdPartyPresentNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incidentDetails.isThirdPartyPresent`}
            component="div"
          />
        </div>
        {values?.incidentDetails?.isThirdPartyPresent === 'true' && (
          <>
            <div className="form-group col-md-6 mb-0">
              <TextInput
                withAsterisk
                name={`incidentDetails.thirdPartyDetails.thirdPartyName`}
                label="Third Party Name"
              />
            </div>
            <div className="form-group col-md-6 mb-0">
              {/* <TextInput
                isPhone
                changeOnUndefined={true}
                label="Third Party Contact Number"
                name="incidentDetails.thirdPartyDetails.thirdPartyContactNumber"
                onPhoneChange={(phone) => {
                  setFieldValue(
                    'incidentDetails.thirdPartyDetails.thirdPartyContactNumber',
                    phone || '',
                  )
                }}
                value={
                  values?.incidentDetails?.thirdPartyDetails
                    ?.thirdPartyContactNumber || ''
                }
              /> */}
              <label>Third Party Contact Number</label>
              <PhoneInput
                // displayInitialValueAsLocalNumber={true}
                limitMaxLength={true}
                value={values?.incidentDetails?.thirdPartyDetails?.thirdPartyContactNumber || ''}
                name="incidentDetails.thirdPartyDetails.thirdPartyContactNumber"
                defaultCountry="US"
                international={false}
                className={'form-control'}
                placeholder="Contact Number"
                onChange={(phone) => {
                  if (phone) {
                    setFieldValue('incidentDetails.thirdPartyDetails.thirdPartyContactNumber', phone || '',)
                  } else {
                    setFieldValue(`incidentDetails.thirdPartyDetails.thirdPartyContactNumber`, '')
                  }
                }}
              />
              <ErrorMessage
                className="text-danger"
                name="incidentDetails.thirdPartyDetails.thirdPartyContactNumber"
                component="div"
              />
            </div>
            <div className="form-group col-md-12">
              <label>
                Has Third Party left or refused to give info ? (If yes, make a
                police report) <span className='text-danger'>*</span>
              </label>
              <div className=" d-block">
                <div className="form-check form-check-inline">
                  <Field
                    className="form-check-input"
                    type="radio"
                    name={`incidentDetails.thirdPartyLeaves`}
                    value={'true'}
                    id="thirdPartyLeavesYes"
                  />
                  <label className="mt-2" htmlFor="thirdPartyLeavesYes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline mr-5">
                  <Field
                    className="form-check-input"
                    type="radio"
                    name={`incidentDetails.thirdPartyLeaves`}
                    value={'false'}
                    id="thirdPartyLeavesNo"
                  />
                  <label className="mt-2" htmlFor="thirdPartyLeavesNo">
                    No
                  </label>
                </div>
              </div>
              <ErrorMessage
                className="text-danger"
                name={`incidentDetails.thirdPartyLeaves`}
                component="div"
              />
            </div>
          </>
        )}
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>Was there a Police Report taken for this incident? <span className='text-danger'>*</span></label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.policeCalled`}
                value={'true'}
                id="policeReportYes"
              />
              <label className="mt-2" htmlFor="policeReportYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.policeCalled`}
                value={'false'}
                id="policeReportNo"
              />
              <label className="mt-2" htmlFor="policeReportNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incidentDetails.policeCalled`}
            component="div"
          />
        </div>
        {values?.incidentDetails?.policeCalled === 'true' && (
          <>
            <div className="form-group col-md-6">
              <TextInput
                withAsterisk
                name={`incidentDetails.policeReportDetails.policeReportNumber`}
                label="Report Number"
              />
            </div>
            <div className="form-group col-md-6">
              <TextInput
                label="Jurisdiction (County)"
                name="incidentDetails.policeReportDetails.policeReportcountry"
              />
            </div>
          </>
        )}
      </div>
      <div className="form-group">
        <TextArea
          withAsterisk
          cols={30}
          rows={5}
          name="incidentDetails.driverStatement"
          label="Driver statement"
          placeholder="Driver statement"
        />
      </div>
      <div className="form-group ">
        <TextArea
          cols={30}
          rows={5}
          label="Unsafe conditions"
          name="incidentDetails.unsafeCondition"
          placeholder="i.e.,Icy, wet, snow roads, uneven pavement, etc."
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>Witnesses? <span className='text-danger'>*</span></label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.hasWitnesses`}
                value={'true'}
                id="hasWitnessesYes"
              />
              <label className="mt-2" htmlFor="hasWitnessesYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.hasWitnesses`}
                value={'false'}
                id="hasWitnessesNo"
              />
              <label className="mt-2" htmlFor="hasWitnessesNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incidentDetails.hasWitnesses`}
            component="div"
          />
        </div>
        {values?.incidentDetails?.hasWitnesses === 'true' && (
          <PublicIncidentWitnessesAdd
            witnesses={witnesses}
            setWitnesses={setWitnesses}
          />
        )}
      </div>
      <div>
        <InjuryDetails />
      </div>
    </div>
  )
}

export default IncidentDetailsForm
