import { Modal } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import _ from 'lodash'
import Select1 from 'react-select'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

import { getClaim, updateClaim } from '../../services/claimService'
import TransactionTable from '../../components/TransactionTable'
import { getPolicies } from '../../services/policyService'
import AddTransactionModalBtn from '../../components/AddTransactionModalBtn'
import AttachmentUpload from '../../components/form/AttachmentUpload'
import TextInput from '../../components/form/TextInput'
import { savePolicyInfo } from '../../actions'
import { allClaimStatusTypes, allPolicyTypes } from '../incident/utils'

function EditClaim({ id, onClose }) {
  const dispatch = useDispatch()
  const [claim, setClaim] = useState(null)
  const [policies, setPolicies] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [activeIncidentTab, setActiveIncidentTab] = useState(0)
  useEffect(() => {
    fetchClaim()
    fetchPolicies()
  }, [])

  const fetchClaim = async () => {
    const { data: claim } = await getClaim(id)
    setClaim(claim.data)
  }

  const onModalClose = (val, path) => {
    const value = _.get(claim, path)
    const i = value.length || 0
    debouncedChange(path, [
      ...value,
      { ...val, transactionId: `#${String(i + 1).padStart(5, '0')}` },
    ])
  }

  const onAfterUpload = (path, urls) => {
    const value = _.get(claim, path)
    debouncedChange(path, [...value, ...urls])
  }

  const handleChange = _.debounce((path, val) => {
    debouncedChange(path, val)
  }, 200)

  const debouncedChange = (path, val) => {
    const claimData = { ...claim }
    const obj = _.set(claimData, path, val)
    setClaim(obj)
  }

  const fetchPolicies = async () => {
    const { data: policies } = await getPolicies()
    if (policies.data && Array.isArray(policies.data)) {
      dispatch(savePolicyInfo(policies.data))
      setPolicies(
        policies.data.map((p) => ({
          policyId: p._id,
          policyName: p.policyId,
          policyType: p.policyType,
        })),
      )
    }
  }
  const history = useHistory()

  const handleSave = async () => {
    try {
      await updateClaim(claim._id, claim)
      toast.success('Claim Updated!')
      onClose()
    } catch (err) {
      toast.error(err)
    }
  }

  return (
    claim && (
      <div className="px-4">
        <div className="no-gutters">
          <div className="col-md-6">
            <h1 className="mt-3">
              Edit claim
              {claim.claimId}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/claims"> Claims Hub </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Claim
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4">
            <span className="pull-right" />
          </div>
          <div className="col-12 col-md-6">
            <ul className="nav nav-tabs border-0 mb-3" role="tablist">
              {claim.incidents.map((w, i) => (
                <li
                  key={i}
                  onClick={() => setActiveIncidentTab(i)}
                  className="nav-item_add mb-4 m-text fw-6 active"
                >
                  <a
                    className={classNames('tabadd pl-4', {
                      active: activeIncidentTab === i,
                    })}
                    data-toggle="tab"
                  >
                    Incident {i + 1}
                  </a>
                </li>
              ))}
            </ul>
            <ul className="nav nav-tabs border-0 mb-3" role="tablist">
              {claim.incidents.map((inc, i) =>
                inc.claimants.map(
                  (cl, j) =>
                    activeIncidentTab === i && (
                      <li
                        key={j}
                        onClick={() => setActiveTab(j)}
                        className="nav-item_add mb-4 m-text fw-6 active"
                      >
                        <a
                          className={classNames('tabadd', {
                            active: activeTab === j,
                            'pl-4': true,
                          })}
                          data-toggle="tab"
                        >
                          Claimant {j + 1}
                        </a>
                      </li>
                    ),
                ),
              )}
            </ul>
            <div className="tab-content" style={{ width: '500px' }}>
              {claim.incidents.map((inc, i) =>
                inc.claimants.map(
                  (claimant, index) =>
                    activeIncidentTab === i && (
                      <div
                        key={index}
                        className={classNames('tab-pane', {
                          active: activeTab === index,
                        })}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Coverage Type</label>
                                  <select
                                    onChange={({ currentTarget }) =>
                                      handleChange(
                                        `incidents.${i}.claimants.${index}.coverageType`,
                                        currentTarget.value,
                                      )
                                    }
                                    value={_.get(
                                      claim,
                                      `incidents.${i}.claimants.${index}.coverageType`,
                                    )}
                                    className="form-control"
                                  >
                                    {allPolicyTypes.map((p) => (
                                      <option key={p.value} value={p.value}>
                                        {p.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Claim Status</label>
                                  <select
                                    className="form-control"
                                    onChange={({ currentTarget }) =>
                                      handleChange(
                                        `incidents.${i}.claimants.${index}.status`,
                                        currentTarget.value,
                                      )
                                    }
                                    value={_.get(
                                      claim,
                                      `incidents.${i}.claimants.${index}.status`,
                                    )}
                                  >
                                    {allClaimStatusTypes.map((p) => (
                                      <option key={p.value} value={p.value}>
                                        {p.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Policy Details</label>
                                  <Select1
                                    placeholder="Select Policy"
                                    getOptionLabel={(option) =>
                                      option.policyName
                                    }
                                    getOptionValue={(option) => option}
                                    name={`incidents.${i}.claimants.${index}.policyDetails`}
                                    value={_.get(
                                      claim,
                                      `incidents.${i}.claimants.${index}.policyDetails`,
                                    )}
                                    isSearchable="true"
                                    onChange={(val) =>
                                      handleChange(
                                        `incidents.${i}.claimants.${index}.policyDetails`,
                                        val,
                                      )
                                    }
                                    options={policies.filter(
                                      (p) =>
                                        p.policyType ===
                                        _.get(
                                          claim,
                                          `incidents.${i}.claimants.${index}.coverageType`,
                                        ),
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Description of Claim
                                </legend>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Type of Claim</label>
                                        <input
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.coverageType`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.description.coverageType`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Allegation</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.allegation`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.description.allegation`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Severity</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`incidents.${i}.claimants.${index}.description.severity`}
                                          value="High"
                                          onChange={() =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.severity`,
                                              'High',
                                            )
                                          }
                                        />
                                        <label className="form-check-label">
                                          High
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`incidents.${i}.claimants.${index}.description.severity`}
                                          value="Medium"
                                          onChange={() =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.severity`,
                                              'Medium',
                                            )
                                          }
                                        />
                                        <label className="form-check-label">
                                          Medium
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline form-group">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={`incidents.${i}.claimants.${index}.description.severity`}
                                          value="Low"
                                          onChange={() =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.severity`,
                                              'Low',
                                            )
                                          }
                                        />
                                        <label className="form-check-label">
                                          Low
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Outcome</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.description.outcome`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.description.outcome`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Claim KPI
                                </legend>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Deductible</label>
                                      <input
                                        onChange={({ currentTarget }) =>
                                          handleChange(
                                            `incidents.${i}.claimants.${index}.deductible`,
                                            currentTarget.value,
                                          )
                                        }
                                        value={_.get(
                                          claim,
                                          `incidents.${i}.claimants.${index}.deductible`,
                                        )}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Open Since</label>
                                      <input
                                        onChange={({ currentTarget }) =>
                                          handleChange(
                                            `incidents.${i}.claimants.${index}.openSince`,
                                            currentTarget.value,
                                          )
                                        }
                                        value={_.get(
                                          claim,
                                          `incidents.${i}.claimants.${index}.openSince`,
                                        )}
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <div className="form-group">
                                <label>Claim Checklist</label>
                                <div className="checkbox-inline">
                                  <label className="checkbox">
                                    <input
                                      checked={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.claimantContacted`,
                                      )}
                                      onChange={({ currentTarget }) =>
                                        handleChange(
                                          `incidents.${i}.claimants.${index}.checkList.claimantContacted`,
                                          currentTarget.checked,
                                        )
                                      }
                                      value={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.claimantContacted`,
                                      )}
                                      type="checkbox"
                                      name="Checkboxes1"
                                    />
                                    <span /> Claimant contacted
                                  </label>
                                  <label className="checkbox">
                                    <input
                                      checked={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.policyAssigned`,
                                      )}
                                      onChange={({ currentTarget }) =>
                                        handleChange(
                                          `incidents.${i}.claimants.${index}.checkList.policyAssigned`,
                                          currentTarget.checked,
                                        )
                                      }
                                      value={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.policyAssigned`,
                                      )}
                                      type="checkbox"
                                      name="Checkboxes2"
                                    />
                                    <span /> Policy assigned
                                  </label>
                                  <label className="checkbox">
                                    <input
                                      checked={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.claimClosed`,
                                      )}
                                      onChange={({ currentTarget }) =>
                                        handleChange(
                                          `incidents.${i}.claimants.${index}.checkList.claimClosed`,
                                          currentTarget.checked,
                                        )
                                      }
                                      value={_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.checkList.claimClosed`,
                                      )}
                                      type="checkbox"
                                      name="Checkboxes3"
                                    />
                                    <span /> Claim Closed
                                  </label>
                                </div>
                              </div>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Claim Financials
                                </legend>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      <AddTransactionModalBtn
                                        modalClose={(val) => {
                                          onModalClose(
                                            val,
                                            `incidents.${i}.claimants.${index}.financials`,
                                          )
                                        }}
                                        heading={'Add Claim Financial'}
                                        buttonLabel="Add Transaction"
                                        btnClasses="btn btn-outline-light text-dark cus-btn"
                                      />
                                      {!!_.get(
                                        claim,
                                        `incidents.${i}.claimants.${index}.financials`,
                                      ).length && (
                                        <TransactionTable
                                          data={
                                            _.get(
                                              claim,
                                              `incidents.${i}.claimants.${index}.financials`,
                                            ) || []
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Claim Notes
                                </legend>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <TextInput
                                        isPhone
                                        changeOnUndefined={true}
                                        name=""
                                        label="Claimant Contact Number"
                                        maxLength={14}
                                        onPhoneChange={(phone) => {
                                          if (phone.length > 2) {
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.claimantContactNumber`,
                                              phone,
                                            )
                                          }
                                        }}
                                        value={_.get(
                                          claim,
                                          `incidents.${i}.claimants.${index}.notes.claimantContactNumber`,
                                        )}
                                      />
                                      {/* <div className="form-group">
                                        <label>Claimant Contact Number</label>
                                        <input
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.claimantContactNumber`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.notes.claimantContactNumber`,
                                          )}
                                          className="form-control"
                                        />
                                      </div> */}
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Claim Decline Reason</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.claimDeclineReason`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.notes.claimDeclineReason`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Litigation Details</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.litigationDetails`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.notes.litigationDetails`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Settlement Offered</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.settlementOffered`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.notes.settlementOffered`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>Claim Adjuster's Remarks</label>
                                        <textarea
                                          onChange={({ currentTarget }) =>
                                            handleChange(
                                              `incidents.${i}.claimants.${index}.notes.claimAdjustorRemarks`,
                                              currentTarget.value,
                                            )
                                          }
                                          value={_.get(
                                            claim,
                                            `incidents.${i}.claimants.${index}.notes.claimAdjustorRemarks`,
                                          )}
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div />
                              </fieldset>
                              <div className="row">
                                <div className="col-md-12">
                                  <AttachmentUpload
                                    type="claim"
                                    afterUpload={(urls) => {
                                      onAfterUpload(
                                        `incidents.${i}.claimants.${index}.attachments`,
                                        urls,
                                      )
                                    }}
                                    label="Add Claim Communication"
                                    name={`claimants.${index}.attachments`}
                                  />
                                </div>
                                {claim.incidents[i].claimants[
                                  index
                                ].attachments.map((el, i) => (
                                  <p key={i}>
                                    {el.split('/').pop().split('%').join('')}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <button
                              type="submit"
                              onClick={handleSave}
                              className="btn btn-primary"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    ),
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default EditClaim
