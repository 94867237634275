import axios from 'axios'
import { toast } from 'react-toastify'
import store from '../store'
axios.interceptors.request.use(
  (request) => {
    const isAwsURL = request.url.includes("amazonaws.com")
    const token = sessionStorage.getItem('token')
    let clientId = store.getState()?.selectedClientId || 'allClients'
    if (clientId === 'allClients') {
      clientId = request?.data?.clientId || 'allClients'
    }

    if (token && !isAwsURL) {
      request['headers']['authorization'] = sessionStorage
        .getItem('token')
        .toString()
    }

    if (!request?.params?.['clientId'] && !isAwsURL) {
      request.params = { ...request.params, clientId }
    }
    if (
      request?.method !== 'get' &&
      request?.params?.['clientId'] === 'allClients'
    ) {
      delete request?.params?.['clientId']
    }

    if(isAwsURL && request.headers.authorization === null) {
      delete request.headers.authorization
    }

    return request
  },
  (error) => Promise.reject(error),
)
axios.interceptors.response.use(null, (error) => {
  // const expectedError =
  //   error.response &&
  //   error.response.status >= 400 &&
  //   error.response.status < 500

  // if (!expectedError) {
  //   if (
  //     error &&
  //     error.response &&
  //     error.response.data &&
  //     error.response.data.msg
  //   ) {
  //     toast.error(error.response.data.msg)
  //   } else {
  //     toast.error('An unexpected error occurred.')
  //   }
  // }

  // console.log("error?.response?.data?.msg===>>", error?.response?.data?.msg);
  if (
    error?.response?.status === 400 &&
    error?.response?.data?.msg?.errors
  ) {
    error?.response?.data?.msg?.errors.forEach(c => {
      toast.error(c.message)
    })
  } else {
    toast.error(error.response.data.msg.err || error?.response?.data?.msg)
  }


  return Promise.reject(error)
})

const setDefaultHeaders = (headers) =>
  (axios.defaults.headers = { ...axios.defaults.headers, ...headers })

export default {
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
  put: axios.put,
  delete: axios.delete,
  setDefaultHeaders,
}
