const ActionTypes = {
  TAB_ID_UPDATED: 'TAB_ID_UPDATED',
  SIDE_NAV_DATA_UPDATED: 'SIDE_NAV_DATA_UPDATED',
  SIDE_NAV_ELEMENT_CLICKED: 'SIDE_NAV_ELEMENT_CLICKED',
  HANDLE_CLIENT_ID_UPDATED: 'HANDLE_CLIENT_ID_UPDATED',
  FETCH_CLIENTS: 'FETCH_CLIENTS',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SAVE_IP: 'SAVE_IP',
  CALENDAR_INFO: 'CALENDAR_INFO',
  VIEW_ACTION: 'VIEW_ACTION',
  SAVE_INCIDENT_FORM_DATA: 'SAVE_INCIDENT_FORM_DATA',
  SAVE_USER_DATA: 'SAVE_USER_DATA',
  SAVE_MODULES: 'SAVE_MODULES',
  ENABLE_FIREBASE: "ENABLE_FIREBASE",
  DISABLE_FIREBASE: "DISABLE_FIREBASE",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  MARK_NOTIFICATION_READ: "MARK_NOTIFICATION_READ",
  MARK_ALL_NOTIFICATION_READ: "MARK_ALL_NOTIFICATION_READ",
  DASHBOARD_DATE_SLOT_CHANGED: "DASHBOARD_DATE_SLOT_CHANGED",
}

export default ActionTypes
