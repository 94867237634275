import classNames from 'classnames'
import { useFormik } from 'formik'
import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { verifyRecaptchToken } from '../../services/publicIncidentService'

function PublicIncidentLogin() {
  const recaptchaRef = useRef()
  const [reCaptchaToken, setReCaptchaToken] = useState('')
  const history = useHistory()

  const handleCaptchaChange = (value) => {
    setReCaptchaToken(value)
  }

  const formik = useFormik({
    initialValues: { fname: '', lname: '', email: '', accessCode: '' },
    validationSchema: Yup.object({
      fname: Yup.string(),
      lname: Yup.string(),
      email: Yup.string().email(),
      accessCode: Yup.string().required('Access code is Required.'),
    }),
    onSubmit: async (val) => {
      try {
        const { data } = await verifyRecaptchToken({
          token: reCaptchaToken,
          accessCode: val.accessCode,
        })
        recaptchaRef.current.reset()
        if (data.data.success) {
          data.data.accessCode
            ? history.push('/add-new-incident', val)
            : toast.error('Please enter valid access code')
        } else {
          toast.error('Please verify you are not a robot')
        }
      } catch (err) {
        toast.error(err.message)
      }
    },
  })

  const [showAccessCode, setShowAccessCode] = useState(false)

  return (
    <>
      <div className="m-content">
        <div className="container">
          <div className="col-lg-12">
            <div className="text-center">
              <div className="mt-3 mb-4">
                <img src="/assets/images/logo.png" alt="acuity" />
              </div>
              <h1 className="wel_back">Welcome Back!</h1>
              <h6 className="login-acu mt-3 mb-5">
                Enter your account credentials to add public incident
              </h6>
            </div>
            <div className="m-portlet">
              <form className="m-form m-form--fit p-4">
                <div className="">
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>First Name</strong>
                      </label>
                      <input
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.fname &&
                            formik?.errors?.fname?.length,
                        })}
                        name="fname"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fname}
                      />
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.fname && formik?.errors?.fname) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>Last Name</strong>
                      </label>
                      <input
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.lname &&
                            formik?.errors?.lname?.length,
                        })}
                        name="lname"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lname}
                      />
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.lname && formik?.errors?.lname) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>Email Address</strong>
                      </label>
                      <input
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.email &&
                            formik?.errors?.email?.length,
                        })}
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.email && formik?.errors?.email) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>Access Code</strong>
                      </label>
                      <div
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.accessCode &&
                            formik?.errors?.accessCode?.length,
                        })}
                      >
                        <input
                          name="accessCode"
                          type={showAccessCode ? 'text' : 'password'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accessCode}
                          style={{
                            width: '97%',
                            border: 'none',
                            outline: 'none',
                            background: 'none',
                          }}
                        />
                        <i
                          className={`fas ${
                            showAccessCode ? 'fa-eye-slash' : 'fa-eye'
                          }`}
                          style={{ marginRight: -30, cursor: 'pointer' }}
                          onClick={() => setShowAccessCode(!showAccessCode)}
                        />
                      </div>
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.accessCode &&
                          formik?.errors?.accessCode) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  {/* recaptcha v2 */}
                  <ReCAPTCHA
                    className="capcha-wrapper"
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />

                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <button
                        type="button"
                        onClick={() => formik.handleSubmit()}
                        className="btn btn-primary login-btn"
                        style={{ width: '100%' }}
                        disabled={
                          (!formik?.touched?.email &&
                            !formik?.touched?.accessCode) ||
                          (formik?.touched?.email &&
                            formik?.errors?.email?.length) ||
                          (formik?.touched?.accessCode &&
                            formik?.errors?.accessCode?.length)
                        }
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*   <footer className="m-footer">
          <div className="text-center">
            <span>Copyright © {moment().year()} All Rights Reserved.</span>
          </div>
        </footer> */}
        </div>
      </div>
    </>
  )
}

export default withRouter(PublicIncidentLogin)
