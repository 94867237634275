import React from 'react'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextInput from '../../../components/form/TextInput'
import { notificationMethod } from '../../incident/utils'

export default function SupervisorDetailsView({ values, setFieldValue }) {
  return (
    <div className="d-flex flex-column">
      <div className="border-bottom h6 py-2 mb-3">Supervisor Details</div>
      <div className="form-row flex-1">
        <div className="col-md-12">
          <TextInput
            withAsterisk
            name={`incident.supervisorDetails.name`}
            label="Supervisor Name"
            showErrorEvenWhenUntouched
          />
        </div>
        <div className="col-md-6">
          <TextInput
            data-testid="supervisor-email"
            type="email"
            name={`incident.supervisorDetails.email`}
            label="Supervisor Email"
            showErrorEvenWhenUntouched
          />
        </div>
        <div className="col-md-6">
          <TextInput
            isPhone
            data-testid="supervisor-contact-number"
            changeOnUndefined={true}
            label="Contact Number"
            name="incident.supervisorDetails.contactNumber"
            showErrorEvenWhenUntouched
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue(
                'incident.supervisorDetails.contactNumber',
                phone || '',
              )
            }}
            value={values?.incident?.supervisorDetails?.contactNumber || ''}
          />
        </div>
        <div className="form-group col-md-6">
          <DatePickerField
            name="incident.supervisorDetails.notifiedDate"
            label="Date Supervisor Notified of Incident:"
          />
        </div>
        <div className="form-group col-md-6">
          <Select
            label="Method Supervisor Notified"
            name="incident.supervisorDetails.notificationMethod"
            defaultLabel="Select Notification Method"
            options={notificationMethod}
            value={
              values?.incident?.supervisorDetails?.notificationMethod || ''
            }
          />
        </div>
      </div>
    </div>
  )
}
