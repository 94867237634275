import React from 'react'
import CommonDropDown from './CommonDropDown'

const Attachments = ({
  show,
  handleDropDownOpenCloseClicked,
  extras,
  attachments,
  onChekedChange,
}) => {
  const onChange = (value, key) => {
    onChekedChange?.(value, key)
  }

  const options = [
    {
      value: 'READ',
      label: 'Read',
      checked: attachments.includes('READ'),
      onChange: function (e) {
        onChange(e.target.value, 'attachments')
      },
    },
    {
      value: 'CREATE',
      label: 'Create (upload)',
      checked: attachments.includes('CREATE'),

      onChange: function (e) {
        onChange(e.target.value, 'attachments')
      },
    },
    {
      value: 'DOWNLOAD',
      label: 'Download',
      checked: extras.includes('DOWNLOAD'),

      onChange: function (e) {
        onChange(e.target.value, 'extras')
      },
    },
    {
      value: 'COPY_LINK',
      label: 'Copy Link',
      checked: extras.includes('COPY_LINK'),

      onChange: function (e) {
        onChange(e.target.value, 'extras')
      },
    },
    {
      value: 'DELETE',
      label: 'Delete',
      checked: attachments.includes('DELETE'),

      onChange: function (e) {
        onChange(e.target.value, 'attachments')
      },
    },
  ]

  const allChecked = options.every((option) => option.checked)

  return (
    <CommonDropDown
      options={options}
      allChecked={allChecked}
      handleDropDownOpenCloseClicked={handleDropDownOpenCloseClicked}
      onSelectAll={() => {
        options.forEach((c) => {
          const e = {
            target: {
              value: c.value,
            },
          }
          if(!allChecked) {
            !c?.checked && c?.onChange(e)
          } else {
            c?.onChange(e);
          }
        })
      }}
      show={show}
      subModule="attachments"
      title={'Attachments'}
    />
  )
}

export default Attachments
