import React, { useMemo } from 'react'
import flatten from 'flat'
const ErrorMessage = ({ errors = {}, name }) => {
  const flatErrors = useMemo(() => flatten(errors), [errors])
  const error = flatErrors?.[name]
  if (error) {
    return (
      <span className="text-danger ml-1" style={{ color: 'red' }}>
        {error}
      </span>
    )
  }else {
    return <></>
  }
}

export default ErrorMessage
