import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select1 from 'react-select'
import { savePolicyInfo } from '../../../actions'
import TextInput from '../../../components/form/TextInput'
import { getPolicies } from '../../../services/policyService'
import { allPolicyTypes } from '../../incident/utils'
import { useDirectClaimContext } from './DirectClaimContext'
import FormikSelect from './FormikSelect'

export default function ClaimNotes() {
  const { values, setFieldValue, handleChange, handleBlur, errors } =
    useFormikContext()
  const { activeStep, setActiveStep, isNew } = useDirectClaimContext()
  const dispatch = useDispatch()
  const [policies, setPolicies] = useState([])
  const [claimants, setClaimants] = useState([])
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const fetchPolicies = useCallback(async () => {
    const { data: policies } = await getPolicies()
    if (policies.data && Array.isArray(policies.data)) {
      dispatch(savePolicyInfo(policies.data))
      setPolicies(
        policies.data
          .filter(
            (el) =>
              el.clientId === values?.clientId ||
              el.clientId === selectedClientId,
          )
          .map((p) => ({
            policyId: p._id,
            policyName: p.policyId,
            policyType: p.policyType,
          })),
      )
    }
  }, [])

  useEffect(() => {
    fetchPolicies()
    let claimantsData = values?.incident.partiesInvolved.map((party) => {
      party.basicInfo = party.personDetails
      party.coverageType = values?.incident?.coverageType
      party.description = values?.incident?.description
      return party
    })
    .filter((claimant) =>
    values?.incident?.vehicularAccident ? !claimant.isCompanyEmployee : true)

    setClaimants(claimantsData)
  }, [])

  useEffect(() => {
    fetchPolicies()
  }, [values?.clientId, selectedClientId])

  return (
    <>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="Claimant">Claimant</label>
          <Select1
            placeholder="Select Claimant"
            getOptionLabel={(option) =>
              `${option?.basicInfo?.firstName || ''} ${
                option?.basicInfo?.lastName || ''
              }`
            }
            getOptionValue={(option) => option}
            name="incident.claimantInfo"
            value={values?.incident.claimantInfo || ''}
            isSearchable="true"
            onChange={(val) => setFieldValue('incident.claimantInfo', val)}
            options={claimants}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Claim Number</label>
            <input
              className="form-control"
              placeholder="Claim Number"
              name="incident.claimNumber"
              value={values?.incident.claimNumber}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <div className="col-md-6">
          <div className="form-group">
            <label>Type of Claim</label>
            <input
              className="form-control"
              placeholder="Type of Claim"
              name="incident.description.claimType"
              type="text"
              value={values?.incident?.description?.claimType}
              onChange={handleChange}
            />
          </div>
        </div> */}
        <div className="col-md-6">
          <div className="form-group">
            <FormikSelect
              label="Coverage Type"
              withAsterisk
              name="incident.coverageType"
              defaultLabel="Select Coverage Type"
              options={allPolicyTypes}
              value={values.incident.coverageType}
              setFieldValue={setFieldValue}
              errors={errors}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6">
          <TextInput
            isPhone
            changeOnUndefined={true}
            label="Claimant Contact"
            name="incident.notes.claimantContactNumber"
            showErrorEvenWhenUntouched
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('incident.notes.claimantContactNumber', phone ?? '')
            }}
            value={values?.incident?.notes?.claimantContactNumber || ''}
          />
        </div>
        <div className="col-md-6" style={{ marginBottom: '20px' }}>
          <label htmlFor="Policy">Policy Details </label>
          <Select1
            placeholder="Select Policy"
            getOptionLabel={(option) => option?.policyName || ''}
            getOptionValue={(option) => option}
            name="incident.policyDetails"
            value={
              policies?.filter(
                (p) =>
                  p.policyType === values.incident?.coverageType?.toUpperCase(),
              ).length
                ? values?.incident?.policyDetails
                : null
            }
            isClearable="true"
            isSearchable="true"
            onChange={(val) => setFieldValue('incident.policyDetails', val)}
            options={policies?.filter(
              (p) =>
                p.policyType === values.incident?.coverageType?.toUpperCase(),
            )}
            // options={policies}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Adjuster Name</label>
            <input
              className="form-control"
              placeholder="Adjuster Name"
              name="incident.adjusterName"
              type="text"
              value={values?.incident?.adjusterName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label>Adjuster Contact Details</label>
          <input
            className="form-control"
            placeholder="Adjuster Contact Details"
            name="incident.adjusterContactDetails"
            type="text"
            value={values?.incident?.adjusterContactDetails || ''}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Claim Handled By</label>
            <input
              className="form-control"
              placeholder="Claim Handled By"
              name="incident.claimHandledBy"
              type="text"
              value={values?.incident?.claimHandledBy}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group mb-3">
        <label>Severity</label>
        <div>
          <div className="form-check form-check-inline mr-4 ">
            <input
              className="form-check-TextInput"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio-1"
              value="Low"
              checked={values?.incident?.description?.severity === 'Low'}
              onChange={() =>
                setFieldValue('incident.description.severity', 'Low')
              }
            />
            <label
              className="form-check-label"
              htmlFor="inlineRadio-1"
              style={{ paddingLeft: '5px' }}
            >
              Low
            </label>
          </div>
          <div className="form-check form-check-inline mr-4">
            <input
              className="form-check-TextInput"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio-2"
              value="Medium"
              checked={values?.incident?.description?.severity === 'Medium'}
              onChange={() =>
                setFieldValue('incident.description.severity', 'Medium')
              }
            />
            <label
              className="form-check-label"
              htmlFor="inlineRadio-2"
              style={{ paddingLeft: '5px' }}
            >
              Medium
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-TextInput"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio-3"
              value="High"
              checked={values?.incident?.description?.severity === 'High'}
              onChange={() =>
                setFieldValue('incident.description.severity', 'High')
              }
            />
            <label
              className="form-check-label"
              htmlFor="inlineRadio-3"
              style={{ paddingLeft: '5px' }}
            >
              High
            </label>
          </div>
        </div>
      </div>
      <div className="form-group mb-3">
        <label htmlFor="Discrepancy" className="col-form-label">
          Discrepancy
        </label>
        <textarea
          onChange={(a) =>
            setFieldValue('incident.description.discrepancy', a.target.value)
          }
          className="form-control"
          id="Discrepancy"
          placeholder={'Discrepancy'}
          value={values?.incident?.description?.discrepancy || ''}
        />
      </div>
    </>
  )
}
