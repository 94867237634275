import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from 'chart.js';
import Chart from "react-apexcharts";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClaimsIncurredReportData } from '../../../../services/dashboardService';
import Loader from '../../../../components/common/Loader';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labelColor = '#3F4254';
const borderColor = '#E4E6EF';


let initialOptions = {
  series: [{
    name: 'WC',
    data: []
  },
  {
    name: 'GL',
    data: []
  },
  {
    name: 'Auto',
    data: []
  },
  {
    name: 'Property',
    data: []
  }
  ],
  chart: {
    height: 350,
    type: 'area',
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    show: true,
    width: 3,
    colors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 6
    }
  },
  xaxis: {
    type: 'datetime',
    categories: [],
    overwriteCategories: undefined,
    position: 'bottom',
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: true,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
      offsetX: 0,
      offsetY: 0,
      formatter: undefined,
      datetimeUTC: false,
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'dd MMM',
        hour: 'HH:mm',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: labelColor,
        fontSize: '12px'
      }
    }
  },
  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0
      }
    },
    hover: {
      filter: {
        type: 'none',
        value: 0
      }
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0
      }
    }
  },
  tooltip: {
    x: {
      show: true,
      formatter: function (val) {
        return moment(val).format('DD/MM/yyyy HH:mm')
      }
    },
    marker: {
      fillColors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
    }
  },
  grid: {
    borderColor: borderColor,
    strokeDashArray: 4,
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    position: 'top',
    offsetY: 10,
    markers: {
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
      colors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0
    },
  },
  fill: {
    opacity: 1,
    colors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
  },
  colors: ['#7239ea', '#4285f4', '#72a4f7', '#00d2ff'],
};

function ClaimsIncurred() {
  const [options, setOptions] = useState(initialOptions)
  const [isLoading, setIsLoading] = useState(true)
  const [total, setTotal] = useState({ WCTotal: 0, GLTotal: 0, autoTotal: 0, propertyTotal: 0 })

  const { slot, selectedClientId } = useSelector((state) => state)


  useEffect(() => {
    setIsLoading(true)
    getClaimsIncurredReportData(slot).then(response => {
      let claimsIncurredReport = response.data.data
      setOptions({
        ...options,
        series: claimsIncurredReport?.series,
        xaxis: {
          ...options.xaxis,
          categories: claimsIncurredReport?.timeslots
        }
      })
      let WCTotal = claimsIncurredReport?.series[0].data.reduce((a, b) => a + b, 0)
      let GLTotal = claimsIncurredReport?.series[1].data.reduce((a, b) => a + b, 0)
      let autoTotal = claimsIncurredReport?.series[2].data.reduce((a, b) => a + b, 0)
      let propertyTotal = claimsIncurredReport?.series[3].data.reduce((a, b) => a + b, 0)
      setTotal({
        WCTotal: WCTotal,
        GLTotal: GLTotal,
        autoTotal: autoTotal,
        propertyTotal: propertyTotal
      })
      setIsLoading(false)
    })
  }, [slot, selectedClientId])

  return (
    <div>
      <div className="card card-bordered" style={{ minHeight: '550px' }}>
        {isLoading &&
          <div style={{ marginTop: "200px" }} ><Loader loading={isLoading} /></div>
        }
        {!isLoading &&
          <>
            <div className="d-flex justify-content-between card-header border-0 mt-3 pt-2">
              <div>
                <h6 className="card-title align-items-start flex-column p-0 m-0">
                  <span className="font-weight-bolder text-dark">Claims Incurred</span>
                </h6>
              </div>
              <div className="d-flex flex-wrap ml-2">
                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mr-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">WC</span>
                  <div className="fw-semibold text-gray-400">{total?.WCTotal}</div>
                </div>

                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mr-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">
                    <span className="ms-n1 counted">GL</span></span>
                  <div className="fw-semibold text-gray-400">{total?.GLTotal}</div>
                </div>
                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mr-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">
                    <span className="ms-n1 counted">Auto</span></span>
                  <div className="fw-semibold text-gray-400">{total?.autoTotal}</div>
                </div>
                <div
                  className="border-gray-300 border-dashed rounded-lg min-w-160px w-160 py-2 px-3 mr-3 mb-3">
                  <span className="fs-20 text-gray-700 fw-bold">
                    <span className="ms-n1 counted">Property</span></span>
                  <div className="fw-semibold text-gray-400">{total?.propertyTotal}</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <Chart
                options={options}
                series={options.series}
                type="area" height={350}
              />
            </div>

            <div className="kt-portlet__foot d-flex justify-content-between p-3 align-items-center">
              <div className="">
                <a href="/incidents" className="text-uppercase font-weight-semibold">View All
                  <span className="svg-icon-arrow">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3"
                        d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                        fill="currentColor"></path>
                      <rect x="21.9497" y="3.46448" width="13" height="2" rx="1"
                        transform="rotate(135 21.9497 3.46448)" fill="currentColor">
                      </rect>
                      <path
                        d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                        fill="currentColor"></path>
                    </svg>
                  </span></a>
              </div>


            </div>
          </>
        }
      </div>
    </div>
  )
}

export default ClaimsIncurred
