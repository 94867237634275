import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ActionTypes from '../../actionTypes'
import { getModules, getRoleDetails } from '../../services/roleService'
import AccountSettings from './AccountSettings'
import EditOrCreateRoleNew from './EditOrCreateRoleNew'
import History from './History'
import Roles from './Roles'

function Settings() {
  const dispatch = useDispatch()

  useEffect(() => {
    getModules().then((r) => {
      dispatch({
        type: ActionTypes.SAVE_MODULES,
        payload: r.data.data,
      })
    })
  }, [])
  const [isRoleDetailView, setIsRoleDetailView] = useState(false)
  const [editRoleData, setEditRoleData] = useState(null)
  const [selectedTabId, setSelectedTabId] = useState('account')

  const fetchRoleDetails = async (id) => {
    const { data } = await getRoleDetails(id)
    setEditRoleData(data.data)
    setIsRoleDetailView(true)
  }

  const getRightSideView = () => {
    switch (selectedTabId) {
      case 'account':
        return <AccountSettings />
      case 'roles':
        return isRoleDetailView ? (
          // <EditOrCreateRole
          //   editRoleData={editRoleData}
          //   handleBackButtonClick={() => {
          //     setAddNewRole(false)
          //     setEditRoleData(null)
          //   }}
          // />

          <EditOrCreateRoleNew
            editRoleData={editRoleData}
            handleBackButtonClick={() => {
              setIsRoleDetailView(false)
              setEditRoleData(null)
            }}
          />
        ) : (
          <Roles
            fetchRoleDetails={fetchRoleDetails}
            handleAddNewRole={() => setIsRoleDetailView(true)}
          />
        )
      case 'history':
        return <History />
    }
  }

  return (
    <>
      <div className="aside-menu" style={{ top: '145px' }}>
        <div
          className="hover-scroll-overlay-y my-5 my-lg-5"
          id="kt_aside_menu_wrapper"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
          data-kt-scroll-wrappers="#kt_aside_menu"
          data-kt-scroll-offset="0"
        >
          <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
            <div className=" here show menu-accordion">
              <span className="menu-link ">
                <span className="menu-section text-muted text-uppercase fs-8">
                  <strong className="text-color-2">GLOBAL SETTINGS</strong>
                </span>
                <div className="menu-arrow mx-4 my-1"></div>
              </span>
              <div className="menu-sub menu-sub-accordion menu-active-bg mt-3">
                <div className="menu-item">
                  <a
                    className={
                      'menu-link' + (selectedTabId === 'roles' ? ' active' : '')
                    }
                    onClick={() => setSelectedTabId('roles')}
                  >
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title">Roles</span>
                  </a>
                </div>
                {/* <div className="menu-item">
                  <a
                    className={
                      'menu-link' +
                      (selectedTabId === 'notifications' ? ' active' : '')
                    }
                    onClick={() => setSelectedTabId('notifications')}
                  >
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title">Notifications</span>
                  </a>
                </div> */}
                <div className="menu-item">
                  <a
                    className={
                      'menu-link' +
                      (selectedTabId === 'history' ? ' active' : '')
                    }
                    onClick={() => setSelectedTabId('history')}
                  >
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title">History</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a
                    className={
                      'menu-link' + (selectedTabId === 'trash' ? ' active' : '')
                    }
                    onClick={() => setSelectedTabId('trash')}
                  >
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title">Trash</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a
                    className={
                      'menu-link' +
                      (selectedTabId === 'account' ? ' active' : '')
                    }
                    onClick={() => setSelectedTabId('account')}
                  >
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title">Account Settings</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="menu-content pt-4 pb-2">
                <span className="menu-section text-muted text-uppercase fs-8">
                  <strong className="text-color-2">App Management</strong>
                </span>{' '}
                <small className="text-white ft-8 ">
                  {' '}
                  <small className="text-white ft-8 ">
                    <span
                      className="
                                badge-danger px-3 border-radius-12 py-1 upcomming ml-2"
                    >
                      Upcoming!
                    </span>
                  </small>
                </small>
                <div className="menu-arrow mx-4 my-1"></div>
              </div>
              <div className="menu-sub menu-sub-accordion menu-active-bg">
                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">Media Library</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">Integrations</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">Reports</span>
                  </a>
                </div>

                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">Calendar</span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">
                      Account Settings
                    </span>
                  </a>
                </div>
                <div className="menu-item">
                  <a className="menu-link">
                    <span className="menu-bullet">
                      <span className="bullet-square bullet-dot-sqaure"></span>
                    </span>
                    <span className="menu-title dulltext">
                      Advance Settings
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {getRightSideView()}
    </>
  )
}

export default Settings
