import { Field } from 'formik'
import React from 'react'

const PublicIncidentAcknowledgement = () => {
  return (
    <div className="col-md-12">
      <div className="form-group d-flex my-4">
        <div>
          <Field
            type="checkbox"
            name="isAgree"
            className="w-20"
            id="checkboxTerms"
          />{' '}
        </div>
        <div className="ml-3">
          I certify that the information given above is true, correct, and accurate.
          <br />
          <span className="">
            I understand that it is unlawful to knowingly assist, abet, conspire with, or solicit a person to file a fraudulent workers’ compensation claim. All questionable claims will be investigated and violators will be punished to the full extent of the law.
          </span>
        </div>
      </div>
    </div>
  )
}

export default PublicIncidentAcknowledgement
