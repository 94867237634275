import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/common/Table'
import { toast } from 'react-toastify'

import ActionWidget from '../../../components/common/ActionWidget'
import {
  deleteBlukClient,
  deleteClient,
  getClients,
} from '../../../services/clientService'
import { getPagedData } from '../../../services/helperService'
import { handleFetchClients, handleSideNavDataUpdated, handleTabIdUpdated } from '../../../actions'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import Loader from '../../../components/common/Loader'

let clients = []
let itemsCount = 0
function ClientList({
  searchQuery,
  handleEdit,
  handleView,
  context,
  checkedRecords,
  handleElementCheckboxClicked,
  setSelectFilter,
  setShowDeleteButton,
  selectFilters,
  dateRangeFilter,
  openBulkDel,
  closeBulkDel,
  handleDechecked
}) {
  let dispatch = useDispatch()
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [isLoading , setIsLoading] = useState(true);
  

  const columns = [
    {
      path: 'clientId',
      label: ' Client Id ',
      minWidth: '180px',
      content: (client) => (
        <>
          <a>{client.clientId}</a>
          <ActionWidget
            module="CLIENT"
            onEdit={() => handleEdit(client)}
            onDelete={() => {
              setClientList(client);
              setDeleteOne(true);
            }}
            onView={() => {
              handleView(client)
            }}
          />
        </>
      ),
    },
    { path: 'companyName', label: ' Company Name ', type: 'string' },
    { path: 'taxId', label: ' FEIN/TAX ID ' },
    { path: 'unemployementId', label: ' State Unemployment ID ' },
    { path: 'issueDate', label: 'Filing Date ', type: 'fiscalDate' },
    { path: 'incorporationState', label: ' State of Incorporation ' },
    { path: 'corporationType', label: ' Type of Corporation ' },
    { path: 'fiscalYear', label: ' Fiscal year End ', type: 'fiscal' },
  ]
  const [sortColumn, setSortColumn] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [deleteOne , setDeleteOne] = useState(false);


  const [clientList, setClientList] = useState({})

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedClientId])



  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])


  useEffect(() => {
    if (openBulkDel) {
      setClientList({ clientList: { companyName: 'Cname' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    if (
      selectedTabId === 'client' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEdit(viewData)
    }
    if (selectedTabId === 'client' && viewAction === 'refresh') {
      setIsLoading(true)
      fetchList()
      dispatch(handleTabIdUpdated('client'))
    }
  }, [viewAction, viewData, selectedTabId])

  const fetchList = async () => {
    const { data } = await getClients()
    setData(data.data)
    dispatch(handleFetchClients(data.data))
    if (sideNavDataContext !== 'searchView') {
      dispatch(
        handleSideNavDataUpdated({ data: data.data[0], module: 'client' }),
      )
      dispatch(handleTabIdUpdated('client'))
    }
    setIsLoading(false)
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    clients = result
    return result.length > 0
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukClient(params)        
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        // setDeleteOne(false);
        // handleDechecked();
        // closeBulkDel();
        setShowDeleteButton(false)
        closeBulkDel()
      } else {
        const { data } = await deleteClient(clientList._id, clientList)        
        handleDechecked();
        setDeleteOne(false);
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setDeleteOne(false);
      }
    } catch (error) {
      console.log('error' , error)
    }    
    setClientList({})
    await fetchList()
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData() || ''}
      <Table
        data={clients.filter((clientData) => {
          const { incorporationState, corporationType } = clientData
          const {
            incorporationState: selectedIncorporationState = '',
            corporationType: selectedCorporationType = [],
          } = selectFilters
          
          let isIncorporationState = false,
            isCorporationType = false
          let isDateRangeValid = false
          let isDateRangeSelected = Object.values(dateRangeFilter).every(
            (x) => {
              return x.start === null
            },
          )

          if (dateRangeFilter && !isDateRangeSelected) {
            Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
              if (start !== null && clientData[key] !== '') {
                if (
                  clientData[key] > start.toISOString() &&
                  clientData[key] < end.toISOString()
                ) {
                  isDateRangeValid = true
                } else {
                  isDateRangeValid = false
                }
              }
            })
          }

          if (
            selectedIncorporationState.length &&
            selectedIncorporationState.includes(incorporationState)
          ) {
            isIncorporationState = true
          }

          if (
            selectedCorporationType.length &&
            selectedCorporationType === corporationType
          ) {
            isCorporationType = true
          }

          return (
            !(!!selectedIncorporationState.length ^ isIncorporationState) &&
            !(!!selectedCorporationType.length ^ isCorporationType) &&
            !(!isDateRangeSelected ^ isDateRangeValid)
          )
        })}
        columns={columns}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked.bind(this, clients)}
      />
      <ConfirmationModal
        name={
          checkedRecords.length > 0 ? `Clients` : clientList?.companyName || ''
        }
        isOpen={Object.keys(clientList || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) }        
        onClose={() => {          
          setShowDeleteButton(false)
          closeBulkDel();
          setDeleteOne(false);
          handleDechecked()
          setClientList({})
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default ClientList
