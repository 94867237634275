import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/claim`

export function getClaims() {
  return http.get(`${apiEndpoint}/get-claim-list`)
}

export function exportClaims() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getClaim(id) {
  return http.get(`${apiEndpoint}/get-claim/${id}`)
}

export function updateClaim(id, claim) {
  return http.put(`${apiEndpoint}/update-claim/${id}`, claim)
}

export function deleteClaim(id, claim) {
  return http.put(`${apiEndpoint}/delete-claim/${id}`, claim)
}

export function deleteBlukClaim(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}

export function saveClaim(claim) {
  return http.post(`${apiEndpoint}/save`, claim)
}

export function getCoverageType() {
  return http.get(`${apiEndpoint}/coverage-type`)
}

export function getClaimsByTime(type) {
  return http.get(`${apiEndpoint}/date/`, {
    params: { id: type },
  })
}

export function getClaimsByTd(type) {
  return http.get(`${apiEndpoint}/ymtd/`, {
    params: { id: type },
  })
}

export function getConvertedClaims() {
  return http.get(`${apiEndpoint}/get-converted-claim-list`)
}