import { useFormik } from 'formik'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { saveClaim, updateClaim } from '../../../services/claimService'
import { generateUID } from '../../../utils/generateUID'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'
import { convertDateToStringIncidentForm } from '../../incident/utils'

const getDirectClaimPayloadData = async (values) => {
  let newValues = convertDateToStringIncidentForm({ ...values }, true)
  newValues?.dateOfClaim &&
    (newValues.dateOfClaim = moment(newValues.dateOfClaim).format(
      NO_TIMEZONE_DATE_FORMAT,
    ))
  // let newValues = values
  let incident = newValues.incident
  if (incident.vehiclesInvolved.length > 0) {
    let vehInvolved = []
    incident?.vehiclesInvolved?.forEach(function (vehicleInvolved) {
      let vehicleDamages = []
      if (vehicleInvolved.vehicleDamages) {
        vehicleInvolved?.vehicleDamages.forEach(function (vehDamage) {
          if (vehDamage) {
            vehicleDamages.push(vehDamage.value)
          }
        })
      }
      vehInvolved.push({
        ...vehicleInvolved,
        vehicleDamages: vehicleDamages,
      })
    })
    incident.vehiclesInvolved = vehInvolved
  }
  if (
    incident.propertiesInvolved?.length > 0
    // &&
    // incident.propertiesInvolved.some(
    //   (property) => !property.isThirdPartyPropertyDamaged,
    // )
  ) {
    let propertiesInvolved = []
    incident?.propertiesInvolved?.forEach(function (propertyInvolved) {
      let propertyDamages = []
      propertyInvolved.propertyDamages.forEach(function (propertyDamage) {
        if (propertyDamage) {
          propertyDamages.push(propertyDamage.value)
        }
      })
      propertiesInvolved.push({
        ...propertyInvolved,
        propertyDamages: propertyDamages,
      })
    })
    incident.propertiesInvolved = propertiesInvolved
  }
  if (incident.partiesInvolved) {
    incident.partiesInvolved.forEach((party) => {
      if (party?.personDetails?.employeeId === '') {
        delete party.personDetails.employeeId
      }
    })
  }

  let payload = {
    ...newValues,
    incident: { ...incident, clientId: newValues.clientId },
  }

  return payload
}

export const validationSchema = yup.object().shape({
  clientId: yup.string().trim().required('Please select a client!'),
  dateOfClaim: yup
    .date()
    .typeError('Date of claim is required!')
    .required('Date of claim is required!'),
  incident: yup.object().shape({
    notes: yup.object().shape({
      claimantContactNumber: yup
        .string()
        .trim()
        .test({
          test: (value) => {
            if (value && value !== '') {
              return isValidPhoneNumber(value ?? '', {
                defaultCountry: 'US',
                defaultCallingCode: '+1',
              })
            }
            return true
          },
          message: 'Please enter a valid US number',
        }),
      litigationAttorney: yup.object().shape({
        contactNumber: yup
          .string()
          .trim()
          .test({
            test: (value) => {
              if (value && value !== '') {
                return isValidPhoneNumber(value ?? '', {
                  defaultCountry: 'US',
                  defaultCallingCode: '+1',
                })
              }
              return true
            },
            message: 'Please enter a valid US number',
          })
          .optional(),
      }),
    }),
    incidentDetails: yup.object().shape({
      pocNumber: yup
        .string()
        .trim()
        .test({
          test: (value) => {
            if (value && value !== '') {
              return isValidPhoneNumber(value ?? '', {
                defaultCountry: 'US',
                defaultCallingCode: '+1',
              })
            }
            return true
          },
          message: 'Please enter a valid US number',
        }),
      dateOfIncident: yup
        .date()
        .typeError('Date of Incident is required!')
        .required('Date of Incident is required!'),
      location: yup
        .string()
        .trim()
        .required('Please provide location where the incident happened!'),
      driverStatement: yup
        .string()
        .trim()
        .required('Driver statement is required!'),
    }),
    coverageType: yup.string().trim().required('Coverage Type is required!'),
    returnToWorkDate: yup
      .date()
      .nullable()
      .required('Return to Work Date is required!')
      .test({
        test: (value, context) => {
          const dateOfIncident =
            context?.parent?.incidentDetails?.dateOfIncident
          if (dateOfIncident && value) {
            return moment(value).isSameOrAfter(dateOfIncident)
          }

          return true
        },
        message: 'Return to Work Date must be on/after incident date.',
      }),
    startDate: yup
      .date()
      .nullable()
      .test({
        test: (value, context) => {
          const dateOfIncident = context.parent?.incidentDetails?.dateOfIncident
          if (
            dateOfIncident &&
            value &&
            moment(value).isBefore(dateOfIncident)
          ) {
            return false
          }
          return true
        },
        message: 'Start date must be on/after incident date',
      })
      .optional(),
    endDate: yup
      .date()
      .nullable()
      .test({
        test: (value, context) => {
          const startDate = context.parent?.startDate
          if (startDate && value && moment(value).isBefore(startDate)) {
            return false
          }
          return true
        },
        message: 'Due date must be on/after start date',
      })
      .optional(),
    closeDate: yup
      .date()
      .nullable()
      .test({
        test: (value, context) => {
          const startDate = context.parent?.startDate
          if (startDate && value && moment(value).isBefore(startDate)) {
            return false
          }
          return true
        },
        message: 'Close date must be on/after start date',
      })
      .optional(),
    userDetails: yup.object().shape({
      employeeId: yup.string().trim().required('Please select an employee'),
      employeeStatus: yup
        .string()
        .trim()
        .required('Please select employee status'),
      firstName: yup.string().when('employeeId', {
        is: 'OTHER',
        then: (schema) =>
          schema.trim().required('Employee first name is required!'),
      }),
      lastName: yup.string().when('employeeId', {
        is: 'OTHER',
        then: (schema) =>
          schema.trim().required('Employee last name is required!'),
      }),
      email: yup.string().trim().email('Please enter a valid email').optional(),
      contactNumber: yup
        .string()
        .trim()
        .test({
          test: (value) => {
            if (value && value !== '') {
              return isValidPhoneNumber(value ?? '', {
                defaultCountry: 'US',
                defaultCallingCode: '+1',
              })
            }
            return true
          },
          message: 'Please enter a valid US number',
        })
        .optional(),
    }),
    supervisorDetails: yup.object().shape({
      name: yup.string().trim().required('Supervisor name is required!'),
      email: yup
        .string()
        .trim()
        .email('Please enter a valid email!')
        .optional(),
      contactNumber: yup
        .string()
        .trim()
        .test({
          test: (value) => {
            if (value && value !== '') {
              return isValidPhoneNumber(value ?? '', {
                defaultCountry: 'US',
                defaultCallingCode: '+1',
              })
            }
            return true
          },
          message: 'Please enter a valid US number',
        })
        .optional(),
      notifiedDate: yup
        .date()
        .nullable()
        .test({
          test: (value, context) => {
            const dateOfIncident =
              context?.from?.[1]?.value?.incidentDetails?.dateOfIncident
            if (
              dateOfIncident &&
              value &&
              moment(value).isBefore(
                moment(dateOfIncident).startOf('day').toDate(),
              )
            ) {
              return false
            }

            return true
          },
          message: 'This date must be on/after the date of loss',
        })
        .optional(),
    }),
    nextAction: yup.string().trim().required('Next Action field is required!'),
    // status: yup.string().trim().required('Claim Status is required!'),
  }),
  claimStatus: yup.string().trim().required('Claim Status is required!'),
})

export function useDirectClaimForm(editDirectClaimData, onClose) {
  const [activeStep, setActiveStep] = useState(1)
  const [isNew, setIsNew] = useState(true)

  if (editDirectClaimData?.incident?.incidentDetails) {
    editDirectClaimData.incident.incidentDetails.thirdPartyLeaves =
      editDirectClaimData.incident.incidentDetails.thirdPartyLeaves?.toString()

    editDirectClaimData.incident.incidentDetails.policeCalled =
      editDirectClaimData.incident.incidentDetails.policeCalled?.toString()
  }
  // Add nextAction,employeeStatus initial value if not present in edit data
  if (editDirectClaimData !== null) {
    if (!editDirectClaimData?.incident?.nextAction) {
      editDirectClaimData.incident.nextAction = ''
    }
    if (!editDirectClaimData?.incident?.userDetails?.employeeStatus) {
      editDirectClaimData.incident.userDetails.employeeStatus = ''
    }
    if (editDirectClaimData?.incident?.partiesInvolved) {
      let partiesInvolvedData = [
        ...editDirectClaimData?.incident.partiesInvolved,
      ]
      let employeeDetails = {
        isCompanyEmployee: true,
        personDetails: {
          firstName:
            editDirectClaimData?.incident?.userDetails?.firstName || '',
          lastName: editDirectClaimData?.incident?.userDetails?.lastName || '',
          email: editDirectClaimData?.incident?.userDetails?.email || '',
          organisation: '',
          contactNumber:
            editDirectClaimData?.incident?.userDetails?.contactNumber || '',
          address: {
            line1: editDirectClaimData?.incident?.userDetails?.address || '',
            line2: '',
            city: editDirectClaimData?.incident?.userDetails.city || '',
            state: editDirectClaimData?.incident?.userDetails.state || '',
            country: editDirectClaimData?.incident?.userDetails.country || '',
            pinCode: editDirectClaimData?.incident?.userDetails.zip || '',
          },
          employeeId: 'OTHERS',
        },
      }
      if (partiesInvolvedData.length) {
        if (
          editDirectClaimData?.incident?.userDetails?.email !==
          partiesInvolvedData[0]?.personDetails?.email
        ) {
          partiesInvolvedData.unshift(employeeDetails)
        }
      } else {
        partiesInvolvedData.push(employeeDetails)
      }
      editDirectClaimData.incident.partiesInvolved = [...partiesInvolvedData]
    }
  }
  const initialValues = useMemo(() => {
    if (editDirectClaimData !== null) {
      setIsNew(false)
    }
    return {
      attachments: [],
      clientId: '',
      incident: {
        returnToWorkDate: '',
        vehicularAccident: true,
        shiftStartTime: '00:00:00 AM',
        incidentNarrative: [],
        packageNumber: '',
        priority: '',
        incidentProperty: null,
        witnesses: [],
        propertiesInvolved: [],
        partiesInvolved: [],
        vehiclesInvolved: [],
        coverageDescription: '',
        claimType: '',
        claimFinancials: [],
        financials: [],
        reportedVia: '',
        incidentDetails: {
          dateOfIncident: null,
          time: '00:00:00 AM',
        },
        supervisorDetails: {
          isNotify: 'true',
        },
        userDetails: {
          employeeId: '',
          employeeStatus: '',
          email: '',
        },
        startDate: '',
        endDate: '',
        closeDate: '',
        incidentId: generateUID('incident'),
        nextAction: '',
      },
      dateOfClaim: '',
      timeOfClaim: '',
      directClaim: true,
      claimId: generateUID('claim'),
      claimStatus: '',
    }
  }, [])

  // const initialValues = useMemo(() => {
  //   if (editDirectClaimData !== null) {
  //     setIsNew(false)
  //   }
  //   return {
  //     attachments: [],
  //     clientId: '',
  //     incident: {
  //       vehicularAccident: true,
  //       dateOfIncident: '',
  //       time: '',
  //       claimNumber: '',
  //       firstName: '',
  //       lastName: '',
  //       email: '',
  //       companyName: '',
  //       incidentNarrative: [],
  //       packageNumber: '',
  //       companyPremises: false,
  //       priority: '',
  //       incidentProperty: null,

  //       witnesses: [],
  //       propertiesInvolved: [],
  //       partiesInvolved: [],
  //       vehiclesInvolved: [],
  //       coverageDescription: '',

  //       amountPaid: '',
  //       amountIncurred: '',
  //       claimFinancials: [],
  //       reportedVia: '',
  //       incidentId: generateUID('incident'),
  //       startDate: '',
  //       endDate: '',
  //       claimantInfo: '',
  //       dateOfClaim: '',
  //       timeOfClaim: '',
  //       coverageType: '',
  //       status: '',
  //       policyDetails: {},
  //       deductible: '',
  //       openSince: '',
  //       checkList: {
  //         claimantContacted: false,
  //         policyAssigned: false,
  //         claimClosed: false,
  //       },
  //       financials: [],
  //       notes: {
  //         claimantContactNumber: '',
  //         claimDeclineReason: '',
  //         litigationDetails: '',
  //         settlementOffered: '',
  //         claimAdjustorRemarks: '',
  //       },
  //       description: {
  //         claimType: '',
  //         allegation: '',
  //         severity: '',
  //         outcome: '',
  //       },
  //       adjusterName: '',
  //       adjusterContactDetails: '',
  //       claimHandledBy: '',
  //     },
  //     dateOfClaim: '',
  //     timeOfClaim: '',
  //     directClaim: true,
  //     claimId: generateUID('claim'),
  //   }
  // }, [])

  const submitForm = async (values) => {
    const claim = await getDirectClaimPayloadData(values)
    if (isNew) {
      const { data } = await saveClaim({
        ...claim,
      })
      toast.success('Direct Claim Added!')
    } else {
      const { result } = await updateClaim(claim._id, { ...claim })
      toast.success('Claim Updated!')
    }
    formik.resetForm()
    onClose()
  }

  const formik = useFormik({
    initialValues: { ...initialValues, ...editDirectClaimData },
    onSubmit: submitForm,
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  })

  return {
    formik,
    setActiveStep,
    activeStep,
    isNew,
    submitForm,
    initialValues: { ...initialValues, ...editDirectClaimData },
    validationSchema,
  }
}
