import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleSideNavDataUpdated, handleSideNavElementClicked, handleTabIdUpdated } from '../../../../actions'
import Loader from '../../../../components/common/Loader'
import Table from '../../../../components/common/Table'
import {
  getPoliciesRenewal, getPolicy
} from '../../../../services/policyService'

let policies = []
let itemsCount = 0

function PolicyRenewal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [sortColumn, setSortColumn] = useState(null)
  const [data, setData] = useState([])
  const mountedRef = useRef(true)
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      path: 'policyId',
      label: 'Policy Id',
      content: (policy) => (
        <>
          <a href="#" onClick={async () => {
            const { data: policyData } = await getPolicy(policy._id)
            dispatch(handleTabIdUpdated('policy'))
            dispatch(
              handleSideNavDataUpdated(
                {
                  data: policyData.data,
                  module: 'policy',
                },
                'searchView',
              ),
            )
            history.push('/policies')
            dispatch(handleSideNavElementClicked('info'))
          }}>{policy.policyId}</a>
        </>
      ),
    },
    { path: 'insuranceCarrier', label: 'Insurance Carrier' },
    { path: 'policyDate', label: 'Policy Date', type: 'date' },
    { path: 'sumInsured', label: 'Sum Insured' },
  ]

  const getPageData = () => {
    const filtered = data
    const sorted =
      sortColumn && sortColumn.path
        ? _.orderBy(filtered, [sortColumn.path], [sortColumn.order])
        : filtered
    itemsCount = sorted.length
    policies = data
    return policies.length > 0
  }

  const { selectedClientId } = useSelector(state => state);
  useEffect(() => {
    mountedRef.current = true
    setIsLoading(true)
    fetchList()
    return () => {
      mountedRef.current = false
    }
  }, [selectedClientId])

  const fetchList = async () => {
    const { data } = await getPoliciesRenewal()
    if (!mountedRef.current) return null
    setData(data.data)
    setIsLoading(false)
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  if (isLoading) return (<div style={{ marginTop: "120px" }} ><Loader loading={isLoading} /></div>)

  return (
    <div className="">
      <div className="card card-bordered">
        <div className="d-flex justify-content-between card-header border-0 mt-3 pt-2">
          <div>
            <h6 className="card-title align-items-start flex-column p-0 m-0">
              <span className="font-weight-bolder text-dark">Policy Renewals</span>
            </h6>
          </div>
        </div>
        <div className="card-body">
          <div className="scroll-change policy-renewal">
            <div className="table-responsive-xl scroll-change">
              <div className="table-responsive-xl table-reponse scroll-change" style={{ height: "400px", overflow: 'auto' }}>
                {getPageData() || ''}
                <Table
                  className={"user-mang"}
                  columns={columns}
                  data={policies}
                  sortColumn={sortColumn}
                  onSort={handleSort}
                  hideCheckbox={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__foot d-flex justify-content-between p-3 align-items-center">
          <div className="">
            <a href="/policies" className="text-uppercase font-weight-semibold">View All
              <span className="svg-icon-arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.3"
                    d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z"
                    fill="currentColor"></path>
                  <rect x="21.9497" y="3.46448" width="13" height="2" rx="1"
                    transform="rotate(135 21.9497 3.46448)" fill="currentColor">
                  </rect>
                  <path
                    d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z"
                    fill="currentColor"></path>
                </svg>
              </span></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PolicyRenewal
