import React, { useMemo } from 'react'
import { string } from 'yup/lib/locale'
import { transformPhoneNumber } from './utils'

function PartiesInvolvedCard(props) {
  const { info = {}, onEdit, onDelete, uid } = props
  const {
    firstName = '',
    lastName = '',
    address: {
      line1 = '',
      line2 = '',
      city = '',
      state = '',
      country = '',
      pinCode = '',
    } = {},
  } = info?.personDetails || {}
  const fullName = firstName + ' ' + lastName
  const phoneNumber = transformPhoneNumber(
    props?.info?.personDetails?.contactNumber,
  )
  const addr =
    [line1, line2, city, state, country].filter((e) => e !== '').join(', ') +
    ' - ' +
    pinCode

    const hasVehicle = useMemo(() => {
      if (Object.keys(props?.info?.vehicleDetails || {})?.length) {
        for (const key of Object.keys(props?.info?.vehicleDetails)) {
          if (key !== 'vehId' && props?.info?.vehicleDetails[key]?.length) {
            return true
          }
        }
      }
      return false
    }, [props?.info?.vehicleDetails])
  return (
    <div
      className="parties bg-white p-3 mb-3"
      data-testid={`parties-involved-${uid}`}
    >
      <div className="pull-right pb-2">
        <button
          data-testid={`edit-party-${uid}`}
          type="button"
          className="btn btn-outline-secondary side-btn mr-1"
          onClick={() => onEdit(uid)}
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
        <button
          data-testid={`delete-party-${uid}`}
          type="button"
          className="btn btn-outline-secondary side-btn"
          onClick={() => onDelete(uid)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
      <h6 className="text-primery">{fullName.trim() !== "" ? fullName : ""}</h6>
      <p className="mb-2">
        <i className="fas fa-mobile-alt mr-2"></i>{' '}
        {phoneNumber.trim() !== '' ? phoneNumber : '-'}
      </p>
      <p className="mb-2">
        <i className="fas fa-map-marker-alt mr-2"></i> {addr}
      </p>
      {props?.info?.isCompanyEmployee && (
        <span className="badge badge-pill btn btn-outline-primary">
          Company Employee
        </span>
      )}
      {props?.info?.hasInjury && (
        <span className="badge badge-pill btn btn-outline-warning">
          Has Injury
        </span>
      )}
      {props?.info?.hasInjury && props?.info?.injuryDetails?.fatality && (
        <span className="badge badge-pill btn btn-outline-danger">
          Fatality
        </span>
      )}
      {hasVehicle && (
        <span className="badge badge-pill btn btn-outline-primary">
          Has Vehicle
        </span>
      )}
    </div>
  )
}
export default PartiesInvolvedCard
