/* eslint-disable react/prop-types */
import { Form, Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { OWNERSHIP_VALIDATION_SCHEMA } from '../utils/validation'
import AddressInput from './form/AddressInput'
import DatePickerField from './form/DatePickerField'
import TextInput from './form/TextInput'
import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../utils/helper'

function AddOwnerShipModalBtn(props) {
  const ref = useRef()
  const { buttonLabel, title, isOpen, setValidateOwnerStructure } = props
  const [modal, setModal] = useState(isOpen || false)
  const init = () => {
    return {
      name: '',
      email: '',
      dob: '',
      ownerShare: '',
      address: {
        state: '',
        city: '',
        country: '',
        line1: '',
        line2: '',
        pinCode: '',
      },
      phoneNumber: '',
    }
  }
  const toggle = () => {
    setModal(!modal)
    !buttonLabel && props.modalClose(null)
  }
  const handleSubmitForm = () => {
    let formatDateValue = ref.current?.values?.dob ? moment(ref.current?.values?.dob).format(NO_TIMEZONE_DATE_FORMAT) : null
    props.modalClose({...ref.current?.values, dob: formatDateValue} || {})
    setValidateOwnerStructure(false)
    toggle()
    ref.current.resetForm()
  }

  return (
    <Formik
      validationSchema={OWNERSHIP_VALIDATION_SCHEMA}
      innerRef={ref}
      enableReinitialize
      initialValues={{ ...init(), ...props.values }}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <>
          <div>
            {buttonLabel && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mt-2"
                id="exampleModalLabel"
                data-toggle="modal"
                data-target="#addownership"
                onClick={toggle}
              >
                {buttonLabel}
              </button>
            )}
            <Modal
              isOpen={modal}
              backdrop="static"
              className={
                'modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable'
              }
              toggle={toggle}
            >
              <div className={'modal-header justify-content-center'}>
                <h5
                  className="modal-title text-primary "
                  id="modal-basic-title"
                >
                  {title}
                </h5>
              </div>
              <ModalBody className="p-0" id="smodaltab">
                <div>
                  <ul
                    className="nav nav-pills bg-light d-flex justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active mt-0 font-weight-normal"
                        id="Person-tab"
                        data-toggle="pill"
                        href="#Person"
                        role="tab"
                        aria-controls="pills-Person"
                        aria-selected="true"
                      >
                        Person Details
                      </a>
                    </li>
                  </ul>
                  <div>
                    <div className={'px-5 mt-4 pb-3'}>
                      <Form>
                        <div className={'form-row'}>
                          <div className="col-md-6">
                            <TextInput label="Name" name="name" />
                          </div>
                          <div className="col-md-6">
                            <TextInput label="Email" name="email" />
                          </div>
                          <div className="col-md-6">
                            <DatePickerField name="dob" label="Date of Birth" />
                          </div>
                          <div className="col-md-6">
                            <TextInput label="Share" name="ownerShare" />
                          </div>
                          <div className="col-md-12">
                            <AddressInput
                              label="Address"
                              name="address"
                              setFieldValue={setFieldValue}
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              isPhone
                              label="Phone"
                              name="phoneNumber"
                              maxLength={14}
                              onPhoneChange={(phone) => {
                                setFieldValue('phoneNumber', phone || '')
                              }}
                              value={values?.phoneNumber || ''}
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <div className={'modal-footer'} style={{ background: '#F4F7FE' }}>
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary add-btn"
                  type="submit"
                >
                  Add
                </button>
                <button
                  onClick={toggle}
                  type="button"
                  className="btn btn-secondary bg-white text-dark cls-btn"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </>
      )}
    </Formik>
  )
}

export default AddOwnerShipModalBtn
