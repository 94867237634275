import React from 'react'
import { getPreSignedUrl, uploadFile } from '../../services/helperService'

function AttachmentUpload({ label, name, afterUpload, type }) {
  const handleFile = async (event) => {
    const files = [...event.target.files]
    event.target.value = null
    const filesToUpload = []
    for (const file of files) {
      const obj = {
        key: file.name,
        contentType: file.type,
      }
      filesToUpload.push(obj)
    }

    const { data: urls } = await getPreSignedUrl({ files: [...filesToUpload] },type)
    afterUpload(urls.data.map((u) => u.objectUrl))
    uploadFiles(files, urls)
  }
  const uploadFiles = async (files, urls) => {
    const promiseArray = []
    const filesArray = Array.from(files)
    for (let i = 0; i < filesArray.length; i++) {
      const myHeaders = new Headers()
      myHeaders.append('ContentType', filesArray[i].type)
      const resp = uploadFile(
        urls.data[i].preSignedUrl,
        filesArray[i],
        myHeaders,
      )
      promiseArray.push(resp)
    }
    await Promise.all(promiseArray)
  }

  return (
    <div className=" input-icon">
      <div className="form-group">
        <label>{label}</label>
        <div className="add-file">
          <i className="fas fa-paperclip" />
          <span className="file-up pt-1 ml-1">Upload File</span>
          <input
            type="file"
            onChange={handleFile}
            multiple
            className="form-control doc-upload search-input"
            name={name}
          />
        </div>
      </div>
    </div>
  )
}

export default AttachmentUpload
