import React from 'react'
import { Modal } from 'reactstrap'
import EditClaim from "./EditClaim";

import { ReactComponent as Close } from '../../svgs/close.svg'

export default function EditClaimModal({ id, onClose }) {
    return (
        <Modal
            isOpen={true}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{width: '40vw'}}
        >
            <div>
                <a
                    onClick={onClose}
                    className="close close-left"
                    aria-label="Close"
                >
                    <Close />
                </a>
                <div className="modal-content mdl-scrol" style={{ height: '100vh' }}>
                    <div className="col-md-12 p-0 col-h">
                        <div className="modal-body p-0 mb-5">
                            <EditClaim id={id} onClose={onClose}/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
