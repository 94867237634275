import classNames from 'classnames'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Table from '../../components/common/Table'
import ListPageHeader from '../../components/ListPageHeader'
import {
  downloadFile,
  getPagedData
} from '../../services/helperService'
import {
  deleteBlukMvr,
  deleteMvr,
  exportMvrs,
  getMvrs,
  updateMvr,
  updateMVRStatus
} from '../../services/mvrService'

import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../actions'
import ActionWidget from '../../components/common/ActionWidget'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import FilterDropdown from '../../components/common/FilterDropdown'
import ImportCSV from '../../components/common/importCSV'
import Loader from '../../components/common/Loader'
import ViewNotes from '../../components/common/ViewNotes'
import MaskedSSN from '../../components/MaskedSSN'
import MVRNotes from '../../components/MVRNotes'
import { ACCESS_TYPES, mvrStatus } from '../../configs/utils'
import { useGetPermission } from '../../hooks/useGetPermission'
import { getAttachmentList } from '../../services/attachmentService'

let mvrs = []
let itemsCount = 0

function MvrList() {
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false)
  const [isLoading , setIsLoading] = useState(true);

  // useGetPermission('mvr', 'CREATE')
  const { currentUser = {}, selectedClientId = '' } = useSelector(
    (state) => state,
  )
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  const isSuperAdmin = useGetPermission('MVR', ACCESS_TYPES['ANALYZE'])

  const [notesModal, setNotesModal] = useState({ state: 'close', info: {} })
  const dispatch = useDispatch()

  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [checkedRecords, setCheckedRecords] = useState([])
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [delMvr, setDelMvr] = useState({})
  const [openBulkDel, setOpenBulkDel] = useState(false)
  const [deleteOne, setDeleteOne] = useState(false)
  const mountedRef = useRef(true)
  const [tab, setTab] = useState(
    isSuperAdmin ? 'mvr_submission' : 'recommendation',
  )
  // const check
  const columns = useMemo(() => {
    let col = [
      {
        path: 'mvrId',
        label: ' MVR Id ',
        minWidth: '240px',
        content: (mvr) => (
          <>
            <a>{mvr.mvrId}</a>
            <ActionWidget
              module="MVR"
              isViewHide={true}
              isUpdateHide={true}
              onDelete={() => {
                setDelMvr(mvr)
                setDeleteOne(true)
              }}
              approveMVR={async (approve) => {
                let status = approve
                  ? mvrStatus.APPROVED
                  : mvrStatus.NOT_APPROVED
                await updateMVRStatus(mvr._id, status)
                fetchList()
              }}
            />
          </>
        ),
      },
      { path: 'firstName', label: ' First Name ', type: 'string' },
      { path: 'lastName', label: ' Last Name ', type: 'string' },
      {
        path: 'ssnCode',
        label: ' Social Security Number ',
        content: (mvr) => <MaskedSSN ssn={mvr?.ssnCode || 'N/A'} />,
      },
      { path: 'contactNumber', label: ' Contact Number ', type: 'phone' },
      {
        path: 'analysis',
        label: ' Analysis ',
        content: (mvr) =>
          isSuperAdmin ? (
            <AnalysisSuperAdmin
              mvr={mvr}
              onSelect={(analysis) => {
                if (analysis !== 'PENDING' && tab === 'mvr_submission') {
                  setNotesModal({
                    state: 'add',
                    info: { analysis, id: mvr?._id, notes: mvr?.notes },
                  })
                }
              }}
            />
          ) : (
            <AdminAnalysis mvr={mvr} />
          ),
      },
      {
        path: 'notes',
        label: ' Notes ',
        content: (mvr) => {
          const { _id = '', notes = '', analysis } = mvr
          return notes.length ? (
            <a
              className="text-decoration-none attachmentview attachfiles"
              onClick={() => {
                if (isSuperAdmin) {
                  setNotesModal({
                    state: 'edit',
                    info: { notes, analysis, id: _id },
                  })
                } else {
                  setNotesModal({ state: 'view', info: { notes } })
                }
              }}
            >
              {`View Notes`}
            </a>
          ) : (
            'N/A'
          )
        },
      },
      {
        path: 'attachments',
        label: ' Attachments ',
        content: (mvr) => {
          const { attachments } = mvr
          let attachmentsData = []
          if (attachments.length) {
            getAttachmentList('mvr', attachments).then((response) => {
              attachmentsData = response.data.data
            })
          }
          return attachments.length ? (
            <a
              className="text-decoration-none attachmentview attachfiles"
              onClick={() =>
                attachmentsData.forEach((attachment) => {
                  window.open(attachment.url, '_blank')
                })
              }
            >
              {`View ${attachments.length} Files`}
            </a>
          ) : (
            'N/A'
          )
        },
      },
    ]

    if (isSuperAdmin) {
      col.splice(col.length - 2, 0, {
        path: 'organisation',
        label: ' Organization ',
      })
    }

    if (
      tab === 'approved' ||
      tab === 'not_approved' ||
      tab === 'all_approvals'
    ) {
      const colPosition =
        tab === 'all_approvals' ? col.length - 2 : col.length - 3
      col.splice(colPosition, 0, {
        path: 'status',
        label: ' Status ',
        content: (mvr) => {
          return (
            <div
              className={classNames({
                'apr-danger': mvr.approvalStatus === mvrStatus['NOT_APPROVED'],
                'apr-success': mvr.approvalStatus === mvrStatus['APPROVED'],
                'apr-info': mvr.approvalStatus === mvrStatus['NOT_SELECTED'],
              })}
            >
              {mvr.approvalStatus}
            </div>
          )
        },
      })
    }
    return col
  }, [tab])

  const categories = columns
    .filter((c) => c.type !== 'date')
    .map((col) => col.label)

  useEffect(() => {
    mountedRef.current = true
    setIsLoading(true)
    fetchList()
    return () => {
      mountedRef.current = false
    }
  }, [selectedClientId])

  useEffect(() => {
    if (openBulkDel) {
      setDelMvr({ delMvr: { firstName: 'mvrf', lastName: 'mvrL' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  const [selectedCategories, setSelectedCategories] = useState([])
  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }
  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }
  const fetchList = async () => {
    const { data } = await getMvrs()
    if (!mountedRef.current) return null

    if (data.data && Array.isArray(data.data)) {
      // Update Filter Values for policy.

      if (sideNavDataContext !== 'searchView') {
        dispatch(
          handleSideNavDataUpdated({ data: data.data[0], module: 'mvr' }),
        )
        dispatch(handleTabIdUpdated('mvr'))
      }
      let mvrData = data.data.map((mvr)=> {
        mvr.fullName = `${mvr.firstName} ${mvr.lastName}`
        return mvr
      })
      setData(mvrData)
    }
    setIsLoading(false)
  }

  const handleAnalysisChange = async (mvr, value) => {
    mvr.notes = value
    await updateMvr(mvr.id, mvr)
    setIsLoading(true)
    fetchList()
  }
  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    mvrs = result
    return result.length > 0
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }
  const handlePageChange = (page) => {
    setCurrPage(page)
  }
  const handleSearch = (query) => {
    setSearchQuery(query)
  }
  const exportToCSV = async () => {
    const { data: policyData } = await exportMvrs()
    downloadFile(policyData, 'Mvr.csv')
  }
  const importFromCSV = async (e) => {
    // set
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (mvrs.length !== checkedRecords.length) {
        mvrs.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }
  useEffect(() => {
    const firstTab = getTabsData()[0]
    dispatch(handleTabIdUpdated(firstTab.id))
  }, [])

  const getTabsData = () =>
    isSuperAdmin
      ? [
          {
            module: 'MVR',
            id: 'mvr_submission',
            label: 'MVR Submission',
          },
          {
            module: 'MVR',
            id: 'approved',
            label: 'Approved',
          },
          {
            module: 'MVR',
            id: 'not_approved',
            label: 'Not Approved',
            style: { marginRight: '48px' },
          }
        ]
      : [
          {
            module: 'MVR',
            id: 'recommendation',
            label: 'Recommendation',
          },
          {
            module: 'MVR',
            id: 'all_approvals',
            label: 'All Approvals',
            style: { marginRight: '48px' },
          },
        ]

  const filters = {
    analysis: {
      name: 'analysis',
      label: 'Select Analysis',
      key: 'analysis',
      options: [
        {
          value: 'Recommended to hire',
          key: 'Recommended to hire',
        },
        {
          value: 'Recommended not to hire',
          key: 'Recommended not to hire',
        },
        {
          value: 'Pending',
          key: 'Pending',
        },
      ],
      selected: '',
    },
    notes: {
      name: 'notes',
      label: 'Select Notes',
      key: 'notes',
      options: [
        {
          value: 'Have notes',
          key: 'Have notes',
        },
        {
          value: 'Does not have notes',
          key: 'Does not have notes',
        },
      ],
      selected: '',
    },
  }

  const allApprovalFilters = {
    analysis: {
      name: 'analysis',
      label: 'Select Analysis',
      key: 'analysis',
      options: [
        {
          value: 'Recommended to hire',
          key: 'Recommended to hire',
        },
        {
          value: 'Recommended not to hire',
          key: 'Recommended not to hire',
        },
        {
          value: 'Pending',
          key: 'Pending',
        },
      ],
      selected: '',
    },
    notes: {
      name: 'notes',
      label: 'Select Notes',
      key: 'notes',
      options: [
        {
          value: 'Have notes',
          key: 'Have notes',
        },
        {
          value: 'Does not have notes',
          key: 'Does not have notes',
        },
      ],
      selected: '',
    },
    status: {
      name: 'status',
      label: 'Select Status',
      key: 'status',
      options: [
        {
          value: 'Not selected',
          key: 'Not selected',
        },
        {
          value: 'Approved',
          key: 'Approved',
        },
        {
          value: 'Not approved',
          key: 'Not approved',
        },
      ],
      selected: '',
    },
  }

  const conditionalIntitialFilter =
    tab === 'all_approvals' ? allApprovalFilters : filters
  const [mvrFilters, setMvrFilters] = useState(conditionalIntitialFilter)

  const onClear = () => {
    if (tab === 'all_approvals') {
      setMvrFilters((mvr) => ({
        analysis: { ...mvr['analysis'], selected: '' },
        notes: { ...mvr['notes'], selected: '' },
        status: { ...mvr['status'], selected: '' },
      }))
    } else {
      setMvrFilters((mvr) => ({
        analysis: { ...mvr['analysis'], selected: '' },
        notes: { ...mvr['notes'], selected: '' },
      }))
    }
  }

  useEffect(() => {
    const conditionalIntitialFilter =
      tab === 'all_approvals' ? allApprovalFilters : filters
    setMvrFilters(conditionalIntitialFilter)
  }, [tab])

  const selectedFilterCount = useMemo(() => {
    let count = 0
    Object.values(mvrFilters).forEach(({ selected }) => {
      if (selected.length) {
        count += 1
      }
    })
    return count
  }, [mvrFilters])

  const getFiltersData = () => {
    return {
      filter: {
        dropdownView: (
          <FilterDropdown
            selectFilters={Object.values(mvrFilters)}
            setSelectedFilter={setMvrFilters}
            selectedFilterCount={selectedFilterCount}
            onClear={onClear}
            showDateRange={false}
          />
        ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const handleTabChange = (id) => {
    setTab(id)
    dispatch(handleTabIdUpdated(id))
    dispatch(handleSideNavDataUpdated({}, ''))
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukMvr(params)
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setShowDeleteButton(false)
        setOpenBulkDel(false)
      } else {
        const { data } = await deleteMvr(delMvr._id, delMvr)

        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setDeleteOne(false)
      }
    } catch (error) {
      console.log('error', error)
    }
    setDelMvr({})
    await fetchList()
  }

  const getMVRData = (mvrs) => {
    let filteredMvrs = mvrs
    if (isSuperAdmin) {
      if (tab === 'approved') {
        filteredMvrs = mvrs.filter(
          ({ approvalStatus }) => approvalStatus === 'Approved',
        )
      } else if (tab === 'not_approved') {
        filteredMvrs = mvrs.filter(
          ({ approvalStatus }) => approvalStatus === 'Not approved',
        )
      }
    }
    //setup the filterdata
    return filteredMvrs
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  if(isLoading) return( <div style={{marginTop: "300px"}} ><Loader loading={isLoading}/></div>)
  
  return (
    <div className={'position-rel mvr-list'} style={{ marginBottom: '15px' }}>
      <ListPageHeader
        showTabs={true}
        // modules={
        //   isSuperAdmin
        //     ? ['MVR SUBMISSION', 'APPROVED', 'NOT APPROVED', 'MVR']
        //     : ['RECOMMENDATION', 'ALL APPROVALS', 'MVR']
        // }
        modules={['MVR']}
        handleExportToCsv={exportToCSV}
        showClientSelect={false}
        searchQuery={searchQuery}
        onSearchChange={handleSearch}
        handleImportfromCsv={() => {
          importFromCSVButtonClicked()
        }}
        handleBulkCheckClick={() => {
          setOpenBulkDel(true)
        }}
        selectedTabId={tab}
        setTab={handleTabChange}
        name="MVR"
        to="/mvr/add"
        heading={'MVR Hub'}
        hideAdd
        showGenerateLink={true}
        showFilters={true}
        tabsData={getTabsData()}
        filtersData={getFiltersData()}
        categories={categories}
        hideDeleteButton={!showDeleteButton}
        selectedCategories={selectedCategories}
        removeCategory={removeCategory}
        addCategory={addCategory}
        filterCount={selectedFilterCount}
      />
      {getPageData() || ''}
      <MvrTable
        columns={columns}
        data={getMVRData(mvrs)}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked}
        selectFilters={Object.values(mvrFilters)
          .filter(({ selected }) => selected.length)
          .reduce((prev, { key, selected }) => {
            prev[key] = selected
            return prev
          }, {})}
        setSelectFilter={(analysisList) => {
          const pFilter = { ...mvrFilters }
          pFilter.analysis.options = analysisList
          setMvrFilters(pFilter)
        }}
      />

      <MVRNotes
        info={notesModal?.info || {}}
        isNotesModal={notesModal.state === 'add' || notesModal.state === 'edit'}
        onSubmit={(notes) => {
          handleAnalysisChange(notesModal?.info, notes)
          setNotesModal({ state: 'close', info: {} })
        }}
        onClose={() => setNotesModal({ state: 'close', info: {} })}
      />
      <ViewNotes
        heading="Analysis"
        info={notesModal?.info?.notes || ''}
        isNotesModal={notesModal.state === 'view'}
        onClose={() => setNotesModal({ state: 'close', info: {} })}
      />
      <ConfirmationModal
        name={
          checkedRecords.length > 0
            ? `MVRs`
            : (delMvr?.firstName || '') + ' ' + (delMvr?.lastName || '')
        }
        // isOpen={checkedRecords.length > 0 || deleteOne}
        isOpen={
          Object.keys(delMvr || {}).length > 0 &&
          (checkedRecords.length > 0 || deleteOne)
        }
        onClose={() => {
          setShowDeleteButton(false)
          setOpenBulkDel(false)
          setDeleteOne(false)
          setCheckedRecords([])
          setDelMvr({})
        }}
        onConfirm={handleDeleteModal}
      />
      {/* <button onClick={importFromCSVButtonClicked}>import</button> */}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSVButtonClicked}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}

const AnalysisSuperAdmin = ({ mvr, onSelect }) => (
  <select
    className={classNames({
      mvr_hub_no_hire: mvr.analysis === 'Recommended not to hire',
      mvr_hub_hire: mvr.analysis === 'Recommended to hire',
      mvr_hub_pen: mvr.analysis === 'Pending',
    })}
    value={mvr.analysis || 'Pending'}
    onChange={(event) => {
      const selectedAnalysis = event.target.value || ''
      onSelect(selectedAnalysis)
    }}
  >
    <option value="Pending">Pending</option>
    <option value="Recommended to hire">Recommended to hire</option>
    <option value="Recommended not to hire">Recommended not to hire</option>
  </select>
)

const AdminAnalysis = ({ mvr }) => {
  return (
    <span
      className={classNames({
        'apr-danger-text': mvr.analysis === 'Recommended not to hire',
        'apr-success-text': mvr.analysis === 'Recommended to hire',
      })}
    >
      {mvr?.analysis || ''}
    </span>
  )
}

const MvrTable = (props) => {
  var renderData = props.data.filter(({ analysis, notes, approvalStatus }) => {
    const {
      analysis: selectedAnalysis = '',
      notes: selectedNotes = '',
      status: selectedStatus = '',
    } = props.selectFilters
    let isSelectAnalysis = false,
      isSelectNotes = false,
      isSelectStatus = false
    if (selectedAnalysis.length && selectedAnalysis === analysis) {
      isSelectAnalysis = true
    }
    let ckNote =
      notes == '' || notes == undefined ? 'Does not have notes' : 'Have notes'    

    if (selectedNotes.length && selectedNotes === ckNote) {
      isSelectNotes = true
    }
    

    if (
      selectedStatus &&
      selectedStatus.length &&
      selectedStatus === approvalStatus
    ) {
      isSelectStatus = true
    }

    return (
      !(!!selectedAnalysis.length ^ isSelectAnalysis) &&
      !(!!selectedNotes.length ^ isSelectNotes) &&
      !(!!selectedStatus.length ^ isSelectStatus)
    )
  })


  return (
    <>
      <Table
        columns={props.columns}
        data={renderData}
        sortColumn={props.sortColumn}
        onSort={props.onSort}
        checkedItems={props.checkedItems}
        checkboxClick={props.checkboxClick}
      />
    </>
  )
}

export default MvrList
