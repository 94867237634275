import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
} from '../../actions'
import apiUrl from '../../configs/api'
import { timeNowFormat } from '../../configs/utils'
import { getClaim } from '../../services/claimService'
import { getClient } from '../../services/clientService'
import { getEmployee } from '../../services/employeeService'
import { getIncident } from '../../services/incidentService'
import { getPolicy } from '../../services/policyService'
import { getProperty } from '../../services/propertyService'
import { getUser } from '../../services/userService'
import { getVehicle } from '../../services/vehicleService'

const History = () => {
  const dispatch = useDispatch()
  const History = useHistory()
  const [history, setHistory] = useState([])

  useEffect(() => {
    fetch(`${apiUrl}/history/list`, {
      headers: { authorization: `${sessionStorage.getItem('token')}` },
    })
      .then((res) => res.json())
      .then((data) => setHistory(data.data))
  }, [])

  const handleView = async (item) => {
    if (
      item.action.toLowerCase().trim() === 'changed' ||
      item.action.toLowerCase().trim() === 'updated'
    ) {
      if (
        item.module.toLowerCase().trim() !== 'mvr' &&
        item.module.toLowerCase().trim() !== 'third-party'
      ) {
        await fetchViewData(item.eventId, item.module)
      }
    }
  }

  const fetchViewData = async (id, type) => {
    switch (type) {
      case 'employee': {
        const { data: employee } = await getEmployee(id)
        dispatch(handleTabIdUpdated('employee'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: employee.data,
              module: 'employee',
            },
            'searchView',
          ),
        )
        History.push('/assetsManagement')
        break
      }
      case 'property': {
        const { data: property } = await getProperty(id)
        dispatch(handleTabIdUpdated('property'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: property.data,
              module: 'property',
            },
            'searchView',
          ),
        )
        History.push('/assetsManagement')

        break
      }
      case 'vehicle': {
        const { data: vehicle } = await getVehicle(id)
        dispatch(handleTabIdUpdated('vehicle'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: vehicle.data,
              module: 'vehicle',
            },
            'searchView',
          ),
        )

        History.push('/assetsManagement')

        break
      }
      case 'client': {
        const { data: client } = await getClient(id)
        dispatch(handleTabIdUpdated('client'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: client.data,
              module: 'client',
            },
            'searchView',
          ),
        )

        History.push('/userManagement')

        break
      }
      case 'user': {
        const { data: user } = await getUser(id)
        dispatch(handleTabIdUpdated('user'))
        dispatch(
          handleSideNavDataUpdated(
            { data: user.data, module: 'user' },
            'searchView',
          ),
        )

        History.push('/userManagement')

        break
      }
      case 'policy': {
        const { data: policy } = await getPolicy(id)
        dispatch(handleTabIdUpdated('policy'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: policy.data,
              module: 'policy',
            },
            'searchView',
          ),
        )

        History.push('/policies')

        break
      }
      // case 'third-party': {
      //   const { data: thirdParty } = await getThirdParty(id)
      //   dispatch(handleTabIdUpdated('thirdParty'))
      //   dispatch(
      //     handleSideNavDataUpdated(
      //       {
      //         data: thirdParty.data,
      //         module: 'thirdParty',
      //       },
      //       'searchView',
      //     ),
      //   )
      //   if (selectedTabId !== 'thirdParty') {
      //     History.push('/thirdParty')
      //   }
      //   break
      // }
      case 'claim': {
        const { data: claims } = await getClaim(id)
        dispatch(handleTabIdUpdated('claim'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: claims.data,
              module: 'claim',
            },
            'searchView',
          ),
        )

        History.push('/incidents')

        break
      }
      case 'incident': {
        const { data: incidents } = await getIncident(id)
        dispatch(handleTabIdUpdated('incident'))
        dispatch(
          handleSideNavDataUpdated(
            {
              data: incidents.data,
              module: 'incident',
            },
            'searchView',
          ),
        )

        History.push('/incidents')

        break
      }
      // case 'mvr': {
      //   const { data: mvr } = await getMvr(id)
      //   dispatch(handleTabIdUpdated('mvr'))
      //   dispatch(
      //     handleSideNavDataUpdated(
      //       {
      //         data: mvr.data,
      //         module: 'mvr',
      //       },
      //       'searchView',
      //     ),
      //   )
      //   if (selectedTabId !== 'mvr') {
      //     History.push('/mvr')
      //   }
      //   break
      // }
      default:
    }
    dispatch(handleSideNavElementClicked('info'))
    onclose()
  }

  return (
    <>
      <div className="position-rel" style={{ paddingLeft: '13rem' }}>
        <div className="sticki-top">
          <div className="pl-6 pt-4 user-mang">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-md-12">
                  <nav className="mt-4">
                    <div className="nav nav-tabs nav-fill m-0" role="tablist">
                      <div className=" nav-link text-left hvr-pulse1 mb-2 inherit p-0">
                        <h2 className="text-color-2">History</h2>
                        <p className="mb-0 text-color">
                          List of all the history events
                        </p>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pl-6 user-mang assetmanagset my-4 ">
          <div className="container-fluid">
            <div className="pb-5 overflow-auto">
              {history && history.length > 0 ? (
                <div className="pr-2 pl-2 pb-4 overflow-auto">
                  <div>
                    {history?.map((data, index) => (
                      <div
                        className="my-3"
                        style={{ cursor: 'pointer' }}
                        key={index}
                        onClick={() => {
                          handleView(data)
                        }}
                      >
                        <div className="d-flex justify-content-between comment-section">
                          <div className="img-box active mb-1 border-bottom">
                            <div className="pro-box d-flex mr-3 ml-3">
                              {`${data?.user?.firstName.charAt(
                                0,
                              )}${data?.user?.lastName?.charAt(0)}`}{' '}
                            </div>
                            <div className="r-box">
                              <p>
                                <strong>
                                  {data?.user?.firstName} {data?.user?.lastName}
                                </strong>{' '}
                                {data?.action}{' '}
                                <strong>
                                  {data.module} {data.tag}
                                </strong>{' '}
                                {data?.fieldChanged.length > 0 && (
                                  <span>
                                    {' '}
                                    fields{' '}
                                    {data?.fieldChanged.map((field, index) => {
                                      return (
                                        <strong key={index}>{field} </strong>
                                      )
                                    })}
                                  </span>
                                )}
                              </p>
                              <p>{timeNowFormat(data.created)}</p>
                            </div>
                            {/* <div className="comment-escl">
                        <a
                          href="#"
                          className="dropdown btn-sm p-0 mr-3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fas fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-left user-dd animated">
                          <ul className="navi navi-hover">
                            <li className="navi-item">
                              <a
                                href="incident.html"
                                className="navi-link"
                                onClick={() => {}}
                              >
                                <span className="navi-text">
                                  <span className="label label-xl label-inline  label-light-danger">
                                    <i
                                      className="far fa-edit"
                                      aria-hidden="true"
                                    ></i>
                                    Edit
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                href="#"
                                className="navi-link"
                                onClick={() => {}}
                              >
                                <span className="navi-text">
                                  <span className="label label-xl label-inline label-light-danger">
                                    <i
                                      className="far fa-trash-alt"
                                      aria-hidden="true"
                                    ></i>
                                    Delete
                                  </span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <span className="bookmark mr-2">
                          <i
                            className="fas fa-thumbtack"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={'pr-2 pl-2 pb-4'}>No History Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default History
