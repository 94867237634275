import { ErrorMessage, Field, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import SignaturePad from '../../components/common/SignaturePad'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import TextArea from '../../components/form/TextArea'
import TextInput from '../../components/form/TextInput'
import PublicIncidentAcknowledgement from '../../components/Incidents/PublicIncidentAcknowledgement'
import { updateManagerForm } from '../../services/publicIncidentService'
import { PUBLIC_INCIDENT_MANAGER_FORM_SCHEMA } from '../../utils/validation'
import { loadoutShift } from '../incident/utils'
import AddAttachmentMVR from '../../components/AddAttachmentMvr'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'

const initialData = {
  clientId: '',
  dateOfIncident: '',
  employeeName: '',
  loadOutType: '',
  dateOfReported: '',
  returnDate: '',
  vehicleLocatedAddress: '',
  supervisorName: '',
  supervisorEmail: '',
  supervisorContactNumber: '',
  isAgree: '',
  isVehicleNeedRepair: '',
  isHospitalized: '',
  incidentDescription: '',
  managerSignature: '',
  hospitalizedDescription: '',
}

export default function ManagerForm({ setActiveStep, publicIncidentData }) {
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [signImg, setSignImg] = useState('')
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [files, setFiles] = useState([])

  const handleSubmit = async (values) => {
    let managerFormPayload = {
      ...publicIncidentData,
      managerFormDetails: {
        tz: moment.tz.guess(),
        ...values,
      },
    }
    values?.dateOfIncident &&
      (managerFormPayload.managerFormDetails.dateOfIncident = moment(values?.dateOfIncident).format(NO_TIMEZONE_DATE_FORMAT))
    values?.dateOfReported &&
      (managerFormPayload.managerFormDetails.dateOfReported = moment(values?.dateOfReported).format(NO_TIMEZONE_DATE_FORMAT))
    values?.returnDate &&
      (managerFormPayload.managerFormDetails.returnDate = moment(values?.returnDate).format(NO_TIMEZONE_DATE_FORMAT))
    
    await updateManagerForm(managerFormPayload._id, managerFormPayload)
    toast.success('Manager form is updated successfully...')
    history.push('/add-new-incident')
  }

  return (
    <>
      <section className="public-form-wrapper">
        <div className="container-fluid modal2">
          <div className="row">
            <div className="col-md-12">
              <div className="my-5 py-5">
                <div
                  className="cab-form customized-scrollbar"
                  style={{
                    overflowY: 'auto',
                    height: '78vh',
                    overflowX: 'hidden',
                  }}
                >
                  <div className="text-center mb-2">
                    <h1>Manager&apos;s Form</h1>
                  </div>
                  <div className="border-bottom pb-3"></div>
                  <div className="mt-4 mb-3">
                    <div className="text-center">
                      <p className="mb-1 font-weight-bold lh-3">
                        SAFETY INCIDENT NOTIFICATION REPORT
                      </p>
                      <p className="mb-1">
                        Please make sure all accidents and incidents are reported within 24-48 hours for compliance purposes.
                      </p>
                      <p className="mb-1">
                        Manager/Supervisor/Owner should call in to Triage to Initiate Process, if necessary.
                      </p>
                      <p className="mb-1">
                        Employee will then speak to Triage directly, please provide phone number: 877-422-5312
                      </p>
                      <p className="mb-0">
                        For any additional questions, please contact <a className='text-decoration-none text-dark' href='mailto:safety@acuityriskconsultants.com'>safety@acuityriskconsultants.com</a>
                      </p>
                    </div>
                  </div>
                  <div className="border-bottom h6 pb-2 mb-4">
                    <span className="text-primery font-weight-bold fs-11">
                      TO BE COMPLETED BY REPORTING DISPATCHER, HUB LEADER,
                      SUPERVISOR OR MANAGER :
                    </span>
                  </div>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      ...initialData,
                      ...publicIncidentData.managerFormDetails,
                    }}
                    validationSchema={PUBLIC_INCIDENT_MANAGER_FORM_SCHEMA}
                    onSubmit={(values) => {
                      handleSubmit(values)
                    }}
                  >
                    {({ values, setFieldValue, setFieldTouched, handleBlur, errors, touched }) => (
                      <Form>
                        <div className="form-row">
                          <div className="col-md-12">
                            {/* <Select
                            label="Select Client"
                            name="clientId"
                            defaultLabel="Select Client"
                            options={getClientDropdownOpt()}
                            value={values?.clientId || ''}
                          /> */}
                            <TextInput
                              withAsterisk={true}
                              label="Company Name"
                              name="clientId"
                              placeholder="Company Name"
                              value={values?.clientId || ''}
                            />
                          </div>

                          <div className="form-group col-md-6">
                            <TextInput
                              withAsterisk={true}
                              name={`employeeName`}
                              label="Employee Involved In Incident"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <DatePickerField
                              withAsterisk={true}
                              name="dateOfIncident"
                              label="Date of Incident"
                              autoComplete="off"
                              onBlur={handleBlur}
                              maxDate={new Date()}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <Select
                              withAsterisk={true}
                              label="Loadout Wave/Shift"
                              name="loadOutType"
                              defaultLabel="Select Type"
                              options={loadoutShift}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <DatePickerField
                              name="dateOfReported"
                              label="Date Incident Reported to Supervisor"
                              autoComplete="off"
                              onBlur={handleBlur}
                              maxDate={new Date()}
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <DatePickerField
                              name="returnDate"
                              label="Return to Work Date"
                              onBlur={handleBlur}
                            />
                          </div>
                          <div className="form-group col-md-6"></div>
                          <div className="form-group col-md-6">
                            <label className="d-block">
                              Was the employee hospitalized overnight? <span className='text-danger'> *</span>
                            </label>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="isHospitalized"
                                value="yes"
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="isHospitalized"
                                value="no"
                              />
                              <label className="form-check-label">No</label>
                            </div>
                            {errors.isHospitalized && <div className='text-danger'>Please select an option</div>}
                          </div>
                          {values?.isHospitalized === 'yes' && (
                            <div className="form-group mt-3 col-md-12">
                              <TextArea
                                withAsterisk={true}
                                name="hospitalizedDescription"
                                label="Please mention the medical provider name and any other necessary details"
                              />
                            </div>
                          )}
                          <div className="form-group col-md-6">
                            <label className="d-block">
                              Does Your Vehicle Need Repairs? <span className='text-danger'> *</span>
                            </label>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name={`isVehicleNeedRepair`}
                                value={'true'}
                                id="isVehicleNeedRepairYes"
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name={`isVehicleNeedRepair`}
                                value={'false'}
                                id="isVehicleNeedRepairNo"
                              />
                              <label className="form-check-label">No</label>
                            </div>
                            {errors.isVehicleNeedRepair && <div className='text-danger'>Please select an option</div>}
                          </div>
                          {values?.isVehicleNeedRepair === 'true' && (
                            <div className="form-group mt-3 col-md-12">
                              <TextArea
                                withAsterisk={true}
                                cols={30}
                                rows={5}
                                name="vehicleLocatedAddress"
                                label="Address Where Vehicle is Located"
                                placeholder="Describe, in detail..."
                              />
                            </div>
                          )}

                          <div className="form-group col-md-12">
                            <TextArea
                              withAsterisk={true}
                              cols={30}
                              rows={5}
                              name="incidentDescription"
                              label="Describe, in detail, the incident or condition based on information you directly observed"
                              placeholder="Describe, in detail, the incident or condition based on information you directly observed and/or were
                            provided with by the reporting employee. For information you were provided, indicate the source."
                            />
                          </div>

                          <div className="form-group col-md-6 ">
                            <TextInput
                              withAsterisk={true}
                              name={`supervisorName`}
                              label="Supervisor's Name"
                            />
                          </div>
                          <div className="form-group col-md-6 ">
                            <TextInput
                              withAsterisk={true}
                              name={`supervisorEmail`}
                              label="Supervisor's Email"
                              type={"email"}
                            />
                          </div>
                          <div className="form-group col-md-6 ">
                            <label>Today&apos;s Date</label>
                            <input
                              className="form-control"
                              type="text"
                              value={moment().format('MM/DD/YYYY')}
                              readOnly
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label>Contact Number <span className="text-danger"> *</span></label>
                            <PhoneInput
                              // withAsterisk={true}
                              // displayInitialValueAsLocalNumber={true}
                              value={
                                values?.supervisorContactNumber || ''
                              }
                              name="supervisorContactNumber"
                              defaultCountry="US"
                              international={false}
                              className={'form-control'}
                              placeholder="Contact Number"
                              maxLength={14}
                              onChange={(phone) => {
                                if (phone) {
                                  setFieldValue(
                                    'supervisorContactNumber',
                                    phone || '',
                                  )
                                } else {
                                  setFieldValue(
                                    'supervisorContactNumber',
                                    '',
                                  )
                                }
                              }}
                              onBlur={() => {
                                if (values?.supervisorContactNumber === '') {
                                  setFieldTouched('supervisorContactNumber', true)
                                }
                              }}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="supervisorContactNumber"
                              component="div"
                            />
                          </div>
                          {/* <div className="form-group col-md-6">
                            <TextInput
                              isPhone
                              label="Contact Number"
                              name="supervisorContactNumber"
                              onPhoneChange={(phone) => {
                                setFieldValue(
                                  'supervisorContactNumber',
                                  phone || '',
                                )
                              }}
                              value={values?.supervisorContactNumber || ''}
                            />
                          </div> */}
                          <div className='form-group col-md-12'>
                            <div className="row mt-3">
                              <div className="form-group col-md-6 addIncidentUploadFile">
                                <label
                                  className="d-block text-primery font-weight-bold"
                                  htmlFor="incidentUpload"
                                >
                                  Attachments {values.vehicleNature === "vehicular" && (<span className='text-danger'>*</span>)}
                                </label>
                                <div className="row m-0"></div>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary side-btn mr-3"
                                  data-toggle="modal"
                                  data-target="#attachuplaods"
                                  onClick={() => setOpenAttachmentModal(true)}
                                >
                                  <i
                                    className="fas fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  <span className="file-up">Upload File</span>
                                </button>
                                {openAttachmentModal && (
                                  <AddAttachmentMVR
                                    totalAttachments={attachments.length}
                                    module="public-incident"
                                    uid={publicIncidentData?.publicIncidentId}
                                    onClose={() => setOpenAttachmentModal(false)}
                                    onAdd={(atts, fileObj) => {
                                      const newAttachments = [
                                        ...attachments,
                                        ...atts,
                                      ]
                                      setAttachments(newAttachments)
                                      const newfiles = [...files, ...fileObj]
                                      setFiles(newfiles)
                                      setFieldValue(
                                        'attachments',
                                        newAttachments.map((c) => c._id),
                                      )
                                      setOpenAttachmentModal(false)
                                    }}
                                    isOpen={openAttachmentModal}
                                  />
                                )}
                              </div>
                              <div className="form-group col-md-6"></div>

                              <ErrorMessage
                                className="text-danger mt-3 ml-3"
                                name={`attachments`}
                                component="div"
                              />
                              <div className="form-group col-md-12 mt-5">
                                {attachments.map((att, i) => (
                                  <div key={i} className="d-flex w-100">
                                    <p style={{ flexGrow: 1 }}>{att.fileName}</p>
                                    <i
                                      onClick={() => {
                                        setAttachments(
                                          attachments.filter(
                                            (c) => att.fileName !== c.fileName,
                                          ),
                                        )
                                        setFieldValue(
                                          'attachments',
                                          attachments.filter(
                                            (c) => att.fileName !== c.fileName,
                                          ),
                                        )
                                      }}
                                      style={{ cursor: 'pointer' }}
                                      className="fa fa-trash"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="form-group col-md-12 addIncidentUploadFile">
                              <label
                                className="d-block"
                                htmlFor="incidentUpload"
                              >
                                Signature <span className='text-danger'> *</span>
                              </label>
                              <div className="row m-0"></div>
                              <button
                                type="button"
                                className="btn btn-outline-secondary side-btn mr-3 upload-sign w-100"
                                onClick={() => {
                                  setDialogOpen(true)
                                }}
                              >
                                <i
                                  className="fas fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                                <span>Upload Signature</span>
                              </button>
                            </div>
                            <ErrorMessage
                              className="text-danger mt-3 ml-3"
                              name={`managerSignature`}
                              component="div"
                            />
                            {signImg && (
                              <div className="form-group col-md-12 mt-5">
                                <div className="show-image d-flex align-items-center">
                                  <img src={signImg} />
                                  <i
                                    onClick={() => {
                                      setSignImg('')
                                    }}
                                    style={{ cursor: 'pointer' }}
                                    className="fa fa-trash"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <PublicIncidentAcknowledgement />
                          <div className="d-flex justify-content-between align-items-center w-100 pb-4">
                            <a href="#" onClick={() => setActiveStep(1)}>
                              <i
                                className="fa fa-long-arrow-left mr-1"
                                aria-hidden="true"
                              ></i>
                              Back to Incident Reported
                            </a>
                            <div className='d-flex flex-column align-items-end'>
                              <button
                                type="submit"
                                disabled={!values.isAgree}
                                className="btn btn-primary px-4 border-radius-12 "
                              >
                                Submit
                              </button>
                              {
                                Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (<div className='d-flex justify-content-end'>
                                  <p className='text-danger mt-2'>Please fill all required fields above*</p>
                                </div>)
                              }
                            </div>
                          </div>
                        </div>
                        {dialogOpen && (
                          <SignaturePad
                            setDialogOpen={setDialogOpen}
                            setSignImg={setSignImg}
                            signImg={signImg}
                            uid={publicIncidentData?.publicIncidentId}
                            addSignature={(id) => {
                              setFieldValue('managerSignature', id)
                            }}
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
