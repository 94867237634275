import moment from 'moment'
import React from 'react'
import { addCommaTableField } from '../incident/utils'

function ClaimFinancialCard({ claim, onEdit, onDelete, id }) {
  let date = '-'
  if (claim?.transactionDate) {
    date =  claim.transactionDate ? moment(claim.transactionDate).format('MM/DD/YYYY') : 'N/A'
  }

  return (
    <div className="parties bg-white p-3 mb-3" data-testid={`claim-financial-${id ?? "na"}`}>
      <div className="d-flex justify-content-between">
        <h6 className="text-primery weekly">$ {claim?.amount ? addCommaTableField(claim?.amount) : ''}</h6>
        <div className="">
          <button
            data-testid="edit-claim-financial-btn"
            type="button"
            className="btn btn-outline-secondary side-btn mr-1"
            onClick={onEdit}
          >
            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          <button
            data-testid="delete-claim-financial-btn"
            type="button"
            className="btn btn-outline-secondary side-btn"
            onClick={onDelete}
          >
            <i className="far fa-trash-alt" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <div className="row mt-3">
        <div className="weekly col-md-4">
          <small>As of Date:</small>
          {date}
        </div>

        <div className="weekly col-md-8">
          <small>Transaction Id:</small>
          {claim?.transactionId}
        </div>
      </div>
    </div>
  )
}
export default ClaimFinancialCard
