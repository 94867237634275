import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import Layout from '../components/Layout'
// import { useShowModule } from '../hooks/useShowModule'

const renderComponent = (layout, Component, props) => {
  return layout ? (
    <Layout component={Component} props={props} />
  ) : (
    <Component {...props} />
  )
}
function ProtectedRoute({
  path,

  Component,

  layout = true,
  protect = true,
}) {
  const { token } = useSelector((state) => state)
  const { pathname } = useLocation();

  useEffect(()=>{
    // window.Intercom("update");
  },[pathname])
  // const {showModule} = useShowModule();
  return (
    <Route
      path={path}
      render={(props) => {
        if (protect) {
          if (token) {
            return renderComponent(layout, Component, props)
            // else return <Redirect to="/" />
          } else {
            return <Redirect to="/login" />
          }
        } else {
          return renderComponent(layout, Component, props)
        }
      }}
    />
  )
}

export default ProtectedRoute
