import { onMessage } from 'firebase/messaging'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import ActionTypes from '../actionTypes'
import { messaging } from '../App'
import { getNotifications } from '../services/notificationService'

const Firebase = () => {
  const { firebase, currentUser } = useSelector((state) => state)
  const dispatch = useDispatch()
  useEffect(() => {
    let unsub
    if (firebase) {
      //   console.log('firebase', firebase)
      (async () => {
        const notificationList = await getNotifications()
        dispatch({
          type: ActionTypes.SET_NOTIFICATIONS,
          payload: notificationList.data.data,
        })
      })()
      unsub = onMessage(messaging, (payload) => {
        const data = {
          notification: JSON.parse(payload.data.notificationData),
          userId: currentUser.id,
          isRead: false,
        }

        dispatch({
          type: ActionTypes.PUSH_NOTIFICATION,
          payload: data,
        })
      })
    } else {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: [],
      })
    }
    return () => {
      unsub?.()
    }
  }, [firebase])

  //   useEffect(()=>{

  //   }[])

  return <></>
}

export default Firebase
