import { ErrorMessage, Field, useFormikContext } from 'formik'
import moment from 'moment'
import React, { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import Select1 from 'react-select'
import { SedanCarParts } from '../../components/Incidents/SedanCarParts'
import { VehiclePart } from '../../components/Incidents/VehiclePart'
import TextInput from '../../components/form/TextInput'
import { vehicleTypeOptions } from '../incident/utils'

function VehicleDetailsForm() {
  const { values, setFieldValue } = useFormikContext()

  const handleDeleteVehicleDetail = (index) => {
    const updatedVehicleDetails = [...values?.vehiclesInvolved]
    updatedVehicleDetails.splice(index, 1)
    setFieldValue('vehiclesInvolved', updatedVehicleDetails)
  }

  return (
    <>
      {values?.vehiclesInvolved?.map((vehicle, index) => (
        <div key={index} className="d-flex align-items-baseline posi-rel">
          <div id="main1" className="vehicle-accordion">
            <div>
              <div className="accordion" id="faq">
                <div className="card vehicleDetail-accordion">
                  <div className="card-header1" id={`faqhead${index}`}>
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target={`#faq${index}`}
                      aria-controls={`faq${index}`}
                    >
                      Vehicle Information
                    </a>
                  </div>

                  <div
                    id={`faq${index}`}
                    className="pt-3 collapse show"
                    aria-labelledby={`faqhead${index}`}
                    data-parent="#faq"
                  >
                    <div
                      style={{
                        display:
                          values?.vehicleNature === 'vehicular'
                            ? 'block'
                            : 'none',
                      }}
                      key={index}
                    >
                      <div className="mt-2 mb-3 ">
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                          <div>
                            <p className="mb-0">Select Vehicle Type <span className='text-danger'>*</span></p>
                          </div>
                          <div style={{ width: '200px' }} className="ml-2">
                            <Field
                              name={`vehiclesInvolved.${index}.vehicleDetails.vehicleType`}
                            >
                              {({ field }) => (
                                <>
                                  <Select1
                                    placeholder="Select vehicle type"
                                    options={vehicleTypeOptions}
                                    isSearchable={false}
                                    className="basic-multi-select"
                                    {...field}
                                    value={vehicleTypeOptions.filter(f => f.value === field.value)?.shift()}
                                    onChange={(val) => setFieldValue(`vehiclesInvolved.${index}.vehicleDetails.vehicleType`, val.value)}
                                  />
                                </>
                              )}
                            </Field>
                          </div>
                          {/* <div
                            className="btn-group btn-group-toggle border btn-tgl"
                            data-toggle="buttons"
                          >
                            <label className="btn mr-2 px-4 ">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name={`vehiclesInvolved.${index}.vehicleDetails.vehicleType`}
                                checked={
                                  vehicle?.vehicleDetails?.vehicleType ===
                                  'company'
                                }
                                value={'company'}
                              />
                              Company Vehicle
                            </label>
                            <label className="btn px-4">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name={`vehiclesInvolved.${index}.vehicleDetails.vehicleType`}
                                checked={
                                  vehicle?.vehicleDetails?.vehicleType ===
                                  'thirdParty'
                                }
                                value={'thirdParty'}
                              />
                              3<sup>rd</sup>
                              Party Vehicle
                            </label>
                          </div> */}
                        </div>
                        <ErrorMessage
                          className="text-danger text-center"
                          name={`vehiclesInvolved.${index}.vehicleDetails.vehicleType`}
                          component="div"
                        />
                        <p className="all-comment p-0 text-center mt-2">
                          Don&apos;t forget to take pictures of ALL four corners
                          and sides
                        </p>
                      </div>
                      <div>
                        <div className="border-bottom h6 py-2 mb-4">
                          <span className="text-primery font-weight-bold">
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'company' && 'Vehicle Damage Details'}
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'thirdParty' && (
                                <>
                                  3<sup>rd</sup> Party Vehicle Details
                                </>
                              )}
                          </span>
                        </div>
                        <div className="row">
                          {vehicle?.vehicleDetails?.vehicleType ===
                            'company' && (
                              <div className="d-flex align-items-center form-group col-md-12">
                                <label className="mr-2 mb-0"> Vehicle Type</label>
                                <div className="form-check form-check-inline">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name={`vehiclesInvolved.${index}.vehicleDetails.companyVehicleType`}
                                    value={'Branded'}
                                    id={`companyVehicleTypeBranded${index}`}
                                  />
                                  <label
                                    className="mt-2"
                                    htmlFor={`companyVehicleTypeBranded${index}`}
                                  >
                                    Branded
                                  </label>
                                </div>
                                <div className="form-check form-check-inline mr-5">
                                  <Field
                                    className="form-check-input"
                                    type="radio"
                                    name={`vehiclesInvolved.${index}.vehicleDetails.companyVehicleType`}
                                    value={'Rental'}
                                    id={`companyVehicleTypeRental${index}`}
                                  />
                                  <label
                                    className="mt-2"
                                    htmlFor={`companyVehicleTypeRental${index}`}
                                  >
                                    Rental
                                  </label>
                                </div>

                                <ErrorMessage
                                  className="text-danger"
                                  name={`vehiclesInvolved.${index}.vehicleDetails.companyVehicleType`}
                                  component="div"
                                />
                              </div>
                            )}
                          <div className="form-group col-md-6 col-lg-6">
                            <label>Year of Manufactured</label>
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy"
                              showYearPicker
                              name={`vehiclesInvolved.${index}.vehicleDetails.yearOfManufacture`}
                              selected={
                                vehicle?.vehicleDetails.yearOfManufacture && vehicle?.vehicleDetails.yearOfManufacture !== "" &&
                                new Date(
                                  vehicle?.vehicleDetails.yearOfManufacture,
                                )
                              }
                              onChange={(val) => {
                                setFieldValue(
                                  `vehiclesInvolved.${index}.vehicleDetails.yearOfManufacture`,
                                  val ? moment(val,).format('YYYY') : '',
                                )
                              }}
                              minDate={moment().subtract(50, 'years').toDate()}
                              maxDate={moment().toDate()}
                            />
                          </div>
                          <div className="form-group col-md-6 col-lg-6">
                            <TextInput
                              className="form-control"
                              label="Model"
                              name={`vehiclesInvolved.${index}.vehicleDetails.model`}
                            />
                          </div>

                          <div className="form-group col-md-6 col-lg-6">
                            <TextInput
                              className="form-control"
                              label="Make"
                              name={`vehiclesInvolved.${index}.vehicleDetails.make`}
                            />
                          </div>
                          {vehicle?.vehicleDetails?.vehicleType ===
                            'company' && (
                              <>
                                <div className="form-group col-md-6 col-lg-6">
                                  <TextInput
                                    label="Mileage"
                                    name={`vehiclesInvolved.${index}.vehicleDetails.mileage`}
                                    className="form-control"
                                  />
                                </div>
                                <div className="form-group  col-md-6 col-lg-6">
                                  <TextInput
                                    withAsterisk
                                    label="VAN Number"
                                    name={`vehiclesInvolved.${index}.vehicleDetails.van`}
                                    className="form-control"
                                  />
                                </div>
                              </>
                            )}
                          <div className="form-group col-md-6 col-lg-6">
                            <TextInput
                              withAsterisk
                              label="VIN Number"
                              name={`vehiclesInvolved.${index}.vehicleDetails.vin`}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group col-md-6 col-lg-6">
                            <TextInput
                              withAsterisk
                              label="License Plate"
                              name={`vehiclesInvolved.${index}.vehicleDetails.licensePlate`}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-md-6 col-lg-6">
                            <TextInput
                              label="State"
                              name={`vehiclesInvolved.${index}.vehicleDetails.damageState`}
                              className="form-control"
                            />
                          </div>
                          <div
                            className="form-group col-md-12 col-lg-12 vehicle-parts-list"
                          >
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'company' &&
                              Object.values(
                                vehicle?.vehicleDetails?.vanParts,
                              ).join(', ')}
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'thirdParty' &&
                              Object.values(
                                vehicle?.vehicleDetails?.sedanParts,
                              ).join(', ')}
                          </div>
                          <div className="form-group col-lg-12 p-5 svg-wrapper">
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'company' && (
                                <VehiclePart
                                  selectedParts={Object.keys(
                                    vehicle?.vehicleDetails?.vanParts || {},
                                  )}
                                  onSelect={(key, value) => {
                                    let newSelectVehicleParts = null
                                    if (vehicle?.vehicleDetails?.vanParts[key]) {
                                      newSelectVehicleParts = {
                                        ...vehicle?.vehicleDetails?.vanParts,
                                      }
                                      delete newSelectVehicleParts[key]
                                    } else {
                                      newSelectVehicleParts = {
                                        ...vehicle?.vehicleDetails?.vanParts,
                                        [key]: value,
                                      }
                                    }
                                    setFieldValue(
                                      `vehiclesInvolved.${index}.vehicleDetails.vanParts`,
                                      newSelectVehicleParts,
                                    )
                                  }}
                                />
                              )}
                            {vehicle?.vehicleDetails?.vehicleType ===
                              'thirdParty' && (
                                <SedanCarParts
                                  selectedParts={Object.keys(
                                    vehicle?.vehicleDetails?.sedanParts || {},
                                  )}
                                  onSelect={(key, value) => {
                                    let newSelectVehicleParts = null
                                    if (
                                      vehicle?.vehicleDetails?.sedanParts[key]
                                    ) {
                                      newSelectVehicleParts = {
                                        ...vehicle?.vehicleDetails?.sedanParts,
                                      }
                                      delete newSelectVehicleParts[key]
                                    } else {
                                      newSelectVehicleParts = {
                                        ...vehicle?.vehicleDetails?.sedanParts,
                                        [key]: value,
                                      }
                                    }
                                    setFieldValue(
                                      `vehiclesInvolved.${index}.vehicleDetails.sedanParts`,
                                      newSelectVehicleParts,
                                    )
                                  }}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            values?.vehiclesInvolved.length > 1 && (<div className="ml-2 delete-accrodion">
              <i
                onClick={() => handleDeleteVehicleDetail(index)}
                style={{ cursor: 'pointer', fontSize: '21px' }}
                className="fa fa-trash"
              />
            </div>)
          }
        </div>
      ))}
    </>
  )
}

export default VehicleDetailsForm
