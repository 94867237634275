import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import { timeNowFormat } from '../../configs/utils'
import { getHistoryByEventId } from '../../services/historyService'

function HistoryRecordInfo() {
  const dispatch = useDispatch()
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const mountedRef = useRef(false)
  const [history, setHistory] = useState([])

  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const eventId = data._id

  const init = async () => {
    const result = await getHistoryByEventId(eventId)
    if (!mountedRef.current) return null
    setHistory(result.data.data)
  }
  useEffect(() => {
    mountedRef.current = true
    init()
    return () => {
      mountedRef.current = false
    }
  }, [eventId])

  const handleOrderChange = async (val) => {
    let param = { order: val }
    const result = await getHistoryByEventId(eventId, param)
    setHistory(result.data.data)
  }

  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div className="card-header-2 d-flex mb-4 mt-4">
          <div className="card-title mb-0 pb-0">
            <h2 className="mb-0 pb-0 dark-text">History</h2>
          </div>
          <nav className="">
            <li className="nav-item p-0 close11">
              <span className="search " onClick={handleSideNavClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.829"
                  height="17.828"
                  viewBox="0 0 17.829 17.828"
                >
                  <g
                    id="Group_9756"
                    data-name="Group 9756"
                    transform="translate(-4013.659 13.151)"
                  >
                    <path
                      id="path1419"
                      d="M-1479.2-17.087l15-15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      id="path1421"
                      d="M-1479.2-32.087l15,15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>
              </span>
            </li>
          </nav>
        </div>

        <div className="mt-4">
          <a href="#" className="card border-hover-none mb-2 border-0 pr-2">
            <div className="card-header-comment  pt-9">
              <p className="p-0 m-0  comment-txt">Recent Updates</p>
              <div>
                <select
                  name="order"
                  id="order"
                  className="form-control comment-asds inherit"
                  onClick={(e) => handleOrderChange(e.target.value)}
                >
                  <option value="desc">Descending</option>
                  <option value="asc">Ascending</option>
                </select>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        className="pb-5 overflow-auto"
        id="scroller"
        style={{ marginTop: '160px' }}
      >
        {history && history.length > 0 ? (
          <div className="pr-2 pl-2 pb-4 overflow-auto" id="scroller">
            <div>
              {history?.map((data, index) => (
                <div className="my-3" key={index}>
                  <div className="d-flex justify-content-between comment-section">
                    <div className="img-box active mb-1 border-bottom">
                      <div className="pro-box d-flex mr-3 ml-3">
                        {`${data?.user?.firstName.charAt(
                          0,
                        )}${data?.user?.lastName?.charAt(0)}`}{' '}
                      </div>
                      <div className="r-box">
                        <p>
                          <strong>
                            {data?.user?.firstName} {data?.user?.lastName}
                          </strong>{' '}
                          {data?.action}{' '}
                          <strong>
                            {data.module} {data.tag}
                          </strong>{' '}
                          {data?.fieldChanged.length > 0 && (
                            <span>
                              {' '}
                              fields{' '}
                              {data?.fieldChanged.map((field, index) => {
                                return <strong key={index}>{field} </strong>
                              })}
                            </span>
                          )}
                        </p>
                        <p>{timeNowFormat(data.created)}</p>
                      </div>
                      {/* <div className="comment-escl">
                        <a
                          href="#"
                          className="dropdown btn-sm p-0 mr-3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fas fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-left user-dd animated">
                          <ul className="navi navi-hover">
                            <li className="navi-item">
                              <a
                                href="incident.html"
                                className="navi-link"
                                onClick={() => {}}
                              >
                                <span className="navi-text">
                                  <span className="label label-xl label-inline  label-light-danger">
                                    <i
                                      className="far fa-edit"
                                      aria-hidden="true"
                                    ></i>
                                    Edit
                                  </span>
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                href="#"
                                className="navi-link"
                                onClick={() => {}}
                              >
                                <span className="navi-text">
                                  <span className="label label-xl label-inline label-light-danger">
                                    <i
                                      className="far fa-trash-alt"
                                      aria-hidden="true"
                                    ></i>
                                    Delete
                                  </span>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <span className="bookmark mr-2">
                          <i
                            className="fas fa-thumbtack"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={'pr-2 pl-2 pb-4'}>No History Found</div>
        )}
      </div>
    </div>
  )
}

export default HistoryRecordInfo
