import ShortUniqueId from 'short-unique-id'
// import moment from 'moment'
export function generateUID(module) {
  const uid = new ShortUniqueId()
  const genId = `${uid.stamp(10).toUpperCase()}`
  //   console.log(uid.parseStamp(genId))
  switch (module) {
    case 'client':
      return `CLN-${genId}`
    case 'user':
      return `USR-${genId}`
    case 'third-party':
      return `TPR-${genId}`
    case 'mvr':
      return `MVR-${genId}`
    case 'claim':
      return `CLM-${genId}`
    case 'incident':
      return `INC-${genId}`
    case 'employee':
      return `EMP-${genId}`
    case 'vehicle':
      return `VHL-${genId}`
    case 'property':
      return `PRP-${genId}`
    case 'policy':
      return `PLY-${genId}`
    case 'public-incident':
      return `PUBINC-${genId}`
    default:
      return uid.stamp(10)
  }
}

export function getUniqueIdKey(module) {
  switch (module) {
    case 'client':
      return `clientId`
    case 'third-party':
      return `thirdPartyId`
    case 'mvr':
      return `mvrId`
    case 'claim':
      return `claimId`
    case 'incident':
      return `incidentId`
    case 'employee':
      return `employeeId`
    case 'vehicle':
      return `vehicleId`
    case 'property':
      return `propertyId`
    case 'policy':
      return `policyId`
    default:
      return ''
  }
}
