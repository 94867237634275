import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { uploadFilesWithPresignedUrl } from '../services/attachmentService'

export default function AddAttachment({ module, uid, onAdd, isOpen, onClose }) {
  const [filename, setFilename] = useState([])
  const [attachments, setAttachments] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    filename.length && setFilename([])
  }, [])

  const handleFile = async (event) => {
    let filename = []
    for (const key in event.target.files) {
      if (Object.hasOwnProperty.call(event.target.files, key)) {
        const element = event.target.files[key];
        filename.push(element.name)
      }
    }
    const files = [...event.target.files]
    setIsUploading(true)
    const data = await uploadFilesWithPresignedUrl(files, module, uid).finally(() => setIsUploading(false))
    setFilename(prev => [...prev, ...filename])
    event.target.value = null
    setAttachments(prev => prev ? [...prev, ...data] : data)
  }

  const handleOnAdd = () => {
    attachments && onAdd(attachments)
    setFilename([])
    setAttachments(null)
  }

  return (
    <Modal isOpen={isOpen}>
      <div className="excelfile" style={{ margin: '0', zIndex: '1050' }}>
        <div className="modal-content border-radius-file">
          <div className="modal-header d-flex justify-content-center">
            <h5 className="modal-title text-primary">Add Attachment</h5>
          </div>
          <div className="modal-body pb-0">
            <div className="form-group">
              <div className="dropzone dropzone-default dropzone-primary dz-clickable">
                {!filename.length ? (
                  <div className="dropzone-msg dz-message needsclick">
                    <img className="mb-2" src="assets/images/icon/upload.svg" />
                    <h3 className="dropzone-msg-title">
                      Drag and Drop Here <br /> or
                    </h3>
                    <span className="dropzone-msg-desc"></span>
                  </div>
                ) : (
                  <div className="dropzone-msg dz-message needsclick">
                    File Added: {filename.map((file, index) => (
                      <><span key={index}>{file}</span><br></br></>
                    ))}
                  </div>
                )}
                <input
                  type="file"
                  disabled={isUploading}
                  multiple
                  id="upload-picture"
                  onChange={handleFile}
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="fileupload modal-footer border-0 cusmdl-ft d-flex">
            <button
              type="submit"
              disabled={!filename.length || isUploading}
              className="btn btn-primary  add-btn"
              onClick={handleOnAdd}
            >
              {isUploading && <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
              Add
            </button>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark"
              onClick={onClose}
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
