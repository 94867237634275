import React from 'react'
import { Modal } from 'reactstrap'

import { ReactComponent as Close } from '../../../svgs/close.svg'
import IncidentForm from '../../incident/IncidentForm'
import DirectClaim from './DirectClaim'

function DirectClaimModal({ title, data, isOpen, onClose, context }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdrop={'static'}
      className={'modal-dialog-top modal-lg modal-right posi-rel'}
      style={{ minWidth: '65vw' }}
    >
      <div>
        <a onClick={onClose} className="close close-left" aria-label="Close">
          <Close />
        </a>
        <div className="modal-content" style={{ height: '100vh' }}>
          <div className="col-md-12 p-0 col-h">
            <div className="modal-body p-0">
              {context === 'incident' ? (
                <IncidentForm
                  data={data}
                  match={null}
                  title={title}
                  onClose={onClose}
                  context={context}
                />
              ) : (
                <DirectClaim data={data} onClose={onClose} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DirectClaimModal
