import React from 'react'
import Select1 from 'react-select'

const CustomSelect = ({ options, values, onChangeHandler }) => {
  return (
    <>
      <div data-testid="claimant-dropdown">
        <Select1
          placeholder="Select Claimant"
          getOptionLabel={(option) =>
            `${option?.basicInfo?.firstName || ''} ${
              option?.basicInfo?.lastName || ''
            }`
          }
          getOptionValue={(option) => option}
          name="claimants.0.claimantInfo"
          value={values?.claimants?.[0].claimantInfo || ''}
          isSearchable="true"
          onChange={onChangeHandler}
          options={options}
        />
      </div>
    </>
  )
}

export default CustomSelect
