import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/third-party`

export function getThirdParties() {
  return http.get(`${apiEndpoint}/list`)
}

export function exportThirdParties() {
  return http.get(`${apiEndpoint}/export-to-csv`, { responseType: 'blob' })
}

export function getThirdParty(id) {
  return http.get(`${apiEndpoint}/details/${id}`)
}

export function updateThirdParty(id, mvr) {
  return http.put(`${apiEndpoint}/update/${id}`, mvr)
}

export function deleteThirdParty(id, mvr) {
  return http.put(`${apiEndpoint}/delete/${id}`, mvr)
}

export function deleteBlukThirdParty(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}

export function saveThirdParty(thirdParty) {
  // const date = new Date()
  // const thirdPartyId = `TP-${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...thirdParty })
}
