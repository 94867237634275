import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import { useCommentAccess, useGetPermission } from '../../hooks/useGetPermission'
import { addCommaTableField } from '../../pages/incident/utils'
import { getClient } from '../../services/clientService'

export default function PolicyRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const mountedRef = useRef(true)
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const deletePermission = useGetPermission('policy', 'DELETE')
  const editPermission = useGetPermission('policy', 'UPDATE')
  const addCommentPermission = useCommentAccess('policy', 'CREATE')

  const isPolicyActive = (date) => {
    return !!date && (moment() > moment(date)) ? 'Inactive' : 'Active'
  }

  const [clientInfo, setclientInfo] = useState({});

  useEffect(() => {
    mountedRef.current = true
    getClientData()
    return () => { 
      mountedRef.current = false
    }
  }, [data])

  const getClientData = async () => {
    try {
      const { data: client } = await getClient(data.clientId)
      setclientInfo(client.data);
      if (!mountedRef.current) return null
    } catch (error) {}
  }

  return (
    <div
      className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}
      style={{ overflow: 'auto' }}
    >
      <div className="mt-5"></div>
      <div className="mt-5"></div>

      <div className="sidebar-sec">
        <div className="card-header-2  mb-4 mt-3">
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
            <div
                className="mr-3 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Policy date"
                data-original-title={moment(data?.policyDate).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {moment(data?.policyDate).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {moment(data?.policyDate).format('YYYY')}
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.policyId || 'N/A'}</div>
                {clientInfo.companyName || 'N/A'}
              </span>
              <p className="mt-3">Policy Type ({data?.policyType || 'N/A'})</p>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                {/* <li className="nav-item p-0">
                  <span className="search">
                    <a className="text-dark">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <path
                            id="icons8-external-link"
                            d="M5,3A2.015,2.015,0,0,0,3,5V19a2.015,2.015,0,0,0,2,2H19a2.015,2.015,0,0,0,2-2V12H19v7H5V5h7V3Zm9,0V5h3.586L8.293,14.293l1.414,1.414L19,6.414V10h2V3Z"
                            transform="translate(-3 -3)"
                            fill="#828282"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li> */}
                <li className="nav-item p-0 close11" onClick={handleSideNavClose}>
                  <span className="search ">
                    <a className="text-dark">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.829"
                          height="17.828"
                          viewBox="0 0 17.829 17.828"
                        >
                          <g
                            id="Group_9756"
                            data-name="Group 9756"
                            transform="translate(-4013.659 13.151)"
                          >
                            <path
                              id="path1419"
                              d="M-1479.2-17.087l15-15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              id="path1421"
                              d="M-1479.2-32.087l15,15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li>
              </nav>
            </div>
          </div>

          <div className="pull-right px-3 pb-5 d-flex justify-content-between cus-width">
            <div className="">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary mr-3 px-3"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}
            </div>
            <div>
            {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
            )}
            {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            )}
            </div>
          </div>
        </div>

        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div className="accordion" id="faq">
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="false"
                      aria-controls="faq1"
                    >
                      Policy Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="pt-3 collapse show"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Policy Date</div>
                          <span>
                            {data?.policyDate ?
                              moment(data?.policyDate).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex">Premium Renewal Date</div>
                          <span>
                            {data?.premiumRenewalDate ? 
                              moment(data?.premiumRenewalDate).format(
                                'MM/DD/YYYY',
                              ) : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Policy Status</div>
                          <span className="text-warning">
                            {isPolicyActive(data?.premiumRenewalDate)}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Limit</div>
                          <span>$ {data?.sumInsured ? addCommaTableField(data?.sumInsured)
                : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Aggregate Limit</div>
                          <span>$ {data?.aggregateLimit ? addCommaTableField(data?.aggregateLimit)
                : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Deductible</div>
                          <span>$ {data.deducibleField ? addCommaTableField(data.deducibleField)
                : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          <div className="d-flex"> Broker</div>
                          <span>{data.broker || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text mb-0">
                          <div className="d-flex"> Policy Notes</div>
                        </label>
                        <p
                          className=""
                          style={{
                            color: '#000',
                            fontWeight: 600,
                            lineHeight: 1.6,
                          }}
                        >
                          {data.policyNotes || 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header1" id="faqhead2">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                    >
                      Premium Paid
                    </a>
                  </div>

                  <div
                    id="faq2"
                    className="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    {data?.premiums?.map(({ amount, transactionDate }, index) => {
                      return (
                        <div className="parties price bg-white p-3 mb-3 col-md-4 mt-4 claim-taken-view ml-2" key={index}>
                          <h6 className="text-primery">
                            {transactionDate &&
                              moment(transactionDate).format('MM/DD/YYYY')}
                          </h6>
                          <small className="mb-2">${amount ? addCommaTableField(amount)
                : 'N/A'}</small>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="card">
                  <div className="card-header1" id="faqhead3">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      Claim Taken
                    </a>
                  </div>

                  <div
                    id="faq3"
                    className="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    {data?.claims?.map(({ amount, transactionDate }, index) => {
                      return (
                        <div className="parties price bg-white p-3 mb-3 col-md-4 mt-4 claim-taken-view ml-2" key={index}>
                          <h6 className="text-primery">
                            {transactionDate &&
                              moment(transactionDate).format('MM/DD/YYYY')}
                          </h6>
                          <small className="mb-2">${amount ? addCommaTableField(amount)
                : 'N/A'}</small>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
