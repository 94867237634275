import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { transformPhoneNumber } from '../../pages/incident/utils'

function TableBody({
  data,
  columns,
  checkboxClick,
  checkedItems,
  hideCheckbox,
}) {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item)
    const cellContent = _.get(item, column.path)
    if (column.type !== 'choice' && !cellContent) return 'N/A'
    if (column.type === 'fiscalDate')
      return moment(cellContent).format('MM/DD/YYYY')
    if (column.type === 'date') return moment(cellContent).format('MM/DD/YYYY')
    if (column.type === 'cost') return `$ ${cellContent}`
    if (column.type === 'choice') return `${cellContent ? 'Yes' : 'No'}`
    if (column.type === 'email') return `${cellContent}`
    if (column.type === 'phone') {
      return `${transformPhoneNumber(cellContent)}`}
    if (column.type === 'fiscal') {
      var check = moment(cellContent).format('MM/DD')
      if (check === 'Invalid date') {
        return `${cellContent}`
      } else {
        return check || ''
      }
    }
    if (column.type === 'string')
      return _.map(cellContent.split(' '), _.capitalize).join(' ')

    return cellContent
  }

  const createKey = (item, column, index) => {
    return `${item._id} ${column.path || column.key} ${index}`
  }

  return (
    <tbody>
      {data.map((item, index) => (
        <tr className="tableRow" key={`${item._id} ${index}`}>
          {!hideCheckbox && (
            <td className={'tableCol'}>
              <input
                className="list-checkbox"
                type="checkbox"
                checked={checkedItems.includes(item._id)}
                onChange={checkboxClick.bind(this, '', item._id)}
              />
            </td>
          )}
          {columns.map((column, index) => {
            if (column.path === 'status' && item.status) {
              return (
                <td
                  key={createKey(item, column, index)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '45px',
                    width: column.width ? column.width : '165px',
                  }}
                  className={'tableCol '}
                >
                  <div className={'pull-left pr-2'}>
                    {(item.status === 'DUE NOW' || item.status === 'OVER DUE')
                       && (
                        <span
                          className={
                            item.status === 'DUE NOW'
                              ? 'badge badge-pill badge-w badge-warning text-white'
                              : item.status === 'OVER DUE'
                              ? 'badge badge-pill badge-w badge-danger'
                              : ''
                          }
                          style={{ fontSize: '75%' }}
                        >
                          {item.status}
                        </span>
                      )}
                  </div>
                  <span className={'tableCol'}>{renderCell(item, column)}</span>
                </td>
              )
            } else {
              return (
                <td className={'tableCol'} key={createKey(item, column, index)}>
                  {renderCell(item, column)}
                </td>
              )
            }
          })}
        </tr>
      ))}
    </tbody>
  )
}

export default TableBody
