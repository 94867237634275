import React, { useState, useEffect, useRef } from 'react'
import { Modal } from 'reactstrap'
import PhoneInput from 'react-phone-number-input'
import { Formik } from 'formik'
import 'react-phone-number-input/style.css'
import AddressInput from '../../components/form/AddressInput'

function WitnessModal({
  isOpen,
  onClose,
  values,
  setFieldValue,
  editData,
  editId,
  directClaim = false,
}) {
  const addressRef = useRef(true)
  const [data, setdata] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    // address: AddressSchema,
    email: '',
    companyName: '',
    designation: '',
    witnessAvailable: false,
  })

  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
  })

  useEffect(() => {
    if (editData) {
      const {
        firstName,
        lastName,
        contactNumber,
        // address: AddressSchema,
        email,
        companyName,
        designation,
        witnessAvailable,
      } = editData
      setdata({
        firstName,
        lastName,
        contactNumber: contactNumber,
        // address: AddressSchema,
        email,
        companyName,
        designation,
        witnessAvailable,
      })
      if (editData.address) {
        const { line1, line2, city, state, country, pinCode } = editData.address
        setAddress({ line1, line2, city, state, country, pinCode })
      }
    }
  }, [editData])

  const clearFormField = () => {
    setdata({
      firstName: '',
      lastName: '',
      contactNumber: '',
      // address: AddressSchema,
      email: '',
      companyName: '',
      designation: '',
      witnessAvailable: false,
    })
    setAddress({
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      pinCode: '',
    })
  }

  const handleSave = () => {
    const a = {
      ...data,
      contactNumber: data?.contactNumber || '',
      address: addressRef.current?.values?.address || {},
    }

    const party =
      editId != null
        ? [...values?.witnesses.filter((a, i) => i != editId), a]
        : [...values.witnesses, a]

    clearFormField()
    if (directClaim) {
      setFieldValue('incident.witnesses', party)
    } else {
      setFieldValue('witnesses', party)
    }
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      centered
      style={{
        height: '400px',
        position: 'absolute',
        top: '44px',
        top: '-23%',
        left: '31%',
        minWidth: '500px',
      }}
    >
      <div>
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title text-primary " id="">
              Add Witness involved in the incident
            </h5>
          </div>
          <div className="modal-body p-0" id="smodaltab">
            <ul
              className="nav nav-pills bg-light d-flex justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active mt-0 font-weight-normal"
                  id="Person-tab"
                  data-toggle="pill"
                  href="#Person"
                  role="tab"
                  aria-controls="pills-Person"
                  aria-selected="true"
                >
                  Witness Details
                </a>
              </li>
            </ul>

            <div style={{ height: '400px', overflowY: 'auto' }}>
              <div className="px-5">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="" className="col-form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.firstName}
                        onChange={(a) =>
                          setdata((x) => ({ ...x, firstName: a.target.value }))
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="" className="col-form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={data.lastName}
                        onChange={(a) =>
                          setdata((x) => ({ ...x, lastName: a.target.value }))
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <Formik
                        innerRef={addressRef}
                        enableReinitialize
                        initialValues={{ address }}
                      >
                        {({ setFieldValue }) => (
                          <AddressInput
                            label="Address"
                            name="address"
                            setFieldValue={setFieldValue}
                          />
                        )}
                      </Formik>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Contact Number</label>
                        <PhoneInput
                          name={''}
                          defaultCountry="US"
                          // displayInitialValueAsLocalNumber={true}
                          international={false}
                          className={'form-control'}
                          placeholder="Contact Number"
                          onChange={(phone) =>
                            setdata((x) => ({
                              ...x,
                              contactNumber: phone || '',
                            }))
                          }
                          maxLength={14}
                          id="Contact"
                          value={data.contactNumber || ''}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            onChange={(a) =>
                              setdata((x) => ({
                                ...x,
                                companyName: a.target.value,
                              }))
                            }
                            name=""
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={data.companyName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Designation</label>
                          <input
                            onChange={(a) =>
                              setdata((x) => ({
                                ...x,
                                designation: a.target.value,
                              }))
                            }
                            name=""
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={data.designation}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer" style={{ background: '#F4F7FE' }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                data-testid="witness-modal-cancel-btn"
                onClick={() => {
                  clearFormField()
                  onClose()
                }}
                type="button"
                className="btn btn-secondary bg-white text-dark"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WitnessModal
