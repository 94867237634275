import React, { useContext, useState } from 'react'
import { Modal } from 'reactstrap'
import Attachments from './ExtraSettings/Attachment'
import Comment from './ExtraSettings/Comment'
import ExtraSettingFooter from './ExtraSettings/Footer'
import ExtraSettingHeader from './ExtraSettings/Header'
import HistoryNotification from './ExtraSettings/HistoryNotification'
import MVR from './ExtraSettings/MVR'
import RolesContext from './RolesContext'

const ExtraSettingsModal = ({ isOpen, onClose, permissionObj }) => {
  const formik = useContext(RolesContext)
  const stings = JSON.parse(JSON.stringify(permissionObj.settings))
  const [settings, setSettings] = useState(stings)


  const [activeModalContext, setActiveModalContext] = useState('')

  const handleDropDownOpenCloseClicked = (context) => {
    if (activeModalContext === context) {
      setActiveModalContext('')
    } else {
      setActiveModalContext(context)
    }
  }

  const onCheck = (value, key) => {
    const newSettings = { ...settings }
    newSettings[key].includes(value)
      ? newSettings[key].splice(newSettings[key].indexOf(value), 1)
      : newSettings[key].push(value)
    setSettings(newSettings)
  }

  const onApply = () => {
    const permissions = [...formik.values.permissions].map((c) => {
      if (c.module === permissionObj.module) {
        c.settings = settings
      }
      return c
    })
    formik.setValues((v) => {
      return {
        ...v,
        permissions: permissions,
      }
    })
    onClose?.()
  }

  // useEffect(() => {
  //   console.log(
  //     'PERMISSION CHANGED',
  //     formik.values.permissions.find((c) => c.module === permissionObj.module),
  //   )
  // }, [formik.values.permissions])
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdrop={'static'}
      className={
        'modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable'
      }
      style={{ width: '600px' }}
    >
      <div className="modal-content">
        <ExtraSettingHeader moduleName={permissionObj.moduleName} />
        <div className="modal-body p-0 px-5" id="smodaltab">
          <ul className="nav nav-pills bg-lightdrop d-flex px-4">
            <li className="nav-item px-3" role="presentation">
              <a className="nav-link  mt-0 font-weight-normal" href="#">
                DEFINE CONDITIONS
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pill-tabContent1">
            <div className="tab-pane fade show active">
              <div className="px-5 my-4 mb-5">
                <form className="pb-5">
                  <Comment
                    comment={settings.comment}
                    handleDropDownOpenCloseClicked={() =>
                      handleDropDownOpenCloseClicked('comment')
                    }
                    show={activeModalContext == 'comment'}
                    onCheckedChange={onCheck}
                  />

                  <Attachments
                    extras={settings.extras}
                    attachments={settings.attachments}
                    handleDropDownOpenCloseClicked={() =>
                      handleDropDownOpenCloseClicked('attachments')
                    }
                    onChekedChange={onCheck}
                    show={activeModalContext == 'attachments'}
                  />

                  {permissionObj.moduleName.toLowerCase() === 'mvr' && (
                    <MVR
                      handleDropDownOpenCloseClicked={() =>
                        handleDropDownOpenCloseClicked('mvr')
                      }
                      show={activeModalContext == 'mvr'}
                      extras={settings.extras}
                      onCheckedChange={(value, key) => {
                        const newSettings = { ...settings }
                        if (!newSettings[key].includes(value)) {
                          if (value === 'ANALYZE') {
                            const index = newSettings[key].indexOf('APPROVE')
                            newSettings[key].splice(index, 1)
                            newSettings[key].push('ANALYZE')
                          } else {
                            const index = newSettings[key].indexOf('ANALYZE')
                            newSettings[key].splice(index, 1)
                            newSettings[key].push('APPROVE')
                          }
                        }
                        setSettings(newSettings)
                      }}
                    />
                  )}

                  <HistoryNotification
                    extras={settings.extras}
                    onCheckedChange={onCheck}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
        <ExtraSettingFooter onApply={onApply} onClose={onClose} />
      </div>
    </Modal>
  )
}

export default ExtraSettingsModal
