import React from 'react'

// columns: array
// sortColumn: object
// onSort: function

function TableHeader(props) {
  const renderSortIcon = (column) => {
    const { sortColumn } = props
    if (!sortColumn) return <i className="fa fa-chevron-down ml-2" aria-hidden="true" style={{fontSize: '10px'}}/>
    if (column.path !== sortColumn.path) return <i className="fa fa-chevron-down ml-2" aria-hidden="true" style={{fontSize: '10px'}}/>
    if (sortColumn.order === 'asc')
      return <i className="fa fa-chevron-up ml-2" aria-hidden="true" style={{fontSize: '10px'}}/>
    return <i className="fa fa-chevron-down ml-2" aria-hidden="true" style={{fontSize: '10px'}}/>
  }

  const raiseSort = (path) => {
    const sortColumn = { ...props.sortColumn }
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc'
    else {
      sortColumn.path = path
      sortColumn.order = 'asc'
    }
    props.onSort(sortColumn)
  }
  return (
    <thead className="table-active">
      <tr className="sticky">
        {!props.hideCheckbox && <th>
          <input type="checkbox" className='list-checkbox' checked={props.checked} onChange={props.checkboxClick.bind(this, "selectAll")}/>
        </th>}
        {props.columns.map((column, index) => {
          if (column.path === 'status') {
            return (
              <th
                key={'status'}
                style={{ width: column.width ? column.width : "165px" }}
              > {column.label} {" "}
              </th>
            )
          } else {
            let style;
            if (index === 0) {
              style = {whiteSpace: 'nowrap', width: column.width, minWidth: column.minWidth };
              if (column.width) {
                style.width = column.width;
              }
            } else {
              style = { whiteSpace: 'nowrap', width: column.width };                
            }

            return (
              <th
                className={column.className}
                style={style}
                key={column.path || column.key || index}
                onClick={() => raiseSort(column.path)}
              >
                <a href="#" className="text-decoration-none ">{column.label}
                  {column.label && <span> {" "}
                    {renderSortIcon(column)}
                   {/* {!props.sortColumn &&  <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>} */}
                  </span>}
                </a>
              </th>
            )
          }
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
