import React, { useContext } from 'react';
import RolesContext from './RolesContext';

function RoleDetails() {
    const formik = useContext(RolesContext)
    return (
        <div className="pl-6 user-mang">
        <div className="container-fluid">
          <div className="row mt-3 mb-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <nav className="">
                <div
                  className="nav  nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  {/* <a
                    className="btn btn-info btn-acuity"
                    style={{ height: '54px' }}
                  >
                    <span>0 users with role </span>{' '}
                  </a> */}
                </div>
              </nav>
            </div>
          </div>
          <div className="row mr-5">
            <div className="col-md-6">
              <div className="form-group col-lg-6 p-0">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control "
                  placeholder=""
                  name="role_data.roleName"
                  value={formik.values.role_data.roleName}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="">Description</label>
                <textarea
                  className="form-control"
                  name="role_data.description"
                  value={formik.values.role_data.description}
                  onChange={formik.handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default RoleDetails
