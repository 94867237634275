import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { getDashboardTotal } from "../../../../services/dashboardService";

export default function CountBar() {
    const [total, setTotal] = useState({})
    const { slot,selectedClientId } = useSelector((state) => state)

    useEffect(() => {
        getDashboardTotal(slot).then(response => {
            setTotal(response.data.data)
        })
    }, [slot,selectedClientId])

    return (
        <div className="row">
            <div className="col-xl-1"></div>
            <div className="col-xl-2 col-md-6 mb-4">
                <div className="card border-left-primary  h-100 py-2">
                    <div className="card-body" id="topcard">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="font-weight-bold text-primary text-uppercase mb-1">
                                    Accidents</div>
                                <div className="h5 mb-0 font-weight-bold ">{total?.accidents}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-2 col-md-6 mb-4">
                <div className="card border-left-primary  h-100 py-2">
                    <div className="card-body" id="topcard">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="font-weight-bold text-primary text-uppercase mb-1">
                                    Claims</div>
                                <div className="h5 mb-0 font-weight-bold">{total?.claims}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-md-6 mb-4">
                <div className="card border-left-primary  h-100 py-2">
                    <div className="card-body" id="topcard">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="font-weight-bold text-primary text-uppercase mb-1">
                                    Policy Renewal
                                </div>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="h5 mb-0 mr-3 font-weight-bold ">{total?.policies}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-2 col-md-6 mb-4">
                <div className="card border-left-primary  h-100 py-2">
                    <div className="card-body" id="topcard">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="font-weight-bold text-primary text-uppercase mb-1">
                                    Employees</div>
                                <div className="h5 mb-0 font-weight-bold">{total?.employees}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-2 col-md-6 mb-4">
                <div className="card border-left-primary  h-100 py-2">
                    <div className="card-body" id="topcard">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="font-weight-bold text-info text-primary mb-1">
                                    VEHICLES</div>
                                <div className="h5 mb-0 font-weight-bold">{total?.vehicles}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-1"></div>
        </div>
    )
}
