import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import {
  useCommentAccess,
  useGetPermission,
} from '../../hooks/useGetPermission'
import { addCommaTableField } from '../../pages/incident/utils'
import { getClient } from '../../services/clientService'

export default function ClaimRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const [activeIncidentIndex, setActiveIncidentIndex] = useState(0)
  const [activeClaimantIndex, setActiveClaimantIndex] = useState(0)
  const data = useSelector((state) => state.sideNavData)

  const dateOfLoss = useMemo(() => {
    if (data?.incidents?.[0]?.incidentDetails?.incidentDetails?.dateOfIncident) {
      return moment(data?.incidents?.[0]?.incidentDetails?.incidentDetails?.dateOfIncident, "YYYY-MM-DD").format("MM/DD/YYYY")
    }

    if (data?.incident?.incidentDetails?.dateOfIncident) {
      return moment(data?.incident?.incidentDetails?.dateOfIncident).format("MM/DD/YYYY")
    }

    return "N/A"
  }, [data])

  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const deletePermission = useGetPermission('claim', 'DELETE')
  const editPermission = useGetPermission('claim', 'UPDATE')
  const addCommentPermission = useCommentAccess('claim', 'CREATE')

  const incidentId =
    data?.incidents?.length > 0
      ? (data?.incidents[activeIncidentIndex] &&
        data?.incidents[activeIncidentIndex]?.incidentDetails?.incidentId) ||
      '-'
      : data?.incident?.incidentId
  const claimNumber =
    data?.incidents?.length > 0
      ? (data?.incidents[activeIncidentIndex] &&
        data?.incidents[activeIncidentIndex]?.claimNumber) ||
      '-'
      : data?.incident?.claimNumber || ''
  const incident =
    data?.incidents?.length > 0
      ? data?.incidents[activeIncidentIndex] &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex] &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex]
        ?.claimantInfo &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex]
        ?.claimantInfo?.basicInfo
      : data?.incident
  const claimant =
    data?.incidents?.length > 0
      ? data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex]
      : data?.incident
  const claimantId =
    data?.incidents?.length > 0
      ? data?.incidents[activeIncidentIndex] &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex] &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex]?.claimantInfo &&
      data?.incidents[activeIncidentIndex]?.claimants[activeClaimantIndex]?.claimantInfo?.claimantId
      : data?.claimantId
  const dateOfClaim = data?.dateOfClaim ? moment(data?.dateOfClaim).format('MM/DD/YYYY') : 'N/A'
  const dateOfClaimMonth = new Date(data?.dateOfClaim).toLocaleString('en-us', {
    month: 'long',
  })
  const activeDropdownItemStyle = { backgroundColor: '#007bff', color: '#fff' }

  const [clientInfo, setclientInfo] = useState({})

  useEffect(() => {
    fetchClient()
  }, [data])

  const fetchClient = async () => {
    const { data: client } = await getClient(data?.clientId)
    setclientInfo(client.data)
  }

  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  const setActiveIncident = (iIndex) => {
    setActiveIncidentIndex(iIndex)
  }

  const setActiveClaimant = (iIndex) => {
    setActiveClaimantIndex(iIndex)
  }

  let getIncidentDropdownView = () => {
    let aListItemViews = []
    let incidentData = data?.incidents?.length > 0 ? data?.incidents : [data]
    incidentData.forEach(function (oIncidentData, iIndex) {
      if (
        oIncidentData?.incidentDetails &&
        oIncidentData?.incidentDetails?.incidentId
      ) {
        aListItemViews.push(
          <a
            className="dropdown-item"
            style={
              activeIncidentIndex === iIndex ? activeDropdownItemStyle : null
            }
            onClick={setActiveIncident.bind(this, iIndex)}
            key={iIndex}
          >
            {oIncidentData?.incidentDetails?.incidentId || '-'}
          </a>,
        )
      }
    })

    return (
      <div
        className="dropdown-menu inc px-3 shadow-sm"
        style={{ right: '-150px' }}
      >
        <span className="dropdown-item-text border-bottom mb-2 pl-3 font-weight-bold">
          Select a incident to see details
        </span>
        {aListItemViews}
        <span className="dropdown-item-text mb-2 pl-3 text-primery">
          <small> * All incidents related to claim {data?.claimId}</small>
        </span>
      </div>
    )
  }

  let getClaimantDropdownView = () => {
    let aListItemViews = []
    let incident = data?.incidents[activeIncidentIndex]
    let incidentId =
      incident &&
      incident?.incidentDetails &&
      incident?.incidentDetails?.incidentId

    incident?.claimants.forEach(function (oClaimantData, iIndex) {
      if (oClaimantData?.claimantInfo && oClaimantData?.claimantInfo?.basicInfo) {
        let basicInfo = oClaimantData?.claimantInfo?.basicInfo

        aListItemViews.push(
          <a
            className="dropdown-item"
            style={
              activeClaimantIndex === iIndex ? activeDropdownItemStyle : null
            }
            onClick={setActiveClaimant.bind(this, iIndex)}
            key={iIndex}
          >
            {basicInfo?.firstName + ' ' + basicInfo?.lastName}
          </a>,
        )
      }
    })

    return (
      <div
        className="dropdown-menu inc px-3 shadow-sm"
        style={{ right: '-80px' }}
      >
        <span className="dropdown-item-text border-bottom mb-2 pl-3 font-weight-bold">
          Select a claimant to see details
        </span>
        {aListItemViews}
        <span className="dropdown-item-text mb-2 pl-3 text-primery">
          <small> * All claimants related to incident {incidentId}</small>
        </span>
      </div>
    )
  }
  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className={'sidebar-sec'}>
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Date of Claim"
                data-original-title="Date of Claim"
              >
                <div className="p-1 bg-secondary text-light">
                  {/* {dateOfClaimMonth.substring(0, 3)} */}
                  {data?.dateOfClaim ? moment(data?.dateOfClaim).format('MMM') : moment(data?.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {' '}
                  {data?.dateOfClaim ? moment(data?.dateOfClaim).format('YYYY') : moment(data?.created).format('YYYY')}
                  {/* {new Date(data?.dateOfClaim).getDate()} */}
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.claimId}</div>
                {clientInfo?.companyName || 'N/A'}
              </span>
              <p className="mt-3 mb-2">Related incidents & claimants</p>
              <div className={'d-flex justify-content-start'}>
                <span className={'dropdown inc-hover px-2'}>
                  <div className={'badge badge-pill bg-inc'}>{incidentId}</div>
                  {getIncidentDropdownView()}
                </span>
                <span className={'dropdown inc-hover px-2'}>
                  <div className={'badge badge-pill bg-info1'}>
                    {incident &&
                      incident?.firstName &&
                      incident?.lastName &&
                      incident?.firstName + ' ' + incident?.lastName}
                  </div>
                  {data?.incidents?.length > 0 && getClaimantDropdownView()}
                </span>
              </div>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <a
                      className="text-dark"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.829"
                          height="17.828"
                          viewBox="0 0 17.829 17.828"
                        >
                          <g
                            id="Group_9756"
                            data-name="Group 9756"
                            transform="translate(-4013.659 13.151)"
                          >
                            <path
                              id="path1419"
                              d="M-1479.2-17.087l15-15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              id="path1421"
                              d="M-1479.2-32.087l15,15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li>
              </nav>
            </div>
          </div>
          <div className="pull-right px-3 pb-2">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}

            {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
            )}

            {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            )}
          </div>
        </div>
        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div
                className="accordion"
                id="faq"
                style={{ paddingBottom: '20px' }}
              >
                <div className="card">
                  <div className="card-header1" id="faqhead1">
                    <a
                      href="#"
                      className="btn btn-header-link"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Claim Information
                    </a>
                  </div>

                  <div
                    id="faq1"
                    className="collapse show pt-3"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Status
                          <span
                            data-testid="claimStatus"
                            className={`text-${(claimant?.status || data.claimStatus) === 'Open' ? 'success' : 'danger'
                              }`}
                          >
                            {claimant?.status || data.claimStatus || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Priority
                          <span className="text-warning" data-testid="priority">
                            {claimant?.priority || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Date of Loss
                          <span data-testid="dateOfLoss">
                            {dateOfLoss}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Time of claim
                          <span>{claimant?.timeOfClaim || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Number
                          <span>{claimNumber || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Coverage Type
                          <span>{claimant?.coverageType || incident?.coverageType || 'N/A'}</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          Policy Details
                          <span>{claimant?.policyDetails?.policyName || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Severity
                          <span>{claimant?.description?.severity || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Start Date
                          <span data-testid="startDate">
                            {claimant?.startDate
                              ? moment(claimant?.startDate).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          End Date
                          <span data-testid="endDate">
                            {claimant?.endDate
                              ? moment(claimant?.endDate).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Close Date
                          <span data-testid="closeDate">
                            {claimant?.closeDate
                              ? moment(claimant?.closeDate).format('MM/DD/YYYY')
                              : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Discrepancy
                          <span>{claimant?.description?.discrepancy || claimant?.description?.allegation || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Outcome
                          <span>{claimant?.description?.outcome || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Last changed date
                          <span>
                            {data?.modified ? moment(data?.modified).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(claimant?.notes || {})?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead2">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Claim Notes
                      </a>
                    </div>

                    <div
                      id="faq2"
                      className="collapse pt-3"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      <div className=" ">
                        <div className="row">
                          <div className="col-md-12">
                            <label className="incident-text">
                              Claim Decline Reasion
                              <span>{claimant?.notes.claimDeclineReason || 'N/A'}</span>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label className="incident-text">
                              Litigation Details
                              <span>{claimant?.notes?.litigationDetails || 'N/A'}</span>
                            </label>
                            <label className="incident-text">
                              Litigation Status
                              <span>{claimant?.notes?.litigationStatus || 'N/A'}</span>
                            </label>
                          </div>
                          {claimant?.notes?.litigationStatus === 'yes' && (
                            <div className="col-md-12">
                              <label className="incident-text">
                                Litigation Attorney
                                <span>{claimant?.notes?.litigationAttorney?.name || 'N/A'}</span>
                              </label>
                              <label className="incident-text">
                                Litigation Attorney Email
                                <span>
                                  {claimant?.notes?.litigationAttorney?.email || 'N/A'}
                                </span>
                              </label>
                              <label className="incident-text">
                                Litigation Attorney Contact Number
                                <span>
                                  {claimant?.notes?.litigationAttorney?.contactNumber || 'N/A'}
                                </span>
                              </label>
                            </div>
                          )}
                          <div className="col-md-12">
                            <label className="incident-text">
                              Settlement Offered
                              <span>{claimant?.notes?.settlementOffered || 'N/A'}</span>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label className="incident-text">
                              Claim Adjuster's Remarks
                              <span>
                                {claimant?.notes?.claimAdjustorRemarks || 'N/A'}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card">
                  <div className="card-header1" id="faqhead3">
                    <a
                      href="#"
                      className="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      Claim KPI
                    </a>
                  </div>

                  <div
                    id="faq3"
                    className="collapse pt-3"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <label className="incident-text">
                          Deductible
                          <span>{claimant?.deductible || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Open Since
                          <span>{claimant?.openSince ? moment(claimant?.openSince).format('MM/DD/YYYY') : 'N/A'}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {claimant?.financials?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead3">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Claim Financials
                      </a>
                    </div>

                    <div
                      id="faq4"
                      className="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      {claimant?.financials?.map((financials) => (
                        <div className="" key={financials._id}>
                          <div className="parties bg-white p-3 mb-3 mt-3 mx-2 claim-taken-view">
                            <div className="d-flex justify-content-between">
                              <h6 className="text-primery weekly">
                                {' '}
                                $ {financials?.amount ? addCommaTableField(financials?.amount) : 'N/A'}
                              </h6>
                            </div>

                            <div className="row mt-3">
                              <div className="weekly col-md-4">
                                <small>As of Date:</small>
                                {financials?.transactionDate ? moment(financials?.transactionDate).format('MM/DD/YYYY') : 'N/A'}
                              </div>

                              <div className="weekly col-md-8">
                                <small>Transaction Id:</small>
                                {financials?.transactionId || 'N/A'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
