/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Formik, Form } from 'formik'
import TextInput from './form/TextInput'
import { REVENUE_VALIDATION_SCHEMA } from '../utils/validation'
import { addComma } from '../pages/incident/utils'

function AddRevenueModalBtn(props) {
  const ref = useRef()
  const { buttonLabel } = props
  const [modal, setModal] = useState(false)
  const init = () => {
    return { year: '', revenue: '' }
  }
  const toggle = () => setModal(!modal)

  const handleSubmitForm = () => {
    props.modalClose(ref.current?.values || {})
    toggle()
    ref.current.resetForm()
  }

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      initialValues={{ ...init(), ...props.values }}
      validationSchema={REVENUE_VALIDATION_SCHEMA}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <>
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
              id="exampleModalLabel"
              data-toggle="modal"
              data-target="#addrevenue"
              onClick={toggle}
            >
              {buttonLabel}
            </button>
            <Modal
              isOpen={modal}
              backdrop="static"
              toggle={toggle}
              className={
                'modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable'
              }
            >
              <div className={'modal-header justify-content-center'}>
                <h5
                  className={'modal-title text-primary '}
                  id="modal-basic-title"
                >
                  Add Revenue
                </h5>
              </div>
              <ModalBody className="p-0" id={'smodaltab'}>
                <ul
                  className="nav nav-pills bg-light d-flex justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link active mt-0 font-weight-normal"
                      id="Person-tab"
                      data-toggle="pill"
                      href="#Person"
                      role="tab"
                      aria-controls="pills-Person"
                      aria-selected="true"
                    >
                      Revenue Details
                    </a>
                  </li>
                </ul>
                <div>
                  <div className={'px-5 mt-3'}>
                    <Form>
                      <div className="form-group mb-0">
                        <div className="input-group mt-5 pb-4">
                          <div className="row">
                            <div className="col-md-6">
                              <TextInput
                                label="Year"
                                name="year"
                                type="number"
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="form-group input-dollar">
                                <label>Revenue</label>
                                <span className="pr-4">$</span>
                                <input
                                  className="form-control dollar-text"
                                  label="Revenue"
                                  name="revenue"
                                  type="text"
                                  value={
                                    values?.revenue
                                      ? addComma(values?.revenue)
                                      : ''
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value
                                    const onlyNums = value.replaceAll(',', '')
                                    if (!isNaN(Number(onlyNums))) {
                                      setFieldValue('revenue', onlyNums)
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="modal-footer" style={{ background: '#F4F7FE' }}>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary add-btn"
                  >
                    Add
                  </button>
                  <button
                    onClick={toggle}
                    type="button"
                    className="btn btn-secondary bg-white text-dark cls-btn"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </>
      )}
    </Formik>
  )
}

export default AddRevenueModalBtn
