/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/common/Table'
import { deleteBlukEmployee, deleteEmployee, getEmployees } from '../../../services/employeeService'
import ActionWidget from '../../../components/common/ActionWidget'
import { getPagedData } from '../../../services/helperService'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../../actions'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import { toast } from 'react-toastify'
import { addCommaTableField } from '../../incident/utils'
import moment from 'moment'
import Loader from '../../../components/common/Loader'

let employees = []
let itemsCount = 0

function EmployeeList({
  filters,
  searchQuery,
  handleEdit,
  handleView,
  context,
  checkedRecords,
  setShowDeleteButton,
  selectFilters,
  setFilterCount,
  handleElementCheckboxClicked,
  dateRangeFilter,
  openBulkDel,
  closeBulkDel,
  handleDechecked
}) {
  const dispatch = useDispatch()
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)
  const [employee, setEmployee] = useState({})
  const [deleteOne , setDeleteOne] = useState(false);
  const [isLoading , setIsLoading] = useState(true);

  const columns = [
    {
      path: 'employeeId',
      label: ' Employee Id ',
      minWidth: '220px',
      content: (employee) => (
        <>
          <a>{employee.employeeId}</a>
          <ActionWidget
            module="EMPLOYEE"
            onEdit={() => handleEdit(employee)}
            onView={() => handleView(employee)}
            onDelete={() => {
              setEmployee(employee);
              setDeleteOne(true);
            }}
          />
        </>
      ),
    },
    { path: 'firstName', label: ' First Name ', type: 'string' },
    { path: 'lastName', label: ' Last Name ', type: 'string' },
    { path: 'status', label: 'Employee Status', type: 'string' },
    { path: 'hireDate', label: 'Hire Date', type: 'date' },
    {
      path: 'terminationDetails.terminatedOn',
      label: 'Termination Date',
      type: 'date',
    },
    { path: 'rehireDate', label: 'Rehire Date', type: 'date' },
    {
      path: 'driverDetails.driverLicense',
      label: 'Driving License Number',
      type: 'string',
    },
    {
      path: 'liscense.expiryDate',
      label: 'License Expiration Date',
      type: 'date',
    },
    { path: 'liscense.status', label: 'License Status', type: 'string' },
    { path: 'mvr', label: 'MVR', type: 'string' },
    {
      path: 'annualSalary',
      label: 'Annual Salary',
      content: (employee) => (
        <>
          <span>
            {employee.annualSalary
              ? addCommaTableField(employee.annualSalary)
              : 'N/A'}
          </span>
        </>
      ),
    },
    // { path: 'officePhone', label: ' Phone (Work) ', type: 'phone' },
    // { path: 'personalPhone', label: ' Phone (Personal) ', type: 'phone' },
    // { path: 'email', label: ' Email ', type: 'email' },
    // { path: 'maritalStatus', label: ' Martial Status ' },
    // { path: 'dateOfJoining', label: ' Date of Joining ', type: 'date' },
    // {
    //   path: 'policyDetails.policyName',
    //   label: ' WC Details ',
    // },
  ]

  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  
  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedClientId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {
    if (openBulkDel) {
      setEmployee({ employee: { firstName: 'all ', lastName: 'employee' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    if (
      selectedTabId === 'employee' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEdit(viewData)
    }
    if (selectedTabId === 'employee' && viewAction === 'refresh') {
      setIsLoading(true)
      fetchList()
      dispatch(handleTabIdUpdated('employee'))
    }
  }, [viewAction, viewData, selectedTabId])

  const handleDelete = async (item) => {
    await deleteEmployee(item._id, item)
    setIsLoading(true)
    await fetchList()
  }

  const hendleBulkDelete = async (ids) => {    
    await deleteBlukEmployee(ids)
    setIsLoading(true)
    await fetchList()
  }

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    employees = result
    return result.length > 0
  }

  const fetchList = async () => {
    const { data } = await getEmployees()
    let employeeData = data.data.map((employee)=> {
      employee.fullName = `${employee.firstName} ${employee.lastName}`
      return employee
    })
    setData(employeeData)
    if (data.data && data.data.length) {

      if (sideNavDataContext !== 'searchView') {
        dispatch(
          handleSideNavDataUpdated({ data: data.data[0], module: 'employee' }),
        )
        dispatch(handleTabIdUpdated('employee'))
      }
      const mapFilterCount = {
        MARRIED: 0,
        UNMARRIED: 0,
        isDriver: 0,
        active: 0,
        terminated: 0,
      }
      data.data.forEach(({ maritalStatus, isDriver, status }) => {
        mapFilterCount[maritalStatus] += 1
        if (isDriver) {
          mapFilterCount['isDriver'] += 1
        }
        mapFilterCount[status.toLowerCase()] += 1
      })
      setFilterCount(mapFilterCount)
    }
    setIsLoading(false)
  }
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handleDeleteModal = async () => {
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await deleteBlukEmployee(params)
        setShowDeleteButton(false)
        closeBulkDel()
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      } else {
        const { data } = await deleteEmployee(employee._id , employee)
        setDeleteOne(false);
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      }
    } catch (error) {
      console.log('error')
    }
    setEmployee({})
    await fetchList()
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData()}
      <Table
        columns={columns}
        data={employees.filter((emp) => {
          let isDateRangeValid = false
          let isDateRangeSelected = Object.values(dateRangeFilter).every(
            (x) => {
              return x.start === null
            },
          )

          if (dateRangeFilter && !isDateRangeSelected) {
            Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
              if (start !== null && emp[key] !== '') {
                if (
                  emp[key] > start.toISOString() &&
                  emp[key] < end.toISOString()
                ) {
                  isDateRangeValid = true
                } else {
                  isDateRangeValid = false
                }
              }
            })
          }

          const {
            mvr: selectedMvr = '',
            maritalStatus: selectedMaritalStatus = '',
          } = selectFilters
          let isMvr = false,
            isMaritalStatus = false

          if (selectedMvr.length && selectedMvr === emp.mvr) {
            isMvr = true
          }

          if (
            selectedMaritalStatus.length &&
            selectedMaritalStatus === emp.maritalStatus
          ) {
            isMaritalStatus = true
          }

          let isStatus = false,
            isDriver = false

          if (
            (filters['active'].isAvailable && emp.status === 'ACTIVE') ||
            (filters['terminated'].isAvailable && emp.status === 'TERMINATED')
          ) {
            isStatus = true
          }
          if (filters['isDriver'].isAvailable && emp.isDriver) {
            isDriver = true
          }

          return (
            !(
              (filters['active'].isAvailable ||
                filters['terminated'].isAvailable) ^ isStatus
            ) &&
            !(filters['isDriver'].isAvailable ^ isDriver) &&
            !(!!selectedMvr.length ^ isMvr) &&
            !(!!selectedMaritalStatus.length ^ isMaritalStatus) &&
            !(!isDateRangeSelected ^ isDateRangeValid)
          )
        })}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked.bind(this, employees)}
      />
      <ConfirmationModal
        name={checkedRecords.length > 0 ? `Employees` :employee?.firstName + ' ' + employee?.lastName}
        // isOpen={checkedRecords.length > 0 || deleteOne}
        isOpen={Object.keys(employee || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) } 

        onClose={() => {
          setEmployee({})
          setShowDeleteButton(false)
          closeBulkDel()
          setDeleteOne(false)
          handleDechecked()
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default EmployeeList
