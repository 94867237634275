import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/mvr`

export function getMvrs() {
  return http.get(`${apiEndpoint}/list`)
}

export function exportMvrs() {
  return http.get(`${apiEndpoint}/export-to-csv`, { responseType: 'blob' })
}

export function getMvr(id) {
  return http.get(`${apiEndpoint}/details/${id}`)
}

export function updateMvr(id, mvr) {
  return http.put(`${apiEndpoint}/update/${id}`, mvr)
}

export function saveMvr(mvr) {
  const date = new Date()
  const mvrId = `MVR-${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...mvr, mvrId })
}

export function deleteMvr(id, mvr) {
  return http.put(`${apiEndpoint}/delete/${id}`, mvr)
}

export function deleteBlukMvr(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}

export function updateMVRStatus(id, status) {
  return http.patch(`${apiEndpoint}/update-status/${id}`, {
    approvalStatus: status,
  })
}
