import classNames from 'classnames'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { getObjectValueByStringKey } from '../../pages/incident/utils'
//   let newString = string.match(/[0-9]{0,14}/g)

//   if (newString === null) {
//     return ''
//   }

//   // Join parts returned from RegEx match
//   newString = newString.join('')

//   // Start number with "+"
//   newString = '+' + newString

//   // Limit length to 15 characters
//   newString = newString.substring(0, 15)

//   return newString
// }
function TextInput({
  label,
  name,
  isMoney = false,
  isPhone = false,
  showErrorEvenWhenUntouched = false,
  onPhoneChange = (phone) => {
    console.log({ phone })
  },
  noBottomMargin,
  withAsterisk,
  changeOnUndefined,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false)
  const { errors } = useFormikContext()

  return (
    <div
      className={classNames('form-group', {
        'input-dollar': isMoney,
        'mb-0': noBottomMargin,
      })}
    >
      <label htmlFor={`input-${name}`} data-testid={`label-${name}`}>
        {label}
        {withAsterisk && <span className="text-danger"> *</span>}
      </label>
      {isMoney && <span className="pr-4">$</span>}

      {rest?.type === 'password' ? (
        <div className="form-control password">
          <Field
            id={`input-${name}`}
            data-testid={`input-${name}`}
            {...rest}
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            name={name}
            placeholder={label ?? rest.placeholder}
            style={{
              width: '97%',
              border: 'none',
              outline: 'none',
              background: 'none',
            }}
            component="input"
          />
          <i
            className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
            style={{ marginRight: -30, cursor: 'pointer' }}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
      ) : isPhone ? (
        <PhoneInput
          data-testid={`input-${name}`}
          id={`input-${name}`}
          {...rest}
          // displayInitialValueAsLocalNumber={true}
          value={rest.value ? rest.value.toString() : ''}
          // value={formatPhoneNumber(rest.value ? rest.value.toString() : '')}
          name={name}
          // defaultCountry="US"
          country="US"
          international={false}
          className={'form-control'}
          placeholder={label}
          // maxLength={14}
          // limitMaxLength={true}
          onChange={(phone) => {
            if (phone || changeOnUndefined) {
              onPhoneChange(phone)
            }
          }}
        />
      ) : (
        <>
          <Field
            id={`input-${name}`}
            data-testid={`input-${name}`}
            {...rest}
            autoComplete="off"
            placeholder={label ?? rest.placeholder}
            className={classNames('form-control', {
              'dollar-text': isMoney,
            })}
            name={name}
            // type={rest?.type === 'number' ? 'number' : 'text'}
            component="input"
          />
        </>
      )}
      {showErrorEvenWhenUntouched ? (
        <div data-testid={`error-message-${name}`} className="text-danger">
          {getObjectValueByStringKey(errors, name)}
        </div>
      ) : (
        <ErrorMessage
          data-testid={`error-message-${name}`}
          className="text-danger"
          name={name}
          component="div"
        />
      )}
    </div>
  )
}

export default TextInput
