import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment-timezone'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import AddAttachmentMVR from '../../components/AddAttachmentMvr'
import FooterPublicIncident from '../../components/Incidents/FooterPublicIncident'
import PublicIncidentAcknowledgement from '../../components/Incidents/PublicIncidentAcknowledgement'
import { PUBLIC_INCIDENT_VALIDATION_SCHEMA } from '../../utils/validation'

import SignaturePad from '../../components/common/SignaturePad'
import TextInput from '../../components/form/TextInput'
import PublicIncidentHeader from '../../components/Incidents/PublicIncidentHeader'
import { savePublicIncident } from '../../services/publicIncidentService'
import {
  initialPublicIncidentValue,
  initialVehicleDetail,
} from '../../utils/publicIncident'
import IncidentDetailsForm from './IncidentDetailsForm'
import SupervisorDetailForm from './SupervisorDetailForm'
import UserDetailsForm from './UserDetailsForm'
import VehicleDetailsForm from './VehicleDetailsForm'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'

export default function AddNewIncident() {
  const mounted = useRef(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const location = useLocation()
  const ref = useRef()
  const loginFormData = location.state
  const [ipAddress, setIpAddress] = useState('')
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [files, setFiles] = useState([])
  const [initialData, setInitialData] = useState(initialPublicIncidentValue)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [signImg, setSignImg] = useState('')
  const [witnesses, setWitnesses] = useState([{ name: '', contactNumber: '' }])

  const handleAddVehicleDetail = () => {
    ref?.current?.setFieldValue(
      'vehiclesInvolved',
      ref?.current?.values?.vehiclesInvolved?.concat(initialVehicleDetail),
    )
  }

  useEffect(() => {
    if (loginFormData && loginFormData.accessCode) {
      //set logicFormData in initialData
      setInitialData({
        ...initialData,
        userDetails: {
          ...initialData.userDetails,
          firstName: loginFormData?.fname,
          lastName: loginFormData?.lname,
          email: loginFormData?.email,
        },
      })
    }
  }, [loginFormData])

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, [])

  useEffect(() => {
    // fetch users IP address
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        if(mounted.current) {
          setIpAddress(data.ip)
        }
      })
      .catch((error) => console.error(error))
  }, [])

  const handleSubmit = async (values) => {
    let humanParts = Object.values(values?.humanParts || {})
    let formData = new FormData()
    // files.forEach((file) => {
    //   formData.append('files', file)
    // })
    let vehicleDetails = values?.vehiclesInvolved.map((vehicle) => {
      if (vehicle?.vehicleDetails?.vehicleType === 'company') {
        vehicle.vehicleDetails.vehicleDamageParts = Object.values(
          vehicle?.vehicleDetails?.vanParts || {},
        )
      } else {
        vehicle.vehicleDetails.vehicleDamageParts = Object.values(
          vehicle?.vehicleDetails?.sedanParts || {},
        )
      }
      return vehicle
    })

    values?.incidentDetails?.dateOfIncident &&
      (values.incidentDetails.dateOfIncident = moment(values.incidentDetails?.dateOfIncident).format(NO_TIMEZONE_DATE_FORMAT));

    values?.incidentDetails?.medicalDeclinationDetails?.dateOfIncident &&
      (values.incidentDetails.medicalDeclinationDetails.dateOfIncident = moment(values.incidentDetails.medicalDeclinationDetails.dateOfIncident).format(NO_TIMEZONE_DATE_FORMAT));

    values?.medicalDeclinationDetails?.dateOfIncident &&
      (values.medicalDeclinationDetails.dateOfIncident = moment(values.medicalDeclinationDetails?.dateOfIncident).format(NO_TIMEZONE_DATE_FORMAT));

    values?.supervisorDetails?.notifiedDate &&
      (values.supervisorDetails.notifiedDate = moment(values.supervisorDetails?.notifiedDate).format(NO_TIMEZONE_DATE_FORMAT));

    values?.userDetails?.dateOfBirth &&
      (values.userDetails.dateOfBirth = moment(values?.userDetails?.dateOfBirth).format(NO_TIMEZONE_DATE_FORMAT));

    let publicIncidentPayload = {
      ...values,
      timezone: moment.tz.guess(),
      vehiclesInvolved:
        values?.vehicleNature === 'vehicular' ? [...vehicleDetails] : [],
      humanParts: [...humanParts],
      ipAddress: ipAddress,
      created: moment().format(NO_TIMEZONE_DATE_FORMAT)
    }

    formData.append('publicIncidentData', JSON.stringify(publicIncidentPayload))
    setIsSubmitting(true)
    await savePublicIncident(formData).finally(() => {
      setIsSubmitting(false)
    })
    toast.success('Incident details Sent successfully.')
    resetForm()
  }

  const resetForm = () => {
    ref?.current?.resetForm()
    setAttachments([])
    setFiles([])
    setSignImg('')
    setWitnesses([{ name: '', contactNumber: '' }])
  }

  return (
    <>
      <PublicIncidentHeader />
      <section className="public-form-wrapper">
        <div className="container-fluid modal2">
          <div className="row">
            <div className="col-md-12">
              <div className="my-5 py-5">
                <div
                  className="cab-form customized-scrollbar"
                  style={{
                    overflowY: 'auto',
                    height: '80vh',
                    overflowX: 'hidden',
                  }}
                >
                  <Formik
                    innerRef={ref}
                    enableReinitialize
                    initialValues={{ ...initialData }}
                    validationSchema={PUBLIC_INCIDENT_VALIDATION_SCHEMA}
                    onSubmit={(values) => {
                      handleSubmit(values)
                    }}
                  >
                    {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
                      <Form>
                        <div className="text-center mb-2">
                          <h1>Add New Incident</h1>
                        </div>
                        <div className="border-bottom pb-3"></div>
                        <div className="mt-4 mb-3 ">
                          <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <span>What is the Nature of Incident?</span>
                            <div
                              className="btn-group btn-group-toggle border btn-tgl ml-2"
                              data-toggle="buttons"
                            >
                              <label className="btn form-check form-check-inline">
                                <Field
                                  data-testid="radio-vehicular"
                                  className="form-check-input"
                                  type="radio"
                                  name="vehicleNature"
                                  checked={
                                    values?.vehicleNature === 'vehicular'
                                  }
                                  onChange={() => {
                                    setFieldValue('vehicleNature', 'vehicular')
                                  }}
                                  value={'vehicular'}
                                />
                                Vehicular
                              </label>
                              <label className="btn form-check form-check-inline">
                                <Field
                                  data-testid="radio-non-vehicular"
                                  className="form-check-input"
                                  type="radio"
                                  name="vehicleNature"
                                  checked={
                                    values?.vehicleNature === 'non-vehicular'
                                  }
                                  onChange={() => {
                                    setFieldValue(
                                      'vehicleNature',
                                      'non-vehicular',
                                    )
                                  }}
                                  value={'non-vehicular'}
                                />
                                Non-Vehicular
                              </label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-row">
                            <div className="col-md-12">
                              <TextInput
                                withAsterisk
                                label="Company Name"
                                name="clientId"
                                placeholder="Company Name"
                                value={values?.clientId || ''}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6 ">
                              <label>Today&apos;s Date</label>
                              <input
                                className="form-control"
                                type="text"
                                value={moment().format('MM/DD/YYYY')}
                                readOnly
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <div>
                                <label>Shift Start Time ({moment.tz(moment.tz.guess()).zoneAbbr()})</label>

                                <TimePicker
                                  use12Hours
                                  className="antDOverride publicincident"
                                  name="shiftStartTime"
                                  onChange={(value) => {
                                    setFieldValue(
                                      'shiftStartTime',
                                      value.format('HH:mm A'),
                                    )
                                  }}
                                  showSecond={false}
                                  format="hh:mm A"
                                  value={moment(
                                    values?.shiftStartTime || '00:00',
                                    'HH:mm A',
                                  )}
                                  defaultValue={moment('00:00', 'HH:mm A')}
                                />
                              </div>
                            </div>
                          </div>
                          <UserDetailsForm
                            values={values}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        </div>

                        <SupervisorDetailForm
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />

                        <IncidentDetailsForm
                          witnesses={witnesses}
                          setWitnesses={setWitnesses}
                        />

                        {values?.vehicleNature === 'vehicular' && (
                          <>
                            <div className="d-flex justify-content-between align-items-center border-bottom h6 py-2 mb-4 ">
                              <span className="text-primery font-weight-bold">
                                Vehicle Details
                              </span>

                              <a
                                onClick={handleAddVehicleDetail}
                                className="btn btn-primary"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    id="Path_16690"
                                    data-name="Path 16690"
                                    d="M-16.32-7.495h-6.5V-14.07h-3v6.575h-6.5v2.886h6.5V1.93h3V-4.609h6.5Z"
                                    transform="translate(32.32 14.07)"
                                    fill="#fff"
                                  />
                                </svg>
                                <span className="pl-2"> Add New</span>
                              </a>
                            </div>

                            <VehicleDetailsForm />
                          </>
                        )}
                        <div className="row mt-3">
                          <div className="form-group col-md-6 addIncidentUploadFile">
                            <label
                              className="d-block text-primery font-weight-bold"
                              htmlFor="incidentUpload"
                            >
                              Attachments {values.vehicleNature === "vehicular" && (<span className='text-danger'>*</span>)}
                            </label>
                            <div className="row m-0"></div>
                            <button
                              type="button"
                              data-testid="button-add-attachment"
                              className="btn btn-outline-secondary side-btn mr-3"
                              data-toggle="modal"
                              data-target="#attachuplaods"
                              onClick={() => setOpenAttachmentModal(true)}
                            >
                              <i
                                className="fas fa-paperclip"
                                aria-hidden="true"
                              ></i>
                              <span className="file-up">Upload File</span>
                            </button>
                            {openAttachmentModal && (
                              <AddAttachmentMVR
                                totalAttachments={attachments.length}
                                module="public-incident"
                                uid={values?.publicIncidentId}
                                onClose={() => setOpenAttachmentModal(false)}
                                onAdd={(atts, fileObj) => {
                                  const newAttachments = [
                                    ...attachments,
                                    ...atts,
                                  ]
                                  setAttachments(newAttachments)
                                  const newfiles = [...files, ...fileObj]
                                  setFiles(newfiles)
                                  setFieldValue(
                                    'attachments',
                                    newAttachments.map((c) => c._id),
                                  )
                                  setOpenAttachmentModal(false)
                                }}
                                isOpen={openAttachmentModal}
                              />
                            )}
                          </div>
                          <div className="form-group col-md-6"></div>

                          <ErrorMessage
                            className="text-danger mt-3 ml-3"
                            name={`attachments`}
                            component="div"
                          />
                          <div className="form-group col-md-12 mt-5">
                            {attachments.map((att, i) => (
                              <div key={i} className="d-flex w-100">
                                <p style={{ flexGrow: 1 }}>{att.fileName}</p>
                                <i
                                  onClick={() => {
                                    setAttachments(
                                      attachments.filter(
                                        (c) => att.fileName !== c.fileName,
                                      ),
                                    )
                                    setFieldValue(
                                      'attachments',
                                      attachments.filter(
                                        (c) => att.fileName !== c.fileName,
                                      ),
                                    )
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  className="fa fa-trash"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="form-group col-md-12 addIncidentUploadFile">
                          <label
                            className="d-block text-primery font-weight-bold"
                            htmlFor="incidentUpload"
                          >
                            Signature <span className='text-danger'>*</span>
                          </label>
                          <div className="row m-0"></div>
                          <button
                            type="button"
                            className="btn btn-outline-secondary side-btn mr-3 upload-sign"
                            onClick={() => {
                              setDialogOpen(true)
                            }}
                          >
                            <i
                              className="fas fa-paperclip"
                              aria-hidden="true"
                            ></i>
                            <span>Upload Signature</span>
                          </button>
                          <ErrorMessage
                            className="text-danger"
                            name={`userDetails.userSignature`}
                            component="div"
                          />
                        </div>
                        {signImg && (
                          <div className="form-group col-md-12 mt-5">
                            <div className="show-image w-100">
                              <img src={signImg} />
                              <i
                                onClick={() => {
                                  setSignImg('')
                                  setFieldValue('userDetails.userSignature', '')
                                }}
                                style={{ cursor: 'pointer' }}
                                className="fa fa-trash"
                              />
                            </div>
                          </div>
                        )}
                        <PublicIncidentAcknowledgement />
                        <div className="d-flex justify-content-end pb-4">
                          <button
                            data-testid="button-submit"
                            type="submit"
                            disabled={
                              !values?.isAgree ||
                              values?.supervisorDetails.isNotify === 'false' || isSubmitting
                            }
                            className="btn btn-primary px-4 border-radius-12"
                          >
                            {
                              isSubmitting && <span className="spinner-border spinner-border-sm mr-2" aria-hidden="true"></span>
                            }
                            <span role="status">Submit</span>
                          </button>
                        </div>
                        {
                          Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (<div className='d-flex justify-content-end'>
                            <p className='text-danger'>Please fill all required fields above*</p>
                          </div>)
                        }
                        {dialogOpen && (
                          <SignaturePad
                            setDialogOpen={setDialogOpen}
                            setSignImg={setSignImg}
                            signImg={signImg}
                            uid={values?.publicIncidentId}
                            addSignature={(id) => {
                              setFieldValue('userDetails.userSignature', id)
                            }}
                          />
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterPublicIncident />
    </>
  )
}
