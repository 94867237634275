import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'

export const ReportedViaTypes = [
  {
    value: 'Email',
    key: 'Email',
    label: 'Email',
  },
  {
    label: 'Phone',
    value: 'Phone',
    key: 'Phone',
  },
  {
    value: 'Sms',
    label: 'Sms',
    key: 'Sms',
  },
  {
    value: 'Public Form',
    key: 'Public Form',
    label: 'Public Form',
  },
  // {
  //   value: 'Guest Form',
  //   key: 'Guest Form',
  //   label: 'Guest Form',
  // },
  {
    value: 'Self',
    key: 'Self',
    label: 'Self',
  },
]

export const ClaimReportedViaTypes = [
  {
    value: 'Email',
    key: 'Email',
    label: 'Email',
  },
  {
    label: 'Phone',
    value: 'Phone',
    key: 'Phone',
  },
  {
    value: 'Sms',
    label: 'Sms',
    key: 'Sms',
  },
  {
    value: 'Public Form',
    key: 'Public Form',
    label: 'Public Form',
  },
  // {
  //   value: 'Guest Form',
  //   key: 'Guest Form',
  //   label: 'Guest Form',
  // },
  {
    value: 'Self',
    key: 'Self',
    label: 'Self',
  },
  {
    value: 'Loss Runs',
    key: 'Loss Runs',
    label: 'Loss Runs',
  },
]

export const allPolicyTypes = [
  {
    value: 'WC',
    key: 'WC',
    label: 'WC',
  },
  {
    value: 'GL',
    key: 'GL',
    label: 'GL',
  },
  {
    value: 'Auto',
    key: 'Auto',
    label: 'Auto',
  },
  {
    value: 'Property',
    key: 'Property',
    label: 'Property',
  },
]
export const allClaimStatusTypes = [
  {
    value: 'Open',
    key: 'Open',
    label: 'Open',
  },
  {
    value: 'Closed',
    key: 'Closed',
    label: 'Closed',
  },
]

export const claimTypes = [
  {
    value: 'Auto',
    key: 'AUTO',
    label: 'Auto',
  },
  {
    value: 'WC',
    key: 'WC',
    label: 'WC',
  },
  {
    value: 'ARC',
    key: 'ARC',
    label: 'ARC',
  },
  {
    value: 'MTC',
    key: 'MTC',
    label: 'MTC',
  },
  {
    value: 'GL',
    key: 'GL',
    label: 'GL',
  },
  {
    value: 'Other',
    key: 'OTHER',
    label: 'Other',
  },
]

export const allPriorityStatusTypes = [
  {
    value: 'Low',
    key: 'Low',
    label: 'Low',
  },
  {
    value: 'Medium',
    key: 'Medium',
    label: 'Medium',
  },
  {
    value: 'High',
    key: 'High',
    label: 'High',
  },
]

export const allCoverageDescription = [
  {
    value: 'Collision',
    key: 'collision',
    label: 'Collision',
  },
  {
    value: 'Bodily Injury',
    key: 'bodily_injury',
    label: 'Bodily Injury',
  },
  {
    value: 'Hit & Run',
    key: 'hit_run',
    label: 'Hit & Run',
  },
  {
    value: 'Property Damage',
    key: 'property_damage',
    label: 'Property Damage',
  },
  {
    value: 'Comprehensive',
    key: 'comprehensive',
  },
  {
    value: 'No Fault',
    key: 'no_fault',
    label: 'Comprehensive',
  },
  {
    value: 'Indemnity',
    key: 'indemnity',
    label: 'Indemnity',
  },
  {
    value: 'Others',
    key: 'others',
    label: 'Others',
  },
]

export const timezones = [
  {
    value: 'Hawaii Time - Pacific/Honolulu',
    key: 'Pacific/Honolulu',
    label: 'Hawaii Time - Pacific/Honolulu',
  },
  {
    value: 'Alaksa Time - America/Anchorage',
    key: 'America/Anchorage',
    label: 'Alaksa Time - America/Anchorage',
  },
  {
    value: 'Pacific Time - America/Los_Angeles',
    key: 'America/Los_Angeles',
    label: 'Pacific Time - America/Los_Angeles',
  },
  {
    value: 'Mountain Time - America/Denver',
    key: 'America/Denver',
    label: 'Mountain Time - America/Denver',
  },
  {
    value: 'Central Time - America/Chicago',
    key: 'America/Chicago',
    label: 'Central Time - America/Chicago',
  },
  {
    value: 'Eastern Time - America/New_York',
    key: 'America/New_York',
    label: 'Eastern Time - America/New_York',
  },
]

export const getAvailableTimezones = (timezone) => {
  const items = []
  if (timezone && timezones.findIndex((f) => f.key === timezone) === -1) {
    if (moment.tz.names().includes(timezone)) {
      const abbr = moment.tz(timezone).zoneAbbr()
      items.push({
        key: timezone,
        value: `${abbr} - ${timezone} - Unavailable`,
        label: `${abbr} - ${timezone} - Unavailable`,
        disabled: true,
      })
    } else {
      items.push({
        key: timezone,
        value: `${timezone} - Unavailable`,
        label: `${timezone} - Unavailable`,
        disabled: true,
      })
    }
  }

  items.push(...timezones)
  return items
}

export const notificationMethod = [
  {
    value: 'Call',
    key: 'Call',
  },
  {
    value: 'Email',
    key: 'Email',
  },
  {
    value: 'Text',
    key: 'Text',
  },
  {
    value: 'In Person',
    key: 'In Person',
  },
]

// pain level types array
export const painLevelTypes = [
  '0 No Pain',
  '1-3 Mild',
  '4-7 Moderate',
  '7-9 Severe',
  '10 ICU',
]

export const loadoutShift = [
  { label: 'First', key: 'First', value: 'First' },
  { label: 'Second', key: 'Second', value: 'Second' },
  { label: 'Third', key: 'Third', value: 'Third' },
  { label: 'Fourth', key: 'Fourth', value: 'Fourth' },
  { label: 'Fifth', key: 'Fifth', value: 'Fifth' },
]

export function addComma(num) {
  const decimalRegex = /.+\.([0-9])*$/

  if (decimalRegex.test(num.toString())) {
    const [number, decimal] = num.toString().split('.')
    const formattedNumber = new Intl.NumberFormat('en-US', {
      currency: 'USD',
    }).format(number)
    return `${formattedNumber}.${decimal}`
  }
  const number = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  }).format(num)
  return number
}

export function addCommaTableField(num) {
  const number = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  }).format(num)
  return number
}

export function transformPhoneNumber(rawNum = '') {
  if (rawNum) {
    const areaCodeStr = rawNum.slice(2, 5)
    const midSectionStr = rawNum.slice(5, 8)
    const lastSectionStr = rawNum.slice(8)

    return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`
  }
  return ''
}

export function convertBase64ImageToFile(base64Img) {
  // Split the base64 string into header and data parts
  const parts = base64Img.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const data = atob(parts[1])
  // Create an ArrayBuffer from the decoded data
  const arrayBuffer = new ArrayBuffer(data.length)
  const uint8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < data.length; i++) {
    uint8Array[i] = data.charCodeAt(i)
  }
  // Create a Blob from the ArrayBuffer
  const blob = new Blob([arrayBuffer], { type: contentType })
  // Create a file object from the Blob
  return new File([blob], 'image.png')
}

export const vehicleTypeOptions = [
  {
    label: 'Company',
    value: 'company',
  },
  {
    label: '3rd Party',
    value: 'thirdParty',
  },
]

export const employeeStatusOptions = [
  {
    value: 'Active',
    key: 'ACTIVE',
  },
  {
    value: 'Inactive',
    key: 'INACTIVE',
  },
  {
    value: 'Leave',
    key: 'LEAVE',
  },
  {
    value: 'Terminated',
    key: 'TERMINATED',
  },
]

export const yesNoOptions = [
  {
    value: 'Yes',
    key: 'Yes',
  },
  {
    value: 'No',
    key: 'No',
  },
]

export const outcomeOptions = [
  {
    value: 'In Process',
    key: 'In Process',
  },
  {
    value: 'Unresolved',
    key: 'Unresolved',
  },
  {
    value: 'Resolved',
    key: 'Resolved',
  },
  {
    value: 'Pending Closure',
    key: 'Pending Closure',
  },
  {
    value: 'Closed',
    key: 'Closed',
  },
]

export const getObjectValueByStringKey = (obj, keyString) => {
  if (!keyString || !obj) {
    return undefined
  }

  const keys = keyString.split('.')
  let result = obj

  for (const key of keys) {
    if (result && result.hasOwnProperty(key)) {
      result = result[key]
    } else {
      return undefined
    }
  }

  return result
}

export const vehicleDamageOptions = [
  { label: 'Driver side Panel', value: 'driver-side-panel' },
  { label: 'Passenger side Panel', value: 'passenger-side-panel' },
  { label: 'Passenger side Panel Glass', value: 'passenger-side-panel-glass' },
  { label: 'Tail Gate', value: 'tailgate' },
  {
    label: 'Driver side Backlight Upper side',
    value: 'driver-side-backlight-upperside',
  },
  {
    label: 'Passenger side Backlight Upper side',
    value: 'passenger-side-backlight-upperside',
  },
  { label: 'Driver side Front Door', value: 'driver-side-front-door' },
  { label: 'Hood', value: 'hood' },
  { label: 'Front Glass', value: 'front-glass' },
  { label: 'Roof', value: 'roof' },
  {
    label: 'Driver side Front Door Mirror',
    value: 'driver-side-front-door-mirror',
  },
  { label: 'Passenger side Head Light', value: 'passenger-side-head-light' },
  { label: 'Back Mirror', value: 'back-mirror' },
  { label: 'Passenger side Door Mirror', value: 'passenger-side-door-mirror' },
  { label: 'Driver side panel glass', value: 'driver-side-panel-glass' },
  { label: 'Driver Side Mirror', value: 'driver-side-mirror' },
  { label: 'Passenger Side Mirror', value: 'passenger-side-mirror' },
  { label: 'Driver side Head Light', value: 'driver-side-head-light' },
  { label: 'Driver side Back light', value: 'driver-side-back-light' },
  { label: 'Passenger side Back light', value: 'passenger-side-back-light' },
  { label: 'Front Bumper', value: 'front-bumper' },
  { label: 'Driver side front fender', value: 'driver-side-front-fender' },
  {
    label: 'Passenger side front fender',
    value: 'passenger-side-front-fender',
  },
  { label: 'Front Upper Panel', value: 'front-upper-panel' },
]

export const convertDateToStringIncidentForm = (
  values,
  directClaim = false,
) => {
  if (directClaim) {
    values?.incident?.openSince &&
      (values.incident.openSince = moment(
        values.incident.openSince,
      ).format(NO_TIMEZONE_DATE_FORMAT))


    values?.incident?.incidentDetails?.dateOfIncident &&
      (values.incident.incidentDetails.dateOfIncident = moment(
        values?.incident?.incidentDetails?.dateOfIncident,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.incident?.dateReportedToDSP &&
      (values.incident.dateReportedToDSP = moment(
        values?.incident?.dateReportedToDSP,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    if (Array.isArray(values?.incident?.incidentNarrative)) {
      values.incident.incidentNarrative = values?.incident?.incidentNarrative?.map((e) => ({ ...e, narrativeDate: moment(e.narrativeDate).format(NO_TIMEZONE_DATE_FORMAT) }))
    }

    if (Array.isArray(values?.partiesInvolved)) {
      values.partiesInvolved = values.partiesInvolved.map(e => ({ ...e, vehicleDetails: e.vehicleDetails ? { ...e.vehicleDetails, year: e.vehicleDetails.year ? moment(e.vehicleDetails.year).format(NO_TIMEZONE_DATE_FORMAT) : undefined } : undefined }))
    }

    values?.incident?.startDate &&
      (values.incident.startDate = moment(values?.incident?.startDate).format(
        NO_TIMEZONE_DATE_FORMAT,
      ))

    values?.incident?.endDate &&
      (values.incident.endDate = moment(values?.incident?.endDate).format(
        NO_TIMEZONE_DATE_FORMAT,
      ))

    values?.incident?.closeDate &&
      (values.incident.closeDate = moment(values?.incident?.closeDate).format(
        NO_TIMEZONE_DATE_FORMAT,
      ))


    values?.incident?.amountPaidTillDate?.date &&
      (values.incident.amountPaidTillDate.date = moment(
        values?.incident?.amountPaidTillDate?.date,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.incident?.amountIncurredTillDate?.date &&
      (values.incident.amountIncurredTillDate.date = moment(
        values?.incident?.amountIncurredTillDate?.date,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.incident?.returnToWorkDate &&
      (values.incident.returnToWorkDate = moment(values?.incident?.returnToWorkDate).format(
        NO_TIMEZONE_DATE_FORMAT,
      ))

    values?.incident?.userDetails?.dateOfBirth &&
      (values.incident.userDetails.dateOfBirth = moment(
        values?.incident?.userDetails?.dateOfBirth,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.incident?.userDetails?.hireDate &&
      (values.incident.userDetails.hireDate = moment(
        values?.incident?.userDetails?.hireDate,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.incident?.supervisorDetails?.notifiedDate &&
      (values.incident.supervisorDetails.notifiedDate = moment(
        values?.incident?.supervisorDetails?.notifiedDate,
      ).format(NO_TIMEZONE_DATE_FORMAT))

  } else {
    values?.openSince &&
      (values.openSince = moment(
        values.openSince
      ).format(NO_TIMEZONE_DATE_FORMAT))


    values?.incidentDetails?.dateOfIncident &&
      (values.incidentDetails.dateOfIncident = moment(
        values?.incidentDetails?.dateOfIncident
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.dateReportedToDSP &&
      (values.dateReportedToDSP = moment(
        values.dateReportedToDSP
      ).format(NO_TIMEZONE_DATE_FORMAT))


    if (Array.isArray(values?.incidentNarrative)) {
      values.incidentNarrative = values?.incidentNarrative?.map((e) => ({ ...e, narrativeDate: moment(e.narrativeDate).format(NO_TIMEZONE_DATE_FORMAT) }))
    }

    if (Array.isArray(values?.partiesInvolved)) {
      values.partiesInvolved = values.partiesInvolved.map(e => ({ ...e, vehicleDetails: e.vehicleDetails ? { ...e.vehicleDetails, year: e.vehicleDetails.year ? moment(e.vehicleDetails.year).format(NO_TIMEZONE_DATE_FORMAT) : undefined } : undefined }))
    }

    values?.startDate &&
      (values.startDate = moment(values?.startDate).format(NO_TIMEZONE_DATE_FORMAT))

    values?.endDate &&
      (values.endDate = moment(values?.endDate).format(NO_TIMEZONE_DATE_FORMAT))


    values?.closeDate &&
      (values.closeDate = moment(values?.closeDate).format(NO_TIMEZONE_DATE_FORMAT))
      
    values?.amountPaidTillDate?.date &&
      (values.amountPaidTillDate.date = moment(
        values?.amountPaidTillDate?.date,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.amountIncurredTillDate?.date &&
      (values.amountIncurredTillDate.date = moment(
        values?.amountIncurredTillDate?.date,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.returnToWorkDate &&
      (values.returnToWorkDate = moment(values?.returnToWorkDate).format(
        NO_TIMEZONE_DATE_FORMAT,
      ))

    values?.userDetails?.dateOfBirth &&
      (values.userDetails.dateOfBirth = moment(
        values?.userDetails?.dateOfBirth,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.userDetails?.hireDate &&
      (values.userDetails.hireDate = moment(
        values?.userDetails?.hireDate,
      ).format(NO_TIMEZONE_DATE_FORMAT))

    values?.supervisorDetails?.notifiedDate &&
      (values.supervisorDetails.notifiedDate = moment(
        values?.supervisorDetails?.notifiedDate,
      ).format(NO_TIMEZONE_DATE_FORMAT))
  }
  return { ...values }
}
