import React, { useState } from 'react'
import AttachmentAddView from '../../../components/AttachmentAddView'
import PartiesInvolvedCard from '../../publicIncident/PartiesInvolvedCard'

import { ErrorMessage, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import { DatePickerCustomHeader } from '../../../components/form/DatePickerCustomHeader'
import PartiesInvolvedModal from '../../publicIncident/PartiesInvolvedModal'
import PropertiesInvolvedCard from '../../incident/PropertiesInvolvedCard'
import PropertyInvolvedModal from '../../incident/PropertyInvolvedModal'
import VehicleInvolvedModal from '../../incident/VehicleInvolvedModal'
import WitnessCard from '../../incident/WitnessCard'
import WitnessModal from '../../incident/WitnessModal'
import {
  ClaimReportedViaTypes,
  allPriorityStatusTypes,
} from '../../incident/utils'
import FormikSelect from './FormikSelect'
import DatePickerField from '../../../components/form/DatePickerField'

function IncidentRightSection() {
  const { values, setFieldValue, handleBlur, setFieldTouched } =
    useFormikContext()
  const vehicularAccident = values?.incident?.vehicularAccident

  // const getDate = (date) => {
  //   let t = ''
  //   if (date) {
  //     try {
  //       t = new Date(date)
  //     } catch (err) {
  //       t = new Date()
  //     }
  //   }
  //   return t && t.toDateString() === 'Invalid Date' ? new Date() : t
  // }

  const [showAddPartiesInvolvedModal, setShowAddPartiesInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showAddVehicleInvolvedModal, setShowAddVehicleInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showPropertyInvolvedModal, setShowPropertyInvolvedModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })
  const [showWitnessModal, setShowWitnessModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })

  const [partiesInvolvedValidation, setPartiesInvolvedValidation] =
    useState(false)

  return (
    <>
      <div
        className="col-md-5  border-left col-h"
        style={{
          background: 'rgb(244, 247, 254)',
          height: '100vh',
        }}
      >
        <div
          className="modal-body p-4 mdl-scroll"
          style={{ overflowX: 'hidden' }}
        >
          <div className="row">
            <div className="col-md-6">
              <DatePickerField
                name="incident.startDate"
                label="Start Date"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <DatePickerField
                name="incident.endDate"
                label="Due Date"
                onBlur={handleBlur}
              />
            </div>
            <div className="col-md-6">
              <DatePickerField
                name="incident.closeDate"
                label="Claim Close Date"
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="w-100 border-top mx-2 py-2"></div>
          <div className={'row mb-3'}>
            <div className="col-md-6">
              <FormikSelect
                label="Priority"
                name="incident.priority"
                defaultLabel="Select Priority"
                options={allPriorityStatusTypes}
                value={values.incident.priority}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-md-6">
              <FormikSelect
                label="Reported Via"
                name="incident.reportedVia"
                defaultLabel="Select Reported Via"
                options={ClaimReportedViaTypes}
                value={values.incident.reportedVia}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="next-action" className="col-form-label">
              Next Action <span className='text-danger'> *</span>
            </label>
            <textarea
              data-testid="next-action"
              className="form-control col-md-12"
              id="next-action"
              placeholder={'Next Action'}
              value={values?.incident?.nextAction ?? ""}
              onChange={(a) =>
                setFieldValue('incident.nextAction', a.target.value)
              }
            ></textarea>
            <ErrorMessage
              data-testid="next-action-error"
              className="text-danger"
              name="incident.nextAction"
              component="div"
            />
          </div>
          <div className="w-100 border-top mx-2 py-2 mt-1"></div>
          <div className="form-group col-md-12">
            <label className="d-block incident-text">
              Parties {vehicularAccident && '& Vehicles'} Involved
            </label>
            {values?.incident.partiesInvolved?.map((party, index) => (
              <PartiesInvolvedCard
                key={index}
                info={party}
                uid={index}
                onEdit={(uid) => {
                  const a = values?.incident.partiesInvolved[uid]
                  setShowAddPartiesInvolvedModal({
                    isOpen: true,
                    data: a,
                    editId: uid,
                  })
                }}
                onDelete={(uid) => {
                  const a = values?.incident.partiesInvolved.filter(
                    (party, i) => i != uid,
                  )
                  setFieldValue('incident.partiesInvolved', a)
                }}
              />
            ))}
            <button
              onClick={() =>
                setShowAddPartiesInvolvedModal({
                  isOpen: true,
                  data: null,
                  editId: null,
                })
              }
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
            >
              Add..
            </button>
          </div>
          {partiesInvolvedValidation && (
            <span className="text-danger">Party involved is required.</span>
          )}

          {/* <div className="w-100 border-top mx-2 py-2 mt-1"></div>
          {values?.incident.vehicularAccident && (
            <>
              <div className="form-group col-md-12">
                <label className="d-block incident-text">
                  Vehicles Involved
                </label>
                {values?.incident.vehiclesInvolved?.map((vehicle, index) => {
                  return (
                    <VehicleInvolvedCard
                      vehicle={vehicle}
                      key={index}
                      uid={index}
                      onEdit={(uid) => {
                        const a = values?.incident.vehiclesInvolved[uid]

                        setShowAddVehicleInvolvedModal({
                          isOpen: true,
                          data: a,
                          editId: uid,
                        })
                      }}
                      onDelete={(uid) => {
                        const a = values?.incident.vehiclesInvolved.filter(
                          (party, i) => i != uid,
                        )
                        setFieldValue('incident.vehiclesInvolved', a)
                      }}
                    />
                  )
                })}
                <button
                  onClick={() =>
                    setShowAddVehicleInvolvedModal({
                      isOpen: true,
                      data: null,
                      editId: null,
                    })
                  }
                  type="button"
                  className="btn btn-outline-secondary side-btn mr-3"
                >
                  Add..
                </button>
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
            </>
          )} */}
          <div className="form-group border-top py-2 mt-1 col-md-12">
            <label className="d-block incident-text">Properties Involved</label>
            {values?.incident.propertiesInvolved.map((property, index) => {
              return (
                <PropertiesInvolvedCard
                  property={property}
                  key={index}
                  uid={index}
                  onEdit={(uid) => {
                    const a = values?.incident?.propertiesInvolved[uid]

                    setShowPropertyInvolvedModal({
                      isOpen: true,
                      data: a,
                      editId: uid,
                    })
                  }}
                  onDelete={(uid) => {
                    const a = values?.incident?.propertiesInvolved.filter(
                      (party, i) => i != uid,
                    )
                    setFieldValue('incident.propertiesInvolved', a)
                  }}
                />
              )
            })}
            <button
              onClick={() =>
                setShowPropertyInvolvedModal({
                  isOpen: true,
                  data: null,
                  editId: null,
                })
              }
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
            >
              Add..
            </button>
          </div>
          <div className="w-100 border-top mx-2 py-2 mt-1"></div>
          <div className="form-group col-md-12">
            <label className="d-block incident-text" htmlFor="package">
              Witnesses
            </label>
            {values?.incident.witnesses.map((witness, index) => {
              return (
                <WitnessCard
                  witness={witness}
                  key={index}
                  uid={index}
                  onEdit={(uid) => {
                    const a = values?.incident.witnesses[uid]

                    setShowWitnessModal({
                      isOpen: true,
                      data: a,
                      editId: uid,
                    })
                  }}
                  onDelete={(uid) => {
                    const a = values?.incident.witnesses.filter(
                      (party, i) => i != uid,
                    )
                    setFieldValue('incident.witnesses', a)
                  }}
                />
              )
            })}

            <button
              onClick={() =>
                setShowWitnessModal({
                  isOpen: true,
                  data: null,
                  editId: null,
                })
              }
              type="button"
              className="btn btn-outline-secondary side-btn mr-3"
            >
              Add..
            </button>
          </div>
          <div className="w-100 border-top mx-2 py-2 mt-1"></div>
          <div className="form-group col-md-12">
            {
              <AttachmentAddView
                formik={{
                  setFieldValue: setFieldValue,
                  values: values,
                }}
                module={'claim'}
                uid={values.claimId}
              />
            }
          </div>
        </div>
      </div>

      <PartiesInvolvedModal
        isOpen={showAddPartiesInvolvedModal.isOpen}
        onClose={() =>
          setShowAddPartiesInvolvedModal({
            isOpen: false,
            data: null,
            editId: null,
          })
        }
        isVehicularIncident={vehicularAccident}
        values={values.incident}
        setFieldValue={setFieldValue}
        editId={showAddPartiesInvolvedModal.editId}
        editData={showAddPartiesInvolvedModal.data}
        setPartiesInvolvedValidation={setPartiesInvolvedValidation}
        directClaim={true}
        clientId={values.clientId}
      />

      <VehicleInvolvedModal
        isOpen={showAddVehicleInvolvedModal.isOpen}
        onClose={() =>
          setShowAddVehicleInvolvedModal({
            isOpen: false,
            data: null,
            editId: null,
          })
        }
        values={values.incident}
        editId={showAddVehicleInvolvedModal.editId}
        editData={showAddVehicleInvolvedModal.data}
        setFieldValue={setFieldValue}
        directClaim={true}
        clientId={values.clientId}
      />
      <PropertyInvolvedModal
        isOpen={showPropertyInvolvedModal.isOpen}
        onClose={() =>
          setShowPropertyInvolvedModal({
            isOpen: false,
            data: null,
            editId: null,
          })
        }
        values={values.incident}
        editId={showPropertyInvolvedModal.editId}
        editData={showPropertyInvolvedModal.data}
        setFieldValue={setFieldValue}
        directClaim={true}
        clientId={values.clientId}
      />
      <WitnessModal
        isOpen={showWitnessModal.isOpen}
        onClose={() =>
          setShowWitnessModal({ isOpen: false, data: null, editId: null })
        }
        values={values.incident}
        editId={showWitnessModal.editId}
        editData={showWitnessModal.data}
        setFieldValue={setFieldValue}
        directClaim={true}
      />
    </>
  )
}

export default IncidentRightSection
