import React from 'react'
import range from 'lodash/range'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'

const years = range(getYear(new Date()) - 100, getYear(new Date()) + 20, 1)

export function DatePickerCustomHeader({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) {
  return (
    <div className="px-2 d-flex justify-content-between align-items-center">
      <div>
        <i
          className="fa fa-chevron-left"
          aria-hidden="true"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        />
      </div>

      <div>
        <select
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <i
          className="fa fa-chevron-right"
          aria-hidden="true"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        />
      </div>
    </div>
  )
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
