import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { timeNowFormat } from '../../../configs/utils'
import { useCommentAccess } from '../../../hooks/useGetPermission'

export default function DirectIncidentNarrativeView({
  activeStep,
  setActiveStep,
  setNarrative,
  context,
  values,
  setFieldValue,
}) {
  const initialized = useRef(false)
  const [narratives, setNarratives] = useState([])
  const [narrativeInput, setNarrativeInput] = useState([])
  const [editNarrativeIndex, setEditNarrativeIndex] = useState('')
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const mountedRef = useRef(true)
  const module = useSelector((state) => state.sideNavModule)
  const currentUser = useSelector((state) => state.currentUser)

  const init = async () => {
    if (values.incident.incidentNarrative.length > 0) {
      if (values.incident.incidentNarrative[0] !== '') {
        setNarratives(values.incident.incidentNarrative)
      }
    }
  }

  useEffect(() => {
    mountedRef.current = true
    init()
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleAddNarrative = () => {
    const currentDate = new Date()
    if (narrativeInput.length > 0) {
      if (editNarrativeIndex !== '') {
        const narrative = {
          narrative: narrativeInput,
          narrativeDate: currentDate,
          narrativeBy: currentUser.firstName + ' ' + currentUser.lastName,
        }
        narratives[editNarrativeIndex] = narrative
        setNarratives([...narratives])
        setNarrativeInput('')
        setEditNarrativeIndex('')
      } else {
        const narrative = {
          narrative: narrativeInput,
          narrativeDate: currentDate,
          narrativeBy: currentUser.firstName + ' ' + currentUser.lastName,
        }
        setNarratives([...narratives, narrative])
        setNarrativeInput('')
      }
      // setFieldValue('incidentNarrative', [...narratives, narrative])
      handleNarrativeSubmit()
    }
  }

  useEffect(() => {
    if (initialized.current) {
      handleNarrativeSubmit()
    } else {
      initialized.current = true
    }
  }, [narratives])


  const handleNarrativeSubmit = () => {
    setFieldValue('incident.incidentNarrative', narratives)
    // setActiveStep(activeStep + 1)
  }

  return (
    <div>
      <div className="img-box" style={{ marginBottom: '10px' }}>
        <div className="r-box1 plus1 posi-rel d-flex">
          <input
            value={narrativeInput}
            onChange={(e) => setNarrativeInput(e.target.value)}
            type="text"
            className="form-control"
            placeholder="Write a Narrative..."
          />
          <button
            className="btn btn-warning custom-btn ml-2"
            type="button"
            onClick={handleAddNarrative}
          >
            {editNarrativeIndex !== '' ? 'Update' : 'Add'}
          </button>
        </div>
      </div>
      <div
        className="pb-5 overflow-auto"
        id="scroller"
        style={{ height: '320px', marginBottom: '10px' }}
      >
        {narratives?.map((c, index) => (
          <div className="comment-section border-bottom" key={index}>
            <div>
              <div className="img-box active mb-1">
                <div className="pro-box d-flex mr-3">
                  {c.narrativeBy
                    ? c.narrativeBy.split(' ')[0]?.split('')[0]
                    : null}
                  {c.narrativeBy
                    ? c.narrativeBy.split(' ')[1]?.split('')[0]
                    : null}
                </div>
                <div className="r-box">
                  <p>{c.narrative}</p>
                  <p
                    title={moment
                      .utc(c.narrativeDate)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss')}
                  >
                    {timeNowFormat(c.narrativeDate)}
                  </p>
                </div>
                <div className="comment-escl">
                  <Dropdown
                    isOpen={dropdownOpen}
                    className="custom-dropdown"
                  >
                    <DropdownToggle caret onClick={() => {
                      setdropdownOpen(!dropdownOpen)
                    }}>
                      <i
                        className="fas fa-ellipsis-v"
                        aria-hidden="true"
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setNarrativeInput(c.narrative)
                          setEditNarrativeIndex(index)
                          setdropdownOpen(false)
                        }}
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          const a = narratives.filter(
                            (narrative, i) => i != index,
                          )
                          setNarratives(a)
                          setdropdownOpen(false)
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="d-flex justify-content-between" style={{ width: '100%' }}>
        <button
          className="btn text-primary pl-0"
          onClick={() => setNarrative()}
        >
          <i
            className={'fas fa-chevron-left'}
            aria-hidden={true}
            style={{ paddingRight: '4px' }}
          >
            {' '}
          </i>
          Back
        </button>
        <button
          type="submit"
          className="btn btn-primary pull-right"
          onClick={handleNarrativeSubmit}
        >
          {context === 'claim' ? 'Save & Continue' : 'Save'}
        </button>
      </div> */}
    </div>
  )
}
