import React from 'react'
import { Form } from 'formik'
import TextInput from '../../components/form/TextInput'
import TextArea from '../../components/form/TextArea'

export default function ClaimNotes({
  litigationStatus,
  setTab,
  setFieldValue,
  values
}) {
  return (
    <Form className={'indent-form'}>
      <div className={'form-row'}>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <TextArea
              cols={30}
              rows={2}
              label="Decline"
              name="claimants.0.notes.claimDeclineReason"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label>Litigation</label>
              <div>
                <div className="form-check form-check-inline mr-4 ">
                  <input
                    className="form-check-TextInput"
                    type="radio"
                    name="litigationStatus"
                    value="yes"
                    checked={litigationStatus === 'yes'}
                    onChange={() =>
                      setFieldValue('claimants.0.notes.litigationStatus', 'yes')
                    }
                    id={'litigationStatusYes'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="litigationStatusYes"
                    style={{ paddingLeft: '5px' }}
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline mr-4">
                  <input
                    className="form-check-TextInput"
                    type="radio"
                    name="litigationStatus"
                    value="no"
                    checked={litigationStatus === 'no'}
                    onChange={() =>
                      setFieldValue('claimants.0.notes.litigationStatus', 'no')
                    }
                    id={'litigationStatusNo'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="litigationStatusNo"
                    style={{ paddingLeft: '5px' }}
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <TextArea
              cols={30}
              rows={2}
              label=""
              name="claimants.0.notes.litigationDetails"
            />
          </div>
        </div>
        {litigationStatus === 'yes' && (
          <div className="col-md-12">
            <div className="form-row">
              <div className={'col-md-6'}>
                <TextInput
                  label="Attorney Name"
                  name="claimants.0.notes.litigationAttorney.name"
                />
              </div>
              <div className={'col-md-6'}>
                <TextInput
                  label="Email"
                  name="claimants.0.notes.litigationAttorney.email"
                  type="email"
                />
              </div>
              <div className={'col-md-6'}>
                <TextInput
                  isPhone
                  label="Contact number"
                  name="claimants.0.notes.litigationAttorney.contactNumber"
                  maxLength={14}
                  changeOnUndefined={true}
                  onPhoneChange={(phone) =>
                   (phone?.length ?? 0)> 2 &&
                    setFieldValue(
                      'claimants.0.notes.litigationAttorney.contactNumber',
                      phone ?? "",
                    )
                  }
                  value={
                    values?.claimants[0]?.notes?.litigationAttorney?.contactNumber || ''
                  }
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <TextArea
              cols={30}
              rows={2}
              label="Settlement"
              name="claimants.0.notes.settlementOffered"
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className={'form-group mb-2'}>
            <TextArea
              cols={30}
              rows={2}
              label="Remarks"
              name="claimants.0.notes.claimAdjustorRemarks"
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{ width: '100%', marginTop: '43px' }}
        >
          <button
            className="btn text-primary pl-0"
            onClick={setTab}
            type="button"
          >
            <i
              className={'fas fa-chevron-left'}
              aria-hidden={true}
              style={{ paddingRight: '4px' }}
            >
              {' '}
            </i>
            Back
          </button>
          <button id="submit" type="submit" className="btn btn-primary ">
            Save &amp; Close
          </button>
        </div>
      </div>
    </Form>
  )
}
