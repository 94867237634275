import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/role`

export function getRolesList() {
  return http.get(`${apiEndpoint}/list`)
}

export function getRoleDetails(id) {
  return http.get(`${apiEndpoint}/detail/${id}`)
}

export function saveRoleDetails(data) {
  return http.post(`${apiEndpoint}/save`, data)
}

export function updateRoleDetails(id, data) {
  return http.patch(`${apiEndpoint}/update/${id}`, data)
}

export function getUserRoles() {
  return http.get(`${apiEndpoint}/roles-list`)
}

export function deleteRoleData(id) {
  return http.delete(`${apiEndpoint}/remove/${id}`)
}

export function getModules() {
  return http.get(`${apiEndpoint}/modules`)
}

export function cloneRole(id) {
  return http.post(`${apiEndpoint}/clone/${id}`)
}

export function checkOrgRole(id) {
  return http.get(`${apiEndpoint}/check-org-role/${id}`)
}
