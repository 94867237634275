import moment from 'moment'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'
import { timezones } from '../pages/incident/utils'

const tSchema = {
  amount: yup
    .number()
    .typeError('Amount should be number.')
    .required()
    .label('Amount'),
  transactionDate: yup.date().nullable().required().label('Date'),
}
export const TRANSACTION_VALIDATION_SCHEMA = () => yup.object().shape(tSchema)
export const POLICY_VALIDATION_SCHEMA = () => {
  const schema = {
    clientId: yup.string().required('client is required!'),
    insuranceCarrier: yup.string().required().label('Insurance Carrier'),
    policyType: yup.string().required().label('Policy Type'),
    sumInsured: yup
      .number()
      .typeError('Limit should be number.')
      .required('Limit is required.'),
    aggregateLimit: yup.number().typeError('Aggregate Limit should be number.'),
    deducibleField: yup.number().typeError('Deductible should be number.'),
    policyDate: yup.date().typeError("Please select a valid policy date").required().label('Policy Date'),
    policyId: yup.string().trim().required().label("Policy ID"),
    premiumRenewalDate: yup
      .date().typeError("Please select a valid Renewal Date")
      .required()
      .label('Renewal Date')
      .min(yup.ref('policyDate'), "Renewal Date can't be before Policy Date"),
    // premiumTransactions:yup.array(yup.object().shape(tSchema)).min(1).required().label('Premium Transactions'),
    // claimTransactions:yup.array(yup.object().shape(tSchema)).min(1).required().label('Claim Transactions'),
    nextAction: yup.string().trim().required("Next Action field is required!")
  }
  return yup.object().shape(schema)
}

export const EMPLOYEE_VALIDATION_SCHEMA = () => {
  const schema = {
    model: yup.string().required().label('Model'),
    type: yup.string().required().label('Type'),
    manufactureYear: yup
      .number()
      .typeError('A number is required.')
      .required('Year of Manufacture is required.'),
    milesTravelled: yup
      .number()
      .typeError('A number is required.')
      .required('Miles Travelled is required.'),
    vinNumber: yup
      .number()
      .typeError('A number is required.')
      .required('VIN Number is required.'),
    purchaseCost: yup
      .number()
      .typeError('A number is required.')
      .required('Cost at the time of purchase is required.'),
    purchaseDate: yup.date().required().label('Date of Purchase'),
  }
  return yup.object().shape(schema)
}

export const PROPERTY_VALIDATION_SCHEMA = () => {
  const schema = {}
  return yup.object().shape(schema)
}

export const VEHICLE_VALIDATION_SCHEMA = () => {
  const schema = {}
  return yup.object().shape(schema)
}

export const CLAIM_VALIDATION_SCHEMA = () => {
  const schema = {
    claimants: yup.array().of(
      yup.object().shape({
        dateOfClaim: yup.date().typeError("Date of claim is required!").required("Date of claim is required!"),
        timeOfClaim: yup.string().trim().required("Time of claim is required!").default('00:00:00 AM'),
        startDate: yup.date().nullable().optional(),
        endDate: yup.date().nullable().test({
          test: (value, context) => {
            const startDate = context.parent?.startDate
            if (startDate && value && moment(moment(value).startOf('day')).isBefore(moment(startDate).startOf('day'))) {
              return false
            }
            return true
          },
          message: "Due date must be on/after start date"
        }).optional(),
        // claimantInfo: yup.object()
        //   .required('claimant is required!'),
        coverageType: yup
          .string()
          .trim()
          .required('Coverage Type is required!'),
        status: yup.string().trim().required('Claim Status is required!'),
        nextAction: yup.string().trim().required("Next Action field is required!"),
        notes: yup.object().shape({
          litigationAttorney: yup.object().shape({
            contactNumber: yup.string().trim().test({
              test: (value) => {
                if (value && value !== "") {
                  return isValidPhoneNumber(value ?? "", {
                    defaultCountry: "US",
                    defaultCallingCode: "+1"
                  })
                }
                return true
              },
              message: "Please enter a valid US number"
            })
          }),
          claimantContactNumber: yup.string().trim().required("Claimant contact number is required!").test({
            test: (value) => {
              if (value && value !== "") {
                return isValidPhoneNumber(value ?? "", {
                  defaultCountry: "US",
                  defaultCallingCode: "+1"
                })
              }
              return true
            },
            message: "Please enter a valid US number"
          })
        }),
        // adjusterContactDetails: yup.string().trim().required("Employee contact number is required!").test({
        //   test: (value) => {
        //     if (value && value !== "") {
        //       return isValidPhoneNumber(value ?? "", {
        //         defaultCountry: "US",
        //         defaultCallingCode: "+1"
        //       })
        //     }
        //     return true
        //   },
        //   message: "Please enter a valid US number"
        // }).optional()
      }),
    ),
  }
  return yup.object().shape(schema)
}

export const INCIDENT_VALIDATION_SCHEMA = () => {
  const schema = {
    claimStatus: yup.string().optional(),
    clientId: yup.string().required('Please select a client!'),
    timezone: yup.string().oneOf(timezones.map(e => e.key), "Please select one of available timezone").required("Please select a timezone"),
    startDate: yup.date().nullable().test({
      test: (value, context) => {
        const dateOfIncident = context.parent?.incidentDetails?.dateOfIncident
        if (dateOfIncident && value && moment(value).isBefore(moment(dateOfIncident).startOf('day').toDate())) {
          return false
        }
        return true
      },
      message: "Start date must be on/after incident date"
    }).optional(),
    endDate: yup.date().nullable().test({
      test: (value, context) => {
        const startDate = context.parent?.startDate
        if (startDate && value && moment(value).isBefore(moment(startDate).startOf('day').toDate())) {
          return false
        }
        return true
      },
      message: "Due date must be on/after start date"
    }).optional(),
    closeDate: yup.date().nullable().test({
      test: (value, context) => {
        const startDate = context.parent?.startDate
        if (startDate && value && moment(value).isBefore(moment(startDate).startOf('day').toDate())) {
          return false
        }
        return true
      },
      message: "Close date must be on/after start date"
    }).optional(),
    returnToWorkDate: yup.date().nullable().when("claimStatus", {
      is: "Closed",
      then: (schema) => schema.required("Return to Work Date is required."),
      otherwise: (schema) => schema.optional()
    }).test({
      test: (value, context) => {
        const dateOfIncident = context?.parent?.incidentDetails?.dateOfIncident
        if (dateOfIncident && value) {
          return moment(value).isSameOrAfter(moment(dateOfIncident).startOf("day").toDate())
        }

        return true
      },
      message: "Return to Work Date must be on/after incident date."
    }),
    userDetails: yup.object().shape({
      employeeId: yup.string().trim().required("Please select an employee"),
      employeeStatus: yup.string().trim().required("Please select employee status"),
      firstName: yup.string().when("employeeId", {
        is: "OTHER",
        then: (schema) => schema.trim().required("Employee first name is required!")
      }),
      lastName: yup.string().when("employeeId", {
        is: "OTHER",
        then: (schema) => schema.trim().required("Employee last name is required!")
      }),
      email: yup.string().trim().email("Please enter a valid email").optional(),
      contactNumber: yup.string().trim().test({
        test: (value) => {
          if (value && value !== "") {
            return isValidPhoneNumber(value ?? "", {
              defaultCountry: "US",
              defaultCallingCode: "+1"
            })
          }
          return true
        },
        message: "Please enter a valid US number"
      }).optional()
    }),
    incidentDetails: yup.object().shape({
      dateOfIncident: yup.date().typeError("Date of Loss is required!").required("Date of Loss is required!"),
      // time: yup.string().trim().required("Time of Loss is required!"),
      location: yup.string().trim().required("Please provide location where the incident happened!"),
      driverStatement: yup.string().trim().required("Driver statement is required!"),
      pocNumber: yup.string().trim().test({
        test: (value) => {
          if (value && value !== "") {
            return isValidPhoneNumber(value ?? "", {
              defaultCountry: "US",
              defaultCallingCode: "+1"
            })
          }
          return true
        },
        message: "Please enter a valid US number"
      }),
      pocEmail: yup.string().trim().email("Please enter a valid email").optional(),
    }),
    supervisorDetails: yup.object().shape({
      name: yup.string().trim().required("Supervisor name is required!"),
      email: yup.string().trim().email("Please enter a valid email!").optional(),
      contactNumber: yup.string().trim().test({
        test: (value) => {
          if (value && value !== "") {
            return isValidPhoneNumber(value ?? "", {
              defaultCountry: "US",
              defaultCallingCode: "+1"
            })
          }
          return true
        },
        message: "Please enter a valid US number"
      }).optional(),
      notifiedDate: yup.date().nullable().test({
        test: (value, context) => {
          const dateOfIncident = context?.from?.[1]?.value?.incidentDetails?.dateOfIncident
          if (dateOfIncident && value && moment(value).isBefore(moment(dateOfIncident).startOf('day').toDate())) {
            return false
          }

          return true
        },
        message: "This date must be on/after the Date of Incident"
      }).optional()
    }),
    nextAction: yup.string().trim().required("Next Action field is required!")
  }
  return yup.object().shape(schema)
}

export const PARTY_INVOLVED_VALIDATION_SCHEMA = () => {
  const schema = {
    personDetails: yup.object().shape({
      firstName: yup.string().trim().required('First Name is required!'),
      lastName: yup.string().trim().required('Last Name is required!'),
      contactNumber: yup.string().trim().test({
        test: (value) => {
          if (value && value !== "" && value !== undefined) {
            return isValidPhoneNumber(value ?? "", {
              defaultCountry: "US",
              defaultCallingCode: "+1"
            })
          }
          return true
        },
        message: "Please enter a valid US number"
      })
    }),
  }
  return yup.object().shape(schema)
}

export const REVENUE_VALIDATION_SCHEMA = () => {
  const schema = {
    year: yup
      .number()
      .typeError('A number is required.')
      .required('Year is required.'),
    revenue: yup
      .number()
      .typeError('A number is required.')
      .required('Revenue is required.'),
  }
  return yup.object().shape(schema)
}

export const OWNERSHIP_VALIDATION_SCHEMA = () => {
  const schema = {
    name: yup.string().trim().required('Name is required!'),
    email: yup.string().email().trim().required('Email is required!'),
    ownerShare: yup.number().typeError('Share must be Numeric!'),
  }
  return yup.object().shape(schema)
}

export const CLIENT_VALIDATION_SCHEMA = () => {
  const schema = {
    companyName: yup.string().trim().required('Company Name is required!'),
    corporationType: yup
      .string()
      .trim()
      .required('Corporation Type is required!'),
    unemployementId: yup
      .number()
      .typeError('Unemployement ID must be Numeric!'),
  }
  return yup.object().shape(schema)
}

export const ORGANIZATION_VALIDATION_SCHEMA = () => {
  const schema = {
    name: yup.string().trim().required('Organization Name is required!'),
    position: yup.string().trim().required('Position Type is required!'),
    email: yup.string().email().trim().required('Email is required!'),
  }
  return yup.object().shape(schema)
}

export const PAYROLL_PROCESSOR_VALIDATION_SCHEMA = () => {
  const schema = {
    frequency: yup.string().trim().required('Frequency is required!'),
    payrollCost: yup
      .number()
      .typeError('Share must be Numeric!')
      .required('Payroll cost is required!'),
  }
  return yup.object().shape(schema)
}


export const THIRD_PARTY_VALIDATION_SCHEMA = () => {
  const schema = {
    firstName: yup.string().trim().required('First Name is required!'),
    lastName: yup.string().trim().required('Last Name is required!'),
    email: yup.string().email().trim().optional(),
    contactNumber: yup.string().trim().test({
      test: (value) => {
        if (value && value !== "") {
          return isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          })
        }
        return true
      },
      message: "Please enter a valid US number"
    })
  }
  return yup.object().shape(schema)
}


export const USER_VALIDATION_SCHEMA = () => {
  const schema = {
    firstName: yup.string().trim().required('First Name is required!'),
    lastName: yup.string().trim().required('Last Name is required!'),
    email: yup.string().email().trim().required('Email is required!'),
    roleId: yup.string().trim().required('Role is required!'),
    clients: yup.array().min(1, 'Select atleast one client'),
    contactNumber: yup.string().trim().test({
      test: (value) => {
        if (value && value !== "") {
          return isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          })
        }
        return true
      },
      message: "Please enter a valid US number"
    })
  }
  return yup.object().shape(schema)
}

export const PUBLIC_INCIDENT_VALIDATION_SCHEMA = () => {
  const schema = {
    humanParts: yup.object().nullable().when("incidentDetails.isInjury", {
      is: true,
      then: (schema) => schema.test({
        test: (value) => value && Object.keys(value).length > 0,
        message: "Please select at least one part on the diagram!"
      }),
      otherwise: (schema) => schema.optional()
    }),
    vehicleNature: yup.string().trim().required(),
    clientId: yup
      .string()
      .trim()
      .required('Company is required!')
      .test(
        'isAmazon',
        'Amazon can not be allowed as company name',
        (value) => {
          if (value && value.toLocaleLowerCase().includes('amazon'))
            return false
          return true
        },
      ),
    userDetails: yup.object().shape({
      firstName: yup.string().trim().required('First Name is required!'),
      lastName: yup.string().trim().required('Last Name is required!'),
      email: yup
        .string()
        .email('must be a valid email')
        .trim()
        .required('Email is required!'),
      contactNumber: yup
        .string()
        .trim()
        .required('Contact Number is required!').test({
          test: (value) => isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          }),
          message: "Please enter a valid US number"
        }),
      userSignature: yup.string().trim().required('Signature is required!'),
    }),
    vehiclesInvolved: yup.array().when('vehicleNature', {
      is: 'vehicular',
      then: (schema) => schema.of(yup.object({
        vehicleDetails: yup.object({
          vehicleType: yup.string().trim().required("Please select vehicle type"),
          van: yup.string().trim().when("vehicleType", {
            is: "company",
            then: (schema) => schema.required("VAN Number is required!"),
          }),
          vin: yup.string().trim().required("VIN Number is required!"),
          licensePlate: yup.string().trim().required("License Plate is required!")
        })
      })).min(1, "At least 1 vehicle information is required!"),
    }),
    incidentDetails: yup.object().shape({
      injuredThirdPartyDetails: yup.object().shape({
        email: yup.string().trim().email("Please enter a valid email").optional(),
        contactNumber: yup.string().trim().test({
          test: (value) => {
            if (value && value !== "") {
              return isValidPhoneNumber(value ?? "", {
                defaultCountry: "US",
                defaultCallingCode: "+1"
              })
            }
            return true
          },
          message: "Please enter a valid US number"
        })
      }),
      dateOfIncident: yup
        .string()
        .trim()
        .required('Date of Incident is required!'),
      employeeActivity: yup.string().trim().required("Please fill this field!"),
      driverStatement: yup
        .string()
        .trim()
        .required('Driver Statement is required!'),
      location: yup.string().trim().required('Incident location is required!'),
      hasWitnesses: yup.bool().required('Please select yes or no from above!'),
      witnesses: yup.array().when('hasWitnesses', {
        is: true,
        then: (schema) => schema.of(yup.object({
          name: yup.string().trim().required("Witness name is required!"),
          contactNumber: yup.string().trim().required("Witness contact number is required!").test({
            test: (value) => {
              if (value && value !== "") {
                return isValidPhoneNumber(value ?? "", {
                  defaultCountry: "US",
                  defaultCallingCode: "+1"
                })
              }
              return true
            },
            message: "Please enter a valid US number"
          })
        })).required("Witnesses details are required!").min(1, "At least one witness is required!"),
        otherwise: (schema) => schema.optional()
      }),
      isInjury: yup.bool().required('Please select yes or no from above!'),
      natureOfInjury: yup.string().when("isInjury", {
        is: true,
        then: (schema) => schema.trim().required("Nature of Injury is required!"),
        otherwise: (schema) => schema.optional()
      }),
      painLevel: yup.number().when("isInjury", {
        is: true,
        then: (schema) => schema.required("Please select pain level!")
      }),
      isMedicalTreatment: yup.bool().nullable().when("isInjury", {
        is: true,
        then: (schema) => schema.required("Please select yes or no from above!"),
        otherwise: (schema) => schema.optional()
      }),
      incidentDescription: yup
        .string()
        .when(['isInjury', 'isMedicalTreatment'], {
          is: true,
          then: yup
            .string()
            .trim()
            .required('Incident Description is required!'),
        }),
      allegedInjury: yup.string().when(['isInjury', 'isMedicalTreatment'], {
        is: true,
        then: yup.string().trim().required('Alleged injury is required!'),
      }),
      publicMemberInjured: yup.string().when(['isInjury', 'isMedicalTreatment'], {
        is: true,
        then: (schema) => schema.trim().required('Please select yes or no from above!')
      }),
      publicMemberInjuredDetails: yup
        .string()
        .when(['isInjury', 'isMedicalTreatment', 'publicMemberInjured'], {
          is: true,
          then: yup.string().trim().required('Detail is required!'),
        }),
      medicalDeclinationDetails: yup.object().when('isMedicalTreatment', {
        is: false,
        then: yup.object().shape({
          empName: yup.string().trim().required('Employee Name is required!'),
          dateOfIncident: yup
            .string()
            .trim()
            .required('Date of Incident is required!'),
          empSignature: yup
            .string()
            .trim()
            .required('Employee signature is required!'),
        }),
      }),
      isThirdPartyPresent: yup.string().trim().required('Please select yes or no from above!'),
      thirdPartyLeaves: yup.string().when("isThirdPartyPresent", {
        is: "true",
        then: (schema) => schema.trim().required('Please select any one of above!')
      }),
      thirdPartyInvolvementType: yup.string().when(['isInjury', 'isMedicalTreatment'], {
        is: true,
        then: (schema) => schema.trim().required('Please select any one of above!')
      }),
      thirdPartyDetails: yup.object().shape({
        thirdPartyName: yup.string().trim().test({
          test: (value, ctx) => {
            const isThirdPartyPresent = ctx.from?.at(1)?.value?.isThirdPartyPresent
            if (isThirdPartyPresent === "true" && (!value || value === "")) {
              return false
            }

            return true
          },
          message: "Third Party name is required!"
        }),
        thirdPartyContactNumber: yup.string().trim().test({
          test: (value) => {
            if (value && value !== "") {
              return isValidPhoneNumber(value ?? "", {
                defaultCountry: "US",
                defaultCallingCode: "+1"
              })
            }
            return true
          },
          message: "Please enter a valid US number"
        })
      }),
      policeCalled: yup.string().trim().required('Please select yes or no from above!'),
      policeReportDetails: yup.object().shape({
        policeReportNumber: yup.string().trim().test({
          test: (value, ctx) => {
            const policeCalled = ctx.from?.at(1)?.value?.policeCalled
            if (policeCalled === "true" && (!value || value === "")) {
              return false
            }

            return true
          },
          message: "Police report number is required!",
        }),
      }),
    }),
    supervisorDetails: yup.object().shape({
      name: yup.string().trim().required('Supervisor name is required!'),
      contactNumber: yup
        .string()
        .trim()
        .required('Contact Number is required!').test({
          test: (value) => isValidPhoneNumber(value ?? "", {
            defaultCountry: "US",
            defaultCallingCode: "+1"
          }),
          message: "Please enter a valid US number"
        }),
      isNotify: yup.string().trim().required('Please select yes or no from above!'),
      email: yup.string().trim().email("Please enter a valid email!").required('Supervisor email is required!'),
      notifiedDate: yup.date().nullable().test({
        test: (value, context) => {
          const dateOfIncident = context.from?.[context.from.length - 1]?.value?.incidentDetails?.dateOfIncident
          if (dateOfIncident && value && moment(value).isBefore(dateOfIncident)) {
            return false
          }
          return true
        },
        message: "Supervisor notified date should be on/after date of incident."
      })
    }),
    attachments: yup
      .array()
      .when('vehicleNature', {
        is: 'vehicular',
        then: yup.array().of(yup.string()).min(1, 'Attachments is Required!'),
      }),
  }
  return yup.object().shape(schema)
}

export const PUBLIC_INCIDENT_MANAGER_FORM_SCHEMA = () => {
  const schema = {
    clientId: yup
      .string()
      .trim()
      .required('Company is required!')
      .test(
        'isAmazon',
        'Amazon can not be allowed as company name',
        (value) => {
          if (value && value.toLocaleLowerCase().includes('amazon'))
            return false
          return true
        },
      ),
    employeeName: yup.string().trim().required("Employee name is required!"),
    dateOfIncident: yup.string().trim().required("Date of Incident is required!"),
    loadOutType: yup.string().trim().required("Please select an option!"),
    dateOfReported: yup.date().nullable().test({
      test: (value, context) => {
        const dateOfIncident = context.parent?.dateOfIncident
        if (dateOfIncident && value && moment(value).isBefore(dateOfIncident)) {
          return false
        }
        return true
      },
      message: "Date Incident Reported to Supervisor should be on/after date of incident."
    }),
    returnDate: yup.date().nullable().test({
      test: (value, context) => {
        const dateOfIncident = context.parent?.dateOfIncident
        if (dateOfIncident && value && moment(value).isBefore(dateOfIncident)) {
          return false
        }
        return true
      },
      message: "Return to work date should be on/after date of incident."
    }).optional(),
    isHospitalized: yup.string().oneOf(["yes", "no"], "Please select Yes or No").required("Please select an option!"),
    hospitalizedDescription: yup.string().when("isHospitalized", {
      is: "yes",
      then: (schema) => schema.trim().required("Please provide description!")
    }),
    isVehicleNeedRepair: yup.string().oneOf(["true", "false"]).required("Please select an option!"),
    vehicleLocatedAddress: yup.string().when("isVehicleNeedRepair", {
      is: "true",
      then: (schema) => schema.trim().required("Please provide address!")
    }),
    incidentDescription: yup.string().trim().required("Please provide incident description!"),
    supervisorName: yup.string().trim().required("Supervisor name is required!"),
    supervisorEmail: yup.string().trim().email("Please provide a valid email!").required("Supervisor email is required!"),
    supervisorContactNumber: yup
      .string()
      .trim()
      .required('Contact Number is required!').test({
        test: (value) => isValidPhoneNumber(value ?? "", { defaultCallingCode: "+1", defaultCountry: "US" }),
        message: "Please enter a valid contact number"
      }),
    managerSignature: yup.string().trim().required('Signature is required!'),
  }
  return yup.object().shape(schema)
}
