import { set } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useAttachmentAccess } from '../hooks/useGetPermission'
import { getAttachmentList } from '../services/attachmentService'
import AddAttachment from './addAttachment'
import AttachmentView from './AttachmentView'

const AttachmentAddView = ({ module, uid, formik }) => {
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [fullAttachments, setFullAttachments] = useState([])
  const [attachmentsId, setAttachmentsId] = useState([])
  const deleteAttachments = useAttachmentAccess(module, 'DELETE')
  const downloadAttachments = useAttachmentAccess(module, 'DOWNLOAD')
  const copyLink = useAttachmentAccess(module, 'COPY_LINK')

  const values = formik.values

  let getAttachmentViews = () => {
    let attachmentViews = []
    fullAttachments.forEach(function (attachment, index) {
      attachmentViews.push(
        <AttachmentView
        key={index}
          attachment={attachment}
          index={index}
          deleteAttachments={deleteAttachments}
          downloadAttachments={downloadAttachments}
          copyLink={copyLink}
          onDeleteAttachment={(index) => {
            const newAttachments = attachmentsId.filter(
              (_, i) => i !== index,
            )
            const newFullAttachments = fullAttachments.filter(
              (_, i) => i !== index,
            )
            setFullAttachments(newFullAttachments)
            formik.setFieldValue('attachments', newAttachments)
          }}
        />,
      )
    })
    return attachmentViews
  }


  const handleAddAttachmentToggle = () => {
    setOpenAttachmentModal(!openAttachmentModal)
  }

  const onAttachmentUpload = (files) => {
    handleAddAttachmentToggle()
    setFullAttachments([...fullAttachments, ...files])
    const attachments = [...values.attachments, ...files.map((c) => c._id)]
    setAttachmentsId(attachments)
    formik.setFieldValue('attachments', attachments)
  }

  const getAttachmentData = ()=> {
    getAttachmentList(module, formik.values.attachments).then((response) => {
      setFullAttachments(response.data.data)
    })
  }

  useEffect(() => {
    if (
      formik?.values?.attachments?.length !== 0 &&
      fullAttachments.length === 0
    ) {
      getAttachmentData()
    }
  }, [formik.values.attachments])

  return (
    <>
      <label className="d-block incident-text" htmlFor="package">
        Attachments
      </label>
      <div className="row">{getAttachmentViews()}</div>
      <AddAttachment
        module={module}
        uid={uid}
        onClose={handleAddAttachmentToggle}
        onAdd={onAttachmentUpload}
        isOpen={openAttachmentModal}
      />
      <button
        type="button"
        className="btn btn-outline-secondary side-btn mr-3"
        id="exampleModalLabel"
        data-toggle="modal"
        data-target="#attachuplaods"
        onClick={handleAddAttachmentToggle}
      >
        Add..
      </button>
    </>
  )
}

export default AttachmentAddView
