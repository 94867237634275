import moment from 'moment'

export const modulesMap = {
  EMPLOYEE: 'employee',
  PROPERTY: 'property',
  CLIENT: 'client',
  POLICY: 'policy',
  MVR: 'mvr',
  USER: 'user',
  VEHICLE: 'vehicle',
  CLAIM: 'claim',
  INCIDENT: 'incident',
  ROLE: 'roles',
  'THIRD-PARTY': 'thirdParty',
}

export const modulesList = [
  {
    _id: '1',
    moduleName: 'EMPLOYEE',
  },
  {
    _id: '2',
    moduleName: 'PROPERTY',
  },
  {
    _id: '3',
    moduleName: 'CLIENT',
  },
  {
    _id: '4',
    moduleName: 'POLICY',
  },
  {
    _id: '5',
    moduleName: 'MVR',
  },
  {
    _id: '6',
    moduleName: 'USER',
  },
  {
    _id: '7',
    moduleName: 'VEHICLE',
  },
  {
    _id: '8',
    moduleName: 'CLAIM',
  },
  {
    _id: '9',
    moduleName: 'INCIDENT',
  },
]

const currentUser = {
  result: 1,
  msg: 'Success',
  data: {
    user: {
      salt: '$2b$20$2j.WATC51rajrrDFkTTjK.',
      accessPriviliges: [],
      termsAccepted: false,
      clients: ['62fdead58f45655724d6c0f8', '62fc9505682f0a64393a9e42'],
      isDeleted: false,
      _id: '62fc9505682f0a64393a9e4f',
      firstName: 'Admin',
      lastName: 'Main',
      email: 'admin@acuity.com',
      roleId: '62fc9505682f0a64393a9e43',
      created: '2022-08-17T07:13:09.763Z',
      modified: '2022-08-17T07:13:09.764Z',
      id: '62fc9505682f0a64393a9e4f',
    },
    permissions: [
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e44',
        module: {
          _id: '62fc9505682f0a64393a9e38',
          moduleName: 'POLICY',
          identifier: 'policy',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b557',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e45',
        module: {
          _id: '62fc9505682f0a64393a9e39',
          moduleName: 'EMPLOYEE',
          identifier: 'employee',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b558',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e46',
        module: {
          _id: '62fc9505682f0a64393a9e3a',
          moduleName: 'VEHICLE',
          identifier: 'vehicle',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b559',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e47',
        module: {
          _id: '62fc9505682f0a64393a9e3b',
          moduleName: 'PROPERTY',
          identifier: 'property',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b55a',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e48',
        module: {
          _id: '62fc9505682f0a64393a9e3c',
          moduleName: 'CLAIM',
          identifier: 'claim',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: ['ANALYZE', 'RECOMMEND', 'IMPORT', 'EXPORT', 'COPY_LINK'],
          _id: '62fcbd4d523c078ec521b55b',
        },
      },
      {
        operations: ['READ', 'UPDATE', 'CREATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e49',
        module: {
          _id: '62fc9505682f0a64393a9e3d',
          moduleName: 'CLIENT',
          identifier: 'client',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b55c',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e4a',
        module: {
          _id: '62fc9505682f0a64393a9e3e',
          moduleName: 'MVR',
          identifier: 'mvr',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
            'GENERATE_LINK',
          ],
          _id: '62fcbd4d523c078ec521b55d',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e4b',
        module: {
          _id: '62fc9505682f0a64393a9e3f',
          moduleName: 'THIRD-PARTY',
          identifier: 'third-party',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b55e',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e4c',
        module: {
          _id: '62fc9505682f0a64393a9e40',
          moduleName: 'INCIDENT',
          identifier: 'incident',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
            'CONVERT-CLAIM',
          ],
          _id: '62fcbd4d523c078ec521b55f',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e4d',
        module: {
          _id: '62fc9505682f0a64393a9e41',
          moduleName: 'USER',
          identifier: 'user',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b560',
        },
      },
      {
        operations: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
        _id: '62fc9505682f0a64393a9e4e',
        module: {
          _id: '62fc9505682f0a64393a9e42',
          moduleName: 'ROLE',
          identifier: 'role',
        },
        roleId: {
          isDeleted: false,
          _id: '62fc9505682f0a64393a9e43',
          roleName: 'Super Admin',
        },
        accessMode: 'FULL_ACCESS',
        settings: {
          comment: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          attachments: ['CREATE', 'READ', 'UPDATE', 'DELETE'],
          extras: [
            'DOWNLOAD',
            'ANALYZE',
            'RECOMMEND',
            'IMPORT',
            'EXPORT',
            'COPY_LINK',
          ],
          _id: '62fcbd4d523c078ec521b561',
        },
      },
    ],
  },
}

export function getDummyUser() {
  return new Promise((resolve) => {
    setTimeout(() => {
      // getCurrentUser
      resolve(currentUser)
    }, 800)
  })
}

export const operations = ['CREATE', 'READ', 'UPDATE', 'DELETE']

export const mvrStatus = {
  NOT_SELECTED: 'Not selected',
  APPROVED: 'Approved',
  NOT_APPROVED: 'Not approved',
}

export const ACCESS_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  ANALYZE: 'ANALYZE',
  APPROVE: 'APPROVE',
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
  GENERATE_LINK: 'GENERATE_LINK',
  COPY_LINK: 'COPY_LINK',
  'CONVERT-CLAIM': 'CONVERT-CLAIM',
}

export const AccesLevels = {
  SELF_ACCESS: 'SELF_ACCESS',
  ORGANISATION_ACCESS: 'ORGANISATION_ACCESS',
  FULL_ACCESS: 'FULL_ACCESS',
  NO_ACCESS: 'NO_ACCESS',
}

export const LicenseStatus = [
  { key: 'VALID', value: 'Valid' },
  { key: 'SUSPENDED', value: 'Suspended' },
  { key: 'SURRENDERED', value: 'Surrendered' },
  { key: 'REVOKED', value: 'Revoked' },
  { key: 'EXPIRED', value: 'Expired' },
  { key: 'ACTIVE', value: 'Active' },
  { key: 'INACTIVE', value: 'Inactive' },
]

export const checkIfDateIsPast24Hours = (givenDate) => {
  const date = moment(givenDate).toDate()
  const now = new Date()
  const diff = now - date
  const diffInHours = diff / 1000 / 60 / 60
  return diffInHours > 22
}

export function timeNowFormat(givenDate) {
  return checkIfDateIsPast24Hours(givenDate)
    ? moment(givenDate).format('MM-DD-YYYY hh:mm A')
    : moment(givenDate).fromNow()
}

export const stateOptions = [
  {
    key: 'Alabama',
    value: 'Alabama',
  },
  {
    key: 'Alaska',
    value: 'Alaska',
  },
  {
    key: 'Arizona',
    value: 'Arizona',
  },
  {
    key: 'Arkansas',
    value: 'Arkansas',
  },
  {
    key: 'California',
    value: 'California',
  },
  {
    key: 'Colorado',
    value: 'Colorado',
  },
  {
    key: 'Connecticut',
    value: 'Connecticut',
  },
  {
    key: 'Delaware',
    value: 'Delaware',
  },
  {
    key: 'Florida',
    value: 'Florida',
  },
  {
    key: 'Georgia',
    value: 'Georgia',
  },
  {
    key: 'Hawaii',
    value: 'Hawaii',
  },
  {
    key: 'Idaho',
    value: 'Idaho',
  },
  {
    key: 'Illinois',
    value: 'Illinois',
  },
  {
    key: 'Indiana',
    value: 'Indiana',
  },
  {
    key: 'Iowa',
    value: 'Iowa',
  },
  {
    key: 'Kansas',
    value: 'Kansas',
  },
  {
    key: 'Kentucky',
    value: 'Kentucky',
  },
  {
    key: 'Louisiana',
    value: 'Louisiana',
  },
  {
    key: 'Maine',
    value: 'Maine',
  },
  {
    key: 'Maryland',
    value: 'Maryland',
  },
  {
    key: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    key: 'Michigan',
    value: 'Michigan',
  },
  {
    key: 'Minnesota',
    value: 'Minnesota',
  },
  {
    key: 'Mississippi',
    value: 'Mississippi',
  },
  {
    key: 'Missouri',
    value: 'Missouri',
  },
  {
    key: 'Montana',
    value: 'Montana',
  },
  {
    key: 'Nebraska',
    value: 'Nebraska',
  },
  {
    key: 'Nevada',
    value: 'Nevada',
  },
  {
    key: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    key: 'New Jersey',
    value: 'New Jersey',
  },
  {
    key: 'New Mexico',
    value: 'New Mexico',
  },
  {
    key: 'New York',
    value: 'New York',
  },
  {
    key: 'North Carolina',
    value: 'North Carolina',
  },
  {
    key: 'North Dakota',
    value: 'North Dakota',
  },
  {
    key: 'Ohio',
    value: 'Ohio',
  },
  {
    key: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    key: 'Oregon',
    value: 'Oregon',
  },
  {
    key: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    key: 'Rhode Island',
    value: 'Rhode Island',
  },
  {
    key: 'South Carolina',
    value: 'South Carolina',
  },
  {
    key: 'South Dakota',
    value: 'South Dakota',
  },
  {
    key: 'Tennessee',
    value: 'Tennessee',
  },
  {
    key: 'Texas',
    value: 'Texas',
  },
  {
    key: 'Utah',
    value: 'Utah',
  },
  {
    key: 'Vermont',
    value: 'Vermont',
  },
  {
    key: 'Virginia',
    value: 'Virginia',
  },
  {
    key: 'Washington',
    value: 'Washington',
  },
  {
    key: 'West Virginia',
    value: 'West Virginia',
  },
  {
    key: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    key: 'Wyoming',
    value: 'Wyoming',
  },
]

export const martialOptions = [
  { value: 'Unmarried', key: 'UNMARRIED' },
  { value: 'Married', key: 'MARRIED' },
  { value: 'Others', key: 'OTHERS' },
]

export const employeeStatusOptions = [
  { value: 'Active', key: 'ACTIVE' },
  { value: 'Terminated', key: 'TERMINATED' },
  { value: 'On leave', key: 'ON LEAVE' },
  { value: 'Inactive', key: 'INACTIVE' },
  { value: 'Retired', key: 'RETIRED' },
  { value: 'Deceased', key: 'DECEASED' },
]

export const genderOptions = [
  { value: 'Male', key: 'Male' },
  { value: 'Female', key: 'Female' },
  { value: 'Non-Binary', key: 'Non-Binary' },
]

export const CompensationTypes = [
  { value: 'Hourly', key: 'Hourly' },
  { value: 'Monthy', key: 'Monthy' },
]

export const amountRangeOptions = [
  {
    value: 'Under $ 1,000',
    key: '1-1000',
  },
  {
    value: '$ 1,000 - $ 5,000',
    key: '1001-5000',
  },
  {
    value: '$ 5,000 - $ 10,000',
    key: '5001-10000',
  },
  {
    value: 'Over $ 10,000',
    key: '10000-',
  },
]

export const fontFamily = [
  'Shadows Into Light',
  'Rock Salt',
  'Cedarville Cursive',
  'Monsieur La Doulaise',
  'Mr Dafoe',
  'Mrs Saint Delafield',
  'Sarina',
  'Lobster Two',
  'Pacifico',
  'Merienda One',
  'Arizonia',
  'Great Vibes',
  'Cookie',
  'Leckerli One',
  'Alex Brush',
  'Sacramento',
  'Montez',
  'Ruthie',
  'Qwigley',
  'Stalemate',
  'Mr Bedfort',
  'Dawning of a New Day',
  'League Script',
  'La Belle Aurore',
  'Bilbo',
  'Zeyada',
  'Over the Rainbow',
  'Just Me Again Down Here',
  'Miniver',
  'Vibur',
  'Sunshiney',
  'Nothing You Could Do',
  'The Girl Next Door',
  'Romanesco',
  'Kristi',
  'Calligraffitti',
  'Reenie Beanie',
  'Devonshire',
  'Crafty Girls',
  'Ribeye Marrow',
  'Meddon',
  'Mrs Sheppards',
  'Herr Von Muellerhoff',
  'Yellowtail',
]
