import { useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { saveRoleDetails, updateRoleDetails } from '../../services/roleService'

const initialPermissionData = (modules) => {

  return modules.map((module) => {
    return {
      module: module._id,
      moduleName: module.moduleName,
      accessMode: 'FULL_ACCESS',
      operations: ['CREATE', 'DELETE', 'READ', 'UPDATE'],
      settings: {
        attachments: ['CREATE', 'DELETE', 'READ', 'UPDATE'],
        comment: ['CREATE', 'DELETE', 'READ', 'UPDATE'],
        extras: [
          'DOWNLOAD',
          'ANALYZE',
          'IMPORT',
          'EXPORT',
          'GENERATE_LINK',
          'COPY_LINK',
          'CONVERT-CLAIM',
          "HISTORY",
          "NOTIFICATION"
        ],
      },
    }
  })
}

export function userRolesForm(editRoleData) {
  const modules = useSelector((state) => state.modules)

  const initialValues = useMemo(() => {
    return {
      role_data: {
        roleName: '',
        description: '',
      },
      permissions: initialPermissionData(modules),
    }
  }, [])

  const submitForm = async (values) => {
    //create
    try {
      if (!editRoleData) {
        await saveRoleDetails(values)
        toast.success('Role create successfully')
      } else {
        await updateRoleDetails(values.role_data._id, values)
        toast.success('Role updated successfully')
      }
    } catch (error) {
      toast.error('Failed to perform operation')
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: submitForm,
  })

  useEffect(() => {
    if (editRoleData) {
      const permissions = editRoleData?.permissions?.map((c) => {
        return {
          ...c,
          moduleName:
            modules.find((module) => module._id === c.module)?.moduleName || '',
        }
      })
      formik.setValues((v) => {
        return {
          ...v,
          ...editRoleData,
          permissions,
        }
      })
    }
  }, [editRoleData])

  return formik
}
