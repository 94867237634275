import React from 'react'
import {Modal} from 'reactstrap'
import UserForm from './UserForm'

import { ReactComponent as Close } from '../../../svgs/close.svg'

export default function UserModal({isOpen, data, title, onClose, id}) {
    return (
        <Modal
            isOpen={isOpen}
            toggle={onClose}
            backdrop={'static'}
            className={'modal-dialog-top modal-lg modal-right posi-rel'}
            style={{minWidth: '65vw'}}
        >
            <div>
                <a onClick={onClose} className="close close-left" aria-label="Close">
                    <Close />
                </a>
                 <UserForm data={data} id={id} onClose={onClose} title={title}/>
            </div>
        </Modal>
    )
}
