import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import Select1 from 'react-select'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import TextInput from '../../../components/form/TextInput'
import Select from '../../../components/form/Select'
import DatePickerField from '../../../components/form/DatePickerField'
import { saveVehicle, updateVehicle } from '../../../services/vehicleService'
import ImageUpload from '../../../components/form/ImageUpload'
import { getPoliciesByType } from '../../../services/policyService'
import { getEmployeesByType } from '../../../services/employeeService'
import AreYouSureModal from '../../../components/common/AreYouSureModal'
// import _ from 'lodash'
import TextArea from '../../../components/form/TextArea'
// import AddAttachment from '../../../components/addAttachment'
import AttachmentAddView from '../../../components/AttachmentAddView'
import { generateUID } from '../../../utils/generateUID'
import { addComma } from '../../incident/utils'
import { stateOptions } from '../../../configs/utils'
import FormikSelect from '../../claims/DirectClaim/FormikSelect'
import moment from 'moment'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'

const vendorTypes = [
  {
    value: 'U-Haul',
    key: 'U-Haul',
  },
  {
    value: 'Merchants',
    key: 'Merchants',
  },
  {
    value: 'Enterprise',
    key: 'Enterprise',
  },
  {
    value: 'Hertz',
    key: 'Hertz',
  },
  {
    value: 'LeasePlan',
    key: 'LeasePlan',
  },
  {
    value: 'Element',
    key: 'Element',
  },
  {
    value: 'Others',
    key: 'OTHERS',
  },
]

const vehicleConditionTypes = [
  { value: 'Active', key: 'Active' },
  { value: 'Inactive', key: 'Inactive' },
  {
    value: 'Out of service(Maintainence)',
    key: 'Out of Service(Maintainence)',
  },
  {
    value: 'Out of service(Body shop)',
    key: 'Out of Service(Body shop)',
  },
  {
    value: 'Inactive(Grounded)',
    key: 'Inactive(Grounded)',
  },
]

const newVehicle = {
  vehicleId: generateUID('vehicle'),
  image: '',
  model: '',
  type: '',
  manufactureYear: '',
  purchaseCost: '',
  milesTravelled: '',
  vinNumber: '',
  vanNumber: '',
  liscence: '',
  dateOfPurchase: null,
  driverInfo: null,
  policyDetails: null,
  conditionAtPurchase: '',
  attachments: [],
  vehicleAddress: null,
}

function VehicleForm({ onClose, data, title }) {
  const ref = useRef()
  const mountedRef = useRef(false)
  const [isNew, setIsNew] = useState(false)
  const [values, setValues] = useState(null)
  const [vPolicies, setVPolicies] = useState([])
  const [drivers, setDrivers] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [clientId, setClientId] = useState('')
  const [filterPolicies, setFilterPolicies] = useState([])
  const [filterDrivers, setFilterDrivers] = useState([])

  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const closeModal = () => setOpenModal(false)

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])

  const validationSchema = yup.object({
    clientId: yup.string().when('$exist', {
      is: () => !isClientExist,
      then: yup.string().required('Client is required!'),
      otherwise: yup.string(),
    }),
    leaseAgreementNumber: yup.number().optional()
  })

  useEffect(() => {
    mountedRef.current = true
    if (data) {
      setIsNew(false)
      setValues(data)
    } else {
      let vehicle = { ...newVehicle }
      if (!isClientExist) {
        vehicle['clientId'] = ''
      }
      setIsNew(true)
      setValues(vehicle)
    }
    init()
    return () => {
      mountedRef.current = false
    }
  }, [isNew, isClientExist])

  async function init() {
    await fetchPolicies()
    await fetchDrivers()
  }

  const fetchPolicies = async () => {
    const { data: policies } = await getPoliciesByType('AUTO')
    if (!mountedRef.current) return null
    setVPolicies(
      policies.data.map((p) => ({ policyId: p._id, policyName: p.policyId, clientId: p.clientId })),
    )
  }

  useEffect(() => {
    let policyListByClient = vPolicies.filter(
      (a) => a.clientId === values.clientId || a.clientId === selectedClientId || a.clientId === clientId,
    )
    setFilterPolicies([...policyListByClient])

    let driverListByClient = drivers.filter(
      (a) => a.clientId === values.clientId || a.clientId === selectedClientId || a.clientId === clientId,
    )
    setFilterDrivers([...driverListByClient])

  }, [values?.clientId, clientId, selectedClientId])

  const fetchDrivers = async () => {
    const { data: employees } = await getEmployeesByType({ isDriver: true })
    if (!mountedRef.current) return null
    setDrivers(
      employees.data.map((e) => ({
        driverId: e._id,
        driverName: e.lastName + ', ' + e.firstName,
        driverImage: e.driverImage,
        clientId: e.clientId,
      })),
    )
  }

  const handleSubmit = useCallback(
    async (values) => {
      if (!ref.current?.values['conditionAtPurchase']?.length) {
        toast.error('Please select vehicle condition.')
      } else if (!ref.current?.values['vehicleType']?.length) {
        toast.error('Please select vehicle type.')
      } else {
        values.dateOfPurchase = (values.dateOfPurchase
          ? moment(values.dateOfPurchase).format(NO_TIMEZONE_DATE_FORMAT)
          : null)
        values.dateOfSold = (values.dateOfSold
          ? moment(values.dateOfSold).format(NO_TIMEZONE_DATE_FORMAT)
          : null)
        setValues(values)
        setOpenModal(true)
      }
    },
    [isClientExist],
  )

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      if (isNew) {
        await saveVehicle(values)
        toast.success('Vehicle Added!')
      } else {
        await updateVehicle(values._id, values)
        toast.success('Vehicle Updated!')
      }
    } catch (err) {
      toast.error(err)
    } finally {
      onClose()
    }
  }

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={data ? data : newVehicle}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values: mainValues, setFieldValue, errors, handleBlur }) => (
        <Form className="indent-form">
          <div className="row m-0">
            <div
              className="col-7 p-4"
              style={{ height: '100vh', overflow: 'auto' }}
            >
              <h2
                className="modal-title text-primary mb-4"
                id="exampleModalLabel"
              >
                {title}
              </h2>
              <div className="border-bottom h6 py-2 mb-3">Vehicle Details</div>
              <div className="px-1">
                <div className="col-12 col-md-12 col-lg-12 p-0">
                  <div className="row m-0">
                    {mainValues && (
                      <div className="col-12 col-md-12 col-lg-12 p-0">
                        <div className="row m-0">
                          {isClientExist ? null : (
                            <div className="col-md-12 pl-0 pr-0">
                              <FormikSelect
                                label="Select Client"
                                name="clientId"
                                defaultLabel="Select Client"
                                options={getClientDropdownOpt()}
                                value={mainValues?.clientId || ''}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                handleBlur={handleBlur}
                                setClientId={setClientId}
                              />
                            </div>
                          )}
                          <div className="col-md-6 pl-0">
                            <TextInput label="Make" name="make" />
                          </div>
                          <div className="col-md-6 pr-0">
                            <TextInput label="Model" name="model" />
                          </div>
                          <div className="col-md-6 pl-0">
                            <TextInput
                              label="Year of Manufactured"
                              name="manufactureYear"
                              type="number"
                            />
                          </div>
                          <div className="col-md-6 pr-0">
                            <div className="form-group input-dollar">
                              <label>Cost at the time of purchase</label>
                              <span className="pr-4">$</span>
                              <input
                                className="form-control dollar-text"
                                label="Cost at the time of purchase"
                                name="purchaseCost"
                                type="text"
                                value={
                                  mainValues?.purchaseCost
                                    ? addComma(mainValues?.purchaseCost)
                                    : ''
                                }
                                onChange={(e) => {
                                  const value = e.target.value
                                  const onlyNums = value.replaceAll(',', '')
                                  setFieldValue('purchaseCost', onlyNums)
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 pl-0">
                            <TextInput
                              label="Miles Traveled"
                              name="milesTravelled"
                              type="number"
                            />
                          </div>
                          <div className="col-md-6 pr-0">
                            <TextInput label="VIN Number" name="vinNumber" />
                          </div>
                          <div className="col-md-6 pl-0">
                            <TextInput label="VAN Number" name="vanNumber" />
                          </div>
                          <div className="col-md-6 pr-0">
                            <TextInput
                              type="number"
                              label="Lease Agreement Number"
                              name="leaseAgreementNumber"
                            />
                          </div>
                          <div className="col-md-6 pl-0">
                            <TextInput label="License Plate" name="liscence" />
                          </div>
                          <div className="col-md-6 pr-0">
                            <Select
                              label="License Plate State"
                              name="licensePlateState"
                              defaultLabel="Select State"
                              options={stateOptions}
                            />
                          </div>

                          <div className="col-md-6 pl-0">
                            <div className="form-group">
                              <label>Vehicle Condition</label>
                              <br />
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="conditionAtPurchase"
                                  value="NEW"
                                />
                                <label className="form-check-label">New</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="conditionAtPurchase"
                                  value="USED"
                                />
                                <label className="form-check-label">Used</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 pr-0">
                            <Select
                              label="Vehicle Status"
                              name="vehicleStatus"
                              defaultLabel="Select Vehicle Status"
                              options={vehicleConditionTypes}
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-12 px-0">
                            <TextArea
                              name="vehicleAddress"
                              label="Vehicle Address"
                              value={mainValues.vehicleAddress || ''}
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-12 p-0">
                            <p>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary text-white px-3"
                              >
                                Save &amp; Close
                              </button>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {mainValues && (
              <div className="col-md-5 pt-3 modal-right-scroll">
                <div className="col-12 p-0">
                  <div className={'row'}>
                    <div className="col-md-6">
                      <label className="incident-text">
                        Vehicle ID
                        <span className="text-primery">
                          {mainValues ? mainValues.vehicleId : '-'}
                          <b className="text-dark font-weight-normal ml-2">
                            {' '}
                            (System Generated)
                          </b>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <ImageUpload
                        type="vehicle"
                        src={mainValues.image}
                        rowStyle={{ float: 'right' }}
                        imageStyle={{ height: '62px', width: '62px' }}
                        afterUpload={(val) => {
                          setFieldValue('image', val[0])
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <DatePickerField
                        name="dateOfPurchase"
                        label="Date of Purchased/Leased "
                      />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField name="dateOfSold" label="Date Sold" />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="row">
                    <div className="col-md-12">
                      <label style={{ fontWeight: 500 }}>Vehicle Type</label>
                      <div className="form-group d-block">
                        <div className="form-check form-check-inline">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="vehicleType"
                            value="OWNED"
                          />
                          <label className="form-check-label">Owned</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="vehicleType"
                            value="LEASED"
                          />
                          <label className="form-check-label">Leased</label>
                        </div>
                      </div>
                    </div>
                    {mainValues.vehicleType === 'LEASED' && (
                      <div className="col-md-12">
                        <Select
                          showOthers
                          label="Vendor"
                          name="vendor"
                          defaultLabel="Select Vendor"
                          options={vendorTypes}
                          othersValue="vendorType"
                        />
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Driver Details</label>
                        <Select1
                          placeholder="Select Driver"
                          getOptionLabel={(option) =>
                            option.driverName || option.driverId
                          }
                          getOptionValue={(option) => option}
                          name="driverInfo"
                          value={filterDrivers.length > 0 ? mainValues.driverInfo : null}
                          isClearable="true"
                          isSearchable="true"
                          onChange={(val) => setFieldValue('driverInfo', val)}
                          options={filterDrivers}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Vehicle Insurance Details</label>
                        <Select1
                          placeholder="Select Insurance"
                          getOptionLabel={(option) => option.policyName}
                          getOptionValue={(option) => option}
                          name="policyDetails"
                          value={filterDrivers.length > 0 ? mainValues.policyDetails : null}
                          isClearable="true"
                          isSearchable="true"
                          onChange={(val) =>
                            setFieldValue('policyDetails', val)
                          }
                          options={filterPolicies.length > 0 ? mainValues.policyDetails : null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    {/* <label className="d-block incident-text" htmlFor="package">
                      Attachments
                    </label>
                    <div className="row m-0">
                      {values.attachments &&
                        values.attachments.length > 0 &&
                        getAttachmentViews()}
                    </div>
                    {openAttachmentModal && (
                      <AddAttachment
                        module="vehicle"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                      data-toggle="modal"
                      data-target="#attachuplaods"
                      onClick={handleAddAttachmentToggle}
                    >
                      Add..
                    </button> */}
                    <AttachmentAddView
                      formik={{
                        setFieldValue: setFieldValue,
                        values: mainValues,
                      }}
                      module={'vehicle'}
                      uid={mainValues.vehicleId}
                    />
                  </div>
                </div>
              </div>
            )}
            <AreYouSureModal
              openModal={openModal}
              closeModal={closeModal}
              handleSubmit={handleSubmitModal}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default VehicleForm
