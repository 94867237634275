import { ErrorMessage, Field, useFormikContext } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { HumanBodyPart } from '../../components/Incidents/HumanBodyPart'
import PainLevel from '../../components/Incidents/PainLevel'
import SignaturePad from '../../components/common/SignaturePad'
import DatePickerField from '../../components/form/DatePickerField'
import TextArea from '../../components/form/TextArea'
import TextInput from '../../components/form/TextInput'
import { getThirdPartyInvolvementTypeDetails } from '../../utils/publicIncident'
import { painLevelTypes } from '../incident/utils'
import PhoneInput from 'react-phone-number-input'

function InjuryDetails() {
  const { values, setFieldValue, handleChange } = useFormikContext()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [signImg, setSignImg] = useState('')

  return (
    <>
      <div className="border-bottom h6 py-2 mb-4">
        <span className="text-primery font-weight-bold">Injuries Details</span>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label> Were there any Injuries? <span className='text-danger'>*</span></label>
          <div className=" d-block">
            <div className="form-check form-check-inline">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.isInjury`}
                value={'true'}
                id="isInjuryYes"
              />
              <label className="mt-2" htmlFor="isInjuryYes">
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline mr-5">
              <Field
                className="form-check-input"
                type="radio"
                name={`incidentDetails.isInjury`}
                value={'false'}
                id="isInjuryNo"
                onChange={(e) => {
                  handleChange(e)
                  setFieldValue('incidentDetails.isMedicalTreatment', null)
                }}
              />
              <label className="mt-2" htmlFor="isInjuryNo">
                No
              </label>
            </div>
          </div>
          <ErrorMessage
            className="text-danger"
            name={`incidentDetails.isInjury`}
            component="div"
          />
        </div>

        {values?.incidentDetails?.isInjury === 'true' && (
          <div className="form-group col-md-12 mb-0">
            <TextInput
              name={`incidentDetails.natureOfInjury`}
              withAsterisk
              label="Nature of Injury"
            />
            <TextInput
              name={`incidentDetails.isAmbulance`}
              label="Was an Ambulance called, if so, which hospital?"
            />

            <div className="my-3">
              {Object.values(values?.humanParts).join(', ')}
            </div>
            <div className="">
              <div className="text-center">
                <p className="mb-1 font-weight-bold lh-3">
                  In connection with your workplace injury, check all parts on
                  the diagram in which you feel pain. <span className='text-danger'>*</span>
                </p>
                <HumanBodyPart
                  selectedParts={Object.keys(values?.humanParts)}
                  onClick={(key, value) => {
                    let newSelectedBodyParts = {}
                    if (values?.humanParts[key]) {
                      newSelectedBodyParts = {
                        ...values?.humanParts,
                      }
                      delete newSelectedBodyParts[key]
                    } else {
                      newSelectedBodyParts = {
                        ...values?.humanParts,
                        [key]: value,
                      }
                    }
                    setFieldValue('humanParts', newSelectedBodyParts)
                  }}
                />
                <ErrorMessage component="p" name='humanParts' className='text-danger' />
              </div>

              <div className="">
                <div className="">
                  <label className="col-form-label">Pain Level <span className='text-danger'>*</span></label>
                </div>
                <PainLevel painLevelTypes={painLevelTypes} values={values} />
                <ErrorMessage component="p" name='incidentDetails.painLevel' className='text-danger' />
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Do You require Medical Treatment? <span className='text-danger'>*</span>
              </label>
              <div className="form-group d-block">
                <div className="form-check form-check-inline">
                  <Field
                    className="form-check-input"
                    type="radio"
                    checked={
                      values?.incidentDetails?.isMedicalTreatment === 'true'
                    }
                    name={`incidentDetails.isMedicalTreatment`}
                    value="true"
                    id="isMedicalTreatmentYes"
                  />
                  <label className='mt-2' htmlFor="isMedicalTreatmentYes">Yes</label>
                </div>
                <div className="form-check form-check-inline mr-5">
                  <Field
                    className="form-check-input"
                    type="radio"
                    checked={
                      values?.incidentDetails?.isMedicalTreatment === 'false'
                    }
                    name={`incidentDetails.isMedicalTreatment`}
                    value="false"
                    id="isMedicalTreatmentNo"
                  />
                  <label className='mt-2' htmlFor="isMedicalTreatmentNo">No</label>
                </div>
                <ErrorMessage
                  className="text-danger"
                  name={`incidentDetails.isMedicalTreatment`}
                  component="div"
                />
              </div>
            </div>

            {values?.incidentDetails?.isMedicalTreatment === 'true' && (
              <div>
                <div className="d-flex align-items-center flex-wrap mb-3">
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name={`incidentDetails.medicalTreatmentType`}
                      value={'triage'}
                      id="triage"
                    />
                    <label className='mt-2' htmlFor="triage">Triage</label>
                  </div>
                  <div className="form-check form-check-inline ">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name={`incidentDetails.medicalTreatmentType`}
                      value={'firstAid'}
                      id={'firstAid'}
                    />
                    <label className='mt-2' htmlFor="firstAid">First Aid</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name={`incidentDetails.medicalTreatmentType`}
                      value={'clinical'}
                      id={'clinical'}
                    />
                    <label className='mt-2' htmlFor="clinical">Clinical</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name={`incidentDetails.medicalTreatmentType`}
                      value={'emergency'}
                      id={'emergency'}
                    />
                    <label className='mt-2' htmlFor="emergency">Emergency</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name={`incidentDetails.medicalTreatmentType`}
                      value={'other'}
                      id={'other'}
                    />
                    <label className='mt-2' htmlFor="other">Other</label>
                  </div>
                </div>
                {(values?.incidentDetails?.medicalTreatmentType.includes(
                  'emergency',
                ) ||
                  values?.incidentDetails?.medicalTreatmentType.includes(
                    'clinical',
                  )) && (
                    <div className="form-group">
                      <TextArea
                        cols={30}
                        rows={5}
                        label="Name and Location of Medical Provider:"
                        placeholder="Name and Location of Medical Provider:"
                        name="incidentDetails.medicalTreatmentProviderInfo"
                      />
                    </div>
                  )}
                {values?.incidentDetails?.medicalTreatmentType.includes(
                  'other',
                ) && (
                    <div className="form-group">
                      <TextArea
                        cols={30}
                        rows={5}
                        label=""
                        placeholder="Other"
                        name="incidentDetails.medicalTreatmentOtherNotes"
                      />
                    </div>
                  )}
                <div className="form-group mb-0 mt-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Was a member of the public injured? <span className='text-danger'>*</span>
                  </label>
                  <div className="form-group d-block mb-0">
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        checked={
                          values?.incidentDetails?.publicMemberInjured ===
                          'true'
                        }
                        name={`incidentDetails.publicMemberInjured`}
                        value="true"
                        id="publicMemberInjuredYes"
                      />
                      <label className='mt-2' htmlFor="publicMemberInjuredYes">Yes</label>

                    </div>
                    <div className="form-check form-check-inline mr-5">
                      <Field
                        className="form-check-input"
                        type="radio"
                        checked={
                          values?.incidentDetails?.publicMemberInjured ===
                          'false'
                        }
                        name={`incidentDetails.publicMemberInjured`}
                        value="false"
                        id="publicMemberInjuredNo"
                      />
                      <label className='mt-2' htmlFor="publicMemberInjuredNo">No</label>
                    </div>
                  </div>
                </div>
                <ErrorMessage
                  className="text-danger"
                  name={`incidentDetails.publicMemberInjured`}
                  component="div"
                />
                {values?.incidentDetails?.publicMemberInjured === 'true' && (
                  <div className="form-group mt-2">
                    <TextArea
                      cols={30}
                      rows={5}
                      placeholder="If yes, provide details"
                      name="incidentDetails.publicMemberInjuredDetails"
                    />
                  </div>
                )}
                <div className="form-group mt-3">
                  <TextArea
                    cols={30}
                    rows={5}
                    label="Object/Substance which directly injured or exposed the employee?"
                    name="incidentDetails.substance"
                  />
                </div>
                <div className="form-group">
                  <TextArea
                    withAsterisk
                    cols={30}
                    rows={5}
                    label="Describe the alleged injury"
                    name="incidentDetails.allegedInjury"
                    placeholder="Sprain, Bruise, strain, fracture, slip, fall, etc."
                  />
                </div>
                <div className="form-group">
                  <TextArea
                    withAsterisk
                    cols={30}
                    rows={5}
                    label="Describe Injury in detail"
                    placeholder=" In detail, describe what happened in the accident/incident below"
                    name="incidentDetails.incidentDescription"
                  />
                </div>

                <div className="form-group mb-3">
                  <label>Type of Third-Party Involvement <span className='text-danger'>*</span></label>
                  <div className="d-flex flex-wrap">
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'None'}
                        id={'None'}
                      />
                      <label className='mt-2' htmlFor="None">None</label>
                    </div>
                    <div className="form-check form-check-inline ">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'Animal'}
                        id={'Animal'}
                      />
                      <label className='mt-2' htmlFor="Animal">Animal</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'Person/Colleague'}
                        id={'Person/Colleague'}
                      />
                      <label className='mt-2' htmlFor="Person/Colleague">Person/Colleague</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'Vehicle'}
                        id={'Vehicle'}
                      />
                      <label className='mt-2' htmlFor="Vehicle">Vehicle</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'Equipment'}
                        id={'Equipment'}
                      />
                      <label className='mt-2' htmlFor="Equipment">Equipment</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name={`incidentDetails.thirdPartyInvolvementType`}
                        value={'Other'}
                        id={'Other'}
                      />
                      <label className='mt-2' htmlFor="Other">Other</label>
                    </div>
                  </div>
                  <ErrorMessage
                    className="text-danger"
                    name={`incidentDetails.thirdPartyInvolvementType`}
                    component="div"
                  />
                </div>
                {values?.incidentDetails?.thirdPartyInvolvementType ===
                  'Person/Colleague' && (
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <TextInput
                          name={`incidentDetails.injuredThirdPartyDetails.firstName`}
                          label=" First Name"
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <TextInput
                          name={`incidentDetails.injuredThirdPartyDetails.lastName`}
                          label=" Last Name"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <TextInput
                          name={`incidentDetails.injuredThirdPartyDetails.email`}
                          label=" Email"
                          type="email"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        {/* <TextInput
                          changeOnUndefined={true}
                          isPhone
                          label="Contact Number"
                          name="incidentDetails.injuredThirdPartyDetails.contactNumber"
                          onPhoneChange={(phone) => {
                            setFieldValue(
                              'incidentDetails.injuredThirdPartyDetails.contactNumber',
                              phone || '',
                            )
                          }}
                          value={
                            values?.incidentDetails?.injuredThirdPartyDetails
                              ?.contactNumber || ''
                          }
                        /> */}
                        <label>Contact Number</label>
                          <PhoneInput
                            // displayInitialValueAsLocalNumber={true}
                            limitMaxLength={true}
                            value={values?.incidentDetails?.injuredThirdPartyDetails?.contactNumber || ''}
                            name="incidentDetails.injuredThirdPartyDetails.contactNumber"
                            defaultCountry="US"
                            international={false}
                            className={'form-control'}
                            placeholder="Contact Number"
                            onChange={(phone) => {
                              if (phone) {
                                setFieldValue('incidentDetails.injuredThirdPartyDetails.contactNumber', phone || '',)
                              } else {
                                setFieldValue(`incidentDetails.injuredThirdPartyDetails.contactNumber`, '')
                              }
                            }}
                          />
                          <ErrorMessage
                            className="text-danger"
                            name="incidentDetails.injuredThirdPartyDetails.contactNumber"
                            component="div"
                          />
                      </div>
                    </div>
                  )}

                {values?.incidentDetails?.thirdPartyInvolvementType !== '' &&
                  values?.incidentDetails?.thirdPartyInvolvementType !==
                  'None' &&
                  getThirdPartyInvolvementTypeDetails(
                    values?.incidentDetails?.thirdPartyInvolvementType,
                  )}
              </div>
            )}

            {values?.incidentDetails?.isMedicalTreatment === 'false' && (
              <div>
                <div className="border-bottom h6 py-2 mb-4">
                  <span className="text-primery font-weight-bold">
                    Declination of Medical Attention
                  </span>
                </div>
                <p>
                  I, {values?.userDetails?.firstName}{' '}
                  {values?.userDetails?.lastName} have been offered the
                  opportunity to have medical care for the above stated injury
                  by my employer. I feel as though I do not require medical care
                  at this time and can return to work.
                </p>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <TextInput
                      withAsterisk
                      name={`incidentDetails.medicalDeclinationDetails.empName`}
                      label="Employee Name"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <DatePickerField
                      withAsterisk
                      maxDate={new Date()}
                      name="incidentDetails.medicalDeclinationDetails.dateOfIncident"
                      label="Date of Incident"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <div className="form-group col-md-12 addIncidentUploadFile">
                      <label className="d-block" htmlFor="incidentUpload">
                        Signature <span className='text-danger'>*</span>
                      </label>
                      <div className="row m-0"></div>
                      <button
                        type="button"
                        className="btn btn-outline-secondary side-btn mr-3 upload-sign w-100"
                        onClick={() => {
                          setDialogOpen(true)
                        }}
                      >
                        <i className="fas fa-paperclip" aria-hidden="true"></i>
                        <span>Upload Signature</span>
                      </button>
                    </div>
                    <ErrorMessage
                      className="text-danger"
                      name={`incidentDetails.medicalDeclinationDetails.empSignature`}
                      component="div"
                    />
                    {signImg && (
                      <div className="form-group col-md-12 mt-5">
                        <div className="show-image d-flex align-items-center">
                          <img src={signImg} />
                          <i
                            onClick={() => {
                              setSignImg('')
                            }}
                            style={{ cursor: 'pointer' }}
                            className="fa fa-trash"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6 ">
                    <label>Today&apos;s Date</label>
                    <input
                      className="form-control"
                      type="text"
                      value={moment().format('MM/DD/YYYY')}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {dialogOpen && (
        <SignaturePad
          setDialogOpen={setDialogOpen}
          setSignImg={setSignImg}
          signImg={signImg}
          uid={values?.publicIncidentId}
          addSignature={(id) => {
            setFieldValue(
              'incidentDetails.medicalDeclinationDetails.empSignature',
              id,
            )
          }}
        />
      )}
    </>
  )
}

export default InjuryDetails
