import { toast } from 'react-toastify'
import { saveIncident, updateIncident } from '../../services/incidentService'
import { convertDateToStringIncidentForm } from '../incident/utils'

export const handleIncidentSave = async (
  isVehicular,
  values,
  isNew,
  onClose,
  context,
) => {

  let filterdValues = convertDateToStringIncidentForm(values);

  if (!isVehicular) {
    filterdValues.vehiclesInvolved = []
  } else {
    if (filterdValues.vehiclesInvolved.length > 0) {
      let vehInvolved = []
      filterdValues?.vehiclesInvolved?.forEach(function (vehicleInvolved) {
        let vehicleDamages = []
        if (vehicleInvolved.vehicleDamages) {
          vehicleInvolved?.vehicleDamages.forEach(function (vehDamage) {
            if (vehDamage) {
              vehicleDamages.push(vehDamage.value)
            }
          })
        }
        vehInvolved.push({
          ...vehicleInvolved,
          vehicleDamages: vehicleDamages,
        })
      })
      filterdValues.vehiclesInvolved = vehInvolved
    }
  }

  if (
    filterdValues.propertiesInvolved?.length > 0
    //  &&
    // filterdValues.propertiesInvolved.some(
    //   (property) => !property.isThirdPartyPropertyDamaged,
    // )
  ) {
    let propertiesInvolved = []
    filterdValues?.propertiesInvolved?.forEach(function (propertyInvolved) {
      let propertyDamages = []
      propertyInvolved.propertyDamages.forEach(function (propertyDamage) {
        if (propertyDamage) {
          propertyDamages.push(propertyDamage.value)
        }
      })
      propertiesInvolved.push({
        ...propertyInvolved,
        propertyDamages: propertyDamages,
      })
    })
    filterdValues.propertiesInvolved = propertiesInvolved
  }

  if (filterdValues.partiesInvolved) {
    filterdValues.partiesInvolved.forEach((party) => {
      if (party?.personDetails?.employeeId === '') {
        delete party.personDetails.employeeId
      }
    })
  }
  try {
    if (context === "publicIncidentToIncident" || isNew) {
      let { data } = await saveIncident(filterdValues)
      if (context !== 'claim') {
        toast.success('Incident Added!')
        onClose()
      } else {
        return data.data
      }
    } else {
      let { data } = await updateIncident(filterdValues._id, filterdValues)
      context !== 'claim' && toast.success('Incident Updated!')
      onClose()
    }
  } catch (err) {
    toast.error(err)
  }
}
