import React from 'react'
import {  Modal, ModalBody } from 'reactstrap'

export default function ConfirmationModal({name, isOpen, onClose, onConfirm, isDelete = true}) {
  return (
    <>
      <Modal isOpen={isOpen} backdrop="static" toggle={onClose}>
        <ModalBody>
          <div className="modal-content rounded-20 border-0">
            <div className="modal-body pb-0">
              <div className="text-center mb-10">
                <img
                  src="/assets/images/add_emp.png"
                  alt="are you sure"
                />
              </div>
              <h5 className="modal-title text-center">
                Are you sure you would like to {isDelete ? `delete` : "restore"} <span className="capitalizeChar">{name}</span>?
              </h5>
            </div>
            <div className="modal-footer claim-modal">
              <button
                onClick={onConfirm}
                type="button"
                className="btn btn-primary pl-10 pr-10"
                data-dismiss="modal"
              >
                Confirm
              </button>{' '}
              <button
                onClick={onClose}
                type="button text-dark"
                className="btn no-btn pl-10 pr-10"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
