import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/incident`

export function getIncidents() {
  return http.get(`${apiEndpoint}/get-incident-list`)
}

export function exportIncidents() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}


export function getIncident(id) {
  return http.get(`${apiEndpoint}/get-incident/${id}`)
}

export function updateIncident(id, incident) {
  return http.put(`${apiEndpoint}/update-incident/${id}`, incident)
}
export function deleteIncident(id, incident) {
  return http.put(`${apiEndpoint}/delete-incident/${id}`, incident)
}

export function deleteBlukIncident(ids) {
  return http.put(`${apiEndpoint}/bulk-delete`, ids);
}

export function saveIncident(incident) {
  // const date = new Date()
  // const incidentId = `INC-${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, 0)}-${date.getMilliseconds()}`

  return http.post(`${apiEndpoint}/save`, {
    ...incident
  })
}

export function addtoExistingClaim(id, claim) {
  return http.put(`${apiEndpoint}/add-incident-to-claim/${id}`, claim)
}

export function convertToClaim(claim) {
  return http.post(`${apiEndpoint}/convert-claim`, claim)
}

