import { ErrorMessage, FieldArray, useFormikContext } from 'formik'
import React from 'react'
import TextInput from '../form/TextInput'
import PhoneInput from 'react-phone-number-input'

const PublicIncidentWitnessesAdd = ({ witnesses, setWitnesses }) => {
  const { values, setFieldValue } = useFormikContext()

  return (
    <div className='p-1'>
      <FieldArray name='incidentDetails.witnesses'>
        {
          ({ remove, push }) => (<>
            {
              values?.incidentDetails?.witnesses?.map((_, index) => (<div className='d-flex align-items-center' key={index}>
                <div className='row'>
                  <div className='col-md-6'>
                    <TextInput
                      withAsterisk
                      name={`incidentDetails.witnesses.${index}.name`}
                      label="Witness Name"
                    />
                  </div>
                  <div className='col-md-6'>
                    <label>Contact Number <span className='text-danger'>*</span></label>
                      <PhoneInput
                        // displayInitialValueAsLocalNumber={true}
                        limitMaxLength={true}
                        value={values?.incidentDetails?.witnesses?.[index]?.contactNumber || ''}
                        name={`incidentDetails.witnesses.${index}.contactNumber`}
                        defaultCountry="US"
                        international={false}
                        className={'form-control'}
                        placeholder="Contact Number"
                        onChange={(phone) => {
                          if (phone) {
                            setFieldValue(`incidentDetails.witnesses.${index}.contactNumber`, phone || "")
                          } else {
                            setFieldValue(`incidentDetails.witnesses.${index}.contactNumber`, '')
                          }
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name={`incidentDetails.witnesses.${index}.contactNumber`}
                        component="div"
                      />
                  </div>
                </div>
                {
                  (values?.incidentDetails?.witnesses.length ?? 0) > 1 && (<div className="ml-4 mt-1">
                    <i
                      data-testid={`button-remove-witness-${index}`}
                      onClick={() => remove(index)}
                      style={{ cursor: 'pointer' }}
                      className="fa fa-trash"
                    />
                  </div>)
                }
              </div>))
            }
            <button
              data-testid="button-add-witness"
              type="button"
              className="btn btn-primary border-radius-12 mb-2"
              onClick={() => push({
                name: "",
                contactNumber: ""
              })}
            >
              Add
            </button>
          </>)
        }
      </FieldArray>
    </div>
  )
}

export default PublicIncidentWitnessesAdd
