import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/notification`

export function saveUserFirebaseToken(token) {
  return http.post(`${apiEndpoint}/save-token`, { firebaseToken: token })
}
export function deleteFirebaseToken(token) {
  return http.post(`${apiEndpoint}/delete-token`, { firebaseToken: token })
}

export function getNotifications() {
  return http.get(`${apiEndpoint}/list`)
}

export function markNotificationRead(id) {
  return http.put(`${apiEndpoint}/mark-read/${id}`)
}

export function markAllNotificationRead() {
  return http.post(`${apiEndpoint}/mark-all-read`)
}
