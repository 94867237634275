import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import Loader from '../../components/common/Loader'
import
  {
    deleteForever,
    getTrashList,
    restoreOthers
  } from '../../services/trashService'
import TrashList from './TrashList'

function Trash(props) {
  const dispatch = useDispatch()
  const [ids, setIDs] = useState([])
  const [data, setData] = useState([])
  const [context, setContext] = useState('')
  const [selectedTab, setSelectedTab] = useState('users')
  const [selectedFilterId, setSelectedFilterId] = useState('All')
  const typeArr = [
    'Policy',
    'Employee',
    'Vehicle',
    'Property',
    'Claim',
    'Client',
    'MVR',
    'Third Party',
    'Incident',
    'User',
  ]
  const [delForever, setDelForever] = useState({})
  const [restoreForever, setRestoreForever] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const epochs = [
    ['year', 31536000],
    ['month', 2592000],
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1],
  ]

  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedTab])
  

  const getResponseData = (responseData) => {
    let ids = []
    responseData.forEach(function (data) {
      data.deletdBy = (
        <a className="codeal" style={{ marginLeft: '20px' }}>
          {' '}
          {`${data.deletedBy?.firstName?.charAt(
            0,
          )}${data.deletedBy?.lastName?.charAt(0)}`}{' '}
        </a>
      )
      data.time = timeAgo(data.created)
      data.action = (
        <div style={{ display: 'flex' }}>
          <div
            style={{ cursor: 'pointer', color: '#207BE3' }}
            className="mr-2"
            onClick={() => setDelForever(data)}
          >
            Delete Forever
          </div>
          <div
            style={{ cursor: 'pointer', color: '#207BE3' }}
            className="ml-2"
            onClick={() => setRestoreForever({ isModule: false, data })}
          >
            Restore
          </div>
        </div>
      )
      ids.push(data._id)
    })
    setData(responseData)
    setIDs(ids)
  }

  const fetchList = async (val = '') => {
    setData([])
    try {
      if (selectedTab === 'users') {
        let payload = {
          module: 'user',
        }
        const { data } = await getTrashList(payload)
        let responseData = data.data
        await getResponseData(responseData)
      } else {
        let payload = {
          excludeUser: true,
        }
        if (val !== 'All') {
          payload.module = val
        }
        const { data } = await getTrashList(payload)
        let responseData = data.data
        await getResponseData(responseData)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const timeAgo = (date) => {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000)
    const { interval, epoch } = getDuration(timeAgoInSeconds)
    const suffix = interval === 1 ? '' : 's'
    return `${interval} ${epoch}${suffix} ago`
  }

  const getDuration = (timeAgoInSeconds) => {
    for (let [name, seconds] of epochs) {
      const interval = Math.floor(timeAgoInSeconds / seconds)
      if (interval >= 1) {
        return {
          interval: interval,
          epoch: name,
        }
      }
    }
  }

  const handleFilter = async (val) => {
    setIsLoading(true)
    await setSelectedFilterId(val)
    await fetchList(val)
  }

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView: (
          <div className="dropdown-menu">
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Policy',
              })}
              href="#"
              onClick={() => handleFilter('policy')}
            >
              Policy
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Employee',
              })}
              href="#"
              onClick={() => handleFilter('employee')}
            >
              Employee
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Property',
              })}
              href="#"
              onClick={() => handleFilter('property')}
            >
              Property
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Claim',
              })}
              href="#"
              onClick={() => handleFilter('claim')}
            >
              Claim
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Client',
              })}
              href="#"
              onClick={() => handleFilter('client')}
            >
              Client
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'MVR',
              })}
              href="#"
              onClick={() => handleFilter('mvr')}
            >
              MVR
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Third Party',
              })}
              href="#"
              onClick={() => handleFilter('third-party')}
            >
              Third Party
            </a>
            <a
              className={classNames('dropdown-item', {
                activeLink: selectedFilterId === 'Incident',
              })}
              href="#"
              onClick={() => handleFilter('incident')}
            >
              Incident
            </a>
          </div>
        ),
      },
    }
  }

  const handleEmptyTrash = async () => {
    try {
      if (ids.length > 0) {
        const { data } = await deleteForever(ids)
        toast.success('Deleted Successfully')
        context === '' ? setContext('fetchList') : setContext('')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteForever = async (id) => {
    try {
      //TO DO : replace with bulk delete API
      const { data } = await deleteForever(id)
      toast.success('Deleted Successfully')
      context === '' ? setContext('fetchList') : setContext('')
    } catch (e) {
      console.log(e)
    }
  }

  const handleRestore = async (id) => {
    try {
      const { data } = await restoreOthers(id)
      toast.success('Restored Successfully')
      context === '' ? setContext('fetchList') : setContext('')
    } catch (e) {
      console.log(e)
    }
  }

  const handleDeleteModal = async () => {
    try {
      await handleDeleteForever([delForever.eventId])
    } catch (error) {
      console.error(error)
    }
    setDelForever({})
  }

  const handleRestoreModal = async () => {
    try {
      if (restoreForever.isModule) {
        await handleRestore({
          moduleName: restoreForever.moduleName,
          docId: restoreForever?.data?.eventId,
        })
      } else {
        await handleRestore(restoreForever?.data?.eventId)
      }
    } catch (error) {
      console.error(error)
    }
    setRestoreForever({})
  }

  let filterData = getFiltersData()

  if(isLoading) return( <div style={{marginTop: "250px"}} ><Loader loading={isLoading}/></div>)

  return (
    <div
      className={'position-rel'}
      style={{ marginBottom: '15px', width: 'calc(100% - 70px)' }}
    >
      <div className={'sticki-top'}>
        <div className={'px-4 pl-6  user-mang'}>
          <div className={'pt-5'}></div>
          <div>
            <div className={'col-md-12'}>
              <div>
                <div className={'row'}>
                  <div className={'col-12 col-sm-12 col-md-12 col-lg-12'}>
                    <h1 className={'mb-4'}>Trash</h1>
                  </div>
                  {selectedTab !== 'users' && (
                    <div className={'col-12 col-sm-6 col-md-4 col-lg-12'}>
                      <div className={'download-btn text-right'}>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              className="fas fa-filter mr-2"
                              aria-hidden="true"
                            ></i>{' '}
                            Filter
                          </button>
                          {filterData.filter.dropdownView}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-sm-12 col-md-1 col-lg-12">
                    <nav className="mb-1" style={{ height: '62px' }}>
                      <div
                        className="nav nav-tabs nav-fill"
                        id="nav-tab"
                        role="tablist"
                      >
                        <div className=" nav-link text-left hvr-pulse1">
                          {
                            <div className="download-btn">
                              {/* <a
                                href="#"
                                className="btn custom-btn pl-3 pr-3 mr-2 disabled"
                                onClick={handleEmptyTrash}
                                style={{
                                  padding: '5px',
                                  pointerEvents: 'none',
                                }}
                              >
                                <svg
                                  id="delete"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <rect
                                    id="Rectangle_2736"
                                    data-name="Rectangle 2736"
                                    width="24"
                                    height="24"
                                    fill="none"
                                  ></rect>
                                  <g
                                    id="delete-2"
                                    data-name="delete"
                                    transform="translate(5.126 3.537)"
                                  >
                                    <path
                                      id="Path_49"
                                      data-name="Path 49"
                                      d="M222.8,154.7a.4.4,0,0,0-.4.4v7.558a.4.4,0,0,0,.8,0V155.1A.4.4,0,0,0,222.8,154.7Zm0,0"
                                      transform="translate(-213.587 -148.665)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_50"
                                      data-name="Path 50"
                                      d="M104.8,154.7a.4.4,0,0,0-.4.4v7.558a.4.4,0,0,0,.8,0V155.1A.4.4,0,0,0,104.8,154.7Zm0,0"
                                      transform="translate(-100.262 -148.665)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_51"
                                      data-name="Path 51"
                                      d="M1.123,5.038V14.8A2.187,2.187,0,0,0,1.7,16.312a1.952,1.952,0,0,0,1.416.612h7.5a1.952,1.952,0,0,0,1.416-.612,2.187,2.187,0,0,0,.581-1.508V5.038A1.514,1.514,0,0,0,12.23,2.06H10.2v-.5A1.557,1.557,0,0,0,8.63,0H5.11a1.557,1.557,0,0,0-1.57,1.566v.5H1.511a1.514,1.514,0,0,0-.388,2.978Zm9.5,11.094H3.12a1.255,1.255,0,0,1-1.2-1.328V5.073h9.91V14.8A1.255,1.255,0,0,1,10.62,16.132ZM4.333,1.564A.764.764,0,0,1,5.11.792H8.63a.764.764,0,0,1,.777.773v.5H4.333ZM1.511,2.853H12.23a.714.714,0,0,1,0,1.427H1.511a.714.714,0,0,1,0-1.427Zm0,0"
                                      transform="translate(0.003 0.001)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_52"
                                      data-name="Path 52"
                                      d="M163.8,154.7a.4.4,0,0,0-.4.4v7.558a.4.4,0,0,0,.8,0V155.1A.4.4,0,0,0,163.8,154.7Zm0,0"
                                      transform="translate(-156.925 -148.665)"
                                      fill="#fff"
                                    ></path>
                                  </g>
                                </svg>
                                <span className="pt-1 mt-2 trsh mb-2">
                                  Empty Trash
                                </span>{' '}
                              </a>{' '} */}
                              <span className="trashtxt ml-4">
                                {' '}
                                Items older than 90 days auomatically delete
                                forever.{' '}
                              </span>
                            </div>
                          }
                        </div>
                        <a
                          className={
                            'nav-item nav-link tabtbl ' +
                            (selectedTab === 'users' ? 'active' : '')
                          }
                          id={'users-tab'}
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          key={'users-tab'}
                          aria-controls={'nav-users'}
                          aria-selected="false"
                          onClick={() => {
                            setSelectedFilterId('All')
                            setSelectedTab('users')
                          }}
                        >
                          Users
                        </a>
                        <a
                          className={
                            ' nav-item nav-link tabtbl ' +
                            (selectedTab === 'others' ? 'active' : '')
                          }
                          id={'others-tab'}
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          key={'others-tab'}
                          aria-controls={'nav-others'}
                          aria-selected="false"
                          onClick={() => {
                            setSelectedFilterId('All')
                            setSelectedTab('others')
                          }}
                        >
                          Others
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrashList
        data={data}
        typeArr={typeArr}
        setContext={setContext}
        selectedFilterId={selectedFilterId}
      />
      <ConfirmationModal
        name={delForever?.name || ''}
        isOpen={Object.keys(delForever).length > 0}
        onClose={() => setDelForever({})}
        onConfirm={handleDeleteModal}
      />
      <ConfirmationModal
        name={restoreForever?.data?.name || ''}
        isOpen={Object.keys(restoreForever).length > 0}
        isDelete={false}
        onClose={() => setRestoreForever({})}
        onConfirm={handleRestoreModal}
      />
    </div>
  )
}

export default Trash
