import apiUrl from '../configs/api'
import http from './httpService'

const apiEndpoint = `${apiUrl}/employee`

export function getEmployees(config) {
  return http.get(`${apiEndpoint}/get-employee-list`, config)
}

export function exportEmployees() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getEmployeesByType(params) {
  return http.get(`${apiEndpoint}/get-employee-by-filter`, { params })
}

export function getEmployee(id) {
  return http.get(`${apiEndpoint}/get-employee/${id}`)
}

export function updateEmployee(id, employee) {
  return http.put(`${apiEndpoint}/update-employee/${id}`, employee)
}

export function deleteEmployee(id, employee) {
  return http.put(`${apiEndpoint}/delete-employee/${id}`, employee)
}

export function deleteBlukEmployee(ids) {
  return http.put(`${apiEndpoint}/bulk-delete`, ids);
}

export function saveEmployee(employee) {
  return http.post(`${apiEndpoint}/save`, employee)
}

export function getTotalInsuranceValueOfEmployee() {
  return http.get(`${apiEndpoint}/get-total-insurance-value`)
}
