/* eslint-disable react/prop-types */
import classNames from 'classnames'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import apiUrl from '../configs/api'

import { useGetPermission } from '../hooks/useGetPermission'
import { useShowModule } from '../hooks/useShowModule'
import SearchBox from './common/searchBox'
import { GenerateLinkModal } from './GenerateLinkModal'
import AttachmentIcon from '../icons/AttachmentIcon'
import { handleExport } from '../utils/export'

function ListPageHeader({
  style,
  heading,
  showTabs,
  filtersData,
  showGenerateLink,
  showFilters,
  searchQuery,
  hideDeleteButton,
  filterCount = 0,
  onAdd = () => {},
  handleExportToCsv = () => {},
  handleImportfromCsv = () => {},
  onSearchChange = () => {},
  categories,
  setTab,
  tabsData,
  selectedTabId,
  selectedCategories,
  addCategory,
  removeCategory,
  hideSearch,
  handleBulkCheckClick,
  modules,
  hideHeader = false,
  hideImport = false,
  exportFormatted = false,
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const sideNavModule = useSelector((state) => state.sideNavModule)
  const selectedTabIdState = useSelector((state) => state.selectedTabId)
  const [seletedTabModule, setSelectedTabModule] = useState(modules[0])

  const importPermission = useGetPermission(seletedTabModule, 'IMPORT')
  const exportPermission = useGetPermission(seletedTabModule, 'EXPORT')
  const createPermission = useGetPermission(seletedTabModule, 'CREATE')
  const [showGenerateLinkModal, setGenerateLinkModal] = useState(false)
  const { showModule } = useShowModule()
  const tabId =
    seletedTabModule.toLowerCase() === 'mvr'
      ? selectedTabId
      : selectedTabIdState

  let getTabsView = () => {
    return tabsData
      ?.filter(() => showModule(modules))
      ?.map((tabData) => (
        <a
          data-testid={tabData.id + '-tab'}
          className={classNames('nav-item', 'nav-link', 'tabtbl', {
            active: tabId === tabData.id,
            customStylePadding: modules.includes('MVR'),
          })}
          id={tabData.id + '-tab'}
          data-toggle="tab"
          href={'#' + tabData.id}
          style={tabData.style}
          role="tab"
          key={tabData.id + '-tab'}
          aria-controls={'nav-' + tabData.id}
          aria-selected="false"
          onClick={() => {
            setTab(tabData.id)
            setSelectedTabModule(tabData.module)
          }}
        >
          {tabData.label}
        </a>
      ))
  }

  return (
    <>
      <div className="sticki-top">
        <div className="pl-6 pt-4 user-mang  ">
          <div className="container-fluid">
            <h1 className="pb-0">{heading}</h1>
            <div className="row mt-3 mb-2">
              <div className="col-12 col-sm-6 col-md-6 col-lg-3 text-right">
                {!hideHeader && !hideSearch && (
                  <SearchBox
                    value={searchQuery}
                    onChange={onSearchChange}
                    categories={categories}
                    selectedCategories={selectedCategories}
                    addCategory={addCategory}
                    removeCategory={removeCategory}
                  />
                )}
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-9" style={style}>
                <div className="download-btn text-right">
                  {!hideHeader && !showGenerateLink && createPermission && (
                    <a onClick={onAdd} className="btn btn-warning custom-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="Path_16690"
                          data-name="Path 16690"
                          d="M-16.32-7.495h-6.5V-14.07h-3v6.575h-6.5v2.886h6.5V1.93h3V-4.609h6.5Z"
                          transform="translate(32.32 14.07)"
                          fill="#fff"
                        />
                      </svg>
                      <span className="pl-2"> Add New</span>
                    </a>
                  )}

                  {showGenerateLink && (
                    <a
                      data-testid="btn-generate-link"
                      onClick={() => setGenerateLinkModal(true)}
                      className="btn btn-warning custom-btn"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="Path_16690"
                          data-name="Path 16690"
                          d="M-16.32-7.495h-6.5V-14.07h-3v6.575h-6.5v2.886h6.5V1.93h3V-4.609h6.5Z"
                          transform="translate(32.32 14.07)"
                          fill="#fff"
                        />
                      </svg>
                      <span className="pl-2">Generate Link</span>
                    </a>
                  )}

                  {/* {!hideHeader && exportPermission && (
                    <a
                      onClick={handleExport}
                      className="btn btn-warning export-btn pl-3 pr-3 ml-3 mr-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Export CSV"
                    >
                      <span>
                        <AttachmentIcon />
                      </span>
                      <span className="export">
                        {exportFormatted ? 'Export Raw' : 'Export'}
                      </span>
                    </a>
                  )} */}
                  {!hideHeader && exportPermission && (
                    <div className="btn">
                      <button
                        data-testid="export-button"
                        type="button"
                        className="btn "
                        data-toggle={exportFormatted ? 'dropdown' : ''}
                        onClick={
                          exportFormatted
                            ? null
                            : handleExport.bind(
                                this,
                                seletedTabModule,
                                selectedClientId,
                              )
                        }
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>
                          <AttachmentIcon />
                        </span>
                        <span className="export">Export</span>
                      </button>
                      {exportFormatted && (
                        <div className="dropdown-menu">
                          <a
                            data-testid="raw-report"
                            className="dropdown-item"
                            onClick={handleExport.bind(
                              this,
                              seletedTabModule,
                              selectedClientId,
                            )}
                            href="#"
                          >
                            Raw Report
                          </a>
                          <a
                            data-testid="client-report"
                            className="dropdown-item"
                            onClick={handleExport.bind(
                              this,
                              seletedTabModule,
                              selectedClientId,
                              true,
                            )}
                            href="#"
                          >
                            Client Report
                          </a>
                        </div>
                      )}
                    </div>
                  )}

                  {!hideHeader && !hideImport && importPermission && (
                    <a
                      data-testid="import-button"
                      className="btn btn-success  import-btn mr-3"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Import CSV"
                      onClick={() => {
                        if (selectedClientId === 'allClients') {
                          toast.info('Please select a client from header')
                          return
                        }
                        handleImportfromCsv()
                      }}
                    >
                      <span>
                        <svg
                          id="surface1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.948"
                          height="21.927"
                          viewBox="0 0 19.948 21.927"
                        >
                          <path
                            id="Path_17005"
                            data-name="Path 17005"
                            d="M14.944,9.492V5.342a.48.48,0,0,0-.132-.315L10.159.142A.464.464,0,0,0,9.826,0H2.449A2.458,2.458,0,0,0,0,2.472V16.42a2.442,2.442,0,0,0,2.449,2.454H8.272a6.285,6.285,0,1,0,6.672-9.382ZM10.283,1.6,13.4,4.89H11.38a1.1,1.1,0,0,1-1.1-1.1ZM2.449,17.961A1.531,1.531,0,0,1,.914,16.42V2.472A1.544,1.544,0,0,1,2.449.914H9.369V3.789A2.015,2.015,0,0,0,11.38,5.8H14.03V9.373c-.137,0-.247-.018-.366-.018A6.377,6.377,0,0,0,9.5,10.927h-5.8a.457.457,0,1,0,0,.914H8.647a7.271,7.271,0,0,0-.8,1.417H3.693a.457.457,0,0,0,0,.914H7.545a6.272,6.272,0,0,0,.274,3.793H2.449ZM13.66,21.018a5.37,5.37,0,1,1,5.37-5.37A5.376,5.376,0,0,1,13.66,21.018Zm0,0"
                            fill="#207be3"
                          />
                          <path
                            id="Path_17006"
                            data-name="Path 17006"
                            d="M76.008,202.6h4.629a.457.457,0,1,0,0-.914H76.008a.457.457,0,1,0,0,.914Zm0,0"
                            transform="translate(-72.315 -193.046)"
                            fill="#207be3"
                          />
                          <path
                            id="Path_17007"
                            data-name="Path 17007"
                            d="M249.028,283.357a.454.454,0,0,0-.667,0l-2.678,2.875a.455.455,0,0,0,.023.644.467.467,0,0,0,.653-.023l1.892-2.025v4.991a.457.457,0,1,0,.914,0v-4.991l1.878,2.025a.456.456,0,0,0,.667-.622Zm0,0"
                            transform="translate(-235.043 -271.082)"
                            fill="#207be3"
                          />
                        </svg>
                      </span>{' '}
                      <label
                        className="pl-1 mb-0"
                        style={{ cursor: 'pointer' }}
                      >
                        Import
                      </label>
                    </a>
                  )}
                  {!hideHeader && showFilters && (
                    <>
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames(
                            'btn',
                            'dropdown-toggle',
                            'border-radius-12',
                            'fltrs',
                            {
                              'btn-primary': filterCount > 0,
                            },
                          )}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="btn-arrow"
                        >
                          <i
                            className={classNames('fas', 'fa-filter', 'mr-2', {
                              'btn-primary': filterCount > 0,
                            })}
                            aria-hidden="true"
                          ></i>{' '}
                          Filter
                          {filterCount > 0 ? (
                            <span
                              className={classNames(
                                'rounded-circle',
                                'filter-count',
                                'ml-1',
                                { 'filter-bgwhite': filterCount > 0 },
                              )}
                            >
                              <small
                                className="count font-weight-bold"
                                id="selected"
                              >
                                {filterCount}
                              </small>
                            </span>
                          ) : null}
                        </button>
                        {filtersData.filter.dropdownView}
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => console.log('on sorting')}
                        >
                          <i className="fas fa-sort" aria-hidden="true"></i>{' '}
                          Sort
                        </button>
                      </div>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn "
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i
                            className="fas fa-ellipsis-h"
                            aria-hidden="true"
                          ></i>
                        </button>
                        {filtersData.option.dropdownView}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <nav className="mb-1" style={{ height: '62px' }}>
              <div
                className="nav nav-tabs nav-fill"
                id="nav-tab"
                role="tablist"
              >
                <div className=" nav-link text-left hvr-pulse1">
                  {!hideDeleteButton && (
                    <button
                      data-testid="bulk-check-button"
                      type="button"
                      className="btn btn-outline-secondary side-btn"
                      onClick={() => handleBulkCheckClick()}
                    >
                      <i className="far fa-trash-alt" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {showTabs && getTabsView()}
              </div>
            </nav>
          </div>
        </div>
      </div>
      {showGenerateLinkModal && (
        <GenerateLinkModal
          isOpen={showGenerateLinkModal}
          onClose={() => setGenerateLinkModal(false)}
        />
      )}
    </>
  )
}

export default ListPageHeader

// const mvrTabList = [
//   'RECOMMENDATION',
//   'ALL APPROVALS',
//   'MVR SUBMISSION',
//   'APPROVED',
//   'NOT APPROVED',
// ]
