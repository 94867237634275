import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavDataUpdated, saveIncidentFormData } from '../../actions'
import AttachmentAddView from '../../components/AttachmentAddView'
import IncidentDetailsTab from '../../components/Incidents/IncidentDetailsTab2'
import AreYouSureModal from '../../components/common/AreYouSureModal'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import { getIncident } from '../../services/incidentService'
import { generateUID } from '../../utils/generateUID'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'
import { INCIDENT_VALIDATION_SCHEMA } from '../../utils/validation'
import { handleIncidentSave } from '../ClaimsAndIncidents/ClaimAndIncidentsCommonFunc'
import PartiesInvolvedModal from '../publicIncident/PartiesInvolvedModal'
import PropertiesInvolvedCard from './PropertiesInvolvedCard'
import PropertyInvolvedModal from './PropertyInvolvedModal'
import VehicleInvolvedModal from './VehicleInvolvedModal'
import WitnessCard from './WitnessCard'
import WitnessModal from './WitnessModal'
import {
  ReportedViaTypes,
  allPriorityStatusTypes,
  getAvailableTimezones,
} from './utils'
import TextArea from '../../components/form/TextArea'
import PartiesInvolvedCard from '../publicIncident/PartiesInvolvedCard'

function IncidentForm({
  match,
  data,
  title,
  onClose,
  context,
  activeStep,
  setActiveStep,
  setClaimData,
}) {
  const ref = useRef()
  const dispatch = useDispatch()
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const [isNew, setIsNew] = useState(false)
  const [isVehicular, setIsVehicular] = useState(
    data
      ? data.directClaim === true
        ? data.incident.vehicularAccident
        : data.vehicularAccident
      : true,
  )

  const [values, setValues] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openNarrative, setOpenNarrative] = useState(false)
  const [showAddPartiesInvolvedModal, setShowAddPartiesInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showAddVehicleInvolvedModal, setShowAddVehicleInvolvedModal] =
    useState({
      isOpen: false,
      data: null,
      editId: null,
    })
  const [showPropertyInvolvedModal, setShowPropertyInvolvedModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })
  const [showWitnessModal, setShowWitnessModal] = useState({
    isOpen: false,
    data: null,
    editId: null,
  })

  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [partiesInvolvedValidation, setPartiesInvolvedValidation] =
    useState(false)

  const closeModal = () => setOpenModal(false)

  const newIncident = useMemo(
    () => ({
      userDetails: {
        employeeId: '',
        employeeStatus: '',
      },
      clientId: '',
      vehicularAccident: isVehicular,
      // firstName: '',
      // lastName: '',
      // email: '',
      // companyName: '',
      timezone: getAvailableTimezones()[0].key,
      returnToWorkDate: '',
      shiftStartTime: '00:00:00 AM',
      incidentNarrative: [],
      packageNumber: '',
      // companyPremises: true,
      priority: '',
      incidentProperty: null,
      attachments: [],
      witnesses: [],
      propertiesInvolved: [],
      partiesInvolved: [],
      vehiclesInvolved: [],
      coverageDescription: '',
      claimType: '',
      // amountPaid: '',
      // amountIncurred: '',
      claimFinancials: [],
      reportedVia: '',
      incidentDetails: {
        dateOfIncident: null,
        time: '00:00:00 AM',
      },
      supervisorDetails: {
        isNotify: 'true',
      },
      startDate: '',
      endDate: '',
      closeDate: '',
      incidentId: generateUID('incident'),
      nextAction: ''
    }),
    [isVehicular],
  )

  useEffect(() => {
    if (data) {
      setIsNew(false)
      if (data.directClaim) {
        setValues(data.incident)
      } else {
        let partiesInvolvedData = [...data.partiesInvolved];
        let employeeDetails = {
          isCompanyEmployee: true,
          personDetails: {
            firstName: data?.userDetails?.firstName || '',
            lastName: data?.userDetails?.lastName || '',
            email: data?.userDetails?.email || '',
            organisation: '',
            contactNumber: data?.userDetails?.contactNumber || '',
            address: {
              line1: data?.userDetails?.address || '',
              line2: '',
              city: data?.userDetails.city || '',
              state: data?.userDetails.state || '',
              country: data?.userDetails.country || '',
              pinCode: data?.userDetails.zip || '',
            },
            employeeId: 'OTHERS',
          },
        }
        if (partiesInvolvedData.length) {
          if (data?.userDetails?.email !== partiesInvolvedData[0]?.personDetails?.email) {
            partiesInvolvedData.unshift(employeeDetails)
          }
        } else {
          partiesInvolvedData.push(employeeDetails)
        }
        let feedEditData = {
          ...data,
          clientId: data?.clientId?._id,
          userDetails: {
            ...data.userDetails,
            employeeStatus: data.userDetails.employeeStatus ?? '',
          },
          partiesInvolved: [
            ...partiesInvolvedData,
          ],
          incidentDetails: {
            ...data.incidentDetails,
            thirdPartyLeaves:
              data.incidentDetails?.thirdPartyLeaves?.toString(),
            policeCalled: data.incidentDetails?.policeCalled?.toString(),
          },
        }
        data.clientId = data?.clientId?._id
        setValues({...feedEditData})
      }
      dispatch(handleSideNavDataUpdated({ data: data, module: 'incident' }))
    } else {
      setIsNew(true)
      setValues(newIncident)
    }
  }, [data])

  const fetchIncident = async (id) => {
    const { data: incident } = await getIncident(id)
    setValues(incident.data)
  }

  const handleSubmit = async (values) => {
    setValues({ ...values })
    if (values.partiesInvolved.length === 0) {
      setPartiesInvolvedValidation(true)
    } else {
      if (context === 'claim') {
        dispatch(saveIncidentFormData(values))
        setActiveStep(activeStep + 1)
      } else {
        setOpenModal(true)
      }
    }
  }

  const handleSubmitModal = () => {
    setOpenModal(false)
    dispatch(saveIncidentFormData(null))
    handleIncidentSave(isVehicular, { ...values, created: values.created ?? (isNew ? moment().format(NO_TIMEZONE_DATE_FORMAT) : undefined) }, isNew, onClose)
    ref?.current?.resetForm()
  }

  const handleNarrativeOpen = (values) => {
    setActiveStep && setActiveStep(activeStep + 1)
    setClaimData && setClaimData({ values: values, isVehicular: isVehicular })
    setValues(values)
    setOpenNarrative(true)
  }

  const handleBackButtonClicked = () => {
    setActiveStep && setActiveStep(activeStep - 1)
    setOpenNarrative(false)
  }

  const vehicularAccident = isVehicular

  return (
    <>
      <Formik
        innerRef={ref}
        enableReinitialize
        initialValues={{ ...newIncident, ...values }}
        validationSchema={INCIDENT_VALIDATION_SCHEMA}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          errors,
          setFieldTouched,
          handleBlur,
        }) => (
          <div className="row m-0">
            {/* {console.log("errors=====>", errors)} */}
            <IncidentDetailsTab
              isNew={isNew}
              title={title}
              values={values}
              context={context}
              activeStep={activeStep}
              handleSubmit={handleSubmit}
              narrative={openNarrative}
              setFieldValue={setFieldValue}
              nextTab={handleNarrativeOpen}
              setFieldTouched={setFieldTouched}
              setNarrative={handleBackButtonClicked}
              setIsVehicular={setIsVehicular}
            />
            {values && (
              <div className="col-md-5 pt-3 modal-right-scroll">
                <div className="col-12 p-0">
                  {context !== 'claim' && (
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Incident ID
                          <span className="text-primery">
                            {values ? values.incidentId : '-'}
                            <b className="text-dark font-weight-normal ml-2">
                              (System Generated)
                            </b>
                          </span>
                        </label>
                      </div>
                    </div>
                  )}
                  {context !== 'claim' && (
                    <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  )}
                  <div className="row">
                    <div className="col-md-6">
                      <DatePickerField
                        name="startDate"
                        label="Start Date"
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField
                        name="endDate"
                        label="Due Date"
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField
                        name="closeDate"
                        label="Incident Close Date"
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className={'row'}>
                    <div className="col-md-6">
                      <Select
                        label="Priority"
                        name="priority"
                        defaultLabel="Select Status"
                        options={allPriorityStatusTypes}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        label="Reported Via"
                        name="reportedVia"
                        defaultLabel="Select"
                        options={ReportedViaTypes}
                      />
                    </div>
                    <div className="col-md-12">
                      <TextArea
                        withAsterisk
                        label="Next Action"
                        name="nextAction"
                        // onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text">
                      Parties {vehicularAccident && '& Vehicles'} Involved
                    </label>
                    {values?.partiesInvolved?.map((party, index) => (
                      <PartiesInvolvedCard
                        key={index}
                        info={party}
                        uid={index}
                        onEdit={(uid) => {
                          const a = values?.partiesInvolved[uid]
                          setShowAddPartiesInvolvedModal({
                            isOpen: true,
                            data: a,
                            editId: uid,
                          })
                        }}
                        onDelete={(uid) => {
                          const a = values?.partiesInvolved.filter(
                            (party, i) => i != uid,
                          )
                          setFieldValue('partiesInvolved', a)
                        }}
                      />
                    ))}
                    <button
                      onClick={() =>
                        setShowAddPartiesInvolvedModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  {partiesInvolvedValidation && (
                    <span className="text-danger">
                      Party involved is required.
                    </span>
                  )}

                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  {/* {vehicularAccident && (
                    <>
                      <div className="form-group col-md-12">
                        <label className="d-block incident-text">
                          Vehicles Involved
                        </label>
                        {values?.vehiclesInvolved?.map((vehicle, index) => {
                          return (
                            <VehicleInvolvedCard
                              vehicle={vehicle}
                              key={index}
                              uid={index}
                              onEdit={(uid) => {
                                const a = values?.vehiclesInvolved[uid]

                                setShowAddVehicleInvolvedModal({
                                  isOpen: true,
                                  data: a,
                                  editId: uid,
                                })
                              }}
                              onDelete={(uid) => {
                                const a = values?.vehiclesInvolved.filter(
                                  (party, i) => i != uid,
                                )
                                setFieldValue('vehiclesInvolved', a)
                              }}
                            />
                          )
                        })}
                        <button
                          onClick={() =>
                            setShowAddVehicleInvolvedModal({
                              isOpen: true,
                              data: null,
                              editId: null,
                            })
                          }
                          type="button"
                          className="btn btn-outline-secondary side-btn mr-3"
                        >
                          Add..
                        </button>
                      </div>
                      <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                    </>
                  )} */}
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text">
                      Properties Involved
                    </label>
                    {values?.propertiesInvolved.map((property, index) => {
                      return (
                        <PropertiesInvolvedCard
                          property={property}
                          key={index}
                          uid={index}
                          onEdit={(uid) => {
                            const a = values?.propertiesInvolved[uid]

                            setShowPropertyInvolvedModal({
                              isOpen: true,
                              data: a,
                              editId: uid,
                            })
                          }}
                          onDelete={(uid) => {
                            const a = values?.propertiesInvolved.filter(
                              (party, i) => i != uid,
                            )
                            setFieldValue('propertiesInvolved', a)
                          }}
                        />
                      )
                    })}
                    <button
                      onClick={() =>
                        setShowPropertyInvolvedModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Witnesses
                    </label>
                    {values?.witnesses.map((witness, index) => {
                      return (
                        <WitnessCard
                          witness={witness}
                          key={index}
                          uid={index}
                          onEdit={(uid) => {
                            const a = values?.witnesses[uid]

                            setShowWitnessModal({
                              isOpen: true,
                              data: a,
                              editId: uid,
                            })
                          }}
                          onDelete={(uid) => {
                            const a = values?.witnesses.filter(
                              (party, i) => i != uid,
                            )
                            setFieldValue('witnesses', a)
                          }}
                        />
                      )
                    })}

                    <button
                      onClick={() =>
                        setShowWitnessModal({
                          isOpen: true,
                          data: null,
                          editId: null,
                        })
                      }
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button>
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    {/* <label className="d-block incident-text">Attachments</label>
                    {values?.attachments.length !== 0 && getAttachmentViews()}
                    {openAttachmentModal && (
                      <AddAttachment
                        module="incident"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )}
                    <button
                      onClick={handleAddAttachmentToggle}
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                    >
                      Add..
                    </button> */}
                    {
                      <AttachmentAddView
                        formik={{
                          setFieldValue: setFieldValue,
                          values: values,
                        }}
                        module={'incident'}
                        uid={values.incidentId}
                      />
                    }
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Gensuite Check List
                    </label>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check4"
                      >
                        Filed on Gensuite
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.filedOnGensuite',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.filedOnGensuite'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check4"
                        checked={values.gensuiteChecklist?.filedOnGensuite}
                      />
                    </div>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check5"
                      >
                        Quality Check Needed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.qualityCheckNeeded',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.qualityCheckNeeded'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check5"
                        checked={values.gensuiteChecklist?.qualityCheckNeeded}
                      />
                    </div>

                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check6"
                      >
                        Quality Check completed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.qualityCheckCompleted',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.qualityCheckCompleted'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check6"
                        checked={
                          values.gensuiteChecklist?.qualityCheckCompleted
                        }
                      />
                    </div>
                    <div className="form-check d-flex justify-content-between pl-0 mb-3 pr-3">
                      <label
                        className="form-check-label text-primary"
                        htmlFor="Check6"
                      >
                        Gensuite Closed
                      </label>
                      <input
                        onChange={(a) =>
                          setFieldValue(
                            'gensuiteChecklist.gensuiteClosed',
                            a.target.checked,
                          )
                        }
                        name={'gensuiteChecklist.gensuiteClosed'}
                        className="form-check-TextInput1 pt-0"
                        type="checkbox"
                        value=""
                        id="Check6"
                        checked={values.gensuiteChecklist?.gensuiteClosed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PartiesInvolvedModal
              isOpen={showAddPartiesInvolvedModal.isOpen}
              onClose={() =>
                setShowAddPartiesInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              isVehicularIncident={vehicularAccident}
              values={values}
              setFieldValue={setFieldValue}
              editId={showAddPartiesInvolvedModal.editId}
              editData={showAddPartiesInvolvedModal.data}
              setPartiesInvolvedValidation={setPartiesInvolvedValidation}
              clientId={
                selectedClientId &&
                  selectedClientId.length &&
                  selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />

            <VehicleInvolvedModal
              isOpen={showAddVehicleInvolvedModal.isOpen}
              onClose={() =>
                setShowAddVehicleInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              values={values}
              editId={showAddVehicleInvolvedModal.editId}
              editData={showAddVehicleInvolvedModal.data}
              setFieldValue={setFieldValue}
              clientId={
                selectedClientId &&
                  selectedClientId.length &&
                  selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />
            <PropertyInvolvedModal
              isOpen={showPropertyInvolvedModal.isOpen}
              onClose={() =>
                setShowPropertyInvolvedModal({
                  isOpen: false,
                  data: null,
                  editId: null,
                })
              }
              values={values}
              editId={showPropertyInvolvedModal.editId}
              editData={showPropertyInvolvedModal.data}
              setFieldValue={setFieldValue}
              clientId={
                selectedClientId &&
                  selectedClientId.length &&
                  selectedClientId !== 'allClients'
                  ? selectedClientId
                  : values?.clientId
              }
            />
            <WitnessModal
              isOpen={showWitnessModal.isOpen}
              onClose={() =>
                setShowWitnessModal({ isOpen: false, data: null, editId: null })
              }
              values={values}
              editId={showWitnessModal.editId}
              editData={showWitnessModal.data}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
      </Formik>
      {context !== 'claim' && (
        <AreYouSureModal
          openModal={openModal}
          closeModal={closeModal}
          handleSubmit={handleSubmitModal}
        />
      )}
    </>
  )
}

export default IncidentForm
