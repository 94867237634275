import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextInput from '../../../components/form/TextInput'
import { getEmployees } from '../../../services/employeeService'
import { employeeStatusOptions } from '../../incident/utils'
import { getAddressDetails } from '../../../services/helperService'

export default function UserDetailsView({
  isClientExist,
  values,
  setFieldValue,
  onNavigate,
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [employeeOptions, setEmployeeOptions] = useState([])

  const handlePincodeChange = async (e) => {
    if (e.target.value !== '') {
      try {
        const {
          data: {
            data: { data: data },
          },
        } = await getAddressDetails(e.target.value)
        setFieldValue('incident.userDetails.state', data?.state_fullname)
        setFieldValue('incident.userDetails.city', data?.city)
        setFieldValue('incident.userDetails.country', 'US')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const selectedEmployeeData = (selectedEmployeeId) => {
    if (selectedEmployeeId) {
      const emp = employeeOptions
        .filter(
          (employeeOptionsData) =>
            employeeOptionsData?.key === selectedEmployeeId,
        )
        ?.shift()
      const pincode =
        emp?.employeeData?.address?.pinCode &&
        emp?.employeeData?.address?.pinCode !== null
          ? emp?.employeeData?.address?.pinCode
          : undefined
      setFieldValue(
        'incident.userDetails.firstName',
        emp?.employeeData?.firstName ?? '',
      )
      setFieldValue(
        'incident.userDetails.lastName',
        emp?.employeeData?.lastName ?? '',
      )
      setFieldValue(
        'incident.userDetails.email',
        emp?.employeeData?.email ?? '',
      )
      setFieldValue(
        'incident.userDetails.contactNumber',
        emp?.employeeData?.personalPhone ?? '',
      )
      setFieldValue(
        'incident.userDetails.dateOfBirth',
        emp?.employeeData?.dateOfBirth ?? '',
      )
      setFieldValue(
        'incident.userDetails.hireDate',
        emp?.employeeData?.hireDate ?? '',
      )
      setFieldValue(
        'incident.userDetails.employeeStatus',
        emp?.employeeData?.status ?? '',
      )
      setFieldValue(
        'incident.userDetails.address',
        emp?.employeeData?.address?.line1 ?? '',
      )
      setFieldValue('incident.userDetails.zip', pincode ?? '')
      setFieldValue(
        'incident.userDetails.city',
        emp?.employeeData?.address?.city ?? '',
      )
      setFieldValue(
        'incident.userDetails.state',
        emp?.employeeData?.address?.state ?? '',
      )
      setFieldValue(
        'incident.userDetails.country',
        emp?.employeeData?.address?.country ?? '',
      )
    }
  }
  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployeeOptions([])
      try {
        const result = await getEmployees({
          params: {
            clientId: values.clientId ?? selectedClientId,
          },
        })
        setEmployeeOptions([
          ...result.data.data.map((e) => ({
            key: e._id,
            value: `${e.firstName} ${e.lastName}`,
            employeeData: e,
          })),
          {
            key: 'OTHER',
            value: 'Other',
          },
        ])
      } catch (error) {
        console.error(error)
      }
    }
    fetchEmployees()
  }, [selectedClientId, values?.clientId])

  return (
    <>
      <div className="border-bottom h6 py-2 mb-3 w-full">User Details</div>
      <div className="form-row">
        <div className="col-md-12">
          <Select
            data-testid="employee-select"
            withAsterisk
            label="Select Employee"
            name="incident.userDetails.employeeId"
            defaultLabel="Select Employee"
            options={employeeOptions}
            value={values?.incident?.userDetails?.employeeId}
            onChange={(e) => {
              setFieldValue('incident.userDetails.employeeId', e.target.value)
              selectedEmployeeData(e.target.value)
            }}
          />
        </div>
        {values?.incident?.userDetails?.employeeId === 'OTHER' && (
          <>
            <div className="col-md-6">
              <TextInput
                label="Employee First Name"
                name="incident.userDetails.firstName"
                withAsterisk
                showErrorEvenWhenUntouched
              />
            </div>
            <div className="col-md-6">
              <TextInput
                withAsterisk
                label="Employee Last Name"
                name="incident.userDetails.lastName"
                showErrorEvenWhenUntouched
              />
            </div>
          </>
        )}
        <div className="col-md-6">
          <TextInput
            label="Employee Email"
            name="incident.userDetails.email"
            showErrorEvenWhenUntouched
            data-testid="employee-email"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            data-testid="employee-contact-number"
            isPhone
            changeOnUndefined={true}
            label="Employee Contact Number"
            name="incident.userDetails.contactNumber"
            showErrorEvenWhenUntouched
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('incident.userDetails.contactNumber', phone || '')
            }}
            value={values?.incident?.userDetails?.contactNumber || ''}
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="incident.userDetails.dateOfBirth"
            label="Date of Birth"
            minDate={moment().subtract(100, 'years').toDate()}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="incident.userDetails.hireDate"
            label="Hire Date"
            minDate={moment().subtract(100, 'years').toDate()}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div>
              <label htmlFor='shift-start-time'>Shift Start Time</label>
            </div>
            <TimePicker
              use12Hours
              id="shift-start-time"
              className="antDOverride"
              name="incident.shiftStartTime"
              onChange={(value) => {
                setFieldValue(
                  'incident.shiftStartTime',
                  value.format('HH:mm:ss A'),
                )
              }}
              format="hh:mm:ss A"
              value={moment(
                values?.incident?.shiftStartTime || '00:00:00',
                'HH:mm:ss A',
              )}
              defaultValue={moment('00:00:00', 'HH:mm:ss A')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <TextInput label="FICO Score" name="incident.userDetails.ficoScore" />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Job Position/Title"
            name="incident.userDetails.jobTitle"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Station Code/ Job Site"
            name="incident.userDetails.jobSite"
          />
        </div>
        <div className="col-md-12">
          <Select
            label="Employee Status"
            withAsterisk
            name="incident.userDetails.employeeStatus"
            defaultLabel="Select status"
            options={employeeStatusOptions}
            value={values?.incident?.employeeStatus}
          />
        </div>
        <div className="col-md-12">
          <TextInput
            noBottomMargin
            label="Driver Address"
            name="incident.userDetails.address"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="Zip"
            name="incident.userDetails.zip"
            type="number"
            onBlur={handlePincodeChange}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="City"
            name="incident.userDetails.city"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="State"
            name="incident.userDetails.state"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="Country"
            name="incident.userDetails.country"
          />
        </div>
      </div>
    </>
  )
}
