import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSideNavElementClicked } from '../../actions'
import { timeNowFormat } from '../../configs/utils'
import {
  useCommentAccess,
  useGetPermission
} from '../../hooks/useGetPermission'
import { addCommaTableField, transformPhoneNumber } from '../../pages/incident/utils'

export default function IncidentRecordInfo({ onEdit, onDelete }) {
  const dispatch = useDispatch()
  const [activeCardId, setActiveCardId] = useState('parent')
  const data = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const vehicularIncidentLabel = data?.vehicularAccident
    ? '(Vehicular Incident)'
    : '(Non-Vehicular Incident)'
  const dateOfIncident = useMemo(() => {
    if (data?.dateOfIncident || data?.incidentDetails?.dateOfIncident) {
      return moment(data.dateOfIncident || data.incidentDetails.dateOfIncident).format('MM/DD/YYYY')
    }
    return "N/A"
  }, [data])

  const timeOfIncident = useMemo(() => {
    if (data?.incidentDetails?.time) {
      return `${data?.incidentDetails?.time}  ${moment.tz(data?.timezone).zoneAbbr()}`;
    }
    return "N/A"
  }, [data])


  const deletePermission = useGetPermission('incident', 'DELETE')
  const editPermission = useGetPermission('incident', 'UPDATE')
  const addCommentPermission = useCommentAccess('incident', 'CREATE')



  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }

  let getAddress = (address) => {
    let addressString = ''
    if (address) {
      if (typeof address === "string") {
        addressString = address
      } else {
        addressString += address.name ? address.name + ', ' : ''
        addressString += address.line1 ? address.line1 + ', ' : ''
        addressString += '\n'
        addressString += address.line2 ? address.line2 + ', ' : ''
        addressString += address.city ? address.city : ''
        addressString += '\n'
        addressString += address.state ? address.state + ', ' : ''
        addressString += address.country ? address.country + '-' : ''
        addressString += address.pinCode ? address.pinCode : ''
      }
    }

    return addressString
  }

  let cardHeaderClicked = (sId) => {
    setActiveCardId(sId)
  }

  let getVehicleInvolvedView = () => {
    let vehicleInvolvedViews = []

    data?.vehiclesInvolved.forEach(function (vehicleInvolved, index) {
      const driverDetails = vehicleInvolved.driverDetails
      const vehicleDetails = vehicleInvolved.vehicleDetails
      const driverName = `${driverDetails?.firstName ?? ""} ${driverDetails?.lastName ?? ""}`.trim()

      vehicleInvolvedViews.push(
        <div
          id="faq3"
          className="collapse"
          aria-labelledby="faqhead3"
          data-parent="#faq"
          key={index}
        >
          <div className=" ">
            <div className="parties bg-white border mt-4 p-3 mb-3">
              <h6 className="text-primery">
                {driverName !== "" ? driverName : "N/A"}
              </h6>
              <p className="mb-2">{driverDetails?.contactNumber || 'N/A'}</p>
              <p className="mb-2">
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
                {getAddress(driverDetails?.address)}
              </p>
              <span className="badge badge-pill btn btn-outline-primary pt-1">
                Company Vehicle
              </span>
              <span className="badge badge-pill btn btn-outline-primary pt-1 ml-2">
                VIN: {vehicleDetails?.vinNumber || 'N/A'}
              </span>
              {/* <span className="badge badge-pill btn btn-outline-primary pt-1">
                License Number
              </span> */}
            </div>
          </div>
        </div>,
      )
    })

    return vehicleInvolvedViews
  }

  let getPartiesInvolvedView = () => {
    let partiesInvolvedViews = []

    data?.partiesInvolved.forEach(function (partyInvolved, index) {
      const personal = partyInvolved.personDetails

      partiesInvolvedViews.push(
        <div
          id="faq2"
          className="collapse"
          aria-labelledby="faqhead2"
          data-parent="#faq"
          key={index}
        >
          <div className=" ">
            <div className="parties bg-white border mt-4 p-3 mb-3">
              <h6 className="text-primery">
                {personal.firstName + ' ' + personal.lastName}
              </h6>
              <div className="d-flex ">
                <div>
                  <i className="fas fa-mobile-alt mr-2" aria-hidden="true"></i>
                </div>
                <div>
                  {' '}
                  {personal.contactNumber ? transformPhoneNumber(personal.contactNumber) : 'N/A'}
                </div>
              </div>

              <p className="mb-2">
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
                {getAddress(personal.address)}
              </p>
              <span className="badge badge-pill btn btn-outline-primary">
                {partyInvolved.isCompanyEmployee
                  ? 'Company Employee'
                  : 'Not A Company Employee'}
              </span>
              <span className="badge badge-pill btn btn-outline-warning">
                {partyInvolved.injuryDetails !== null
                  ? 'Has Injury'
                  : 'No Injury'}
              </span>
              <span className="badge badge-pill btn btn-outline-danger">
                {partyInvolved.injuryDetails.painLevel || 'N/A'}
              </span>
            </div>
          </div>
        </div>,
      )
    })

    return partiesInvolvedViews
  }

  let getWitnessCardView = () => {
    let witnessViews = []

    data?.witnesses.forEach(function (os, index) {
      witnessViews.push(
        <div className=" " key={index}>
          <div className="parties bg-white border mt-4 p-3 mb-3">
            <h6 className="text-primery">{os.firstName + ' ' + os.lastName}</h6>
            {os.contactNumber && (
              <div className="col-md-6">
                <label className="incident-text">
                  Contact Number
                  <span>{os?.contactNumber ? transformPhoneNumber(os?.contactNumber) : 'N/A'}</span>
                </label>
              </div>
            )}
            {os.designation && (
              <div className="col-md-6">
                <label className="incident-text">
                  Designation
                  <span>{os.designation || 'N/A'}</span>
                </label>
              </div>
            )}
            {os.email && (
              <div className="col-md-6">
                <label className="incident-text">
                  Email
                  <span>{os.email || 'N/A'}</span>
                </label>
              </div>
            )}
            {os.companyName && (
              <div className="col-md-6">
                <label className="incident-text">
                  Account Name
                  <span>
                    {_.map(
                      (os?.companyName || ' ')?.split(' '),
                      _.capitalize,
                    ).join(' ')}
                  </span>
                </label>
              </div>
            )}
            {os.address && (
              <p className="mb-2">
                <i
                  className="fas fa-map-marker-alt mr-2"
                  aria-hidden="true"
                ></i>
                {getAddress(os.address)}
              </p>
            )}
          </div>
        </div>,
      )
    })

    return witnessViews
  }

  let getPropertiesInvolvedView = () => {
    let propertiesInvolvedViews = []

    data?.propertiesInvolved.forEach(function (propertyInvolved, index) {
      const propertyDetails = propertyInvolved.propertyDetails
      const propertyDamages = propertyInvolved.propertyDamages
      propertiesInvolvedViews.push(
        <div
          id="faq4"
          className="collapse"
          aria-labelledby="faqhead4"
          data-parent="#faq"
          key={index}
        >
          <div className="">
            <div className="parties bg-white p-3 mb-3 mx-2 mt-4 claim-taken-view">
              <h6 className="text-primery">{propertyDetails.name || 'N/A'}</h6>
              <div className="mb-2 d-flex">
                <div>
                  <i
                    className="fas fa-map-marker-alt mr-2"
                    aria-hidden="true"
                  ></i>
                </div>
                <div>{getAddress(propertyDetails.address)}</div>
              </div>
              {propertyDamages?.length > 0 && (
                <div className="my-2">
                  <p className="text-primary mt-2 mb-2">Damage Details</p>
                  <div className="m-2">
                    {propertyDamages.map((propertyDamage, index) => (
                      <span
                        key={`${index}-propertyDamage`}
                        className="badge badge-light p-2 text-primary mr-2"
                      >
                        {propertyDamage}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <span className="badge badge-pill btn btn-outline-primary">
                Company Employee
              </span>
              <span className="badge badge-pill btn btn-outline-warning ml-2">
                {propertyDetails.propertyType || 'N/A'}
              </span>
            </div>
          </div>
        </div>,
      )
    })

    return propertiesInvolvedViews
  }

  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2  mb-4 mt-3"
          style={{ width: '480px', marginTop: '190px' }}
        >
          <div className="d-flex bd-highlight mb-3">
            <div className="bd-highlight">
              <div
                className="mr-3 cal-box d-flex flex-column bd-highlight text-center"
                data-toggle="tooltip"
                data-placement="top"
                title="Date of Loss"
                data-original-title={moment(data?.dateOfIncident ?? data?.incidentDetails?.dateOfIncident).format('ll')}
              >
                <div className="p-1 bg-secondary text-light">
                  {(data?.dateOfIncident || data?.incidentDetails?.dateOfIncident) ? moment(data?.dateOfIncident ?? data?.incidentDetails?.dateOfIncident).format('MMM') : moment(data?.created).format('MMM')}
                </div>
                <div className="p-1 bg-light text-dark">
                  {(data?.dateOfIncident || data?.incidentDetails?.dateOfIncident) ? moment(data?.dateOfIncident ?? data?.incidentDetails?.dateOfIncident).format('YYYY') : moment(data?.created).format('YYYY')}
                </div>
              </div>
            </div>

            <div className="bd-highlight mr-3">
              <span className="h3 mb-0 pb-0 dark-text">
                <div className="text-black-50 py-1">{data?.incidentId || 'N/A'}</div>
                {data?.clientId?.companyName || 'N/A'}
              </span>
              <p className="mt-3">{vehicularIncidentLabel || 'N/A'}</p>
            </div>

            <div className="ml-auto bd-highlight pt-2">
              <nav className="">
                <li
                  className="nav-item p-0 close11"
                  onClick={handleSideNavClose}
                >
                  <span className="search ">
                    <a
                      className="text-dark"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.829"
                          height="17.828"
                          viewBox="0 0 17.829 17.828"
                        >
                          <g
                            id="Group_9756"
                            data-name="Group 9756"
                            transform="translate(-4013.659 13.151)"
                          >
                            <path
                              id="path1419"
                              d="M-1479.2-17.087l15-15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                            <path
                              id="path1421"
                              d="M-1479.2-32.087l15,15"
                              transform="translate(5494.273 20.35)"
                              fill="none"
                              stroke="#828282"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </span>
                </li>
              </nav>
            </div>
          </div>

          <div className="pull-right px-3 pb-2">
            {addCommentPermission && (
              <button
                type="button"
                className="btn btn-primary mr-3"
                onClick={() => dispatch(handleSideNavElementClicked('comment'))}
              >
                Add Comment
              </button>
            )}
            {editPermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-3"
                onClick={onEdit}
              >
                <i className="fas fa-pencil-alt"></i>
              </button>
            )}
            {deletePermission && (
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={onDelete}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            )}
          </div>
        </div>

        <div className="pr-2 pb-4 overflow-auto" id="scroller">
          <div id="main1">
            <div className="container">
              <div
                className="accordion"
                id="faq"
                style={{ paddingBottom: '20px' }}
              >
                <div className="card">
                  <div
                    className="card-header1"
                    id="faqhead1"
                    onClick={cardHeaderClicked.bind(this, 'parent')}
                  >
                    <a
                      href="#"
                      className={
                        'btn btn-header-link ' +
                        (activeCardId !== 'parent' ? 'collapsed' : '')
                      }
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Incident Details
                    </a>
                  </div>
                  <div
                    id="faq1"
                    className="collapse show pt-3"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <label className="incident-text">
                          Date of Loss
                          <span>{dateOfIncident || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Time of incident
                          <span>{timeOfIncident}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          First Name
                          <span>
                            {_.map(
                              ((data?.firstName ?? data?.userDetails?.firstName) || ' ')?.split(' '),
                              _.capitalize,
                            ).join(' ')}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Last Name
                          <span>
                            {_.map(
                              ((data?.lastName ?? data?.userDetails?.lastName) || ' ')?.split(' '),
                              _.capitalize,
                            ).join(' ')}
                          </span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          Email
                          <span>{(data?.email ?? data?.userDetails?.email) || 'N/A'}</span>
                        </label>
                      </div>
                      {
                        data?.userDetails?.contactNumber && (<div className="col-md-6">
                          <label className="incident-text">
                            {data?.companyName && "Company Name"}
                            {data?.userDetails?.contactNumber && "Contact Number"}
                            <span>{(data?.companyName ?? transformPhoneNumber(data?.userDetails?.contactNumber)) || 'N/A'}</span>
                          </label>
                        </div>)
                      }

                      <div className="col-md-6">
                        <label className="incident-text">
                          Package No
                          <span>{data?.packageNumber || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Company Premises
                          <span>{(data?.companyPremises ?? data?.incidentDetails?.companyPremises) ? 'Yes' : 'No'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Start Date
                          <span>
                            {data?.startDate ? moment(data?.startDate).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          End Date
                          <span>
                            {data?.endDate ? moment(data?.endDate).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Incident Close Date
                          <span>
                            {data?.closeDate ? moment(data?.closeDate).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-12">
                        <label className="incident-text">
                          Incident Address
                          <span>{(() => {
                            const addr = getAddress(data?.address)
                            if (addr !== '') {
                              return addr
                            }
                            return "N/A"
                          })()}</span>
                        </label>
                      </div>

                      <div className="col-md-6">
                        <label className="incident-text">
                          Reported
                          <span>{data?.reportedVia || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Status
                          <span
                            className={`text-${data?.claimStatus === 'Open' ? 'success' : 'danger'
                              }`}
                          >
                            {data?.claimStatus || 'N/A'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Type of Claim
                          <span>{data?.claimType || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Coverage Description
                          <span>{data?.coverageDescription || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Paid
                          <span>{(data?.amountPaid || data?.amountPaidTillDate?.amount) ? addCommaTableField(data?.amountPaid ?? data?.amountPaidTillDate?.amount) : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Claim Incurred
                          <span>{(data?.amountIncurred || data?.amountIncurredTillDate?.amount) ? addCommaTableField(data?.amountIncurred ?? data?.amountIncurredTillDate?.amount) : 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Priority
                          <span>{data?.priority || 'N/A'}</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label className="incident-text">
                          Last changed date
                          <span>
                            {data?.modified ? moment(data?.modified).format('MM/DD/YYYY') : 'N/A'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {data?.partiesInvolved?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'accident')}
                  >
                    <div className="card-header1" id="faqhead2">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'accident' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        Parties Involved
                      </a>
                    </div>
                    <div
                      id="faq2"
                      className="collapse"
                      aria-labelledby="faqhead2"
                      data-parent="#faq"
                    >
                      {getPartiesInvolvedView()}
                    </div>
                  </div>
                )}
                {data?.vehicularAccident && data?.vehiclesInvolved?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Vehicle Involved
                      </a>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      {getVehicleInvolvedView()}
                    </div>
                  </div>
                )}
                {data?.propertiesInvolved?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead3">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq4"
                        aria-expanded="true"
                        aria-controls="faq4"
                      >
                        Property Damage Details
                      </a>
                    </div>
                    <div
                      id="faq4"
                      className="collapse"
                      aria-labelledby="faqhead4"
                      data-parent="#faq"
                    >
                      {getPropertiesInvolvedView()}
                    </div>
                  </div>
                )}
                {data?.witnesses?.length > 0 && (
                  <div
                    className="card"
                    onClick={cardHeaderClicked.bind(this, 'witness')}
                  >
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className={
                          'btn btn-header-link ' +
                          (activeCardId !== 'witness' ? 'collapsed' : '')
                        }
                        data-toggle="collapse"
                        data-target="#faq5"
                        aria-expanded="true"
                        aria-controls="faq5"
                      >
                        Witnesses
                      </a>
                    </div>
                    <div
                      id="faq5"
                      className="collapse"
                      aria-labelledby="faqhead5"
                      data-parent="#faq"
                    >
                      {getWitnessCardView()}
                    </div>
                  </div>
                )}

                {data?.incidentNarrative?.length > 0 && (
                  <div className="card">
                    <div className="card-header1" id="faqhead5">
                      <a
                        href="#"
                        className="btn btn-header-link collapsed"
                        data-toggle="collapse"
                        data-target="#faq3"
                        aria-expanded="true"
                        aria-controls="faq3"
                      >
                        Incident Narrative
                      </a>
                    </div>
                    <div
                      id="faq3"
                      className="collapse"
                      aria-labelledby="faqhead3"
                      data-parent="#faq"
                    >
                      {data?.incidentNarrative?.map((item, index) => (
                        <div
                          key={`${index}-incidentNarrative`}
                          id="faq6"
                          // className="collapse show"
                          aria-labelledby="faqhead6"
                          data-parent="#faq"
                        >
                          <div className="parties bg-white p-2 mb-2 mx-1 mt-3 claim-taken-view" >
                            <div className="img-box mb-1">
                              <div className="pro-box d-flex mr-3">
                                {item.narrativeBy
                                  ? item.narrativeBy.split(' ')[0]?.split('')[0]
                                  : null}
                                {item.narrativeBy
                                  ? item.narrativeBy.split(' ')[1]?.split('')[0]
                                  : null}
                              </div>
                              <div className="">
                                <p className="mb-0">{item.narrative || 'N/A'}</p>
                                <p
                                  className="mb-0"
                                  title={moment
                                    .utc(item.narrativeDate)
                                    .local()
                                    .format('YYYY-MM-DD HH:mm:ss')}
                                >
                                  {item.narrativeDate ? timeNowFormat(item.narrativeDate) : 'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
