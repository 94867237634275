import React from 'react'
import Checkbox from './Checkbox'

const CommonDropDown = ({
  handleDropDownOpenCloseClicked,
  show,
  title,
  subModule,
  allChecked,
  onSelectAll,
  options,
}) => {
  return (
    <div className="form-group">
      <label htmlFor="" className="col-form-label">
        {title}
      </label>
      <div>
        <div
          className={
            'selectBox form-control form-select pb-5 mb-4 ' +
            (show ? 'show' : '')
          }
        >
          <div
            className="selectBox__value"
            onClick={handleDropDownOpenCloseClicked}
          >
            Select Permission
          </div>
          <div className="dropdown-menu pb-5 m-0">
            <a
              href="#"
              className="dropdown-item d-flex justify-content-space-between"
            >
              <div className="d-flex align-items-center ml-4">
                <input
                  id={subModule + 'all'}
                  type="checkbox"
                  value={subModule}
                  checked={allChecked}
                  onChange={onSelectAll}
                />
                <label htmlFor={subModule + 'all'} className="m-0 ml-3">
                  {' '}
                  Select All
                </label>
              </div>
            </a>
            <a href="#" className="dropdown-item">
              <div
                className="px-4 py-3"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 30,
                }}
              >
                {options.map((option) => (
                  <Checkbox
                    id={`${subModule}-${option.value}`}
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={option?.checked}
                    onChange={option?.onChange}
                  />
                ))}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonDropDown
