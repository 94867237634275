import React from 'react'

function Extras() {
  return (
    <>
      <div
        className="accordion md-accordion mb-3"
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingOne1"
          >
            <div
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseOne1"
              aria-expanded="false"
              aria-controls="collapseOne1"
              className="onix collapsed px-5 py-4"
            >
              <div
                className=" mb-0 d-flex justify-content-between
                                                    align-items-center"
              >
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">Notifications</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings for the module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div
            id="collapseOne1"
            className="collapse"
            role="tabpanel"
            aria-labelledby="headingOne1"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the Notifications Settings Page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">EVENTS</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">New Entry Created</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Entry Updated</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Entry Deleted</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">
                      Recent/Upcoming Events (Due or Overdue)
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion md-accordion mb-3"
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingTwo2"
          >
            <div
              className="onix collapsed px-5 py-4"
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseTwo2"
              aria-expanded="false"
              aria-controls="collapseTwo2"
            >
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">User Roles</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings for the user roles module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div
            id="collapseTwo2"
            className="collapse p-0"
            role="tabpanel"
            aria-labelledby="headingTwo2"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the User Roles management page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">ROLES</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Create</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Read</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Update</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Delete</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion md-accordion mb-3"
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingThree3"
          >
            <div
              className="onix collapsed px-5 py-4"
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseThree3"
              aria-expanded="false"
              aria-controls="collapseThree3"
            >
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">History</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings for the history module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div
            id="collapseThree3"
            className="collapse p-0"
            role="tabpanel"
            aria-labelledby="headingThree3"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the User Roles management page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">ROLES</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Create</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Read</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Update</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Delete</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion md-accordion mb-3"
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingFour4"
          >
            <div
              className="onix collapsed px-5 py-4"
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseFour4"
              aria-expanded="false"
              aria-controls="collapseFour4"
            >
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">Trash</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings for the trash module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseFour4"
            className="collapse py-0"
            role="tabpanel"
            aria-labelledby="headingFour4"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the User Roles management page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">ROLES</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Create</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Read</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Update</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Delete</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion md-accordion mb-3"
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingFive5"
          >
            <div
              className="onix collapsed px-5 py-4"
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseFive5"
              aria-expanded="false"
              aria-controls="collapseFive5"
            >
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">Account Settings</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings for the account management module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div
            id="collapseFive5"
            className="collapse p-0"
            role="tabpanel"
            aria-labelledby="headingFive5"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the User Roles management page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">ROLES</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Create</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Read</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Update</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Delete</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion md-accordion mb-5 "
        id="accordionEx"
        role="tablist"
        aria-multiselectable="true"
      >
        <div className="card border-radius-12 set-card">
          <div
            className="card-header headingall p-0"
            role="tab"
            id="headingSix6"
          >
            <div
              className="onix collapsed px-5 py-4"
              data-toggle="collapse"
              data-parent="#accordionEx"
              href="#collapseSix6"
              aria-expanded="false"
              aria-controls="collapseSix6"
            >
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="color-blue">
                  <h5 className="mb-0 text-color nots">Calender</h5>
                  <p className="mt-2 mb-0 text-color textper">
                    Permissions settings Permissions settings for the calendar
                    module
                  </p>
                </div>
                <div className="arrow-bg d-flex align-items-center justify-content-center mr-5">
                  <i
                    className="fas fa-angle-down fa-2x rotate-icon"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div
            id="collapseSix6"
            className="collapse p-0"
            role="tabpanel"
            aria-labelledby="headingSix6"
            data-parent="#accordionEx"
          >
            <div className="card-body role-setting">
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">GENERAL</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <p className="my-5 d-flex align-items-center">
                  <input type="checkbox" className="check-w " />{' '}
                  <span className="ml-3">
                    Access the User Roles management page
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center m-acuity">
                <p className="mb-0 gen">ROLES</p>
                <p className="border-cus mb-0 d-flex align-self-center border-w mx-3"></p>
                <p className="mb-0 selall text-align-right d-flex align-items-center justify-content-between ">
                  <input type="checkbox" className="check-w" /> Select All
                </p>
              </div>
              <div>
                <div className="my-4 d-flex align-items-center justify-content-between w-95">
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Create</span>
                  </p>

                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Read</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Update</span>
                  </p>
                  <p className="mb-0 d-flex align-items-center">
                    <input type="checkbox" className="check-w " />{' '}
                    <span className="ml-3">Delete</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Extras
