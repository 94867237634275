/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { sideBarItems } from '../configs/sidebar_config';
import Loader from './common/Loader';
import { SearchBar } from "./common/searchBar";
import Sidebar from './common/Sidebar';
import Header from './Header';
import RightSidebar from './RightSidebar';

function Layout({ component: Component, props }) {
  const isSidNavOpen = useSelector(state => state.isSidNavOpen);
  const [showSearch, setShowSearch] = useState(false);
  // const [searchQuery, setSearchQuery] = useState('');
  // const [result, setResult] = useState(null);
  // const [selectedTags, setSelectedTags] = useState([]);
  const history = useHistory();

  // const handleSearch = async ()=>{
  //   const res = await getAllData(selectedTags);
  //   const allData = res.map(r=>r.data.data).flat(1);
  //   const filteredData = tableSearch(allData,searchQuery);
  //   const categorisedData = groupData(filteredData);
  //   setResult(categorisedData);
  // }

  const showRightSidebar = () => {
    return !(
      history.location.pathname.includes('mvr') ||
      history.location.pathname.includes('thirdParty') ||
      history.location.pathname.includes('dashboard') ||
      history.location.pathname.includes('trash') ||
      history.location.pathname.includes('settings')
    )
  }

  return (
    <>
      <div>
        <section id="main">
          <Header {...props} setShowSearch={setShowSearch} />
          <div className={isSidNavOpen ? "all-wrap table-reponse scroll-change verticlal slide" : showRightSidebar() ? "all-wrap scroll-change verticlal" : "all-wrap f-width"}>
            <Sidebar items={sideBarItems} />
            {
              showRightSidebar() && <RightSidebar/>
            }
            <Component {...props} />
            <Loader />
          </div>
        </section>
        <SearchBar isOpen={showSearch} onClose={() => setShowSearch(false)} />
      </div>
    </>
  )
}
export default Layout
