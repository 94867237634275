import apiUrl from '../configs/api'

/**
 * Export module data to CSV.
 * @param {String} module Module name. Ex. incident
 * @param {String} selectedClientId Current selected client id
 * @param {Boolean} formatted Export raw data or formatted data. Default is false
 */
export const handleExport = (module, selectedClientId, formatted = false) => {
  const client = selectedClientId ? `&clientId=${selectedClientId}` : ''
  const formattedQuery = formatted === true ? '&formatted=true' : ''
  fetch(
    `${apiUrl}/export?module=${module.toLowerCase()}${client}${formattedQuery}`,
    {
      headers: {
        authorization: sessionStorage.getItem('token').toString() || '',
      },
    },
  )
    .then((r) => r.blob())
    .then((r) => {
      const data = URL.createObjectURL(r)
      const anchor = document.createElement('a')
      anchor.href = data
      anchor.download = `${module.toLowerCase()}.csv`
      anchor.click()
      anchor.remove()
    })
}
