import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/common/Table'
import {
  deleteBlukProperty,
  deleteProperty,
  getProperties,
} from '../../../services/propertyService'
import ActionWidget from '../../../components/common/ActionWidget'
import { getPagedData } from '../../../services/helperService'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../../actions'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import { addCommaTableField } from '../../incident/utils'
import { toast } from 'react-toastify'
import Loader from '../../../components/common/Loader'

let properties = []
let itemsCount = 0
function PropertyList({
  searchQuery,
  handleEdit,
  handleView,
  context,
  checkedRecords,
  setShowDeleteButton,
  filters,
  setFilterCount,
  handleElementCheckboxClicked,
  inputFilters,
  dateRangeFilter,
  openBulkDel,
  closeBulkDel,
  handleDechecked

}) {
  const [title, setTitle] = useState('')
  const [deleteOne , setDeleteOne] = useState(false);
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)
  const [property, setProperty] = useState({})
  const [isLoading , setIsLoading] = useState(true);

  const columns = [
    {
      path: 'propertyId',
      label: ' PropertyId ',
      minWidth: '200px',
      width: '240px',
      content: (property) => (
        <>
          <a>{property.propertyId || ''}</a>
          <ActionWidget
            module="PROPERTY"
            onEdit={() => handleEdit(property)}
            onView={() => handleView(property)}
            onDelete={() => {
              setProperty(property)
              setDeleteOne(true);
            }}
          />
        </>
      ),
    },
    { path: 'name', label: ' Name ', type: 'string' },
    { path: 'activeStatus', label: ' Active ', type: 'choice' },
    { path: 'propertyType', label: ' Property Owning ', type: 'string' },
    {
      path: 'dateOfPurchaseOrLeased',
      label: ' Purchased/Leased on ',
      type: 'date',
    },
    { path: 'constructionType', label: ' Construction Type ' },
    { path: 'constructionYear', label: ' Construction Year ' },
    { path: 'propertyMeasurement.area', label: ' Area ', className: 'area' },
    { path: 'propertyMeasurement.height', label: ' Height ' },
    {
      path: 'propertyMeasurement.numberOfUnits',
      label: ' Number Of Units ',
      content: (property) => (
        <>
          <span>
            {property.propertyMeasurement.numberOfUnits
              ? addCommaTableField(property.propertyMeasurement.numberOfUnits)
              : 'N/A'}
          </span>
        </>
      ),
    },
    {
      path: 'policyDetails.policyName',
      label: ' Property Insurance Details ',
    },
  ]
  const categories = columns
    .filter((c) => c.type !== 'date')
    .map((col) => col.label)

  const dispatch = useDispatch()
  const [formOpen, setFormOpen] = useState({ isOpen: false, data: null })
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)

  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedClientId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])
  useEffect(() => {
    if (openBulkDel) {
      setProperty({ property: { name: 'all ' } })
    }
  }, [openBulkDel])

  useEffect(() => {
    if (
      selectedTabId === 'property' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEdit(viewData)
    }
    if (selectedTabId === 'property' && viewAction === 'refresh') {
      setIsLoading(true)
      fetchList()
      dispatch(handleTabIdUpdated('property'))
    }
  }, [viewAction, viewData, selectedTabId])

  const fetchList = async () => {
    const { data } = await getProperties()
    setData(data.data)
    if (data.data && data.data.length) {
      const mapFilterCount = {
        active: 0,
        inactive: 0,
        owned: 0,
        leased: 0,
        constructionYear: 0,
      }
      data.data.forEach(({ activeStatus, propertyType }) => {
        if (activeStatus) {
          mapFilterCount['active'] += 1
        } else {
          mapFilterCount['inactive'] += 1
        }

        const type =
          propertyType && propertyType.length && propertyType.toLowerCase()
        if (type === 'owned') {
          mapFilterCount['owned'] += 1
        } else if (type === 'leased') {
          mapFilterCount['leased'] += 1
        }
      })

      setFilterCount(mapFilterCount)
    }
    if (sideNavDataContext !== 'searchView') {
      dispatch(
        handleSideNavDataUpdated({ data: data.data[0], module: 'property' }),
      )
      dispatch(handleTabIdUpdated('property'))
    }
    setIsLoading(false)
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    properties = result
    return result.length > 0
  }

  const handleDelete = async (item) => {
    await deleteProperty(item._id, item)
    await fetchList()
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
      let params = { ids: checkedRecords }
        const { data } = await deleteBlukProperty(params)
        setShowDeleteButton(false)
        closeBulkDel()
        await fetchList()
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      } else {
      const { data } = await deleteProperty(property._id , property)
      setDeleteOne(false);
      await fetchList()
      if (data && data.msg === 'Success') {
        toast.success('Deletion Successful!')
      } else {
        toast.error('Error: Delete Unsuccessful!')
      }
    }
    } catch (error) {
      console.log('error' , error)
    }
    setProperty({})
  
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handlePageChange = (page) => {
    setCurrPage(page)
  }

  const handleAddNew = () => {
    setFormOpen({ isOpen: true, data: null })
    setTitle('Add New Property')
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData()}
      <Table
        id="property"
        columns={columns}
        data={properties.filter(
          (propertyData) => {
            const { constructionYear: constructionYearInput } = inputFilters

            let {
            propertyType,
            activeStatus,
            constructionYear,
          } = propertyData

            let isPropertyType = false,
              isActiveStatus = false,
              isConstructionYear = false

            let isDateRangeValid = false
            let isDateRangeSelected = Object.values(dateRangeFilter).every(
              (x) => {
                return x.start === null
              },
            )
            if (dateRangeFilter && !isDateRangeSelected) {
              Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
                if (start !== null && propertyData[key] !== '') {
                  if (
                    propertyData[key] > start.toISOString() &&
                    propertyData[key] < end.toISOString()
                  ) {
                    isDateRangeValid = true
                  } else {
                    isDateRangeValid = false
                  }
                }
              })
            }
            if (
              (propertyType === 'OWNED' && filters['owned'].isAvailable) ||
              (propertyType === 'LEASED' && filters['leased'].isAvailable)
            ) {
              isPropertyType = true
            }

            if (
              (activeStatus && filters['active'].isAvailable) ||
              (!activeStatus && filters['inactive'].isAvailable)
            ) {
              isActiveStatus = true
            }

            if (
              constructionYear &&
              constructionYearInput.value &&
              constructionYearInput.value === constructionYear
            ) {
              isConstructionYear = true
            }

           
            return (
              !(
                (filters['owned'].isAvailable ||
                  filters['leased'].isAvailable) ^ isPropertyType
              ) &&
              !(
                (filters['active'].isAvailable ||
                  filters['inactive'].isAvailable) ^ isActiveStatus
              ) &&
              !(!!constructionYearInput.value.length ^ isConstructionYear) &&
              !(!isDateRangeSelected ^ isDateRangeValid)
            )
          },
        )}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked.bind(this, properties)}
      />
      <ConfirmationModal
        name={checkedRecords.length > 0 ? `Properties` : property?.name || ''}
        // isOpen={checkedRecords.length > 0  || deleteOne}
        isOpen={Object.keys(property || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) }      

        onClose={() => {
          setProperty({})
          setShowDeleteButton(false)
          closeBulkDel()
          setDeleteOne(false)
          handleDechecked()
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default PropertyList
