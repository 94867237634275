import React from 'react'
import CommonDropDown from './CommonDropDown'

const Comment = ({
  comment,
  show,
  handleDropDownOpenCloseClicked,
  onCheckedChange,
}) => {
  const onChange = (e) => {
    onCheckedChange(e.target.value, 'comment')
  }

  const options = [
    {
      value: 'READ',
      label: 'Read',
      checked: comment.includes('READ'),
      onChange,
    },
    {
      value: 'CREATE',
      label: 'Create',
      checked: comment.includes('CREATE'),
      onChange,
    },
    {
      value: 'UPDATE',
      label: 'Update',
      checked: comment.includes('UPDATE'),
      onChange,
    },
    {
      value: 'DELETE',
      label: 'Delete',
      checked: comment.includes('DELETE'),
      onChange,
    },
  ]
  const allChecked = options.every((option) => option.checked)
  return (
    <CommonDropDown
      options={options}
      allChecked={allChecked}
      handleDropDownOpenCloseClicked={handleDropDownOpenCloseClicked}
      onSelectAll={() => {
        options.forEach((c) => {
          const e = {
            target: {
              value: c.value,
            },
          }
          if(!allChecked) {
            !c?.checked && c?.onChange(e)
          } else {
            c?.onChange(e);
          }
        })
      }}
      show={show}
      subModule="comment"
      title={'Comment'}
    />
  )
}

export default Comment
