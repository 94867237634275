import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import ActionWidget from '../../components/common/ActionWidget'
import Table from '../../components/common/Table'
import { deleteClaim, getClaims } from '../../services/claimService'
import ClaimsDashboard from '../claims/ClaimsDashboard'
import { getPagedData } from '../../services/helperService'
import Loader from '../../components/common/Loader'
import EditClaimModal from "../claims/EditClaimModal";
import ConfirmationModal from '../../components/common/ConfirmationModal';
import { useSelector } from 'react-redux'

let claims = []
let itemsCount = 0

function IncinentClaimData({activeTab}) {
  const columns = [
    {
      path: 'claimId',
      label: 'Claim Id',
      content: (claim) => (
        <>
          <a>{claim.claimId}</a>
          <ActionWidget
            module='CLAIM'
            onEdit={handleEditButtonClicked.bind(this, claim._id)}
            onDelete={() => {
              setClaimIncident(claim)
            }}
          />
        </>
      ),
    },
    {
      path: `incidents.${0}.incidentDetails.incidentId`,
      label: 'Primary Incident ID',
    },
    { path: 'dateOfClaim', label: 'Date', type: 'date' },
    {
      path: 'timeOfClaim',
      label: 'Time',
      content: (claim) => claim.timeOfClaim,
    },
  ]
  const categories = columns
    .filter((c) => c.type !== 'date')
    .map((col) => col.label)
  const [formOpen, setFormOpen]= useState({isOpen:false, data:null});

  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const [editModalData, setEditModalData] = useState({isOpen: false, id: null})
  const [loading, setLoading] = useState(false);
  const [claimIncidentInfo, setClaimIncident] = useState({});

  const handleEditButtonClicked = (id) => {
    setEditModalData({isOpen: true, id: id})
  };

  const handleEditModalClose = () => {
    setEditModalData({isOpen: false, id: null});
  }

  const {selectedClientId} = useSelector(state=>state);
  useEffect(() => {
    fetchList()
  }, [selectedClientId])
  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }
  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }
  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    claims = result
    return result.length > 0
  }
  const fetchList = async () => {
    setLoading(true);
    const { data } = await getClaims()
    setData(data.data);
    setLoading(false);
  }
  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }
  const handlePageChange = (page) => {
    setCurrPage(page)
  }
  const handleSearch = (query) => {
    setSearchQuery(query)
  }
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handleDelete = async (item) => {
    await deleteClaim(item._id, item)
    await fetchList()
  }
 

  const handleDeleteModal = async () => {
    try {
      await handleDelete(claimIncidentInfo);
    } catch (error) {
        console.log("error");
    }
    setClaimIncident({})
  }

  return (
    <>
      <div className="px-4">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div className="container-fluid" style={{paddingLeft:"0"}}>
                <TabContent activeTab={activeTab}>
                  {/* <hr className="claimhub_border" /> */}
                  <TabPane tabId="1">
                    <h1>Dashboard</h1>
                    <ClaimsDashboard />
                  </TabPane>
                  <TabPane tabId="2">
                    <div
                      className="table-responsive-xl table-reponse scroll-change"
                      style={{ overflow: 'auto' }}
                    >
                      {getPageData() || ''}
                      
                      {loading && <div style={{marginTop: "120px"}} ><Loader loading={loading}/></div>}

                      {!loading &&<Table
                        columns={columns}
                        data={claims}
                        sortColumn={sortColumn}
                        onSort={handleSort}
                        hideCheckbox={true}
                      />}
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
        {editModalData.isOpen ? <EditClaimModal id={editModalData.id} onClose={handleEditModalClose}/> : null}
      </div>
      <ConfirmationModal name={claimIncidentInfo?.name || ""} 
        isOpen={Object.keys(claimIncidentInfo || {}).length > 0} onClose={() => setClaimIncident({})}
        onConfirm={handleDeleteModal} />
    </>
  )
}

export default IncinentClaimData;
