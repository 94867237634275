/* eslint-disable react/prop-types */
import { deleteToken } from 'firebase/messaging'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { handleClientIdUpdated, handleLogout } from '../actions'
// import NotificationAlerts from './NotificationAlerts'
import ActionTypes from '../actionTypes'
import { messaging } from '../App'
import { AccesLevels } from '../configs/utils'
import {
  deleteFirebaseToken,
  markAllNotificationRead
} from '../services/notificationService'
import { getModuleByTabId } from '../utils/getModuleByTabId'
import Firebase from './Firebase'
import NotificationLogs from './NotificationLogs'
function Header({ setShowSearch }) {
  const {
    clients,
    selectedClientId,
    currentUser: user,
    selectedTabId,
    permissions,
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  // const location = useLocation()
  const handleClientSelectionValue = (value) => {
    dispatch(handleClientIdUpdated(value))
  }

  const notifications = useSelector((state) => state.notifications)

  const logout = () => {
    const firebaseToken = sessionStorage.getItem('firebaseToken')
    if (firebaseToken) {
      deleteToken(messaging).then(() => {
        deleteFirebaseToken(firebaseToken).then(() => {
          sessionStorage.removeItem('firebaseToken')
          dispatch(handleLogout())
        })
      })
    } else {
      sessionStorage.removeItem('firebaseToken')
      dispatch(handleLogout())
    }
  }

  const isNewNotification = () => {
    return notifications?.find((c) => c.isRead === false)
  }

  const countUnreadNotification = () => {
    let unreadNotification = notifications?.filter((c) => c.isRead === false)
    return unreadNotification.length
  }

  const [showUnread, setShowUnread] = useState(false)

  const showAllClientOption = useMemo(() => {
    return (
      permissions?.find(
        (c) => c.module.identifier == getModuleByTabId(selectedTabId),
      )?.accessMode !== AccesLevels['ORGANISATION_ACCESS']
    )
  }, [permissions, selectedTabId])

  // const

  useEffect(() => {
    if (!showAllClientOption) {
      handleClientSelectionValue(clients[0]?._id)
    } else {
      handleClientSelectionValue('allClients')
    }
  }, [showAllClientOption, clients])

  return (
    <header>
      <div className="d-flex flex-column flex-md-row align-items-center p-2 px-md-4 bg-white border-bottom shadow-sm header-top">
        <span className="my-0 mr-md-auto font-weight-normal acuity-logo">
          <Link to="/">
            <img
              style={{ margin: '10px 0', maxWidth: '130px', width: '100%' }}
              // src="/assets/images/Acuity-logo.svg"
              src={process.env.REACT_APP_FIREBASE_ENV === "production" ? "/assets/images/production-caps-logo.svg" : "/assets/images/Acuity-logo-staging.svg"}
            />
          </Link>
        </span>
        <Firebase />
        <nav className="my-2 my-md-0 mr-md-0">
          {clients?.length > 0 && (
            <>
              <li className="nav-item mt-1 mx-3">
                <select
                  value={selectedClientId}
                  onChange={({ currentTarget }) => {
                    handleClientSelectionValue(currentTarget.value)
                  }}
                  className="form-control admin_select"
                >
                  {showAllClientOption && (
                    <option key="default" value="allClients">
                      All Clients
                    </option>
                  )}
                  {clients.map((cl) => {
                    return (
                      <option key={cl._id} value={cl._id}>
                        {cl.clientId}-{cl.companyName}
                      </option>
                    )
                  })}
                </select>
              </li>
            </>
          )}
          <li className="nav-item">
            <a
              className=" text-dark d-inline-block pulse-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowSearch(true)}
            >
              <span>
                <img src="/assets/images/icon/search.svg" />
              </span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a className=" text-dark" style={{ cursor: 'pointer' }}>
              <span>
                <img src="/assets/images/notifications.svg" />
              </span>
            </a>
          </li> */}
          <li className="nav-item">
            <div className="dropdown">
              <div
                className="topbar-item"
                data-toggle="dropdown"
                data-offset="10px,0px"
                aria-expanded="false"
              >
                <div className="btn-dropdown pulse-primary">
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    {isNewNotification() && (
                      <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 blink">
                        <span className="notificaton-ribbon">
                          {countUnreadNotification() > 99 ? '99+' : countUnreadNotification()}
                        </span>
                      </span>
                    )}

                    <svg
                      id="Group_10455"
                      data-name="Group 10455"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                    >
                      <circle
                        id="Ellipse_8"
                        data-name="Ellipse 8"
                        cx="25"
                        cy="25"
                        r="25"
                        fill="none"
                      ></circle>
                      <path
                        id="notifications"
                        d="M734.059,1825.2v3.075c-3.656.544-6.489,4.044-6.489,8.276v4.137a6.159,6.159,0,0,1-2.528,5.484l-1.992,1.668h8.343a3.67,3.67,0,0,0,7.314,0h8.343l-1.993-1.668a6.16,6.16,0,0,1-2.527-5.484v-4.137c0-4.233-2.833-7.732-6.49-8.276V1825.2Zm.991,24.1a1.76,1.76,0,0,1-1.669-1.458h3.34A1.76,1.76,0,0,1,735.05,1849.3Zm5.5-12.747v4.137a7.908,7.908,0,0,0,1.57,5.25H727.981a7.907,7.907,0,0,0,1.57-5.25v-4.137c0-3.56,2.466-6.457,5.5-6.457S740.548,1832.995,740.548,1836.555Z"
                        transform="translate(-710.067 -1814.035)"
                        fill="#07448a"
                      ></path>
                    </svg>
                  </span>
                  <span className="pulse-ring"></span>
                </div>
              </div>

              <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg notify-menu">
                <form>
                  <div className="d-flex flex-column pt-4">
                    <h4 className="d-flex justify-content-between align-items-center rounded-top px-4">
                      <span className="usr">Notifications</span>
                      <span className="d-flex ml-2 new align-items-center">
                        Only show unread notifications
                        <label className="switch mb-0 ml-3">
                          <input
                            type="checkbox"
                            id="togBtn"
                            checked={showUnread}
                            onChange={() => setShowUnread(!showUnread)}
                          />
                          <div className="slider round">
                            <span className={showUnread ? '' : 'off'}>
                              <i
                                className="fa fa-check"
                                style={{
                                  fontSize: '10px',
                                  color: '#fff',
                                  marginLeft: '6px',
                                }}
                              ></i>
                            </span>
                            <span className={showUnread ? 'on' : ''}>
                              <i
                                className="fa fa-close"
                                style={{
                                  fontSize: '10px',
                                  color: '#fff',
                                  marginLeft: '4px',
                                }}
                              ></i>
                            </span>
                          </div>
                        </label>
                      </span>
                    </h4>

                    <ul
                      className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success px-2"
                      role="tablist"
                    >
                      <li className="nav-item font-weight-normal">
                        <a
                          className="nav-link nav-link show  active font-weight-normal"
                          data-toggle="tab"
                          href="#topbar_notifications_logs"
                          id="notify"
                        >
                          Logs
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane show active"
                      id="topbar_notifications_notifications"
                      role="tabpanel"
                    >
                      <div className="d-flex justify-content-between">
                        <small className="d-flex text-muted px-4 mt-3">
                          LATEST
                        </small>
                        {isNewNotification() && (
                          <small className="d-flex text-muted px-4 mt-3 ">
                            <a
                              href="#"
                              className="allread"
                              onClick={async () => {
                                await markAllNotificationRead()
                                dispatch({
                                  type: ActionTypes.MARK_ALL_NOTIFICATION_READ,
                                })
                              }}
                            >
                              Mark all as read
                            </a>
                          </small>
                        )}
                      </div>
                      <div
                        className="navi navi-hover scroll my-2 scroll-change"
                        data-scroll="true"
                        data-height="300"
                        data-mobile-height="200"
                        style={{ height: '500px', overflow: 'hidden' }}
                      >
                        <NotificationLogs showUnread={showUnread} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
          <li className="nav-item" style={{ cursor: 'pointer' }}>
            <div className="pulse-primary">
              <a
                className="text-dark"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src="/assets/images/icon/profile.svg" />
              </a>
              <div className="dropdown-menu dropdown-menu-left user-dd animated">
                <a className="dropdown-item d-flex" href="">
                  <div className="log log-txt d-flex justify-content-center">
                    <span>
                      {`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(
                        0,
                      )}`}{' '}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <strong className=" pl-2">
                      {user?.firstName}
                      {user?.lastName}
                    </strong>
                  </div>

                  <div className="mb-10"></div>
                </a>
                <div className="mb-10" />
                <div className="dropdown-divider" />
                <div className="mb-10" />
                {/* <Link to={`/auth/reset-password/${user?._id}`} className="dropdown-item">Reset Password</Link> */}
                <Link to="/settings" className="dropdown-item">
                  Settings
                </Link>
                <a className="dropdown-item" onClick={logout}>
                  Logout
                </a>
              </div>
            </div>
          </li>
        </nav>
      </div>
    </header>
  )
}

export default Header