import { Form, Formik } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
// import AddAttachment from '../../../components/addAttachment'
import AddOrganizationalStructureBtn from '../../../components/AddOrganizationalStructureBtn'
import AddOwnerShipModalBtn from '../../../components/AddOwnerShipModalBtn'
import AddPayrollProcessorBtn from '../../../components/AddPayrollProcessorBtn'
import AddRevenueModalBtn from '../../../components/AddRevenueModalBtn'
import AddVendorModalBtn from '../../../components/AddVendorModalBtn'
import AttachmentAddView from '../../../components/AttachmentAddView'
import AreYouSureModal from '../../../components/common/AreYouSureModal'
import AddressInput from '../../../components/form/AddressInput'
import Select from '../../../components/form/Select'
import TextInput from '../../../components/form/TextInput'
import { stateOptions } from '../../../configs/utils'
import { saveClient, updateClient } from '../../../services/clientService'
import { generateUID } from '../../../utils/generateUID'
import { CLIENT_VALIDATION_SCHEMA } from '../../../utils/validation'
import { addComma, addCommaTableField } from '../../incident/utils'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'

const corporationOptions = [
  { value: 'Corp-C', key: 'Corp-C' },
  { value: 'Corp-S', key: 'Corp-S' },
  { value: 'LLC', key: 'LLC' },
  { value: 'LLP', key: 'LLP' },
  { value: 'Sole-Prop', key: 'Sole-Prop' },
]

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function ClientForm({ data, title, onClose }) {
  const ref = useRef()
  const [isNew, setIsNew] = useState(false)
  const [values, setValues] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  // const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [activeEditModalData, setActiveEditModalData] = useState({
    isOpen: false,
    key: '',
    data: [],
    index: null,
  })
  const [validateOwnerStructure, setValidateOwnerStructure] = useState(false)

  const closeModal = () => setOpenModal(false)

  const newClient = () => ({
    clientId: generateUID('client'),
    companyName: '',
    taxId: '',
    unemployementId: '',
    incorporationState: '',
    corporationType: '',
    ownershipStructure: [],
    businessAddress: {
      state: '',
      city: '',
      country: '',
      line1: '',
      line2: '',
      pinCode: '',
    },
    fiscalYear: '',
    issueDate: '',
    annualRevenue: [],
    payrollProcessor: [],
    organisationStructure: [],
    lawyers: [],
    accountants: [],
    thirdPartySoftware: [],
    attachments: [],
  })

  const handleSubmit = async (values) => {
    setValues(values)
    if (
      values.ownershipStructure.length === 0 ||
      values?.ownershipStructure[0]?.email === ''
    ) {
      setValidateOwnerStructure(true)
    } else {
      setOpenModal(true)
    }
  }

  const handleSubmitModal = async () => {
    const payload = {
      companyName: values.companyName,
      email: values.ownershipStructure[0]?.email,
      taxId: values.taxId,
      unemployementId: values.unemployementId,
      issueDate: values.issueDate ? moment(values.issueDate).format(NO_TIMEZONE_DATE_FORMAT) : '',
      incorporationState: values.incorporationState,
      corporationType: values.corporationType,
      ownershipStructure: values.ownershipStructure,
      businessAddress: values.businessAddress,
      fiscalYear: values.fiscalYear,
      annualRevenue: values.annualRevenue,
      payrollProcessor: values.payrollProcessor,
      organisationStructure: values.organisationStructure,
      lawyers: values.lawyers,
      accountants: values.accountants,
      thirdPartySoftware: values.thirdPartySoftware,
      attachments: values.attachments,
      clientId: values.clientId,
    }
    setOpenModal(false)
    try {
      if (isNew) {
        await saveClient(payload)
        onClose()
        toast.success('Client Added!')
      } else {
        await updateClient(values._id, payload)
        onClose()
        toast.success('Client Updated!')
      }
    } catch (err) {
      toast.error(err)
    }
  }

  useEffect(() => {
    if (data) {
      setIsNew(false)
      setValues(data)
    } else {
      setIsNew(true)
      setValues(newClient())
    }
  }, [data])

  const onModalClose = (key, val) => {
    let updatedValues = ref.current?.values || {}
    if (updatedValues[key]) {
      updatedValues[key].push(val)
      setValues({ ...updatedValues })
    }
  }

  // const handleAddAttachmentToggle = () => {
  //   setOpenAttachmentModal(!openAttachmentModal)
  // }

  // const onAttachmentUpload = (file) => {
  //   handleAddAttachmentToggle()
  //   onModalClose('attachments', file[0])
  // }

  let getOwnershipStructureView = () => {
    let ownershipViews = []

    values.ownershipStructure.forEach(function (ownershipData, index) {
      ownershipViews.push(
        <div className="parties bg-white p-3 mb-3" key={index}>
          <div className="pull-right pb-2">
            <button
              type="button"
              className="btn btn-outline-secondary side-btn mr-1"
              onClick={handleEditModal.bind(
                this,
                'ownershipStructure',
                ownershipData,
                index,
              )}
            >
              <i className="fas fa-pencil-alt"></i>
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary side-btn"
              onClick={handleDeleteCard.bind(this, 'ownershipStructure', index)}
            >
              <i className="far fa-trash-alt"></i>
            </button>
          </div>
          <h6 className="text-primery">{ownershipData.name}</h6>
          <p className="mb-2">{ownershipData.phoneNumber}</p>
          {ownershipData.address && (ownershipData.address.country || ownershipData.address.pinCode) &&(
            <p className="mb-2">
              <i className="fas fa-map-marker-alt mr-2"></i>
              {ownershipData.address.country} - {ownershipData.address.pinCode}
            </p>
          )}
          <span className="badge badge-pill btn btn-outline-primary pt-1">
            {ownershipData.ownerShare || 0 }% Owned
          </span>
        </div>,
      )
    })

    return ownershipViews
  }

  let getAnnualRevenueView = () => {
    let annualRevenueViews = []

    values.annualRevenue.forEach(function (annualRevenueData, index) {
      annualRevenueViews.push(
        <div className="col-md-4" key={index}>
          <div className="parties price bg-white p-3 mb-3">
            <div
              className="x-btn"
              onClick={handleDeleteCard.bind(this, 'annualRevenue', index)}
            >
              &times;
            </div>
            <h6 className="text-primery">{annualRevenueData.year}</h6>
            <small className="mb-2">${annualRevenueData.revenue ? addComma(annualRevenueData.revenue) : ''}</small>
          </div>
        </div>,
      )
    })

    return annualRevenueViews
  }

  let getPayrollProcessorViews = () => {
    let payrollProcessorViews = []

    values.payrollProcessor.forEach(function (payroll, index) {
      let date = new Date(payroll.addedOn)
      payrollProcessorViews.push(
        <div className="parties bg-white p-3 mb-3" key={index}>
          <div className="d-flex justify-content-between">
            <h6 className="text-primery weekly">
              <small>Frequency</small>
              {payroll.frequency}
            </h6>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-1"
                onClick={handleEditModal.bind(
                  this,
                  'payrollProcessor',
                  payroll,
                  index,
                )}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={handleDeleteCard.bind(this, 'payrollProcessor', index)}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="weekly col-md-4">
              <small>As of Date:</small>
              {moment(date).format('MM/DD/YYYY')}
            </div>
            <div className="weekly col-md-4">
              <small>Notes:</small>
              {payroll.notes ? '***********' : ''}
            </div>
            <div className="weekly col-md-4">
              <small>Payroll Cost:</small>${payroll.payrollCost ? addCommaTableField(payroll.payrollCost) : ''}
            </div>
          </div>
        </div>,
      )
    })

    return payrollProcessorViews
  }

  let getPropertyModal = () => {
    let modal = null
    let title
    switch (activeEditModalData.key) {
      case 'organisationStructure':
        modal = (
          <AddOrganizationalStructureBtn
            isOpen={true}
            modalClose={handleModalClose}
            title={'Edit Organizational Structure'}
            values={activeEditModalData.data}
          />
        )
        break
      case 'lawyers':
      case 'accountants':
      case 'thirdPartySoftware':
        if (activeEditModalData.key === 'lawyers') {
          title = 'Edit Lawyer'
        } else if (activeEditModalData.key === 'accountants') {
          title = 'Edit Accountants'
        } else {
          title = 'Edit Third Party Software'
        }
        modal = (
          <AddVendorModalBtn
            isOpen={true}
            title={title}
            modalClose={handleModalClose}
            values={activeEditModalData.data}
          />
        )
        break
      case 'payrollProcessor':
        modal = (
          <AddPayrollProcessorBtn
            isOpen={true}
            modalClose={handleModalClose}
            title={'Edit Payroll Processor'}
            values={activeEditModalData.data}
          />
        )
        break
      case 'ownershipStructure':
        modal = (
          <AddOwnerShipModalBtn
            isOpen={true}
            title={'Edit Ownership'}
            modalClose={handleModalClose}
            values={activeEditModalData.data}
            setValidateOwnerStructure={setValidateOwnerStructure}
          />
        )
        break
    }

    return modal
  }

  let handleEditModal = (key, data, index) => {
    setActiveEditModalData({ isOpen: true, key: key, data: data, index: index })
  }

  let handleModalClose = (data) => {
    if (data) {
      values[activeEditModalData.key][activeEditModalData.index] = data
      setValues(values)
    }
    setActiveEditModalData({ isOpen: false, key: '', data: {}, index: null })
  }

  let handleDeleteCard = (key, index) => {
    values[key].splice(index, 1)
    let val = { ...values }
    setValues(val)
  }

  let geOrganisationStructureViews = () => {
    let OrganisationStructureViews = []

    values.organisationStructure.forEach(function (org, index) {
      OrganisationStructureViews.push(
        <div className="parties bg-white p-3 mb-3" key={index}>
          <div className="d-flex justify-content-between">
            <h6 className="text-primery weekly">
              <small>Name</small>
              {_.map((org?.name || ' ')?.split(' '), _.capitalize).join(' ')}
            </h6>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-1"
                onClick={handleEditModal.bind(
                  this,
                  'organisationStructure',
                  org,
                  index,
                )}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={handleDeleteCard.bind(
                  this,
                  'organisationStructure',
                  index,
                )}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="weekly col-md-4"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <small>Position:</small>
              {org.position}
            </div>
            <div
              className="weekly col-md-4"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <small>Email:</small>
              {org.email}
            </div>
            <div className="weekly col-md-4">
              <small>Phone:</small>
              {org.phoneNumber}
            </div>
          </div>
        </div>,
      )
    })

    return OrganisationStructureViews
  }

  let getLawyerProcessorViews = () => {
    let lawyerProcessorViews = []

    values.lawyers.forEach(function (lawyer, index) {
      let date = new Date(lawyer.addedOn)
      lawyerProcessorViews.push(
        <div className="parties bg-white p-3 mb-3 " key={index}>
          <div className="d-flex justify-content-between">
            <h6 className="text-primery weekly">{lawyer.name}</h6>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-1"
                onClick={handleEditModal.bind(this, 'lawyers', lawyer, index)}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={handleDeleteCard.bind(this, 'lawyers', index)}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="weekly col-md-4">
              <small>As of Date:</small>
              {moment(date).format('MM/DD/YYYY')}
            </div>
            <div className="weekly col-md-4">
              <small>Notes:</small>
              ***********
            </div>
          </div>
        </div>,
      )
    })

    return lawyerProcessorViews
  }

  let getAccountantViews = () => {
    let accountantViews = []

    values.accountants.forEach(function (accountant, index) {
      let date = new Date(accountant.addedOn)
      accountantViews.push(
        <div className="parties bg-white p-3 mb-3" key={index}>
          <div className="d-flex justify-content-between">
            <h6 className="text-primery weekly">{accountant.name}</h6>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-1"
                onClick={handleEditModal.bind(
                  this,
                  'accountants',
                  accountant,
                  index,
                )}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={handleDeleteCard.bind(this, 'accountants', index)}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="weekly col-md-4">
              <small>As of Date:</small>
              { date ? moment(date).format('MM/DD/YYYY') : 'N/A'}
            </div>
            <div className="weekly col-md-4">
              <small>Notes:</small>
              ***********
            </div>
          </div>
        </div>,
      )
    })

    return accountantViews
  }

  let getThirdPartySoftwareViews = () => {
    let thirdPartySoftwareViews = []

    values.thirdPartySoftware.forEach(function (thirdPartySoftware, index) {
      let date = new Date(thirdPartySoftware.addedOn)
      thirdPartySoftwareViews.push(
        <div className="parties bg-white p-3 mb-3" key={index}>
          <div className="d-flex justify-content-between">
            <h6 className="text-primery weekly">{thirdPartySoftware.name}</h6>
            <div className="">
              <button
                type="button"
                className="btn btn-outline-secondary side-btn mr-1"
                onClick={handleEditModal.bind(
                  this,
                  'thirdPartySoftware',
                  thirdPartySoftware,
                  index,
                )}
              >
                <i className="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary side-btn"
                onClick={handleDeleteCard.bind(
                  this,
                  'thirdPartySoftware',
                  index,
                )}
              >
                <i className="far fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="weekly col-md-4">
              <small>As of Date:</small>
              {date ? moment(date).format('MM/DD/YYYY') : 'N/A'}
            </div>
            <div className="weekly col-md-4">
              <small>Notes:</small>
              ***********
            </div>
          </div>
        </div>,
      )
    })

    return thirdPartySoftwareViews
  }

  const handleValueUpdated = (key, val) => {
    let updatedValues = ref.current?.values || {}
    updatedValues[key] = val
    setValues({ ...updatedValues })
  }

  const getDate = (date) => {
    let t = ''
    if (date) {
      try {
        t = new Date(date)
      } catch (err) {
        t = new Date()
      }
    }
    return t && t.toDateString() === 'Invalid Date' ? new Date() : t
  }

  return (
    <div className="row no-gutters col-container">
      <div className="col-md-7 col-h">
        <div className="modal-body p-4 px-3" style={{ overflow: 'hidden' }}>
          <h2 className="modal-title text-primary mb-3">{title}</h2>
          <div className="border-bottom h6 py-3 mb-4 text-primary">
            Company Information
          </div>
          <Formik
            validationSchema={CLIENT_VALIDATION_SCHEMA}
            innerRef={ref}
            enableReinitialize
            initialValues={{
              ...values,
            }}
            onSubmit={handleSubmit}
            setFieldValue
          >
            {({ setFieldValue }) => (
              <Form className="indent-form ">
                <div className="form-row">
                  <div className="form-group col-md-6" style={{ margin: '0' }}>
                    <TextInput label="Company Name" name="companyName" />
                  </div>
                  <div className="form-group col-md-6" style={{ margin: '0' }}>
                    <TextInput label="FEIN/TAX ID" name="taxId" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <TextInput
                      label="State Unemployement ID"
                      name="unemployementId"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <Select
                      label="Type of Corporation"
                      name="corporationType"
                      defaultLabel="Type of Corporation"
                      options={corporationOptions}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      label="State of Incorporation"
                      name="incorporationState"
                      defaultLabel="Select State of Incorporation"
                      options={stateOptions}
                    />
                  </div>
                </div>
                <AddressInput
                  label="Business Address"
                  name="businessAddress"
                  setFieldValue={setFieldValue}
                />
                <div className="d-flex justify-content-between">
                  <div></div>
                  <button type="submit" className="btn btn-primary ">
                    Save &amp; Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <AreYouSureModal
            openModal={openModal}
            closeModal={closeModal}
            handleSubmit={handleSubmitModal}
          />
        </div>
      </div>
      {values && (
        <div
          className="col-md-5 col-h border-left"
          style={{ background: '#F4F7FE' }}
        >
          <div className="modal-body p-4 mdl-scroll">
            <div className="row">
              <div className="col-md-12">
                <label className="incident-text">
                  Client ID
                  <span className="text-primery">
                    {values.clientId}
                    <b className="text-dark font-weight-normal ml-2">
                      (System Generated)
                    </b>
                  </span>
                </label>
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Fiscal Year End</label>
                  <DatePicker
                    renderCustomHeader={({ date, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <select
                          value={months[date]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    autoComplete="off"
                    placeholderText="MM/DD"
                    className="form-control"
                    dateFormat="MM/dd"
                    selected={getDate(values?.fiscalYear)}
                    onChange={(val) => {
                      handleValueUpdated('fiscalYear', val)
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Filing Date</label>
                  <DatePicker
                    autoComplete="off"
                    placeholder=""
                    className="form-control"
                    placeholderText="MM/DD/YYYY"
                    selected={getDate(values?.issueDate)}
                    onChange={(val) => {
                      handleValueUpdated('issueDate', val)
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  />
                </div>
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Ownership Structure
                </label>
                {values.ownershipStructure && getOwnershipStructureView()}
                <AddOwnerShipModalBtn
                  title={'Add Ownership'}
                  modalClose={onModalClose.bind(this, 'ownershipStructure')}
                  buttonLabel="Add.."
                  btnClasses="btn btn-outline-light text-dark cus-btn"
                  setValidateOwnerStructure={setValidateOwnerStructure}
                />
                {validateOwnerStructure && (
                  <span className="text-danger">
                    Ownership email is required.
                  </span>
                )}
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Annual Revenue
                </label>
                <div className="form-row">
                  {values.annualRevenue && getAnnualRevenueView()}
                </div>
                <AddRevenueModalBtn
                  modalClose={onModalClose.bind(this, 'annualRevenue')}
                  buttonLabel="Add.."
                  btnClasses="btn btn-outline-light text-dark cus-btn"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Organizational Structure
                </label>
                {values.organisationStructure && geOrganisationStructureViews()}
                <AddOrganizationalStructureBtn
                  modalClose={onModalClose.bind(this, 'organisationStructure')}
                  buttonLabel="Add.."
                  title={'Add Organizational Structure'}
                  btnClasses="btn btn-outline-secondary side-btn mt-2"
                />
              </div>
              <div className="w-100 border-top border-primary border-bottom mx-2 p-0 mb-3 "></div>
              <div className="form-group col-md-12">
                <h6 className="text-primery">Vendor List</h6>
                <label className="d-block incident-text" htmlFor="package">
                  Payroll Processor
                </label>
                {values.payrollProcessor && getPayrollProcessorViews()}
                <AddPayrollProcessorBtn
                  title={'Add Payroll Processor'}
                  modalClose={onModalClose.bind(this, 'payrollProcessor')}
                  buttonLabel="Add.."
                  btnClasses="btn btn-outline-secondary side-btn mt-2"
                />
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Lawyer
                </label>
                {values.lawyers && getLawyerProcessorViews()}
                <AddVendorModalBtn
                  title={'Add Lawyer'}
                  bodyHeading={'Lawyer Details'}
                  modalClose={onModalClose.bind(this, 'lawyers')}
                  buttonLabel="Add.."
                  values={{ addedOn: new Date(), name: '', notes: '' }}
                  btnClasses="btn btn-outline-light text-dark cus-btn"
                />
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Accountant
                </label>
                {values.accountants && getAccountantViews()}
                <AddVendorModalBtn
                  title={'Add Accountant'}
                  bodyHeading={'Accountant Details'}
                  modalClose={onModalClose.bind(this, 'accountants')}
                  buttonLabel="Add.."
                  values={{ name: '', addedOn: new Date(), notes: '' }}
                  btnClasses="btn btn-outline-light text-dark cus-btn"
                />
              </div>
              <div className="w-100 border-top mx-2 py-2 mt-1"></div>
              <div className="form-group col-md-12">
                <label className="d-block incident-text" htmlFor="package">
                  Third Party Softwares
                </label>
                {values.thirdPartySoftware && getThirdPartySoftwareViews()}
                <AddVendorModalBtn
                  title={'Add Third Party Software'}
                  bodyHeading={'Third Party Software Details'}
                  modalClose={onModalClose.bind(this, 'thirdPartySoftware')}
                  buttonLabel="Add.."
                  values={{ name: '', addedOn: new Date() }}
                  btnClasses="btn btn-outline-light text-dark cus-btn"
                />
              </div>
              <div className="w-100 border-top border-primary border-bottom mx-2 p-0 mb-3 "></div>
              <div className="form-group col-md-12">
                <h6 className="text-primery">Policies & Other Documents</h6>
                {/* <label className="d-block incident-text" htmlFor="package">
                  Attachments
                </label>
                <div className="row">{getAttachmentViews()}</div>
                <AddAttachment
                  module="client"
                  uid={values.clientId}
                  onClose={handleAddAttachmentToggle}
                  onAdd={onAttachmentUpload}
                  isOpen={openAttachmentModal}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary side-btn mr-3"
                  id="exampleModalLabel"
                  data-toggle="modal"
                  data-target="#attachuplaods"
                  onClick={handleAddAttachmentToggle}
                >
                  Add..
                </button> */}
                <AttachmentAddView
                  formik={{ setFieldValue: ref.current.setFieldValue, values }}
                  module={'client'}
                  uid={values.clientId}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {activeEditModalData.isOpen && getPropertyModal()}
    </div>
  )
}

export default ClientForm
