import React from 'react'

const Checkbox = ({ checked, value, onChange, name, label, id }) => {
  return (
    <div className=" d-flex align-items-center">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        value={value}
        name={name}
        onChange={onChange}
      />
      <label htmlFor={id} className="m-0 ml-3">
        {' '}
        {label}
      </label>
    </div>
  )
}

export default Checkbox
