import React, { useContext } from 'react'
import ModuleRow from './ModuleRow'
import RolesContext from './RolesContext'

function Modules() {
  const Operations = ['CREATE', 'READ', 'UPDATE', 'DELETE']

  const formik = useContext(RolesContext)

  const permissions = formik.values.permissions

  const headChecked = (operation) => {
    let shouldBeChecked = true;
   formik.values.permissions.forEach((data) => {
        if(!data.operations.includes(operation)) {
          shouldBeChecked = false
        }
    })
    
    return shouldBeChecked;
  }
  const handleModuleSelectionChange = (operation, add) => {
    let filterData
    if (add) {
      filterData = formik.values.permissions.map((data) => {
        if (!data.operations.includes(operation)) {
          data.operations.push(operation)
        }
        return data
      })
    } else {
      filterData = formik.values.permissions.map((data) => {
        data.operations = data.operations.filter((c) => c !== operation)
        return data
      })
    }

    formik.setValues((v) => {
      return {
        ...v,
        permissions: [...filterData],
      }
    })
  }

  const allselectdisable = !!formik.values.permissions.find(c=>c.accessMode === 'NO_ACCESS');

  return (
    <div className="table-responsive-xl">
      <table className="table table-hover">
        <thead
          className="table-active text-center"
          style={{ position: 'relative' }}
        >
          <tr className="sticky">
            <th
              style={{
                width: '50px',
                verticalAlign: 'middle',
              }}
              className="py-4"
            >
              {/* <div className="hvr-pulse1">
                <button
                  type="button"
                  className="btn btn-outline-secondary side-btn"
                >
                  <i className="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              </div> */}
            </th>
            <th style={{ minWidth: '116px' }}>
              <a className="text-decoration-none"></a>
            </th>

            {Operations?.map((operation) => (
              <th
                style={{
                  minWidth: '200px',
                  textAlign: operation === 'DELETE' ? 'left' : 'null',
                }}
                key={operation}
              >
                {operation}
                <div
                  className={`${
                    operation === 'DELETE' ? 'text-left' : 'text-center'
                  } mt-2`}
                >
                  <input
                    disabled={allselectdisable}
                    className={operation === 'DELETE' && 'ml-3'}
                    type="checkbox"
                    value={operation}
                    checked={headChecked(operation)}
                    onChange={(e) => {
                      handleModuleSelectionChange(operation, e.target.checked)
                    }}
                  />
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permissionObj, index) => (
            <ModuleRow
              key={index}
              Operations={Operations}
              permissionObj={permissionObj}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Modules
