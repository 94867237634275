import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal } from 'reactstrap'
import {
  uploadMVRFilesWithPresignedUrl,
  uploadPublicIncidentFilesWithPresignedUrl,
} from '../services/attachmentService'

export default function AddAttachmentMVR({
  module = '',
  uid,
  onAdd,
  isOpen,
  onClose,
  token,
  totalAttachments = 0,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filename, setFilename] = useState([])
  const [attachments, setAttachments] = useState(null)
  const [files, setFiles] = useState(null)

  useEffect(() => {
    filename !== '' && setFilename([])
  }, [])

  // const fileUpload = (urls) => {
  //     setURLs(urls);
  // };

  const handleFile = async (event) => {
    setIsSubmitting(true)
    let filename = []
    for (const key in event.target.files) {
      if (Object.hasOwnProperty.call(event.target.files, key)) {
        const element = event.target.files[key]
        filename.push(element.name)
      }
    }

    const newFiles = [...event.target.files]
    if (newFiles.length > 0) {
      if (module === 'mvr') {
        const data = await uploadMVRFilesWithPresignedUrl(
          newFiles,
          module,
          uid,
          token,
        ).finally(() => setIsSubmitting(false))
        setAttachments((prev) => (prev ? [...prev, ...data] : data))
      } else if (module === 'public-incident') {
        const existingFilesLength = files?.length ?? 0
        if (
          newFiles.length > 5 ||
          existingFilesLength >= 5 ||
          existingFilesLength + newFiles.length > 5 ||
          totalAttachments + newFiles.length > 5
        ) {
          toast.error('You may select a maximum of five files.')
          event.target.value = null
          setIsSubmitting(false)
          return
        }

        const data = await uploadPublicIncidentFilesWithPresignedUrl(
          newFiles,
          module,
          uid,
          true,
        ).finally(() => setIsSubmitting(false))
        setAttachments((prev) => (prev ? [...prev, ...data] : data))
        setFiles((prev) => (prev ? [...prev, ...newFiles] : newFiles))
      }
    }
    setFilename((prev) => [...prev, ...filename])
    event.target.value = null
    // const filesToUpload = []
    // for (const file of files) {
    //     const obj = {
    //         key: file.name,
    //         contentType: file.type,
    //     }
    //     filesToUpload.push(obj)
    // }
    // const {data: urls} = await getPreSignedUrl({files: [...filesToUpload]},props?.module)
    // fileUpload(urls.data.map((u) => u.objectUrl))
    // uploadFiles(files, urls)
  }

  const handleOnAdd = () => {
    attachments && onAdd(attachments, files)
  }

  // const uploadFiles = async (files, urls) => {
  //     const promiseArray = []
  //     const filesArray = Array.from(files)
  //     for (let i = 0; i < filesArray.length; i++) {
  //         const myHeaders = new Headers()
  //         myHeaders.append('ContentType', filesArray[i].type)
  //         const resp = uploadFile(
  //             urls.data[i].preSignedUrl,
  //             filesArray[i],
  //             myHeaders,
  //         )
  //         promiseArray.push(resp)
  //     }
  //     let promiseAll= await Promise.all(promiseArray)
  //     console.log("promiseAll===>>",promiseAll);
  // };

  return (
    <Modal isOpen={isOpen}>
      <div className="excelfile" style={{ margin: '0', zIndex: '1050' }}>
        <div className="modal-content border-radius-file" data-testid="add-attachment-modal-content">
          <div className="modal-header d-flex justify-content-center">
            <h5 className="modal-title text-primary">Add Attachment</h5>
          </div>
          <div className="modal-body pb-0">
            <div className="form-group">
              <div className="dropzone dropzone-default dropzone-primary dz-clickable">
                {!filename.length ? (
                  <div className="dropzone-msg dz-message needsclick">
                    <img className="mb-2" src="/assets/images/icon/upload.svg" />
                    <h3 className="dropzone-msg-title">
                      Drag and Drop Here <br /> or
                    </h3>
                    <span className="dropzone-msg-desc">Browse files</span>
                  </div>
                ) : (
                  <div className="dropzone-msg dz-message needsclick">
                    File Added:{' '}
                    {filename.map((file, index) => (
                      <div key={index}>
                        <span>{file}</span>
                        <br></br>
                      </div>
                    ))}
                  </div>
                )}
                <input
                  type="file"
                  multiple
                  max={5}
                  id="upload-picture"
                  onChange={handleFile}
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="fileupload modal-footer border-0 cusmdl-ft d-flex">
            <button
              type="submit"
              disabled={!filename.length || isSubmitting}
              className="btn btn-primary  add-btn"
              onClick={handleOnAdd}
            >
              {isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  aria-hidden="true"
                ></span>
              )}
              <span role="status">Add</span>
            </button>
            <button
              type="button"
              disabled={isSubmitting}
              className="btn btn-secondary bg-white text-dark"
              onClick={onClose}
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
