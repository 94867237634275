import { ErrorMessage, Field, useField, useFormikContext } from 'formik'
import React from 'react'

function Select({
  showOthers,
  name,
  label,
  options,
  defaultLabel,
  othersValue,
  withAsterisk,
  showErrorEvenWhenUntouched = false,
  // eslint-disable-next-line no-unused-vars
  ...rest
}) {
  const [field] = useField(name)
  const { setFieldValue, errors, values } = useFormikContext()
  return (
    <div className="form-group">
      {/* <label>{label}</label> */}
      <label>
        {label}
        {withAsterisk && <span className="text-danger"> *</span>}
      </label>
      <Field
        value={field?.value || ''}
        autoComplete="off"
        className="form-control"
        name={name}
        component="select"
        onChange={rest.onChange ?? field.onChange}
        onBlur={field.onBlur}
        disabled={rest.disabled}
        data-testid={rest['data-testid']}
      >
        {defaultLabel.length && (
          <option disabled value="">
            {defaultLabel}
          </option>
        )}
        {options.length &&
          options.map((option) => (
            <option
              key={option.key}
              disabled={option.disabled ?? false}
              value={option.key}
            >
              {option.value}
            </option>
          ))}
      </Field>

      {showOthers && field.value === 'OTHERS' && (
        <div className="mt-2">
          <input
            data-testid={`other-input-${name}`}
            type="text"
            className="form-control"
            onChange={({ currentTarget }) =>
              setFieldValue(othersValue, currentTarget.value)
            }
            value={values?.[othersValue ?? ''] ?? ''}
            placeholder="Enter here..."
            name={'othersOption'}
          />
        </div>
      )}
      <ErrorMessage className="text-danger" name={name} component="div" />
      {/* {
        showErrorEvenWhenUntouched ? <div className='text-danger'>{getObjectValueByStringKey(errors, name)}</div> : <ErrorMessage className="text-danger" name={name} component="div" />
      } */}
    </div>
  )
}

export default Select
