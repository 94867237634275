import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { handleSideNavElementClicked } from '../actions'
import {
  deleteComment,
  getCommentsByEventId,
  saveComment,
  updateCommentPined,
} from '../services/commentService'
import { useCommentAccess, useGetPermission } from '../hooks/useGetPermission'
import { timeNowFormat } from '../configs/utils'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

export default function CommentSection() {
  const wrapperRef = useRef(null);

  const dispatch = useDispatch()
  const mountedRef = useRef(false)
  const [comments, setComments] = useState([])
  const [commentInput, setCommentInput] = useState([])
  const [writeCommentSectionOpen, setWriteCommentSectionOpen] = useState(false)
  const [dropdownOpen, setdropdownOpen] = useState(false)

  const sideNavData = useSelector((state) => state.sideNavData)
  const isSidNavOpen = useSelector((state) => state.isSidNavOpen)
  const module = useSelector((state) => state.sideNavModule)
  const eventId = sideNavData._id
  const handleSideNavClose = () => {
    dispatch(handleSideNavElementClicked('arrow'))
  }
  const init = async () => {
    if (eventId) {
      const { data } = await getCommentsByEventId(eventId, module)
      setComments(data.data)
    }
  }

  const deletePermission = useGetPermission(module, 'DELETE')

  useEffect(() => {
    mountedRef.current = true
    init()
    return () => {
      mountedRef.current = false
    }
  }, [eventId])

  const handleAddComment = async () => {
    try {
      await saveComment({ comment: commentInput, eventId }, module)
      setCommentInput('')
      toast.success('Comment Added!')
      setWriteCommentSectionOpen(false)
      const commentData = await getCommentsByEventId(eventId, module)
      setComments(commentData.data.data)
    } catch (err) {
      toast.error(err)
    }
  }

  const handleOrderChange = async (val) => {
    let param = { order: val }
    const result = await getCommentsByEventId(eventId, module, param)
    setComments(result.data.data)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setWriteCommentSectionOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);


  return (
    <div className={'slide-sidebar mt-5 ' + (isSidNavOpen ? 'sidebar-shift' : '')}>
      <div className="mt-5"></div>
      <div className="mt-5"></div>
      <div className="sidebar-sec">
        <div
          className="card-header-2 d-flex mb-4 mt-3"
          style={{ marginTop: '190px' }}
        >
          <div className="card-title mb-0 pb-0">
            <h2 className="mb-0 pb-0 dark-text">Comments</h2>
          </div>
          <nav className="">
            <li className="nav-item p-0 close11">
              <span className="search " onClick={handleSideNavClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.829"
                  height="17.828"
                  viewBox="0 0 17.829 17.828"
                >
                  <g
                    id="Group_9756"
                    data-name="Group 9756"
                    transform="translate(-4013.659 13.151)"
                  >
                    <path
                      id="path1419"
                      d="M-1479.2-17.087l15-15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                    <path
                      id="path1421"
                      d="M-1479.2-32.087l15,15"
                      transform="translate(5494.273 20.35)"
                      fill="none"
                      stroke="#828282"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    ></path>
                  </g>
                </svg>
              </span>
            </li>
          </nav>
        </div>
        <div className="mt-4">
          <a href="#" className="card border-hover-none mb-2 border-0 pr-2">
            <div className="card-header-comment  pt-9">
              <p className="p-0 m-0  comment-txt">Recent Updates</p>
              <div>
                <select
                  name="order"
                  id="order"
                  className="form-control comment-asds inherit"
                  onClick={(e) => handleOrderChange(e.target.value)}
                >
                  <option value="desc">Descending</option>
                  <option value="asc">Ascending</option>
                </select>
              </div>
            </div>
          </a>
        </div>
        <div>
          <div className="pr-2 px-3 ">
            {useCommentAccess(module, 'CREATE') && (
              <div className="img-box mb-3 d-flex justify-content-center">
                {!writeCommentSectionOpen ? (
                  <div className="r-box1 plus1 posi-rel">
                    <div
                      className="form-control"
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        cursor: 'text',
                      }}
                      onClick={() => setWriteCommentSectionOpen(true)}
                    >
                      Add a comment...
                    </div>
                  </div>
                ) : (
                  <div className="row r-box1 posi-rel" ref={wrapperRef}>
                    <div
                      className="col-md-12 "
                      style={{
                        paddingRight: 'unset',
                        height: '250px',
                        paddingLeft: 'unset',
                      }}
                    >
                      <form>
                        <textarea
                          name=""
                          id=""
                          cols="20"
                          rows="10"
                          className="form-control bg-transparent commentpost"
                          placeholder="Add a comment..."
                          onChange={(e) => setCommentInput(e.target.value)}
                          value={commentInput}
                        ></textarea>
                      </form>

                      <div
                        className="pr-4 font-bold text-right donetxt"
                      >
                        <a className="font-weight-bold text-decoration-none" onClick={handleAddComment}
                          style={{ cursor: 'pointer' }}>
                          Done
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {useCommentAccess(module, 'READ') && (
            <div
              className="pr-2 pb-3 overflow-auto"
              id="scroller"
              style={
                writeCommentSectionOpen
                  ? { height: '250px' }
                  : { height: '620px' }
              }
            >
              <div className="px-3">
                {comments?.filter((c) => c.isPined).map((c, index) => (
                  <div
                    className="comment-section border-bottom"
                    key={`${c?.comment || ''} ${index}`}
                  >
                    <div className="img-box active mb-1 d-flex">
                      <div className="pro-box d-flex mr-3">
                        {c.userName
                          ? c.userName.split(' ')[0]?.split('')[0]
                          : null}
                        {c.userName
                          ? c.userName.split(' ')[1]?.split('')[0]
                          : null}
                      </div>
                      <div className="r-box">
                        <p>{c.comment}{c.isPined}</p>
                        <p
                          title={moment
                            .utc(c.created)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {timeNowFormat(c.created)}
                        </p>
                      </div>
                      <div className="comment-escl">
                        <Dropdown
                          isOpen={dropdownOpen}
                          className="custom-dropdown mr-4"
                        >
                          <DropdownToggle
                            caret
                            onClick={() => {
                              setdropdownOpen(!dropdownOpen)
                            }}
                            style={{ padding: '2px 5px', marginRight: '10px' }}
                          >
                            <i
                              className="fas fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem
                              onClick={() => {
                                // setNarrativeInput(c.narrative)
                                // setEditNarrativeIndex(index)
                                // setdropdownOpen(false)
                              }}
                            >
                              Edit
                            </DropdownItem> */}
                            {deletePermission && (
                              <DropdownItem
                                onClick={async () => {
                                  await deleteComment(c._id, c.module)
                                  toast.success('Comment Deleted!')
                                  setdropdownOpen(false)
                                  await init()
                                }}
                              >
                                Delete
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div>
                        <a onClick={async () => {
                          await updateCommentPined(c._id, c.module, { isPined: !c.isPined })

                          await init()
                        }} title={c.isPined ? "Unpin Comment" : "Pin Comment"}>
                          <span className="bookmark">
                            <i className="fas fa-thumbtack pined" aria-hidden="true"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
                {comments?.filter((c) => !c.isPined).map((c, index) => (
                  <div
                    className="comment-section border-bottom"
                    key={`${c?.comment || ''} ${index}`}
                  >
                    <div className="img-box active mb-1 d-flex">
                      <div className="pro-box d-flex mr-3">
                        {c.userName
                          ? c.userName.split(' ')[0]?.split('')[0]
                          : null}
                        {c.userName
                          ? c.userName.split(' ')[1]?.split('')[0]
                          : null}
                      </div>
                      <div className="r-box">
                        <p>{c.comment}</p>
                        <p
                          title={moment
                            .utc(c.created)
                            .local()
                            .format('YYYY-MM-DD HH:mm:ss')}
                        >
                          {timeNowFormat(c.created)}
                        </p>
                      </div>
                      <div className="comment-escl">
                        <Dropdown
                          isOpen={dropdownOpen}
                          className="custom-dropdown mr-4"
                        >
                          <DropdownToggle
                            caret
                            onClick={() => {
                              setdropdownOpen(!dropdownOpen)
                            }}
                            style={{ padding: '2px 5px', marginRight: '10px' }}
                          >
                            <i
                              className="fas fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem
                              onClick={() => {
                                // setNarrativeInput(c.narrative)
                                // setEditNarrativeIndex(index)
                                // setdropdownOpen(false)
                              }}
                            >
                              Edit
                            </DropdownItem> */}
                            {deletePermission && (
                              <DropdownItem
                                onClick={async () => {
                                  await deleteComment(c._id, c.module)
                                  toast.success('Comment Deleted!')
                                  setdropdownOpen(false)
                                  await init()
                                }}
                              >
                                Delete
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      <div>
                        <a onClick={async () => {
                          await updateCommentPined(c._id, c.module, { isPined: !c.isPined, comment: c.comment })
                          await init()
                        }} title={c.isPined ? "Unpin Comment" : "Pin Comment"}>
                          <span className="bookmark">
                            <i className="fas fa-thumbtack unpined" aria-hidden="true"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
