import classNames from 'classnames'
import { Formik } from 'formik'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from 'reactstrap'

import { useMemo } from 'react'
import { useGetPermission } from '../hooks/useGetPermission'
import { generateToken } from '../utils/jwt'
import Select from './form/Select'

export function GenerateLinkModal({ isOpen, onClose }) {
  const clients = useSelector((state) => state?.clients || [])
  const user = useSelector((state) => state?.currentUser || {})
  const mvrAccess = useGetPermission('mvr', 'CREATE')
  const [tokenInfo, setTokenInfo] = useState({})
  const [link, setLink] = useState('')

  const isSuperAdmin = useMemo(() => {
    const { clientId = '', userId = '', accessLevels = [] } = user

    const accessToken = sessionStorage.getItem('token')
    setTokenInfo({
      clientId,
      userId,
      accessToken,
    })
    return accessLevels.includes('FULL_ACCESS')
  }, [user])

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  const generateLink = useCallback(
    (clientId, companyName) => {
      const token = generateToken({ ...tokenInfo, clientId, companyName })
      return `${window.location.origin}/add-mvr/${companyName}?key=${token}`
    },
    [tokenInfo],
  )

  return (
    <Modal isOpen={isOpen}>
      <div className={'modal-header justify-content-center'}>
        <h5 className="modal-title text-primary" data-testid="generate-link-headline">Generate Link</h5>
      </div>
      <ModalBody className="excelfile p-0">
        <div className="px-4 my-3">
          <Formik enableReinitialize initialValues={{ clientId: '' }}>
            {({ values }) => (
              <form className="indent-form genform">
                {mvrAccess ? (
                  <div className="row" style={{ alignItems: 'center' }}>
                    <div className="col-md-9">
                      <Select
                        data-testid="generate-link-client-select"
                        label="Select Client"
                        name="clientId"
                        defaultLabel="Select Client"
                        options={getClientDropdownOpt()}
                      />
                    </div>

                    <div className="col-md-3">
                      <button
                        data-testid="generate-link-btn"
                        className="btn btn-primary border-radius-12 genclick"
                        type="button"
                        onClick={() => {
                          const clientId = values?.clientId || ''
                          const client = clients.filter(
                            ({ _id: cId }) => cId === clientId,
                          )[0]
                          if (client) {
                            const { _id, companyName } = client
                            const link = generateLink(_id, companyName)
                            setLink(link)
                          } else {
                            toast.error(
                              'Invalid Client, Please select a valid client',
                            )
                          }
                        }}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="input-group py-2">
                    <input
                      type="text"
                      className="form-control text-dark"
                      defaultValue="Acuity"
                      readOnly
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary border-radius-12 genclick"
                        type="button"
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                )}
              </form>
            )}
          </Formik>

          <div
            className={classNames(
              'd-flex',
              'justify-content-between',
              'align-items-center',
              {
                showgen: link.length === 0,
              },
            )}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '80%',
              }}
              data-testid="generated-link"
            >
              {link}
            </div>
            <a
              data-testid="copy-generated-link-btn"
              style={{ cursor: 'pointer' }}
              className="text-dark"
              onClick={() => {
                navigator.clipboard.writeText(link)
                toast.success('COPIED!!')
              }}
            >
              <i className="fa fa-clone"></i>
            </a>
          </div>
        </div>
        <div className={'modal-footer'} style={{ background: '#F4F7FE' }}>
          <button
            data-testid="generate-link-cancel-btn"
            type="button"
            className="btn btn-secondary bg-white text-dark cls-btn"
            aria-label="Close"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

// import React from 'react'

// export const GenerateLinkModal = () => {
//   return (
//     <div>GenerateLinkModal</div>
//   )
// }

// export default GenerateLinkModal
