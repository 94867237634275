/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import classNames from 'classnames'
import Select1 from 'react-select'
import * as yup from 'yup'
import { useSelector } from 'react-redux'

import TextArea from '../../../components/form/TextArea'
import TextInput from '../../../components/form/TextInput'
import Select from '../../../components/form/Select'
import DatePickerField from '../../../components/form/DatePickerField'
import AddTransactionModalBtn from '../../../components/AddTransactionModalBtn'
import {
  getProperty,
  saveProperty,
  updateProperty,
} from '../../../services/propertyService'
import AddressInput from '../../../components/form/AddressInput'
import { getPoliciesByType } from '../../../services/policyService'
import AreYouSureModal from '../../../components/common/AreYouSureModal'
import AddAttachment from '../../../components/addAttachment'
import AttachmentAddView from '../../../components/AttachmentAddView'
import { generateUID } from '../../../utils/generateUID'
import { addComma, addCommaTableField } from '../../incident/utils'
import moment from 'moment'
import FormikSelect from '../../claims/DirectClaim/FormikSelect'
import { NO_TIMEZONE_DATE_FORMAT } from '../../../utils/helper'

const ConstructionTypes = [
  { value: 'Wood Frame', key: 'Wood_Frame' },
  { value: 'Brick', key: 'Brick' },
  { value: 'Joisted Masonry', key: 'Joisted_Masonry' },
  { value: 'Tilt Wall', key: 'Tilt_Wall' },
]

const DisasterTypes = [
  { value: 'Flood', key: 'Flood' },
  { value: 'Windstorm', key: 'Windstorm' },
  { value: 'Earthquake', key: 'Earthquake' },
]

const OccupancyTypes = [
  { value: 'Warehouse', key: 'WAREHOUSE' },
  { value: 'Office', key: 'OFFICE' },
  { value: 'Retail', key: 'RETAIL' },
  { value: 'Vacant', key: 'VACANT' },
]

const SecurityTypes = [
  { value: 'Guards', key: 'GUARDS' },
  { value: 'CCTV', key: 'CCTV' },
]

const ProtectionTypes = [
  { value: 'Sprinklered', key: 'SPRINKLERED' },
  { value: 'Hardwired', key: 'HARDWIRED SMOKE' },
]

const newProperty = {
  name: '',
  propertyId: generateUID('property'),
  address: {
    state: '',
    city: '',
    country: '',
    line1: '',
    line2: '',
    pinCode: '',
  },
  activeStatus: '',
  lastActiveOn: null,
  propertyType: '',
  dateOfPurchaseOrLeased: null,
  soldOn: null,
  policyDetails: null,
  constructionType: '',
  constructionYear: '',
  propertyMeasurement: {
    area: 0,
    height: 0,
    numberOfUnits: '',
  },
  security: '',
  occupancyType: '',
  mordernised: '',
  protection: '',
  propertyValueDetails: [],
  disasterProtectedFrom: '',
  content: '',
  edp: '',
  improvements: '',
  totalLimit: '',
  businessInterruptionAndExpense: '',
  attachments: [],
  notes: '',
}

function PropertyForm({ data, title, onClose }) {
  const ref = useRef()
  const [isNew, setIsNew] = useState(false)
  const [propPolicies, setPropPolicies] = useState([])
  const [filterPolicies, setFilterPolicies] = useState([])
  const [clientId, setClientId] = useState('')
  const [values, setValues] = useState(data ? data : newProperty)
  const [openModal, setOpenModal] = useState(false)
  const closeModal = () => setOpenModal(false)
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false)
  const [isDisplayNotes, setDisplayNotes] = useState(false)

  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])

  const validationSchema = yup.object({
    clientId: yup.string().when('$exist', {
      is: (exist) => !isClientExist,
      then: yup.string().required('Client is required!'),
      otherwise: yup.string(),
    }),
    totalLimit: yup
      .number()
      .typeError('Total Limit should be number')
      .positive('Total Limit should be +ve number'),
    occupancyType: yup.string('').required('Occupancy Type is required!'),
    mordernised: yup.string('').required('Mordernised is required!'),
    security: yup.string('').required('Security is required!'),
    protection: yup.string('').required('Protection is required!'),
    propertyMeasurement: yup.object().shape({
      numberOfUnits: yup
        .number()
        .typeError('Units must be a number')
        .positive('Units must be positive')
        .integer('Units must be an integer')
        .nullable(),
    }),
  })

  const handleSubmit = useCallback(
    async (values) => {
      if (!values?.activeStatus?.length) {
        toast.error('Active Status is required!')
        return
      } else if (!values?.propertyType?.length) {
        toast.error('Property Type is required!')
        return
      } else if (!isClientExist && !values?.clientId?.length) {
        toast.error('Client is required!')
        return
      }

      setValues({
        ...(values || {}),
        dateOfPurchaseOrLeased: values?.dateOfPurchaseOrLeased
          ? moment(values.dateOfPurchaseOrLeased).format(
              NO_TIMEZONE_DATE_FORMAT,
            )
          : null,
        soldOn: values?.soldOn
          ? moment(values.soldOn).format(NO_TIMEZONE_DATE_FORMAT)
          : null,
        lastActiveOn: values?.lastActiveOn
          ? moment(values.lastActiveOn).format(NO_TIMEZONE_DATE_FORMAT)
          : null,
        propertyValueDetails: values?.propertyValueDetails.map((p) => ({
          ...p,
          transactionDate: p.transactionDate
            ? moment(p.transactionDate).format(NO_TIMEZONE_DATE_FORMAT)
            : null,
        })),
      })
      setDisplayNotes(true)
    },
    [isClientExist],
  )

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      if (isNew) {
        await saveProperty(values)
        onClose()
        toast.success('Property Added!')
      } else {
        await updateProperty(values._id, values)
        onClose()
        toast.success('Property Updated!')
      }
    } catch (err) {
      toast.error(err)
    }
  }

  useEffect(() => {
    if (data) {
      setIsNew(false)
      setValues(data)
    } else {
      let property = { ...newProperty }
      if (!isClientExist) {
        property['clientId'] = ''
      }
      setIsNew(true)
      setValues(property)
    }
    fetchPolicies()
  }, [isNew, isClientExist])

  useEffect(() => {
    let policyListByClient = propPolicies.filter(
      (a) =>
        a.clientId === values.clientId ||
        a.clientId === selectedClientId ||
        a.clientId === clientId,
    )
    setFilterPolicies([...policyListByClient])
  }, [values?.clientId, clientId, selectedClientId])

  const fetchPolicies = async () => {
    const { data: policies } = await getPoliciesByType('PROPERTY')
    setPropPolicies(
      policies.data.map((p) => ({
        policyId: p._id,
        policyName: p.policyId,
        clientId: p.clientId,
      })),
    )
  }

  const fetchProperty = async (id) => {
    const { data: property } = await getProperty(id)
    setValues(property.data)
  }

  const onModalClose = (val, key, setFieldValue, values) => {
    setFieldValue(key, [...values[key], { ...val }])
  }

  const onArrayUpdate = (key, val) => {
    let updatedValues = ref.current?.values || {}
    if (updatedValues[key]) {
      if (key === 'attachments') {
        updatedValues[key].push(val[0])
      } else {
        updatedValues[key].push(val)
      }
      setValues({ ...updatedValues })
    }
  }

  const handleAddAttachmentToggle = () => {
    setOpenAttachmentModal(!openAttachmentModal)
  }

  const onAttachmentUpload = (file) => {
    handleAddAttachmentToggle()
    onArrayUpdate('attachments', file)
  }

  let handleDeleteCard = (key, index) => {
    values[key].splice(index, 1)
    let val = { ...values }
    setValues(val)
  }

  let getPremiumViews = () => {
    let property = []

    values?.propertyValueDetails?.forEach(function (
      propertyValueDetails,
      index,
    ) {
      property.push(
        <div className="parties price bg-white p-3 mb-3 col-md-4 mt-2">
          <div
            onClick={handleDeleteCard.bind(this, 'propertyValueDetails', index)}
            className="x-btn"
          >
            ×
          </div>
          <h6 className="text-primery">
            {propertyValueDetails.transactionDate
              ? moment(propertyValueDetails.transactionDate).format(
                  'MM/DD/YYYY',
                )
              : 'N/A'}
          </h6>
          <small className="mb-2">
            $
            {propertyValueDetails.amount
              ? addCommaTableField(propertyValueDetails.amount)
              : ''}
          </small>
        </div>,
      )
    })

    return property
  }

  let getAttachmentViews = () => {
    let attachmentViews = []
    values?.attachments.forEach(function (xmlns, index) {
      let fileNameArray = Array.isArray(xmlns)
        ? xmlns[0]?.split('/')
        : xmlns.split('/')
      let filename = fileNameArray[fileNameArray.length - 1]

      let isFileImage = filename.match(/\.(jpg|jpeg|png|gif)$/)

      attachmentViews.push(
        <div className="col-md-6" key={index}>
          <div className="attachment">
            <div className="attach-img d-flex justify-content-center align-items-center ">
              <a
                target="_blank"
                href={xmlns}
                rel="noreferer noreferrer"
                role="button"
                className="btn btn-primary btn-down"
              >
                Download
              </a>
              <div className="text-center">
                {isFileImage ? (
                  <object
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    data={xmlns}
                  />
                ) : (
                  <>
                    <svg
                      id="picture"
                      xmlns={xmlns}
                      width="34.705"
                      height="34.705"
                      viewBox="0 0 34.705 34.705"
                    >
                      <path
                        id="Path_17011"
                        data-name="Path 17011"
                        d="M27.475,0H7.23A7.239,7.239,0,0,0,0,7.23V27.475A7.239,7.239,0,0,0,7.23,34.7H27.475a7.239,7.239,0,0,0,7.23-7.23V7.23A7.239,7.239,0,0,0,27.475,0ZM7.23,2.892H27.475A4.338,4.338,0,0,1,31.813,7.23V27.475a4.267,4.267,0,0,1-.434,1.858l-13.25-13.25a7.23,7.23,0,0,0-10.226,0l-5.01,5.009V7.23A4.338,4.338,0,0,1,7.23,2.892Zm0,28.921a4.338,4.338,0,0,1-4.338-4.338V25.181l7.054-7.054a4.338,4.338,0,0,1,6.137,0l13.25,13.251a4.267,4.267,0,0,1-1.858.434Z"
                        fill="#868686"
                      ></path>
                      <path
                        id="Path_17012"
                        data-name="Path 17012"
                        d="M17.561,13.622A5.061,5.061,0,1,0,12.5,8.561,5.061,5.061,0,0,0,17.561,13.622Zm0-7.23a2.169,2.169,0,1,1-2.169,2.169,2.169,2.169,0,0,1,2.169-2.169Z"
                        transform="translate(5.575 1.561)"
                        fill="#868686"
                      ></path>
                    </svg>
                    <div className="">Preview Not Available</div>
                  </>
                )}
              </div>
            </div>
            <div className="px-2">
              <p>
                <b>Filename: </b> {filename}
              </p>
            </div>
          </div>
        </div>,
      )
    })
    return attachmentViews
  }

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <Formik
      validationSchema={validationSchema}
      innerRef={ref}
      enableReinitialize
      initialValues={{
        ...values,
        security: Array.isArray(values?.security)
          ? values?.security[0]
          : values?.security || '',
        protection: Array.isArray(values?.protection)
          ? values?.protection[0]
          : values?.protection || '',
        activeStatus: String(values?.activeStatus),
      }}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        values: mainValues,
        setFieldValue,
        errors,
        handleBlur,
      }) => (
        <Form className="indent-form">
          <div className="row m-0">
            <div
              className="col-7 p-4"
              style={{ height: '100vh', overflow: 'auto' }}
            >
              <h2
                className="modal-title text-primary mb-4"
                id="exampleModalLabel"
              >
                {title}
              </h2>
              {isDisplayNotes ? (
                <>
                  <div className={'border-bottom h6 py-3 mb-4 text-primary'}>
                    <b>Please add notes about the property(Optional).</b>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextArea label="Notes" name="notes" />
                    </div>
                  </div>
                  <div
                    className="narrative-modal-scroll"
                    style={{ height: '320px', marginBottom: '10px' }}
                  ></div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: '100%' }}
                  >
                    <button
                      className="btn text-primary pl-0"
                      onClick={() => setDisplayNotes(false)}
                    >
                      <i
                        className={'fas fa-chevron-left'}
                        aria-hidden={true}
                        style={{ paddingRight: '4px' }}
                      ></i>
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary pull-right"
                      onClick={() => setOpenModal(true)}
                    >
                      Save &amp; Close
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="border-bottom h6 py-2 mb-3">
                    Property Details
                  </div>
                  <div className="px-4" style={{ marginLeft: '-24px' }}>
                    <div className="row m-0">
                      {mainValues && (
                        <div className="col-12 p-0">
                          <div
                            className="tab-content pb-0"
                            id="v-pills-tabContent"
                          >
                            <div
                              className={classNames('tab-pane fade', {
                                'show active': true,
                              })}
                              id="v-pills-1"
                              role="tabpanel"
                              aria-labelledby="v-1"
                            >
                              <div className="row m-0 pt-3">
                                <div className="col-12 p-0">
                                  <div className="form-row">
                                    {isClientExist ? null : (
                                      <div className="col-md-12 pl-0 pr-0">
                                        <FormikSelect
                                          label="Select Client"
                                          name="clientId"
                                          defaultLabel="Select Client"
                                          options={getClientDropdownOpt()}
                                          value={mainValues?.clientId || ''}
                                          setFieldValue={setFieldValue}
                                          errors={errors}
                                          handleBlur={handleBlur}
                                          setClientId={setClientId}
                                        />
                                      </div>
                                    )}
                                    <div className="col-md-6 pl-0">
                                      <TextInput
                                        label="Property Name"
                                        name="name"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group input-dollar">
                                        <label>Number of Units</label>
                                        <input
                                          className="form-control"
                                          label="Number of Units"
                                          name="propertyMeasurement.numberOfUnits"
                                          type="text"
                                          value={
                                            mainValues?.propertyMeasurement
                                              .numberOfUnits
                                              ? addComma(
                                                  mainValues
                                                    ?.propertyMeasurement
                                                    .numberOfUnits,
                                                )
                                              : ''
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value
                                            const onlyNums = value.replaceAll(
                                              ',',
                                              '',
                                            )
                                            setFieldValue(
                                              'propertyMeasurement.numberOfUnits',
                                              onlyNums,
                                            )
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <DatePickerField
                                        name="dateOfPurchaseOrLeased"
                                        label="Date of Purchase/Leased"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <DatePickerField
                                        name="soldOn"
                                        label="Date Sold (if applicable)"
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        label="Construction Type"
                                        name="constructionType"
                                        defaultLabel="Select Type"
                                        options={ConstructionTypes}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <TextInput
                                        label="Construction Year"
                                        name="constructionYear"
                                        type="number"
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <Select
                                        label="Zones"
                                        name="disasterProtectedFrom"
                                        defaultLabel="Select Type"
                                        options={DisasterTypes}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        label="Occupancy Type"
                                        name="occupancyType"
                                        defaultLabel="Select Type"
                                        options={OccupancyTypes}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <Select
                                        label="Security"
                                        name="security"
                                        defaultLabel="Select Type"
                                        options={SecurityTypes}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Select
                                        label="Modernized"
                                        name="mordernised"
                                        defaultLabel="Select Type"
                                        options={[
                                          { value: 'Yes', key: true },
                                          { value: 'No', key: false },
                                        ]}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary text-white pull-right"
                                  >
                                    Continue
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {mainValues && (
              <div className="col-md-5 pt-3 modal-right-scroll">
                <div className="col-12 p-0">
                  <div className={'row'}>
                    <div className="col-md-6">
                      <label className="incident-text">
                        Property ID
                        <span className="text-primery">
                          {mainValues ? mainValues.propertyId : '-'}
                          <b className="text-dark font-weight-normal ml-2">
                            (System Generated)
                          </b>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        label="Protection"
                        name="protection"
                        defaultLabel="Select Type"
                        options={ProtectionTypes}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInput
                        label="Area"
                        name="propertyMeasurement.area"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextInput
                        label="Height"
                        name="propertyMeasurement.height"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <label> Active?</label>
                      <div className="form-group d-block">
                        <div className="form-check form-check-inline mr-5">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="activeStatus"
                            value="true"
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="activeStatus"
                            value="false"
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                    {mainValues.activeStatus == 'false' && (
                      <div className="col-md-12">
                        <DatePickerField
                          name="lastActiveOn"
                          label="Specify date since inactive"
                        />
                      </div>
                    )}
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Policy Details</label>
                        {filterPolicies.length < 1 && (
                          <span color="red">No Policy Values Found</span>
                        )}
                        <Select1
                          placeholder="Select Insurance"
                          getOptionLabel={(option) => option.policyName}
                          getOptionValue={(option) => option}
                          name="policyDetails"
                          value={mainValues.policyDetails}
                          isSearchable="true"
                          onChange={(val) =>
                            setFieldValue('policyDetails', val)
                          }
                          options={filterPolicies}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group" style={{ marginBottom: 'unset' }}>
                    <AddressInput
                      label="Address"
                      name="address"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group">
                    <label> Property Owned or Leased?</label>
                    <div className="form-group d-block">
                      <div className="form-check form-check-inline mr-5">
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="propertyType"
                          value="OWNED"
                        />
                        <label className="form-check-label">Owned</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="propertyType"
                          value="LEASED"
                        />
                        <label className="form-check-label">Leased</label>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextArea label="Contents" name="content" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextInput label="Equipment Data Processing" name="edp" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextInput
                        label="Business Interruption"
                        name="businessInterruptionAndExpense"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInput label="Improvements" name="improvements" />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group input-dollar">
                        <label>Total Limit</label>
                        <span className="pr-4">$</span>
                        <input
                          className="form-control dollar-text"
                          label="Total Limit"
                          name="totalLimit"
                          type="text"
                          value={
                            mainValues?.totalLimit
                              ? addComma(mainValues?.totalLimit)
                              : ''
                          }
                          onChange={(e) => {
                            const value = e.target.value
                            const onlyNums = value.replaceAll(',', '')
                            if(isNaN(onlyNums)) return
                            setFieldValue('totalLimit', onlyNums)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 border-top mx-2 py-2"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Property Valuation
                    </label>
                    {getPremiumViews()}
                    <AddTransactionModalBtn
                      modalClose={(val) => {
                        onArrayUpdate('propertyValueDetails', val)
                      }}
                      heading={'Add Property Value'}
                      buttonLabel="Add..."
                      btnClasses="btn btn-outline-light text-dark cus-btn"
                      dateLabel="As of Date"
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <AttachmentAddView
                      module={'property'}
                      uid={mainValues?.propertyId}
                      formik={{
                        setFieldValue,
                        values: mainValues,
                      }}
                    />
                    {/* <label className="d-block incident-text" htmlFor="package">
                      Attachments
                    </label>
                    <div className="row m-0">
                      {values.attachments &&
                        values.attachments.length > 0 &&
                        getAttachmentViews()}
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                      data-toggle="modal"
                      data-target="#attachuplaods"
                      onClick={handleAddAttachmentToggle}
                    >
                      Add..
                    </button>
                    {openAttachmentModal && (
                      <AddAttachment
                        module="property"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            )}
            <AreYouSureModal
              openModal={openModal}
              closeModal={closeModal}
              handleSubmit={handleSubmitModal}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PropertyForm
