import React, { useState, useEffect, useRef } from 'react'
import GaugeChart from 'react-gauge-chart'
import {
  getClaimsByTd,
  getClaimsByTime,
  getCoverageType,
} from '../../../../services/claimService'

function ClaimsReported(props) {
  const [duration, setDuration] = useState('YTD')
  const [total, setTotal] = useState(0)
  const mountedRef = useRef(true)

  const getClaimsReport = async (_) => {
    try {
      const { data } = await getClaimsByTd(duration)
      if (!mountedRef.current) return null
      setTotal(data.data.length)
    } catch (error) {}
  }

  const handleDurationChange = (event) => {
    setDuration(event.target.name)
    getClaimsReport(event.target.name)
  }

  useEffect(() => {
    mountedRef.current = true
    getClaimsReport()
    return () => { 
      mountedRef.current = false
    }
  }, [])

  return (
    <div className="row here">
      <div className="col-md-12">
        <div className="card card-custom bg-gray-100 card-stretch gutter-b">
          <div className="card-header align-items-center border-0 mt-2">
            <h6 className="card-title align-items-start flex-column">
              <span className="font-weight-bolder text-dark cus-header">
                Claims Reported
              </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                <div className="btn-group pull-right">
                  <button
                    type="button"
                    name="MTD"
                    className={`btn btn-primary-outline btn-sm ${
                      duration === 'MTD' ? 'text-primary' : ''
                    }`}
                    onClick={handleDurationChange}
                  >
                    MTD
                  </button>
                  <button
                    type="button"
                    className={`btn btn-primary-outline btn-sm ${
                      duration === 'YTD' ? 'text-primary' : ''
                    }`}
                    name="YTD"
                    onClick={handleDurationChange}
                  >
                    YTD
                  </button>
                </div>
              </span>
            </h6>
          </div>

          <div className="card-body">
            <div className="card-rounded-bottom claim_reported mt-3" style={{width: "400px", height: "190px"}}>
              <div className="text-center">
                {total ? (
                  <GaugeChart
                    id="gauge-chart"
                    percent={total / 100}
                    nrOfLevels={30}
                    formatTextValue={(v) => v}
                    textColor="#000000"
                    colors={['#FFFF00', '#228B22', '#FF0000']}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimsReported
