import classNames from 'classnames'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Modal } from 'reactstrap'
import * as Yup from 'yup'
import { handleLogin, handleLogout, saveUserData } from '../../actions'
import authService from '../../services/authService'
import { acceptTerms, getCurrentUser } from '../../services/userService'

function Login() {
  const token = sessionStorage.getItem('token');
  const history = useHistory()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    openTermsModal: false,
    termCheckbox: false,
  })
  const currentUser = useSelector((state) => state?.currentUser || {})
  useEffect(()=>{
    if(token) {
      history.push('/dashboard');
    }
    
  },[])
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Error: Invalid Email.')
        .required('Email is Required.'),
      password: Yup.string().required('Password is Required.'),
    }),
    onSubmit: async (val) => {
      try {
        const data = await authService.login(val)
        const currentUser = await getCurrentUser(data.data.data.token)
        
        // set token to localstorage
        authService.setLocalStorage(data.data.data.token)

        const user = currentUser.data.data.user
    
        if (user) {
          dispatch(handleLogin(data.data.data.token))
          dispatch(saveUserData(currentUser.data.data))
          if (user?.termsAccepted) {
            history.push('/dashboard')
          } else setState({ ...state, openTermsModal: true })
        }
      } catch (err) {
        toast.error(err.message)
      }
    },
  })

  const [showPassword, setShowPassword] = useState(false)

  const handleClose = () => {
    setState((s) => ({ ...s, openTermsModal: false, termCheckbox: false }))
    dispatch(handleLogout())
  }

  const handleTermsClick = async(event) => {
    setState((s) => ({ ...s, termCheckbox: event.target.checked }))
  }

  const handleAgree = async() => {
    if (state.termCheckbox) {
      await acceptTerms(currentUser);
      setState((s) => ({ ...s, openTermsModal: false, termCheckbox: true }))
      history.push('/dashboard')
    } else {
      toast.error('Please accept terms and conditions.')
    }
  }

  return (
    <>
      <div className="m-content">
        <div className="container">
          <div className="col-lg-12">
            <div className="text-center">
              <div className="mt-3 mb-4">
                <img src="/assets/images/logo.png" alt="acuity" />
              </div>
              <h1 className="wel_back">Welcome Back!</h1>
              <h6 className="login-acu mt-3 mb-5">
                Enter your credentials to view your space
              </h6>
            </div>
            <div className="m-portlet">
              <form className="m-form m-form--fit p-4">
                <div className="">
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>Email Address</strong>
                      </label>
                      <input
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.email &&
                            formik?.errors?.email?.length,
                        })}
                        name="email"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.email && formik?.errors?.email) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <label className="form-control-label">
                        <strong>Password</strong>
                      </label>
                      <div
                        className={classNames('form-control border-radius-0', {
                          'login-error':
                            formik?.touched?.password &&
                            formik?.errors?.password?.length,
                        })}
                      >
                        <input
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          style={{
                            width: '97%',
                            border: 'none',
                            outline: 'none',
                            background: 'none',
                          }}
                        />
                        <i
                          className={`fas ${
                            showPassword ? 'fa-eye-slash' : 'fa-eye'
                          }`}
                          style={{ marginRight: -30, cursor: 'pointer' }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      <span id="message" style={{ color: 'red' }}>
                        {(formik?.touched?.password &&
                          formik?.errors?.password) ||
                          ''}
                      </span>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <div className="mt-4">
                        <Link to="/forgot-password" className="">
                          <span>
                            Forgot your password? <br />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-12">
                      <button
                        type="button"
                        onClick={() => formik.handleSubmit()}
                        className="btn btn-primary login-btn"
                        style={{ width: '100%' }}
                        disabled={
                          (!formik?.touched?.email &&
                            !formik?.touched?.password) ||
                          (formik?.touched?.email &&
                            formik?.errors?.email?.length) ||
                          (formik?.touched?.password &&
                            formik?.errors?.password?.length)
                        }
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*   <footer className="m-footer">
          <div className="text-center">
            <span>Copyright © {moment().year()} All Rights Reserved.</span>
          </div>
        </footer> */}
        </div>
      </div>
      <Modal
        isOpen={state.openTermsModal}
        toggle={handleClose}
        className={'modal-dialog term-condition'}
      >
        <div className={'modal-content border-radius-18 border-0 trm'}>
          <div className={'modal-header border-0 px-4'}>
            <h4 className={'modal-title terms-serv'}>Terms of services</h4>
          </div>
          <div className={'terms-con'}>
            <div className={'modal-body py-0 px-4'}>
              <p className="mvr-subtxt font-weight-500">
                By clicking "Agree" below, you confirm that Acuity Risk
                Consultants is and remains authorized and specifically directed
                to share information with, and receive information from, the
                third-party integrations you enable, which may include,
                depending on the third-party integration partner, your personal
                information, personal information about your Acuity Risk
                Consultants account users and contacts, and other information
                about your Acuity Risk Consultants account.
              </p>
              <p className="pt-4">
                You understand that Acuity Risk Consultants does not control or
                oversee third-party integration partners. As a result, and as
                noted in Acuity Risk Consultant's Terms of Service, you are
                responsible for ensuring that the third-party integrations you
                enable are appropriate for your intended use, for understanding
                what information will be exchanged with third-party integration
                partners you have enabled, and for ensuring that your use of
                third-party integrations, including Acuity Risk Consultants
                sharing and receiving information on your behalf, complies with
                all applicable law. This confirmation is required for your
                enabled integrations to work effectively, and applies to any
                integrations you later enable.
              </p>
              <p>
                If you previously enabled a third-party integration and no
                longer want Acuity Risk Consultants to share or receive
                information on your behalf with that integration partner, you
                may select “I do not agree” below, and additional instructions
                for disabling third-party integrations will be displayed.
              </p>
              <p>
                Please note: As you originally enabled your third-party
                integrations, you are responsible for disabling any integrations
                you no longer wish to use.
              </p>
              <p>
                Please review our <a href="#">privacy policy</a> for more
                information on how we process your personal information.
              </p>
              <p className="term-check my-4">
                <input
                  type="checkbox"
                  value=""
                  className="check-box mt-3 mr-2"
                  id="checkboxTerms"
                  onClick={handleTermsClick}
                />{' '}
                <span className="agree">
                  I have read and understand the information presented above,
                  confirm that Acuity Risk Consultants is specifically directed
                  to share and receive information in connection with my current
                  third-party integrations and any future third-party
                  integrations I enable, and affirm that I have the necessary
                  power and authority to make this election on behalf of this
                  Acuity Risk Consultants account.
                </span>
              </p>
            </div>
          </div>
          <div className={'modal-footer term-footer p-4'}>
            <button
              type="button"
              className="btn btn-secondary bg-white text-dark"
              data-dismiss="modal"
              onClick={handleClose}
            >
              I do not agree
            </button>
            <button
              type="button"
              className="btn btn-primary add-btn"
              onClick={() => handleAgree()}
            >
              Agree
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withRouter(Login)
