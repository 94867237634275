import React, { useState, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import ImportCSV from '../../components/common/importCSV'
import ListPageHeader from '../../components/ListPageHeader'
import { exportProperties, getProperty } from '../../services/propertyService'
import {
  downloadFile,
  getPagedData,
  getPromiseArrayForImport,
  parseFile,
} from '../../services/helperService'
import PropertyModal from './property/PropertyModal'
import EmployeeModal from './employee/EmployeeModal'
import VehicleModal from './vehicle/VehicleModal'
import EmployeeList from './employee/EmployeeList'
import PropertyList from './property/PropertyList'
import VehicleList from './vehicle/VehicleList'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
  saveClaimInfo,
  saveIncidentInfo,
  savePolicyInfo,
} from '../../actions'
import { exportVehicles, getVehicle } from '../../services/vehicleService'
import { exportEmployees, getEmployee } from '../../services/employeeService'
import FilterDropdown from '../../components/common/FilterDropdown'
import { getClaims } from '../../services/claimService'
import { getIncidents } from '../../services/incidentService'
import { getPolicies } from '../../services/policyService'
import { useShowModule } from '../../hooks/useShowModule'

const initialEmployeeFilterState = {
  isDriver: {
    key: 'isDriver',
    value: 'Is Driver',
    count: 0,
    isAvailable: false,
  },
  active: { key: 'active', value: 'Active', count: 0, isAvailable: false },
  terminated: {
    key: 'terminated',
    value: 'Terminated',
    count: 0,
    isAvailable: false,
  },
}

const initialVehicleFilterState = {
  new: {
    key: 'new',
    value: 'New',
    count: 0,
    isAvailable: false,
  },
  used: {
    key: 'used',
    value: 'Used',
    count: 0,
    isAvailable: false,
  },
}

const initialPropertyFilterState = {
  owned: {
    key: 'owned',
    value: 'Property Owned',
    count: 0,
    isAvailable: false,
  },
  leased: {
    key: 'leased',
    value: 'Property Leased',
    count: 0,
    isAvailable: false,
  },
  active: { key: 'active', value: 'Active', count: 0, isAvailable: false },
  inactive: {
    key: 'inactive',
    value: 'Inactive',
    count: 0,
    isAvailable: false,
  },
}
const initialEmployeeDateFilterState = {
  hireDate: {
    label: ' Select date range of Joining',
    start: null,
    end: null,
    key: 'hireDate',
  },
}

const initialVehicleDateFilterState = {
  dateOfPurchase: {
    label: ' Select date range of Purchase',
    start: null,
    end: null,
    key: 'dateOfPurchase',
  },
  dateOfSold: {
    label: ' Select date range of Sold',
    start: null,
    end: null,
    key: 'dateOfSold',
  },
}

const initialPropertyDateFilterState = {
  dateOfPurchaseOrLeased: {
    label: ' Select daterRange of Purchase/Leased',
    start: null,
    end: null,
    key: 'dateOfPurchaseOrLeased',
  },
}
function AssetManagement(props) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [editId, setEditId] = useState('')
  const [tab, setTab] = useState('employee')
  const [context, setContext] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [checkedRecords, setCheckedRecords] = useState([])
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [openForm, setOpenForm] = useState({ isOpen: false, data: null })
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false)
  const selectedTabId = useSelector((state) => state.selectedTabId) 

  const { calendarInfo } = useSelector((state) => state)

  const [dateRangeOfSold, setDateRangeOfSold] = useState({
    start: null,
    end: null,
  })

  const [employeeFilter, setEmployeeFilter] = useState(
    initialEmployeeFilterState,
  )
  const [propertyFilter, setPropertyFilter] = useState(
    initialPropertyFilterState,
  )
  const [vehicleFilter, setVehicleFilter] = useState(initialVehicleFilterState)

  const [openBulkPropertyDel, setOpenBulkPropertyDel] = useState(false)
  const [openBulkVehicleDel, setOpenBulkVehicleDel] = useState(false)  
  const [openBulkEmployeeDel, setOpenBulkEmployeeDel] = useState(false)

  const [selectFilters, setSelectFilters] = useState({
    model: {
      name: 'modelType',
      label: 'Select Model',
      key: 'model',
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selected: '',
    },
    make: {
      name: 'makeType',
      label: 'Select Make',
      key: 'make',
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selected: '',
    },
    vehicleStatus: {
      name: 'vehicleStatus',
      label: 'Select Vehicle Status',
      key: 'vehicleStatus',
      options: [
        {
          value: 'Active',
          key: 'Active',
        },
        {
          value: 'Inactive',
          key: 'Inactive',
        },
        {
          value: 'Out of service(Maintainence)',
          key: 'Out of service(Maintainence)',
        },
        {
          value: 'Out of service(Body shop)',
          key: 'Out of service(Body shop)',
        },
        {
          value: 'Inactive(Grounded)',
          key: 'Inactive(Grounded)',
        },
      ],
      selected: '',
    },
    vehicleType: {
      name: 'vehicleType',
      label: 'Select Vehicle Type',
      key: 'vehicleType',
      options: [
        {
          value: 'Leased',
          key: 'LEASED',
        },
        {
          value: 'Owned',
          key: 'OWNED',
        },
      ],
      selected: '',
    },
  })

  const [inputFilters, setInputFilters] = useState({
    constructionYear: {
      value: '',
      key: 'constructionYear',
      label: 'Construction Year',
    },
  })

  const [employeeSelectFilters, setEmployeeSelectFilters] = useState({
    mvr: {
      name: 'mvr',
      label: 'Select MVR',
      key: 'mvr',
      options: [
        {
          value: 'Yes',
          key: 'yes',
        },
        {
          value: 'Required',
          key: 'required',
        },
        {
          value: 'Not Applicable',
          key: 'not_applicable',
        },
      ],
      selected: '',
    },
    maritalStatus: {
      name: 'maritalStatus',
      label: 'Select Marital Status',
      key: 'maritalStatus',
      options: [
        {
          value: 'Unmarried',
          key: 'UNMARRIED',
        },
        { value: 'Married', key: 'MARRIED' },
        { value: 'Others', key: 'OTHERS' },
      ],
      selected: '',
    },
  })

  const [employeeDateRangeFilter, setEmployeeDateRangeFilter] = useState(
    initialEmployeeDateFilterState,
  )

  const [vehicleDateRangeFilter, setVehicleDateRangeFilter] = useState(
    initialVehicleDateFilterState,
  )

  const [propertyDateRangeFilter, setPropertyDateRangeFilter] = useState(
    initialPropertyDateFilterState,
  )

  const { showModule } = useShowModule()

  useEffect(() => {
    if (
      !(
        calendarInfo.claim.length &&
        calendarInfo.incident.length &&
        calendarInfo.policy.length
      )
    ) {
      let modules = ['claim', 'incident', 'policy']
      let fetchCalenderInfo = modules.filter((m) => showModule([m]))

      Promise.all([
        fetchCalenderInfo.includes('claim') && getClaims(),
        fetchCalenderInfo.includes('incident') && getIncidents(),
        fetchCalenderInfo.includes('policy') && getPolicies(),
      ])
        .then(([{ data: claims }, { data: incidents }, { data: policies }]) => {
          if (claims.data && Array.isArray(claims.data)) {
            dispatch(saveClaimInfo(claims.data))
          }

          if (incidents.data && Array.isArray(incidents.data)) {
            dispatch(saveIncidentInfo(incidents.data))
          }

          if (policies.data && Array.isArray(policies.data)) {
            dispatch(savePolicyInfo(policies.data))
          }
        })
        .catch((error) => {})
    }
  }, [])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  const handleEdit = async (item) => {
    if (tab === 'employee') {
      setTitle('Edit Employee')
      await fetchEmployee(item._id)
    } else if (tab === 'property') {
      setTitle('Edit Property')
      await fetchProperty(item._id)
    } else {
      setTitle('Edit Vehicle')
      await fetchVehicle(item._id)
    }
  }

  const fetchVehicle = async (id, context) => {
    const { data: vehicle } = await getVehicle(id)
    dispatch(
      handleSideNavDataUpdated({ data: vehicle.data, module: 'vehicle' }),
    )
    context !== 'view' &&
      setOpenForm({ isOpen: true, data: vehicle.data, context: context })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const fetchEmployee = async (id, context) => {
    const { data: employee } = await getEmployee(id)
    dispatch(
      handleSideNavDataUpdated({ data: employee.data, module: 'employee' }),
    )
    context !== 'view' &&
      setOpenForm({ isOpen: true, data: employee.data, context: context })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const fetchProperty = async (id, context) => {
    const { data: property } = await getProperty(id)
    dispatch(
      handleSideNavDataUpdated({ data: property.data, module: 'property' }),
    )
    context !== 'view' &&
      setOpenForm({ isOpen: true, data: property.data, context: context })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const exportToCSV = async () => {
    if (tab === 'employee') {
      const { data: policyData } = await exportEmployees()
      downloadFile(policyData, 'Employees.csv')
    } else if (tab === 'property') {
      const { data: policyData } = await exportProperties()
      downloadFile(policyData, 'Properties.csv')
    } else {
      const { data: policyData } = await exportVehicles()
      downloadFile(policyData, 'Vehicles.csv')
    }
  }

  const importFromCSV = async (file) => {
    try {
      const res = await parseFile(file)
      if (tab === 'employee' || tab === 'vehicle') {
        res.forEach(function (data) {
          let property = tab === 'employee' ? 'ssnCode' : 'vinNumber'
          if (data.hasOwnProperty(property)) {
            delete data[property]
          }
        })
      }
      const createPolicyPromiseArray = getPromiseArrayForImport(res, tab)
      await Promise.all(createPolicyPromiseArray)
      toast.success(`${res.length} items are imported`)
      setContext('fetchList')
      setImportCSVDialogOpen(false)
    } catch (err) {
    } finally {
      setContext('')
      setContext('fetchList')
      setImportCSVDialogOpen(!importCSVDialogOpen)
    }
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  const handleAddNew = () => {
    setOpenForm({ isOpen: true, data: null })
    if (tab === 'employee') {
      setTitle('Add New Employee')
    } else if (tab === 'property') {
      setTitle('Add New Property')
    } else if (tab === 'vehicle')  {
      setTitle('Add New Vehicle')
    }
  }

  const handleView = async (item) => {
    if (tab === 'employee') {
      dispatch(handleTabIdUpdated('employee'))
      await fetchEmployee(item._id, 'view')
    } else if (tab === 'property') {
      dispatch(handleTabIdUpdated('property'))
      await fetchProperty(item._id, 'view')
    } else if (tab === 'vehicle') {
      dispatch(handleTabIdUpdated('vehicle'))
      await fetchVehicle(item._id, 'view')
    }
  }

  const onClear = (selectedTab) => {
    if (selectedTab === 'employee') {
      setEmployeeFilter((emp) => ({
        isDriver: { ...emp['isDriver'], isAvailable: false },
        active: { ...emp['active'], isAvailable: false },
        terminated: { ...emp['terminated'], isAvailable: false },
      }))
      setEmployeeSelectFilters((emp) => ({
        mvr: {
          ...emp['mvr'],
          selected: '',
        },
        maritalStatus: {
          ...emp['maritalStatus'],
          selected: '',
        },
      }))
      setEmployeeDateRangeFilter(initialEmployeeDateFilterState)
    } else if (selectedTab === 'vehicle') {
      setVehicleFilter((vehicle) => ({
        new: { ...vehicle['new'], isAvailable: false },
        used: { ...vehicle['used'], isAvailable: false },
      }))
      setSelectFilters((s) => ({
        model: {
          ...s['model'],
          selected: '',
        },
        make: {
          ...s['make'],
          selected: '',
        },
        vehicleStatus: {
          ...s['vehicleStatus'],
          selected: '',
        },
        vehicleType: {
          ...s['vehicleType'],
          selected: '',
        },
      }))
      setVehicleDateRangeFilter(initialVehicleDateFilterState)
    } else if (selectedTab === 'property') {
      setPropertyFilter((property) => ({
        owned: { ...property['owned'], isAvailable: false },
        leased: { ...property['leased'], isAvailable: false },
        active: { ...property['active'], isAvailable: false },
        inactive: { ...property['inactive'], isAvailable: false },
      }))
      setPropertyDateRangeFilter(initialPropertyDateFilterState)
    }
  }

  const handleModalClose = () => {
    setEditId('')
    setOpenForm({ data: null, isOpen: false })
    context === 'fetchList' ? setContext('') : setContext('fetchList')
  }


  const handleDechecked = () => {
    setCheckedRecords([])
  }

  const selectedFilterCount = useMemo(() => {
    let count = 0

    let filters = []
    if (tab === 'employee') {
      filters = employeeFilter
      Object.values(employeeSelectFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })
      Object.values(employeeDateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })
    } else if (tab === 'property') {
      filters = propertyFilter
      Object.values(inputFilters).forEach(({ value }) => {
        if (value.length) {
          count += 1
        }
      })
      Object.values(propertyDateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })
    } else if (tab === 'vehicle') {
      filters = vehicleFilter
      Object.values(selectFilters).forEach(({ selected }) => {
        if (selected.length) {
          count += 1
        }
      })
      Object.values(vehicleDateRangeFilter).forEach(({ start, end }) => {
        if (start || end) {
          count += 1
        }
      })
    }
    Object.values(filters).forEach(({ isAvailable }) => {
      if (isAvailable) {
        count += 1
      }
    })
    return count
  }, [
    tab,
    employeeFilter,
    propertyFilter,
    vehicleFilter,
    inputFilters,
    employeeDateRangeFilter,
    propertyDateRangeFilter,
    vehicleDateRangeFilter,
    selectFilters,
    employeeSelectFilters,
  ])

  let getTableView = () => {
    if (tab === 'employee') {
      return (
        <EmployeeList
          context={context}
          handleView={handleView}
          searchQuery={searchQuery}
          checkedRecords={checkedRecords}
          filters={employeeFilter}
          openBulkDel={openBulkEmployeeDel}
          handleDechecked = {handleDechecked}
          closeBulkDel={() => setOpenBulkEmployeeDel(false)}
          setShowDeleteButton={setShowDeleteButton}
          dateRangeFilter={employeeDateRangeFilter}
          selectFilters={Object.values(employeeSelectFilters)
            .filter(({ selected }) => selected.length)
            .reduce((prev, { key, selected }) => {
              prev[key] = selected
              return prev
            }, {})}
          setFilterCount={(filterCount) => {
            const { isDriver, active, terminated } = filterCount

            setEmployeeFilter({
              isDriver: {
                ...employeeFilter['isDriver'],
                count: isDriver,
              },
              active: { ...employeeFilter['active'], count: active },
              terminated: {
                ...employeeFilter['terminated'],
                count: terminated,
              },
            })
          }}
          handleElementCheckboxClicked={handleElementCheckboxClicked}
          handleEdit={handleEdit}
         
        />
      )
    } else if (tab === 'property') {
      return (
        <PropertyList
          context={context}
          handleView={handleView}
          searchQuery={searchQuery}
          checkedRecords={checkedRecords}
          filters={propertyFilter}
          inputFilters={inputFilters}
          handleDechecked = {handleDechecked}
          dateRangeFilter={propertyDateRangeFilter}
          openBulkDel={openBulkPropertyDel}
          closeBulkDel={() => setOpenBulkPropertyDel(false)}
          setShowDeleteButton={setShowDeleteButton}
          setFilterCount={(filterCount) => {
            const { owned, leased, active, inactive } = filterCount
            setPropertyFilter({
              owned: { ...propertyFilter['owned'], count: owned },
              leased: { ...propertyFilter['leased'], count: leased },
              active: { ...propertyFilter['active'], count: active },
              inactive: { ...propertyFilter['inactive'], count: inactive },
            })
          }}
          handleElementCheckboxClicked={handleElementCheckboxClicked}
          handleEdit={handleEdit}
        />
      )
    } else if (tab === 'vehicle')  {
      return (
        <VehicleList
          context={context}
          handleView={handleView}
          searchQuery={searchQuery}
          checkedRecords={checkedRecords}
          filters={vehicleFilter}
          handleDechecked = {handleDechecked}
          openBulkDel={openBulkVehicleDel}
          closeBulkDel={() => setOpenBulkVehicleDel(false)}
          setShowDeleteButton={setShowDeleteButton}
          dateRangeFilter={vehicleDateRangeFilter}
          dateRangeOfSold={dateRangeOfSold}
          selectFilters={Object.values(selectFilters)
            .filter(({ selected }) => selected.length)
            .reduce((prev, { key, selected }) => {
              prev[key] = selected
              return prev
            }, {})}
          setSelectFilter={(modelList, makeList) => {
            const vFilter = { ...selectFilters }
            vFilter.make.options = makeList
            vFilter.model.options = modelList
            setSelectFilters(vFilter)
          }}
          setFilterCount={({ NEW, USED }) => {
            const vFilter = { ...vehicleFilter }
            vFilter.new.count = NEW
            vFilter.used.count = USED
            setVehicleFilter(vFilter)
          }}
          handleElementCheckboxClicked={handleElementCheckboxClicked}
          handleEdit={handleEdit}
        />
      )
    }
  }

  let getModal = () => {
    if (tab === 'employee') {
      return (
        <EmployeeModal
          id={editId}
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )
    } else if (tab === 'property') {
      return (
        <PropertyModal
          id={editId}
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )
    } else {
      return (
        <VehicleModal
          id={editId}
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )
    }
  }

  let getTabsData = () => {
    return [
      {
        module: 'EMPLOYEE',
        id: 'employee',
        label: 'Employees',
      },
      {
        module: 'VEHICLE',
        id: 'vehicle',
        label: 'Vehicles',
      },
      {
        module: 'PROPERTY',
        id: 'property',
        label: 'Property',
        style: { marginRight: '48px' },
      },
    ]
  }

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView:
          tab === 'employee' ? (
            <FilterDropdown
              selectFilters={Object.values(employeeSelectFilters)}
              filters={employeeFilter}
              setFilters={setEmployeeFilter}
              setSelectedFilter={setEmployeeSelectFilters}
              dateRangeFilter={employeeDateRangeFilter}
              setDateRangeFilter={setEmployeeDateRangeFilter}
              selectedFilterCount={selectedFilterCount}
              onClear={() => onClear('employee')}
            />
          ) : tab === 'property' ? (
            <FilterDropdown
              filters={propertyFilter}
              setFilters={setPropertyFilter}
              dateRangeFilter={propertyDateRangeFilter}
              setDateRangeFilter={setPropertyDateRangeFilter}
              selectedFilterCount={selectedFilterCount}
              onClear={() => onClear('property')}
              inputFilters={inputFilters}
              setInputFilters={setInputFilters}
            />
          ) : (
            <FilterDropdown
              selectFilters={Object.values(selectFilters)}
              filters={vehicleFilter}
              setFilters={setVehicleFilter}
              setSelectedFilter={setSelectFilters}
              dateRangeFilter={vehicleDateRangeFilter}
              setDateRangeFilter={setVehicleDateRangeFilter}
              dateRangeOfSold={dateRangeOfSold}
              setDateRangeOfSold={setDateRangeOfSold}
              selectedFilterCount={selectedFilterCount}
              onClear={() => onClear('vehicle')}
            />
          ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const handleElementCheckboxClicked = (data, context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (data.length !== checkedRecords.length) {
        data.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }
      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  const handleTabChange = (tab) => {
    setTab(tab)
    dispatch(handleTabIdUpdated(tab))
    setCheckedRecords([])
  }  

  useEffect(()=> {
    if(selectedTabId){
      setTab(selectedTabId);
    }
  },[selectedTabId])


  return (
    <div
      className="position-rel"
      style={{ marginBottom: '15px', width: 'calc(100% - 70px)' }}
    >
      <ListPageHeader
        showTabs={true}
        modules={['EMPLOYEE', 'PROPERTY', 'VEHICLE']}
        showFilters={true}
        selectedTabId={tab}
        onAdd={handleAddNew}
        setTab={handleTabChange}
        tabsData={getTabsData()}
        searchQuery={searchQuery}
        addCategory={addCategory}
        heading={'Assets Management'}
        onSearchChange={handleSearch}
        filtersData={getFiltersData()}
        handleExportToCsv={exportToCSV}
        removeCategory={removeCategory}
        hideDeleteButton={!showDeleteButton}
        handleImportfromCsv={importFromCSVButtonClicked}
        selectedCategories={selectedCategories}
        filterCount={selectedFilterCount}
        handleBulkCheckClick={() => {
          if(tab == 'employee'){
            setOpenBulkEmployeeDel(true)
          }else if(tab == 'property'){
            setOpenBulkPropertyDel(true)
          }else{
            setOpenBulkVehicleDel(true)
          }                     
        }}
      />
      {getTableView()}
      {openForm.isOpen && getModal()}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSV}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}

export default AssetManagement
