import { useDispatch } from 'react-redux'
import React, { useState, useEffect, useMemo } from 'react'
import ImportCSV from '../../components/common/importCSV'
import ListPageHeader from '../../components/ListPageHeader'
import {
  handleSideNavDataUpdated,
  handleSideNavElementClicked,
  handleTabIdUpdated,
} from '../../actions'
import {
  downloadFile,
  getPromiseArrayForImport,
  parseFile,
} from '../../services/helperService'
import {
  exportThirdParties,
  getThirdParty,
} from '../../services/thirdPartyService'
import ThirdPartyModal from './ThirdPartyModal'
import ThirdPartyList from './ThirdPartyList'
import FilterDropdown from '../../components/common/FilterDropdown'
import { toast } from 'react-toastify'

function ThirdParty(props) {
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [context, setContext] = useState('')
  const [openBulkDel, setOpenBulkDel] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [openForm, setOpenForm] = useState({ isOpen: false, data: null })
  const [importCSVDialogOpen, setImportCSVDialogOpen] = useState(false)
  const [thirdPartyFilters, setThirdPartyFilters] = useState({
    typeOfEnquiry: {
      name: 'typeOfEnquiry',
      label: 'Select Type Of Enquiry',
      key: 'typeOfEnquiry',
      options: [
        {
          value: 'Active',
          key: 'Active',
        },
        {
          value: 'Inactive',
          key: 'Inactive',
        },
      ],
      selected: '',
    },
    organisation: {
      name: 'organisation',
      label: 'Select Organization',
      key: 'organisation',
      isAutocomplete: true,
      options: [
        {
          value: '',
          key: '',
        },
      ],
      selected: [],
    },
  })

  const addCategory = (category) => {
    const temp = [...selectedCategories]
    if (temp.indexOf(category) === -1) {
      temp.push(category)
    }
    setSelectedCategories(temp)
  }

  const removeCategory = (category) => {
    const temp = [...selectedCategories]
    const index = temp.indexOf(category)
    if (index > -1) {
      temp.splice(index, 1)
    }
    setSelectedCategories(temp)
  }

  const exportToCSV = async () => {
    const { data: partyData } = await exportThirdParties()
    downloadFile(partyData, 'Parties.csv')
  }

  const handleEdit = async (item) => {
    setTitle('Edit Third Party')
    await fetchThirdParty(item._id)
  }

  const fetchThirdParty = async (id, context) => {
    const { data: party } = await getThirdParty(id)
    dispatch(
      handleSideNavDataUpdated({ data: party.data, module: 'third-party' }),
    )
    context !== 'view' && setOpenForm({ isOpen: true, data: party.data })
    context === 'view' && dispatch(handleSideNavElementClicked('info'))
  }

  const handleView = async (item) => {
    dispatch(handleTabIdUpdated('thirdParty'))
    await fetchThirdParty(item._id, 'view')
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const importFromCSV = async (file) => {
    try {
      const res = await parseFile(file)
      const createPartyPromiseArray = getPromiseArrayForImport(
        res,
        'thirdParty',
      )
      await Promise.all(createPartyPromiseArray)
      toast.success(`${res.length} items are imported`)
      setContext('fetchList')
      setImportCSVDialogOpen(false)
    } catch (err) {}
  }

  let getFiltersData = () => {
    return {
      filter: {
        dropdownView: (
          <FilterDropdown
            selectFilters={Object.values(thirdPartyFilters)}
            setSelectedFilter={setThirdPartyFilters}
            selectedFilterCount={selectedFilterCount}
            onClear={onClear}
          />
        ),
      },
      option: {
        dropdownView: (
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#">
              Action
            </a>
            <a className="dropdown-item" href="#">
              Another action
            </a>
            <a className="dropdown-item" href="#">
              Something else here
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#">
              Separated link
            </a>
          </div>
        ),
      },
    }
  }

  const importFromCSVButtonClicked = () => {
    setImportCSVDialogOpen(!importCSVDialogOpen)
  }

  const handleAddNew = () => {
    setOpenForm({ isOpen: true, data: null })
    setTitle('Add New Third Party')
  }

  const handleModalClose = () => {
    setOpenForm({ data: null, isOpen: false })
    setContext('')
    setContext('fetchList')
  }

  const onClear = () => {
    setThirdPartyFilters((thirdParty) => ({
      typeOfEnquiry: { ...thirdParty['typeOfEnquiry'], selected: '' },
      organisation: { ...thirdParty['organisation'], selected: '' },
    }))
  }

  const selectedFilterCount = useMemo(() => {
    let count = 0

    Object.values(thirdPartyFilters).forEach(({ selected }) => {
      if (selected.length) {
        count += 1
      }
    })
    return count
  }, [thirdPartyFilters])

  return (
    <div className={'position-rel'} style={{ marginBottom: '15px' }}>
      <ListPageHeader
        modules={['THIRD-PARTY']}
        showTabs={false}
        showFilters={true}
        onAdd={handleAddNew}
        searchQuery={searchQuery}
        addCategory={addCategory}
        heading={'Third Party'}
        onSearchChange={handleSearch}
        filtersData={getFiltersData()}
        handleExportToCsv={exportToCSV}
        removeCategory={removeCategory}
        hideDeleteButton={!showDeleteButton}
        selectedCategories={selectedCategories}
        handleBulkCheckClick={() => {
          setOpenBulkDel(true)
        }}
        handleImportfromCsv={importFromCSVButtonClicked}
        filterCount={selectedFilterCount}
      />
      <ThirdPartyList
        context={context}
        handleView={handleView}
        searchQuery={searchQuery}
        setShowDeleteButton={setShowDeleteButton}
        selectFilters={Object.values(thirdPartyFilters)
          .filter(({ selected }) => selected.length)
          .reduce((prev, { key, selected }) => {
            prev[key] = selected
            return prev
          }, {})}
        setSelectFilter={(organisationList) => {
          const pFilter = { ...thirdPartyFilters }
          // pFilter.typeOfEnquiry.options = typeOfEnquiryList
          pFilter.organisation.options = organisationList
          setThirdPartyFilters(pFilter)
        }}
        openBulkDel={openBulkDel}
        closeBulkDel={() => setOpenBulkDel(false)}
        handleEdit={handleEdit}
      />
      {openForm.isOpen && (
        <ThirdPartyModal
          title={title}
          data={openForm.data}
          isOpen={openForm.isOpen}
          onClose={handleModalClose}
        />
      )}
      {importCSVDialogOpen && (
        <ImportCSV
          isOpen={importCSVDialogOpen}
          onImport={importFromCSV}
          onClose={importFromCSVButtonClicked}
        />
      )}
    </div>
  )
}

export default ThirdParty
