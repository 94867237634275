import moment from 'moment'
import TimePicker from 'rc-time-picker'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DatePickerField from '../../../components/form/DatePickerField'
import Select from '../../../components/form/Select'
import TextInput from '../../../components/form/TextInput'
import { getEmployees } from '../../../services/employeeService'
import { getAddressDetails } from '../../../services/helperService'
import { employeeStatusOptions } from '../utils'

export default function UserDetailsView({
  isClientExist,
  values,
  setFieldValue,
  onNavigate,
}) {
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [employeeOptions, setEmployeeOptions] = useState([])

  const handlePincodeChange = async (e) => {
    if (e.target.value !== '') {
      try {
        const {
          data: {
            data: { data: data },
          },
        } = await getAddressDetails(e.target.value)
        setFieldValue('userDetails.state', data?.state_fullname)
        setFieldValue('userDetails.city', data?.city)
        setFieldValue('userDetails.country', 'US')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const selectedEmployeeData = (selectedEmployeeId) => {
    if (selectedEmployeeId) {
      const emp = employeeOptions
        .filter(
          (employeeOptionsData) =>
            employeeOptionsData?.key === selectedEmployeeId,
        )
        ?.shift()
      const pincode =
        emp?.employeeData?.address?.pinCode &&
        emp?.employeeData?.address?.pinCode !== null
          ? emp?.employeeData?.address?.pinCode
          : undefined
      setFieldValue('userDetails.firstName', emp?.employeeData?.firstName ?? '')
      setFieldValue('userDetails.lastName', emp?.employeeData?.lastName ?? '')
      setFieldValue('userDetails.email', emp?.employeeData?.email ?? '')
      setFieldValue(
        'userDetails.contactNumber',
        emp?.employeeData?.personalPhone ?? '',
      )
      setFieldValue(
        'userDetails.dateOfBirth',
        emp?.employeeData?.dateOfBirth ?? '',
      )
      setFieldValue('userDetails.hireDate', emp?.employeeData?.hireDate ?? '')
      setFieldValue(
        'userDetails.employeeStatus',
        emp?.employeeData?.status ?? '',
      )
      setFieldValue(
        'userDetails.address',
        emp?.employeeData?.address?.line1 ?? '',
      )
      setFieldValue('userDetails.zip', pincode ?? '')
      setFieldValue('userDetails.city', emp?.employeeData?.address?.city ?? '')
      setFieldValue(
        'userDetails.state',
        emp?.employeeData?.address?.state ?? '',
      )
      setFieldValue(
        'userDetails.country',
        emp?.employeeData?.address?.country ?? '',
      )
    }
  }

  useEffect(() => {
    const fetchEmployees = async () => {
      setEmployeeOptions([])
      try {
        const result = await getEmployees({
          params: {
            clientId: values.clientId ?? selectedClientId,
          },
        })
        setEmployeeOptions([
          ...result.data.data.map((e) => ({
            key: e._id,
            value: `${e.firstName} ${e.lastName}`,
            employeeData: e,
          })),
          {
            key: 'OTHER',
            value: 'Other',
          },
        ])
      } catch (error) {
        console.error(error)
      }
    }
    fetchEmployees()
  }, [selectedClientId, values.clientId])

  const handleContinue = () => {
    let parties = values?.partiesInvolved?.length
      ? [...values.partiesInvolved]
      : [];
  
    let party = {
      firstName: values?.userDetails?.firstName || '',
      lastName: values?.userDetails?.lastName || '',
      email: values?.userDetails?.email || '',
      organisation: values?.userDetails?.organisation || '',
      contactNumber: values?.userDetails?.contactNumber || '',
      address: {
        line1: values?.userDetails?.address,
        line2: '',
        city: values?.userDetails?.city,
        state: values?.userDetails?.state,
        country: values?.userDetails?.country,
        pinCode: values?.userDetails?.zip,
      },
      employeeId:
        values?.userDetails?.employeeId &&
        values?.userDetails?.employeeId !== 'OTHER'
          ? values?.userDetails?.employeeId
          : 'OTHERS',
    };
  
    if (parties.length) {
      // Update the existing object in the array
      parties[0].isCompanyEmployee = true;
      parties[0].personDetails = { ...party };
    } else {
      parties.push({ isCompanyEmployee: true, personDetails: { ...party } });
    }
    setFieldValue('partiesInvolved', parties);
    onNavigate(2)
  };
  return (
    <>
      <div className="border-bottom h6 py-2 mb-3">User Details</div>
      <div className="form-row">
        <div className="col-md-12">
          <Select
            data-testid="employee-select"
            withAsterisk
            label="Select Employee"
            name="userDetails.employeeId"
            defaultLabel="Select Employee"
            options={employeeOptions}
            showErrorEvenWhenUntouched
            value={values?.userDetails?.employeeId}
            onChange={(e) => {
              setFieldValue('userDetails.employeeId', e.target.value)
              selectedEmployeeData(e.target.value)
            }}
          />
        </div>
        {(values?.userDetails?.employeeId === 'OTHERS' ||
          values?.userDetails?.employeeId === 'OTHER') && (
          <>
            <div className="col-md-6">
              <TextInput
                withAsterisk
                label="Employee First Name"
                name="userDetails.firstName"
                showErrorEvenWhenUntouched
                // onChange={(e) => {
                //   setFieldValue('partiesInvolved.0.personDetails.firstName', e.target.value)
                // }}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                withAsterisk
                label="Employee Last Name"
                name="userDetails.lastName"
                showErrorEvenWhenUntouched
              />
            </div>
          </>
        )}
        <div className="col-md-6">
          <TextInput
            data-testid="employee-email"
            label="Employee Email"
            name="userDetails.email"
            showErrorEvenWhenUntouched
            value={values?.userDetails?.email || ''}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            data-testid="employee-contact-number"
            isPhone
            changeOnUndefined={true}
            label="Employee Contact Number"
            name="userDetails.contactNumber"
            showErrorEvenWhenUntouched
            maxLength={14}
            onPhoneChange={(phone) => {
              setFieldValue('userDetails.contactNumber', phone || '')
            }}
            value={values?.userDetails?.contactNumber || ''}
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="userDetails.dateOfBirth"
            label="Date of Birth"
            minDate={moment().subtract(100, 'years').toDate()}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <DatePickerField
            name="userDetails.hireDate"
            label="Hire Date"
            minDate={moment().subtract(100, 'years').toDate()}
            maxDate={new Date()}
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div>
              <label>
                Shift Start Time (
                {moment()
                  .tz(values.timezone ?? moment.tz.guess())
                  ?.zoneAbbr()}
                )
              </label>
            </div>
            <TimePicker
              use12Hours
              className="antDOverride"
              name="shiftStartTime"
              onChange={(value) => {
                setFieldValue('shiftStartTime', value.format('HH:mm:ss A'))
              }}
              format="hh:mm:ss A"
              value={moment(values?.shiftStartTime || '00:00:00', 'HH:mm:ss A')}
              defaultValue={moment('00:00:00', 'HH:mm:ss A')}
            />
          </div>
        </div>
        <div className="col-md-6">
          <TextInput label="FICO Score" name="userDetails.ficoScore" />
        </div>
        <div className="col-md-6">
          <TextInput label="Job Position/Title" name="userDetails.jobTitle" />
        </div>
        <div className="col-md-6">
          <TextInput
            label="Station Code/ Job Site"
            name="userDetails.jobSite"
          />
        </div>
        <div className="col-md-12">
          <Select
            label="Employee Status"
            withAsterisk
            name="userDetails.employeeStatus"
            defaultLabel="Select status"
            options={employeeStatusOptions}
            value={values.employeeStatus}
          />
        </div>
        <div className="col-md-12">
          <TextInput
            noBottomMargin
            label="Driver Address"
            name="userDetails.address"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="Zip"
            name="userDetails.zip"
            type="number"
            onBlur={handlePincodeChange}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="City"
            name="userDetails.city"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="State"
            name="userDetails.state"
          />
        </div>
        <div className="col-md-6">
          <TextInput
            noBottomMargin
            placeholder="Country"
            name="userDetails.country"
          />
        </div>
      </div>
      <hr />
      <button
        data-testid="btn-next"
        type="button"
        className="btn btn-primary pull-right"
        // onClick={() => {
        //   if (isClientExist && selectedClientId !== 'allClients') {
        //     values['clientId'] = selectedClientId
        //     setFieldValue('clientId', selectedClientId)
        //   }
        //   nextTab(values)
        // }}
        onClick={handleContinue}
      >
        Continue
      </button>
    </>
  )
}
