import React, { useState, useEffect, useRef, useContext } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import Table from '../../components/common/Table'
import {
  deleteBlukPolicy,
  deletePolicy,
  getPolicies,
} from '../../services/policyService'
import ActionWidget from '../../components/common/ActionWidget'
import { getPagedData } from '../../services/helperService'
import {
  handleSideNavDataUpdated,
  handleTabIdUpdated,
  saveClaimInfo,
  saveIncidentInfo,
  savePolicyInfo,
} from '../../actions'
import ConfirmationModal from '../../components/common/ConfirmationModal'
import { getClaims } from '../../services/claimService'
import { getIncidents } from '../../services/incidentService'
import { useShowModule } from '../../hooks/useShowModule'
import { addCommaTableField } from '../incident/utils'
import Loader from '../../components/common/Loader'

let policies = []
let itemsCount = 0

function PolicyList(props) {
  const [isLoading , setIsLoading] = useState(true);
  const columns = [
    {
      path: 'policyId',
      label: ' Policy Id ',
      minWidth: '200px',
      width: '230px',
      content: (policy) => (
        <>
          <a>{policy.policyId}</a>
          <ActionWidget
            module="POLICY"
            onEdit={() => props.handleEdit(policy)}
            onView={() => props.handleView(policy)}
            onDelete={() => {
              setPolicy(policy);
              setDeleteOne(true);
            }}
          />
        </>
      ),
    },
    { path: 'insuranceCarrier', label: ' Insurance Carrier ' },
    {
      path: 'policyType',
      label: ' Policy Type ',
      content: ({ policyType = '' }) => {
        let displayPolicyType = policyType
        if (!['GL', 'WC'].includes(displayPolicyType)) {
          displayPolicyType = _.map(
            displayPolicyType.split(' '),
            _.capitalize,
          ).join(' ')
        }
        return displayPolicyType
      },
    },
    {
      path: 'sumInsured',
      label: ' Sum Insured ',
      content: (policy) => (
        <>
          <span>
            {policy.sumInsured
              ? '$ ' + addCommaTableField(policy.sumInsured)
              : 'N/A'}
          </span>
        </>
      ),
    },
    { path: 'policyDate', label: ' Policy Date ', type: 'date' },
    {
      path: 'premiumRenewalDate',
      label: ' Policy Renewal Date ',
      type: 'date',
    },
    { path: 'policyNotes', label: ' Policy Notes ' },
  ]
  let dispatch = useDispatch()
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [checkedRecords, setCheckedRecords] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [policy, setPolicy] = useState({})
  const [deleteOne , setDeleteOne] = useState(false);
  const mountedRef = useRef(true)
  const { selectedClientId } = useSelector((state) => state)
  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)

  const { showModule } = useShowModule()

  useEffect(() => {
    let modules = ['claim', 'incident']
    let fetchCalenderInfo = modules.filter((m) => showModule([m]))

    Promise.all([
      fetchCalenderInfo.includes('claim') && getClaims(),
      fetchCalenderInfo.includes('incident') && getIncidents(),
    ])
      .then(([{ data: claims }, { data: incidents }]) => {
        if (claims.data && Array.isArray(claims.data)) {
          dispatch(saveClaimInfo(claims.data))
        }
        if (incidents.data && Array.isArray(incidents.data)) {
          dispatch(saveIncidentInfo(incidents.data))
        }
      })
      .catch((error) => {
        console.log({ error })
      })
  }, [])

  useEffect(() => {
    if (
      selectedTabId === 'policy' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      props.handleEdit(viewData)
    }
    if (selectedTabId === 'policy' && viewAction === 'refresh') {
      setIsLoading(true)
      fetchList()
    }
  }, [viewAction, viewData, selectedTabId])

  useEffect(() => {
    mountedRef.current = true
    setIsLoading(true)
    fetchList()
    dispatch(handleTabIdUpdated('policy'))
    return () => {
      mountedRef.current = false
    }
  }, [props.context, selectedClientId])

  useEffect(() => {
    props.setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {
    if (props.openBulkDel) {
      setPolicy({ policy: { policyId: 'all claims' } })
    }
  }, [props.openBulkDel])

  const fetchList = async () => {
    const { data } = await getPolicies()
    if (!mountedRef.current) return null

    if (data.data && Array.isArray(data.data)) {
      setData(data.data)
      dispatch(savePolicyInfo(data.data))

      // Update Filter Values for policy.
      const insuranceList = {}
        // policyTypeList = {}
        // sumInsuredList = {}
      data.data.forEach(({ insuranceCarrier }) => {
        insuranceList[insuranceCarrier] = {
          key: insuranceCarrier,
          value: insuranceCarrier,
        }
        // policyTypeList[policyType] = {
        //   key: policyType,
        //   value: policyType,
        // }
        // sumInsuredList[sumInsured] = {
        //   key: sumInsured,
        //   value: sumInsured,
        // }
      })
      props.setSelectFilter(
        Object.values(insuranceList || {}),
        // Object.values(policyTypeList || {}),
        // Object.values(sumInsuredList || {}),
      )
      if (sideNavDataContext !== 'searchView') {
        dispatch(
          handleSideNavDataUpdated({ data: data.data[0], module: 'policy' }),
        )
        dispatch(handleTabIdUpdated('policy'))
      }
    }
    setIsLoading(false)
  }

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      props.searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    policies = result
    return result.length > 0
  }

  const handleDelete = async (item) => {
    await deletePolicy(item._id, item)
    await fetchList()
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handleElementCheckboxClicked = (context, id) => {
    if (context === 'selectAll') {
      let selectedIds = []
      if (policies.length !== checkedRecords.length) {
        policies.forEach(function (item) {
          selectedIds.push(item._id)
        })
      }

      setCheckedRecords(selectedIds)
    } else {
      if (checkedRecords.includes(id)) {
        let index = checkedRecords.indexOf(id)
        checkedRecords.splice(index, 1)
        setCheckedRecords([...checkedRecords])
      } else {
        checkedRecords.push(id)
        setCheckedRecords([...checkedRecords])
      }
    }
  }

  const handleDeleteModal = async () => {
    setIsLoading(true)
    try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }
        const { data } = await   await deleteBlukPolicy(params)
        setDeleteOne(false);
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        props.setShowDeleteButton(false)
        props.closeBulkDel()
      } else {
        const { data } = await deletePolicy(policy._id, policy)
        setDeleteOne(false);
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      }
    } catch (error) {
      console.log('error' , error);
    }
    setPolicy({});
    await fetchList()
  }

  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData() || ''}
      <Table
        columns={columns}
        data={policies.filter((policy) => {
          const { insuranceCarrier, policyType, sumInsured } = policy
          const {
            insurance: selectedInsuranceCarrier = '',
            policyType: selectedPolicyType = '',
            sumInsured: selectedSumInsured = '',
          } = props.selectFilters

          let isInsuranceCarrier = false,
            isPolicyType = false,
            isDateRange = false,
            isSumInsured = false

          const dateRangeFilter = props.dateRangeFilter
          let isDateRangeValid = false
          let isDateRangeSelected = Object.values(dateRangeFilter).every(
            (x) => {
              return x.start === null
            },
          )

          if (dateRangeFilter && !isDateRangeSelected) {
            Object.values(dateRangeFilter).forEach(({ start, end, key }) => {
              if (start !== null && policy[key] !== '') {
                if (
                  policy[key] > start.toISOString() &&
                  policy[key] < end.toISOString()
                ) {
                  isDateRangeValid = true
                } else {
                  isDateRangeValid = false
                }
              }
            })
          }

          if (
            selectedInsuranceCarrier.length &&
            selectedInsuranceCarrier === insuranceCarrier
          ) {
            isInsuranceCarrier = true
          }
          if (selectedPolicyType.length && selectedPolicyType.toLowerCase() === policyType.toLowerCase()) {
            isPolicyType = true
          }
          if (selectedSumInsured.length) {
            let startRange = selectedSumInsured.split('-')[0]
            let endRange = selectedSumInsured.split('-')[1]
            if(endRange){
              if(sumInsured >= startRange && sumInsured <= endRange){
                isSumInsured = true
              }
            } else {
              if(sumInsured >= startRange){
                isSumInsured = true
              }
            }
          }

          return (
            !(!!selectedInsuranceCarrier.length ^ isInsuranceCarrier) &&
            !(!!selectedPolicyType.length ^ isPolicyType) &&
            !(!!selectedSumInsured.length ^ isSumInsured) &&
            !(!isDateRangeSelected ^ isDateRangeValid)
          )
        })}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked}
      />
      <ConfirmationModal
        name={checkedRecords.length > 0 ? `Policies` : 'Policy Id ' + (policy?.policyId || '')}
        isOpen={Object.keys(policy || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) }      
        // isOpen={checkedRecords.length > 0 || deleteOne}
        onClose={() => {
          setPolicy({})
          props.setShowDeleteButton(false)
        props.closeBulkDel()
        setDeleteOne(false)
        setCheckedRecords([])

        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default PolicyList
