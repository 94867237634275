export const capitalizeWords = (wordsArray, spitCapitalized = false) => {
    return wordsArray.map(word => {
        // Capitalize the first letter of each word
        let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        
        if (spitCapitalized && capitalized) {
            capitalized = capitalized.split(/(?=[A-Z])/).join(" ")
        }
        return capitalized
    }).join(', ');
}

export const NO_TIMEZONE_DATE_FORMAT = "YYYY-MM-DD[T]HH:mm:ss"