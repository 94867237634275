import apiUrl from '../configs/api'
import httpService from './httpService'

// const apiUrl = 'http://localhost:3005/api/v1'
const apiEndpoint = `${apiUrl}/search`

export async function gobalSearchQuery(query) {
  const urlEncoded = encodeURI(query)
  return httpService.get(`${apiEndpoint}/find?q=${urlEncoded}`)
}
