import { useMemo } from 'react'
import { useSelector } from 'react-redux'

/**
 * @param  {} module
 * @param  {"CREATE" | "READ" | "UPDATE" | "DELETE" | "DOWNLOAD" | "GENERATE_LINK" | "COPY_LINK"} accessType
 */
export function useGetPermission(module, accessType) {
  const permissions = useSelector((state) => state.permissions)
  const modulePermission = useMemo(() => {
    if (permissions) {
      return permissions.find((c) => c.module.moduleName === module.toUpperCase())
    } else {
      return null
    }
  }, [permissions, module])
  
  let access = false
  if (modulePermission) {
    if (modulePermission.accessMode === 'NO_ACCESS') {
      access = false
    } else {

      let checkOperation = modulePermission.operations.includes(accessType)
      let extras = modulePermission.settings.extras.includes(accessType)
      access = checkOperation || extras
    }
  }
  return access
}

export function useCommentAccess(module, accessType) {
  const permissions = useSelector((state) => state.permissions)
  const modulePermission = useMemo(() => {
    if (permissions) {
      return permissions.find((c) => c.module.identifier === module)
    } else {
      return null
    }
  }, [permissions, module])
  let access = false
  if (modulePermission) {
    if (modulePermission.accessMode === 'NO_ACCESS') {
      access = false
    } else {
      let extras = modulePermission.settings.comment.includes(accessType)
      access = extras
    }
  }
  return access
}

export function useAttachmentAccess(module, accessType) {
  const permissions = useSelector((state) => state.permissions)
  const modulePermission = useMemo(() => {
    if (permissions) {
      return permissions.find((c) => c.module.identifier === module)
    } else {
      return null
    }
  }, [permissions, module])

  let access = false
  if (modulePermission) {
    if (modulePermission.accessMode === 'NO_ACCESS') {
      access = false
    } else {
      let extras = modulePermission.settings.attachments.includes(accessType) || modulePermission.settings.extras.includes(accessType)
      access = extras
    }
  }
  return access
}