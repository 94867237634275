import React from 'react'
import Checkbox from './Checkbox'

const HistoryNotification = ({ extras, onCheckedChange }) => {
  const options = [
    {
      value: 'HISTORY',
      label: 'History',
      checked: extras.includes('HISTORY'),
      onChange: function (e) {
        onCheckedChange(e.target.value, 'extras')
      },
    },
    {
      value: 'NOTIFICATION',
      label: 'Notification',
      checked: extras.includes('NOTIFICATION'),
      onChange: function (e) {
        onCheckedChange(e.target.value, 'extras')
      },
    },
  ]

  return (
    <div className="form-group d-flex">
      {options.map((option) => (
        <a
          key={option.value}
          href="#"
          className="dropdown-item d-inline-flex justify-content-space-between"
          id="selectboxdrop"
        >
          <Checkbox
            value={option.value}
            label={option.value}
            id={'historyNotification' + option.value}
            checked={option.checked}
            onChange={option.onChange}
          />
        </a>
      ))}
    </div>
  )
}

export default HistoryNotification
