import axios from 'axios'
import FileSaver from 'file-saver'
import _ from 'lodash'
import Papa from 'papaparse'
import React from 'react'
import apiUrl from '../configs/api'
import { getClaims, saveClaim } from './claimService'
import { saveClient } from './clientService'
import { getEmployees, saveEmployee } from './employeeService'
import http from './httpService'
import { getIncidents, saveIncident } from './incidentService'
import { saveMvr } from './mvrService'
import { getPolicies, savePolicy } from './policyService'
import { getProperties, saveProperty } from './propertyService'
import { saveThirdParty } from './thirdPartyService'
import { saveUser } from './userService'
import { getVehicles, saveVehicle } from './vehicleService'

const apiEndpoint = `${apiUrl}/utilities`

export function uploadFiles(files, key) {
  const formData = new FormData()
  for (const f of files) formData.append('file', f)
  formData.append(`key`, key)
  return http.post(`${apiEndpoint}/upload-to-s3`, formData)
}
export function getPreSignedUrl(body, module) {
  return http.post(`${apiEndpoint}/get-presigned-url?module=${module}`, body)
}

export async function getAddressDetails(zipcode) {
  return http.get(`${apiEndpoint}/get-zipcode?zipCode=${zipcode}`)
}

export async function uploadFile(url, data, headers) {
  const response = await fetch(url, {
    method: 'PUT',
    headers,
    body: data,
  })
  return response
}

export function downloadFile(data, name) {
  FileSaver.saveAs(data, name)
}
export function parseFile(file) {
  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      worker: true,
      dynamicTyping: true,
      complete: (results) => {
        resolve(results.data)
      },
    })
  })
}
export function removeProp(obj, propToDelete) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === 'object') {
        removeProp(obj[property], propToDelete)
      } else if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
}
export function hasValue(obj, value) {
  for (const property in obj) {
    if (property.startsWith('_')) continue
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === 'object') {
        hasValue(obj[property], value)
      } else if (['number', 'string'].includes(typeof obj[property])) {
        const res = obj[property]
          .toString()
          .toLowerCase()
          .startsWith(value.toLowerCase())
        if (res) return res
      } else {
        continue
      }
    }
  }
  return false
}
export function IsJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export function getPromiseArrayForImport(data, type) {
  return data.map((item) => {
    for (const k in item) {
      IsJsonString(item[k]) && (item[k] = JSON.parse(item[k]))
    }
    removeProp(item, '_id')
    item = parseAddress(item)
    item = convertToDate(item)
    switch (type) {
      case 'policy':
        return savePolicy(item)
      case 'employee':
        return saveEmployee(item)
      case 'client':
        return saveClient(item)
      case 'user':
        return saveUser(item)
      case 'vehicle':
        return saveVehicle(item)
      case 'property':
        return saveProperty(item)
      case 'mvr':
        return saveMvr(item)
      case 'thirdParty':
        return saveThirdParty(item)
      case 'claims':
        return saveClaim(item)
      case 'incidents':
        return saveIncident(item)
    }
  })
}

function parseAddress(obj) {
  let addressKeys = []
  for (let k in obj) {
    if (k.includes('address.')) {
      addressKeys.push(k)
    }
  }
  if (addressKeys.length) {
    let address = {}
    addressKeys.forEach((k) => {
      address[k.split('.')[1]] = obj[k] || ''
      delete obj[k]
    })
    obj = { ...obj, address }
  }

  return obj
}

export function convertToDate(obj) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === 'object') {
        convertToDate(obj[property])
      } else if (checkIfValueIsDate(obj[property])) {
        obj[property] = obj[property].toString().split('#D')[1]
      }
    }
  }
  return obj
}

function checkIfValueIsDate(val) {
  return val.toString().split('#D').length === 3
}
export function tagSearch(selectedCategories, columns, data, searchQuery) {
  const selectedPaths = columns
    .filter((c) => selectedCategories.indexOf(c.label) > -1)
    .map((c) => c.path)
  return data.filter((item) =>
    selectedPaths.some(
      (p) =>
        item[p] &&
        item[p]
          .toString()
          .toLowerCase()
          .startsWith(searchQuery.toString().toLowerCase()),
    ),
  )
}
export function tableSearch(data, searchQuery) {
  return data.filter((m) => hasValue(m, searchQuery))
}
export function getPagedData(
  data,
  searchQuery,
  sortColumn,
  selectedCategories,
  columns,
  currPage,
  pageSize,
) {
  let manipulatedArr = data
  if (searchQuery) {
    manipulatedArr =
      selectedCategories.length > 0
        ? tagSearch(selectedCategories, columns, data, searchQuery)
        : tableSearch(data, searchQuery)
  }
  if (sortColumn && sortColumn.path) {
    const colSorter = [
      (data) =>
        typeof data[sortColumn.path] === 'string'
          ? data[sortColumn.path].toLowerCase()
          : data[sortColumn.path],
    ]
    manipulatedArr = _.orderBy(manipulatedArr, colSorter, [sortColumn.order])
  }
  const count = manipulatedArr.length
  // const result = [...paginate(manipulatedArr, currPage, pageSize)]
  const result = [...manipulatedArr]
  return { result, count }
}

export async function getAllData(selectedTags = []) {
  if (selectedTags.length) {
    const arr = selectedTags.map((t) => {
      if (t === 'INCIDENTS') return getIncidents()
      if (t === 'CLAIMS') return getClaims()
      if (t === 'PROPERTIES') return getProperties()
      if (t === 'EMPLOYEES') return getEmployees()
      if (t === 'VEHICLES') return getVehicles()
      if (t === 'POLICIES') return getPolicies()
    })
    return Promise.all(arr)
  } else {
    return Promise.all([
      getIncidents(),
      getClaims(),
      getPolicies(),
      getEmployees(),
      getVehicles(),
      getProperties(),
    ])
  }
}

export function groupData(allData = []) {
  const keyArr = [
    'incidentId',
    'claimId',
    'propertyId',
    'employeeId',
    'policyId',
    'vehicleId',
  ]
  return allData.reduce((acc, curr) => {
    keyArr.forEach((k) => {
      if (Object.keys(curr).includes(k)) {
        if (acc.hasOwnProperty(`${k}s`))
          acc[`${k}s`].push({ id: curr['_id'], linkTitle: curr[k] })
        else acc[`${k}s`] = [{ id: curr['_id'], linkTitle: curr[k] }]
      }
    })
    return acc
  }, {})
}

export function getFormattedAddress(obj) {
  if (!obj) return ''
  return (
    <>
      {obj?.address?.line1 && obj?.address?.line1 + ','}
      <br /> {obj?.address?.line2 && obj?.address?.line2 + ','}
      <br /> {obj?.address?.city && obj?.address?.city + ','}
      <br />
      {obj?.address?.state && obj?.address?.state + ','}
      {obj?.address?.country && obj?.address?.country + '-'}
      {obj?.address?.pinCode}
    </>
  )
}

export function csvToJSON(module, file) {
  const formData = new FormData()
  formData.append('csv', file)
  return http.post(`${apiEndpoint}/csv-to-json?module=${module}`, formData)
}

export function importDataApi(module, data) {
  return http.post(
    `${apiUrl}/${module}/import`,
    data,
  )
}
