import React, { useState } from 'react'

import { toast } from 'react-toastify'
import { sendResetpasswordLink } from '../../services/authService'
import { useHistory } from 'react-router-dom'

export default function ForgotPassword() {

  let history = useHistory()
  const [email, setEmail] = useState('')

  const [isLinkSent, setIsLinkSent] = useState(false)

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const sendForgotPasswordLink = async () => {
    if (!email?.trim()) {
      toast.error('Email is required.')
      return
    }
    if (!validateEmail(email)) {
      toast.error('Email is invalid')
      return
    }
    try {
      const res = await sendResetpasswordLink({ email })
      setIsLinkSent(true)
    } catch (error) {
      toast.error(error?.response?.data?.msg)
    }
  }

  const goBackToSignIn = () => {
    history.push('/login')
  }


  return (
    <div className='m-content'>
      <div className='container'>
        <div className='col-lg-12'>
          <div className='text-center'>
            <div className='mt-3 mb-4'>
              <img src='/assets/images/logo.png' alt='acuity' />
            </div>
            <h1 className='wel_back'>Welcome Back!</h1>
            <h6 className='login-acu mt-3 mb-5'>Forgot your account credentials to view your
              space</h6>
          </div>
          {
            !isLinkSent ? <div className='m-portlet'>
              <div className='m-portlet__head text-center'>
                <h3>Forgot Password</h3>
              </div>
              <div className='m-form m-form--fit'>
                <div className='form-group m-form__group row'>
                  <div className='col-lg-12'>
                    <label className='form-control-label'> </label>
                    <input onChange={({ currentTarget }) => setEmail(currentTarget.value)}
                           type='email' className='form-control border-radius-0'
                           placeholder='Enter your email' required />
                  </div>
                </div>
                <div className='form-group m-form__group row'>
                  <div className='col-lg-12 d-flex'>
                    <div style={{ paddingBottom: '15px' }}>
                      <button onClick={sendForgotPasswordLink} type='submit'
                              className='btn btn-primary custom-btn'>Submit
                      </button>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <button onClick={goBackToSignIn} className='btn btn-primary custom-btn'>Go
                        Back to Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> : <>
              <h3 className='text-center'>Reset Link is sent to your email!</h3>
            </>

          }
        </div>
      </div>
    </div>
  )
}
