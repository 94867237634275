import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { handleSideNavDataUpdated, handleTabIdUpdated } from '../../../actions'
import ActionWidget from '../../../components/common/ActionWidget'
import ConfirmationModal from '../../../components/common/ConfirmationModal'
import Loader from '../../../components/common/Loader'
import Table from '../../../components/common/Table'
import { getPagedData } from '../../../services/helperService'
import {
  deleteBlukUser,
  deleteUser,
  getUsers
} from '../../../services/userService'
let users = []

let itemsCount = 0
function UserList({
  searchQuery,
  handleEdit,
  handleView,
  context,
  checkedRecords,
  setShowDeleteButton,
  handleElementCheckboxClicked,
  setSelectFilter,
  selectFilters,
  openBulkDel,
  handleDechecked,
  closeBulkDel
}) {
  let dispatch = useDispatch()
  const selectedClientId = useSelector((state) => state.selectedClientId)
  const [deleteOne , setDeleteOne] = useState(false);
  const [isLoading , setIsLoading] = useState(true);
  
  const columns = [
    {
      path: 'userId',
      label: ' User ID ',
      minWidth: '134px',
      width: '240px',
      content: (user) => (
        <>
          <a>{user.userId}</a>
          <ActionWidget
            module="USER"
            onEdit={() => handleEdit(user)}
            onDelete={() => {
              setUserInfo(user);
              setDeleteOne(true);
            }}
            onView={() => {
              handleView(user)
            }}
          />
        </>
      ),
    },
    {
      path: 'firstName',
      label: ' Name ',
      type: 'string',
      content: (user) => (
        <>
          <span>
            {user.firstName} {user.lastName}
          </span>
        </>
      ),
    },
    { path: 'contactNumber', label: ' Contact Number ', type: 'phone' },
    { path: 'email', label: ' Email ID ' },
    { path: 'dateOfBirth', label: ' DOB ', type: 'date' },
    { path: 'designation', label: ' Designation ' },
    { path: 'roleId.roleName', label: ' Role ' },
  ]
  const categories = columns
    .filter((c) => c.type !== 'date')
    .map((col) => col.label)
  const [sortColumn, setSortColumn] = useState(null)
  const [currPage, setCurrPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [data, setData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])

  const [userInfo, setUserInfo] = useState({})

  const { action: viewAction, data: viewData } = useSelector(
    (state) => state.viewAction || {},
  )
  const selectedTabId = useSelector((state) => state.selectedTabId)
  const sideNavDataContext = useSelector((state) => state.sideNavDataContext)
  useEffect(() => {
    setIsLoading(true)
    fetchList()
  }, [context, selectedClientId])

  useEffect(() => {
    setShowDeleteButton(checkedRecords.length > 0)
  }, [checkedRecords])

  useEffect(() => {      
    if (openBulkDel) {
      setUserInfo({ userInfo: { firstName: 'name', lastName: 'lastname' } })
    }
  }, [openBulkDel])

  const fetchList = async () => {
    const { data } = await getUsers()
    let userData = data.data.map((user)=> {
      user.fullName = `${user.firstName} ${user.lastName}`
      return user
    })
    setData(userData)
    if (sideNavDataContext !== 'searchView') {
      dispatch(handleSideNavDataUpdated({ data: data.data[0], module: 'user' }))
      dispatch(handleTabIdUpdated('user'))
    }

    const designationList = {},
      roleList = {}

    data.data.forEach(({ designation, roleId }) => {
      if (designation !== '') {
        designationList[designation] = {
          key: designation,
          value: designation,
        }
      }
      if (roleId.roleName !== '') {
        roleList[roleId.roleName] = {
          key: roleId.roleName,
          value: roleId.roleName,
        }
      }
    })

    setSelectFilter(
      Object.values(designationList || {}),
      Object.values(roleList || {}),
    )
    setIsLoading(false)
  }

  useEffect(() => {
    if (
      selectedTabId === 'user' &&
      viewAction === 'edit' &&
      Object.keys(viewData).length > 0
    ) {
      handleEdit(viewData)
    }
    if (selectedTabId === 'user' && viewAction === 'refresh') {
      setIsLoading(true)
      fetchList()
      dispatch(handleTabIdUpdated('user'))
    }
  }, [viewAction, viewData, selectedTabId])

  const getPageData = () => {
    const { count, result } = getPagedData(
      data,
      searchQuery,
      sortColumn,
      selectedCategories,
      columns,
      currPage,
      pageSize,
    )
    itemsCount = count
    users = result
    return result.length > 0
  }

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn)
  }

  const handleDeleteModal = async () => {   
        try {
      if (checkedRecords.length > 0) {
        let params = { ids: checkedRecords }        
        const { data } =  await deleteBlukUser(params);                        
        setShowDeleteButton(false)
        closeBulkDel()
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
      } else {
        const { data } = await deleteUser(userInfo._id, userInfo)
        if (data && data.msg === 'Success') {
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setDeleteOne(false);
      }
    } catch (error) {
      console.log('error', error)
    }
    setUserInfo({})
    await fetchList()
  }
  
  if(isLoading) return( <div style={{marginTop: "120px"}} ><Loader loading={isLoading}/></div>)

  return (
    <>
      {getPageData()}
      <Table
        columns={columns}
        data={users.filter((userData) => {
          const { designation, roleId } = userData
          const {
            designation: selectedDesignation = '',
            role: selectedRoleId = '',
          } = selectFilters

          let isDesignation = false,
            isRoleId = false

          if (
            selectedDesignation.length &&
            selectedDesignation.includes(designation)
          ) {
            isDesignation = true
          }
          if (
            selectedRoleId.length &&
            selectedRoleId.includes(roleId.roleName)
          ) {
            isRoleId = true
          }
          return (
            !(!!selectedDesignation.length ^ isDesignation) &&
            !(!!selectedRoleId.length ^ isRoleId)
          )
        })}
        sortColumn={sortColumn}
        onSort={handleSort}
        checkedItems={checkedRecords}
        checkboxClick={handleElementCheckboxClicked.bind(this, users)}
      />
      <ConfirmationModal
        name={checkedRecords.length > 0 ? `Users` : (userInfo?.firstName || '') + ' ' + (userInfo?.lastName || '')}
        // isOpen={checkedRecords.length > 0 || Object.keys(userInfo || {}).length > 0}
      
        isOpen={Object.keys(userInfo || {}).length > 0 && (checkedRecords.length > 0  || deleteOne) }
        onClose={() => {
          setShowDeleteButton(false)
          closeBulkDel();
          setDeleteOne(false);
          handleDechecked()
          setUserInfo({})
        }}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}

export default UserList
