import React, { useState } from 'react';
import { useShowModule } from '../../hooks/useShowModule';
import Accidents from './components/charts/accidents';
import AccidentsPaidVSIncurred from './components/charts/accidentsPaidvsIncurred';
import DashboardDateRangePicker from './components/charts/bootstrapdateRangePicker';
import ClaimsIncurred from './components/charts/claimsIncurred';
import CountBar from './components/charts/countBar';
import Employees from './components/charts/employees';
import OpenAndClosedClaims from './components/charts/open&ClosedClaims';
import PolicyRenewal from './components/charts/PolicyRenewal';
import Vehicles from './components/charts/vehicles';

function Dashboard() {
  const [showDashboard, setShowDashboard] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState('')

  const { showModule } = useShowModule()

  const getSelectedFilter = () => {
    switch (selectedFilter) {
      case 'accidents':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <Accidents />
            </div>
          </div>
        )
      case 'accidentsPaidvsIncurred':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <AccidentsPaidVSIncurred />
            </div>
          </div>
        )
      case 'openAndClosedAlaims':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <OpenAndClosedClaims />
            </div>
          </div>
        )
      case 'claimsIncurred':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <ClaimsIncurred />
            </div>
          </div>
        )
      case 'employees':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <Employees />
            </div>
          </div>
        )
      case 'vehicles':
        return (
          <div className="row">
            <div className='flex-grow-1 p-2'>
              <Vehicles />
            </div>
          </div>
        )

      default:
        break;
    }
  }


  return (
    showDashboard && (
      <div
        className="px-4 pl-6 mt-5 dashboard-scroll"
        style={{ paddingBottom: '40px' }}
      >
        <div className={'container-fluid'}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between py-3 align-items-center mb-3">
                <div>
                  <h1 className="mb-3">Dashboard</h1>
                </div>
                <div>
                  <DashboardDateRangePicker setSelectedFilter={setSelectedFilter}></DashboardDateRangePicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CountBar></CountBar>
        {selectedFilter === '' &&
          <>
            {showModule(['incident']) &&
              <div className="row">
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <Accidents />
                </div>
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <AccidentsPaidVSIncurred />
                </div>
              </div>
            }
            {showModule(['claim']) &&
              <div className="row">
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <OpenAndClosedClaims />
                </div>
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <ClaimsIncurred />
                </div>
              </div>
            }
            <div className="row">
              {showModule(['policy']) &&
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <PolicyRenewal />
                </div>
              }
              {showModule(['employee']) &&
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <Employees />
                </div>
              }
              {/* {showModule(['vehicle']) &&
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <VehicleStatus />
                </div>
              } */}
            </div>
            <div className="row">

              {showModule(['vehicle']) &&
                <div className='col-6 col-md-6 col-lg-6 col-xxl-6 mb-2'>
                  <Vehicles />
                </div>
              }
            </div>
          </>
        }

        {selectedFilter !== '' &&
          getSelectedFilter()
        }
      </div>
    )
  )
}

export default Dashboard
