import React, { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { applyViewAction, handleSideNavElementClicked } from '../actions'
import VehicleRecordInfo from './sideNavigation/VehicleRecordInfo'
import PropertyRecordInfo from './sideNavigation/PropertyRecordInfo'
import EmployeesRecordInfo from './sideNavigation/EmployeesRecordInfo'
import UserRecordInfo from './sideNavigation/UserRecordInfo'
import ClientRecordInfo from './sideNavigation/ClientRecordInfo'
import AttachmentRecordInfo from './sideNavigation/AttachmentRecordInfo'
import DashboardRecordInfo from './sideNavigation/DashboardRecordInfo'
import PolicyRecordInfo from './sideNavigation/PolicyRecordInfo'
import ThirdPartyRecordInfo from './sideNavigation/ThirdPartyRecordInfo'
import ClaimRecordInfo from './sideNavigation/ClaimRecordInfo'
import IncidentRecordInfo from './sideNavigation/IncidentRecordInfo'
import MVRRecordInfo from './sideNavigation/MVRRecordInfo'
import CommentSection from './CommentSection'
import CalendarSection from './sideNavigation/CalendarSection'
import ConfirmationModal from './common/ConfirmationModal'
import { deleteClaim } from '../services/claimService'
import { deleteIncident } from '../services/incidentService'
import { deleteUser } from '../services/userService'
import { deleteEmployee } from '../services/employeeService'
import { deleteVehicle } from '../services/vehicleService'
import { deleteProperty } from '../services/propertyService'
import { deletePolicy } from '../services/policyService'
import { deleteClient } from '../services/clientService'
import HistoryRecordInfo from './sideNavigation/HistoryRecordInfo'
import { useGetPermission } from '../hooks/useGetPermission'
import { getModuleByTabId } from '../utils/getModuleByTabId'
import PublicIncidentRecordInfo from './sideNavigation/PublicIncidentRecordInfo'

export default function RightSidebar() {
  let view = null
  const dispatch = useDispatch()
  const [isDelete, setDelete] = useState(false)
  const tabId = useSelector((state) => state.selectedTabId)
  const data = useSelector((state) => state.sideNavData)
  const selectedSideNavId = useSelector((state) => state.selectedSideNavId)
  const location = useLocation()

  const history = useGetPermission(getModuleByTabId(tabId), 'HISTORY')

  if (data && Object.keys(data).length > 0) {
    if (selectedSideNavId === 'info') {
      switch (tabId) {
        case 'employee':
          view = (
            <EmployeesRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'property':
          view = (
            <PropertyRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'vehicle':
          view = (
            <VehicleRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'client':
          view = (
            <ClientRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'user':
          view = (
            <UserRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'dashboard':
          view = <DashboardRecordInfo />
          break
        case 'policy':
          view = (
            <PolicyRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'thirdParty':
          view = <ThirdPartyRecordInfo />
          break
        case 'claim':
          view = (
            <ClaimRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'incident':
          view = (
            <IncidentRecordInfo
              onEdit={() => {
                handleSideNavButtonClicked('close')
                dispatch(applyViewAction('edit', data))
              }}
              onDelete={() => {
                setDelete(true)
              }}
            />
          )
          break
        case 'public-incident':
          view = (
            <PublicIncidentRecordInfo
            // onEdit={() => {
            //   handleSideNavButtonClicked('close')
            //   dispatch(applyViewAction('edit', data))
            // }}
            // onDelete={() => {
            //   setDelete(true)
            // }}
            />
          )
          break
        case 'mvr':
          view = <MVRRecordInfo />
          break
        default:
          view = <div className={'slide-sidebar mt-5'}>Nothing To Show</div>
      }
    } else if (
      selectedSideNavId === 'attachment' &&
      data &&
      Object.keys(data).length > 0
    ) {
      view = <AttachmentRecordInfo />
    } else if (
      selectedSideNavId === 'comment' &&
      data &&
      Object.keys(data).length > 0
    ) {
      view = <CommentSection />
    } else if (
      selectedSideNavId === 'calendar' &&
      data &&
      Object.keys(data).length > 0
    ) {
      view = <CalendarSection />
    } else if (
      selectedSideNavId === 'history' &&
      data &&
      Object.keys(data).length > 0
    ) {
      view = <HistoryRecordInfo />
    } else {
      view = <div className={'slide-sidebar mt-5'}>Nothing To Show</div>
    }
  }

  const handleDeleteModal = async () => {
    try {
      if (selectedSideNavId === 'info') {
        let responseData = {}
        if (tabId === 'claim') {
          responseData = await deleteClaim(data._id, data)
        } else if (tabId === 'incident') {
          responseData = await deleteIncident(data._id, data)
        } else if (tabId === 'user') {
          responseData = await deleteUser(data._id, data)
        } else if (tabId === 'employee') {
          responseData = await deleteEmployee(data._id, data)
        } else if (tabId === 'vehicle') {
          responseData = await deleteVehicle(data._id, data)
        } else if (tabId === 'property') {
          responseData = await deleteProperty(data._id, data)
        } else if (tabId === 'policy') {
          responseData = await deletePolicy(data._id, data)
        } else if (tabId === 'client') {
          responseData = await deleteClient(data._id, data)
        }
        if (
          responseData.data &&
          responseData.data.msg &&
          responseData.data.msg === 'Success'
        ) {
          handleSideNavButtonClicked('close')
          dispatch(applyViewAction('refresh', ''))
          toast.success('Deletion Successful!')
        } else {
          toast.error('Error: Delete Unsuccessful!')
        }
        setDelete(false)
      }
    } catch (error) {
      console.error({ error })
      toast.error('Error: Delete Unsuccessful!')
    }
  }

  const getEntityName = useCallback(() => {
    if (selectedSideNavId === 'info' && data) {
      if (tabId === 'claim') {
        return `ClaimId ${data?.claimId}` || ''
      } else if (tabId === 'incident') {
        return (data?.firstName || '') + ' ' + (data?.lastName || '')
      } else if (tabId === 'user' || tabId === 'employee') {
        return (data?.firstName || '') + ' ' + (data?.lastName || '')
      } else if (tabId === 'vehicle') {
        return data?.vehicleId ? 'Vehicle ID ' + data?.vehicleId || '' : ''
      } else if (tabId === 'property') {
        return data?.name || ''
      } else if (tabId === 'policy') {
        return data?.policyId ? 'Policy Id ' + data?.policyId || '' : ''
      }
    }
  }, [selectedSideNavId, tabId, data])

  const handleSideNavButtonClicked = (id) => {
    dispatch(handleSideNavElementClicked(id))
  }

  useEffect(() => {
    handleSideNavButtonClicked('close')
  }, [location.pathname])
  return (
    <>
      <aside id="sidebarright" className="scroll-change-side">
        <nav className="nav flex-column justify-content-center align-items-center">
          <div className="mt-5" />
          <a
            aria-current="page"
            className={
              'nav-link mt-5 sidebar p-0' +
              (selectedSideNavId === 'info' ? ' active' : '')
            }
            onClick={handleSideNavButtonClicked.bind(this, 'info')}
          >
            <svg
              id="info"
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={23}
              viewBox="0 0 24 23"
            >
              <path
                id="Path_17002"
                data-name="Path 17002"
                d="M12,0A11.758,11.758,0,0,0,0,11.5,11.758,11.758,0,0,0,12,23,11.758,11.758,0,0,0,24,11.5,11.771,11.771,0,0,0,12,0Zm0,21.083A9.8,9.8,0,0,1,2,11.5,9.8,9.8,0,0,1,12,1.917,9.8,9.8,0,0,1,22,11.5,9.809,9.809,0,0,1,12,21.083Z"
                fill="#207be3"
              />
              <path
                id="Path_17003"
                data-name="Path 17003"
                d="M12,10H11a1,1,0,0,0,0,2h1v6a1,1,0,0,0,2,0V12A2,2,0,0,0,12,10Z"
                transform="translate(0 -0.667)"
                fill="#207be3"
              />
              <circle
                id="Ellipse_738"
                data-name="Ellipse 738"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(10.5 4.762)"
                fill="#207be3"
              />
            </svg>
          </a>
          <a
            aria-current="page"
            className={
              'nav-link mt-3 sidebar p-0' +
              (selectedSideNavId === 'calendar' ? ' active' : '')
            }
            onClick={handleSideNavButtonClicked.bind(this, 'calendar')}
          >
            <svg
              id="Group_9748"
              data-name="Group 9748"
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height="21.167"
              viewBox="0 0 25 21.167"
            >
              <rect
                id="Rectangle_3360"
                data-name="Rectangle 3360"
                width={2}
                height={3}
                transform="translate(11.5)"
                fill="#3486e4"
              />
              <rect
                id="Rectangle_3361"
                data-name="Rectangle 3361"
                width={2}
                height={3}
                transform="translate(4.5)"
                fill="#3486e4"
              />
              <rect
                id="Rectangle_3362"
                data-name="Rectangle 3362"
                width={2}
                height={3}
                transform="translate(18.5)"
                fill="#3486e4"
              />
              <path
                id="Path_16936"
                data-name="Path 16936"
                d="M13,24a1,1,0,0,1-1-1V18.62l-.55.27a1,1,0,0,1-.9-1.78l2-1A1,1,0,0,1,14,17v6A1,1,0,0,1,13,24Z"
                transform="translate(-7.565 -6)"
                fill="#3486e4"
              />
              <path
                id="Path_16937"
                data-name="Path 16937"
                d="M20,24H18a2,2,0,0,1-2-2V18a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v4A2,2,0,0,1,20,24Zm-2-6v4h2V18Z"
                transform="translate(-7.565 -6)"
                fill="#3486e4"
              />
              <g
                id="Group_9747"
                data-name="Group 9747"
                transform="translate(0 2)"
              >
                <path
                  id="Path_16934"
                  data-name="Path 16934"
                  d="M21.833,25.167h-20A.833.833,0,0,1,1,24.333V7.667A1.667,1.667,0,0,1,2.667,6H24.333A1.667,1.667,0,0,1,26,7.667V21a.833.833,0,0,1-.242.592l-3.333,3.333A.833.833,0,0,1,21.833,25.167ZM2.667,23.5H21.492l2.842-2.842V7.667H2.667ZM24.333,7.667Z"
                  transform="translate(-1 -6)"
                  fill="#3486e4"
                />
                <path
                  id="Path_16935"
                  data-name="Path 16935"
                  d="M25.167,13.995H1.833A.926.926,0,0,1,1,13a.926.926,0,0,1,.833-1H25.167A.926.926,0,0,1,26,13,.926.926,0,0,1,25.167,13.995Z"
                  transform="translate(-1 -7.094)"
                  fill="#3486e4"
                />
                <path
                  id="Path_16938"
                  data-name="Path 16938"
                  d="M29.989,24,26,27.989V24Z"
                  transform="translate(-5.986 -9.82)"
                  fill="#3486e4"
                />
                <path
                  id="Path_16939"
                  data-name="Path 16939"
                  d="M26,28.984a.838.838,0,0,1-.379-.08A1,1,0,0,1,25,27.986V24a1,1,0,0,1,1-1h3.989a1,1,0,0,1,.708,1.705l-3.989,3.989a1,1,0,0,1-.708.289Zm1-3.989v.588l.588-.588Z"
                  transform="translate(-5.984 -9.817)"
                  fill="#3486e4"
                />
              </g>
            </svg>
          </a>
          <a
            aria-current="page"
            className={
              'nav-link mt-3 sidebar p-0 ' +
              (selectedSideNavId === 'comment' ? ' active' : '')
            }
            onClick={handleSideNavButtonClicked.bind(this, 'comment')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 20 20"
            >
              <path
                id="Path_16929"
                data-name="Path 16929"
                d="M23.99,6A1.991,1.991,0,0,0,22,4H6A2,2,0,0,0,4,6V18a2,2,0,0,0,2,2H20l4,4ZM20,16H8V14H20Zm0-3H8V11H20Zm0-3H8V8H20Z"
                transform="translate(-4 -4)"
                fill="#3184e5"
              />
            </svg>
          </a>
          {history && (
            <a
              aria-current="page"
              className={
                'nav-link mt-3 sidebar p-0 ' +
                (selectedSideNavId === 'history' ? ' active' : '')
              }
              onClick={handleSideNavButtonClicked.bind(this, 'history')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height="21.429"
                viewBox="0 0 25 21.429"
              >
                <path
                  id="Path_16932"
                  data-name="Path 16932"
                  d="M16.28,6A10.706,10.706,0,0,0,5.571,16.714H2l4.637,4.637.083.173,4.8-4.81H7.952A8.353,8.353,0,1,1,10.4,22.6L8.714,24.286A10.712,10.712,0,1,0,16.28,6ZM15.1,11.952V17.9l5.1,3.024.857-1.446-4.167-2.47v-5.06Z"
                  transform="translate(-2 -6)"
                  fill="#207be3"
                  opacity="0.9"
                />
              </svg>
            </a>
          )}
          <a
            aria-current="page"
            className={
              'nav-link mt-3 sidebar p-0 ' +
              (selectedSideNavId === 'attachment' ? ' active' : '')
            }
            onClick={handleSideNavButtonClicked.bind(this, 'attachment')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.723"
              height="21.503"
              viewBox="0 0 20.723 21.503"
            >
              <path
                id="clip"
                d="M20.567,8.6a.9.9,0,0,0-1.266,0L9.545,18.4a4.479,4.479,0,0,1-6.334-6.333l9.448-9.487a2.687,2.687,0,1,1,3.8,3.8L7.011,15.862a.915.915,0,0,1-1.266,0,.9.9,0,0,1,0-1.266l8.412-8.451A.9.9,0,1,0,12.89,4.878L4.478,13.329a2.687,2.687,0,0,0,0,3.8,2.752,2.752,0,0,0,3.8,0l9.448-9.488a4.478,4.478,0,1,0-6.334-6.333L1.944,10.8a6.27,6.27,0,0,0,8.867,8.867l9.756-9.8a.9.9,0,0,0,0-1.27Z"
                transform="translate(-0.108 0.003)"
                fill="#3486e5"
              />
            </svg>
          </a>
          <a
            className="arrow-icon sidebar p-0"
            onClick={handleSideNavButtonClicked.bind(this, 'arrow')}
          >
            <div className="d-flex align-items-center justify-content-center">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                >
                  <g
                    id="Group_10309"
                    data-name="Group 10309"
                    transform="translate(-1864 -993)"
                  >
                    <rect
                      id="Rectangle_3355"
                      data-name="Rectangle 3355"
                      width={48}
                      height={48}
                      rx={24}
                      transform="translate(1864 993)"
                      fill="#f4f7fe"
                    />
                    <g
                      id="Group_7973"
                      data-name="Group 7973"
                      transform="translate(1875 1031) rotate(-90)"
                    >
                      <rect
                        id="Rectangle_2724"
                        data-name="Rectangle 2724"
                        width={27}
                        height={27}
                        transform="translate(0)"
                        fill="none"
                      />
                      <path
                        id="Path_16705"
                        data-name="Path 16705"
                        d="M9.191,11.7,0,2.408,2.383,0,9.191,6.881,16,0l2.383,2.408Z"
                        transform="translate(4.44 7.52)"
                        fill="#747c86"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </a>
        </nav>
      </aside>
      {view}
      <ConfirmationModal
        name={getEntityName()}
        isOpen={isDelete}
        onClose={() => setDelete(false)}
        onConfirm={handleDeleteModal}
      />
    </>
  )
}
