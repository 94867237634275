import http from './httpService'
import apiUrl from '../configs/api'
// import store from "../store";

const apiEndpoint = `${apiUrl}/policy`

export function getPolicies() {
  return http.get(`${apiEndpoint}/get-policy-list`)
}

export function exportPolicies() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getPoliciesByType(type) {
  return http.get(`${apiEndpoint}/get-policy-by-filter`, {
    params: { policyType: type },
  })
}

export function getPolicy(id) {
  return http.get(`${apiEndpoint}/get-policy/${id}`)
}

export function updatePolicy(id, policy) {
  return http.put(`${apiEndpoint}/update-policy/${id}`, policy)
}

export function deletePolicy(id, policy) {
  return http.put(`${apiEndpoint}/delete-policy/${id}`, policy)
}

export function deleteBlukPolicy(ids) {    
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}

export function savePolicy(policy) {
  // const policyId = `${policy.insuranceCarrier
  //   .slice(0, 2)
  //   .toUpperCase()}-${policy.policyType.slice(0, 2).toUpperCase()}-${Date.now()
  //   .toString()
  //   .slice(-4)}`
  return http.post(`${apiEndpoint}/save`, { ...policy })
}

export function getPolicyByPolicyType() {
  return http.get(`${apiEndpoint}/get-policy-by-group?id=policyType`)
}

export function getPolicyByInsuranceCarrier() {
  return http.get(`${apiEndpoint}/get-policy-by-group?id=insuranceCarrier`)
}

export function getPoliciesByGroup(propId, time) {
  return http.get(`${apiEndpoint}/property-value-change/${propId}`, {
    params: { id: time },
  })
}

export function getPoliciesRenewal() {
  return http.get(`${apiEndpoint}/policy-renewal-alerts`)
}
