import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/client`

export function getClients() {
  return http.get(`${apiEndpoint}/get-client-list`)
}

export function exportClients() {
  return http.get(`${apiEndpoint}/export-csv`, { responseType: 'blob' })
}

export function getClient(id) {
  return http.get(`${apiEndpoint}/get-client/${id}`)
}

export function getUserClients() {
  return http.get(`${apiEndpoint}/get-clients-for-user`)
}

export function updateClient(id, client) {
  return http.put(`${apiEndpoint}/update-client/${id}`, client)
}
export function deleteClient(id, client) {
  return http.put(`${apiEndpoint}/delete-client/${id}`, client)
}

export function deleteBlukClient(ids) {  
  return http.put(`${apiEndpoint}/bulk-delete` , ids);
}


export function saveClient(client) {
  // const date = new Date()
  // const clientId = `CLN-${date.getFullYear()}-${(date.getMonth() + 1)
  //   .toString()
  //   .padStart(2, 0)}-${date.getMilliseconds()}`
  return http.post(`${apiEndpoint}/save`, { ...client })
}
