import classNames from 'classnames'
import { ErrorMessage, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

// import AddAttachment from '../../components/addAttachment'
import AddTransactionModalBtn from '../../components/AddTransactionModalBtn'
import AttachmentAddView from '../../components/AttachmentAddView'
import AreYouSureModal from '../../components/common/AreYouSureModal'
import DatePickerField from '../../components/form/DatePickerField'
import Select from '../../components/form/Select'
import TextArea from '../../components/form/TextArea'
import TextInput from '../../components/form/TextInput'
import { savePolicy, updatePolicy } from '../../services/policyService'
import { POLICY_VALIDATION_SCHEMA } from '../../utils/validation'
import { addComma, addCommaTableField } from '../incident/utils'
import { allInsuranceCarriers, allPolicyTypes } from './utils/constants'
import { NO_TIMEZONE_DATE_FORMAT } from '../../utils/helper'

const newPolicy = () => ({
  // policyId: generateUID('policy'),
  policyId: '',
  insuranceCarrier: '',
  policyType: '',
  policyDate: null,
  premiumRenewalDate: null,
  premiums: [],
  claims: [],
  attachments: [],
  sumInsured: '',
  deducibleField: '',
  // aggregateLimit: '',
  nextAction: '',
  broker: '',
  policyNotes: '',
  clientId: '',
  status: false,
})

function PolicyForm({ data, onClose, title }) {
  const ref = useRef()
  const [isNew, setIsNew] = useState(false)
  const clients = useSelector((state) => state.clients)
  const selectedClientId = useSelector((state) => state.selectedClientId)

  const isClientExist = useMemo(() => {
    return (
      !isNew ||
      (selectedClientId &&
        selectedClientId.length &&
        selectedClientId !== 'allClients') ||
      Object.keys(clients).length === 0
    )
  }, [isNew, selectedClientId, clients])
  const [values, setValues] = useState(
    data
      ? data
      : {
          ...newPolicy(),
          clientId:
            isClientExist &&
            selectedClientId &&
            selectedClientId !== 'allClients'
              ? selectedClientId
              : '',
        },
  )
  const [openModal, setOpenModal] = useState(false)
  // const [openAttachmentModal, setOpenAttachmentModal] = useState(false)

  const closeModal = () => setOpenModal(false)

  const handleSubmit = async (values) => {
    setValues(values)
    setOpenModal(true)
  }

  const handleSubmitModal = async () => {
    setOpenModal(false)
    try {
      // Deep clone values.
      const modifiedVals = JSON.parse(JSON.stringify(values ?? {}))
      modifiedVals.policyDate = modifiedVals.policyDate
        ? moment(modifiedVals.policyDate).format(NO_TIMEZONE_DATE_FORMAT)
        : null
      modifiedVals.premiumRenewalDate = modifiedVals.premiumRenewalDate
        ? moment(modifiedVals.premiumRenewalDate).format(
            NO_TIMEZONE_DATE_FORMAT,
          )
        : null

      if (modifiedVals.claims && modifiedVals.claims.length > 0) {
        modifiedVals.claims.forEach((claim) => {
          claim.transactionDate = claim.transactionDate
            ? moment(claim.transactionDate).format(NO_TIMEZONE_DATE_FORMAT)
            : null
        })
      }

      if (modifiedVals.premiums && modifiedVals.premiums.length > 0) {
        modifiedVals.premiums.forEach((premium) => {
          premium.transactionDate = premium.transactionDate
            ? moment(premium.transactionDate).format(NO_TIMEZONE_DATE_FORMAT)
            : null
        })
      }

      if (isNew) {
        await savePolicy(modifiedVals)
        toast.success('Policy Added!')
      } else {
        await updatePolicy(modifiedVals._id, modifiedVals)
        toast.success('Policy Updated!')
      }
    } catch (err) {
      toast.error(err)
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (data) {
      setIsNew(false)
      setValues(data)
    } else {
      setIsNew(true)
      // setValues(newPolicy())
    }
  }, [isNew])

  // const handleAddAttachmentToggle = () => {
  //   setOpenAttachmentModal(!openAttachmentModal)
  // }

  // const onAttachmentUpload = (file) => {
  //   handleAddAttachmentToggle()
  //   onArrayUpdate('attachments', file)
  // }

  const onArrayUpdate = (key, val) => {
    let updatedValues = ref.current?.values || {}
    if (updatedValues[key]) {
      if (key === 'attachments') {
        updatedValues[key].push(val[0])
      } else {
        updatedValues[key].push(val)
      }
      setValues({ ...updatedValues })
    }
  }

  let handleDeleteCard = (key, index) => {
    values[key].splice(index, 1)
    let val = { ...values }
    setValues(val)
  }

  let getClaimViews = () => {
    let claimViews = []

    values?.claims?.forEach(function (claim, index) {
      claimViews.push(
        <div className="parties price bg-white p-3 mb-3 col-md-4" key={index}>
          <div
            onClick={handleDeleteCard.bind(this, 'claims', index)}
            className="x-btn"
          >
            ×
          </div>
          <h6 className="text-primery">
            {claim.transactionDate
              ? moment(claim.transactionDate).format('MM/DD/YYYY')
              : 'N/A'}
          </h6>
          <small className="mb-2">
            ${claim.amount ? addCommaTableField(claim.amount) : 'N/A'}
          </small>
        </div>,
      )
    })

    return claimViews
  }

  let getPremiumViews = () => {
    let premiumViews = []

    values?.premiums?.forEach(function (premium, index) {
      premiumViews.push(
        <div className="parties price bg-white p-3 mb-3 col-md-4" key={index}>
          <div
            onClick={handleDeleteCard.bind(this, 'premiums', index)}
            className="x-btn"
          >
            ×
          </div>
          <h6 className="text-primery">
            {premium.transactionDate
              ? moment(premium.transactionDate).format('MM/DD/YYYY')
              : 'N/A'}
          </h6>
          <small className="mb-2">
            ${premium.amount ? addCommaTableField(premium.amount) : 'N/A'}
          </small>
        </div>,
      )
    })

    return premiumViews
  }

  // let getAttachmentViews = () => {
  //   let attachmentViews = []
  //   values?.attachments?.forEach(function (xmlns, index) {
  //     let xmlnsString = xmlns.toString()
  //     let xmlnsArray = xmlnsString.toString().split('/')
  //     let fileName = xmlnsArray[xmlnsArray.length - 1]

  //     let isFileImage = fileName.match(/\.(jpg|jpeg|png|gif)$/)
  //     attachmentViews.push(
  //       <div className="col-md-6" key={index}>
  //         <div className="attachment">
  //           <div className="attach-img d-flex justify-content-center align-items-center ">
  //             <a
  //               target="_blank"
  //               href={xmlns}
  //               rel="noreferrer"
  //               role="button"
  //               className="btn btn-primary btn-down"
  //             >
  //               Download
  //             </a>
  //             <div className="text-center">
  //               {isFileImage ? (
  //                 <object
  //                   style={{
  //                     width: '100%',
  //                     height: '100%',
  //                     objectFit: 'cover',
  //                   }}
  //                   data={xmlns}
  //                 />
  //               ) : (
  //                 <>
  //                   <svg
  //                     id="picture"
  //                     xmlns={xmlns}
  //                     width="34.705"
  //                     height="34.705"
  //                     viewBox="0 0 34.705 34.705"
  //                   >
  //                     <path
  //                       id="Path_17011"
  //                       data-name="Path 17011"
  //                       d="M27.475,0H7.23A7.239,7.239,0,0,0,0,7.23V27.475A7.239,7.239,0,0,0,7.23,34.7H27.475a7.239,7.239,0,0,0,7.23-7.23V7.23A7.239,7.239,0,0,0,27.475,0ZM7.23,2.892H27.475A4.338,4.338,0,0,1,31.813,7.23V27.475a4.267,4.267,0,0,1-.434,1.858l-13.25-13.25a7.23,7.23,0,0,0-10.226,0l-5.01,5.009V7.23A4.338,4.338,0,0,1,7.23,2.892Zm0,28.921a4.338,4.338,0,0,1-4.338-4.338V25.181l7.054-7.054a4.338,4.338,0,0,1,6.137,0l13.25,13.251a4.267,4.267,0,0,1-1.858.434Z"
  //                       fill="#868686"
  //                     ></path>
  //                     <path
  //                       id="Path_17012"
  //                       data-name="Path 17012"
  //                       d="M17.561,13.622A5.061,5.061,0,1,0,12.5,8.561,5.061,5.061,0,0,0,17.561,13.622Zm0-7.23a2.169,2.169,0,1,1-2.169,2.169,2.169,2.169,0,0,1,2.169-2.169Z"
  //                       transform="translate(5.575 1.561)"
  //                       fill="#868686"
  //                     ></path>
  //                   </svg>
  //                   <div className="">Preview Not Available</div>
  //                 </>
  //               )}
  //             </div>
  //           </div>
  //           <div className="px-2">
  //             <p>
  //               <b>Filename: </b> {fileName}
  //             </p>
  //           </div>
  //         </div>
  //       </div>,
  //     )
  //   })
  //   return attachmentViews
  // }

  const getClientDropdownOpt = useCallback(() => {
    return clients.map(({ _id, clientId, companyName }) => ({
      value: `${clientId}-${companyName}`,
      key: _id,
    }))
  }, [clients])

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      initialValues={{ ...values }}
      validationSchema={POLICY_VALIDATION_SCHEMA()}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values: mainValues, setFieldValue }) => (
        <Form className="indent-form">
          <div className="row m-0">
            {mainValues && (
              <div
                className="col-7 p-4"
                style={{ height: '100vh', overflow: 'auto' }}
              >
                <h2
                  className="modal-title text-primary mb-4"
                  id="exampleModalLabel"
                >
                  {title}
                </h2>
                <div className="border-bottom h6 py-2 mb-3">Policy Details</div>
                <div className="px-4" style={{ marginLeft: '-24px' }}>
                  <div className="row m-0">
                    <div className="col-12 p-0">
                      <div className="tab-content pb-0" id="v-pills-tabContent">
                        <div
                          className={classNames('tab-pane fade', {
                            'show active': true,
                          })}
                          id="v-pills-1"
                          role="tabpanel"
                          aria-labelledby="v-1"
                        >
                          <div className="row m-0 pt-3">
                            <div className="col-12 p-0">
                              <div className="form-row">
                                {isClientExist ? null : (
                                  <div className="col-md-12 pl-0 pr-0">
                                    <Select
                                      withAsterisk
                                      label="Select Client"
                                      name="clientId"
                                      defaultLabel="Select Client"
                                      options={getClientDropdownOpt()}
                                    />
                                  </div>
                                )}
                                <div className="col-md-6">
                                  <Select
                                    withAsterisk
                                    data-testid="insurance-carrier"
                                    showOthers
                                    label="Insurance Carrier"
                                    name="insuranceCarrier"
                                    defaultLabel="Select Carrier"
                                    options={allInsuranceCarriers}
                                    othersValue="otherInsuranceType"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <Select
                                    withAsterisk
                                    showOthers
                                    label="Policy Type"
                                    name="policyType"
                                    defaultLabel="Select Type"
                                    options={allPolicyTypes}
                                    othersValue="otherPolicyType"
                                  />
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group input-dollar">
                                    <label>Limit</label>
                                    <span className="pr-4">$</span>
                                    <input
                                      className="form-control dollar-text"
                                      label="Limit"
                                      name="sumInsured"
                                      type="text"
                                      value={
                                        mainValues?.sumInsured
                                          ? addComma(mainValues?.sumInsured)
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const onlyNums = value.replaceAll(
                                          ',',
                                          '',
                                        )
                                        if (!isNaN(onlyNums)) {
                                          setFieldValue('sumInsured', onlyNums)
                                        }
                                      }}
                                    />
                                    <ErrorMessage
                                      className="text-danger"
                                      name="sumInsured"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group input-dollar">
                                    <label>Aggregate Limit</label>
                                    <span className="pr-4">$</span>
                                    <input
                                      className="form-control dollar-text"
                                      label="Aggregate Limit"
                                      name="aggregateLimit"
                                      type="text"
                                      value={
                                        mainValues?.aggregateLimit
                                          ? addComma(mainValues?.aggregateLimit)
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const onlyNums = value.replaceAll(
                                          ',',
                                          '',
                                        )
                                        if (!isNaN(onlyNums)) {
                                          setFieldValue(
                                            'aggregateLimit',
                                            onlyNums,
                                          )
                                        }
                                      }}
                                    />
                                    <ErrorMessage
                                      className="text-danger"
                                      name="aggregateLimit"
                                      component="div"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group input-dollar">
                                    <label>Deductible</label>
                                    <span className="pr-4">$</span>
                                    <input
                                      className="form-control dollar-text"
                                      label="Deductible"
                                      name="deducibleField"
                                      type="text"
                                      value={
                                        mainValues?.deducibleField
                                          ? addComma(mainValues?.deducibleField)
                                          : ''
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value
                                        const onlyNums = value.replaceAll(
                                          ',',
                                          '',
                                        )
                                        if (!isNaN(onlyNums)) {
                                          setFieldValue(
                                            'deducibleField',
                                            onlyNums,
                                          )
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <TextInput label="Broker" name="broker" />
                                </div>
                                <div className="col-md-12">
                                  <TextArea
                                    label="Policy Notes"
                                    name="policyNotes"
                                  />
                                </div>
                              </div>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary text-white"
                              >
                                Save &amp; Close
                              </button>
                            </div>
                          </div>
                          <div className="mb-60" />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mainValues && (
              <div
                className="col-md-5 pt-3 border-left mdl-scrol"
                style={{ background: '#F4F7FE', overflowY: 'scroll' }}
              >
                <div className="col-12 p-0">
                  {/* <div className="row">
                    <div className="col-md-6">
                      <label className="incident-text">
                        Policy ID
                        <span className="text-primery">
                          {mainValues ? mainValues.policyId : '-'}
                          <b className="text-dark font-weight-normal ml-2">
                            (System Generated)
                          </b>
                        </span>
                      </label>
                    </div>
                  </div> */}
                  <TextInput label="Policy ID" name="policyId" />
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>

                  <div className="row">
                    <div className="col-md-6">
                      <DatePickerField
                        name="policyDate"
                        label="Policy Inception"
                      />
                    </div>
                    <div className="col-md-6">
                      <DatePickerField
                        name="premiumRenewalDate"
                        label="Policy Expiring"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="next-action" className="col-form-label">
                      Next Action <span className='text-danger'> *</span>
                    </label>
                    <textarea
                      data-testid="next-action"
                      className="form-control col-md-12"
                      id="next-action"
                      placeholder={'Next Action'}
                      name="nextAction"
                      value={mainValues.nextAction || ''}
                      onChange={(a) =>
                        setFieldValue('nextAction', a.target.value)
                      }
                    ></textarea>
                    <ErrorMessage
                      data-testid="next-action-error"
                      className="text-danger"
                      name="nextAction"
                      component="div"
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="col-md-12 mb-3">
                    <span className="mr-4"> Active?</span>
                    <div
                      className="btn-group btn-group-toggle border btn-yesno"
                      data-toggle="buttons"
                    >
                      <label
                        className={
                          'btn mr-2 px-4' + (mainValues.status ? ' active' : '')
                        }
                        onClick={() => {
                          setFieldValue('status', true)
                        }}
                      >
                        <input type="radio" name="status" id="option1" /> Yes
                      </label>
                      <label
                        className={
                          'btn mr-2 px-4' +
                          (!mainValues.status ? ' active' : '')
                        }
                        onClick={() => {
                          setFieldValue('status', false)
                        }}
                      >
                        <input type="radio" name="status" id="option2" /> No
                      </label>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Claims Taken
                    </label>
                    {getClaimViews()}
                    <AddTransactionModalBtn
                      testId="add-claim"
                      modalClose={(val) => {
                        onArrayUpdate('claims', val)
                      }}
                      buttonLabel="Add.."
                      heading={'Add Claim'}
                      btnClasses="btn btn-outline-light text-dark cus-btn"
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    <label className="d-block incident-text" htmlFor="package">
                      Premium Paid
                    </label>
                    {getPremiumViews()}
                    <AddTransactionModalBtn
                      testId="add-premium"
                      modalClose={(val) => {
                        onArrayUpdate('premiums', val)
                      }}
                      heading={'Add Premium'}
                      buttonLabel="Add.."
                      btnClasses="btn btn-outline-light text-dark cus-btn"
                    />
                  </div>
                  <div className="w-100 border-top mx-2 py-2 mt-1"></div>
                  <div className="form-group col-md-12">
                    {/* <label className="d-block incident-text" htmlFor="package">
                      Attachments
                    </label>
                    <div className="row m-0">
                      {values?.attachments &&
                        values.attachments.length > 0 &&
                        getAttachmentViews()}
                    </div>
                    {openAttachmentModal && (
                      <AddAttachment
                        module="policy"
                        onClose={handleAddAttachmentToggle}
                        onAdd={onAttachmentUpload}
                        isOpen={openAttachmentModal}
                      />
                    )}
                    <button
                      type="button"
                      className="btn btn-outline-secondary side-btn mr-3"
                      data-toggle="modal"
                      data-target="#attachuplaods"
                      onClick={handleAddAttachmentToggle}
                    >
                      Add..
                    </button> */}
                    <AttachmentAddView
                      formik={{
                        setFieldValue: setFieldValue,
                        values: mainValues,
                      }}
                      module={'policy'}
                      uid={mainValues.policyId}
                    />
                  </div>
                </div>
              </div>
            )}
            <AreYouSureModal
              openModal={openModal}
              closeModal={closeModal}
              handleSubmit={handleSubmitModal}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PolicyForm
