import { useSelector } from "react-redux";

export const useShowModule = ()=>{
    const permissions = useSelector(state=>state.permissions);
    const checkAccess = (modules = []) => {
      if(modules.length == 0) return true;
      
      const modulesToCheck = permissions.filter(c => modules.includes(c.module.identifier));
      let access = false;
      modulesToCheck.forEach(z => {
        if (z.accessMode !== "NO_ACCESS") {
         access = z.operations.includes("READ");
        }
      })
  
      return access;
    }
    const showModule = (modules) => {
      return checkAccess(modules.map(c=>c.toLowerCase()));
      
      // if(!modules.length) return true;
      // const {roleDetails} = currentUser?.role;
      // const {accessLevels} = roleDetails;
      // return accessLevels?.some(level=>{
      //   const found = roleDetails[level]?.modulesAccess.some(
      //     (r) => modules.indexOf(r) >= 0,
      //   )
      // return found
      // })
    }
    return {showModule}
}