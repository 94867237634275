import http from './httpService'
import apiUrl from '../configs/api'

const apiEndpoint = `${apiUrl}/auth`

export function login(credentials) {
  return http.post(`${apiEndpoint}/login`, credentials)
}
export function sendResetpasswordLink(data){
  return http.put(`${apiEndpoint}/send-reset-password-link`,data)
}

export function forgotPassword(data){
  return http.put(`${apiEndpoint}/forgot-password`,data)
}

export function resetPassword(data,id){
  return http.put(`${apiEndpoint}/reset-password?id=${id}`,data)
}
export function logout() {
  sessionStorage.removeItem('token')
  window.location = '/';
}

export function setLocalStorage(token) {
  sessionStorage.setItem('token', token);
}

export default {
  setLocalStorage,
  login,
  logout,
}
