import React, { useRef, useState } from 'react'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import FontPicker from 'font-picker-react'
import { uploadPublicIncidentFilesWithPresignedUrl } from '../../services/attachmentService'
import { convertBase64ImageToFile } from '../../pages/incident/utils'
import { fontFamily } from '../../configs/utils'

const styles = {
  border: 'none',
  borderRadius: '0px',
}

const SignaturePad = ({
  setDialogOpen,
  setSignImg,
  signImg,
  uid,
  addSignature,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [signatureType, setSignatureType] = useState('type')
  const canvasRef = useRef()
  const signImageRef = useRef(null)
  const [activeFontFamily, setActiveFontFamily] = useState('Open Sans')

  const clearSignature = () => {
    if (signatureType == 'type') {
      document.getElementById('TypeSignature').value = ''
    } else if (signatureType == 'draw') {
      canvasRef.current.clearCanvas()
    } else {
      document.getElementById('uploadImage').value = ''
    }
    setSignImg('')
    signImageRef.current.src = ''
  }

  const imageChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setSignImg(reader.result)
      signImageRef.current.src = reader.result
    }
  }

  const setSignType = async () => {
    const signature = document.getElementById('TypeSignature').value
    if (signature === '') {
      return false
    }
    // Create a canvas element
    const canvas = document.createElement('canvas')
    // Set the canvas dimensions
    canvas.width = 554
    canvas.height = 150
    // Get the canvas context object
    const context = canvas.getContext('2d')
    // Draw the text on the canvas
    context.font = `40px ${activeFontFamily}`

    context.fillStyle = '#fff'
    context.fillRect(0, 0, 554, 150)
    context.fillStyle = '#000'
    context.fillText(signature, 50, 60)
    // Convert the canvas to an image
    const image = new Image()
    image.src = canvas.toDataURL()
    setSignImg(image.src)
    signImageRef.current.src = image.src
    return true
  }

  const reviewSignature = async () => {
    if (signatureType == 'type') {
      setSignType()
    } else if (signatureType == 'draw') {
      const signature = await canvasRef.current.exportImage('png')
      signImageRef.current.src = signature
      setSignImg(signature)
    }
  }

  const saveSignature = async () => {
    setIsSubmitting(true)
    let setImage = false
    if (signatureType == 'type') {
      setImage = await setSignType()
    } else if (signatureType == 'draw') {
      const canvasPaths = await canvasRef.current.exportPaths()
      if (!canvasPaths.length) {
        setIsSubmitting(false)
        return
      }
      const signature = await canvasRef.current.exportImage('png')
      setSignImg(signature)
      signImageRef.current.src = signature
      setImage = true
    } else {
      if (signImg) {
        setImage = true
      }
    }
    if (setImage) {
      const SignFile = convertBase64ImageToFile(signImageRef.current.src)
      const uploadResult = await uploadPublicIncidentFilesWithPresignedUrl(
        [SignFile],
        'public-incident',
        uid,
      ).finally(() => setIsSubmitting(false))
      if (uploadResult) {
        addSignature(uploadResult[0]?._id)
        setDialogOpen(false)
      }
    }

    setIsSubmitting(false)
  }

  return (
    <div className="signature-modal fade align-items-top show" id="signform">
      <div className="modal-dialog w-600 s-modal modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header justify-content-center">
            <h5 className="modal-title text-primary " id="">
              E-Signature{' '}
            </h5>
          </div>
          <div className="custom-tab-header">
            <div
              className={
                'sign-type ' + (signatureType == 'type' ? 'active' : '')
              }
              onClick={() => {
                setSignatureType('type')
              }}
            >
              <i className="fas fa-keyboard mr-2"></i>
              <span>Type</span>
            </div>
            <div
              className={
                'sign-type ' + (signatureType == 'draw' ? 'active' : '')
              }
              onClick={() => {
                setSignatureType('draw')
              }}
            >
              <i className="fas fa-edit mr-2"></i>
              <span>Draw</span>
            </div>
            <div
              className={
                'sign-type ' + (signatureType == 'image' ? 'active' : '')
              }
              onClick={() => {
                setSignatureType('image')
              }}
            >
              <i className="fas fa-image mr-2"></i>
              <span>Image</span>
            </div>
          </div>
          <div className="dialog-body">
            {signatureType == 'type' && (
              <div className="input-group">
                <input
                  type="text"
                  id="TypeSignature"
                  className="form-control apply-font"
                  placeholder="Typing..."
                />
                <FontPicker
                  className="font-change"
                  families={fontFamily}
                  apiKey={process.env.REACT_APP_GOOGLE_FONT_API_KEY}
                  activeFontFamily={activeFontFamily}
                  onChange={(nextFont) => setActiveFontFamily(nextFont.family)}
                  sort={'popularity'}
                />
              </div>
            )}
            {signatureType == 'draw' && (
              <div>
                <ReactSketchCanvas
                  style={styles}
                  ref={canvasRef}
                  strokeWidth={4}
                  strokeColor="#000"
                  id="sig-canvas"
                />
              </div>
            )}
            {signatureType == 'image' && (
              <div className="file-wrapper">
                <input type="file" id="uploadImage" disabled={isSubmitting} onChange={imageChange} />
              </div>
            )}
          </div>
          {signImageRef && <img ref={signImageRef} className="sign-image" />}
          <div className="clear-button-div">
            <button
              type="button"
              onClick={reviewSignature}
              disabled={isSubmitting}
              className="btn btn-primary add-btn mr-2"
            >
              Review
            </button>
            <button
              type="button"
              disabled={isSubmitting}
              onClick={clearSignature}
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
            >
              Clear
            </button>
          </div>
          <div className="modal-footer term-footer">
            <button
              type="button"
              disabled={isSubmitting}
              className="btn btn-primary add-btn"
              onClick={saveSignature}
            >
              {
                isSubmitting && <span className="spinner-border spinner-border-sm mr-2" aria-hidden="true"></span>
              }
              <span role="status">Save</span>
            </button>
            <button
              type="button"
              disabled={isSubmitting}
              className="btn btn-secondary bg-white text-dark px-4 border-radius-12"
              onClick={() => {
                setSignImg('')
                setDialogOpen(false)
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignaturePad
